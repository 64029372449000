import React from 'react';

import { Skeleton } from '@mui/material';

import AppBreadcrumbs from 'Components/Breadcrumbs/AppBreadcrumbs';
import {
  getBuildingNameBreadcrumb,
  getFloorplansBreadcrumb,
  getProjectBreadcrumb,
  getProjectTestBreadcrumb,
  getProjectsBreadcrumb,
  getSitesBuildingsBreadcrumb,
  getTextBreadcrumb,
} from 'Components/Breadcrumbs/dataUtils';
import { useFloorplanContext } from 'Components/Floorplans/context';

const BreadcrumbsFloorplanHDA = () => {
  const {
    projectId: _projectId,
    projectData,
    testId,
    buildingId,
    buildingData,
    testData,
    floorplanData,
  } = useFloorplanContext();
  let breadcrumbs = [];

  const projectId = _projectId || projectData?.id;

  if (buildingId && testId && projectId) {
    breadcrumbs = [
      getProjectsBreadcrumb(),
      getProjectBreadcrumb(
        projectId,
        projectData?.name ?? <Skeleton width={80} />,
      ),
      getProjectTestBreadcrumb(
        projectId,
        testId,
        testData?.testname ?? <Skeleton width={80} />,
      ),
      getFloorplansBreadcrumb(buildingId, projectId, testId),
      getTextBreadcrumb(floorplanData?.filename || ''),
    ];
  } else if (projectId && testId) {
    // Test specific breadcrumbs, navigated from the test page
    // Projects / <Project Name> / <Test Number> / Floor Plans
    breadcrumbs = [
      getProjectsBreadcrumb(),
      getProjectBreadcrumb(
        projectId,
        projectData?.name ?? <Skeleton width={80} />,
      ),
      getProjectTestBreadcrumb(
        projectId,
        testId,
        testData?.testname ?? <Skeleton width={80} />,
      ),
      getFloorplansBreadcrumb(null, projectId, testId),
      getTextBreadcrumb(floorplanData?.filename || ''),
    ];
  } else if (!projectId && buildingId) {
    // Sites & Buildings / <Building name> / Floor Plans
    breadcrumbs = [
      getSitesBuildingsBreadcrumb(),
      getBuildingNameBreadcrumb(
        buildingId,
        buildingData?.buildingname ?? <Skeleton width={80} />,
      ),
      getFloorplansBreadcrumb(buildingId),
      getTextBreadcrumb(floorplanData?.filename || ''),
    ];
  } else if (projectId) {
    // Projects / <Project Name> / Floor Plans
    breadcrumbs = [
      getProjectsBreadcrumb(),
      getProjectBreadcrumb(
        projectId,
        projectData?.name ?? <Skeleton width={80} />,
      ),
      getFloorplansBreadcrumb(null, projectId),
      getTextBreadcrumb(floorplanData?.filename || ''),
    ];
  }

  return <AppBreadcrumbs links={breadcrumbs} />;
};

export default BreadcrumbsFloorplanHDA;
