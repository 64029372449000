import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Save } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import portfoliosApi from 'Api/portfolios';

import { usePortfolioContext } from 'Components/Portfolios/context';

const EditPortfolio = ({ id, cancel }) => {
  const [portfolio, setPortfolio] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { handleSubmit, register, setValue, formState } = useForm();
  const { fetchPortfoliosAndSites } = usePortfolioContext();

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      fetchPortfolioById(id);
    }
  }, []);

  const fetchPortfolioById = async (id) => {
    const data = await portfoliosApi.getPortfolio(id);
    setPortfolio(data?.data?.data ?? null);
    setValue('portfolioname', data?.data?.data?.portfolioname ?? '');
    setIsLoading(false);
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    portfoliosApi
      .updatePortfolio(id, data)
      .then(() => {
        fetchPortfoliosAndSites();
        cancel();
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderForm = () => {
    if (portfolio && !isLoading) {
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <TextField
              id="portfolio-id"
              fullWidth
              required
              label="Portfolio name"
              margin="normal"
              {...register('portfolioname')}
            />
          </FormControl>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              startIcon={<Save />}
              disabled={!formState.isDirty}
              type="submit"
            >
              Save
            </Button>
            <ButtonGroup>
              <Button onClick={cancel}>Cancel</Button>
            </ButtonGroup>
          </Stack>
        </form>
      );
    }
    return null;
  };

  return (
    <Stack p={2} spacing={2}>
      {isLoading && <LinearProgress />}
      <Typography variant="h5">Edit portfolio {id}</Typography>
      <Box py={2}>{renderForm()}</Box>
      {!!error && (
        <Alert severity="error">Error when updating the portfolio</Alert>
      )}
    </Stack>
  );
};

export default EditPortfolio;
