import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';

import { Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function EditTestDescription({
  testDescription,
  open,
  handleClose,
  onSubmit,
  transitionDuration = 220,
  actionsDisabled,
  defaultValues,
}) {
  const TestDescriptionFormRef = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues || {},
  });

  return (
    <>
      <Dialog
        open={open}
        transitionDuration={transitionDuration}
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            p: 1,
            pb: 2,
            justifyContent: 'center',
            alignItems: 'center',
            width: '440px',
          },
        }}
      >
        <DialogTitle>Edit test description</DialogTitle>
        <DialogContent sx={{ width: '100%' }}>
          <form onSubmit={handleSubmit(onSubmit)} ref={TestDescriptionFormRef}>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Test desctiption"
                  defaultValue={testDescription}
                  {...register('overview', {
                    required: 'Test description field required',
                  })}
                  error={!!(errors && errors.overview)}
                  helperText={errors?.overview?.message}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: 'space-between', width: '60%' }}
        >
          <Button
            style={{ width: '100%', marginRight: '8px' }}
            variant="outlined"
            onClick={handleClose}
            disabled={actionsDisabled}
          >
            Cancel
          </Button>
          <Button
            style={{ width: '100%' }}
            variant="contained"
            disabled={actionsDisabled}
            onClick={() => TestDescriptionFormRef.current?.requestSubmit()}
            endIcon={
              actionsDisabled ? (
                <CircularProgress color="inherit" size={'1rem'} />
              ) : null
            }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
