import React, { useEffect, useState } from 'react';

import SignificanceMenu from '../Filters/SignificanceMenu';
import SignificanceTypeMenu from '../Filters/SignificanceTypeMenu';

import { Box, FormControlLabel, Switch } from '@mui/material';

import FilterButton from 'Components/Buttons/FilterButton';
import InputSearch from 'Components/Mix/InputSearch';

import { StyledFilterMenu } from 'Layout/layoutStyles';

import {
  copyColorsFilterObj,
  formDefaultColorsFilteredObj,
  getUserData,
} from 'Utils';

export default function FilterSearchColors({
  filterColors,
  inProgress,
  handleSearch,
  searchFieldLabel,
  singleTest,
  singleTestType,
  handleFilterChange,
  handleInProgressSwitch,
  searchOnly,
  disabled,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const showProgressFilter = getUserData('roleName') !== 'Customer';

  const [isFilterActive, setFilterActive] = useState(false);

  const [checkedObj, setCheckedObj] = useState(
    copyColorsFilterObj(formDefaultColorsFilteredObj()),
  );

  useEffect(() => {
    const newCheckedObj = copyColorsFilterObj(
      filterColors || formDefaultColorsFilteredObj(),
    );
    let atLeastOneNotChecked = false;

    for (let testType of Object.keys(newCheckedObj)) {
      const { colors } = newCheckedObj[testType];
      newCheckedObj[testType].atLeastOneChecked = Object.values(colors).reduce(
        (res, curr) => res || curr,
        false,
      );
      atLeastOneNotChecked =
        atLeastOneNotChecked ||
        Object.values(colors).reduce(
          (res, curr) => res || !curr,
          atLeastOneNotChecked,
          false,
        );
    }
    setFilterActive(atLeastOneNotChecked);
    setCheckedObj(newCheckedObj);
  }, [filterColors]);

  const resetFilter = () => {
    handleFilterChange(formDefaultColorsFilteredObj());
  };

  const InProgressSwitch = React.useMemo(() => {
    if (!showProgressFilter) return null;

    return (
      <Box>
        <FormControlLabel
          control={
            <Switch
              checked={inProgress}
              onChange={(e) => handleInProgressSwitch(e.target.checked)}
              name="inProgressSwitch"
            />
          }
          disabled={disabled}
          label="In Progress"
          sx={{ ml: 1, pt: 0.6 }}
        />
      </Box>
    );
  }, [showProgressFilter, inProgress, handleInProgressSwitch]);

  if (!checkedObj) return;

  if (searchOnly) {
    return (
      <Box
        style={{
          width: '100%',
          marginBottom: 15,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <InputSearch
          placeholder={searchFieldLabel}
          onChange={handleSearch}
          disabled={disabled}
        />
      </Box>
    );
  }

  return (
    <Box style={{ width: '100%', marginBottom: 15 }}>
      <Box sx={{ display: 'flex', alignItems: 'end' }}>
        <Box sx={{ flexGrow: 1, mr: 2 }}>
          <InputSearch
            placeholder={searchFieldLabel}
            onChange={handleSearch}
            disabled={disabled}
          />
        </Box>
        <FilterButton
          btn_id="advanced-fltr-button"
          ariaControls="advanced-fltr-menu"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          filterOpen={!!open}
          text="Filter"
          filterActive={isFilterActive}
          onReset={resetFilter}
          sx={{ my: 'auto' }}
          disabled={disabled}
        />
        <StyledFilterMenu
          id="advanced-fltr-menu"
          MenuListProps={{
            'aria-labelledby': 'advanced-fltr-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
        >
          {singleTest ? (
            <SignificanceMenu
              filterColors={checkedObj}
              handleFilterColors={handleFilterChange}
              testType={singleTestType}
              disabled={disabled}
            />
          ) : (
            <SignificanceTypeMenu
              filterColors={checkedObj}
              handleFilterColors={handleFilterChange}
              disabled={disabled}
            />
          )}
        </StyledFilterMenu>
        {InProgressSwitch}
      </Box>
    </Box>
  );
}
