import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import SpOpEditableList from './components/SpOpEditableList';

import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';

import originPoints from 'Api/originpoints';
import samplePoints from 'Api/samplePoints';

import FloorplanComponent from 'Components/Floorplans/OPsSPs/FloorplanComponent';
import ParameterProvider from 'Components/Floorplans/ParameterProvider';
import { FloorplanContextProvider } from 'Components/Floorplans/context';

export default function SpOpRearrangeDialog({ open, handleClose, testId }) {
  const [OPs, setOPs] = useState([]);
  const [SPs, setSPs] = useState([]);
  const markerSize = 3;
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [updateStatus, setUpdateStatus] = useState('idle');
  const [error, setError] = useState('');

  useEffect(() => {
    fetchOpsAndSps(testId);
  }, [testId]);

  useEffect(() => {
    if (!open) {
      setIsImageLoaded(false);
      setError('');
      setUpdateStatus('idle');
    }
    setIsImageLoaded(false);
  }, [open]);

  const fetchOpsAndSps = async (testId) => {
    if (testId) {
      try {
        const OPs = await originPoints.getAllOriginPointsForTest(testId);
        const SPs = await samplePoints.getAllSamplePointsForTest(testId);
        if (OPs.data?.success && SPs.data?.success) {
          setOPs(OPs.data?.data);
          setSPs(SPs.data?.data);
        } else {
          setError('Error fetching the OP and SP data');
        }
      } catch (e) {
        console.error(e);
        setError(
          'Error fetching the OP and SP data, check the console for details',
        );
      }
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="lg">
      <DialogTitle sx={{ m: 0, p: 2 }}>SP & OP edit</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ minHeight: '80px' }}>
        {error ? <Alert severity="error">{error}</Alert> : null}
        <FloorplanContextProvider location="heatmapPage">
          <Stack direction="row">
            <SpOpEditableList
              OPs={OPs}
              SPs={SPs}
              setError={setError}
              reFetchOpsAndSps={() => {
                fetchOpsAndSps(testId);
              }}
            />
            <ParameterProvider testId={testId} />
            <DndProvider backend={HTML5Backend}>
              <FloorplanComponent
                testId={testId}
                OPs={OPs}
                SPs={SPs}
                reFetchOpsAndSps={() => {
                  fetchOpsAndSps(testId);
                }}
                markerSize={markerSize}
                isImageLoaded={isImageLoaded}
                setIsImageLoaded={setIsImageLoaded}
                updateStatus={updateStatus}
                setUpdateStatus={setUpdateStatus}
              />
            </DndProvider>
          </Stack>
        </FloorplanContextProvider>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
