import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

/**
 * Search with deferred update of external value caused by passed onChange.
 * Suitable for React version 16.8 and all that are higher.
 * Deferred update is used for triggering the search through the data only after user stops typing,
 * so there would be less operations.
 */
function InputSearch({
  id = 'input-with-icon-adornment',
  placeholder = '',
  onChange,
  deferredUpdate = true,
  delayMs = 240,
  disabled,
}) {
  const [searchValue, setSearchValue] = useState('');

  const [lastUpdateMs, setLastUpdateMs] = useState(0);
  const [updateTimeoutId, setUpdateTimeoutId] = useState(0);

  const widthHeight = '18px';
  const iconWidthHeight = '12px';

  const conditionalUpdate = (value) => {
    const now = Date.now();
    let diff = null;
    if (lastUpdateMs) {
      diff = now - lastUpdateMs;
    }
    setLastUpdateMs(now);

    if (updateTimeoutId && diff < delayMs) {
      clearTimeout(updateTimeoutId);
    }
    const timeoutId = setTimeout(() => onChange(value), delayMs);
    setUpdateTimeoutId(timeoutId);
  };

  const handleValueChange = (value) => {
    if (typeof value !== 'string') return;

    const valueTrimmed = value.trim();

    setSearchValue(value);
    if (typeof onChange === 'function') {
      if (deferredUpdate) {
        conditionalUpdate(valueTrimmed);
      } else {
        onChange(valueTrimmed);
      }
    }
  };

  return (
    <TextField
      id={id}
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: searchValue && (
          <InputAdornment position="start">
            <IconButton
              size="small"
              onClick={() => handleValueChange('')}
              sx={{
                width: widthHeight,
                height: widthHeight,
                background: 'rgba(0, 0, 0, 0.05)',
                ml: 2,
              }}
            >
              <CloseIcon
                fontSize="large"
                sx={{
                  width: iconWidthHeight,
                  height: iconWidthHeight,
                }}
              />
            </IconButton>
          </InputAdornment>
        ),
        placeholder: placeholder || 'Search',
      }}
      fullWidth
      variant="standard"
      onChange={(e) => handleValueChange(e.target.value)}
      value={searchValue}
      autoComplete="off"
    />
  );
}

export default React.memo(InputSearch);
