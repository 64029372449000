import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

function CircularProgressWithLabel({ progress, size = 56 }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" value={progress} size={size} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(progress)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  progress: PropTypes.number.isRequired,
};

export default function CustomCircularProgress({ value, step = 2 }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (typeof value === 'number' && Math.abs(progress - value) >= step) {
      setProgress(value);
    }
  }, [value]);

  return <CircularProgressWithLabel progress={progress} />;
}
