import React from 'react';

import { Alert, Box, LinearProgress } from '@mui/material';

import { useFloorplanContext } from 'Components/Floorplans/context';

const LoadingAndAlerts = () => {
  const { error, isLoading, setError } = useFloorplanContext();
  return (
    <Box>
      <Box sx={{ height: '10px' }}>
        {isLoading && <LinearProgress variant="indeterminate" />}
      </Box>

      {error && (
        <Alert
          severity="error"
          onClose={() => {
            setError(null);
          }}
        >
          {error}
        </Alert>
      )}
    </Box>
  );
};

export default LoadingAndAlerts;
