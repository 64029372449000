import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Typography } from '@mui/material';

import BreadcrumbsFloorplans from 'Components/Breadcrumbs/BreadcrumbsFloorplans';
import LoadingAndAlerts from 'Components/Floorplans/LoadingAndAlerts';
import ParameterProvider from 'Components/Floorplans/ParameterProvider';
import { FloorplanContextProvider } from 'Components/Floorplans/context';
import { ProjectDetailsFloorPlan } from 'Components/Floorplans/migratedComponents/ProjectDetailsFloorPlan';

const FloorPlannerPage = () => {
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const { projectId, buildingId, testId } = params;

  return (
    <FloorplanContextProvider>
      <ParameterProvider
        projectId={projectId}
        buildingId={buildingId}
        testId={testId}
      />
      <BreadcrumbsFloorplans />
      <Typography variant="h4" sx={{ mb: 2 }}>
        Floor Plans
      </Typography>
      <LoadingAndAlerts />
      <ProjectDetailsFloorPlan />
    </FloorplanContextProvider>
  );
};

export default FloorPlannerPage;
