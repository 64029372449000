import React from 'react';

import { RedBorderButton } from '../../Layout/layoutStyles';

import Delete from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';

export default function DeleteBorderButton({
  clickAction,
  sx,
  disabled = false,
  tooltipText = '',
  text = null,
  variant = 'outlined',
}) {
  return (
    <Tooltip title={tooltipText} placement="top">
      <span>
        <RedBorderButton
          disabled={disabled}
          variant={variant}
          sx={{ ...sx }}
          onClick={clickAction}
          startIcon={text ? <Delete /> : null}
        >
          {text ? text : <Delete />}
        </RedBorderButton>
      </span>
    </Tooltip>
  );
}
