import React, { useEffect } from 'react';

import { Stack } from '@mui/material';

const HeatmapGradient = ({ healtCare, scale, isNewHeatmap, type }) => {
  const gradientWidth = 120;
  const singleGradientItemHeight = 80;
  const labelMargin = 20;

  const headerMargin = 40;
  const headersLineHeight = 10;

  const fontSize = healtCare ? 16 / scale : 26;

  const labels = {
    reductionLog: [
      '6 (99.9999%)',
      '5 (99.999%)',
      '4 (99.99%)',
      '3 (99.9%)',
      '2 (99%)',
      '1 (90%)',
      '0 (0%)',
    ],
    copiesPerMillion: ['1000', '100', '10', '1', '0.1', '0.01', '0.001'],
  };
  const imgGradientConfigColors = {
    reductionLog: [
      'green',
      'green',
      'green',
      'green',
      'lightgreen',
      'yellow',
      'red',
      'red',
    ],
    copiesPerMillion: [
      'red',
      'red',
      'red',
      'yellow',
      'lightgreen',
      'green',
      'green',
      'green',
      'green',
    ],
  };

  useEffect(() => {
    setTimeout(updateSVGDimensions, 200);
  }, []);

  const updateSVGDimensions = () => {
    try {
      const svgEl = document.getElementById('gradient-svg');
      const contentsEl = document.getElementById('gradient-svg-contents');
      if (!contentsEl) {
        setTimeout(updateSVGDimensions, 200);
        return;
      }
      const { height, width } = contentsEl.getBoundingClientRect();

      svgEl.style.minHeight = height;
      svgEl.style.minWidth = width;
      contentsEl.style.visibility = '';

      contentsEl.style.transform = `translateY(${
        headerMargin + 2 * fontSize + headersLineHeight
      }px)`;
    } catch (err) {
      console.log(err, 'updateSVGDimensions');
      setTimeout(updateSVGDimensions, 200);
    }
  };

  const singleGradientItem = (label, itemNo) => {
    const divisionLineHeight = singleGradientItemHeight * itemNo;
    return (
      <React.Fragment key={itemNo}>
        <line
          x1={0}
          y1={divisionLineHeight}
          x2={gradientWidth}
          y2={divisionLineHeight}
          stroke="white"
          strokeOpacity="0.3"
        />
        <text
          x={gradientWidth + labelMargin}
          y={divisionLineHeight}
          fontFamily="Arial"
          alignmentBaseline="middle"
        >
          {label}
        </text>
      </React.Fragment>
    );
  };

  if (healtCare) {
    return (
      <Stack spacing={4}>
        <span
          style={{
            fontSize: `${fontSize}px`,
            textDecoration: isNewHeatmap ? 'underline' : 'none',
          }}
        >
          Results Evaluation
        </span>
        <Stack direction="row" spacing={4}>
          <div
            style={{
              width: `${fontSize * 1.5}px`,
              height: `${fontSize * 1.5}px`,
              borderRadius: '50%',
              background: 'rgb(24, 255, 27)',
            }}
          />
          <span
            style={{
              fontSize: `${fontSize}px`,
            }}
          >
            Pass
          </span>
        </Stack>
        <Stack direction="row" spacing={4}>
          <div
            style={{
              width: `${fontSize * 1.5}px`,
              height: `${fontSize * 1.5}px`,
              borderRadius: '50%',
              background: 'rgb(255, 25, 24)',
            }}
          />
          <span
            style={{
              fontSize: `${fontSize}px`,
            }}
          >
            Failure
          </span>
        </Stack>
        <Stack direction="row" spacing={4}>
          <div
            style={{
              width: `${fontSize * 1.5}px`,
              height: `${fontSize * 1.5}px`,
              borderRadius: '50%',
              background: 'rgb(147, 211, 237)',
            }}
          />
          <span
            style={{
              fontSize: `${fontSize}px`,
            }}
          >
            N.A.
          </span>
        </Stack>
      </Stack>
    );
  }

  return (
    <svg
      style={{
        fontSize: `${fontSize}px`,
      }}
      id="gradient-svg"
    >
      <defs>
        <linearGradient id="gradient" gradientTransform="rotate(90)">
          {imgGradientConfigColors[type].map((color, i) => (
            <stop
              key={color + i}
              offset={`${
                (i * 100) / (imgGradientConfigColors[type].length - 1)
              }%`}
              stopColor={color}
            />
          ))}
        </linearGradient>
      </defs>
      <g id="gradient-svg-contents" style={{ visibility: 'hidden' }}>
        <text
          x={0}
          y={-1 * (headerMargin + fontSize + headersLineHeight)}
          fontFamily="Arial"
          textDecoration={
            isNewHeatmap && type === 'reductionLog' ? 'underline' : 'none'
          }
        >
          {type === 'reductionLog' ? `Indicator Tag` : 'DNA Copies per'}
        </text>
        <text x={0} y={-1 * headerMargin} fontFamily="Arial">
          {type === 'reductionLog' ? (
            <React.Fragment>
              <tspan>Reduction Log</tspan>
              <tspan baselineShift="sub">10</tspan>
            </React.Fragment>
          ) : (
            <tspan>million emitted</tspan>
          )}
        </text>
        <rect
          x={0}
          y={0}
          width={gradientWidth}
          height={
            type === 'reductionLog'
              ? singleGradientItemHeight * 7
              : singleGradientItemHeight * 8
          }
          fill="url(#gradient)"
        />
        {labels[type].map((label, i) => singleGradientItem(label, i + 1))}
      </g>
    </svg>
  );
};

export default HeatmapGradient;
