import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Breadcrumbs,
  LinearProgress,
  Link,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

import buildings from 'Api/buildings';

import BuildingDetailsEditable from 'Components/BuildingDetails/BuildingDetailsEditable';
import BuildingDisplayImage from 'Components/BuildingDetails/BuildingDisplayImage';
import BuildingFloorplans from 'Components/BuildingDetails/BuildingFloorplans';
import CalculatorEACH from 'Components/BuildingDetails/CalculatorEACH';

import {
  ACCESS_LEVEL_ADMIN,
  ACCESS_LEVEL_EDITOR,
  ROLE_PARTNER,
  ROLE_SAFETRACES,
} from 'Config/roles';

import { getUserData } from 'Utils';

const BuildingDetailsPage = () => {
  const { buildingId } = useParams();

  const { roleName, accessLevel } = getUserData();
  const isPartnerEditor =
    !!~[ACCESS_LEVEL_EDITOR].indexOf(accessLevel) &&
    !!~[ROLE_PARTNER].indexOf(roleName);
  const editPermission =
    !!~[ACCESS_LEVEL_ADMIN, ACCESS_LEVEL_EDITOR].indexOf(accessLevel) &&
    !!~[ROLE_SAFETRACES].indexOf(roleName);

  const [buildingData, setBuildingData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchBuildingData = async (afterLoadCallback = null) => {
    try {
      setIsLoading(true);
      const response = await buildings.getBuilding(buildingId);

      const buildingObj = response.data?.data;

      const metadata = buildingObj?.buildingmetadata;
      if (metadata) {
        const { age, outdoorAirPercentage } = metadata;
        buildingObj.age = age;
        buildingObj.outdoorAirPercentage = outdoorAirPercentage;

        delete metadata.age;
        delete metadata.outdoorAirPercentage;
      }

      setBuildingData(buildingObj);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      if (afterLoadCallback && typeof afterLoadCallback === 'function') {
        afterLoadCallback();
      }
    }
  };

  useEffect(() => {
    fetchBuildingData();
  }, [buildingId]);

  return (
    <Stack spacing={1}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" href="/sites">
          Sites & Buildings
        </Link>
        <Typography color="text.primary">
          {buildingData?.buildingname ?? <Skeleton />}
        </Typography>
      </Breadcrumbs>
      <Typography variant="h4" color="primary">
        {buildingData?.buildingname ?? <Skeleton />}
      </Typography>
      <br />
      <BuildingDetailsEditable
        isLoading={isLoading}
        buildingData={buildingData}
        buildingId={buildingId}
        afterUpdate={fetchBuildingData}
        editPermission={editPermission}
      />
      <br />
      <CalculatorEACH
        isLoading={isLoading}
        buildingData={
          buildingData?.buildingmetadata?.dataForCalculations || null
        }
        buildingId={buildingId}
        afterUpdate={fetchBuildingData}
        buildingName={buildingData?.buildingname || ''}
        isPartnerEditor={isPartnerEditor}
      />
      <br />
      {isLoading && <LinearProgress />}
      <br />
      <BuildingFloorplans
        isLoading={isLoading}
        buildingId={buildingId}
        floorplans={buildingData?.floorplans || []}
      />
      <BuildingDisplayImage
        buildingId={buildingId}
        isLoading={isLoading}
        refetch={fetchBuildingData}
        setIsLoading={setIsLoading}
        images={buildingData?.buildingmetadata?.previewImages || []}
      />
    </Stack>
  );
};

export default BuildingDetailsPage;
