import React, { useEffect, useState } from 'react';

import contractsApi from 'Api/contracts';

import DeleteBorderButton from 'Components/Buttons/DeleteBorderButton';
import DeleteDialog from 'Components/Dialogs/DeleteDialog';
import { usePortfolioContext } from 'Components/Portfolios/context';

import { isSafetracesAdmin } from 'Config/roles';

import { isTrainingCompanySelected, isValidStatusResponse } from 'Utils';

function DeleteContractButton({ contract }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const { setErrorToast, setSuccessToast, fetchPortfoliosAndSites } =
    usePortfolioContext();

  const canForceDelete = isSafetracesAdmin() && isTrainingCompanySelected();
  const isUsed = Object.values(contract.sitecontracts).length !== 0;
  const useForceDelete = isUsed && canForceDelete;

  const handleOpenDialog = () => {
    setIsOpen(true);
  };
  const handleCloseDialog = () => {
    setIsOpen(false);
  };
  /*
    Checking if the component is mounted before doing async requests.
    Prevents a runtime error when trying to update a non-existent component
   */
  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const handleDeleteContract = async () => {
    try {
      if (!isMounted) return;
      setIsLoading(true);
      if (!contract?.contractid) {
        setIsLoading(false);
        setErrorToast('Unable to delete contract, unexpected error.');
        return;
      }

      const response = useForceDelete
        ? await contractsApi.removeContractDeep(contract.contractid)
        : await contractsApi.removeContract(contract.contractid);

      if (isValidStatusResponse(response)) {
        handleCloseDialog();
        setSuccessToast('Contract successfully force-deleted');
        await fetchPortfoliosAndSites();
      } else {
        setErrorToast(response.data?.error?.message);
      }
    } catch (e) {
      setErrorToast(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <DeleteBorderButton
        disabled={isUsed && !canForceDelete}
        clickAction={handleOpenDialog}
        tooltipText={
          isUsed
            ? 'The contract has site contracts linked to it.'
            : 'Delete site contract'
        }
        text={
          useForceDelete
            ? `Force delete "${contract.contractname}" contract`
            : `Delete "${contract.contractname}" contract`
        }
      />
      <DeleteDialog
        open={isOpen}
        isLoading={isLoading}
        handleClose={handleCloseDialog}
        handleDelete={handleDeleteContract}
        title={isUsed ? 'Force delete the contract' : 'Delete contract'}
        extraText={
          useForceDelete
            ? 'This will delete the contract and all associated site contracts amd test packages. ' +
              'It will also cancel and delete all projects, samples, and test results associated with the contract. ' +
              'This action cannot be undone.'
            : ''
        }
        itemName={contract?.contractname}
      />
    </>
  );
}

export default DeleteContractButton;
