import React from 'react';

import './styles.css';

import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { isViewer } from 'Config/roles';

const BuildingFloorplans = (props) => {
  const { isLoading, floorplans, buildingId } = props;

  return (
    <>
      <Box className="buildingDetailsSection">
        <Stack className="buildingDetailsSection-header-container">
          <Typography
            className="buildingDetailsSection-header-container--text"
            variant="h5"
          >
            Floorplans
          </Typography>
          {!isViewer() && (
            <a href={`/floorplanner?buildingId=${buildingId}`}>
              <Typography>Manage floorplans</Typography>
            </a>
          )}
        </Stack>
        <Box className="buildingDetailsSection-form-container">
          {isLoading ? (
            <Skeleton />
          ) : (
            <ul style={{ paddingInlineStart: '16px' }}>
              {floorplans.map(({ filename }, i) => (
                <li key={i}>{filename}</li>
              ))}
            </ul>
          )}
        </Box>
      </Box>
    </>
  );
};

export default BuildingFloorplans;
