import React from 'react';

import { useProjectContext } from '../../context';

import { Modal, Paper } from '@mui/material';

import {
  EditArea,
  EditComments,
  EditCustomArea,
  EditOP,
  EditPlatekind,
  EditSP,
  EditSampleRate,
  EditSampleType,
  EditScenarioComments,
  EditScenarios,
  EditSprayer,
  EditSurveyDate,
  EditTags,
} from 'Components/SingleTest/Modals/TestEditModals';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const EditTestDetailsModal = ({
  isDrawerOpen: isDrawerOpenProp,
  setIsDrawerOpen: setIsDrawerOpenProp,
  drawerMeta: drawerMetaProp,
  reFetchProject: reFetchProjectProp,
}) => {
  const {
    isDrawerOpen: isDrawerOpenContext,
    setIsDrawerOpen: setIsDrawerOpenContext,
    drawerMeta: drawerMetaContext,
    fetchProject: reFetchProjectContext,
    refetchProjectTags,
  } = useProjectContext();

  const reFetchProject = reFetchProjectProp || reFetchProjectContext;
  const setIsDrawerOpen = setIsDrawerOpenProp || setIsDrawerOpenContext;

  const renderComponent = () => {
    const drawerMeta = drawerMetaProp || drawerMetaContext;
    switch (drawerMeta?.type) {
      case 'sample_type':
        return (
          <EditSampleType
            testId={drawerMeta?.testId}
            value={drawerMeta?.value}
            testType={drawerMeta?.data?.testType}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
          />
        );
      case 'sample_rate':
        return (
          <EditSampleRate
            testId={drawerMeta?.testId}
            value={drawerMeta?.value}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
          />
        );
      case 'sprayer':
        return (
          <EditSprayer
            testId={drawerMeta?.testId}
            value={drawerMeta?.value}
            sprayers={drawerMeta?.data.sprayers}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
          />
        );
      case 'comments':
        return (
          <EditComments
            testId={drawerMeta?.testId}
            value={drawerMeta?.value}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
            segmentId={drawerMeta?.data.segmentId}
          />
        );
      case 'platekind':
        return (
          <EditPlatekind
            testId={drawerMeta?.testId}
            value={drawerMeta?.value}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
            segmentId={drawerMeta?.data.segmentId}
            projectId={drawerMeta?.data.projectId}
          />
        );
      case 'surveydate':
        return (
          <EditSurveyDate
            testId={drawerMeta?.testId}
            value={drawerMeta?.value}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
            segmentId={drawerMeta?.data.segmentId}
            segment={drawerMeta?.data.segment}
          />
        );
      case 'area':
        return (
          <EditArea
            testId={drawerMeta?.testId}
            buildingData={drawerMeta?.data}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
          />
        );
      case 'custom_area':
        return (
          <EditCustomArea
            testId={drawerMeta?.testId}
            buildingData={drawerMeta?.data}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
            segment={drawerMeta?.data.segment}
            preparedScenariosList={drawerMeta?.data.preparedScenariosList}
          />
        );
      case 'origin_points':
        return (
          <EditOP
            testId={drawerMeta?.testId}
            value={drawerMeta?.value}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
            segment={drawerMeta?.data.segment}
            test={drawerMeta?.data.test}
            preparedScenariosList={drawerMeta?.data.preparedScenariosList}
            sampleType={drawerMeta?.data.sampleType}
          />
        );
      case 'sample_points':
        return (
          <EditSP
            testId={drawerMeta?.testId}
            value={drawerMeta?.value}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
            segment={drawerMeta?.data.segment}
            test={drawerMeta?.data.test}
          />
        );
      case 'scenarios':
        return (
          <EditScenarios
            testId={drawerMeta?.testId}
            value={drawerMeta?.value}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
            segment={drawerMeta?.data.segment}
          />
        );
      case 'tags':
        return (
          <EditTags
            testId={drawerMeta?.testId}
            value={drawerMeta?.value}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={() => {
              reFetchProject();
              refetchProjectTags();
            }}
            originPoints={drawerMeta?.data.originPoints}
            allowedTags={drawerMeta?.data.allowedTags}
            scenario={drawerMeta?.data.scenario}
            projectId={drawerMeta?.data.projectId}
          />
        );
      case 'scenario_comments':
        return (
          <EditScenarioComments
            value={drawerMeta?.value}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
            scenarioId={drawerMeta?.data.scenarioId}
          />
        );
      default:
      //setIsDrawerOpen(false);
    }
  };

  return (
    <Modal
      open={
        typeof isDrawerOpenProp === 'boolean'
          ? isDrawerOpenProp
          : typeof isDrawerOpenContext === 'boolean'
          ? isDrawerOpenContext
          : false
      }
      onClose={setIsDrawerOpen}
    >
      <Paper sx={style} variant="outlined">
        {renderComponent()}
      </Paper>
    </Modal>
  );
};

export default EditTestDetailsModal;
