import { ERROR_MESSAGE_DEFAULT } from 'Constants';
import React, { useEffect, useRef, useState } from 'react';

import DetailsForm from './Forms/DetailsForm';
import './styles.css';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Box, IconButton, Stack, Typography } from '@mui/material';

import buildingsApi from 'Api/buildings';

import ErrorMessage from 'Components/UI/ErrorMessage';

import { useIsMounted } from 'Context';

import { isValidResponse } from 'Utils';

const BuildingDetailsEditable = (props) => {
  const { isLoading, buildingData, buildingId, afterUpdate, editPermission } =
    props;
  const [actionsDisabled, setActionsDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [editMode, setEditMode] = useState(false);
  const detailsFormRef = useRef(null);
  const mounted = useIsMounted();

  useEffect(() => {
    setActionsDisabled(isLoading);
  }, [buildingData, isLoading]);

  const onFormSubmit = async (values) => {
    try {
      setActionsDisabled(true);

      const metadataFields = [
        'city',
        'state',
        'address',
        'country',
        'postalCode',
        'outdoorAirPercentage',
        'age',
      ];

      const bodyObj = {
        buildingMetadata: {},
      };

      for (let key of Object.keys(values)) {
        const _val = values[key];

        if (!!~metadataFields.indexOf(key)) {
          bodyObj.buildingMetadata[key] = _val;
        } else {
          bodyObj[key] = _val;
        }
      }
      setActionsDisabled(true);

      bodyObj.buildingName = bodyObj.buildingname;

      const updateBuilding = await buildingsApi.updateOneBuilding(
        buildingId,
        bodyObj,
      );

      if (isValidResponse(updateBuilding)) {
        if (typeof afterUpdate === 'function') {
          afterUpdate(() => {
            if (mounted.current) {
              setActionsDisabled(false);
              setEditMode(false);
            }
          });
        }
      } else {
        const errorStr = updateBuilding.data?.error?.message;

        throw new Error(errorStr);
      }
    } catch (err) {
      console.log(`onFormSubmit Error: `, err);
      if (mounted.current) {
        setErrorMsg(err.message || ERROR_MESSAGE_DEFAULT);
      }
    }
  };

  const ActionsButtons = React.useMemo(() => {
    if (!editPermission) return null;

    return (
      <>
        <IconButton
          size="small"
          disabled={actionsDisabled}
          color="primary"
          onClick={
            editMode
              ? () => detailsFormRef.current?.requestSubmit()
              : () => setEditMode(true)
          }
        >
          {editMode ? <SaveIcon /> : <EditIcon />}
        </IconButton>
        <IconButton
          size="small"
          disabled={actionsDisabled}
          style={{
            visibility: !editMode ? 'hidden' : 'visible',
          }}
          color="primary"
          onClick={() => setEditMode(false)}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </>
    );
  }, [editMode, actionsDisabled, editPermission]);

  return (
    <>
      <ErrorMessage
        message={errorMsg}
        handleCloseErrorAlert={() => setErrorMsg('')}
      />
      <Box className="buildingDetailsSection">
        <Stack
          className="buildingDetailsSection-header-container"
          direction="row"
        >
          <Typography
            className="buildingDetailsSection-header-container--text"
            variant="h5"
          >
            Details
          </Typography>
          {ActionsButtons}
        </Stack>
        <Box className="buildingDetailsSection-form-container">
          <DetailsForm
            ref={detailsFormRef}
            onSubmit={(values) => onFormSubmit(values)}
            viewMode={!editMode}
            defaultValues={buildingData}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </>
  );
};

export default BuildingDetailsEditable;
