import React from 'react';

import './styles.css';

import ApartmentIcon from '@mui/icons-material/Apartment';
import MapIcon from '@mui/icons-material/Map';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

export default function PortfolioCardSkeleton() {
  return (
    <Card className="scroll-on-hover">
      <CardHeader
        title={<Skeleton width={'60%'} />}
        titleTypographyProps={{ color: 'primary' }}
      />
      <Divider />
      <CardContent>
        <Stack spacing={1} style={{ maxHeight: '340px', overflowY: 'scroll' }}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ py: 0.5 }}
          >
            <MapIcon
              fontSize="small"
              color="disabled"
              style={{
                position: 'relative',
                top: '2px',
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            />
            <Typography
              variant="h6"
              color="text.secondary"
              style={{
                fontVariant: 'all-petite-caps',
                fontWeight: 'bolder',
                fontSize: '1.4em',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <Skeleton width={'220px'} />
            </Typography>
          </Stack>
          <BuildingPieceSkeleton divider />
          <BuildingPieceSkeleton divider />
          <BuildingPieceSkeleton />
        </Stack>
      </CardContent>
    </Card>
  );
}

const BuildingPieceSkeleton = ({ divider }) => {
  const linkStyle = {
    marginLeft: '24px',
    position: 'absolute',
    left: 10,
  };

  return (
    <div style={{ paddingLeft: '24px' }}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ pt: 0.5, pb: 1, position: 'relative' }}
      >
        <div>
          <ApartmentIcon
            style={{
              height: '24px',
              width: '24px',
              color: 'rgba(0, 0, 0, 0.57)',
              position: 'relative',
              display: 'inline',
              top: '2px',
              left: 0,
            }}
          />
        </div>
        <Link style={linkStyle}>
          <Skeleton width={'120px'} />
        </Link>
      </Stack>
      {!!divider ? (
        <Box
          style={{
            paddingLeft: '32px',
          }}
        >
          <Divider />
        </Box>
      ) : null}
    </div>
  );
};
