import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Box, Stack } from '@mui/material';

import BreadcrumbsBuildingProjects from 'Components/Breadcrumbs/BreadcrumbsBuildingProjects';
import FilterSearchColors from 'Components/Mix/FilterSearchColors';
import ResultsBuildingProjectsDisplay from 'Components/ResultsBuildingProjects/ResultsBuildingProjectsDisplay';
import ErrorMessage from 'Components/UI/ErrorMessage';

import {
  buildingProjectResultsSelector,
  fetchBuildingProjects,
  setNotError,
} from 'Features/BuildingProjectsResults/buildingProjectResultsSlice';
import { colorsSelector } from 'Features/Colors/colorsSlice';
import { inProgressSelector } from 'Features/Filter/inProgressSlice';
import { fetchBuildings } from 'Features/Results/resultsSlice';

export default function BuildingProjectsResults() {
  const { buildingId } = useParams();
  const [search, setSearch] = useState('');
  const [initialLoad, setInitialLoad] = useState(true);
  const { colors } = useSelector(colorsSelector);
  const { inProgress } = useSelector(inProgressSelector);
  const { projects, isLoading, error } = useSelector(
    buildingProjectResultsSelector,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBuildings(colors, buildingId, false, inProgress));
    dispatch(fetchBuildingProjects(buildingId));
  }, [dispatch, buildingId]);

  useEffect(() => {
    if (!isLoading && initialLoad) {
      setInitialLoad(false);
    }
  }, [isLoading]);

  const projectsList = useMemo(() => {
    return projects.filter((project) =>
      project.name.toLowerCase().includes(search.toLowerCase()),
    );
  }, [projects, search]);

  return (
    <Stack direction="column">
      {!!error && (
        <ErrorMessage
          message={error}
          handleCloseErrorAlert={() => dispatch(setNotError())}
        />
      )}
      <Box sx={{ height: '60px' }}>
        <BreadcrumbsBuildingProjects buildingId={buildingId} />
      </Box>
      <FilterSearchColors
        handleSearch={(value) => setSearch(value)}
        searchFieldLabel={'Search for projects'}
        searchOnly={true}
        disabled={isLoading || initialLoad}
      />
      <ResultsBuildingProjectsDisplay
        loading={!!isLoading || initialLoad}
        projects={projectsList}
      />
    </Stack>
  );
}
