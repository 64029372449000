import { LOCAL_STORAGE_USER_KEY } from '../Context/Classes/User';
import { getUserData } from '../Utils';

const isSafetracesAdmin = () => {
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
  return (
    user !== null &&
    user.accessLevel === ACCESS_LEVEL_ADMIN &&
    user.roleName === ROLE_SAFETRACES
  );
};

const isPartnerAdmin = () => {
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
  return (
    user !== null &&
    user.accessLevel === ACCESS_LEVEL_ADMIN &&
    user.roleName === ROLE_PARTNER
  );
};

const isSafetracesEditor = () => {
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
  return (
    user !== null &&
    user.accessLevel === ACCESS_LEVEL_EDITOR &&
    user.roleName === ROLE_SAFETRACES
  );
};

const isViewer = () => {
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
  return user !== null && user.accessLevel === ACCESS_LEVEL_VIEWER;
};

const isPartnerUser = () => {
  try {
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
    return user !== null && user.roleName === ROLE_PARTNER;
  } catch (err) {
    return false;
  }
};

const isPartnerEditorWithLabAccess = () => {
  try {
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
    return (
      user !== null && 
      user.roleName === ROLE_PARTNER && 
      (user.accessLevel === ACCESS_LEVEL_EDITOR || user.accessLevel === ACCESS_LEVEL_ADMIN) &&
      user.companyName !== null && user.companyName === 'Logistics Reply'
    );
  } catch (err) {
    return false;
  }
};

const isSafetracesUser = () => {
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
  return user !== null && user.roleName === ROLE_SAFETRACES;
};

export const ACCESS_LEVEL_ADMIN = 'Admin';
export const ACCESS_LEVEL_EDITOR = 'Editor';
export const ACCESS_LEVEL_VIEWER = 'Viewer';
export const ROLE_SAFETRACES = 'Safetracer';
export const ROLE_PARTNER = 'Partner';
export const ROLE_CUSTOMER = 'Customer';

const hasPermission = (item) => {
  if (!item.permission) {
    return true;
  }

  return !!item.permission.includes(
    getUserData('roleName') + getUserData('accessLevel'),
  );
};

export {
  isSafetracesAdmin,
  isPartnerAdmin,
  isSafetracesEditor,
  isSafetracesUser,
  isPartnerUser,
  isViewer,
  isPartnerEditorWithLabAccess,
  hasPermission,
};
