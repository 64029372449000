import React from 'react';

import ApartmentIcon from '@mui/icons-material/Apartment';
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton,
} from '@mui/material';

export default function ResultsItemSkeleton() {
  return (
    <ListItemButton sx={{ maxWidth: '800px', borderRadius: 5 }}>
      <ListItemAvatar>
        <Avatar>
          <ApartmentIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={<Skeleton width={320} />}
        secondary={<Skeleton width={380} />}
        secondaryTypographyProps={{ paddingRight: 2 }}
      />
    </ListItemButton>
  );
}
