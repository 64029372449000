import { ERROR_AUTOCLOSE_TIMEOUT_MS } from 'Constants';
import React, { useState } from 'react';

import DeleteDialog from '../../Dialogs/DeleteDialog';

import buildings from 'Api/buildings';

import DeleteIconButton from 'Components/Buttons/DeleteIconButton';
import ErrorMessage from 'Components/UI/ErrorMessage';

import { isValidResponse } from 'Utils';

export default function DeleteBuildingButton({
  afterDelete,
  buildingId,
  disabled,
  disabledReason,
  buildingName,
  sx,
}) {
  const buttonDisabledProps = disabled
    ? {
        disabled,
        tooltipText: !!disabled && disabledReason,
      }
    : {};

  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [mainError, setMainError] = useState('');

  const deleteBuilding = async () => {
    setDeleteInProgress(true);
    let isSuccess = false;
    try {
      const response = await buildings.deleteBuilding(buildingId);
      isSuccess = isValidResponse(response);
    } catch (err) {
      console.log('Delete building error: ', err);
      setMainError(err?.message || `Couldn't delete the building`);
      setTimeout(() => setMainError(''), ERROR_AUTOCLOSE_TIMEOUT_MS);
    } finally {
      setDeleteInProgress(false);
      setDialogOpen(false);
      if (isSuccess && typeof afterDelete === 'function') {
        afterDelete();
      }
    }
  };

  return (
    <>
      <DeleteDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        title="Delete company"
        itemName={(buildingName && `the company "${buildingName}"`) || ''}
        isLoading={deleteInProgress}
        handleDelete={deleteBuilding}
      />
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
      <DeleteIconButton
        onClick={() => setDialogOpen(true)}
        sx={{ border: 0, ...sx }}
        {...buttonDisabledProps}
      />
    </>
  );
}
