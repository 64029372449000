import React from 'react';
import { useSelector } from 'react-redux';

import AppBreadcrumbs from './AppBreadcrumbs';
import { getPortfolioBreadcrumb, getTextBreadcrumb } from './dataUtils';

import { resultsSelector } from 'Features/Results/resultsSlice';

function BreadcrumbsBuildingProjects({ buildingId }) {
  const { buildings, currentBuildingName } = useSelector(resultsSelector);
  let breadcrumbs = [
    getPortfolioBreadcrumb(buildings, buildingId, true),
    currentBuildingName ? getTextBreadcrumb(currentBuildingName) : null,
  ];
  return <AppBreadcrumbs links={breadcrumbs} />;
}

export default BreadcrumbsBuildingProjects;
