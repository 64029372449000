import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { getMergePosition, getScaledImageSize } from './utils';

const SingleFloorplanDisplay = forwardRef(({ url, cropData }, imgRef) => {
  const displayStyles = {
    originalImage: {
      container: {
        display: 'flex',
      },
      canvas: {
        display: 'none',
      },
      image: {
        margin: 'auto',
      },
    },
    croppedImage: {
      container: {},
      canvas: {},
      image: { display: 'none' },
    },
  };

  const imgContainerRef = useRef(null);
  let containerDimensionsUpdateTimeoutId;

  const [displayType, setDisplayType] = useState('croppedImage');

  const [imageDimensions, setImageDimensions] = useState(null);
  const [containerDimensions, setContainerDimensions] = useState(null);

  useEffect(() => {
    obtainContainerDimensions();

    window.addEventListener(`resize`, updateContainerDimensionsOnWindowResize);
    return () => {
      window.removeEventListener(
        `resize`,
        updateContainerDimensionsOnWindowResize,
      );
    };
  }, []);

  /**
   * Redraw image on canvas in case of any dimensions or crop settings change.
   * Does nothing if image should not be cropped.
   */
  useEffect(() => {
    const shouldCropImage = !!(
      imageDimensions &&
      containerDimensions &&
      cropData?.width
    );

    if (shouldCropImage) {
      cropAndTransformImage();
    }
  }, [imageDimensions, containerDimensions, cropData, displayType]);

  /**
   * Updates display type, based on the nesessity of image crop, so that
   * correct styles for container, canvas & image could be applied.
   */
  useEffect(() => {
    const _displayType = cropData?.width ? 'croppedImage' : 'originalImage';
    setDisplayType(_displayType);
  }, [cropData]);

  /**
   * Triggers delayed update of the main container dimensions within state.
   * useCallback is being used so that removeEventListener would work correctly
   * (removeEventListener searches for the _same_ function from addEventListener)
   */
  const updateContainerDimensionsOnWindowResize = useCallback(() => {
    if (containerDimensionsUpdateTimeoutId) {
      clearTimeout(containerDimensionsUpdateTimeoutId);
    }

    containerDimensionsUpdateTimeoutId = setTimeout(
      obtainContainerDimensions,
      200,
    );
  }, []);

  const obtainImageDimensions = () => {
    if (!imgRef?.current) {
      return setTimeout(obtainImageDimensions, 200);
    }
    const { naturalWidth: width, naturalHeight: height } = imgRef.current;

    setImageDimensions({
      width,
      height,
    });
  };

  const obtainContainerDimensions = () => {
    if (!imgContainerRef?.current) {
      containerDimensionsUpdateTimeoutId = setTimeout(
        () => obtainContainerDimensions(),
        200,
      );
      return;
    }

    const { clientWidth: width, clientHeight: height } =
      imgContainerRef.current;
    containerDimensionsUpdateTimeoutId = null;
    setContainerDimensions({
      width,
      height,
    });
  };

  const cropAndTransformImage = () => {
    const { width: imgWidth, height: imgHeight } = imageDimensions;

    const canvas = document.getElementById('floorplanView');
    const context = canvas.getContext('2d');

    canvas.width = imgWidth;
    canvas.height = imgHeight;

    const finalDimensions = getScaledImageSize(cropData, containerDimensions);
    const { x: shiftCroppedX, y: shiftCroppedY } = getMergePosition(
      finalDimensions,
      { x: 0, y: 0 },
      { x: containerDimensions.width, y: containerDimensions.height },
    );

    context.drawImage(
      imgRef.current,
      cropData.x,
      cropData.y,
      cropData.width,
      cropData.height,
      shiftCroppedX,
      shiftCroppedY,
      finalDimensions.width,
      finalDimensions.height,
    );
  };

  return (
    <div
      ref={imgContainerRef}
      style={{
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        ...displayStyles[displayType].container,
      }}
    >
      <canvas id="floorplanView" style={displayStyles[displayType].canvas} />
      <img
        ref={imgRef}
        onLoad={obtainImageDimensions}
        src={url}
        style={{
          maxWidth: '100%',
          ...displayStyles[displayType].image,
        }}
        alt="floorplanImg"
      />
    </div>
  );
});

export default SingleFloorplanDisplay;
