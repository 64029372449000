import { createContext, useContext, useEffect, useState } from 'react';

import buildingsApi from 'Api/buildings';
import floorPlansApi from 'Api/floorPlans';
import projectsApi from 'Api/projects';
import testsApi from 'Api/tests';

import { getDataFromResponse, isValidResponse } from 'Utils';

const FloorplanContext = createContext({});

export const FloorplanContextProvider = ({ location, children }) => {
  const [projectId, setProjectId] = useState(null);
  const [floorplanId, setFloorplanId] = useState(null);
  const [floorplanData, setFloorplanData] = useState(null);
  const [projectData, setProjectData] = useState(undefined);
  const [buildingId, setBuildingId] = useState(null);
  const [buildingData, setBuildingData] = useState(undefined);
  const [testId, setTestId] = useState(null);
  const [testData, setTestData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [error, setError] = useState(null);
  const [floorPlans, setFloorPlans] = useState([]);
  const [HDAtypesByApplication, setHDATypesByApplication] = useState({});
  const [HDACreateMetadata, setHDACreateMetadata] = useState(null);
  const [HDAToDelete, setHDAToDelete] = useState(null);
  const [deleteHDADialogOpen, setDeleteHDADialogOpen] = useState(false);
  const [overrideColorPointId, setOverrideColorPointId] = useState(null);
  const [pointFocusTextFieldId, setPointFocusTextFieldId] = useState(null);

  const fetchBuildingDetails = async (buildingId) => {
    try {
      const buildingResponse = await buildingsApi.getBuilding(buildingId);
      if (isValidResponse(buildingResponse)) {
        setBuildingData(getDataFromResponse(buildingResponse));
      } else {
        setError(
          `Error getting building data for building with ID ${buildingId}`,
        );
      }
    } catch (e) {
      console.error(e);
      setError(
        `Error getting building data for building with ID ${buildingId}`,
      );
    }
  };

  const fetchProjectDetails = async (projectId) => {
    try {
      const projectResponse = await projectsApi.getOneProject(projectId);
      if (isValidResponse(projectResponse)) {
        setProjectData(getDataFromResponse(projectResponse));
      } else {
        setError(`Error getting project data for project with ID ${projectId}`);
      }
    } catch (e) {
      console.error(e);
      setError(`Error getting project data for project with ID ${projectId}`);
    }
  };

  const fetchTestData = async (testId) => {
    try {
      const testResponse = await testsApi.getTest(testId);
      if (isValidResponse(testResponse)) {
        setTestData(getDataFromResponse(testResponse));
      } else {
        setError(`Error getting test data for test with ID ${testId}`);
      }
    } catch (e) {
      console.error(e);
      setError(`Error getting test data for test with ID ${testId}`);
    }
  };

  const fetchDataByBuildingId = async (bId) => {
    const resp = await floorPlansApi.getFloorPlansForBuilding(bId);
    if (isValidResponse(resp)) {
      setFloorPlans(getDataFromResponse(resp));
    }
  };

  const fetchFloorplanData = async (floorplanId) => {
    try {
      const floorplanResponse = await floorPlansApi.getFloorPlan(floorplanId);
      if (isValidResponse(floorplanResponse)) {
        const floorplan = getDataFromResponse(floorplanResponse)[0];

        setBuildingId(floorplan.buildingid);
        setProjectId(floorplan.projectid);

        setFloorplanData(floorplan);
      } else {
        setError(
          `Error getting floorplan data for floorplan with ID ${floorplanId}`,
        );
      }
    } catch (e) {
      console.error(e);
      setError(
        `Error getting floorplan data for floorplan with ID ${floorplanId}`,
      );
    }
  };

  const fetchHDATypesData = async () => {
    try {
      const response = await floorPlansApi.getHDATypes();
      if (isValidResponse(response)) {
        const hdaTypes = getDataFromResponse(response);

        setHDATypesByApplication(hdaTypes);
      } else {
        setError(`Error getting HDA types data`);
      }
    } catch (e) {
      console.error(e);
      setError(`Error getting HDA types data`);
    }
  };

  const fetchAllData = async () => {
    setIsLoading(true);
    try {
      await fetchHDATypesData();
      if (buildingId) {
        await fetchBuildingDetails(buildingId);
        await fetchDataByBuildingId(buildingId);
      }
      if (projectId) {
        await fetchProjectDetails(projectId);
      }
      if (testId) {
        await fetchTestData(testId);
      }
      if (floorplanId) {
        await fetchFloorplanData(floorplanId);
      }
    } catch (e) {
      console.error(e);
      setError('Error loading floor plans data');
    } finally {
      setIsLoading(false);
    }
  };

  const focusOnPlaceblePointOnTextFieldFocus = (id) => {
    if (location !== 'heatmapPage') return;
    setOverrideColorPointId(id);
  };

  const resetFocusOnPlaceblePoint = () => {
    if (location !== 'heatmapPage') return;
    setOverrideColorPointId(null);
  };

  const setFocusOnTextField = (id) => {
    if (location !== 'heatmapPage' || id === pointFocusTextFieldId) return;
    setPointFocusTextFieldId(id);
  };

  useEffect(() => {
    fetchAllData();
  }, [buildingId, projectId, testId]);

  useEffect(() => {
    if (testId) {
      fetchTestData(testId);
    }
  }, [testId]);

  return (
    <FloorplanContext.Provider
      value={{
        projectId,
        setProjectId,
        projectData,
        setProjectData,
        buildingId,
        setBuildingId,
        buildingData,
        setBuildingData,
        testId,
        setTestId,
        floorplanId,
        setFloorplanId,
        floorplanData,
        testData,
        setTestData,
        isLoading,
        setIsLoading,
        loadingProgress,
        setLoadingProgress,
        error,
        setError,
        floorPlans,
        setFloorPlans,
        fetchAllData,
        HDAtypesByApplication,
        HDACreateMetadata,
        setHDACreateMetadata,
        HDAToDelete,
        setHDAToDelete,
        deleteHDADialogOpen,
        setDeleteHDADialogOpen,
        location,
        focusOnPlaceblePointOnTextFieldFocus,
        resetFocusOnPlaceblePoint,
        overrideColorPointId,
        pointFocusTextFieldId,
        setFocusOnTextField,
      }}
    >
      {children}
    </FloorplanContext.Provider>
  );
};

export const useFloorplanContext = () => {
  return useContext(FloorplanContext);
};
