import React from 'react';

import AppBreadcrumbs from '../AppBreadcrumbs';
import {
  getProjectBreadcrumb,
  getProjectsBreadcrumb,
  getTextBreadcrumb,
} from '../dataUtils';

function BreadcrumbForProjectTest({ currentProjectName, projectId, testId }) {
  let breadcrumbs = [
    getProjectsBreadcrumb(),
    getProjectBreadcrumb(projectId, currentProjectName),
    getTextBreadcrumb(testId),
  ];
  return <AppBreadcrumbs links={breadcrumbs} />;
}

export default React.memo(BreadcrumbForProjectTest);
