import React, { useEffect, useState } from 'react';

import { useFloorplanContext } from '../context';

import DoneIcon from '@mui/icons-material/Done';
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import floorplansApi from 'Api/floorPlans';

import DeleteIconButton from 'Components/Buttons/DeleteIconButton';

import { isViewer } from 'Config/roles';

const HDAitem = ({ hda, reFetch }) => {
  const {
    setError,
    HDAtypesByApplication,
    setHDAToDelete,
    setDeleteHDADialogOpen,
  } = useFloorplanContext();
  const [hdaName, setHDAName] = useState(hda.name);
  const [hdaType, setHDAType] = useState(hda.type);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const HDAtypes = Object.values(HDAtypesByApplication).reduce((all, curr) => {
    return Object.assign(all, curr.spaceTypes);
  }, {});

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    }
  }, [isSuccess]);

  useEffect(() => {
    setHDAName(hda.name);
  }, [hda.name]);

  const updateHDAName = async () => {
    if (hdaName === hda.name) return;

    setIsUpdating(true);
    try {
      const res = await floorplansApi.updateHDA(hda.id, {
        name: hdaName,
      });
      if (res?.data && res?.data?.success) {
        setIsSuccess(true);
        await reFetch();
      } else {
        setError('Error while updating the HDA details');
      }
    } catch (e) {
      setError('Error while updating the HDA details');
    } finally {
      setIsUpdating(false);
    }
  };

  const updateHDAType = async (val) => {
    setIsUpdating(true);
    try {
      const res = await floorplansApi.updateHDA(hda.id, {
        type: val,
      });
      if (res?.data && res?.data?.success) {
        setIsSuccess(true);
        await reFetch();
      } else {
        setError('Error while updating the HDA details');
      }
    } catch (e) {
      setError('Error while updating the HDA details');
    } finally {
      setIsUpdating(false);
    }
  };

  const renderLoadingIcon = () => {
    if (isUpdating) return <CircularProgress size="30px" />;
    if (isSuccess) return <DoneIcon color="success" />;
  };

  return (
    <Paper sx={{ p: 2 }}>
      {!isViewer() ? (
        <Box display="flex" alignItems="center">
          <Stack sx={{ mr: 1, width: '100%' }}>
            <TextField
              id={`hda-item-name-input-${hda.id}`}
              value={hdaName ?? ''}
              variant="standard"
              onChange={(e) => {
                setHDAName(e.target.value);
              }}
              size="small"
              onBlur={updateHDAName}
              placeholder={hda.name}
            />
            <FormControl fullWidth sx={{ mt: 2 }} variant="standard">
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="hda-select-label"
                id="hda-select"
                value={Object.entries(HDAtypes).length ? hdaType || '' : ''}
                label="Type"
                onChange={(e) => {
                  const val = e.target.value;
                  setHDAType(val);
                  updateHDAType(val);
                }}
              >
                {!!HDAtypes
                  ? Object.entries(HDAtypes).map((hda) => (
                      <MenuItem key={hda[0]} value={hda[0]}>
                        {hda[1]}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Stack>
          <Box width="30px">{renderLoadingIcon()}</Box>
          <DeleteIconButton
            sx={{ ml: 'auto' }}
            onClick={() => {
              setHDAToDelete({ id: hda.id, name: hda.name });
              setDeleteHDADialogOpen(true);
            }}
          />
        </Box>
      ) : (
        <Stack>
          <Typography sx={{ fontWeight: 'bolder' }}>{hda.name}</Typography>
          <Typography>{HDAtypes[hda.type]}</Typography>
        </Stack>
      )}
    </Paper>
  );
};

export default HDAitem;
