import React from 'react';

import { TableCell } from '@mui/material';

import { StyledTableRow } from 'Layout/layoutStyles';

/**
 * Just a bunch of rows for preserving tables height (if one has table with 10 rows and only 5 records
 * but wants table to be the same height for example on display page change - one can use this filler)
 * Expects children for obtaining row's height.
 * Usually it's some actions (buttons) as text is smaller than buttons in cells.
 */
export default function TableBodyEmptyRowsFiller({ children, rowsAmount }) {
  return (
    <React.Fragment>
      {Array(rowsAmount)
        .fill(1)
        .map((_, i) => (
          <StyledTableRow disablecoloring="true" key={i}>
            <TableCell sx={{ borderBottomColor: 'rgba(1,1,1,0)' }}>
              {children}
            </TableCell>
          </StyledTableRow>
        ))}
    </React.Fragment>
  );
}
