import { ERROR_AUTOCLOSE_TIMEOUT_MS } from 'Constants';
import React, { useState } from 'react';

import Button from '@mui/material/Button';

import users from 'Api/users';

import ConfirmDialog from 'Components/Dialogs/ConfirmDialog';
import ErrorMessage from 'Components/UI/ErrorMessage';

import { useIsMounted } from 'Context';

import { isValidResponse } from 'Utils';

export default function RejectUserBtn({ afterReject, userId }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [rejectInProgress, setRejectInProgress] = useState(false);
  const [mainError, setMainError] = useState('');
  const mounted = useIsMounted();

  const handleReject = async () => {
    setRejectInProgress(true);
    let isSuccess = false;
    try {
      const response = await users.removeOneUser(userId);
      isSuccess = isValidResponse(response);
      if (!isSuccess) {
        throw new Error(response?.data?.error?.message);
      }
    } catch (err) {
      console.log('Reject user error: ', err);
      setMainError(err?.message || `Couldn't reject user`);
      setTimeout(() => setMainError(''), ERROR_AUTOCLOSE_TIMEOUT_MS);
    } finally {
      if (mounted.current) {
        setRejectInProgress(false);
      }
      if (isSuccess && typeof afterReject === 'function') {
        if (mounted.current) {
          setModalOpen(false);
        }
        afterReject();
      }
    }
  };

  return (
    <div>
      <Button
        size="small"
        onClick={() => setModalOpen(true)}
        disableFocusRipple
      >
        Reject
      </Button>
      <ConfirmDialog
        open={modalOpen}
        action="reject user"
        okBtnText="Reject user"
        handleClose={() => setModalOpen(false)}
        handleConfirm={handleReject}
        isLoading={rejectInProgress}
      />
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
    </div>
  );
}
