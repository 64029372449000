import React, { useMemo } from 'react';

import { ExpandMore } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  IconButton,
  Link,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

import {
  AddSiteContractButton,
  DeleteContractButton,
  DeleteSiteContractButton,
} from 'Components/Portfolios/ModalButtons';
import CircularProgressWithLabel from 'Components/Portfolios/PortfolioItem/CircularProgressWithLabel';
import { usePortfolioContext } from 'Components/Portfolios/context';
import {
  getCoverageSummaryText,
  getNumberOfTests,
  renderTestsIcons,
} from 'Components/Portfolios/utils';

import { getCompanies } from 'Utils';

const PortfolioContractSummary = ({ contract, territories, regions }) => {
  const {
    requestTestsCoverageDataForContractUpdate,
    testCoverageIsUpdating,
    openEditModal,
  } = usePortfolioContext();

  const handleTitleChangeClick = (e) => {
    e.stopPropagation();
    openEditModal({
      type: 'contract',
      id: contract.contractid,
    });
  };

  const handleSiteContractChangeClick = (e, scId) => {
    e.stopPropagation();
    openEditModal({
      type: 'siteContract',
      id: scId,
    });
  };

  const startDate = new Date(contract.startdate);
  const endDate = new Date(contract.enddate);
  const today = new Date();
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });

  const chipData = {
    color: 'primary',
    text: 'Not started',
  };

  if (today > endDate) {
    chipData.color = 'success';
    chipData.text = 'Completed';
  } else if (today > startDate && today < endDate) {
    chipData.color = 'warning';
    chipData.text = 'Ongoing';
  }

  const renderProgressCircles = (testCoverage) => {
    const parts = [];
    if (testCoverage) {
      if (testCoverage?.scheduled_rate)
        parts.push({
          label: 'Scheduled',
          rate: testCoverage.scheduled_rate,
          color: 'primary',
        });
      if (testCoverage?.in_progress_rate)
        parts.push({
          label: 'In progress',
          rate: testCoverage.in_progress_rate,
          color: 'secondary',
        });
      if (testCoverage?.completed_rate)
        parts.push({
          label: 'Completed',
          rate: testCoverage.completed_rate,
          color: 'success',
        });
    }

    return (
      <Stack direction="row" style={{ height: '76px' }}>
        {parts.map((p) => (
          <CircularProgressWithLabel
            key={p.label}
            label={p.label}
            progressPercent={p.rate}
            color={p.color}
            loading={false}
          />
        ))}
        {!testCoverage && testCoverageIsUpdating
          ? ['Scheduled', 'In progress', 'Completed'].map((label, i) => (
              <CircularProgressWithLabel key={i} label={label} loading={true} />
            ))
          : null}
      </Stack>
    );
  };

  const renderRegionsAndTerritories = () => {
    if (regions.length === 0 && territories.length === 0) {
      return null;
    }
    const relevantRegions = regions.filter((r) =>
      contract.regionIds.includes(r.id),
    );
    const relevantTerritories = territories.filter((t) =>
      contract.territoryIds.includes(t.id),
    );
    return `(${relevantRegions
      .map((r) => r.name)
      .join(', ')} - ${relevantTerritories.map((t) => t.name).join(', ')})`;
  };

  const updateTestsData = async (_, isOpen) => {
    if (!isOpen) return;

    const portfolioId = contract?.portfolioid;
    const contractId = contract?.contractid;

    // Timeout for accordion's animation to finish, so that transition smoothness won't be lost.
    setTimeout(
      () => requestTestsCoverageDataForContractUpdate(portfolioId, contractId),
      250,
    );
  };

  const ContractTestCoverageText = useMemo(() => {
    if (!contract?.testCoverage && !testCoverageIsUpdating) return null;

    return (
      <>
        {!contract?.testCoverage ? (
          <Skeleton />
        ) : (
          <Typography>
            {getCoverageSummaryText(contract?.testCoverage)}
          </Typography>
        )}
      </>
    );
  }, [contract?.testCoverage, testCoverageIsUpdating]);

  return (
    <Accordion onChange={updateTestsData}>
      <AccordionSummary expandIcon={<ExpandMore />} id={contract.contractid}>
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Stack spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h5">{contract.contractname}</Typography>
              <IconButton
                aria-label="edit portfolio title"
                size="small"
                onClick={handleTitleChangeClick}
              >
                <EditIcon />
              </IconButton>
              <Typography>{renderRegionsAndTerritories()}</Typography>
              <Typography>Total tests: {contract.totaltests} </Typography>
            </Stack>
            {ContractTestCoverageText}
          </Stack>
          <Chip
            variant="outlined"
            label={chipData.text}
            color={chipData.color}
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack marginBottom={2}>
          <Typography>
            <strong>Start date: </strong>
            {dateTimeFormat.format(startDate)}
          </Typography>
          <Typography>
            <strong>End date: </strong>
            {dateTimeFormat.format(endDate)}
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Stack direction="row" alignContent="center">
            <Typography variant="h6">Site contracts</Typography>
            <AddSiteContractButton contract={contract} />
          </Stack>
          {Array.from(Object.values(contract.sitecontracts)).map((sc) => {
            const partner = getCompanies().find(
              (c) => c.companyid === sc.partner,
            );
            return (
              <Box
                key={sc.sitecontractid}
                sx={{
                  p: 1,
                  '&:hover': {
                    backgroundColor: '#f5f5f5',
                  },
                }}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Box>
                    <Stack direction="row">
                      <Link
                        underline="hover"
                        href={'/test-packages/' + sc.sitecontractid}
                        sx={{ display: 'flex' }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ marginTop: 'auto', marginBottom: 'auto' }}
                        >
                          {sc.sitecontractname}
                        </Typography>
                      </Link>
                      <IconButton
                        aria-label="edit sitecontract"
                        size="small"
                        onClick={(e) =>
                          handleSiteContractChangeClick(e, sc.sitecontractid)
                        }
                        sx={{ ml: 1 }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Stack>
                    <Typography variant="subtitle2">
                      Partner: {partner?.companyname} ({partner?.contact})
                    </Typography>
                  </Box>
                  {renderProgressCircles(sc.testCoverage)}
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {renderTestsIcons(getNumberOfTests(sc))}
                  <DeleteSiteContractButton sitecontract={sc} />
                </Stack>
                <Divider sx={{ m: 1 }} />
              </Box>
            );
          })}
          <Stack direction="row">
            <DeleteContractButton contract={contract} />
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default PortfolioContractSummary;
