import React from 'react';

import { SvgIcon } from '@mui/material';

const SamplePortfolioIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <g
        id="g230"
        transform="matrix(0.28717225,0,0,0.36047122,-2.8946963,-6.2685945)"
      >
        <polygon
          className="cls-1"
          points="30.59,28.53 30.23,25.83 66.21,25.63 68.89,39.43 71.58,39.43 68.33,22.7 37.35,22.88 37.05,20.52 72.2,20.31 75.92,39.43 75.89,39.43 83.9,71.86 83.9,25.41 75.89,25.41 75.38,22.8 74.33,17.39 34.01,17.62 34.69,22.8 34.7,22.89 29.64,22.92 27.2,22.93 27.93,28.53 19.9,28.53 21.44,36.19 24.24,36.19 23.31,31.56 60.83,31.56 62.41,39.43 65.21,39.43 63.02,28.53 "
          id="polygon63"
        />
        <polygon
          className="cls-1"
          points="12.94,38.8 13.53,42.04 10.08,42.04 21.48,77.61 81.65,77.61 72.86,42.04 34.22,42.04 29.18,38.8 "
          id="polygon65"
        />
      </g>
    </SvgIcon>
  );
};

export default SamplePortfolioIcon;
