import React from 'react';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button, CircularProgress, Tooltip } from '@mui/material';

export default function UploadButton({
  clickAction,
  sx = {},
  disabled = false,
  tooltipText = '',
  text = 'Upload',
  loading = false,
  variant = 'contained',
  size = 'small',
  extraBtnProps = {},
  type = 'submit',
}) {
  return (
    <Tooltip title={tooltipText} placement="top">
      <span>
        <Button
          disabled={disabled}
          variant={variant}
          size={size}
          type={type}
          sx={{ ...sx }}
          onClick={clickAction}
          startIcon={
            loading ? (
              <CircularProgress color="inherit" size={'1rem'} />
            ) : (
              <FileUploadIcon />
            )
          }
          {...extraBtnProps}
        >
          {text}
        </Button>
      </span>
    </Tooltip>
  );
}
