import { ERROR_MESSAGE_DEFAULT } from 'Constants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import PortfolioCard from './PortfolioCard';
import './styles.css';

import { Box, Grid, Stack } from '@mui/material';

import buildings from 'Api/buildings';

import InputSearch from 'Components/Mix/InputSearch';
import ViewEditSwitch from 'Components/Mix/ViewEditSwitch';
import PortfolioCardSkeleton from 'Components/Sites/PortfolioCardSkeleton';
import { isValidBuildingObj, useSitesData } from 'Components/Sites/utils';

import {
  ACCESS_LEVEL_ADMIN,
  ACCESS_LEVEL_EDITOR,
  ROLE_SAFETRACES,
} from 'Config/roles';

import { useIsMounted } from 'Context';

import { getDataFromResponse, getUserData, isValidResponse } from 'Utils';

export const BuildingsSitesContext = React.createContext({});

/**
 * **isFetching** is responsible for the buildingsData update indicator.
 * Sites data is not crucial for the component to work, so it can use background updates.
 * Mostly it is needed to prevent any user's actions during the after error data update.
 *
 * **handleError** is a function which expects the error message and an indicator,
 * whether global **buildingsData** should be updated from the server.
 * The whole component skips GET async updates after every action and change the data in place,
 * due to the drag & drop actions which aren't being saved anywhere on the server, yet
 * the order from which still must be preserved for the better UX whilst user's being on the page.
 *
 * **sitesData** is needed mostly for the 'edit' mode, so we can display the sites with no buildings,
 * and which we do not get from the data from buildings.
 *
 * **NOTE**: buildingsData is being sorted & grouped for childrens components use.
 * We do not want to do this very often, yet the Search component and some other actions
 * trigger the update (sorting & grouping) of the inner-prepared data.
 * Therefore, any small mutations used to minimise the amount of rerenders and stay consistent with the
 * data updates must update BOTH **buildingsData** and formed **portfolios** object.
 * **updateBuildingsData** triggers global change & rerenders and is better to use within important local updates.
 */
const Sites = ({
  sitesData,
  buildingsData,
  handleError,
  isFetching,
  updateBuildingsData,
  handleOpenFloorplanUploadModal,
  isInitialLoading,
}) => {
  const {
    portfolios,
    filledConfig,
    setSearchTerm,
    swapBuildingLocally,
    addBuildingLocally,
    deleteBuildingLocally,
  } = useSitesData(buildingsData, sitesData);

  const [viewMode, setViewMode] = useState(null);
  const mounted = useIsMounted();

  const { roleName, accessLevel } = getUserData();
  const editPermission =
    !!~[ACCESS_LEVEL_ADMIN, ACCESS_LEVEL_EDITOR].indexOf(accessLevel) &&
    !!~[ROLE_SAFETRACES].indexOf(roleName);

  useEffect(() => {
    if (editPermission) {
      const sessionViewMode = window.sessionStorage.getItem(`sites_page_mode`);
      const mode =
        (['view', 'edit'].includes(sessionViewMode) && sessionViewMode) ||
        'view';

      setViewMode(mode);
    }
  }, []);

  /**
   * This exist so we can pass global handlers to the children components,
   * which use components related specific to the single building, such as
   * delete building button.
   * We do this because we want to be able not to update data from server after
   * each action, so we won't lose the order of the buildings
   * within one site, which does not being saved anywhere, but must be
   * consistent for better UX.
   */
  const BuildingsContextHandler = useMemo(() => {
    return {
      afterBuildingAdd: (success, createdBuilding) => {
        /**
         * Building addition can end with two scenarios:
         *
         * 1. In case we successfully added the building, we want to update
         * local data inside our component (so it will display correct data) and
         * the data, coming from parent (so when our sorted data will be forming again)
         *
         * If something is wrong with adding building into our locally sorted & grouped data,
         * we can conclude that the local data has wrong format for some reason, so it is more safe to update
         * it with the data from the server (stability > smooth UI). Message is not being shown to the user,
         * because it has nothing to do with real functionality.
         * If everything is okay here, update parent's data.
         *
         * 2. In case we could not managed to add the building, just show an error and do not
         * update data from server (we assume that nothing have changed on server fail)
         * NOTE: in case of frequent duplicates creations, add the flag for force update in **handleError**.
         */
        if (success) {
          const newBuildingsData = addBuildingLocally(createdBuilding);
          if (!newBuildingsData) {
            if (typeof handleError === 'function') {
              handleError(``, true);
            }
          } else {
            if (mounted.current) {
              updateBuildingsData(newBuildingsData);
            }
          }
        } else {
          if (typeof handleError === 'function') {
            handleError(`Couldn't create building. Please, try again.`);
          }
        }
      },
      afterBuildingDelete: (success, buildingId) => {
        if (!success) return;
        /**
         * Success managing is very similar to the previos handler's mechanism,
         * yet the fail state is not being controlled, because there is a totally independent
         * component for the building deletion, which takes care of errors in place.
         */
        const newBuildingsData = deleteBuildingLocally(buildingId);

        if (!newBuildingsData) {
          if (typeof handleError === 'function') {
            handleError(``, true);
          }
        } else {
          if (mounted.current) {
            updateBuildingsData(newBuildingsData);
          }
        }
      },
      handleOpenFloorplanUploadModal: handleOpenFloorplanUploadModal,
    };
  }, [
    portfolios,
    filledConfig,
    updateBuildingsData,
    buildingsData,
    handleOpenFloorplanUploadModal,
  ]);

  const changeViewMode = useCallback((mode) => {
    if (!editPermission) return;

    window.sessionStorage.setItem(`sites_page_mode`, mode);
    setViewMode(mode);
  }, []);

  const onDragEnd = useCallback(
    (event) => {
      try {
        if (event.reason !== 'DROP') return;

        const { destination, source, draggableId } = event;

        if (!destination) return;

        const buildingId = parseInt(draggableId.replace(`building-`, ``), 10);

        const destinationSiteId = parseInt(
          destination.droppableId.match(/site-\d+/)[0].replace(`site-`, ``),
          10,
        );
        const sourceSiteId = parseInt(
          source.droppableId.match(/site-\d+/)[0].replace(`site-`, ``),
          10,
        );

        const sourceIndex = source.index;
        const destinationIndex = destination.index;

        if (!buildingId || !destinationSiteId || !sourceSiteId) {
          throw new Error(`Droppable fault ID`);
        }

        /** Case where building did not change its place */
        if (
          sourceSiteId === destinationSiteId &&
          sourceIndex === destinationIndex
        )
          return;

        /**
         * Local update for consistent UI.
         * On success we get two objects: one for local already sorted data update
         * and one for update parent's data (so the local data can be sorted and grouped
         * correctly in the future)
         */
        const { updatedBuilding, newBuildingsData } = swapBuildingLocally(
          buildingId,
          sourceSiteId,
          destinationSiteId,
          sourceIndex,
          destinationIndex,
        );

        /**
         * If building changes its site, then update on the server is needed.
         * The **updatedBuilding** object is used for the future data comparison with
         * the server's response: if it's not the same as server response,
         * we trigger update data from server as we conclude
         * that is something wrong with the local data then.
         * We also check, if the format of **updatedBuilding** matches the expected format,
         * before any server requests, and if it's not, then we also trigger the data update.
         *
         * However, we update all the data (component's and parent's) locally
         * before we recieve the response from server.
         *
         * NOTE: failed requests here trigger the update of the parent's data from server
         * (unlike add-delete actions where we assume that data hadn't change on fail)
         */
        if (sourceSiteId !== destinationSiteId) {
          if (isValidBuildingObj(updatedBuilding)) {
            const expectedResponseData = updatedBuilding;
            if (mounted.current) {
              updateBuildingsData(newBuildingsData);
            }
            updateBuildingSite(
              buildingId,
              destinationSiteId,
              expectedResponseData,
            );
          } else if (typeof handleError === 'function') {
            handleError(`Couldn't update building. Please, try again.`, true);
          }
        }
      } catch (err) {
        console.log(`onDragEnd Error: `, err);
      }
    },
    [portfolios, filledConfig],
  );

  const updateBuildingSite = useCallback(
    async (buildingId, siteId, expectedResponseData) => {
      try {
        const response = await buildings.linkBuildingSite(siteId, buildingId);
        if (isValidResponse(response)) {
          const data = getDataFromResponse(response);
          for (let key of Object.keys(expectedResponseData)) {
            if (expectedResponseData[key] !== data[key]) {
              throw new Error();
            }
          }
        }
      } catch (err) {
        if (typeof handleError === 'function') {
          handleError(ERROR_MESSAGE_DEFAULT, true);
        }
        console.log('Building site update error: ', err);
      }
    },
    [handleError],
  );

  return (
    <BuildingsSitesContext.Provider value={BuildingsContextHandler}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Stack spacing={2} mt={2} style={{ width: '90%' }}>
          <Stack direction="row">
            <Box
              sx={{ width: '360px', mr: 1, position: 'relative', top: '6px' }}
            >
              <InputSearch
                disabled={isInitialLoading}
                placeholder="Search"
                onChange={setSearchTerm}
              />
            </Box>
            {viewMode && (
              <ViewEditSwitch
                disabled={isInitialLoading}
                onChange={changeViewMode}
                value={viewMode}
              />
            )}
          </Stack>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            rowSpacing={2}
            columnSpacing={4}
            sx={{ position: 'relative', left: (theme) => theme.spacing(-4) }}
          >
            {isInitialLoading ? (
              <React.Fragment>
                <Grid item xs={6}>
                  <PortfolioCardSkeleton />
                </Grid>
                <Grid item xs={6}>
                  <PortfolioCardSkeleton />
                </Grid>
                <Grid item xs={6}>
                  <PortfolioCardSkeleton />
                </Grid>
                <Grid item xs={6}>
                  <PortfolioCardSkeleton />
                </Grid>
              </React.Fragment>
            ) : (
              portfolios?.map((p) => {
                if (
                  !p.sites ||
                  !p.sites.length ||
                  (viewMode !== 'edit' &&
                    !parseInt(
                      p.sites.reduce(
                        (amount, site) => amount + site.buildings?.length,
                        0,
                      ),
                      10,
                    ))
                )
                  return null;

                return (
                  <Grid item xs={6} key={p.id}>
                    <PortfolioCard
                      name={p.name}
                      sites={p.sites}
                      mode={viewMode || 'view'}
                      disableActions={!filledConfig || isFetching}
                      loading={isFetching}
                    />
                  </Grid>
                );
              })
            )}
          </Grid>
        </Stack>
      </DragDropContext>
    </BuildingsSitesContext.Provider>
  );
};

export default Sites;
