import React from 'react';
import { Controller } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

export default function SelectControl({
  control,
  errors,
  name,
  options,
  label,
  defaultValue = '',
  rules = {},
}) {
  return (
    <FormControl fullWidth error={!!(errors && errors[name])} margin="normal">
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, name, ref } }) => (
          <>
            <InputLabel id={`${name}_${label}_id`}>{label}</InputLabel>
            <Select
              onChange={onChange}
              inputRef={ref}
              labelId={`${name}_${label}_id`}
              id={`${name}_${label}_id_select`}
              label={label}
              defaultValue={defaultValue}
            >
              {options}
            </Select>
          </>
        )}
      />
      <FormHelperText>{errors[name]?.message}</FormHelperText>
    </FormControl>
  );
}
