import React from 'react';

import { Alert } from '@mui/material';

import { useDashboardContext } from 'Components/Dashboard/context';

export default function ErrorAlert() {
  const { errorMsg } = useDashboardContext();

  if (!errorMsg) return null;

  return <Alert severity="error">{errorMsg}</Alert>;
}
