import React from 'react';

import { TextField } from '@mui/material';

export default function DatePickerControl({
  register,
  isLoading,
  errors,
  name,
  label,
  defaultValue = '',
  inputProps = {},
}) {
  return (
    <TextField
      label={label}
      type="date"
      defaultValue={defaultValue}
      variant="outlined"
      margin="normal"
      fullWidth
      InputProps={inputProps}
      InputLabelProps={{
        shrink: true,
      }}
      name={name}
      id={name}
      {...register}
      error={!!(errors && errors[name])}
      helperText={errors[name]?.message}
      disabled={isLoading}
    />
  );
}
