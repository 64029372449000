import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import ApartmentIcon from '@mui/icons-material/Apartment';
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { isSafetracesAdmin } from 'Config/roles';
import { ROUTE_BUILDING_PROJECTS_RESULTS, ROUTE_TESTS } from 'Config/routes';

import { setCurrentBuildingName } from 'Features/Results/resultsSlice';
import { setTestsSuccess, testsSelector } from 'Features/Tests/testsSlice';

const ProjectsButtonStyledItem = ({ attributes }) => {
  const theme = useTheme();

  return (
    <Tooltip title="Navigate to verification projects" placement="top">
      <Typography
        component="div"
        {...attributes}
        sx={{
          borderRadius: '50%',
          width: 36,
          height: 36,
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:hover, &:focus': {
            backgroundColor: theme.palette.action.hover,
            cursor: 'pointer',
          },
          '&:active': {
            boxShadow: theme.shadows[1],
          },
        }}
      >
        <SvgIcon color="action" sx={{ fontSize: 24 }} {...attributes}>
          <path
            {...attributes}
            d="m16.24 11.51 1.57-1.57-3.75-3.75-1.57 1.57-4.14-4.13c-.78-.78-2.05-.78-2.83 0l-1.9 1.9c-.78.78-.78 2.05 0 2.83l4.13 4.13L3 17.25V21h3.75l4.76-4.76 4.13 4.13c.95.95 2.23.6 2.83 0l1.9-1.9c.78-.78.78-2.05 0-2.83l-4.13-4.13zm-7.06-.44L5.04 6.94l1.89-1.9L8.2 6.31 7.02 7.5l1.41 1.41 1.19-1.19 1.45 1.45-1.89 1.9zm7.88 7.89-4.13-4.13 1.9-1.9 1.45 1.45-1.19 1.19 1.41 1.41 1.19-1.19 1.27 1.27-1.9 1.9zm3.65-11.92c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.47-.47-1.12-.29-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
          ></path>
        </SvgIcon>
      </Typography>
    </Tooltip>
  );
};

export default function ResultsItem({ item }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentBuildingId } = useSelector(testsSelector);
  /**
   * Open results of building
   * @param buildingId
   */
  const navigateToBuildingResults = (event) => {
    const isProjectsPartClicked = !!event.target.getAttribute(
      'custom_attr_link_name',
    );

    dispatch(setCurrentBuildingName(item.buildingname));
    if (parseInt(item.buildingid) !== parseInt(currentBuildingId)) {
      dispatch(setTestsSuccess([]));
    }
    const _path = generatePath(
      isProjectsPartClicked ? ROUTE_BUILDING_PROJECTS_RESULTS : ROUTE_TESTS,
      { buildingId: item.buildingid },
    );

    /** This is for opening it in new tab through command + click or ctrl + click */
    if (event.metaKey || event.ctrlKey) {
      window.open(_path);
      return;
    }
    navigate(_path);
  };

  return (
    <ListItemButton
      onClick={navigateToBuildingResults}
      sx={{ maxWidth: '800px', borderRadius: 5 }}
    >
      <ListItemAvatar>
        <Avatar>
          <ApartmentIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={item.buildingname}
        secondary={`${item?.sitename || ''} ${item?.siteaddress || ''}`}
        secondaryTypographyProps={{ paddingRight: 2 }}
      />
      {isSafetracesAdmin() && item.hasVerificationHeatmap ? (
        <ListItemSecondaryAction sx={{ pl: 2 }}>
          <ProjectsButtonStyledItem
            attributes={{
              custom_attr_link_name:
                'buildingProjectsResults_' + item.buildingid,
            }}
          />
        </ListItemSecondaryAction>
      ) : null}
    </ListItemButton>
  );
}
