import {
  HEATMAP_HEALTH_CARE_LIKE_BEHAVIOR,
  HEATMAP_VERIFICATION_LIKE_BEHAVIOR,
  colorsFilterColors,
  colorsFilterLabels,
  colorsFilterValues,
} from 'Constants';
import React, { useEffect, useState } from 'react';

import './styles.css';

import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

import { StyledCheckedIcon } from 'Layout/layoutStyles';

function SignificanceIconSquare({ color }) {
  return (
    <div
      className="square-significance-icon"
      style={{
        background: color,
      }}
    />
  );
}

function SignificanceOptions({
  type = '',
  testType = '',
  onChange,
  values,
  width = 100,
  disabled,
}) {
  const [optionsType, setOptionsType] = useState(type);

  useEffect(() => {
    if (!optionsType && testType) {
      setOptionsType(
        ~HEATMAP_VERIFICATION_LIKE_BEHAVIOR.indexOf(testType) ||
          ~HEATMAP_HEALTH_CARE_LIKE_BEHAVIOR.indexOf(testType)
          ? 'verLike'
          : 'custom',
      );
    }
  }, [testType]);

  const handleCheckboxChange = (color, value) => {
    const resultObj = { ...values };
    resultObj[color] = value;

    onChange(resultObj, color);
  };

  if (!optionsType) return null;

  return Object.values(colorsFilterValues).map((color) => (
    <React.Fragment key={color}>
      <MenuItem
        onClick={() => handleCheckboxChange(color, !values[color])}
        key={color}
        value={color}
        label={colorsFilterLabels[optionsType][color]}
        disabled={disabled}
      >
        <div
          className="menu-item--significance-option"
          style={{
            width: `${width}%`,
          }}
        >
          <SignificanceIconSquare color={colorsFilterColors[color]} />
          <ListItemText
            primary={colorsFilterLabels[optionsType][color]}
            sx={{ mr: 'auto' }}
          />
          <Checkbox
            checked={values[color] || false}
            onChange={(e) => handleCheckboxChange(color, e.target.checked)}
            sx={{ ml: 'auto' }}
            checkedIcon={<StyledCheckedIcon checked />}
            icon={<StyledCheckedIcon />}
            disableRipple
          />
        </div>
      </MenuItem>
    </React.Fragment>
  ));
}

export default SignificanceOptions;
