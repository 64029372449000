import React, { useEffect, useRef, useState } from 'react';

import { useFloorplanContext } from '../context';
import { cropImageFromURL } from '../utils';
import PlaceableHDAItem from './PlaceableHDAItem';
import SelectableHDAItem from './SelectableHDAItem';

import { Box, CircularProgress } from '@mui/material';

const FloorPlanHDAImage = ({
  dropRef,
  imageData,
  boundingRef,
  HDAs,
  setScaledDimensions,
  isImageLoaded,
  handleClickNewHDACreate,
  setIsImageLoaded,
  markerSize = 3,
  hdaTypesInFp,
  type = 'placeable',
  HDAtypesByApplication: HDAtypesByApplicationProps,
  handleHdaSelect,
  imageSize,
  updateStatus,
  clearErrors,
}) => {
  const { HDAtypesByApplication: HDAtypesByApplicationContext } =
    useFloorplanContext();

  const [actualImageURL, setActiveImageUrl] = useState('');

  const coordsForHDARef = useRef(null);
  const interactionWithAnyPointRef = useRef(null);
  const isDraggingRef = useRef(false);

  useEffect(() => {
    if (imageData?.cropData) {
      cropImageFromURL(imageData.url, imageData.cropData, setActiveImageUrl);
    } else if (imageData?.url) {
      setActiveImageUrl(imageData.url);
    }
  }, [imageData?.url]);

  const resizeHandler = () => {
    if (boundingRef.current) {
      setScaledDimensions({
        width: boundingRef.current.getBoundingClientRect().width,
        height: boundingRef.current.getBoundingClientRect().height,
      });
    }
  };

  useEffect(() => {
    resizeHandler();
  }, [imageData]);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    const fpBox = document.getElementById('fp-box');

    const handleMouseMove = () => {
      coordsForHDARef.current = null;
      isDraggingRef.current = true;
    };

    fpBox.addEventListener(`mousedown`, (e) => {
      coordsForHDARef.current = {
        x: e.offsetX,
        y: e.offsetY,
      };
      isDraggingRef.current = false;
      fpBox.addEventListener('mousemove', handleMouseMove);
    });
    fpBox.addEventListener(`mouseup`, (e) => {
      fpBox.removeEventListener('mousemove', handleMouseMove);
      setTimeout(() => {
        if (coordsForHDARef.current && !isDraggingRef.current) {
          const { x, y } = coordsForHDARef.current;
          if (x === e.offsetX && y === e.offsetY) {
            if (interactionWithAnyPointRef.current) {
              const inputEl = document.getElementById(
                `hda-item-name-input-${interactionWithAnyPointRef.current}`,
              );
              if (inputEl) {
                if (typeof clearErrors === 'function') {
                  clearErrors();
                }
                inputEl.focus();
              }

              interactionWithAnyPointRef.current = null;
            } else {
              handleClickNewHDACreate(x, y);
            }
            coordsForHDARef.current = null;
          }
        }
      }, 0);
    });
    return () => {
      window.removeEventListener('resize', resizeHandler);
      fpBox.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const renderScaledPoints = () => {
    if (!isImageLoaded) {
      return (
        <Box
          sx={{
            display: 'flex',
            w: '100%',
            h: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      );
    }
    const atLeastOneSelected = HDAs.some((hda) => hda.selected);
    return (
      <React.Fragment>
        {HDAs.map((hda) =>
          type === 'placeable' ? (
            <PlaceableHDAItem
              key={hda.id}
              colorIndex={hdaTypesInFp.indexOf(hda.type) % 10}
              hda={hda}
              markerSize={markerSize}
              updateInProgress={updateStatus === 'loading'}
              HDAtypesByApplication={
                HDAtypesByApplicationContext || HDAtypesByApplicationProps
              }
              handleClick={() => {
                interactionWithAnyPointRef.current = hda.id;
              }}
            />
          ) : (
            <SelectableHDAItem
              key={hda.id}
              colorIndex={hdaTypesInFp.indexOf(hda.type) % 10}
              hda={hda}
              markerSize={markerSize}
              updateInProgress={updateStatus === 'loading'}
              HDAtypesByApplication={
                HDAtypesByApplicationContext || HDAtypesByApplicationProps
              }
              handleHdaSelect={() => handleHdaSelect(hda.id)}
              selectionColorOverride={atLeastOneSelected}
              isSelected={hda.selected}
            />
          ),
        )}
      </React.Fragment>
    );
  };

  return (
    <Box
      position="relative"
      style={{ width: 'fit-content', height: 'fit-content' }}
    >
      <img
        src={actualImageURL}
        alt="active floorplan"
        width={imageSize ? imageSize.width : '100%'}
        height={imageSize ? imageSize.height : '100%'}
        onLoad={() => {
          if (boundingRef.current) {
            setScaledDimensions({
              width: boundingRef.current.getBoundingClientRect().width,
              height: boundingRef.current.getBoundingClientRect().height,
            });
            setIsImageLoaded(true);
          }
        }}
        style={{
          visibility: isImageLoaded ? 'visible' : 'hidden',
        }}
        ref={boundingRef}
      />
      <Box
        id="fp-box"
        ref={dropRef}
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={50}
      >
        {renderScaledPoints()}
      </Box>
    </Box>
  );
};

export default FloorPlanHDAImage;
