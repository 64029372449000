import React from 'react';

import '../styles.css';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

const NotesTable = ({ rowsAmount, header }) => {
  const EmptyRows = () => {
    return Array.from({ length: rowsAmount }, (v, i) => (
      <TableRow key={v || i} sx={{ height: '32px' }}>
        <TableCell align="left" />
      </TableRow>
    ));
  };

  return (
    <TableContainer className="projectConfiguration-standartTable--container">
      <Table size="medium" aria-label="notes">
        <TableHead>
          <TableRow>
            <TableCell align="left">{header}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{EmptyRows()}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(NotesTable);
