import React from 'react';

import { Box, Checkbox, FormControlLabel, Stack } from '@mui/material';

// import SplitButton from 'Components/Buttons/SplitButton';

export default function ScalingSavingsControlsSection({
  disabled,
  settings,
  updateSettings,
  // canReset,
  // handleReset,
}) {
  return (
    <Stack spacing={2} direction="column" sx={{ mb: 2 }}>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={settings.shouldSaveViewParams}
              onChange={(e) => {
                updateSettings((prev) => {
                  return {
                    ...prev,
                    shouldSaveViewParams: e.target.checked,
                    saveForAllHeatmapsInATest: e.target.checked
                      ? false
                      : prev.saveForAllHeatmapsInATest,
                  };
                });
              }}
              disabled={disabled}
            />
          }
          label="Save scaling for current heatmaps"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={settings.saveForAllHeatmapsInATest}
              onChange={(e) => {
                updateSettings((prev) => {
                  return {
                    ...prev,
                    saveForAllHeatmapsInATest: e.target.checked,
                    shouldSaveViewParams: e.target.checked
                      ? false
                      : prev.shouldSaveViewParams,
                  };
                });
              }}
              disabled={disabled}
            />
          }
          label="Save scaling for all test heatmaps in the test"
        />
      </Box>
      {/* <Collapse in={canReset}>
        <Box sx={{ display: 'flex', justifyContent: 'start' }}>
          <SplitButton
            buttonGroupProps={{
              color: 'error',
              variant: 'outlined',
              size: 'small',
              disabled,
            }}
            options={['Reset scaling', 'Reset scaling for the test']}
            actions={[() => handleReset('single'), () => handleReset('all')]}
          />
        </Box>
      </Collapse> */}
    </Stack>
  );
}
