import { SCENARIOS_HEALTHCARE_DATA_CONFIG_TABLE } from './config';

// WARNING: all the functions below are sutable only for healthcare tests

export const normaliseScenariosData = (
  scenarios,
  originPoints,
  segments,
  samples,
) => {
  const scenariosObj = {};

  for (let {
    scenarioid: scenarioId,
    scenariodescription: description,
    scenarioname: internalName,
    segmentid: segmentId,
    scenarioexecuted: scenarioExecuted,
  } of scenarios) {
    // NOTE:
    // internal name is something we can not change
    // because a lot of other things depend on it
    // so for the ability to change the name we will use displayName
    // which is now stored in testMetadata

    /**
     * 1. Fill in initial data from the scenario itself
     */
    scenariosObj[scenarioId] = {
      scenarioId,
      segmentId,
      description,
      internalName,
      scenarioExecuted,
    };

    /**
     * 2. Deal with the parts of the data that come from the segments
     */
    const segment = segments.find(
      (item) => parseInt(item.segmentid) === parseInt(segmentId),
    );
    const { surveydate, testmetadata: testMetadata, op: opAmount } = segment;
    scenariosObj[scenarioId].opCount = opAmount;

    /**
     * 3. Deal with the data that comes from test's metadata
     * (!! only for healthcare tests as other tests don't have metadata !!)
     * NOTE: this is a temporary solution
     */
    const scenarioConfigFromTestMetadata =
      testMetadata[segmentId].scenariosData[internalName];

    const keysFromTestMetadata = Object.values(
      SCENARIOS_HEALTHCARE_DATA_CONFIG_TABLE,
    ).reduce((res, item) => {
      if (item.dataOrigin === 'testMetadata') {
        res.push(item.key);
      }
      return res;
    }, []);

    for (let key of keysFromTestMetadata) {
      // NOTE: Quick fix to get the selector Knob ACH setting on the scenarios table. Needs to be updated
      if (key === 'selectorKnobAchSetting') {
        const totalACH = getTotalACH(
          scenarioConfigFromTestMetadata.PEC_ACH,
          scenarioConfigFromTestMetadata.HVAC_ACH,
        );
        const achRange = getACHRange(totalACH);
        scenariosObj[scenarioId][key] = `${achRange[0]} - ${achRange[1]}`;
      } else {
        scenariosObj[scenarioId][key] =
          scenarioConfigFromTestMetadata[key] ?? '';
      }
    }

    scenariosObj[scenarioId].displayName =
      scenariosObj[scenarioId].displayName || internalName;
    scenariosObj[scenarioId].testDate =
      scenariosObj[scenarioId].testDate || surveydate;

    /**
     * 4. Deal with the data that comes from origin points
     */
    const normalizedOPs = normaliseOriginPointsData(originPoints);
    const opConfigKeys =
      opAmount === 2 ? ['op001Tag', 'op002Tag'] : ['op001Tag'];
    for (let configKey of opConfigKeys) {
      const { opKeyInNormalizedData, key } =
        SCENARIOS_HEALTHCARE_DATA_CONFIG_TABLE[configKey];
      scenariosObj[scenarioId][key] =
        normalizedOPs[opKeyInNormalizedData]?.scenariosTags[scenarioId]?.tag ||
        '';
    }

    /**
     * 5. Deal with the data that comes from samples (sample numbers for sample points)
     */
    const normalisedSamples = normaliseSamplesData(samples);
    for (let configKey of [
      'sp001_sampleNumbers',
      'sp002_sampleNumbers',
      'sp003_sampleNumbers',
      'sp004_sampleNumbers',
      'sp005_sampleNumbers',
    ]) {
      const { spKeyInNormalizedData, key } =
        SCENARIOS_HEALTHCARE_DATA_CONFIG_TABLE[configKey];

      scenariosObj[scenarioId][key] = formatSampleNumbersDisplay(
        normalisedSamples[scenarioId][spKeyInNormalizedData].sampleNumbers,
      );
    }
  }

  return scenariosObj;
};

/** Groups samples into an object by Origin Point Number key
 * Solution is sutable only for healthcare tests
 */
export const normaliseOriginPointsData = (originPoints) => {
  try {
    const originPointsObj = {};

    for (let OP of originPoints) {
      const { opnumber, segmentid, opid, tags } = OP;
      // NOTE: do now follow the same logic for any other test types
      // as opnumber is special only for healthcare tests

      const scenariosTags = Array.isArray(tags)
        ? tags.reduce((res, { scenarioId, tag }) => {
            res[scenarioId] = {
              tag,
              scenarioId,
            };
            return res;
          }, {})
        : {};

      originPointsObj[opnumber] = {
        id: opid,
        standartizedNumber: opnumber,
        segmentId: segmentid,
        scenariosTags,
      };
    }

    return originPointsObj;
  } catch (err) {
    console.error(err);
    return {};
  }
};

/** Groups samples into an object by ScenarioId key &=> Sample Point Number key
 * Solution is sutable only for healthcare tests
 */
export const normaliseSamplesData = (samples) => {
  try {
    const samplesObj = {};
    for (let sample of samples) {
      const {
        scenarioid: scenarioId,
        spnumber: spNumber,
        samplenumber: sampleNumber,
      } = sample;
      if (!samplesObj[scenarioId]) {
        samplesObj[scenarioId] = {};
      }

      if (!samplesObj[scenarioId][spNumber]) {
        samplesObj[scenarioId][spNumber] = {
          sampleNumbers: [],
        };
      }

      if (
        !samplesObj[scenarioId][spNumber].sampleNumbers.includes(sampleNumber)
      ) {
        samplesObj[scenarioId][spNumber].sampleNumbers.push(sampleNumber);
      }
    }
    return samplesObj;
  } catch (err) {
    return {};
  }
};

const formatSampleNumbersDisplay = (sampleNumbersArr) => {
  if (!Array.isArray(sampleNumbersArr)) {
    return '';
  }
  if (sampleNumbersArr.length === 1) {
    return sampleNumbersArr[0];
  }

  const sortedSampleNumbersArr = sampleNumbersArr.sort((a, b) => a - b);
  return `${sortedSampleNumbersArr[0]} to ${
    sortedSampleNumbersArr[sortedSampleNumbersArr.length - 1]
  }`;
};

export const extractTagsInfoFromScenariosData = (data) => {
  if (!data) return;

  const tagsUsed = [];
  const tagsUsedByScenarios = {};

  for (let [scenarioId, scenarioData] of Object.entries(data)) {
    const { op002Tag, op001Tag } = scenarioData;
    tagsUsedByScenarios[scenarioId] = [];

    if (op002Tag) {
      tagsUsedByScenarios[scenarioId].push(op002Tag);
      if (!tagsUsed.includes(op002Tag)) {
        tagsUsed.push(op002Tag);
      }
    }
    if (op001Tag) {
      tagsUsedByScenarios[scenarioId].push(op001Tag);
      if (!tagsUsed.includes(op001Tag)) {
        tagsUsed.push(op001Tag);
      }
    }
  }

  return [tagsUsed, tagsUsedByScenarios];
};

/**
 * Returns the total ACH value - sum of PEC ACH and HVAC ACH
 * @param {number} PEC_ACH
 * * @param {number} HVAC_ACH
 * @returns {Number} totalACH
 */
export const getTotalACH = (PEC_ACH, HVAC_ACH) => {
  let totalACH = 0;
  if (parseFloat(PEC_ACH)) {
    totalACH += parseFloat(PEC_ACH);
  }
  if (parseFloat(HVAC_ACH)) {
    totalACH += parseFloat(HVAC_ACH);
  }
  return totalACH;
};

/**
 * Returns an array of 2 numbers that represent the range of ACH
 * @param {number} ACH
 * @returns {Number[]} [min, max]
 */
export const getACHRange = (ACH = 4.61) => {
  switch (true) {
    case ACH <= 8:
      return [1, 8];
    case ACH > 8 && ACH <= 16:
      return [8, 16];
    case ACH > 16 && ACH <= 24:
      return [16, 24];
    case ACH > 24 && ACH <= 60:
      return [24, 60];
    case ACH > 60:
      return [60, 100];
    default:
      // change later
      return [0, 0];
  }
};
