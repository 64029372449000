import React from 'react';

import './styles.css';

import DownloadIcon from '@mui/icons-material/Download';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';

import { useDashboardContext } from 'Components/Dashboard/context';

export default function ChartTitle({ titleText, chartId }) {
  const { handleDownloadPNGChart, handleDownloadSlide, isLoading } =
    useDashboardContext();

  return (
    <Stack direction="row" gap={1}>
      <Typography
        className="noselect"
        variant="h6"
        sx={{ textAlign: 'center' }}
      >
        {titleText}
      </Typography>
      <Tooltip title={!isLoading ? 'Download PNG chart' : ''} placement="top">
        <span>
          <IconButton
            size="small"
            onClick={() => handleDownloadPNGChart(chartId)}
            disabled={isLoading}
          >
            <DownloadIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip
        title={!isLoading ? 'Download presentation slide' : ''}
        placement="top"
      >
        <span>
          <IconButton
            size="small"
            onClick={() => handleDownloadSlide(chartId)}
            disabled={isLoading}
          >
            <SystemUpdateAltIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Stack>
  );
}
