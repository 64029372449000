import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Typography } from '@mui/material';

import BreadcrumbsFloorplanHDA from 'Components/Breadcrumbs/BreadcrumbsFloorplanHDA';
import HDAPlacement from 'Components/Floorplans/HDAPlacement';
import LoadingAndAlerts from 'Components/Floorplans/LoadingAndAlerts';
import ParameterProvider from 'Components/Floorplans/ParameterProvider';
import { FloorplanContextProvider } from 'Components/Floorplans/context';

const FloorplanHDAPlacement = () => {
  const { floorplanId } = useParams();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const { projectId, buildingId, testId } = params;

  return (
    <FloorplanContextProvider>
      <ParameterProvider
        floorplanId={floorplanId}
        projectId={projectId}
        buildingId={buildingId}
        testId={testId}
      />
      <LoadingAndAlerts />
      <BreadcrumbsFloorplanHDA />
      <Typography variant="h4" sx={{ mb: 2 }}>
        Floor Plan HDA
      </Typography>
      <HDAPlacement />
    </FloorplanContextProvider>
  );
};

export default FloorplanHDAPlacement;
