import { format, parseISO } from 'date-fns';
import moment from 'moment';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SCENARIOS_HEALTHCARE_DATA_CONFIG_TABLE } from '../config';
import { useTestScenariosContext } from '../context';

import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { isSafetracesAdmin } from 'Config/roles';

/** TODO: redo this to refs & more config way if it'll stay with us */
const HealthcareSettingsForm = forwardRef(
  ({ onSubmit, defaultValues, focusField, type = 'regular' }, ref) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      setFocus,
    } = useForm({
      defaultValues: defaultValues || {},
    });
    const {
      currentTestType,
      tagsUsed,
      tagsUsedByScenario,
      forceRestrictAllEdits,
    } = useTestScenariosContext();

    const disabledFields =
      !(isSafetracesAdmin() && type === 'restricted') && forceRestrictAllEdits;
    // TODO: redo to refs
    const [testDate, setTestDate] = useState();
    const [testTime, setTestTime] = useState();

    const unusedTags = useMemo(() => {
      const result = [];
      const allTags = [
        `Tag-C1`,
        `Tag-C2`,
        `Tag-C3`,
        `Tag-C4`,
        `Tag-C5`,
        `Tag-C6`,
        `Tag-C7`,
        `Tag-C8`,
        `Tag-B1`,
        `Tag-B2`,
        `Tag-B3`,
        `Tag-B4`,
        `Tag-B5`,
        `Tag-B6`,
        `Tag-B7`,
        `Tag-B8`,
      ];

      for (let tag of allTags) {
        if (!tagsUsed.includes(tag)) {
          result.push(tag);
        }
      }
      if (defaultValues?.scenarioId) {
        const tagsInThisScenario = tagsUsedByScenario[defaultValues.scenarioId];
        result.push(...tagsInThisScenario);
      }

      result.sort();

      return result;
    }, [tagsUsed, tagsUsedByScenario]);

    useEffect(() => {
      if (focusField) {
        setFocus(focusField);
      }

      if (defaultValues?.testDate) {
        setTestDate(parseISO(defaultValues?.testDate));
      } else {
        setTestDate(new Date());
      }

      if (defaultValues?.testStartTime) {
        const time = moment(defaultValues?.testStartTime, 'hh:mm A').toDate();
        setTestTime(time);
      } else {
        setTestTime(new Date());
      }
    }, []);

    const renderFormFields = () => {
      const result = [];

      for (let [fieldKey, fieldConfig] of Object.entries(
        SCENARIOS_HEALTHCARE_DATA_CONFIG_TABLE,
      )) {
        const {
          key,
          label,
          editable,
          valueAsNumber,
          specialValidator,
          requiredInForm,
          isDate,
          dateType,
          allowedTestTypes,
          presentInRestrictedForm,
        } = fieldConfig;

        const shouldSkip =
          allowedTestTypes && !allowedTestTypes.includes(currentTestType);

        if (
          !editable ||
          shouldSkip ||
          (type === 'restricted' && !presentInRestrictedForm)
        )
          continue;

        if (isDate) {
          let component = null;

          switch (dateType) {
            case 'date':
              component = (
                <DatePicker
                  key={fieldKey}
                  label={label}
                  name={fieldKey}
                  inputFormat={'MMM do, yyyy'}
                  value={testDate}
                  onChange={(date) => setTestDate(date)}
                  disabled={disabledFields}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      margin="dense"
                      fullWidth
                      disabled={disabledFields}
                      {...params}
                    />
                  )}
                />
              );
              break;
            case 'time':
              component = (
                <TimePicker
                  key={fieldKey}
                  label={label}
                  name={fieldKey}
                  value={testTime}
                  onChange={(time) => setTestTime(time)}
                  disabled={disabledFields}
                  inputFormat={'hh:mm a'}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      margin="dense"
                      fullWidth
                      disabled={disabledFields}
                      {...params}
                    />
                  )}
                />
              );

              break;
            default:
              break;
          }

          if (component) {
            result.push(component);
          }
          continue;
        }

        if (~['op001Tag', 'op002Tag'].indexOf(fieldKey)) {
          const _label =
            typeof label === 'object' ? label[currentTestType] || label : label;

          const allowedTags = unusedTags.filter((tag) =>
            tag.includes(fieldKey === 'op001Tag' ? 'Tag-C' : 'Tag-B'),
          );

          const component = (
            <TextField
              key={fieldKey}
              variant="standard"
              margin="dense"
              label={_label}
              defaultValue={defaultValues?.[key] || ''}
              select
              disabled={disabledFields}
              sx={{ width: '100%' }}
              {...register(key, {
                required: requiredInForm && `${label} is required`,
              })}
              error={!!(errors && errors[key])}
              helperText={errors?.[key]?.message}
            >
              {allowedTags.map((value) => (
                <MenuItem key={value} value={value}>
                  <Typography>{value}</Typography>
                </MenuItem>
              ))}
            </TextField>
          );

          result.push(component);

          continue;
        }

        result.push(
          <TextField
            key={fieldKey}
            variant="standard"
            margin="dense"
            label={label}
            fullWidth
            disabled={disabledFields}
            {...register(key, {
              required: requiredInForm && `${label} is required`,
              valueAsNumber: valueAsNumber,
              validate: (value) =>
                specialValidator ? specialValidator(value) : true,
            })}
            error={!!(errors && errors[key])}
            helperText={errors?.[key]?.message}
          />,
        );
      }

      return result;
    };

    const submit = (vals) => {
      const newDate = format(testDate, 'yyyy-MM-dd').toString();
      const newTime = testTime.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });

      if (typeof onSubmit === 'function') {
        vals.testDate = newDate;
        vals.testStartTime = newTime;

        onSubmit(vals);
      }
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <form ref={ref} onSubmit={handleSubmit(submit)}>
          <Grid container spacing={4}>
            <Grid item>{renderFormFields()}</Grid>
          </Grid>
        </form>
      </LocalizationProvider>
    );
  },
);

HealthcareSettingsForm.displayName = 'HealthcareSettingsForm';

export default HealthcareSettingsForm;
