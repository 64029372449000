import React from 'react';
import { useDispatch } from 'react-redux';

import { setPageTitle } from '../../Features/Main/mainSlice';

import TagsComponent from 'Components/Tags/TagsComponent';

const TagsPage = () => {
  const globalDispatch = useDispatch();
  globalDispatch(setPageTitle('Tags - Admin'));
  return (
    <div>
      <TagsComponent />
    </div>
  );
};

export default TagsPage;
