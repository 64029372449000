import { COMPANY_TYPES } from 'Constants';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Save } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import contractsApi from 'Api/contracts';

import { usePortfolioContext } from 'Components/Portfolios/context';

import { useIsMounted } from 'Context';

import {
  getCompanies,
  getDataFromResponse,
  getErrorMessageFromResponse,
  isValidResponse,
} from 'Utils';

const EditSiteContract = ({ id, cancel }) => {
  const [siteContract, setSiteContract] = useState(null);
  const [partners, setPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { handleSubmit, register, formState } = useForm();
  const { fetchPortfoliosAndSites } = usePortfolioContext();

  const mounted = useIsMounted();

  useEffect(() => {
    const _partners = getCompanies().reduce((res, company) => {
      if (company.type === COMPANY_TYPES.Partner) {
        const { companyname, companyid } = company;
        res.push({
          id: companyid,
          name: companyname,
        });
      }
      return res;
    }, []);
    setPartners(_partners);

    if (id) {
      setIsLoading(true);
      fetchSiteContractById(id);
    }
  }, []);

  const fetchSiteContractById = async (id) => {
    const response = await contractsApi.getSiteContract(id);

    const data = getDataFromResponse(response);

    if (mounted.current) {
      setSiteContract(data ?? null);
      setIsLoading(false);
    }
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    setError(null);

    contractsApi
      .updateSiteContract(id, data)
      .then((response) => {
        if (isValidResponse(response)) {
          fetchPortfoliosAndSites();
          cancel();
        } else {
          throw new Error(getErrorMessageFromResponse(response));
        }
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderForm = () => {
    if (siteContract && !isLoading) {
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <TextField
              id="site-contract-id"
              fullWidth
              required
              label="Site contract name"
              margin="normal"
              defaultValue={siteContract?.sitecontractname ?? ''}
              {...register('sitecontractname')}
            />
            <TextField
              select
              variant="standard"
              margin="dense"
              fullWidth
              label="Partner"
              defaultValue={siteContract?.partner ?? ''}
              {...register('partner')}
              SelectProps={{
                MenuProps: { style: { maxHeight: '280px' } },
              }}
            >
              {partners.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              startIcon={<Save />}
              disabled={!formState.isDirty}
              type="submit"
            >
              Save
            </Button>
            <ButtonGroup>
              <Button onClick={cancel}>Cancel</Button>
            </ButtonGroup>
          </Stack>
        </form>
      );
    }
    return null;
  };

  return (
    <Stack p={2} spacing={2}>
      {isLoading && <LinearProgress />}
      <Typography variant="h5">Edit site contract {id}</Typography>
      <Box py={2}>{renderForm()}</Box>
      {!!error && <Alert severity="error">{error}</Alert>}
    </Stack>
  );
};

export default EditSiteContract;
