import React from 'react';
import { useParams } from 'react-router-dom';

import { Stack } from '@mui/material';

import BreadcrumbForProject from 'Components/Breadcrumbs/ProjectsBreadcrumbs/BreadcrumbForProject';
import EditTestDetailsModal from 'Components/SingleProject/Main/Modals/EditTestDetailsModal';
import ProjectActions from 'Components/SingleProject/Main/ProjectActions';
import ProjectDetailsView from 'Components/SingleProject/Main/ProjectDetailsView';
import ProjectTagsLotNumbersSection from 'Components/SingleProject/Main/ProjectTagsLotNumbersSection';
import ProjectTagsSelector from 'Components/SingleProject/Main/ProjectTagsSelector';
import ProjectTestsSection from 'Components/SingleProject/Main/ProjectTestsSection';
import ProjectTitle from 'Components/SingleProject/ProjectTitle';
import { ProjectContextProvider } from 'Components/SingleProject/context';

export default function ProjectMain() {
  const { projectId } = useParams();

  return (
    <ProjectContextProvider
      value={{
        projectId,
      }}
    >
      <EditTestDetailsModal />
      <BreadcrumbForProject />
      <ProjectTitle />
      <ProjectActions />
      <ProjectDetailsView />
      <Stack direction="column">
        <ProjectTestsSection />
        <ProjectTagsSelector />
        <ProjectTagsLotNumbersSection />
      </Stack>
    </ProjectContextProvider>
  );
}
