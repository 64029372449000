import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Save } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import tests from 'Api/tests';

import { areaSectionConfig } from 'Components/SingleTest/config';

const EditArea = ({ cancel, testId, reFetchProject, buildingData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [validationMethod, setValidationMethod] = useState({
    valFunc: (val) => Number(val) || `Value must be a number`,
  });

  const {
    handleSubmit,
    register,
    formState,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (typeof areaSectionConfig[buildingData.type] !== 'undefined') {
      setValidationMethod({
        valFunc:
          areaSectionConfig[buildingData.type].form.restrict.range_validator
            .sq_ft,
      });
    }
  }, []);

  const onSubmit = (data) => {
    const obj = { ...buildingData };

    obj.sq_ft = data.sq_ft;
    obj.celling_height = data.celling_height;

    setIsLoading(true);
    tests
      .updateOneTest(testId, {
        buildingData: obj,
      })
      .then(() => {
        reFetchProject();
        cancel();
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderForm = () => {
    if (!isLoading) {
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              fullWidth
              label="Sq.ft."
              defaultValue={
                buildingData.sq_ft !== 'null' ? buildingData.sq_ft : ''
              }
              {...register('sq_ft', {
                validate: { lessThanTen: validationMethod.valFunc },
              })}
              error={!!(errors && errors.sq_ft)}
              helperText={errors?.sq_ft?.message}
            />
            <br />
            <TextField
              variant="outlined"
              fullWidth
              label="Ceiling Height, ft"
              defaultValue={
                buildingData.celling_height !== 'null'
                  ? buildingData.celling_height
                  : ''
              }
              {...register('celling_height', {
                validate: (v) => Number(v) || `Value must be a number`,
              })}
              error={!!(errors && errors.celling_height)}
              helperText={errors?.celling_height?.message}
            />
          </FormControl>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              startIcon={<Save />}
              disabled={!formState.isDirty}
              type="submit"
            >
              Save
            </Button>
            <ButtonGroup>
              <Button onClick={cancel}>Cancel</Button>
            </ButtonGroup>
          </Stack>
        </form>
      );
    }
    return null;
  };

  return (
    <Stack p={2} spacing={2}>
      {isLoading && <LinearProgress />}
      <Typography variant="h5">Edit test area</Typography>
      <Box py={2}>{renderForm()}</Box>
      {!!error && (
        <Alert severity="error">Error when updating the test area</Alert>
      )}
    </Stack>
  );
};

export default EditArea;
