import React from 'react';
import { Link } from 'react-router-dom';

import CheckIcon from '@mui/icons-material/Check';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Paper,
  TableRow,
  TableSortLabel,
  Toolbar,
} from '@mui/material';
import Menu from '@mui/material/Menu';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import tableSortLabelClasses from '@mui/material/TableSortLabel/tableSortLabelClasses';
import { alpha, styled } from '@mui/material/styles';

export const drawerWidth = 240;

export const disableColor = '#adadad';
export const whiteColor = '#ffffff';
export const nearWhiteGreenColor = '#F7FFEDFF';
export const nearWhiteRedColor = '#fff4f4';
export const yellowColor = '#ffc400';
export const redColor = '#d32f2f';
export const greenColor = '#89c541';
export const nearBlackColor = '#282828';

export const PrimaryBorderButton = styled(Button)(({ theme, disabled }) => ({
  border: `solid 1px ${!disabled ? theme.palette.primary.main : disableColor}`,
  '&:hover': {
    backgroundColor: nearWhiteGreenColor,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

export const IconButtonLink = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  padding: 3,
  marginTop: 3,
  marginBottom: 3,
  '&:hover': {
    backgroundColor: theme.palette.grey.main,
  },
}));

export const SecondaryButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  border: 0,
  color: whiteColor,
  '&:hover': {
    backgroundColor: greenColor,
    border: 0,
  },
}));

export const LinkBlackColor = styled(Link)(() => ({
  color: nearBlackColor,
  '&:hover': {
    textDecoration: 'none',
  },
}));

export const RedBorderButton = styled(Button)(({ disabled }) => ({
  border: `solid 1px ${!disabled ? redColor : disableColor}`,
  color: redColor,
  '&:hover': {
    backgroundColor: nearWhiteRedColor,
    borderColor: redColor,
  },
}));

export const AppBarStyled = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: whiteColor,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export const DisabledBorderButton = styled(Button)(() => ({
  border: `solid 1px ${disableColor}`,
  color: whiteColor,
  '&:hover': {
    backgroundColor: whiteColor,
    borderColor: disableColor,
  },
}));

export const YellowBorderButton = styled(Button)(() => ({
  color: yellowColor,
  border: `solid 1px ${yellowColor}`,
  '&:hover': {
    backgroundColor: '#fff6ce',
    borderColor: '#e7b102',
  },
}));

export const ToolbarIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '0 8px',
  gap: '6px',
  ...theme.mixins.toolbar,
}));

export const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: theme.spacing(1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const DrawerStyled = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  position: 'absolute',
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginTop: '80px',
      overflowX: 'hidden',
    },
  }),
  ...(!open && {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      display: 'none',
    },
    width: `calc(${theme.spacing(7)} + 100px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(9)} + 1px)`,
    },
    '& .MuiDrawer-paper': {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      marginTop: '80px',
      width: `calc(${theme.spacing(7)} + 1px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
      },
    },
  }),
}));

export const MainStyled = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  flexGrow: 1,
  maxWidth: 'calc(100vw - 24px)',
  padding: theme.spacing(3),
  paddingLeft: 100,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export const DrawerHeaderStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(1),
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export const SecondaryBorderButton = styled(Button)(
  ({ theme, disable_styles }) =>
    !disable_styles
      ? {
          border: `solid 1px ${theme.palette.secondary.main}`,
          color: theme.palette.secondary.main,
          '&:hover': {
            backgroundColor: nearWhiteGreenColor,
            borderColor: theme.palette.secondary.main,
          },
        }
      : {},
);

export const BreadcrumbSubLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.black.main,
  whiteSpace: 'nowrap',
}));

export const BreadcrumbMainLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
}));

export const DropDownMenuBox = styled(Box)(({ theme }) => ({
  zIndex: 1000,
  position: 'absolute',
  top: 25,
  left: -20,
  backgroundColor: 'white',
  borderColor: 'grey',
  margin: theme.spacing(1),
  paddingRight: theme.spacing(1),
  border: 1,
  borderRadius: 3,
  borderStyle: 'solid',
}));

export const StyledTableCell = styled(TableCell)(
  ({ theme, widthcol, headertype = 'primary' }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor:
        headertype === 'secondary' ? whiteColor : theme.palette.primary.main,
      color: theme.palette.common.white,
      width: widthcol ? `calc((100% / 24) * ${widthcol})` : 'unset',
      maxWidth: widthcol ? `calc((100% / 24) * ${widthcol})` : 'unset',
      minWidth: widthcol ? `calc((100% / 24) * ${widthcol})` : 'unset',
    },
    [`&.${tableCellClasses.root}`]: {
      width: widthcol ? `calc((100% / 24) * ${widthcol})` : 'unset',
      maxWidth: widthcol ? `calc((100% / 24) * ${widthcol})` : 'unset',
      minWidth: widthcol ? `calc((100% / 24) * ${widthcol})` : 'unset',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }),
);

export const StyledTestTableCell = styled(TableCell)(
  ({ theme, widthcol, headertype = 'primary' }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor:
        headertype === 'secondary' ? whiteColor : theme.palette.primary.main,
      color: theme.palette.common.white,
      width: widthcol ? `calc((100% / 24) * ${widthcol})` : 'unset',
      '@media (min-width: 780px)': {
        width: '140px',
      },
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }),
);

export const StyledTableRow = styled(TableRow)(
  ({ theme, coloring = 'odd', disablecoloring, border = 'false' }) => ({
    [`&:nth-of-type(${coloring})`]: {
      backgroundColor: !disablecoloring
        ? theme.palette.action.hover
        : whiteColor,
    },
    // hide last border
    '&:last-child td, &:last-child th':
      border === 'false'
        ? {
            border: 0,
          }
        : {},
  }),
);

export const ProjectsStyledTableSortLabel = styled(TableSortLabel)(() => ({
  textDecoration: 'underline',
  '&:hover': {
    color: 'rgba(255,255,255,0.8)',
  },
  [`&.${tableSortLabelClasses.active}`]: {
    color: '#fff',
    [`& .${tableSortLabelClasses.icon}`]: {
      color: '#fff',
    },
  },
}));

export const LongTextTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    maxWidth: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const GreyTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  color: theme.palette.common.white,
  '&.Mui-active': {
    color: theme.palette.common.white,
  },
  '&.Mui-active .MuiTableSortLabel-icon': {
    color: theme.palette.common.white,
  },
}));

export const StyledFilterMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 64,
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    paddingBottom: 0,
    maxHeight: '60vh',
    display: 'flex',
    justifyContent: 'center',
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '0px 0',
      '& .MuiCheckbox-root': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    '& #cancel-fltr-btn': {
      color: theme.palette.text.secondary,
      backgroundColor: 'unset',
      '&:hover': {
        color: theme.palette.text.primary,
      },
    },
    '& #apply-fltr-btn': {
      backgroundColor: 'unset',
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export const StyledCheckedIcon = styled(CheckIcon)(({ theme, checked }) => ({
  color: !checked ? theme.palette.grey[300] : theme.palette.primary.main,
}));

export const StyledTestHeader = styled(Paper)(() => ({
  position: 'sticky',
  top: '80px',
  zIndex: 1000,
  paddingTop: '5px',
  paddingBottom: '5px',
}));
