import { TEST_TYPES } from 'Constants';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Save } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import originpoints from 'Api/originpoints';
import samplePlans from 'Api/samplePlans';
import samplePoints from 'Api/samplePoints';
import scenarios from 'Api/scenarios';
import segments from 'Api/segments';
import segmentsApi from 'Api/segments';

import { isValidResponse } from 'Utils';

const EditOP = ({
  cancel,
  value,
  testId,
  reFetchProject,
  segment,
  preparedScenariosList,
  test,
  sampleType,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { handleSubmit, register, formState, errors } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);
    let sp = segment.sp > data.op ? data.op : segment.sp;
    let isGeneral = false;
    if (
      [
        TEST_TYPES.GENERAL,
        TEST_TYPES.LARGE_SURVEY,
        TEST_TYPES.SMALL_SURVEY,
      ].includes(test.testtype)
    ) {
      // For general survey tests, we need to create 2 segments,
      // each containing 12 SPs
      sp = 12;
      isGeneral = true;
    }
    segments
      .createSegments({
        projectId: segment.projectid,
        testId: testId,
        totalSegments: data.op !== 16 ? 1 : 2,
        op: !isGeneral ? data.op : null,
        intervalcount: 4,
        sp: sp,
        floorplan: segment.floorplan,
      })
      .then(async () => {
        const response = await segments.getSegments(testId);
        let newSegments;
        if (isValidResponse(response)) {
          newSegments = response.data.data;
        } else {
          setError('Error happened');
          cancel();
        }
        for (const newSegment of newSegments) {
          if (!isGeneral) {
            await segments.updateTestPlan({
              segmentId: newSegment.segmentid,
              testId: testId,
              op: data.op,
              intervalcount: 4,
              sp: segment.sp,
            });
          }
        }
      })
      .then(async () => {
        const response = await segments.getSegments(testId);
        let newSegments;
        if (isValidResponse(response)) {
          newSegments = response.data.data;
        } else {
          setError('Error happened');
          cancel();
        }
        await originpoints.createOriginpoints({
          testId: testId,
        });
        await samplePoints.createSamplePoints({
          testId: testId,
          collectionMethod: sampleType,
        });
        try {
          await samplePlans.removeSamplePlan(segment.projectid);
        } catch (e) {
          console.log(e);
        }
        if (isGeneral && data.op === 16) {
          await segmentsApi.updateSamples({
            testId: testId,
            collectionMethod: sampleType,
          });
          await segments.updateSegment(newSegments[0].segmentid, {
            testId: testId,
            plateKind: 'A',
          });
          await segments.updateSegment(newSegments[1].segmentid, {
            testId: testId,
            plateKind: 'B',
          });
        }
        for (const newSegment of newSegments) {
          await segments.updateTestPlan({
            segmentId: newSegment.segmentid,
            testId: testId,
            surveyDate: segment.surveydate,
            comments: segment.comments,
            floorPlan: segment.floorplan,
            totalSegments: data.op !== 16 ? 1 : 2,
            segmentsTested: !isGeneral
              ? null
              : newSegments.map((s) => s.segmentid),
          });

          if (
            ![
              TEST_TYPES.GENERAL,
              TEST_TYPES.LARGE_SURVEY,
              TEST_TYPES.SMALL_SURVEY,
            ].includes(test.testtype)
          ) {
            await scenarios.createScenarios({
              testId: testId,
              segmentId: newSegment.segmentid,
              totalScenarios: preparedScenariosList.length,
            });
          }
        }

        try {
          await samplePlans.removeSamplePlan(segment.projectid);
        } catch (e) {
          console.log(e);
        }

        reFetchProject();
        cancel();
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const inclusiveRange = (start, end, step) => {
    return Array.from(
      Array.from(Array(Math.ceil((end - start + 1) / step)).keys()),
      (x) => start + x * step,
    );
  };

  const renderForm = () => {
    const range = [
      TEST_TYPES.GENERAL,
      TEST_TYPES.LARGE_SURVEY,
      TEST_TYPES.SMALL_SURVEY,
    ].includes(test.testtype)
      ? [8, 16]
      : inclusiveRange(
          1,
          test.testtype === TEST_TYPES.RECIRCULATION ? 12 : 10,
          1,
        );
    if (!isLoading) {
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <TextField
              select
              focused
              variant="outlined"
              fullWidth
              label="Number of OP"
              defaultValue={value}
              {...register('op')}
              error={!!(errors && errors.op)}
              helperText={errors?.op?.message}
            >
              {range.map((value) => (
                <MenuItem key={value} value={value}>
                  <Typography>{value}</Typography>
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              startIcon={<Save />}
              disabled={!formState.isDirty}
              type="submit"
            >
              Save
            </Button>
            <ButtonGroup>
              <Button onClick={cancel}>Cancel</Button>
            </ButtonGroup>
          </Stack>
        </form>
      );
    }
    return null;
  };

  return (
    <Stack p={2} spacing={2}>
      {isLoading && <LinearProgress />}
      <Typography variant="h5">Edit number of OP</Typography>
      <Box py={2}>{renderForm()}</Box>
      {!!error && <Alert severity="error">Error when updating OP</Alert>}
    </Stack>
  );
};

export default EditOP;
