import { DASHBOARD_VIEW_TYPES } from 'Constants';
import {
  BuildingDetailsPage,
  BuildingProjectsResults,
  ChangePassword,
  Companies,
  Compare,
  Contracts,
  Dashboard,
  Documents,
  FloorPlannerPage,
  FloorplanHDAPlacement,
  FloorplanPlacementPage,
  Heatmap,
  Heatmaps,
  NotFound,
  Portfolios,
  Profile,
  ProjectDownloads,
  ProjectMain,
  Projects,
  Results,
  SingleProjectFloorplanCompilationHeatmap,
  SingleTest,
  SitesPage,
  TagsPage,
  TestPackageItemPage,
  TestPackages,
  Tests,
  Users,
} from 'Pages';

import {
  ACCESS_LEVEL_ADMIN,
  ACCESS_LEVEL_EDITOR,
  ACCESS_LEVEL_VIEWER,
  ROLE_CUSTOMER,
  ROLE_PARTNER,
  ROLE_SAFETRACES,
} from './roles';

export let ROUTE_HOME = '/';

export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_DASHBOARD_PORTFOLIO = `${ROUTE_DASHBOARD}/:portfolioId`;
export const ROUTE_DASHBOARD_BUILDING = `${ROUTE_DASHBOARD_PORTFOLIO}/:buildingId`;

export let ROUTE_CONTRACTS = '/contracts';
export let ROUTE_RESULTS = '/results';
export let ROUTE_TESTS = '/results/:buildingId';
export let ROUTE_BUILDING_PROJECTS_RESULTS =
  '/results/buildingProjects/:buildingId';
export let ROUTE_PROJECT_FLOORPLAN_HEATMAP =
  '/results/buildingProjects/:buildingId/:projectId/:floorplanId';
export let ROUTE_HEATMAPS = '/results/:buildingId/:testId';
export let ROUTE_HEATMAP = '/results/:buildingId/:testId/:heatmapId';
export let ROUTE_COMPARE = '/compare';
export let ROUTE_USERS = '/users';
export let ROUTE_PROFILE_CHANGE_PASSWORD = '/profile/change-password';
export let ROUTE_PROFILE = '/profile';
export let ROUTE_PORTFOLIOS = '/portfolios';
export let ROUTE_TEST_PACKAGES = '/test-packages';
export let ROUTE_TEST_PACKAGES_ITEMS = '/test-packages/:siteContractId';
export let ROUTE_DOCUMENTS = '/documents';
export let ROUTE_COMPANIES = '/companies';
export let ROUTE_TAGS = '/tags';
export let ROUTE_SITES = '/sites';
export let ROUTE_SITES_BUILDING = '/sites/:buildingId';
export let ROUTE_PROJECTS = '/projects';
export let ROUTE_PROJECT_DETAILS = '/projects/:projectId';
export let ROUTE_PROJECT_TEST_DETAILS = '/projects/:projectId/:testId';
export const ROUTE_PROJECT_DOWNLOADS = '/projects/:projectId/resources';
export let ROUTE_FLOORPLANNER = '/floorplanner';
export const ROUTE_FLOORPLANNER_HDA = '/floorplanner-hda/:floorplanId';
export let ROUTE_FLOORPLANNPLACEMENT = '/floorplan-placement';

export const ROUTES_COMPANY_CHANGE_NO_REDIRECT = [
  ROUTE_HOME,
  ROUTE_DASHBOARD,
  ROUTE_CONTRACTS,
  ROUTE_PORTFOLIOS,
  ROUTE_RESULTS,
  ROUTE_PROFILE,
  ROUTE_USERS,
  ROUTE_PROFILE_CHANGE_PASSWORD,
  ROUTE_COMPARE,
  ROUTE_TEST_PACKAGES,
  ROUTE_COMPANIES,
  ROUTE_TAGS,
  ROUTE_SITES,
  ROUTE_PROJECTS,
  ROUTE_DOCUMENTS,
];

export const ROUTES_COMPANY_CHANGE_REDIRECTION_CONFIG = {
  // PROJECT routes
  [ROUTE_PROJECT_DETAILS]: ROUTE_PROJECTS,
  [ROUTE_PROJECT_TEST_DETAILS]: ROUTE_PROJECTS,
  [ROUTE_PROJECT_DOWNLOADS]: ROUTE_PROJECTS,
  [ROUTE_FLOORPLANNPLACEMENT]: ROUTE_PROJECTS,
  // TEST PACKAGES
  [ROUTE_TEST_PACKAGES_ITEMS]: ROUTE_TEST_PACKAGES,
  // SITES & BUILDINGS
  [ROUTE_SITES_BUILDING]: ROUTE_SITES,
  // RESULTS
  [ROUTE_TESTS]: ROUTE_RESULTS,
  [ROUTE_HEATMAPS]: ROUTE_RESULTS,
  [ROUTE_HEATMAP]: ROUTE_RESULTS,
  [ROUTE_BUILDING_PROJECTS_RESULTS]: ROUTE_RESULTS,
  [ROUTE_PROJECT_FLOORPLAN_HEATMAP]: ROUTE_RESULTS,
};

export const ROUTES_SAFETRACER_COMPANY_CHOICE_ALL_TYPES = [
  ROUTE_USERS,
  ROUTE_PROJECTS,
  ROUTE_PROJECT_DETAILS,
  ROUTE_PROJECT_TEST_DETAILS,
  ROUTE_PROJECT_DOWNLOADS,
];
export const ROUTES_SAFETRACER_COMPANY_CHOICE_PARTNER_ONLY = [];
export const ROUTES_SAFETRACER_COMPANY_NO_CHOICE = [
  ROUTE_COMPANIES,
  ROUTE_PROFILE_CHANGE_PASSWORD,
  ROUTE_PROFILE,
  ROUTE_TAGS,
  ROUTE_PROJECT_DETAILS,
  ROUTE_PROJECT_DOWNLOADS,
];
export const ROUTES_PARTNER_COMPANY_CHOICE_ALL_TYPES = [ROUTE_USERS];

const generateDashboardRouteConfig = (type) => {
  const configObj = {
    exact: true,
    component: Dashboard,
    permission: [ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN],
  };

  let path;

  switch (type) {
    case 'portfolio':
      path = ROUTE_DASHBOARD_PORTFOLIO;
      break;
    case 'building':
      path = ROUTE_DASHBOARD_BUILDING;
      break;
    default:
      path = ROUTE_DASHBOARD;
      break;
  }

  configObj.path = path;
  configObj.componentProps = { type };

  return configObj;
};

const routes = [
  ...DASHBOARD_VIEW_TYPES.map((type) => generateDashboardRouteConfig(type)),
  {
    path: ROUTE_RESULTS,
    exact: true,
    component: Results,
  },
  {
    path: ROUTE_BUILDING_PROJECTS_RESULTS,
    exact: true,
    component: BuildingProjectsResults,
    permission: [ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN],
  },
  {
    path: ROUTE_PROJECT_FLOORPLAN_HEATMAP,
    exact: true,
    component: SingleProjectFloorplanCompilationHeatmap,
    permission: [ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN],
  },
  {
    path: ROUTE_DOCUMENTS,
    exact: true,
    component: Documents,
  },
  {
    path: ROUTE_TESTS,
    exact: true,
    component: Tests,
  },
  {
    path: ROUTE_HEATMAPS,
    exact: true,
    component: Heatmaps,
  },
  {
    path: ROUTE_HEATMAP,
    exact: true,
    component: Heatmap,
  },
  {
    path: ROUTE_CONTRACTS,
    exact: true,
    component: Contracts,
    permission: [ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN],
  },
  {
    path: ROUTE_COMPARE,
    exact: true,
    component: Compare,
  },
  {
    path: ROUTE_USERS,
    exact: true,
    component: Users,
    permission: [
      ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN,
      ROLE_PARTNER + ACCESS_LEVEL_ADMIN,
    ],
  },
  {
    path: ROUTE_PROFILE_CHANGE_PASSWORD,
    exact: true,
    component: ChangePassword,
  },
  {
    path: ROUTE_PROFILE,
    exact: true,
    component: Profile,
  },
  {
    path: ROUTE_PORTFOLIOS,
    exact: true,
    component: Portfolios,
    permission: [ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN],
  },
  {
    path: ROUTE_TEST_PACKAGES,
    exact: true,
    component: TestPackages,
    permission: [
      ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN,
      ROLE_SAFETRACES + ACCESS_LEVEL_EDITOR,
      ROLE_PARTNER + ACCESS_LEVEL_ADMIN,
      ROLE_PARTNER + ACCESS_LEVEL_EDITOR,
    ],
  },
  {
    path: ROUTE_TEST_PACKAGES_ITEMS,
    exact: true,
    component: TestPackageItemPage,
    permission: [
      ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN,
      ROLE_SAFETRACES + ACCESS_LEVEL_EDITOR,
      ROLE_PARTNER + ACCESS_LEVEL_ADMIN,
      ROLE_PARTNER + ACCESS_LEVEL_EDITOR,
    ],
  },
  {
    path: ROUTE_COMPANIES,
    exact: true,
    component: Companies,
    permission: [ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN],
  },
  {
    path: ROUTE_TAGS,
    exact: true,
    component: TagsPage,
    permission: [ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN],
  },
  {
    path: ROUTE_SITES,
    exact: true,
    component: SitesPage,
    permission: [
      ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN,
      ROLE_SAFETRACES + ACCESS_LEVEL_EDITOR,
      ROLE_SAFETRACES + ACCESS_LEVEL_VIEWER,
      ROLE_PARTNER + ACCESS_LEVEL_ADMIN,
      ROLE_PARTNER + ACCESS_LEVEL_EDITOR,
      ROLE_PARTNER + ACCESS_LEVEL_VIEWER,
    ],
  },
  {
    path: ROUTE_SITES_BUILDING,
    exact: true,
    component: BuildingDetailsPage,
    permission: [
      ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN,
      ROLE_SAFETRACES + ACCESS_LEVEL_EDITOR,
      ROLE_SAFETRACES + ACCESS_LEVEL_VIEWER,
      ROLE_PARTNER + ACCESS_LEVEL_ADMIN,
      ROLE_PARTNER + ACCESS_LEVEL_EDITOR,
      ROLE_PARTNER + ACCESS_LEVEL_VIEWER,
    ],
  },
  {
    path: ROUTE_PROJECTS,
    exact: true,
    component: Projects,
    permission: [
      ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN,
      ROLE_SAFETRACES + ACCESS_LEVEL_EDITOR,
      ROLE_SAFETRACES + ACCESS_LEVEL_VIEWER,
      ROLE_PARTNER + ACCESS_LEVEL_ADMIN,
      ROLE_PARTNER + ACCESS_LEVEL_EDITOR,
      ROLE_PARTNER + ACCESS_LEVEL_VIEWER,
    ],
  },
  {
    path: ROUTE_PROJECT_DETAILS,
    exact: true,
    component: ProjectMain,
    permission: [
      ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN,
      ROLE_SAFETRACES + ACCESS_LEVEL_EDITOR,
      ROLE_SAFETRACES + ACCESS_LEVEL_VIEWER,
      ROLE_PARTNER + ACCESS_LEVEL_ADMIN,
      ROLE_PARTNER + ACCESS_LEVEL_EDITOR,
      ROLE_PARTNER + ACCESS_LEVEL_VIEWER,
    ],
  },
  {
    path: ROUTE_PROJECT_TEST_DETAILS,
    exact: true,
    component: SingleTest,
    permission: [
      ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN,
      ROLE_SAFETRACES + ACCESS_LEVEL_EDITOR,
      ROLE_SAFETRACES + ACCESS_LEVEL_VIEWER,
      ROLE_PARTNER + ACCESS_LEVEL_ADMIN,
      ROLE_PARTNER + ACCESS_LEVEL_EDITOR,
      ROLE_PARTNER + ACCESS_LEVEL_VIEWER,
    ],
  },
  {
    path: ROUTE_PROJECT_DOWNLOADS,
    exact: true,
    component: ProjectDownloads,
    permission: [
      ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN,
      ROLE_SAFETRACES + ACCESS_LEVEL_EDITOR,
      ROLE_PARTNER + ACCESS_LEVEL_ADMIN,
      ROLE_PARTNER + ACCESS_LEVEL_EDITOR,
    ],
  },
  {
    path: ROUTE_FLOORPLANNER,
    exact: true,
    component: FloorPlannerPage,
    permission: [
      ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN,
      ROLE_SAFETRACES + ACCESS_LEVEL_EDITOR,
      ROLE_PARTNER + ACCESS_LEVEL_ADMIN,
      ROLE_PARTNER + ACCESS_LEVEL_EDITOR,
    ],
  },
  {
    path: ROUTE_FLOORPLANNER_HDA,
    exact: true,
    component: FloorplanHDAPlacement,
    permission: [
      ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN,
      ROLE_SAFETRACES + ACCESS_LEVEL_EDITOR,
      ROLE_PARTNER + ACCESS_LEVEL_ADMIN,
      ROLE_PARTNER + ACCESS_LEVEL_EDITOR,
    ],
  },
  {
    path: ROUTE_FLOORPLANNPLACEMENT,
    exact: true,
    component: FloorplanPlacementPage,
    permission: [
      ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN,
      ROLE_SAFETRACES + ACCESS_LEVEL_EDITOR,
      ROLE_SAFETRACES + ACCESS_LEVEL_VIEWER,
      ROLE_PARTNER + ACCESS_LEVEL_ADMIN,
      ROLE_PARTNER + ACCESS_LEVEL_EDITOR,
      ROLE_PARTNER + ACCESS_LEVEL_VIEWER,
      ROLE_CUSTOMER + ACCESS_LEVEL_VIEWER,
    ],
  },
  {
    path: '/*',
    component: NotFound,
  },
];

export default routes;
