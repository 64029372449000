import React from 'react';

import { useTestScenariosContext } from '../context';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButton, Stack, Tooltip } from '@mui/material';

export default function ResetTagButton({ scenarioId, opKey, sx }) {
  const {
    startResetTagScenarioProcess,
    editPermission,
    forceRestrictAllEdits,
    forceRestrictAllEditsReason,
  } = useTestScenariosContext();

  return (
    <Stack>
      <Tooltip title={forceRestrictAllEditsReason || ''} placement="top">
        <span>
          <IconButton
            title="reset-scenario-tag-btn"
            color="error"
            size="small"
            disabled={!editPermission || forceRestrictAllEdits}
            onClick={() => startResetTagScenarioProcess(scenarioId, opKey)}
            sx={{ ...sx }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Stack>
  );
}
