import { CHART_COLORS, TEST_TYPES, TEST_TYPES_LABELS } from 'Constants';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import '../styles.css';

import { useDashboardContext } from 'Components/Dashboard/context';

export default function ChartTestsByTypes() {
  const { mainOverviewData, mainOverviewFilterParams } = useDashboardContext();
  const periodType = mainOverviewFilterParams.periodType;

  const data = mainOverviewData?.testsOverview;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={400}
        height={340}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 25,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={periodType === 'yearly' ? 'year' : 'quarter'}
          label={{
            className: 'noselect',
            position: 'bottom',
          }}
        />
        <YAxis
          label={{
            value: 'Amount of Tests',
            angle: -90,
            position: 'insideBottomLeft',
            className: 'noselect',
          }}
        />
        <Tooltip
          labelFormatter={(_, payload) => {
            try {
              const name =
                payload[0].payload[
                  periodType === 'yearly' ? 'year' : 'quarter'
                ];
              return name;
            } catch (err) {
              return '';
            }
          }}
          formatter={(value, name) => {
            if (!value) return [];
            return [value, TEST_TYPES_LABELS[name]];
          }}
        />
        {Object.values(TEST_TYPES).map((testType, i) => (
          <Bar
            key={testType}
            dataKey={testType}
            stackId="a"
            fill={CHART_COLORS[i]}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}
