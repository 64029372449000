import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Person } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { ROUTE_PROFILE, ROUTE_PROFILE_CHANGE_PASSWORD } from 'Config/routes';

import { logout, useAuthDispatch } from 'Context';

const ProfileMenu = () => {
  const dispatch = useAuthDispatch();

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout(dispatch);
    navigate('/login');
  };

  const handleChangePassword = () => {
    navigate(ROUTE_PROFILE_CHANGE_PASSWORD);
    setAnchorEl(null);
  };

  const handleProfile = () => {
    navigate(ROUTE_PROFILE);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-controls="basic-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={'profile-button'}
      >
        <Person />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleProfile}>Profile</MenuItem>
        <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
        <MenuItem onClick={handleLogout} className={'logout-button'}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default React.memo(ProfileMenu);
