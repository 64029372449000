import { HEALTHCARE_TEST_TYPES, PROJECT_STATUSES, TEST_TYPES } from 'Constants';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import segmentsApi from 'Api/segments';
import testsApi from 'Api/tests';

import ErrorMessage from 'Components/UI/ErrorMessage';

import {
  ACCESS_LEVEL_ADMIN,
  ACCESS_LEVEL_EDITOR,
  ROLE_PARTNER,
  ROLE_SAFETRACES,
} from 'Config/roles';

import {
  fetchProjectData,
  fetchProjectDetails,
  fetchProjectStatus,
  fetchProjectTags,
  fetchProjectTagsExtended,
  fetchProjectTest,
  fetchProjectTests,
  projectDataSelector,
} from 'Features/Projects/projectsSlice';

import { getUserData, isValidResponse } from 'Utils';

const ProjectContext = createContext({});

export const ProjectContextProvider = ({ children, value }) => {
  const dispatch = useDispatch();
  const { projectData, projectDetails, isLoading } =
    useSelector(projectDataSelector);

  const [mainError, setMainError] = useState('');

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [globalError, setGlobalError] = useState('');
  const [drawerMeta, setDrawerMeta] = useState({
    type: 'sample_type',
    testId: null,
    id: null,
    value: null,
    data: null,
  });
  const { roleName, accessLevel } = getUserData();

  const cantDeleteTests =
    projectData?.status &&
    !~[PROJECT_STATUSES.NEW, PROJECT_STATUSES.IN_PROGRESS].indexOf(
      projectData.status,
    );

  const editPermission =
    !!~[ACCESS_LEVEL_ADMIN, ACCESS_LEVEL_EDITOR].indexOf(accessLevel) &&
    !!~[ROLE_SAFETRACES, ROLE_PARTNER].indexOf(roleName);

  useEffect(() => {
    dispatch(fetchProjectData(value.projectId));
  }, []);

  const openEditModal = ({ type, id, testId, value, data }) => {
    setDrawerMeta({ type, id, testId, value, data });
    setIsDrawerOpen(true);
  };
  const updateTestsOrderAndCreateSamples = async (testsToUpdate, tests) => {
    const response = await testsApi.updateTestsOrderNumbers({
      tests: testsToUpdate,
    });

    const projectId = tests[0]?.projectid ?? null;
    const samplesUpdate = await segmentsApi.recreateSamples(projectId);

    if (!isValidResponse(response) || !isValidResponse(samplesUpdate)) {
      throw new Error('Error updating tests order');
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        projectData,
        projectDetails,
        openEditModal,
        isLoading,
        isDrawerOpen,
        setIsDrawerOpen,
        drawerMeta,
        fetchProject: () => dispatch(fetchProjectData(value.projectId)),
        fetchProjectStatus: () => dispatch(fetchProjectStatus(value.projectId)),
        fetchProjectTests: () => dispatch(fetchProjectTests(value.projectId)),
        fetchProjectTest: (testId) =>
          dispatch(fetchProjectTest(value.projectId, testId)),
        fetchProjectDetails: () =>
          dispatch(fetchProjectDetails(value.projectId)),
        refetchProjectTags: (includeAllTags) =>
          dispatch(fetchProjectTags(value.projectId, includeAllTags)),
        fetchProjectTagsExtended: () =>
          dispatch(fetchProjectTagsExtended(value.projectId)),
        projectId: value.projectId,
        cantDeleteTests,
        editPermission,
        mainError,
        setMainError,
        testAmountChangeHandler: async () => {
          await Promise.all([
            dispatch(fetchProjectTests(value.projectId)),
            dispatch(fetchProjectTags(value.projectId)),
          ]);
        },
        updateTestsOrderAndCreateSamples: updateTestsOrderAndCreateSamples,
        globalError,
        setGlobalError,
      }}
    >
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectContext = () => {
  const {
    projectData,
    projectDetails,
    projectId,
    isDrawerOpen,
    setIsDrawerOpen,
    openEditModal,
    drawerMeta,
    fetchProject,
    fetchProjectStatus,
    fetchProjectDetails,
    editPermission,
    refetchProjectTags,
    fetchProjectTests,
    testAmountChangeHandler,
    isLoading,
    cantDeleteTests,
    setMainError,
    fetchProjectTest,
    updateTestsOrderAndCreateSamples,
    fetchProjectTagsExtended,
  } = useContext(ProjectContext);

  let projectHasHealthcareTests = false;
  let projectHasVerificationTests = false;

  if (Array.isArray(projectData?.tests)) {
    for (const { testtype } of projectData?.tests) {
      if (testtype === TEST_TYPES.VERIFICATION) {
        projectHasVerificationTests = true;
      }
      if (Object.values(HEALTHCARE_TEST_TYPES).includes(testtype)) {
        projectHasHealthcareTests = true;
      }
    }
  }

  return {
    projectData,
    projectDetails,
    projectTests: projectData?.tests || [],
    projectBuildings: projectData?.buildings || [],
    projectId,
    isDrawerOpen,
    setIsDrawerOpen,
    openEditModal,
    drawerMeta,
    fetchProject,
    refetchProjectTags,
    testAmountChangeHandler,
    projectStatus: projectData?.status,
    isLoading,
    cantDeleteTests,
    fetchProjectStatus,
    fetchProjectDetails,
    setMainError,
    editPermission,
    projectHasHealthcareTests,
    projectHasVerificationTests,
    initialLoading: !projectData,
    fetchProjectTests,
    fetchProjectTest,
    updateTestsOrderAndCreateSamples,
    fetchProjectTagsExtended,
  };
};
