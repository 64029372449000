import {
  PROJECT_STATUSES,
  PROJECT_STATUS_WORKFLOW,
  SP_COLLECTION_METHODS,
  TEST_TYPES,
  TEST_TYPES_LABELS,
} from 'Constants';
import { format, parseISO } from 'date-fns';
import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { Grid, IconButton, Link, Stack, Typography } from '@mui/material';

import GenSurvOPTable from 'Components/OriginPoints/GenSurvOPTable';
import Floorplan from 'Components/SingleTest/Elements/Floorplan';
import { useTestContext } from 'Components/SingleTest/context';

import {
  ACCESS_LEVEL_ADMIN,
  ROLE_SAFETRACES,
  isSafetracesAdmin,
  isViewer,
} from 'Config/roles';

import { getUserData } from 'Utils';

const GeneralSurvey = (props) => {
  const {
    filteredTest,
    sampleType,
    originPointsList,
    samplePointsList,
    platekind,
    sprayer,
    surveydate,
    comments,
    airSamplerRate,
    sprayers,
    segmentId,
    projectId,
    segment,
    floorPlanPlacementURL,
    preparedScenariosList,
    projectStatus,
  } = props;

  const { openEditModal, disableEdits } = useTestContext();
  const overrideEditPermissionSafetracesAdminIgroneWorkflow =
    isSafetracesAdmin();
  const editSamplesDisabled =
    PROJECT_STATUS_WORKFLOW.indexOf(projectStatus) >=
    PROJECT_STATUS_WORKFLOW.indexOf(PROJECT_STATUSES.EXECUTION_READY);
  const editDetailsDisabled =
    PROJECT_STATUS_WORKFLOW.indexOf(projectStatus) >=
    PROJECT_STATUS_WORKFLOW.indexOf(PROJECT_STATUSES.EXECUTED);

  const { roleName, accessLevel } = getUserData();
  const editFullPermission =
    !!~[ACCESS_LEVEL_ADMIN].indexOf(accessLevel) &&
    !!~[ROLE_SAFETRACES].indexOf(roleName);

  return (
    <Stack direction="column" mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={6} lg={3}>
          <Typography variant={'body1'}>
            <b>Test Type: </b>
            {samplePointsList.length > 12
              ? TEST_TYPES_LABELS[TEST_TYPES.LARGE_SURVEY]
              : TEST_TYPES_LABELS[TEST_TYPES.SMALL_SURVEY]}
          </Typography>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Typography variant={'body1'}>
            <b>Sample Type: </b>
            {SP_COLLECTION_METHODS[sampleType]}{' '}
            {!isViewer() && !disableEdits && !editDetailsDisabled && (
              <IconButton
                aria-label="Edit sample type"
                size="small"
                onClick={() => {
                  openEditModal({
                    type: 'sample_type',
                    value: sampleType,
                    testId: filteredTest.testid,
                  });
                }}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            )}
          </Typography>
        </Grid>
        {sampleType !== 'swab' && (
          <Grid item xs={12} lg={6}>
            <Typography variant={'body1'}>
              <b>Air Sample Rate (L/min)*:</b>
              {airSamplerRate}{' '}
              {!isViewer() && !disableEdits && !editDetailsDisabled && (
                <IconButton
                  aria-label="Edit sample rate"
                  size="small"
                  onClick={() => {
                    openEditModal({
                      type: 'sample_rate',
                      value: airSamplerRate,
                      testId: filteredTest.testid,
                    });
                  }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={6} lg={3}>
          <Typography variant={'body1'}>
            <b>No. of OP:</b>{' '}
            {originPointsList.length > 0 ? originPointsList.length : ''}{' '}
            {!isViewer() &&
              editFullPermission &&
              !disableEdits &&
              !editDetailsDisabled &&
              !editSamplesDisabled && (
                <IconButton
                  aria-label="Edit number of origin points"
                  size="small"
                  onClick={() => {
                    openEditModal({
                      type: 'origin_points',
                      value: originPointsList.length,
                      testId: filteredTest.testid,
                      data: {
                        segment,
                        preparedScenariosList,
                        test: filteredTest,
                        sampleType,
                      },
                    });
                  }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              )}
          </Typography>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Typography variant={'body1'}>
            <b>No. of SP:</b>{' '}
            {samplePointsList.length > 0 ? samplePointsList.length : ''}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant={'body1'}>
            {segment.floorplan && (
              <>
                {!isSafetracesAdmin() &&
                !isViewer() &&
                !disableEdits &&
                !editDetailsDisabled ? (
                  <b>
                    Floorplan (
                    <Link href={floorPlanPlacementURL}>Manage OPs/SPs</Link>)
                  </b>
                ) : null}
                {!isSafetracesAdmin() &&
                (isViewer() || disableEdits || editDetailsDisabled) ? (
                  <b>
                    Floorplan (
                    <Link href={floorPlanPlacementURL}>View OPs/SPs</Link>)
                  </b>
                ) : null}
                {isSafetracesAdmin() &&
                projectStatus !== PROJECT_STATUSES.PUBLISHED ? (
                  <b>
                    Floorplan (
                    <Link href={floorPlanPlacementURL}>Manage OPs/SPs</Link>)
                  </b>
                ) : null}
                {isSafetracesAdmin() &&
                projectStatus === PROJECT_STATUSES.PUBLISHED ? (
                  <b>
                    Floorplan (
                    <Link href={floorPlanPlacementURL}>View OPs/SPs</Link>)
                  </b>
                ) : null}
              </>
            )}
            {!segment.floorplan && (
              <>
                <b>Floorplan</b>{' '}
              </>
            )}
            <Floorplan
              projectId={projectId}
              buildingId={filteredTest.buildingid || ''}
              filteredTest={filteredTest}
              segment={segment}
              editDisabled={disableEdits || editDetailsDisabled}
            />
          </Typography>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Typography variant={'body1'}>
            <b>Tag Series:</b> {originPointsList.length > 8 && 'A/B'}
            {originPointsList.length < 9 && (
              <>
                {platekind !== 'C' ? platekind : ''}{' '}
                {!isViewer() &&
                  !disableEdits &&
                  !editDetailsDisabled &&
                  !editSamplesDisabled && (
                    <IconButton
                      aria-label="Edit Tag Series"
                      size="small"
                      onClick={() => {
                        openEditModal({
                          type: 'platekind',
                          value: platekind !== 'C' ? platekind : '',
                          testId: filteredTest.testid,
                          data: { segmentId, projectId },
                        });
                      }}
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  )}
              </>
            )}
          </Typography>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Typography variant={'body1'}>
            <b>Sprayer:</b> {sprayer}{' '}
            {!isViewer() && !disableEdits && !editDetailsDisabled && (
              <IconButton
                aria-label="Edit sprayer"
                size="small"
                onClick={() => {
                  openEditModal({
                    type: 'sprayer',
                    value: sprayer,
                    testId: filteredTest.testid,
                    data: { sprayers },
                  });
                }}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            )}
          </Typography>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Typography variant={'body1'}>
            <b>Survey Date:</b>{' '}
            {surveydate ? format(parseISO(surveydate), 'MMM do, yyyy') : ''}{' '}
            {!isViewer() && !disableEdits && !editDetailsDisabled && (
              <IconButton
                aria-label="Edit survey date"
                size="small"
                onClick={() => {
                  openEditModal({
                    type: 'surveydate',
                    value: surveydate,
                    testId: filteredTest.testid,
                    data: { segment },
                  });
                }}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            )}
          </Typography>
        </Grid>
        {typeof filteredTest.buildingdata.sq_ft !== 'undefined' && (
          <Grid item xs={6} md={3}>
            <Typography variant={'body1'}>
              <b>Test Area:</b> {filteredTest.buildingdata.sq_ft} sq.ft
            </Typography>
          </Grid>
        )}
        {typeof filteredTest.buildingdata.celling_height !== 'undefined' && (
          <Grid item xs={6} md={3}>
            <Typography variant={'body1'}>
              <b>Ceiling Height</b> {filteredTest.buildingdata.celling_height}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          <Typography variant={'body1'}>
            <b>Comments:</b> {comments}{' '}
            {overrideEditPermissionSafetracesAdminIgroneWorkflow ||
            (!isViewer() && !disableEdits && !editDetailsDisabled) ? (
              <IconButton
                aria-label="Edit sample rate"
                size="small"
                onClick={() => {
                  openEditModal({
                    type: 'comments',
                    value: comments,
                    testId: filteredTest.testid,
                    data: { segmentId },
                  });
                }}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            ) : null}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'body1'}>
            <b>Origin Points:</b>
          </Typography>
          <GenSurvOPTable originPoints={originPointsList}></GenSurvOPTable>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default GeneralSurvey;
