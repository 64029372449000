import React from 'react';

import { Box, LinearProgress } from '@mui/material';

const StaticLoadingProgress = ({ isLoading }) => {
  return (
    <Box height={8} width="100%">
      {isLoading && <LinearProgress />}
    </Box>
  );
};

export default StaticLoadingProgress;
