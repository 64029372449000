import React from 'react';
import { useSelector } from 'react-redux';

import { useProjectContext } from '../context';

import { Stack, Typography } from '@mui/material';

import TagsSelectorTable from 'Components/SingleProject/Main/Tables/TagsSelectorTable';

import { projectDataSelector } from 'Features/Projects/projectsSlice';

export default function ProjectTagsSelector() {
  const { refetchProjectTags, fetchProjectTests } = useProjectContext();
  const { initialLoading } = useSelector(projectDataSelector);

  if (initialLoading) return null;

  return (
    <Stack direction="column" sx={{ mt: 4 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Tag Selection
      </Typography>
      <TagsSelectorTable
        updateProjectData={() => {
          refetchProjectTags(false);
          fetchProjectTests();
        }}
      />
    </Stack>
  );
}
