import { DEFAULT_ROWS_AMOUNT_PER_TABLE_PAGE } from 'Constants';
import React, { useEffect, useState } from 'react';

import ActivateOrBlockUserBtn from './Buttons/ActivateOrBlockUserBtn';
import ApproveUserBtn from './Buttons/ApproveUserBtn';
import DeleteUserBtn from './Buttons/DeleteUserBtn';
import EditUserBtn from './Buttons/EditUserBtn';
import RejectUserBtn from './Buttons/RejectUserBtn';

import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

import TablePaginationActions from 'Components/Companies/TablePaginationActions';
import TableBodyEmptyRowsFiller from 'Components/UI/TableBodyEmptyRowsFiller';
import TableBodySkeleton from 'Components/UI/TableBodySkeleton';
import TableEmptyDataDisplay from 'Components/UI/TableEmptyDataDisplay';

import { LOCAL_STORAGE_USER_KEY } from 'Context/Classes/User';

import { StyledTableCell, StyledTableRow } from 'Layout/layoutStyles';

function UsersTable({
  page,
  handleChangePage,
  usersList,
  roles,
  rolesData,
  accesses,
  afterUsersListUpdate,
  isLoading,
  canManageUsers,
  searchParam,
}) {
  const [tableData, setTableData] = useState([]);

  const currentUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));

  const configTable = {
    firstname: {
      widthcol: 4,
      headerName: 'First Name',
      align: 'left',
    },
    lastname: {
      widthcol: 4,
      headerName: 'Last Name',
      align: 'left',
    },
    email: {
      widthcol: 4,
      headerName: 'Email',
      align: 'center',
    },
    role: {
      widthcol: 3,
      headerName: 'Role',
      align: 'center',
    },
    permission: {
      widthcol: 3,
      headerName: 'Permission',
      align: 'center',
    },
    is_active: {
      widthcol: 2,
      headerName: 'Status',
      align: 'center',
    },
    action: {
      widthcol: 4,
      headerName: '',
      align: 'center',
    },
  };

  useEffect(() => {
    setFilteredTableData();
  }, [usersList, searchParam]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * DEFAULT_ROWS_AMOUNT_PER_TABLE_PAGE - tableData.length,
        )
      : 0;

  const setFilteredTableData = () => {
    if (!usersList || !Array.isArray(usersList)) {
      setTableData([]);
      return;
    }

    let searchRegex;
    if (searchParam && typeof searchParam === 'string') {
      searchRegex = new RegExp(`${searchParam.trim()}`, 'i');
    }

    const users = [];
    for (let user of usersList) {
      if (searchRegex) {
        const { firstname, lastname, email } = user;
        if (
          !firstname.match(searchRegex) &&
          !email.match(searchRegex) &&
          !lastname.match(searchRegex)
        ) {
          continue;
        }
        users.push(user);
      } else users.push(user);
    }

    setTableData(users);
  };

  const renderActions = (user) => {
    if (!user || !Object.keys(user))
      return (
        <Stack direction="row" justifyContent="end">
          <EditUserBtn displayOnly sx={{ visibility: 'hidden' }} />
        </Stack>
      );

    const { status } = user;
    if (status === 'active') {
      return (
        <Stack direction="row" justifyContent="end">
          <ActivateOrBlockUserBtn
            disabled={isLoading}
            user={user}
            afterStatusChange={afterUsersListUpdate}
            sx={{
              visibility:
                canManageUsers && user.userid !== currentUser.userId
                  ? 'visible'
                  : 'hidden',
            }}
          />
          <EditUserBtn
            disabled={isLoading}
            user={user}
            afterEdit={afterUsersListUpdate}
            sx={{ visibility: canManageUsers ? 'visible' : 'hidden' }}
            rolesData={rolesData}
          />
          <DeleteUserBtn
            disabled={user.userid === currentUser.userId}
            disabledReason={'You cannot delete your own user account'}
            userName={`${user.firstname} ${user.lastname}`}
            userId={user.userid}
            afterDelete={afterUsersListUpdate}
            sx={{ visibility: canManageUsers ? 'visible' : 'hidden' }}
          />
        </Stack>
      );
    } else if (status === 'new') {
      return (
        <Stack
          direction="row"
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <ApproveUserBtn
            userEmail={user.email}
            accesses={accesses}
            roles={roles}
            afterApprove={afterUsersListUpdate}
            sx={{ visibility: canManageUsers ? 'visible' : 'hidden' }}
          />
          <RejectUserBtn
            userId={user.userid}
            afterReject={afterUsersListUpdate}
            sx={{ visibility: canManageUsers ? 'visible' : 'hidden' }}
          />
        </Stack>
      );
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small" sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            {Object.keys(configTable).map((column, i) => (
              <StyledTableCell
                align={configTable[column].align}
                widthcol={configTable[column].widthcol}
                key={`header-${i}`}
                sx={{ py: 1.5 }}
              >
                {configTable[column].headerName}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableBodySkeleton
              columns={7}
              rows={DEFAULT_ROWS_AMOUNT_PER_TABLE_PAGE}
              shouldRenderActionsCell={true}
              actionsComponent={
                canManageUsers ? (
                  <Stack direction="row" justifyContent="end">
                    <ActivateOrBlockUserBtn displayOnly />
                    <EditUserBtn displayOnly />
                    <DeleteUserBtn displayOnly />
                  </Stack>
                ) : null
              }
            />
          ) : (
            <React.Fragment>
              {!!tableData.length ? (
                <React.Fragment>
                  {tableData
                    .slice(
                      page * DEFAULT_ROWS_AMOUNT_PER_TABLE_PAGE,
                      page * DEFAULT_ROWS_AMOUNT_PER_TABLE_PAGE +
                        DEFAULT_ROWS_AMOUNT_PER_TABLE_PAGE,
                    )
                    .map((user, i) => (
                      <StyledTableRow key={`key=${i}`}>
                        <StyledTableCell>{user.firstname}</StyledTableCell>
                        <StyledTableCell>{user.lastname}</StyledTableCell>
                        <StyledTableCell align="center">
                          {user.email}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {user.rolename}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {user.accesslevel}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {user.is_active ? 'Active' : 'Inactive'}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {renderActions(user)}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  <TableBodyEmptyRowsFiller rowsAmount={emptyRows}>
                    {renderActions()}
                  </TableBodyEmptyRowsFiller>
                </React.Fragment>
              ) : (
                <TableEmptyDataDisplay columns={6} />
              )}
            </React.Fragment>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={6}
              count={tableData.length}
              rowsPerPage={DEFAULT_ROWS_AMOUNT_PER_TABLE_PAGE}
              labelRowsPerPage={null}
              SelectProps={{ style: { display: 'none' } }}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[DEFAULT_ROWS_AMOUNT_PER_TABLE_PAGE]}
              ActionsComponent={TablePaginationActions}
              labelDisplayedRows={({ from, to, count }) =>
                tableData.length ? `${from}–${to} of ${count}` : ''
              }
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default UsersTable;
