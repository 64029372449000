import { UNMOUNT_IGNORED_ERR_MSG } from 'Constants';
import axios from 'axios';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import FloorplansFileList from './Floorplans/FloorplansFileList';

import { Skeleton, Stack, Typography } from '@mui/material';

import floorPlans from 'Api/floorPlans';
import projects from 'Api/projects';

import { useIsMounted } from 'Context';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  handleProjectStatusDecision,
  isValidResponse,
} from 'Utils';

export const FloorplansContext = createContext({});

function Floorplans({
  loading,
  projectId,
  projectStatus,
  doExist,
  onError,
  refetchProject,
}) {
  const mounted = useIsMounted();

  const [isFetching, setIsFetching] = useState(false);
  const [floorplansData, setFloorplansData] = useState(null);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (doExist) {
      fetchFloorplans(source);
    } else if (typeof doExist === 'boolean') {
      setFloorplansData([]);
    }

    return () => {
      source.cancel(UNMOUNT_IGNORED_ERR_MSG);
    };
  }, [doExist]);

  const fetchFloorplans = useCallback(
    async (source) => {
      if (!projectId) return;

      setIsFetching(true);

      try {
        const response = await floorPlans.getFloorPlans(projectId, source);

        if (isValidResponse(response)) {
          const data = getDataFromResponse(response);
          if (!Array.isArray(data)) {
            throw new Error();
          }

          if (mounted.current) {
            const _floorplansData = data.map(
              ({
                floorplanid,
                filename,
                address,
                floornumber,
                heatmapversion,
                metadata,
              }) => {
                return {
                  id: floorplanid,
                  name: filename,
                  address,
                  floorNumber: floornumber,
                  hasHeatmapVersion: heatmapversion,
                  metadata,
                };
              },
            );

            setFloorplansData(_floorplansData || []);
          }
        } else {
          throw new Error(getErrorMessageFromResponse(response));
        }
      } catch (err) {
        if (err.message === UNMOUNT_IGNORED_ERR_MSG) return;

        console.error(`fetchFloorplans Error: `, err);
        if (mounted.current) {
          setFloorplansData([]);
          if (typeof onError === 'function') {
            onError();
          }
        }
      } finally {
        if (mounted.current) {
          setIsFetching(false);
        }
      }
    },
    [projectId],
  );

  const FloorplansContextValue = useMemo(() => {
    return {
      afterFloorplanFileDelete: async () => {
        try {
          const isTheLastFloorplan = floorplansData?.length <= 1;
          if (isTheLastFloorplan && typeof refetchProject === 'function') {
            refetchProject();
          } else {
            await fetchFloorplans();
          }
        } catch (err) {
          console.error(`afterFloorplanFileDelete Error: `, err);
        }
      },
      afterFloorplanFileUpload: async () => {
        const [shouldUpdate, newStatus] = handleProjectStatusDecision(
          'resultsUpload',
          projectStatus,
        );

        if (shouldUpdate) {
          updateProjectStatus(newStatus);
        } else {
          await fetchFloorplans();
        }

        return true;
      },
    };
  }, [projectStatus, projectId, floorplansData]);

  const updateProjectStatus = async (status) => {
    try {
      const projectStatusUpdate = await projects.updateStatus(
        projectId,
        status,
      );
      if (!isValidResponse(projectStatusUpdate)) {
        throw new Error(getErrorMessageFromResponse(projectStatusUpdate));
      }

      return true;
    } catch (err) {
      console.log(`updateProjectStatus Error: `, err);

      return false;
    } finally {
      if (typeof refetchProject === 'function') {
        refetchProject();
      }
    }
  };

  return (
    <Stack direction="column">
      {(loading || !floorplansData) && (
        <Stack direction="column" gap={2} sx={{ maxWidth: '800px' }}>
          <Skeleton variant="rounded" height={20} />
        </Stack>
      )}
      {!loading && floorplansData && (
        <FloorplansContext.Provider value={FloorplansContextValue}>
          <Stack direction="column" gap={2}>
            {!!doExist && (
              <FloorplansFileList
                projectId={projectId}
                fileList={floorplansData}
                onError={onError}
                afterUpdate={fetchFloorplans}
              />
            )}
            {typeof doExist === 'boolean' && !doExist && !isFetching && (
              <Typography>There are no floorplans in the project.</Typography>
            )}
          </Stack>
        </FloorplansContext.Provider>
      )}
    </Stack>
  );
}

export default React.memo(Floorplans);
