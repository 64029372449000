import React from 'react';

import { Box, Stack } from '@mui/material';

import BreadcrumbsTestPackages from 'Components/Breadcrumbs/BreadcrumbsTestPackages';
import MainAlert from 'Components/Mix/MainAlert';
import AddTestPackageButton from 'Components/TestPackageItem/AddTestPackageButton';
import CreateProjectButton from 'Components/TestPackageItem/CreateProjectButton';
import TestPackagePage from 'Components/TestPackageItem/TestPackagePage';
import { useTestPackageItems } from 'Components/TestPackageItem/context';
import ErrorMessage from 'Components/UI/ErrorMessage';

export default function TestPackageItems() {
  const { siteContract, mainError, setMainError, successMessage } =
    useTestPackageItems();

  return (
    <>
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => {
            setMainError('');
          }}
        />
      )}
      {!!successMessage && <MainAlert message={successMessage} />}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <BreadcrumbsTestPackages
            siteContractName={siteContract?.sitecontractname}
          />
        </Box>
        <Stack direction="row" spacing={1}>
          <CreateProjectButton />
          <AddTestPackageButton />
        </Stack>
      </Box>
      <div style={{ width: '100%' }}>
        <TestPackagePage />
      </div>
    </>
  );
}
