import React, { useState } from 'react';

import { Button, Tooltip } from '@mui/material';

import { useTestPackageItems } from 'Components/TestPackageItem/context';

import { PrimaryBorderButton } from 'Layout/layoutStyles';

export default function StartProjectButton({ testPackage }) {
  const { handleAddToProject } = useTestPackageItems();

  const [stateButton, setStateButton] = useState(false);
  const [textButton, setTextButton] = useState('Add to Project');
  const [buttonVariant, setButtonVariant] = useState('contained');

  const handleClick = () => {
    setStateButton((s) => !s);
    handleAddToProject(!stateButton, testPackage);
    setTextButton(stateButton ? 'Add to Project' : 'Remove from Project');
    setButtonVariant(stateButton ? 'contained' : 'outlined');
  };
  const buttonProps = {
    onClick: handleClick,
    variant: buttonVariant,
  };

  return (
    <>
      <Tooltip>
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
        >
          {stateButton ? (
            <Button {...buttonProps} sx={{ padding: '6px 16px' }}>
              {textButton}
            </Button>
          ) : (
            <PrimaryBorderButton {...buttonProps}>
              {textButton}
            </PrimaryBorderButton>
          )}
        </span>
      </Tooltip>
    </>
  );
}
