import { EXCEL_FILE_TYPES } from 'Constants';
import SampleContractSheetIcon from 'Icons/SampleContractSheetIcon';
import SamplePortfolioIcon from 'Icons/SamplePortfolioIcon';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import XLSX from 'xlsx-color';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import buildingsApi from 'Api/buildings';
import contractsApi from 'Api/contracts';
import portfoliosApi from 'Api/portfolios';

import { usePortfolioContext } from 'Components/Portfolios/context';

import {
  getSafetraceSetCompanyName,
  isValidResponse,
  xlsxStringToNumber,
} from 'Utils';

const parseXLSXFile = (fileData) => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const ab = e.target.result;
        const wb = XLSX.read(ab, { type: 'array' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const dataXlsx = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          raw: false,
          dateNF: 'yyyy-mm-dd',
        });
        validateParsedData(dataXlsx);
        resolve({
          success: true,
          fileName: fileData.name,
          data: dataXlsx,
        });
      } catch (error) {
        resolve({
          success: false,
          fileName: fileData.name,
          message: 'Error processing XLSX file: ' + error.message,
        });
      }
    };

    reader.onerror = () => {
      resolve({
        success: false,
        fileName: fileData.name,
        message: 'Error reading the file',
      });
    };

    reader.readAsArrayBuffer(fileData);
  });
};

/**
 * Validates the parsed data from an XLSX file.
 *
 * The function checks the structure of the data to ensure it matches the expected format.
 *
 * @param {Array<Array<string>>} data - The data to validate.
 * @returns {boolean} - Returns true if the data matches the expected structure and is valid.
 * @throws {Error} - Throws an error if the data is not in the expected format or if any discrepancies are found.
 *
 * Expected structure:
 * [
 *   [],
 *   ['Portfolio Name', '$portfolioName'],
 *   ['End customer', '$endCustomerName'],
 *   ['Partners', '$partners'],
 *   ['Number of Sites', '$numberOfSite'], // Not used
 *   [],
 *   ['Site name', 'Building Name', 'Building Address', 'Building City', 'Building State', 'Building Postal Code', 'Building Country', 'Total sq ft', 'Number of Floors', 'Typical Floor sq ft'],
 *   [],
 *   ['$site1', '$building1', '$address1', '$city1', '$state1', '$postcode1', '$country1', '$totalArea1', '$numOfFloors1', '$floorArea1'],
 *   ['$site2', '$building2', '$address2', '$city2', '$state2', '$postcode2', '$country2', '$totalArea2', '$numOfFloors2', '$floorArea2'],
 *   ... and so on
 * ]
 */
const validateParsedData = (data) => {
  // Check if data is an array of arrays
  if (!Array.isArray(data) || !data.every((row) => Array.isArray(row))) {
    throw new Error('Invalid data format: Data should be an array of arrays.');
  }

  const expectedRows = [
    ['Portfolio Name', '$portfolioName'],
    ['End customer', '$endCustomerName'],
    ['Partners', '$partners'],
    ['Number of Sites', '$numberOfSite'], // Not used
    [
      'Site name',
      'Building Name',
      'Building Address',
      'Building City',
      'Building State',
      'Building Postal Code',
      'Building Country',
      'Total sq ft',
      'Number of Floors',
      'Typical Floor sq ft',
    ],
  ];

  data = data.filter((row) => row.length > 0);

  for (let i = 0; i < expectedRows.length; i++) {
    const expectedRow = expectedRows[i];
    const actualRow = data[i] || [];

    if (expectedRow.length !== actualRow.length) {
      throw new Error(
        `Invalid row length at index ${i}: expected ${expectedRow.length} elements, found ${actualRow.length}`,
      );
    }

    for (let j = 0; j < expectedRow.length; j++) {
      if (expectedRow[j].startsWith('$')) {
        // Variable row
        if (actualRow[j] === '' || actualRow[j] === null) {
          throw new Error(
            `Empty value found at row ${i}, column ${j}: expected a non-empty value for "${expectedRow[j]}"`,
          );
        }
        continue;
      }

      if (expectedRow[j] !== actualRow[j]) {
        throw new Error(
          `Mismatch at row ${i}, column ${j}: expected "${expectedRow[j]}", found "${actualRow[j]}"`,
        );
      }
    }
  }

  const sitesRowStartIndex = 6;
  for (let i = sitesRowStartIndex; i < data.length; i++) {
    const row = data[i];

    if (row.length === 0 && i !== sitesRowStartIndex) {
      continue;
    }
    // Each row should have exactly 10 elements
    if (row.length !== 10) {
      throw new Error(
        `Invalid number of columns at row ${i}: expected 10, found ${row.length}. Make sure you are using the latest template.`,
      );
    }

    // Check that all columns in the row are non-empty
    for (let j = 0; j < row.length; j++) {
      if (row[j] === '' || row[j] === null || row[j] === undefined) {
        throw new Error(
          `Empty value found at row ${i + 1 - sitesRowStartIndex}, column "${
            data[6][j]
          }".`,
        );
      }
    }
  }

  return true;
};

const parseDataFromXLSX = (xlsxResult) => {
  const { data, fileName, success, message } = xlsxResult;
  if (!success) {
    return { success: false, errorMessage: message };
  }
  let portfolio = {};
  let buildings = [];
  let getDataFromNextRow;
  for (const item of data) {
    if (item.length <= 1) {
      continue;
    }
    const firstElement = item[0].toString();
    if (firstElement.includes('Portfolio Name')) {
      portfolio.portfolioName = item[1];
    }

    if (firstElement.includes('End customer')) {
      portfolio.endCustomerName = item[1];
    }

    if (firstElement.includes('Site name')) {
      getDataFromNextRow = true;
      continue;
    }

    if (getDataFromNextRow) {
      let obj = {};
      obj.siteName = item[0];
      obj.buildingName = item[1];
      obj.buildingAddress = {
        address: item[2],
        city: item[3],
        state: item[4],
        postalCode: item[5],
        country: item[6],
      };
      obj.totalSqft = xlsxStringToNumber(item[7]);
      obj.numberOfFloors = xlsxStringToNumber(item[8]);
      obj.floorSqeft = xlsxStringToNumber(item[9]);
      obj.portfolioName = portfolio.portfolioName;
      obj.endCustomerName = portfolio.endCustomerName;
      buildings.push(obj);
    }
  }

  return { portfolio, buildings, fileName, success: true };
};

const downloadFromURL = (url, filename) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
};

const PortfolioActions = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [_isLoading, setIsLoading] = useState(false);
  const [allBuildings, setAllBuildings] = useState([]);
  const [toBeCreated, setToBeCreated] = useState({
    portfolios: [],
    buildings: [],
  });
  const [diplicatedSites, setDiplicatedSites] = useState();
  const [duplicateBuildings, setDuplicateBuildings] = useState([]);
  const [fileIssues, setFileIssues] = useState([]);
  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const {
    setToast,
    isLoading: isLoadingContext,
    fetchPortfoliosAndSites,
  } = usePortfolioContext();
  const selectedFiles = watch('file_upload');

  const isLoading = _isLoading || isLoadingContext;

  useEffect(() => {
    setFileIssues([]);
    parseFiles(selectedFiles ?? []);
  }, [selectedFiles]);

  const fetchAllBuildings = async () => {
    const response = await buildingsApi.getAllBuildingsForPortfolios();
    if (isValidResponse(response)) {
      setAllBuildings(response.data.data);
    }
  };

  useEffect(() => {
    fetchAllBuildings();
  }, [isOpen]);

  const parseFiles = async (files) => {
    const parsingIssues = [];
    const duplicatedSites = [];
    // Dissect the files, parse them, and prepare the portfolios and buildings to be created
    if (!files.length) {
      setToBeCreated({
        portfolios: [],
        buildings: [],
      });
      return;
    }
    const promises = [];
    for (const file of files) {
      if (!EXCEL_FILE_TYPES.includes(file.type)) {
        parsingIssues.push({
          fileName: file.name,
          message: `Only Excel (XLSX) files are allowed. The file will be ignored.`,
        });
        continue;
      }
      promises.push(parseXLSXFile(file));
    }
    const responses = await Promise.all(promises);
    const parsedItems = responses
      .map((res) => {
        if (res.success) {
          return parseDataFromXLSX(res);
        } else {
          parsingIssues.push({
            fileName: res.fileName,
            message: res.message,
          });
          return null;
        }
      })
      .filter((item) => item !== null);

    setFileIssues(parsingIssues);

    const result = {
      portfolios: [],
      buildings: [],
    };

    for (const curr of parsedItems) {
      const { portfolio, buildings } = curr;
      const { portfolioName, endCustomerName } = portfolio;

      // Check if the portfolio exists in the DB, keep track of the portfolioId
      const existentPortfolio = allBuildings.find(
        (b) =>
          b.portfolioname === portfolioName &&
          b.companyname === endCustomerName,
      );
      // Also check that the portfolio isn't already prepared to be created
      const portfolioAlreadyAdded = result.portfolios.find(
        (p) =>
          p.portfolioName === portfolioName &&
          p.endCustomerName === endCustomerName,
      );

      if (!existentPortfolio && !portfolioAlreadyAdded) {
        result.portfolios.push(portfolio);
      }

      const buildingsToCreate = [];
      // Similar checks but for buildings
      for (const building of buildings) {
        let augmentedBuilding = building;

        if (existentPortfolio) {
          augmentedBuilding = {
            ...building,
            portfolioName: existentPortfolio.portfolioname,
            portfolioId: existentPortfolio.portfolioid,
          };
        } else {
          augmentedBuilding = {
            ...building,
            portfolioLinkKey: portfolioName + endCustomerName,
          };
        }

        const buildingExistsInDB = !!allBuildings.find(
          (ab) =>
            ab.buildingname === augmentedBuilding.buildingName &&
            ab.portfolioname === portfolioName,
        );

        const buildingAlreadyAdded = result.buildings.find(
          (ab) =>
            ab.buildingName === augmentedBuilding.buildingName &&
            ab.portfolioName === portfolioName,
        );

        const duplicateBuildings = buildingsToCreate.find(
          (ab) =>
            ab.buildingName === augmentedBuilding.buildingName &&
            ab.portfolioName === portfolioName,
        );

        if (
          !buildingExistsInDB &&
          !buildingAlreadyAdded &&
          !duplicateBuildings
        ) {
          buildingsToCreate.push(augmentedBuilding);
        }
        if (buildingExistsInDB) {
          duplicatedSites.push(augmentedBuilding.siteName);
        }

        if (duplicateBuildings) {
          setDuplicateBuildings((prev) => [...prev, augmentedBuilding]);
        }
      }

      result.buildings.push(...buildingsToCreate);
    }

    setToBeCreated(result);
    setDiplicatedSites(duplicatedSites);
  };

  const handleUploadClick = () => setIsOpen(true);
  const handleCloseDialog = () => {
    setToBeCreated({
      portfolios: [],
      buildings: [],
    });
    setDiplicatedSites([]);
    setDuplicateBuildings([]);
    setValue('file_upload', null);
    setIsOpen(false);
  };

  const handleDownloadPortfolioClick = async () => {
    const response = await portfoliosApi.getPortfolioExample();
    downloadFromURL(response.data.preSignedURL, 'Portfolio-example.xlsx');
  };

  const handleDownloadContractClick = async () => {
    const response = await contractsApi.getContractExample();
    downloadFromURL(response.data.preSignedURL, 'Contract-example.xlsx');
  };

  const handleUploadFiles = async () => {
    const uploadProgress = {
      portfolios: {
        total: toBeCreated.portfolios.length,
        success: 0,
        error: 0,
      },
      buildings: {
        total: toBeCreated.buildings.length,
        success: 0,
        error: 0,
      },
    };
    // Upload the portfolios first, save the corresponding portfolioId
    // Then upload the buildings grouped by portfolios
    setIsLoading(true);
    try {
      const { portfolios, buildings } = toBeCreated;
      // Filter out the buildings that will be created separately
      const independentBuildings = buildings.filter((b) => !b.portfolioId);
      for (const portfolio of portfolios) {
        // One by one, upload the portfolios, get its portfolioId
        const res = await portfoliosApi.createPortfolio(portfolio);
        if (isValidResponse(res)) {
          uploadProgress.portfolios.success += 1;
          const portfolioId = res.data.data.portfolioid;
          // Find the buildings that correspond to this portfolio
          const buildingsToCreate = buildings.filter(
            (b) =>
              b.portfolioLinkKey ===
              portfolio.portfolioName + portfolio.endCustomerName,
          );
          const buildingResponse = await buildingsApi.createBuilding({
            portfolioId,
            portfolioName: portfolio.portfolioName,
            buildings: buildingsToCreate,
          });
          if (isValidResponse(buildingResponse)) {
            uploadProgress.buildings.success += buildingsToCreate.length;
          } else {
            uploadProgress.buildings.error += buildingsToCreate.length;
          }
        } else {
          uploadProgress.portfolios.error += 1;
        }
      }

      if (portfolios.length === 0 && buildings.length !== 0) {
        const portfolioId = buildings[0].portfolioId;
        const portfolioName = buildings[0].portfolioName;
        const buildingResponse = await buildingsApi.createBuilding({
          portfolioId,
          portfolioName: portfolioName,
          buildings: buildings,
        });
        if (isValidResponse(buildingResponse)) {
          uploadProgress.buildings.success += buildings.length;
        } else {
          uploadProgress.buildings.error += buildings.length;
        }
      }
      // Finally, upload the buildings that are linked to pre-existing portfolios
      const separatedBuildings = independentBuildings.reduce((acc, curr) => {
        // Split into arrays by portfolioId
        const { portfolioId } = curr;
        if (!portfolioId) {
          return acc;
        }
        if (!acc[portfolioId]) {
          acc[portfolioId] = [];
        }
        acc[portfolioId].push(curr);
        return acc;
      }, {});
      for (const [portfolioId, buildings] of Object.entries(
        separatedBuildings,
      )) {
        const buildingResponse = await buildingsApi.createBuilding({
          portfolioId,
          buildings,
        });
        if (isValidResponse(buildingResponse)) {
          uploadProgress.buildings.success += buildings.length;
        } else {
          uploadProgress.buildings.error += buildings.length;
        }
      }
    } catch (e) {
      setToast({ severity: 'error', message: e.message });
      handleCloseDialog();
    } finally {
      setIsLoading(false);
      await fetchPortfoliosAndSites();
      await fetchAllBuildings();
      handleCloseDialog();
      setToBeCreated({
        portfolios: [],
        buildings: [],
      });
      setToast({
        severity: 'success',
        message: `Successfully uploaded ${uploadProgress.portfolios.success} portfolio(s) and ${uploadProgress.buildings.success} building(s). ${uploadProgress.portfolios.error} portfolio(s) and ${uploadProgress.buildings.error} building(s) failed to upload.`,
      });
    }
  };

  const renderCompanyMismatchAlert = () => {
    if (!toBeCreated.portfolios.length) {
      return null;
    }
    // If the toBeCreated portfolios include a company that is not the current company, show an alert
    const toBeCreatedCompanies = [
      ...new Set(toBeCreated.portfolios.map((p) => p.endCustomerName)),
    ];
    const currentCompany = getSafetraceSetCompanyName();
    const companyNameMismatch = !toBeCreatedCompanies.includes(currentCompany);
    const companiesMismatched = toBeCreatedCompanies.filter(
      (c) => c !== currentCompany,
    );
    if (companyNameMismatch) {
      return (
        <Alert severity="warning">
          <AlertTitle>Company mismatch</AlertTitle>
          One or more of the portfolios you are about to create are for a
          different company (<strong>{companiesMismatched.join(', ')}</strong>)
          than the one you are currently viewing (
          <strong>{currentCompany}</strong>). To view the newly created
          portfolios, you will need to switch to the correct company.
        </Alert>
      );
    }
    return null;
  };

  const renderInfoAlert = () => {
    if (!selectedFiles?.length) {
      return null;
    }
    return (
      <Alert severity="info">
        <AlertTitle>
          <strong>{selectedFiles?.length ?? '0'}</strong> file(s) were
          processed.
        </AlertTitle>
        {toBeCreated.buildings.length > 0 && toBeCreated.portfolios.length > 0 && (
          <>
            <Typography>The following portfolios will be created:</Typography>
            <ol>
              {toBeCreated.portfolios.map((p) => {
                return (
                  <li key={p.portfolioName}>
                    <strong>{p.portfolioName}</strong>
                    <span style={{ margin: '0 5px' }}>for</span>
                    <strong>{p.endCustomerName}</strong>
                  </li>
                );
              })}
            </ol>
          </>
        )}
        {toBeCreated.buildings.length > 0 && (
          <>
            <Typography>
              Number of new buildings to be created:{' '}
              <strong>{toBeCreated.buildings.length}</strong>
            </Typography>
          </>
        )}
        {selectedFiles?.length > 0 &&
          toBeCreated.portfolios.length === 0 &&
          toBeCreated.buildings.length === 0 && (
            <Typography>
              No valid portfolios were found in the file(s).
            </Typography>
          )}
        {selectedFiles?.length > 0 && toBeCreated.buildings.length === 0 && (
          <Typography>No valid buildings were found in the file(s).</Typography>
        )}
        {diplicatedSites?.length > 0 && (
          <>
            <Typography>
              The following sites already exist in the database and will be
              skipped:
            </Typography>
            <ul>
              {diplicatedSites.map((site) => (
                <li key={site}>{site}</li>
              ))}
            </ul>
          </>
        )}
        {duplicateBuildings?.length > 0 && (
          <>
            <Typography>
              The specified buildings are duplicated in the uploaded file and
              will be skipped:
            </Typography>
            <ul>
              {duplicateBuildings.map((building, index) => (
                <li key={index}>
                  {building.siteName} {building.buildingName}
                </li>
              ))}
            </ul>
          </>
        )}
      </Alert>
    );
  };

  return (
    <Box>
      <Tooltip title="Download sample portfolio sheet">
        <span>
          <IconButton
            disabled={isLoading}
            onClick={handleDownloadPortfolioClick}
          >
            <SamplePortfolioIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Download sample contract sheet">
        <span>
          <IconButton
            disabled={isLoading}
            sx={{ marginRight: 2 }}
            onClick={handleDownloadContractClick}
          >
            <SampleContractSheetIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Button
        variant="contained"
        onClick={handleUploadClick}
        disabled={isLoading}
      >
        Upload new portfolio
      </Button>

      <Dialog open={isOpen} onClose={handleCloseDialog}>
        <DialogTitle>Add Portfolio(s)</DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            type="file"
            name="file_upload"
            id="file_upload"
            {...register('file_upload', {
              required: 'File(s) required',
              validate: {
                lessThan10MB: (files) =>
                  Array.from(files).some((file) => file.size < 10000000) ||
                  'File size must be less than 10MB',
                acceptedFormats: (files) =>
                  Array.from(files).some((file) =>
                    EXCEL_FILE_TYPES.includes(file.type),
                  ) || 'Only Excel (XLSX) files are allowed',
              },
            })}
            error={!!errors.file_upload}
            inputProps={{
              accept: EXCEL_FILE_TYPES.join(','),
              multiple: true,
            }}
            helperText={errors?.file_upload?.message}
            disabled={isLoading}
          />
          <Stack spacing={1}>
            {fileIssues.length > 0 && (
              <Alert severity="warning">
                <AlertTitle>The following files have been skipped</AlertTitle>
                <List>
                  {fileIssues.map((issue) => (
                    <ListItem key={issue.fileName}>
                      <Typography>
                        <strong>{issue.fileName}</strong> - {issue.message}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Alert>
            )}
            {renderInfoAlert()}
            {renderCompanyMismatchAlert()}
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCloseDialog}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="button"
            color="primary"
            disabled={
              isLoading ||
              !selectedFiles?.length ||
              (toBeCreated.portfolios.length === 0 &&
                toBeCreated.buildings.length === 0)
            }
            onClick={handleUploadFiles}
            endIcon={isLoading && <CircularProgress size="20px" />}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PortfolioActions;
