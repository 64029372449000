import React, { useEffect, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';

import '../styles.css';
import BuildingsDraggableList from './BuildingsDraggableList';

const BuildingsDroppableBox = ({
  buildings,
  siteId,
  boxStyle = {},
  mode = 'view',
  disableActions,
  loading,
}) => {
  const [enabled, setEnabled] = useState(false);
  /**
   * This is used to render the Droppable elements after an animation frame,
   * because react-beautiful-dnd library has some issues with React 18 compatibility.
   */
  useEffect(() => {
    const animation = window.requestAnimationFrame(() => setEnabled(true));

    return () => {
      window.cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return (
    <Droppable
      droppableId={`site-${siteId}-buildings-droppable`}
      type="buildings"
      isDropDisabled={mode !== 'edit' || disableActions}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={boxStyle}
        >
          <BuildingsDraggableList
            snapshot={snapshot}
            isInDragAction={
              snapshot.isDraggingOver || snapshot.draggingFromThisWith
            }
            mode={mode}
            disableActions={disableActions}
            buildings={buildings}
            loading={loading}
          />
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default BuildingsDroppableBox;
