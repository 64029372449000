import React, { useMemo } from 'react';

import { Divider, Grid, Stack, Typography } from '@mui/material';

import AddContractButton from 'Components/Portfolios/ModalButtons/AddContractButton';
import DeletePortfolioButton from 'Components/Portfolios/ModalButtons/DeletePortfolioButton';
import PortfolioContractSummary from 'Components/Portfolios/PortfolioDetails/PortfolioContractSummary';
import { injectMetadataIntoContracts } from 'Components/Portfolios/utils';

const TerritorySelector = ({
  contracts,
  sites,
  endCustomer,
  portfolio,
  territories,
  regions,
}) => {
  const activeContracts = useMemo(() => {
    return injectMetadataIntoContracts(contracts, sites, territories, regions);
  }, [contracts, sites, regions, territories]);

  return (
    <Stack>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Stack>
            <Typography>
              End customer: <strong>{endCustomer.companyname}</strong>
            </Typography>
            <Typography variant="body1">
              {endCustomer.companyaddress}
            </Typography>
            <Typography variant="body1" mb={1}>
              {endCustomer.contact}
            </Typography>
            <Divider />
          </Stack>
          <DeletePortfolioButton portfolio={portfolio} />
        </Grid>
        <Grid item xs={9}>
          <Stack direction="row" justifyContent="center" alignContent="center">
            <Typography variant="h4" align="center">
              Contracts
            </Typography>
            <AddContractButton portfolio={portfolio} />
          </Stack>
          {activeContracts?.map((contract) => (
            <PortfolioContractSummary
              key={contract.contractid}
              contract={contract}
              sites={sites}
              territories={territories}
              regions={regions}
            />
          )) ?? null}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default TerritorySelector;
