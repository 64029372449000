import React, { useEffect, useState } from 'react';

import { Alert, Snackbar } from '@mui/material';

export default function ErrorMessage({ message, handleCloseErrorAlert }) {
  const [open, setOpen] = useState(false);
  const [displayMsg, setDisplatMsg] = useState('');

  useEffect(() => {
    setOpen(message !== '');
    if (message !== '') {
      setDisplatMsg(message);
    }
  }, [message]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={4500}
      onClose={handleCloseErrorAlert}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert severity="error" onClose={handleCloseErrorAlert} variant="filled">
        {displayMsg}
      </Alert>
    </Snackbar>
  );
}
