import { ERROR_AUTOCLOSE_TIMEOUT_MS } from 'Constants';
import React, { useState } from 'react';

import samplePlans from 'Api/samplePlans';
import tests from 'Api/tests';

import DeleteBorderButton from 'Components/Buttons/DeleteBorderButton';
import DeleteDialog from 'Components/Dialogs/DeleteDialog';
import ErrorMessage from 'Components/UI/ErrorMessage';

export default function DeleteTestBtn({
  displayOnly = false,
  afterDelete,
  testId,
  projectId,
  testName,
  disabled,
  disabledReason,
}) {
  const buttonDisabledProps = disabled
    ? {
        disabled,
        tooltipText: !!disabled && disabledReason,
      }
    : {};

  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [mainError, setMainError] = useState('');

  const deleteProject = async () => {
    setDeleteInProgress(true);
    let isSuccess = false;
    try {
      const response = await tests.removeOneTest(testId);
      isSuccess = isValidResponse(response);
      if (!isSuccess) {
        throw new Error(response?.data?.error?.message);
      }
      await samplePlans.removeSamplePlan(projectId);
    } catch (err) {
      console.log('Delete test error: ', err);
      setMainError(err?.message || `Couldn't delete test`);
      setTimeout(() => setMainError(''), ERROR_AUTOCLOSE_TIMEOUT_MS);
    } finally {
      setDeleteInProgress(false);
      setDialogOpen(false);
      if (isSuccess && typeof afterDelete === 'function') {
        afterDelete();
      }
    }
  };

  const isValidResponse = (response) => {
    return (
      response &&
      response.status === 200 &&
      response.data !== null &&
      response.data.success === true
    );
  };

  return displayOnly ? (
    <DeleteBorderButton sx={{ border: 0 }} variant="text" />
  ) : (
    <>
      <DeleteDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        title="Delete test"
        itemName={testName || ''}
        isLoading={deleteInProgress}
        handleDelete={deleteProject}
      />
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
      <DeleteBorderButton
        variant="text"
        clickAction={() => setDialogOpen(true)}
        sx={{ border: 0 }}
        {...buttonDisabledProps}
      />
    </>
  );
}
