import React, { useEffect } from 'react';

import { useFloorplanContext } from 'Components/Floorplans/context';

const ParameterProvider = ({ projectId, buildingId, testId, floorplanId }) => {
  const { setProjectId, setBuildingId, setTestId, setFloorplanId } =
    useFloorplanContext();

  useEffect(() => {
    if (typeof setProjectId === 'function') setProjectId(projectId);
  }, [projectId]);

  useEffect(() => {
    if (typeof setBuildingId === 'function') setBuildingId(buildingId);
  }, [buildingId]);

  useEffect(() => {
    if (typeof setTestId === 'function') setTestId(testId);
  }, [testId]);

  useEffect(() => {
    if (typeof setFloorplanId === 'function') setFloorplanId(floorplanId);
  }, [floorplanId]);

  return <div></div>;
};

export default ParameterProvider;
