import { ERROR_AUTOCLOSE_TIMEOUT_MS } from 'Constants';
import React, { useState } from 'react';

import EditBorderButton from '../../Buttons/EditBorderButton';
import AddEditUserModal from '../AddEditUserModal';

import users from 'Api/users';

import ErrorMessage from 'Components/UI/ErrorMessage';

import { useIsMounted } from 'Context';

import { isValidResponse } from 'Utils';

export default function EditUserBtn({
  displayOnly = false,
  afterEdit,
  companyName,
  rolesData,
  disabled,
  user,
  sx,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [editInProgress, setEditInProgress] = useState(false);
  const [mainError, setMainError] = useState('');
  const mounted = useIsMounted();

  const editUser = async (values) => {
    setEditInProgress(true);
    let isSuccess = false;
    try {
      const bodyObj = {
        ...values,
        companyName,
      };

      const response = await users.updateOneUser(user.userid, bodyObj);
      isSuccess = isValidResponse(response);
      if (!isSuccess) {
        throw new Error(response?.data?.error?.message);
      }
    } catch (err) {
      console.log('Edit user error: ', err);
      setMainError(err?.message || `Couldn't update user`);
      setTimeout(() => setMainError(''), ERROR_AUTOCLOSE_TIMEOUT_MS);
    } finally {
      if (mounted.current) {
        setEditInProgress(false);
      }
      if (isSuccess && typeof afterEdit === 'function') {
        if (mounted.current) {
          setModalOpen(false);
        }
        afterEdit();
      }
    }
  };

  return displayOnly ? (
    <EditBorderButton
      disableStyles
      extraBtnProps={{ disableFocusRipple: true, disabled }}
      sx={{ border: 0, ...sx }}
      variant="text"
    />
  ) : (
    <>
      <EditBorderButton
        disableStyles={true}
        clickAction={() => setModalOpen(true)}
        extraBtnProps={{ disableFocusRipple: true, disabled }}
        sx={{ ...sx }}
        variant="text"
      />
      <AddEditUserModal
        open={modalOpen}
        type="edit"
        handleClose={() => setModalOpen(false)}
        handleSubmit={(values) => editUser(values)}
        actionsDisabled={editInProgress}
        rolesData={rolesData}
        user={user}
      />
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
    </>
  );
}
