import { createSlice } from '@reduxjs/toolkit';

function startLoading(state) {
  state.isLoading = true;
}

function finishLoading(state) {
  state.isLoading = false;
}

function loadingFailed(state, action) {
  state.isLoading = false;
  state.error = action.payload;
}

export const slice = createSlice({
  name: 'compare',
  initialState: {
    isShowMenu: false,
    isClearAll: false,
    isLoading: false,
    error: null,
  },
  reducers: {
    setStartLoading: startLoading,
    setFinishLoading: finishLoading,
    setLoadingFailed: loadingFailed,
    setNotError: (state) => {
      state.error = null;
    },
    setClearAll: (state, action) => {
      state.isClearAll = action.payload;
    },
    setShowMenu: (state, action) => {
      state.isShowMenu = action.payload;
    },
  },
});

export const {
  setStartLoading,
  setFinishLoading,
  setLoadingFailed,
  setNotError,
  setShowMenu,
  setClearAll,
} = slice.actions;

export default slice.reducer;

export const compareSelector = (state) => state.compare;
