import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import Title from 'Components/Mix/Title';

import { getUserData } from 'Utils';

export default function Profile() {
  const [profile, setProfile] = useState([]);

  useEffect(() => {
    setProfile(getUserData());
  }, []);

  return (
    <>
      <Title text={'Profile'} />
      <Box sx={{ ml: 3, mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <Typography variant="body2">First name:</Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="subtitle2" color="primary">
              {profile?.firstName}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body2">Last name:</Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="subtitle2" color="primary">
              {profile?.lastName}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body2">Email:</Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="subtitle2" color="primary">
              {profile?.email}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body2">Company:</Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="subtitle2" color="primary">
              {profile?.companyName}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body2">Role:</Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="subtitle2" color="primary">
              {profile?.roleName}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
