import { DEFAULT_ROWS_AMOUNT_PER_TABLE_PAGE } from 'Constants';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Stack } from '@mui/material';

import projectsApi from 'Api/projects';

import InputSearch from 'Components/Mix/InputSearch';
import Title from 'Components/Mix/Title';
import ProjectsTable from 'Components/Projects/ProjectsTable';
import ErrorMessage from 'Components/UI/ErrorMessage';

import {
  isPartnerUser,
  isSafetracesAdmin,
  isSafetracesUser,
} from 'Config/roles';

import {
  changePartnerViewCompany,
  changeSafetracesViewCompany,
  useAuthDispatch,
  useAuthState,
  useIsMounted,
} from 'Context';

import { setPageTitle } from 'Features/Main/mainSlice';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  isValidResponse,
} from 'Utils';

export default function Projects() {
  const [projectsList, setProjectsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mainError, setMainError] = useState('');
  const [page, setPage] = useState(0);
  const [totalProjects, setTotalProjects] = useState(10);
  const [orderBy, setOrderBy] = useState('createdate');
  const [order, setOrder] = useState('desc');
  const [query, setQuery] = useState('');

  const [shouldIgnoreCompanyChange, setShouldIgnoreCompanyChange] =
    useState(false);

  const globalDispatch = useDispatch();
  globalDispatch(setPageTitle('Projects'));

  const mounted = useIsMounted();
  const authState = useAuthState();
  const dispatch = useAuthDispatch();

  useEffect(() => {
    fetchProjects();
  }, [page, orderBy, order, query]);

  useEffect(() => {
    if (!shouldIgnoreCompanyChange) {
      setTotalProjects(10);
      setPage(0);

      fetchProjects();
    }
    setShouldIgnoreCompanyChange(false);
  }, [authState.viewCompanyId]);

  const fetchProjects = async () => {
    setIsLoading(true);
    const now = Date.now();
    try {
      const response = await projectsApi.getAllProjects({
        params: {
          pageLimit: DEFAULT_ROWS_AMOUNT_PER_TABLE_PAGE,
          page,
          company:
            isSafetracesAdmin() && !!query ? '' : authState.viewCompanyId,
          pageOrder: orderBy,
          direction: order,
          query,
        },
      });

      if (!isValidResponse(response))
        throw new Error(getErrorMessageFromResponse(response));

      const { projects, total } = getDataFromResponse(response);
      if (!mounted.current) return;

      if (Date.now() - now < 500) await wait(500);

      setProjectsList(projects.length ? projects : []);
      setTotalProjects(total);
      if (projects.length === 1) {
        const { endCustomerId, endCustomer } = projects[0];
        if (authState.viewCompanyId !== endCustomerId) {
          setShouldIgnoreCompanyChange(true);
          if (isPartnerUser()) {
            changePartnerViewCompany(dispatch, endCustomerId, endCustomer);
          }
          if (isSafetracesUser()) {
            changeSafetracesViewCompany(dispatch, endCustomerId, endCustomer);
          }
        }
      }
      setIsLoading(false);
    } catch (e) {
      if (mounted.current) {
        if (Date.now() - now < 500) {
          await wait(500);
        }
        setProjectsList([]);
        setIsLoading(false);
      }
      console.log(e.toString());
    }
  };

  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
      <Stack direction="column" justifyContent="space-between">
        <Title text="Projects" />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 1, mt: 2 }}
        >
          <Box style={{ width: '240px' }}>
            <InputSearch
              placeholder="Search projects"
              onChange={(value) => setQuery(value)}
              delayMs={500}
            />
          </Box>
        </Stack>
        <ProjectsTable
          projectsList={projectsList}
          page={page}
          rowsPerPage={DEFAULT_ROWS_AMOUNT_PER_TABLE_PAGE}
          totalProjects={totalProjects}
          setPage={setPage}
          setProjectsList={setProjectsList}
          isLoading={isLoading}
          afterProjectsListUpdate={fetchProjects}
          onRequestSort={onRequestSort}
          orderBy={orderBy}
          order={order}
        />
      </Stack>
    </>
  );
}
