import React from 'react';

import { useTestScenariosContext } from '../context';

import DeleteIconButton from 'Components/Buttons/DeleteIconButton';

export default function DeleteScenarioButton({ scenarioId, disabled }) {
  const {
    startDeleteProcess,
    editPermission,
    forceRestrictAllEdits,
    forceRestrictAllEditsReason,
  } = useTestScenariosContext();

  return (
    <DeleteIconButton
      disabled={!editPermission || forceRestrictAllEdits || disabled}
      tooltipText={forceRestrictAllEditsReason || ''}
      onClick={() => startDeleteProcess(scenarioId)}
    />
  );
}
