import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import './styles.css';

import TestsItem from 'Components/ResultTests/TestsItem';

import {
  ACCESS_LEVEL_ADMIN,
  ACCESS_LEVEL_EDITOR,
  ROLE_PARTNER,
  ROLE_SAFETRACES,
} from 'Config/roles';

import { getUserData } from 'Utils';

export default function TestsDraggableList({
  tests,
  buildingId,
  isLoading,
  afterUpdate,
}) {
  const { roleName, accessLevel } = getUserData();
  const dragPermission =
    !!~[ACCESS_LEVEL_ADMIN, ACCESS_LEVEL_EDITOR].indexOf(accessLevel) &&
    !!~[ROLE_PARTNER, ROLE_SAFETRACES].indexOf(roleName);

  return (
    <>
      {tests.map((item, index) => (
        <Draggable
          draggableId={`test-${item.testid}`}
          index={index}
          key={item.testid}
          isDragDisabled={!dragPermission || isLoading}
        >
          {(provided, snapshot) => {
            const style = {
              boxShadow: snapshot.isDragging
                ? 'rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px'
                : '',
              width: 'fit-content',
              marginBottom: '12px',
              borderRadius: '6px',
              padding: '6px 0px',
              backgroundColor: 'white',
              ...provided.draggableProps.style,
            };

            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                style={style}
              >
                <TestsItem
                  name={item.testname}
                  testId={item.testid}
                  surveyDate={item.surveydate}
                  resultNumber={item.resultnumber}
                  displayNumber={item.displayNumber}
                  allReviewed={item.allReviewed}
                  atLeastOneReviewed={item.atLeastOneReviewed}
                  buildingId={buildingId}
                  key={item.testid}
                  isDraggable={dragPermission}
                  dragHandleProps={provided.dragHandleProps || {}}
                  afterUpdate={afterUpdate}
                />
              </div>
            );
          }}
        </Draggable>
      ))}
    </>
  );
}
