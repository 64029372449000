import React from 'react';

const darkCell = {
  background: 'rgb(0, 137, 150)',
  color: 'white',
  padding: '5px',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: 'smaller',
};

const lightCell = {
  background: 'white',
  color: 'black',
  padding: '5px',
  textAlign: 'center',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: 'smaller',
};

const TestSprayTable = ({ details, test }) => {
  const sprayers = new Set();
  if (Array.isArray(details)) {
    details.forEach((d) => {
      sprayers.add(d.sprayerName);
    });
  }
  if (sprayers.size === 0) sprayers.add('n/a');

  return (
    <table
      style={{ border: '1px solid gray', width: '99.5%', borderSpacing: 0 }}
    >
      <thead>
        <tr>
          <td style={darkCell}>Spray Type:</td>
          <td style={lightCell}>{Array.from(sprayers.values()).join(', ')}</td>

          <td style={darkCell}>Sample Type:</td>
          <td style={lightCell}>{test.collectionMethods.join(', ')}</td>

          <td style={darkCell}>Sample Flow:</td>
          <td style={lightCell}>{test.airSamplerRate || ''}</td>

          <td style={darkCell}>Interval Duration:</td>
          <td style={lightCell}>{test.intervalDuration || ''}</td>

          <td style={darkCell}>Interval Count:</td>
          <td style={lightCell}>{test.intervalCount || ''}</td>
        </tr>
      </thead>
    </table>
  );
};

export default TestSprayTable;
