import { COMPANY_TYPES } from 'Constants';
import React, { forwardRef } from 'react';
import { useForm } from 'react-hook-form';

import { Grid, MenuItem, TextField, Typography } from '@mui/material';

const CompanyForm = forwardRef((props, ref) => {
  const { onSubmit, defaultValues, keyFieldsDisabled } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues || {},
  });

  return (
    <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <TextField
          variant="outlined"
          margin="dense"
          disabled={keyFieldsDisabled}
          fullWidth
          label="Company name"
          {...register('companyname', {
            required: 'Company name is required',
          })}
          error={!!(errors && errors.companyname)}
          helperText={errors?.companyname?.message}
        />
        <TextField
          variant="outlined"
          margin="normal"
          multiline
          minRows={2}
          fullWidth
          label="Address"
          {...register('companyaddress', {
            required: 'Company address is required',
          })}
          error={!!(errors && errors.companyaddress)}
          helperText={errors?.companyaddress?.message}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Contact email"
          {...register('contact', {
            required: 'Contact email is required',
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Entered value does not match email format',
            },
          })}
          error={!!(errors && errors.contact)}
          helperText={errors?.contact?.message}
        />
        <TextField
          select
          variant="outlined"
          margin="normal"
          disabled={keyFieldsDisabled}
          fullWidth
          sx={{ w: '50%' }}
          defaultValue={defaultValues?.type || ''}
          label="Company type"
          {...register('type', {
            required: 'Company type is required',
          })}
          error={!!(errors && errors.type)}
          helperText={errors?.type?.message}
        >
          {Object.values(COMPANY_TYPES).map((type) => (
            <MenuItem key={type} value={type}>
              <Typography>{type}</Typography>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </form>
  );
});

CompanyForm.displayName = 'CompanyForm';

export default CompanyForm;
