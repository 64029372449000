import React from 'react';

import { Stack } from '@mui/material';

import BuildingOverview from 'Components/Dashboard/BuildingOverview';
import ErrorAlert from 'Components/Dashboard/ErrorAlert';
import MainOverview from 'Components/Dashboard/MainOverview';
import NavigationListMenu from 'Components/Dashboard/NavigationListMenu';
import PortfolioOverview from 'Components/Dashboard/PortfolioOverview';
import { DashboardContextProvider } from 'Components/Dashboard/context';
import Title from 'Components/Mix/Title';

export default function Dashboard({ type }) {
  return (
    <DashboardContextProvider type={type}>
      <Stack direction="column" justifyContent="space-between" gap={2}>
        <NavigationListMenu />
        <Title text="Dashboard" />
        <ErrorAlert />
        {type === 'all' ? <MainOverview /> : null}
        {type === 'portfolio' ? <PortfolioOverview /> : null}
        {type === 'building' ? <BuildingOverview /> : null}
      </Stack>
    </DashboardContextProvider>
  );
}
