import React from 'react';
import { useDrag } from 'react-dnd';

import './styles.css';

import { isViewer } from 'Config/roles';

const HDA_COLORS = [
  '#FF5733',
  '#33FF57',
  '#3357FF',
  '#FF33C6',
  '#33C6FF',
  '#FFD933',
  '#D933FF',
  '#CDFADB',
  '#7A33FF',
  '#944E63',
];

const PlaceableHDAItem = ({
  hda,
  markerSize,
  colorIndex,
  HDAtypesByApplication,
  updateInProgress,
  handleClick,
}) => {
  const hdaType =
    Object.values(HDAtypesByApplication).find((e) => e.spaceTypes[hda.type])
      ?.spaceTypes[hda.type] || 'N/A';

  const [, drag] = useDrag({
    type: 'HDA',
    item: {
      ...hda,
      type: 'HDA',
      id: hda.id,
    },
    canDrag: !isViewer() && !updateInProgress,
  });

  // Adjust icon and font size
  const width = 150 * (markerSize / 7.5) ?? 50;
  const fontSize = 10 * (markerSize / 3) ?? 16;

  // Place the icon in the middle
  const left = hda.coordX - width / 2;
  const top = hda.coordY - width / 2;

  if (hda.coordX >= 0 || hda.coordY >= 0) {
    return (
      <div
        ref={drag}
        className="object"
        style={{
          position: 'absolute',
          left,
          top,
          width: `${width}px`,
          height: `${width}px`,
        }}
        onClick={handleClick}
      >
        <svg height="100%" width="100%" preserveAspectRatio="xMidYMid meet">
          <circle
            cx="50%"
            cy="50%"
            r="40%"
            stroke={HDA_COLORS[colorIndex]}
            style={
              updateInProgress ? { animation: 'opacityPulse 2s infinite' } : {}
            }
            strokeWidth="10%"
            fill="linen"
          />
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize={`${fontSize}px`}
          >
            {hdaType.slice(0, 3).toUpperCase()}
          </text>
        </svg>
      </div>
    );
  }
  return null;
};

export default PlaceableHDAItem;
