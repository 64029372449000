import React, { useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';

import { useFloorplanContext } from '../context';
import { scaleCoordinates } from '../utils';
import FloorPlanImage from './FloorPlanImage';

import { Stack } from '@mui/material';

import floorplansApi from 'Api/floorPlans';
import originPoints from 'Api/originpoints';
import samplePoints from 'Api/samplePoints';

const FloorplanComponent = ({
  testId,
  OPs,
  SPs,
  reFetchOpsAndSps,
  markerSize,
  isImageLoaded,
  setIsImageLoaded,
  setUpdateStatus,
}) => {
  const { setError, setIsLoading, location } = useFloorplanContext();
  const [floorPlanImageData, setFloorPlanImageData] = useState(null);
  const [naturalDimensions, setNaturalDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [scaledDimensions, setScaledDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [scaledOPs, setScaledOPs] = useState([]);
  const [scaledSPs, setScaledSPs] = useState([]);
  const boundingBox = useRef(null);
  const [, drop] = useDrop(
    () => ({
      accept: ['OP', 'SP'],
      drop: (item, monitor) => {
        const offset = monitor.getClientOffset();
        if (offset && boundingBox.current) {
          const scaledX =
            offset.x - boundingBox.current.getBoundingClientRect().left;
          const scaledY =
            offset.y - boundingBox.current.getBoundingClientRect().top;
          const naturalCoords = scaleCoordinates({
            fromDimensions: scaledDimensions,
            toDimensions: naturalDimensions,
            fromCoordinates: {
              x: scaledX,
              y: scaledY,
            },
          });
          updateCoordinates({
            opid: item.type === 'OP' ? item.id : undefined,
            spid: item.type === 'SP' ? item.id : undefined,
            x: naturalCoords.x,
            y: naturalCoords.y,
            opname: !item.opname ? item.opnumber : undefined,
            spname: !item.spname ? item.spnumber : undefined,
          });
        }
      },
    }),
    [naturalDimensions, scaledDimensions],
  );

  useEffect(() => {
    setScaledOPs(
      OPs.map((op) => {
        const coords = scaleCoordinates({
          fromDimensions: naturalDimensions,
          toDimensions: scaledDimensions,
          fromCoordinates: {
            x: Number.parseInt(op.opcoordx),
            y: Number.parseInt(op.opcoordy),
          },
        });
        return {
          ...op,
          opcoordx: coords.x,
          opcoordy: coords.y,
        };
      }),
    );
  }, [OPs, naturalDimensions, scaledDimensions]);

  useEffect(() => {
    setScaledSPs(
      SPs.map((sp) => {
        const coords = scaleCoordinates({
          fromDimensions: naturalDimensions,
          toDimensions: scaledDimensions,
          fromCoordinates: {
            x: Number.parseInt(sp.spcoordx),
            y: Number.parseInt(sp.spcoordy),
          },
        });
        return {
          ...sp,
          spcoordx: coords.x,
          spcoordy: coords.y,
        };
      }),
    );
  }, [SPs, naturalDimensions, scaledDimensions]);

  useEffect(() => {
    fetchFloorPlanImage(testId);
  }, [testId]);

  const fetchFloorPlanImage = async (testId) => {
    if (testId) {
      setIsLoading(true);
      try {
        const req = await floorplansApi.getFloorPlanImageByTestId(testId);
        if (req.status === 200 && req.data?.preSignedURL) {
          if (req.data?.metadata?.mainfloorplanid) {
            // We have a cropped image, don't check natural dimensions, use
            // the width/height we already have saved in the DB
            setNaturalDimensions({
              width: req.data?.metadata?.cropData?.width,
              height: req.data?.metadata?.cropData?.height,
            });
          } else {
            // We're dealing with a regular floor plan,
            // check image's natural dimensions
            const img = new Image();
            img.src = req.data.preSignedURL;
            img.onload = () => {
              setNaturalDimensions({
                width: img.naturalWidth,
                height: img.naturalHeight,
              });
            };
          }

          setFloorPlanImageData({
            url: req.data.preSignedURL,
            cropData: req?.data?.metadata?.cropData,
          });
        } else {
          setError(
            `No floorplan was found for test number ${testId}. Please upload or assign a floorplan image.`,
          );
        }
      } catch (e) {
        console.error('Error loading the floorplan image', e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const updateCoordinates = async ({ spid, opid, x, y, opname, spname }) => {
    setUpdateStatus('loading');
    try {
      if (opid) {
        const resp = await originPoints.updateOriginPoint({
          OPId: opid,
          OPCoordX: x,
          OPCoordY: y,
          OPName: opname,
        });
        if (resp.data && resp.data?.success) {
          await reFetchOpsAndSps();
        } else {
          setError('Error occurred while updating the OP details');
          setUpdateStatus('error');
        }
      }
      if (spid) {
        const resp = await samplePoints.updateSamplePoint({
          SPId: spid,
          SPCoordX: x,
          SPCoordY: y,
          SPName: spname,
        });
        if (resp.data && resp.data?.success) {
          await reFetchOpsAndSps();
        } else {
          setError('Error occurred while updating the SP details');
          setUpdateStatus('error');
        }
      }
      setUpdateStatus('success');
      setTimeout(() => {
        setUpdateStatus('idle');
      }, 5000);
    } catch (e) {
      console.error('Error when updating the OP/SP', e);
      setError('Error when updating the OP/SP');
    }
  };

  return (
    <Stack
      sx={
        location === 'heatmapPage'
          ? { width: '100%' }
          : {
              width: `min(calc(75vh * ${
                naturalDimensions.width / naturalDimensions.height
              }), 75%)`,
            }
      }
    >
      <FloorPlanImage
        dropRef={drop}
        boundingRef={boundingBox}
        imageData={floorPlanImageData}
        setScaledDimensions={setScaledDimensions}
        naturalDimensions={naturalDimensions}
        scaledDimensions={scaledDimensions}
        isImageLoaded={isImageLoaded}
        setIsImageLoaded={setIsImageLoaded}
        OPs={scaledOPs}
        SPs={scaledSPs}
        markerSize={markerSize}
      />
    </Stack>
  );
};

export default FloorplanComponent;
