import React from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import { Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function TableFilterBtn({
  config,
  updateFilters,
  onOk,
  onReset,
  disabled,
}) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const choiceItem = (key, label, value, i) => {
    return (
      <Stack key={i} direction="row" style={{ alignItems: 'center' }}>
        <Checkbox
          checked={value}
          onChange={(e) => updateFilters(key, e.target.checked)}
          size="small"
        />
        <Typography>{label}</Typography>
      </Stack>
    );
  };

  if (!config || !Object.keys(config) || !Object.keys(config).length) return;

  const handleConfirm = () => {
    onOk();
    setOpen(false);
  };

  const handleReset = () => {
    onReset();
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <IconButton
          aria-label="filter"
          sx={{
            marginLeft: '6px',
          }}
          disabled={disabled}
          onClick={(e) => handleClick(e)}
        >
          <FilterListIcon sx={{ color: 'white', fontSize: '18px' }} />
        </IconButton>
        <Popper open={open} anchorEl={anchorEl} placement="bottom" transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={200}>
              <Paper style={{ paddingTop: '8px' }}>
                <Stack direction="column" style={{ marginBottom: '8px' }}>
                  {Object.keys(config).map((key, i) =>
                    choiceItem(key, config[key].label, config[key].value, i),
                  )}
                </Stack>
                <Stack direction="row">
                  <Button onClick={handleConfirm} size="small">
                    Ok
                  </Button>
                  <Button onClick={handleReset} size="small">
                    Reset
                  </Button>
                </Stack>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
