import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'floorPlanCrop',
  initialState: {
    images: {},
  },
  reducers: {
    addImage: (state, action) => {
      state.images[action.payload] = {
        loading: true,
        src: action.payload,
        shift: {},
        image: '',
      };
    },
    setShift: (state, action) => {
      state.images[action.payload.src] = {
        ...state.images[action.payload.src],
        ...action.payload,
      };
    },
  },
});

// export const doCropImage = (src) => async (dispatch, getState) => {
//   if (typeof getState().floorPlanCrop.images[src] !== 'undefined') {
//     return;
//   }
//   dispatch(addImage(src));
//   dispatch(setShift({ src, shift, image: data.url, loading: false }));
// };

export const doCropImage = (src) => async (dispatch, getState) => {
  if (typeof getState().floorPlanCrop.images[src] !== 'undefined') {
    return;
  }
  dispatch(addImage(src));
  const myImage = new Image();
  myImage.crossOrigin = 'Anonymous';
  myImage.onload = function () {
    console.log('removeHalfImageBlanksStateless');
    const shift = {
      left: 0,
      top: 0,
    };
    removeHalfImageBlanks(myImage).then((data) => {
      shift.left = data.leftShift;
      shift.top = data.topShift;
      const myImage = new Image();
      myImage.crossOrigin = 'Anonymous';
      myImage.onload = function () {
        removeHalfImageBlanks(myImage, true).then((data) => {
          shift.left += data.leftShift;
          shift.top += data.topShift;
          dispatch(setShift({ src, shift, image: data.url, loading: false }));
        });
      };
      myImage.src = data.url;
    });
  };
  myImage.src = src;
};

const removeHalfImageBlanks = async (imageObject, onlyblanks = false) => {
  const imgWidth = imageObject.width;
  const imgHeight = imageObject.height;

  const canvas = document.createElement('canvas');
  canvas.setAttribute('width', imgWidth);
  canvas.setAttribute('height', imgHeight);
  const context = canvas.getContext('2d');
  context.drawImage(imageObject, 0, 0);

  const oc = document.createElement('canvas'),
    octx = oc.getContext('2d');

  const halfImgWidth = Math.round(imgWidth * 0.5);
  const halfImgHeight = Math.round(imgHeight * 0.5);
  oc.setAttribute('width', halfImgWidth);
  oc.setAttribute('height', halfImgHeight);

  octx.drawImage(imageObject, 0, 0, halfImgWidth, halfImgHeight);

  const cropThreshold = 1;

  const imageData = octx.getImageData(0, 0, halfImgWidth, halfImgHeight);
  const data = imageData.data;
  const getRBG = (x, y) => {
    const offset = halfImgWidth * y + x;
    return {
      red: data[offset * 4],
      green: data[offset * 4 + 1],
      blue: data[offset * 4 + 2],
      opacity: data[offset * 4 + 3],
    };
  };
  const isWhite = (rgb) => {
    // many images contain noise, as the white is not a pure #fff white
    return (
      rgb.opacity === 0 || (rgb.red > 200 && rgb.green > 200 && rgb.blue > 200)
    );
  };

  const scanWhiteSpaces = (direction, fromStart) => {
    const offset = fromStart ? 1 : -1;

    for (
      let y = fromStart
        ? 0
        : (direction === 'height' ? halfImgHeight : halfImgWidth) - 1;
      fromStart
        ? y < (direction === 'height' ? halfImgHeight : halfImgWidth)
        : y > -1;
      y += offset
    ) {
      let count = 0;
      for (
        let x = 0;
        x < (direction !== 'height' ? halfImgHeight : halfImgWidth);
        x++
      ) {
        const rgb = getRBG(
          direction !== 'height' ? y : x,
          direction === 'height' ? y : x,
        );
        if (!isWhite(rgb)) {
          count++;
        }
      }
      if (count > cropThreshold) {
        if (fromStart) {
          return y * 2;
        } else {
          return Math.min(
            (y + 1) * 2,
            direction === 'height' ? imgHeight : imgWidth,
          );
        }
      }
    }
    return null; // all image is white
  };

  const scanBlanks = (direction) => {
    if (onlyblanks) {
      return [];
    }
    const blanks = [];

    const lastPoint = direction === 'height' ? halfImgHeight : halfImgWidth;

    let current = 0;
    let lastIsWhite = false;
    let lastIsBlack = false;
    let lastBlack = 0;
    for (let y = 0; y < lastPoint; y++) {
      let count = 0;
      for (let x = 0; x < lastPoint; x++) {
        const rgb = getRBG(
          direction !== 'height' ? y : x,
          direction === 'height' ? y : x,
        );
        if (!isWhite(rgb)) {
          count++;
        }
      }
      if (count > 1 && lastIsWhite) {
        blanks.push([current > 0 ? current * 2 - 1 : 0, (y - 1) * 2 - 1]);
        current = y;
      }
      if (y === lastPoint - cropThreshold && lastIsWhite) {
        blanks.push([(lastBlack + 1) * 2 - 1, y * 2 - 1]);
        current = y;
      }
      lastIsWhite = count <= cropThreshold;
      lastIsBlack = !lastIsWhite;
      if (lastIsBlack) {
        lastBlack = y;
      }
      if (blanks.length > 2) {
        break;
      }
    }

    return blanks; // all image is white
  };

  const crop = {};
  crop['Top'] = scanWhiteSpaces('height', true);
  crop['Bottom'] = scanWhiteSpaces('height', false);
  crop['Left'] = scanWhiteSpaces('width', true);
  crop['Right'] = scanWhiteSpaces('width', false);
  crop['Width'] = crop['Right'] - crop['Left'];
  crop['Height'] = crop['Bottom'] - crop['Top'];
  crop['BlanksTop'] = scanBlanks('height', true);
  crop['BlanksLeft'] = scanBlanks('width', true);

  const getCropPoints = (crop) => {
    const halfHeight = imgHeight / 2;
    const halfWidth = imgWidth / 2;

    const points = {
      startX: 0,
      startY: 0,
      finishX: 999999,
      finishY: 999999,
    };

    if (crop['BlanksTop'].length > 0) {
      crop['BlanksTop'].forEach((value) => {
        if (value[1] < halfHeight) {
          points.startY = value[1];
        }
        if (value[0] > halfHeight && points.finishY === 0) {
          points.finishY = value[0];
        }
      });
    } else {
      points.startY = 0;
      points.finishY = imgHeight;
    }

    if (crop['BlanksLeft'].length > 0) {
      crop['BlanksLeft'].forEach((value) => {
        if (value[1] < halfWidth) {
          points.startX = value[1];
        }
        if (value[0] > halfWidth && points.finishX === 0) {
          points.finishX = value[0];
        }
      });
    } else {
      points.startX = 0;
      points.finishX = imgWidth;
    }

    if (points.startY < crop.Top) {
      points.startY = crop.Top;
    }
    if (points.finishY > crop.Bottom) {
      points.finishY = crop.Bottom;
    }

    if (points.startX < crop.Left) {
      points.startX = crop.Left;
    }
    if (points.finishX > crop.Right) {
      points.finishX = crop.Right;
    }
    points.width = points.finishX - points.startX;
    points.height = points.finishY - points.startY;
    return points;
  };

  const points = getCropPoints(crop);

  const imageShift = 200;

  // finally crop the guy
  if (onlyblanks) {
    canvas.setAttribute('width', points.width + imageShift * 2);
    canvas.setAttribute('height', points.height + imageShift * 2);
    canvas
      .getContext('2d')
      .drawImage(
        imageObject,
        points.startX,
        points.startY,
        points.width,
        points.height,
        imageShift,
        imageShift,
        points.width,
        points.height,
      );
  } else {
    canvas.setAttribute('width', points.width);
    canvas.setAttribute('height', points.height);
    canvas
      .getContext('2d')
      .drawImage(
        imageObject,
        points.startX,
        points.startY,
        points.width,
        points.height,
        0,
        0,
        points.width,
        points.height,
      );
  }

  return {
    url: canvas.toDataURL(),
    leftShift: onlyblanks ? points.startX - imageShift : points.startX,
    topShift: onlyblanks ? points.startY - imageShift : points.startY,
  };
};

export const { addImage, setShift } = slice.actions;

export default slice.reducer;

export const floorPlanCropSelector = (state) => state.floorPlanCrop;
