import React, { useCallback, useContext, useState } from 'react';

import { BuildingsSitesContext } from '../index';
import '../styles.css';
import BuildingsDroppableBox from './BuildingsDroppableBox';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MapIcon from '@mui/icons-material/Map';
import { Stack, Typography } from '@mui/material';
import { IconButton } from '@mui/material';

import buildings from 'Api/buildings';

import AddEditBuildingModal from 'Components/BuildingDetails/Modals/AddEditBuildingModal';

import { useIsMounted } from 'Context';

import { getDataFromResponse, isValidResponse } from 'Utils';

const SingleSite = ({ site, disableActions, loading, mode = 'view' }) => {
  const buildingsHandlers = useContext(BuildingsSitesContext);
  const mounted = useIsMounted();
  const [openAddBuilding, setOpenAddBuilding] = useState(false);

  const handleAddBuilding = useCallback(
    async (values) => {
      const result = {
        success: false,
        newBuilding: {},
      };

      try {
        const {
          buildingname,
          city,
          country,
          postalCode,
          state,
          address,
          totalsqft,
          numberoffloors,
          floorsqeft,
          outdoorAirPercentage,
          age,
        } = values;

        const bodyObj = {
          buildingName: buildingname,
          buildingMetadata: {
            city: city || '',
            country: country || '',
            postalCode: postalCode || '',
            state: state || '',
            address: address || '',
            age,
            outdoorAirPercentage,
          },
          totalsqft,
          numberoffloors,
          floorsqeft,
        };

        const response = await buildings.createBuildingSingleSite(
          site.id,
          bodyObj,
        );
        if (isValidResponse(response)) {
          const newBuilding = getDataFromResponse(response);

          result.success = true;
          result.newBuilding = newBuilding;
        }
      } catch (err) {
        console.log(`handleAddBuilding Error: `, err);
      } finally {
        const { success, newBuilding } = result;
        if (typeof buildingsHandlers.afterBuildingAdd === 'function') {
          buildingsHandlers.afterBuildingAdd(success, newBuilding);
        }

        if (success && mounted.current) {
          setOpenAddBuilding(false);
        }

        return result;
      }
    },
    [site.id, buildingsHandlers.afterBuildingAdd],
  );

  if (!site || !site.buildings || (mode === 'view' && !site.buildings.length))
    return null;

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center" sx={{ py: 0.5 }}>
        <MapIcon
          fontSize="small"
          color="disabled"
          style={{
            position: 'relative',
            top: '2px',
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        />
        <Typography
          variant="h6"
          color="text.secondary"
          style={{
            fontVariant: 'all-petite-caps',
            fontWeight: 'bolder',
            fontSize: '1.4em',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {site.name}
        </Typography>
        {mode === 'edit' && (
          <IconButton
            size="small"
            style={{ position: 'relative', top: '2px' }}
            onClick={() => setOpenAddBuilding(true)}
            disabled={disableActions}
          >
            <AddCircleOutlineIcon fontSize="small" color="primary" />
          </IconButton>
        )}
      </Stack>
      <BuildingsDroppableBox
        siteId={site.id}
        buildings={site.buildings}
        mode={mode}
        boxStyle={{ paddingLeft: '24px' }}
        disableActions={disableActions}
        loading={loading}
      />
      {openAddBuilding && (
        <AddEditBuildingModal
          open={openAddBuilding}
          handleSubmit={handleAddBuilding}
          handleClose={() => setOpenAddBuilding(false)}
        />
      )}
    </>
  );
};

export default SingleSite;
