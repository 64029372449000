import React, { useEffect } from 'react';

import { Divider, Stack } from '@mui/material';

import EditDetailsModal from 'Components/Portfolios/EditDetailsModal';
import PortfolioItem from 'Components/Portfolios/PortfolioItem/PortfolioItem';
import { usePortfolioContext } from 'Components/Portfolios/context';
import NoDataDisplay from 'Components/UI/NoDataDisplay';
import SkeletonGroupLoading from 'Components/UI/SkeletonGroupLoading';

const PortfolioList = ({ viewCompanyId }) => {
  const {
    portfolios,
    sites,
    fetchPortfoliosAndSites,
    requestTestsCoverageDataForPortfolioUpdate,
    isLoading,
    initialLoading,
  } = usePortfolioContext();

  useEffect(() => {
    fetchPortfoliosAndSites(true);
  }, [viewCompanyId]);

  if (initialLoading) return <SkeletonGroupLoading />;

  return (
    <Stack>
      <Divider sx={{ borderColor: 'grey.300' }} />
      {Object.values(portfolios).map((portfolio) => (
        <PortfolioItem
          key={`p_${portfolio.portfolioid}`}
          portfolio={portfolio}
          portfolioSites={sites[portfolio.portfolioid]?.sites}
          portfolioTerritories={sites[portfolio.portfolioid]?.territories}
          portfolioRegions={sites[portfolio.portfolioid]?.regions}
          requestTestsCoverageDataForPortfolioUpdate={
            requestTestsCoverageDataForPortfolioUpdate
          }
        />
      ))}
      {!Object.keys(portfolios).length && !isLoading && <NoDataDisplay />}
      <EditDetailsModal />
    </Stack>
  );
};
export default PortfolioList;
