import React, { useMemo } from 'react';

import SingleSite from './PortfolioCard/SingleSite';
import './styles.css';

import { Stack } from '@mui/material';
import { CardHeader, Divider } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const PortfolioCard = ({
  name,
  sites,
  disableActions,
  mode = 'view',
  loading,
}) => {
  const SitesList = useMemo(() => {
    const list = sites.map((site) => {
      const { has_building } = site;
      if (mode === 'view' && (!has_building || !site.buildings?.length))
        return null;

      return (
        <SingleSite
          site={site}
          key={site.id}
          mode={mode}
          disableActions={disableActions}
          loading={loading}
        />
      );
    });

    let hasSite = false;
    for (let site of list) {
      if (site) {
        hasSite = true;
        break;
      }
    }

    if (!hasSite) return null;

    return list;
  }, [sites, disableActions, mode]);

  if (!SitesList) return null;

  return (
    <Card className="scroll-on-hover">
      <CardHeader title={name} titleTypographyProps={{ color: 'primary' }} />
      <Divider />
      <CardContent>
        <Stack
          spacing={1}
          style={
            mode === 'view' ? { maxHeight: '340px', overflowY: 'scroll' } : {}
          }
        >
          {SitesList}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default PortfolioCard;
