import { HEALTHCARE_TEST_TYPES, HEALTHCARE_TEST_TYPES_LABELS } from 'Constants';
import TestIcon from 'Icons/TestIcon';
import React from 'react';
import XLSX from 'xlsx-color';

import { Badge, Stack, Tooltip } from '@mui/material';

import portfoliosApi from 'Api/portfolios';
import testsApi from 'Api/tests';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  isValidResponse,
} from 'Utils';

export function getNumberOfTests(siteContract) {
  const testsInfoObj = siteContract?.testsmetadata || siteContract;
  return {
    smallSurvey: testsInfoObj?.smallsurveytests ?? 0,
    largeSurvey: testsInfoObj?.largesurveytests ?? 0,
    smallDilution: testsInfoObj?.smalldilutiontests ?? 0,
    largeDilution: testsInfoObj?.largedilutiontests ?? 0,
    miniSurvey: testsInfoObj?.minisurveytests ?? 0,
    recirculation: testsInfoObj?.recirculationtests ?? 0,
    verification: testsInfoObj?.verificationtests ?? 0,
    [HEALTHCARE_TEST_TYPES.NEGATIVE]:
      testsInfoObj?.[HEALTHCARE_TEST_TYPES.NEGATIVE] ?? 0,
    [HEALTHCARE_TEST_TYPES.NEUTRAL]:
      testsInfoObj?.[HEALTHCARE_TEST_TYPES.NEUTRAL] ?? 0,
    [HEALTHCARE_TEST_TYPES.POSITIVE]:
      testsInfoObj?.[HEALTHCARE_TEST_TYPES.POSITIVE] ?? 0,
  };
}

/**
 * Calculates the total number of tests inside the site contracts
 * @param contracts
 * @returns {{largeDilution: number, smallSurvey: number, largeSurvey: number, smallDilution: number, recirculation: number, miniSurvey: number}}
 */
export function getTotalTests(contracts) {
  const totalTests = {
    smallSurvey: 0,
    largeSurvey: 0,
    smallDilution: 0,
    largeDilution: 0,
    miniSurvey: 0,
    recirculation: 0,
    verification: 0,
    [HEALTHCARE_TEST_TYPES.NEGATIVE]: 0,
    [HEALTHCARE_TEST_TYPES.NEUTRAL]: 0,
    [HEALTHCARE_TEST_TYPES.POSITIVE]: 0,
  };

  contracts.forEach((c) => {
    if (c.sitecontracts) {
      Object.values(c.sitecontracts).forEach((sc) => {
        const testsInfoObj = sc.testsmetadata || sc;
        if (testsInfoObj.smallsurveytests) {
          totalTests.smallSurvey =
            totalTests.smallSurvey + testsInfoObj.smallsurveytests;
        }
        if (testsInfoObj.largesurveytests) {
          totalTests.largeSurvey =
            totalTests.largeSurvey + testsInfoObj.largesurveytests;
        }
        if (testsInfoObj.smalldilutiontests) {
          totalTests.smallDilution =
            totalTests.smallDilution + testsInfoObj.smalldilutiontests;
        }
        if (testsInfoObj.largedilutiontests) {
          totalTests.largeDilution =
            totalTests.largeDilution + testsInfoObj.largedilutiontests;
        }
        if (testsInfoObj.minisurveytests) {
          totalTests.miniSurvey =
            totalTests.miniSurvey + testsInfoObj.minisurveytests;
        }
        if (testsInfoObj.recirculationtests) {
          totalTests.recirculation =
            totalTests.recirculation + testsInfoObj.recirculationtests;
        }
        if (testsInfoObj.verificationtests) {
          totalTests.verification =
            totalTests.verification + testsInfoObj.verificationtests;
        }
        if (testsInfoObj[HEALTHCARE_TEST_TYPES.NEGATIVE]) {
          totalTests[HEALTHCARE_TEST_TYPES.NEGATIVE] =
            totalTests.verification +
            testsInfoObj[HEALTHCARE_TEST_TYPES.NEGATIVE];
        }
        if (testsInfoObj[HEALTHCARE_TEST_TYPES.POSITIVE]) {
          totalTests[HEALTHCARE_TEST_TYPES.POSITIVE] =
            totalTests.verification +
            testsInfoObj[HEALTHCARE_TEST_TYPES.POSITIVE];
        }
        if (testsInfoObj[HEALTHCARE_TEST_TYPES.NEUTRAL]) {
          totalTests[HEALTHCARE_TEST_TYPES.NEUTRAL] =
            totalTests.verification +
            testsInfoObj[HEALTHCARE_TEST_TYPES.NEUTRAL];
        }
      });
    }
  });

  return totalTests;
}

/**
 * Loops through the contracts, finds the earliest start dates and the latest end dates
 * @param contracts
 * @returns {{earliest: Date, latest: Date}}
 */
export function getStartEndDates(contracts) {
  const dates = {
    earliest: new Date(),
    latest: new Date(),
  };

  contracts.forEach((c) => {
    if (c.startdate) {
      if (new Date(c.startdate) < dates.earliest) {
        dates.earliest = new Date(c.startdate);
      }
    }
    if (c.enddate) {
      if (new Date(c.enddate) > dates.latest) {
        dates.latest = new Date(c.enddate);
      }
    }
  });

  return dates;
}

/**
 * Returns MUI stack with icons of the tests, with the number of tests displayed as badges.
 * @param totalTests
 * @param flexDirection
 * @param justifyContent
 * @param badgeColor
 * @param iconColor
 * @returns {JSX.Element}
 */
export function renderTestsIcons(
  totalTests,
  flexDirection = 'row',
  justifyContent = 'flex-start',
  badgeColor = 'primary',
  iconColor = '#118996',
) {
  return (
    <Stack
      direction={flexDirection}
      spacing={1}
      sx={{ mt: 2 }}
      pr={1}
      justifyContent={justifyContent}
    >
      {!!totalTests?.smallSurvey && (
        <Tooltip
          title={'Small surveys - ' + totalTests?.smallSurvey}
          placement="top"
        >
          <Badge
            badgeContent={totalTests?.smallSurvey}
            color={badgeColor}
            showZero
          >
            <TestIcon type="survey" isPadded color={iconColor} />
          </Badge>
        </Tooltip>
      )}
      {!!totalTests?.largeSurvey && (
        <Tooltip
          title={'Large surveys - ' + totalTests?.largeSurvey}
          placement="top"
        >
          <Badge
            badgeContent={totalTests?.largeSurvey ?? '0'}
            color={badgeColor}
            showZero
          >
            <TestIcon type="survey" color={iconColor} />
          </Badge>
        </Tooltip>
      )}
      {!!totalTests?.smallDilution && (
        <Tooltip
          title={'Small dilution - ' + totalTests?.smallDilution}
          placement="top"
        >
          <Badge
            badgeContent={totalTests?.smallDilution ?? '0'}
            color={badgeColor}
            showZero
          >
            <TestIcon type="dilution" color={iconColor} isPadded />
          </Badge>
        </Tooltip>
      )}
      {!!totalTests?.largeDilution && (
        <Tooltip
          title={'Large dilution - ' + totalTests?.largeDilution}
          placement="top"
        >
          <Badge
            badgeContent={totalTests?.largeDilution ?? '0'}
            color={badgeColor}
            showZero
          >
            <TestIcon type="dilution" color={iconColor} />
          </Badge>
        </Tooltip>
      )}
      {!!totalTests?.recirculation && (
        <Tooltip title={'Flex - ' + totalTests?.recirculation} placement="top">
          <Badge
            badgeContent={totalTests?.recirculation ?? '0'}
            color={badgeColor}
            showZero
          >
            <TestIcon type="recirculation" color={iconColor} />
          </Badge>
        </Tooltip>
      )}
      {!!totalTests?.miniSurvey && (
        <Tooltip
          title={'Mini survey - ' + totalTests?.miniSurvey}
          placement="top"
        >
          <Badge
            badgeContent={totalTests?.miniSurvey ?? '0'}
            color={badgeColor}
            showZero
          >
            <TestIcon type="mini_survey" color={iconColor} />
          </Badge>
        </Tooltip>
      )}
      {!!totalTests?.verification && (
        <Tooltip
          title={'Verification - ' + totalTests?.verification}
          placement="top"
        >
          <Badge
            badgeContent={totalTests?.verification ?? '0'}
            color={badgeColor}
            showZero
          >
            <TestIcon type="verification" color={iconColor} />
          </Badge>
        </Tooltip>
      )}
      {!!totalTests?.[HEALTHCARE_TEST_TYPES.NEGATIVE] && (
        <Tooltip
          title={
            `${
              HEALTHCARE_TEST_TYPES_LABELS[HEALTHCARE_TEST_TYPES.NEGATIVE]
            } - ` + totalTests?.[HEALTHCARE_TEST_TYPES.NEGATIVE]
          }
          placement="top"
        >
          <Badge
            badgeContent={totalTests?.[HEALTHCARE_TEST_TYPES.NEGATIVE] ?? '0'}
            color={badgeColor}
            showZero
          >
            <TestIcon type={HEALTHCARE_TEST_TYPES.NEGATIVE} color={iconColor} />
          </Badge>
        </Tooltip>
      )}
      {!!totalTests?.[HEALTHCARE_TEST_TYPES.NEUTRAL] && (
        <Tooltip
          title={
            `${
              HEALTHCARE_TEST_TYPES_LABELS[HEALTHCARE_TEST_TYPES.NEUTRAL]
            } - ` + totalTests?.[HEALTHCARE_TEST_TYPES.NEUTRAL]
          }
          placement="top"
        >
          <Badge
            badgeContent={totalTests?.[HEALTHCARE_TEST_TYPES.NEUTRAL] ?? '0'}
            color={badgeColor}
            showZero
          >
            <TestIcon type={HEALTHCARE_TEST_TYPES.NEUTRAL} color={iconColor} />
          </Badge>
        </Tooltip>
      )}
      {!!totalTests?.[HEALTHCARE_TEST_TYPES.POSITIVE] && (
        <Tooltip
          title={
            `${
              HEALTHCARE_TEST_TYPES_LABELS[HEALTHCARE_TEST_TYPES.POSITIVE]
            } - ` + totalTests?.[HEALTHCARE_TEST_TYPES.POSITIVE]
          }
          placement="top"
        >
          <Badge
            badgeContent={totalTests?.[HEALTHCARE_TEST_TYPES.POSITIVE] ?? '0'}
            color={badgeColor}
            showZero
          >
            <TestIcon type={HEALTHCARE_TEST_TYPES.POSITIVE} color={iconColor} />
          </Badge>
        </Tooltip>
      )}
    </Stack>
  );
}

/**
 * Returns time-related metadata for the contracts.
 * @param contracts
 * @returns {{total: number, ongoing: number, notStarted: number, completed: number}}
 */
export function getContractsProgress(contracts) {
  const results = {
    total: 0,
    completed: 0,
    ongoing: 0,
    notStarted: 0,
  };
  results.total = contracts.length;
  contracts.forEach((c) => {
    const startDate = new Date(c.startdate);
    const endDate = new Date(c.enddate);
    const today = new Date();
    if (today > endDate) {
      results.completed = results.completed + 1;
    } else if (today > startDate && today < endDate) {
      results.ongoing = results.ongoing + 1;
    } else {
      results.notStarted = results.notStarted + 1;
    }
  });

  return results;
}

/**
 * Injects extra metadata into the contracts. Adds an array of Region IDs, Territory IDs
 * and Site IDS into each contract object to make searching/filtering easier.
 * @param contracts
 * @param sites
 * @param territories
 * @param regions
 * @returns {*}
 */
export function injectMetadataIntoContracts(
  contracts,
  sites,
  territories,
  regions,
) {
  return contracts.map((c) => {
    const regionIds = new Set();
    const territoryIds = new Set();
    const siteIds = new Set();
    if (c.sitecontracts) {
      for (const id in c.sitecontracts) {
        const siteId = c.sitecontracts[id].siteid;
        siteIds.add(siteId);
        // Find the site that the site contract belongs to, to determine its territory
        const site = sites.find((s) => parseInt(s.id) === parseInt(siteId));
        if (site) {
          territories.forEach((t) => {
            if (parseInt(site.territoryId) === parseInt(t.id)) {
              // Add territories that the site is linked to
              territoryIds.add(t.id);
              // Also find the regions that the territory is linked to
              regions.forEach((r) => {
                if (parseInt(t.regionId) === parseInt(r.id)) {
                  regionIds.add(r.id);
                }
              });
            }
          });
        }
      }
    }
    return {
      ...c,
      regionIds: Array.from(regionIds.values()),
      territoryIds: Array.from(territoryIds.values()),
      siteIds: Array.from(siteIds.values()),
    };
  });
}

export function getCoverageSummaryText(testCoverage) {
  const parts = [];
  if (testCoverage) {
    if (testCoverage?.scheduled_rate)
      parts.push(`Scheduled: ${testCoverage.scheduled_rate}%`);
    if (testCoverage?.in_progress_rate)
      parts.push(`In progress: ${testCoverage.in_progress_rate}%`);
    if (testCoverage?.completed_rate)
      parts.push(`Completed: ${testCoverage.completed_rate}%`);
  }
  return parts.join(', ');
}

const getSimpleCoverage = (data) => {
  const { completed_rate, in_progress_rate, scheduled_rate } = data;
  return { completed_rate, in_progress_rate, scheduled_rate };
};

/**
 * Fetches portfolio data, injects empty test coverage
 * @returns {Promise<{}>}
 */
export async function fetchPortfoliosDetailed() {
  try {
    const response = await portfoliosApi.getPortfoliosContracts();

    if (isValidResponse(response)) {
      const portfoliosData = getDataFromResponse(response) || {};

      return portfoliosData;
    } else {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error(response.data?.error?.message.toString());
    }
  } catch (e) {
    throw new Error(e.message);
  }
}

/**
 * Checks if tests coverage data is already present in the contracts objects
 * related to specified portfolio
 * @returns {boolean}
 */
export function checkIfContractsHasTestCoverage(portfolios, portfolioId) {
  try {
    const { contracts } = portfolios[portfolioId];
    const portfolioContracts = Object.values(contracts);

    let result = true;

    for (let contract of portfolioContracts) {
      const contractHasCoverageData = !!(
        contract.testCoverage && Object.keys(contract.testCoverage).length
      );

      result = result && contractHasCoverageData;

      if (!contractHasCoverageData) {
        break;
      }
    }

    return result;
  } catch (err) {
    console.error(`Error checkIfContractsHasTestCoverage: `, err);
    return false;
  }
}

/**
 * Checks if tests coverage data is already present in the contracts objects
 * related to specified portfolio & contract
 * @returns {boolean}
 */
export function checkIfSiteContractsHasTestCoverage(
  portfolios,
  portfolioId,
  contractId,
) {
  try {
    const { sitecontracts } = portfolios[portfolioId].contracts[contractId];
    const contractSiteContracts = Object.values(sitecontracts);

    let result = true;

    for (let sc_contract of contractSiteContracts) {
      const sc_contractHasCoverageData = !!(
        sc_contract.testCoverage && Object.keys(sc_contract.testCoverage).length
      );

      result = result && sc_contractHasCoverageData;

      if (!sc_contractHasCoverageData) {
        break;
      }
    }

    return result;
  } catch (err) {
    console.error(`Error checkIfSiteContractsHasTestCoverage: `, err);
    return false;
  }
}

/**
 * Adds tests coverage data to each contract object of single portfolio.
 * Returns mutated portfolios object or null in case of failure.
 * @returns {?{}}
 */
export function injectTestCoverageDataIntoPortfoliosContracts(
  portfolioId,
  portfolios,
  contractsData,
) {
  try {
    const { contracts } = portfolios[portfolioId];

    const contractsIds = Object.keys(contracts);
    const coverageContractsIds = Object.keys(contractsData);

    for (let contractId of contractsIds) {
      if (~coverageContractsIds.indexOf(contractId)) {
        contracts[contractId].testCoverage = getSimpleCoverage(
          contractsData[contractId],
        );
      }
    }

    return portfolios;
  } catch (err) {
    console.error(`Error injectTestCoverageDataIntoPortfoliosContracts: `, err);
    return null;
  }
}

/**
 * Adds tests coverage data to each site contract object of specified contract
 * of specified portfolio.
 * Returns mutated portfolios object or null in case of failure.
 * @returns {?{}}
 */
export function injectTestCoverageDataIntoContractSiteContracts(
  portfolioId,
  contractId,
  portfolios,
  siteContractsCoverageData,
) {
  try {
    const { sitecontracts } = portfolios[portfolioId].contracts[contractId];

    const siteContractsIds = Object.keys(sitecontracts);
    const coverageSiteContractsIds = Object.keys(siteContractsCoverageData);

    for (let siteContractId of siteContractsIds) {
      if (~coverageSiteContractsIds.indexOf(siteContractId)) {
        sitecontracts[siteContractId].testCoverage = getSimpleCoverage(
          siteContractsCoverageData[siteContractId],
        );
      }
    }

    return portfolios;
  } catch (err) {
    console.error(
      `Error injectTestCoverageDataIntoContractSiteContracts: `,
      err,
    );
    return null;
  }
}

/**
 * Fetches tests coverage for single portfolio and returnes data object where
 * each data piece if accessible by site contract ID key.
 * Returns null in case of failure
 * @returns {Promise<?{}>}
 */
export async function fetchTestsCoverageForPortfolio(portfolioId) {
  try {
    const response = await testsApi.getTestsStatusesForPortfolio(portfolioId);

    if (isValidResponse(response)) {
      const { contracts } = getDataFromResponse(response);
      return contracts;
    } else {
      throw new Error(getErrorMessageFromResponse(response));
    }
  } catch (err) {
    console.error(`Error fetchTestsCoverageForPortfolio: `, err);
    return null;
  }
}

/**
 * Fetches tests coverage for single contract and returnes data object where
 * each data piece if accessible by site contract ID key
 * Returns null in case of failure
 * @returns {Promise<?{}>}
 */
export async function fetchTestsCoverageForContract(contractId) {
  try {
    const response = await testsApi.getTestsStatusesForContract(contractId);

    if (isValidResponse(response)) {
      const { sc_contracts } = getDataFromResponse(response);
      return sc_contracts;
    } else {
      throw new Error(getErrorMessageFromResponse(response));
    }
  } catch (err) {
    console.error(`Error fetchTestsCoverageForContract: `, err);
    return null;
  }
}

export async function parseXLSXFile(fileData, callBack) {
  const reader = new FileReader();
  reader.onload = (e) => {
    const ab = e.target.result;
    const wb = XLSX.read(ab, { type: 'array' });
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    const dataXlsx = XLSX.utils.sheet_to_json(ws, {
      header: 1,
      raw: false,
      dateNF: 'yyyy-mm-dd',
    });
    callBack(dataXlsx);
  };
  reader.readAsArrayBuffer(fileData);
}

export function validateSiteContractData(siteContractData) {
  return (
    parseInt(siteContractData.verificationTests, 10) ||
    !(
      !!~~siteContractData.smallSurveyTests === false &&
      !!~~siteContractData.largeSurveyTests === false &&
      !!~~siteContractData.smallDilutionTests === false &&
      !!~~siteContractData.largeDilutionTests === false &&
      !!~~siteContractData.miniSurveyTests === false &&
      !!~~siteContractData.recirculationTests === false &&
      !!~~siteContractData[HEALTHCARE_TEST_TYPES.NEGATIVE] === false &&
      !!~~siteContractData[HEALTHCARE_TEST_TYPES.POSITIVE] === false &&
      !!~~siteContractData[HEALTHCARE_TEST_TYPES.NEUTRAL] === false
    )
  );
}
