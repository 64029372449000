import React from 'react';
import { useSelector } from 'react-redux';

import { useProjectContext } from '../context';

import { Stack, Typography } from '@mui/material';

import ProjectTagsTable from 'Components/SingleProject/Main/Tables/ProjectTagsTable';

import { projectDataSelector } from 'Features/Projects/projectsSlice';

export default function ProjectTagsLotNumbersSection() {
  const { refetchProjectTags } = useProjectContext();
  const { initialLoading, projectTags } = useSelector(projectDataSelector);

  const { common, healthcare } = projectTags;

  if (initialLoading) return null;

  return (
    <Stack direction="row" style={{ width: '100%' }}>
      {Object.keys(common).length ? (
        <Stack direction="column" style={{ width: '50%' }}>
          <Typography variant={'h5'} sx={{ mt: 4, mb: 2 }}>
            Tags
          </Typography>
          <ProjectTagsTable tags={common} fetchTags={refetchProjectTags} />
        </Stack>
      ) : null}
      {Object.keys(healthcare).length ? (
        <Stack direction="column" style={{ width: '50%' }}>
          <Typography variant={'h5'} sx={{ mt: 4, mb: 2 }}>
            Tags (CET)
          </Typography>
          <ProjectTagsTable tags={healthcare} fetchTags={refetchProjectTags} />
        </Stack>
      ) : null}
    </Stack>
  );
}
