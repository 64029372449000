import { ERROR_AUTOCLOSE_TIMEOUT_MS } from 'Constants';
import React, { useState } from 'react';

import KeyIcon from '@mui/icons-material/Key';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import { Button, Tooltip } from '@mui/material';

import usersApi from 'Api/users';

import ConfirmDialog from 'Components/Dialogs/ConfirmDialog';
import ErrorMessage from 'Components/UI/ErrorMessage';

import { getErrorMessageFromResponse, isValidResponse } from 'Utils';

export default function ActivateOrBlockUserBtn({
  displayOnly = false,
  afterStatusChange,
  user,
  disabled,
  sx,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mainError, setMainError] = useState('');

  const changeUserStatus = async () => {
    try {
      setIsLoading(true);
      const response = await usersApi.updateOneUser(user.userid, {
        is_active: !user.is_active,
      });
      if (isValidResponse(response)) {
        setDialogOpen(false);
      } else throw new Error(getErrorMessageFromResponse(response));

      afterStatusChange();
    } catch (err) {
      console.log('Update user status error: ', err);
      setMainError(err?.message || `Couldn't update user status`);
      setTimeout(() => setMainError(''), ERROR_AUTOCLOSE_TIMEOUT_MS);
    } finally {
      setIsLoading(false);
    }
  };

  return displayOnly ? (
    <Button sx={{ border: 0 }} color="error" variant="text">
      <KeyOffIcon />
    </Button>
  ) : (
    <>
      <ConfirmDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        action={
          user.is_active ? `disable user's account` : `enable user's account`
        }
        isLoading={isLoading}
        handleConfirm={changeUserStatus}
      />
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
      <Button
        variant="text"
        onClick={() => setDialogOpen(true)}
        sx={{ border: 0, ...sx }}
        disabled={disabled}
        color={user.is_active ? 'error' : 'success'}
      >
        <Tooltip
          title={user.is_active ? 'Deactivate user' : 'Activate user'}
          placement="top"
        >
          {user.is_active ? <KeyOffIcon /> : <KeyIcon />}
        </Tooltip>
      </Button>
    </>
  );
}
