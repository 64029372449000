/* eslint-disable */
import { HEALTHCARE_TEST_TYPES } from 'Constants';

class ProjectScopeTabulator {
  constructor(dataModel) {
    this.dataModel = dataModel;
  }

  tabulate() {
    let resultTable = [];

    this.dataModel.tests.map((test) => {
      let testResultTable = [];

      testResultTable = this.tabulateTestGrouppedByScenario(test);

      if (testResultTable.length > 0) {
        // merge it into the result Table
        testResultTable.map((item) => {
          resultTable.push(item);
        });
      }
    });
    return resultTable;
  }

  tabulateTestGrouppedByScenario(test) {
    let resultTable = [];
    let scenarios = this.dataModel.scenarios.filter(
      (item) => item.testId === test.id,
    );

    //console.log(this.scenarios);
    //console.log(scenarios);

    scenarios.map((scenario) => {
      let scenarioNames = [scenario.name];
      let scenarioDescriptions = [scenario.description];

      //console.log(scenarioNames);
      //console.log(scenarioDescriptions);

      //get all tags for the scenario
      const originPoints = this.dataModel.originPoints.filter(
        (item) => item.testId === test.id,
      );
      //console.log(originPoints);
      let opTags = [];
      originPoints.map((op) => {
        const tags = op.tags.map((tag) => {
          //console.log('scenario ID: ' + scenario.id);
          //console.log(tag);
          const t = tag.tag.split('-')[1];
          if (
            !opTags.includes(t) &&
            tag.scenarioSequenceNumber == scenario.sequenceNumber
          ) {
            opTags.push(t);
          }
        });
      });
      if (opTags.length < 1) {
        opTags.push('n/a');
      }

      const samplePoints = this.dataModel.samplePoints.filter(
        (s) => s.testId == test.id,
      );

      const samples = this.dataModel.samples
        .filter(
          (item) => item.testId === test.id && item.scenarioId === scenario.id,
        )
        .map((sample) => {
          return sample.number;
        });
      //console.log(samples);
      const sortedSamples = samples.sort();
      const minSampleNumber =
        sortedSamples.length > 0 ? sortedSamples[0] : 'n/a';
      const maxSampleNumber =
        sortedSamples.length > 0
          ? sortedSamples[sortedSamples.length - 1]
          : 'n/a';

      let intervalDuration = test.intervalDuration;

      if (Object.values(HEALTHCARE_TEST_TYPES).includes(test.type)) {
        intervalDuration = scenario.testmetadata.testDurationSelectorNob;
      }

      resultTable.push({
        testId: test.id,
        testName: test.name,
        testSequenceNumber: test.sequenceNumber,
        isCompleted: test.isCompleted,
        testDate: test.testDate,
        type: test.type,
        scenarios: scenarios != null ? scenarios.length : 'n/a',
        scenarioNames: scenarioNames,
        scenarioDescriptions: scenarioDescriptions,
        opCount: originPoints.length,
        opTags: opTags,
        sampleType: test.collectionMethods,
        spCount: samplePoints.length,
        intervalCount: test.intervalCount,
        intervalDuration: intervalDuration,
        sampleCount: samples.length,
        startingSampleNumber: minSampleNumber,
        endingSampleNumber: maxSampleNumber,
      });
    });

    return resultTable;
  }
}
export default ProjectScopeTabulator;
