import React from 'react';

import { Box, Paper, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import DilutionSlider from 'Components/Mix/DilutionSlider';
import ScaleSliders from 'Components/Mix/ScaleSliders';

export default function ElementsControlPanel({
  settings,
  updateSettings,
  showDilutionSlider,
  disabled,
}) {
  const updateSetting = (key, value) =>
    updateSettings(Object.assign({}, settings, { [key]: value }));

  return (
    <Paper elevation={1}>
      <Box pl={2} pr={2} pb={1} mb={2}>
        <Typography variant="overline" display="block" gutterBottom>
          Scaling
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <ScaleSliders
            handleSlideHeatmapChange={(e, newValue) =>
              updateSetting('sliderHeatmap', newValue)
            }
            sliderHeatmap={settings.sliderHeatmap}
            handleSlideSpChange={(e, newValue) =>
              updateSetting('sliderSp', newValue)
            }
            sliderSp={settings.sliderSp}
            minScaleHeatmap={settings.minScaleHeatmap}
            disabled={disabled}
          />
        </Box>
        {showDilutionSlider ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              mb: 1,
              mt: 2,
              ml: 2,
            }}
          >
            <DilutionSlider
              handleSlideCropBoxChange={(event, newValue) => {
                updateSettings(
                  Object.assign({}, settings, { sliderCropBox: newValue }),
                );
              }}
              sliderCropBox={settings.sliderCropBox}
              disabled={disabled}
            />
          </Box>
        ) : null}
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={settings.showSpOpValue}
                onChange={(e) =>
                  updateSetting('showSpOpValue', e.target.checked)
                }
                name="inProgressSwitch"
              />
            }
            label="Show Sp/Op Value"
            sx={{ pt: 0.6 }}
            disabled={disabled}
          />
        </Box>
      </Box>
    </Paper>
  );
}
