import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import samplePlans from 'Api/samplePlans';
import testsApi from 'Api/tests';

import CheckBorderButton from 'Components/Buttons/CheckBorderButton';
import { useProjectContext } from 'Components/SingleProject/context';

import { projectDataSelector } from 'Features/Projects/projectsSlice';

import { isValidResponse } from 'Utils';

export default function CompleteAllTestsBtn({ setLoading }) {
  const [allTestsInProgress, setAllTestsInProgress] = useState(false);
  const { fetchProjectTests, setMainError, projectId } = useProjectContext();
  const { projectTests, isProjectTestsLoading } =
    useSelector(projectDataSelector);

  const handleTestExecuted = async (testid) => {
    try {
      const res = await testsApi.updateOneTest(testid, {
        testCompleted: true,
      });
      if (!isValidResponse(res)) {
        return false;
      }
      return true;
    } catch (err) {
      return false;
    }
  };

  const handleSetAllTestsExecuted = async () => {
    setLoading(true);

    const error = [];
    setAllTestsInProgress(true);
    for (const test of projectTests) {
      if (!test.testexecuted) {
        const isSuccess = await handleTestExecuted(test.testid);
        if (!isSuccess) {
          error.push(test.orderNumber);
        }
      }
    }
    await samplePlans.removeSamplePlan(projectId);

    if (Object.keys(error).length === 0) {
      fetchProjectTests();
    } else {
      fetchProjectTests().then(() => {
        if (error.length === 1) {
          setMainError(
            `Test ${error.join(', ')} was not set as completed due to errors`,
          );
        } else {
          setMainError(
            `Tests ${error.join(', ')} were not set as completed due to errors`,
          );
        }
      });
    }
    setAllTestsInProgress(false);
    setLoading(false);
  };

  return (
    <CheckBorderButton
      disableStyles={true}
      text="Complete all tests"
      disabled={isProjectTestsLoading || allTestsInProgress}
      clickAction={() => handleSetAllTestsExecuted()}
      extraBtnProps={{
        disableFocusRipple: true,
        disabled: isProjectTestsLoading || allTestsInProgress,
      }}
    />
  );
}
