import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const FloorplanPreviewModal = ({ open, fileName, handleClose, imgUrl }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          padding: 2,
          justifyContent: 'center',
          alignItems: 'center',
          visibility: 'visible',
          width: '640px',
          height: '480px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          whiteSpace: 'nowrap',
          maxWidth: '80%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {fileName}
      </DialogTitle>
      <DialogContent>
        <IconButton
          style={{ position: 'absolute', top: '20px', right: '20px' }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <img
          style={{ maxWidth: '95%', maxHeight: '95%' }}
          src={imgUrl}
          alt="floorplan-img"
        ></img>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(FloorplanPreviewModal);
