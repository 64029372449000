import React, { useMemo } from 'react';

const darkCell = {
  background: 'rgb(0, 137, 150)',
  color: 'white',
  padding: '5px',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: 'smaller',
};

const lightCell = {
  background: 'white',
  borderRight: '1px solid gray',
  borderBottom: '1px solid gray',
  color: 'black',
  padding: '5px',
  textAlign: 'center',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: 'smaller',
};

const GeneralSurveyOPTable = ({ OPs, SPs }) => {
  const OPsData = useMemo(() => {
    const opNamesMemo = {};
    const result = [];

    for (let OP of OPs) {
      if (!opNamesMemo[OP.number]) {
        opNamesMemo[OP.number] = true;
        result.push({
          number: OP.number,
          tag: OP.tags[0]?.tag ?? `n/a`,
          tagLotNum: OP.tags[0]?.lotNumber ?? `n/a`,
        });
      }
    }

    return result;
  }, [OPs]);
  return (
    <div>
      {Array.isArray(OPsData) && (
        <table
          style={{
            border: '1px solid gray',
            borderRight: 'none',
            borderBottom: 'none',
            width: '99.5%',
            borderSpacing: 0,
          }}
        >
          <thead>
            <tr>
              <th style={darkCell} width="30%">
                OP Name
              </th>
              <th style={darkCell} width="30%">
                Tag
              </th>
              <th style={darkCell} width="30%">
                Tag Lot Number
              </th>
            </tr>
          </thead>
          <tbody>
            {OPsData.sort((a, b) => a.number.localeCompare(b.number)).map(
              (op) => (
                <tr key={op.number}>
                  <td style={lightCell} width="30%">
                    {op.number ?? 'n/a'}
                  </td>
                  <td style={lightCell} width="30%">
                    {op.tag ?? 'n/a'}
                  </td>
                  <td style={lightCell} width="30%">
                    {op.tagLotNum ?? 'n/a'}
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      )}
      <br />
      {Array.isArray(SPs) && (
        <table
          style={{
            border: '1px solid gray',
            borderRight: 'none',
            borderBottom: 'none',
            width: '99.5%',
            borderSpacing: 0,
          }}
        >
          <thead>
            <tr>
              <th style={darkCell} width="50%">
                SP number
              </th>
              <th style={darkCell} width="50%">
                SP name
              </th>
            </tr>
          </thead>
          <tbody>
            {SPs.sort((a, b) => a.number.localeCompare(b.number)).map((sp) => (
              <tr key={sp.number}>
                <td style={lightCell} width="50%">
                  {sp.number ?? 'n/a'}
                </td>
                <td style={lightCell} width="50%">
                  {sp.name ?? 'n/a'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default GeneralSurveyOPTable;
