import * as React from 'react';

import SelectCompanyList from '../../Components/Select/SelectCompanyList';
import { AppBarStyled, ToolbarIcon, ToolbarStyled } from '../layoutStyles';
import HeatmapCompareMenu from './Components/HeatmapCompareMenu';
import MainLogo from './Components/MainLogo';
import ProfileMenu from './Components/ProfileMenu';
import UserDisplayInfo from './Components/UserDisplayInfo';

import DescriptionIcon from '@mui/icons-material/Description';
import { IconButton } from '@mui/material';

const CustomAppBar = ({
  userEmail,
  userRoleName,
  openCompanySelect,
  hideCompanyChoice,
  mountCompanySelect,
  showHeatmapCompare,
}) => {
  const CompanySelect = React.useMemo(
    () => (
      <SelectCompanyList
        sx={{
          visibility: hideCompanyChoice ? 'hidden' : '',
        }}
        defaultOpen={openCompanySelect}
        shouldCloseAfterDefaultOpen={openCompanySelect}
      />
    ),
    [hideCompanyChoice, openCompanySelect, window.location.pathname],
  );

  return (
    <AppBarStyled position="fixed" color="transparent">
      <ToolbarStyled>
        <MainLogo onClickLocation={'/'} />
        <ToolbarIcon>
          {!!showHeatmapCompare && <HeatmapCompareMenu />}
          {mountCompanySelect && CompanySelect}
          <UserDisplayInfo userEmail={userEmail} userRoleName={userRoleName} />
          <IconButton
            onClick={() =>
              window.open('https://www.safetraces.com/documentation')
            }
          >
            <DescriptionIcon />
          </IconButton>
          <ProfileMenu />
        </ToolbarIcon>
      </ToolbarStyled>
    </AppBarStyled>
  );
};

export default CustomAppBar;
