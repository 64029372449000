import { createSlice } from '@reduxjs/toolkit';

import { getBuildingName } from '../../Components/Breadcrumbs/dataUtils';

export const slice = createSlice({
  name: 'breadcrumbs',
  initialState: {
    currentBuildingId: 0,
    currentBuildingName: '',
    isLoading: false,
    error: null,
    breadcrumbs: null,
  },
  reducers: {
    setCurrentBuildingId: (state, action) => {
      state.currentBuildingId = action.payload;
    },
    setCurrentBuildingName: (state, action) => {
      state.currentBuildingId = action.payload;
    },
    setBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload;
    },
  },
});

export const setBuildingData = (buildingId, buildings) => async (dispatch) => {
  console.log(buildingId + 'setBuildingData');
  dispatch(setCurrentBuildingId(buildingId));
  const buildingName = getBuildingName(buildings, buildingId);
  dispatch(setCurrentBuildingName(buildingName));
};

export const { setCurrentBuildingId, setCurrentBuildingName, setBreadcrumbs } =
  slice.actions;

export default slice.reducer;

export const breadcrumbsSelector = (state) => state.breadcrumbs;
