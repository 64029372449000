import React from 'react';

import { Typography } from '@mui/material';

export default function Title({ text, color = '' }) {
  return (
    <Typography color={color} variant="h5" style={{ marginBottom: 10 }}>
      {text}
    </Typography>
  );
}
