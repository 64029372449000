import React from 'react';

import {
  DisabledBorderButton,
  YellowBorderButton,
  disableColor,
} from '../../Layout/layoutStyles';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Tooltip } from '@mui/material';

export default function CancelBorderButton({
  clickAction,
  sx,
  tooltipText = '',
  isDisable = false,
}) {
  const buttonProps = {
    variant: 'outlined',
    sx,
    onClick: clickAction,
  };

  return (
    <Tooltip title={tooltipText} placement="top">
      {isDisable ? (
        <DisabledBorderButton {...buttonProps}>
          <HighlightOffIcon sx={{ color: disableColor }} />
        </DisabledBorderButton>
      ) : (
        <YellowBorderButton {...buttonProps}>
          <HighlightOffIcon />
        </YellowBorderButton>
      )}
    </Tooltip>
  );
}
