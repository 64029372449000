import { ST_CHART_COLORS } from 'Constants';
import React, { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { useDashboardContext } from '../context';

export default function ChartEACHByFloor() {
  const { buildingOverviewData } = useDashboardContext();

  const [maxYAxisVal, setMaxYAxisVal] = useState(10);

  useEffect(() => {
    let maxEACHVal = 0;
    const data = buildingOverviewData?.eACHByFloor || [];
    for (let { eACH_calc, eACH_real } of data) {
      maxEACHVal = Math.max(maxEACHVal, eACH_calc || 0, eACH_real || 0);
    }
    if (Math.ceil(maxEACHVal) + 2 > maxYAxisVal) {
      setMaxYAxisVal(Math.ceil(maxEACHVal) + 2);
    }
  }, [buildingOverviewData?.eACHByFloor]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={400}
        data={buildingOverviewData?.eACHByFloor || []}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 60,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="name" />
        <YAxis
          type="number"
          domain={[0, maxYAxisVal]}
          ticks={Array(maxYAxisVal + 1)
            .fill(1)
            .map((_, i) => i)}
          interval={0}
          label={<CustomizedOYLabel maxYAxisVal={maxYAxisVal} />}
        />
        <Tooltip
          formatter={(value, name) => {
            return [
              value.toFixed(1),
              name === 'eACH_calc'
                ? 'ASHRAE eACH Calculator'
                : 'eACH Real World Performance',
            ];
          }}
        />
        <Legend
          layout="vertical"
          formatter={(value) =>
            value === 'eACH_calc'
              ? 'ASHRAE eACH Calculator'
              : 'eACH Real World Performance'
          }
        />
        {/* <Bar dataKey="eACH_calc" barSize={20} fill={ST_CHART_COLORS.blue}>
          <LabelList content={renderBarLabel} />
        </Bar> */}
        <Bar dataKey="eACH_real" barSize={20} fill={ST_CHART_COLORS.blue}>
          <LabelList content={renderBarLabel} />
        </Bar>
        <ReferenceLine y={4.61} stroke="red" strokeWidth={3} />
      </BarChart>
    </ResponsiveContainer>
  );
}

const renderBarLabel = ({ x, y, width, value }) => {
  if (!value) return null;

  return (
    <g>
      <text
        x={x + width / 2}
        y={y - 10}
        fill="black"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value.toFixed(1)}
      </text>
    </g>
  );
};

const CustomizedOYLabel = (props) => {
  const { viewBox, maxYAxisVal } = props;
  const { height, x, y } = viewBox;

  const offsetXText = 15;
  const r = '5';
  const widthOfStroke = 3;
  const widthOfBracket = 20;

  const defZoneEndYCoord = 4.5;
  const energyZoneStartYCoord = 6;

  const defZoneHeight = height * (defZoneEndYCoord / maxYAxisVal);
  const defZoneStartY = height + y - defZoneHeight;
  const energyZoneHeight =
    height * ((maxYAxisVal - energyZoneStartYCoord) / maxYAxisVal);

  return (
    <g>
      <rect
        x={x}
        y={defZoneStartY}
        height={defZoneHeight}
        fill={ST_CHART_COLORS.blue}
        width={widthOfBracket}
        rx={r}
        ry={r}
      />
      <rect
        x={x + widthOfStroke}
        y={defZoneStartY + widthOfStroke}
        height={defZoneHeight - widthOfStroke * 2}
        fill="white"
        width={widthOfBracket - widthOfStroke}
      />
      <text
        x={x - widthOfStroke - offsetXText}
        y={(y + height + defZoneStartY) / 2}
        textAnchor="middle"
        alignmentBaseline="middle"
        transform={`rotate(-90 ${x - widthOfStroke - offsetXText} ${
          (y + height + defZoneStartY) / 2
        })`}
        fill="black"
        fontSize={14}
      >
        Deficiency zone
      </text>
      <rect
        x={x}
        y={y}
        height={energyZoneHeight}
        fill={ST_CHART_COLORS.blue}
        width={widthOfBracket}
        rx={r}
        ry={r}
      />
      <rect
        x={x + widthOfStroke}
        y={y + widthOfStroke}
        height={energyZoneHeight - widthOfStroke * 2}
        fill="white"
        width={widthOfBracket - widthOfStroke}
      />
      <text
        x={x - widthOfStroke - offsetXText}
        y={y + energyZoneHeight / 2}
        textAnchor="middle"
        alignmentBaseline="middle"
        transform={`rotate(-90 ${x - widthOfStroke - offsetXText} ${
          y + energyZoneHeight / 2
        })`}
        fill="black"
        fontSize={12}
      >
        Energy & Cost Savings Zone
      </text>
    </g>
  );
};
