import React, { useEffect, useState } from 'react';

import { useProjectContext } from '../../context';
import TestOrderDragAndDrop from '../TestOrderDragAndDrop/index';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

export default function EditTestOrderModal({
  open,
  handleClose,
  transitionDuration = 220,
  projectTests,
}) {
  const { fetchProjectTests, updateTestsOrderAndCreateSamples } =
    useProjectContext();
  const [tests, setTests] = useState([]);
  const [actionsDisabled, setActionsDisabled] = useState(false);

  useEffect(() => {
    const _projectTests = [...projectTests];
    const _tests = _projectTests
      .sort((a, b) =>
        typeof a.testordernumber === 'number'
          ? a.testordernumber - b.testordernumber
          : 1,
      )
      .map((test, i) => {
        return {
          id: test.testid,
          name: test.testname,
          order: test.testordernumber ?? i + 1,
        };
      });

    setTests(_tests);
  }, [projectTests]);

  const updateTestsAndCreateSamples = async () => {
    setActionsDisabled(true);
    try {
      const testsToUpdate = tests.map((test, i) => {
        test.order = i + 1;
        return test;
      });
      updateTestsOrderAndCreateSamples(testsToUpdate, projectTests).then(() => {
        setActionsDisabled(false);
        handleClose();
        fetchProjectTests();
      });
    } catch (err) {
      console.log(err);
      setActionsDisabled(false);
    }
  };

  return (
    <Dialog
      open={open}
      transitionDuration={transitionDuration}
      disableEscapeKeyDown
      PaperProps={{
        sx: {
          p: 1,
          pb: 2,
          justifyContent: 'center',
          alignItems: 'center',
          width: '640px',
        },
      }}
    >
      <DialogTitle>Edit test order</DialogTitle>
      <DialogContent sx={{ width: '540px' }}>
        <TestOrderDragAndDrop
          tests={tests}
          updateTests={(_tests) => setTests(_tests)}
          disableActions={actionsDisabled}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between', width: '60%' }}>
        <Button
          style={{ width: '100%', marginRight: '8px' }}
          variant="outlined"
          onClick={handleClose}
          disabled={actionsDisabled}
        >
          Cancel
        </Button>
        <Button
          style={{ width: '100%' }}
          variant="contained"
          disabled={actionsDisabled}
          onClick={updateTestsAndCreateSamples}
          endIcon={
            actionsDisabled ? (
              <CircularProgress color="inherit" size={'1rem'} />
            ) : null
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
