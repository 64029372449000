import * as React from 'react';

import Skeleton from '@mui/material/Skeleton';

import { StyledTableCell, StyledTableRow } from 'Layout/layoutStyles';

export default function TableBodySkeleton({
  columns,
  rows = 4,
  shouldRenderActionsCell = false,
  actionsComponent,
}) {
  const renderTableBodySkeleton = (columns, rows) => {
    const result = [];

    const singleRowContent = [];
    for (let j = 0; j < columns; j++) {
      singleRowContent.push(
        <StyledTableCell key={j}>
          <Skeleton />
        </StyledTableCell>,
      );
    }

    if (shouldRenderActionsCell) {
      singleRowContent[columns - 1] = (
        <StyledTableCell key="actions">{actionsComponent}</StyledTableCell>
      );
    }

    for (let i = 0; i < rows; i++) {
      result.push(<StyledTableRow key={i}>{singleRowContent}</StyledTableRow>);
    }

    return <>{result}</>;
  };

  const TableBodySkeleton = React.useMemo(
    () => renderTableBodySkeleton(columns, rows),
    [columns, rows],
  );

  return TableBodySkeleton;
}
