import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import BreadcrumbsHeatmaps from 'Components/Breadcrumbs/BreadcrumbsHeatmaps';
import HeatMapItem from 'Components/HeatMap/HeatMapItem';
import FilterSearchColors from 'Components/Mix/FilterSearchColors';
import ErrorMessage from 'Components/UI/ErrorMessage';
import NoDataDisplay from 'Components/UI/NoDataDisplay';
import SkeletonGroupLoading from 'Components/UI/SkeletonGroupLoading';

import { colorsSelector, setColors } from 'Features/Colors/colorsSlice';
import { compareSelector, setClearAll } from 'Features/Compare/compareSlice';
import {
  inProgressSelector,
  setInProgress,
} from 'Features/Filter/inProgressSlice';
import {
  fetchHeatmaps,
  heatmapsSelector,
  setNotError,
} from 'Features/Heatmaps/heatmapsSlice';
import { setHeatmapsSuccess } from 'Features/Heatmaps/heatmapsSlice';
import { fetchBuildings } from 'Features/Results/resultsSlice';
import { fetchBuildingTest, testsSelector } from 'Features/Tests/testsSlice';

import { filterItem } from 'Utils';

export default function ResultTests() {
  const { buildingId, testId } = useParams();
  const { colors } = useSelector(colorsSelector);
  const { inProgress } = useSelector(inProgressSelector);
  const [initialLoading, setInitialLoading] = useState(false);
  const { heatmaps, isLoading, error, heatmapDisplayNumbers } =
    useSelector(heatmapsSelector);
  const { currentTestDate, currentTestType } = useSelector(testsSelector);
  const { isClearAll } = useSelector(compareSelector);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [date, setDate] = useState('');
  const [testType, setTestType] = useState('');

  useEffect(() => {
    if (!isLoading) {
      setInitialLoading(false);
    }
  }, [isLoading]);
  const handleFilterChange = useCallback(
    (colorsObj) => {
      dispatch(setColors(colorsObj));
      dispatch(fetchBuildings(colorsObj, buildingId, true, inProgress));
      dispatch(
        fetchBuildingTest(buildingId, colorsObj, testId, true, inProgress),
      );
      dispatch(fetchHeatmaps(testId, colorsObj, true, inProgress));
    },
    [inProgress, buildingId, testId],
  );

  const handleInProgressSwitch = useCallback(
    (inProgressValue) => {
      dispatch(setInProgress(inProgressValue));
      dispatch(fetchBuildings(colors, buildingId, true, inProgressValue));
      dispatch(
        fetchBuildingTest(buildingId, colors, testId, true, inProgressValue),
      );
      dispatch(fetchHeatmaps(testId, colors, true, inProgressValue));
    },
    [colors, testId, buildingId],
  );

  useEffect(() => {
    dispatch(fetchBuildings(colors, buildingId, false, inProgress));
    dispatch(fetchBuildingTest(buildingId, colors, testId, false, inProgress));
    dispatch(fetchHeatmaps(testId, colors, true, inProgress));
    setDate(currentTestDate);
    setTestType(currentTestType);
  }, [
    dispatch,
    buildingId,
    testId,
    currentTestDate,
    currentTestType,
    inProgress,
  ]);

  useEffect(() => {
    if (isClearAll) {
      dispatch(setHeatmapsSuccess([]));
      dispatch(fetchHeatmaps(testId, colors, true, inProgress));
      dispatch(setClearAll(false));
    }
  }, [isClearAll]);

  const handleCloseErrorAlert = () => {
    dispatch(setNotError());
  };

  const filteredHeatmaps = !!heatmaps
    ? heatmaps.filter((item) => filterItem(item, search))
    : [];

  return (
    <>
      {!!error && (
        <ErrorMessage
          message={error}
          handleCloseErrorAlert={handleCloseErrorAlert}
        />
      )}
      <div style={{ width: '100%' }}>
        <BreadcrumbsHeatmaps
          testId={testId}
          buildingId={buildingId}
          date={date}
        />
        <FilterSearchColors
          filterColors={colors}
          inProgress={inProgress}
          handleSearch={(value) => setSearch(value)}
          searchFieldLabel={'Search for Heatmaps'}
          singleTest
          singleTestType={testType}
          handleFilterChange={handleFilterChange}
          handleInProgressSwitch={handleInProgressSwitch}
          disabled={isLoading || isLoading}
        />
      </div>
      <React.Fragment>
        {isLoading || initialLoading ? (
          <SkeletonGroupLoading />
        ) : (
          <React.Fragment>
            {filteredHeatmaps.length ? (
              filteredHeatmaps.map((item) => {
                const heatmapDisplayNumber =
                  heatmapDisplayNumbers[item.heatmapid];
                return (
                  <HeatMapItem
                    key={item.heatmapid}
                    item={item}
                    testId={testId}
                    buildingId={buildingId}
                    displayNumber={heatmapDisplayNumber}
                  />
                );
              })
            ) : (
              <Box sx={{ height: '500px', width: '100%', display: 'flex' }}>
                <NoDataDisplay />
              </Box>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    </>
  );
}
