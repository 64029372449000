import {
  PROJECT_STATUSES,
  PROJECT_STATUS_WORKFLOW,
  TEST_TYPES_LABELS,
} from 'Constants';
import { format, parseISO } from 'date-fns';
import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { Grid, IconButton, Link, Stack, Typography } from '@mui/material';

import { ScenariosTableSingleTest } from 'Components/Scenarios';
import Floorplan from 'Components/SingleTest/Elements/Floorplan';
import { useTestContext } from 'Components/SingleTest/context';

import {
  ACCESS_LEVEL_ADMIN,
  ACCESS_LEVEL_EDITOR,
  ROLE_SAFETRACES,
  isSafetracesAdmin,
  isViewer,
} from 'Config/roles';

import { getUserData } from 'Utils';

const Verification = (props) => {
  const {
    filteredTest,
    originPointsList,
    samplePointsList,
    platekind,
    sprayer,
    surveydate,
    comments,
    intervalCount,
    intervalDuration,
    preparedScenariosList,
    segmentId,
    projectId,
    segment,
    floorPlanPlacementURL,
    projectStatus,
  } = props;

  const { openEditModal, disableEdits } = useTestContext();
  const { roleName, accessLevel } = getUserData();
  const overrideEditPermissionSafetracesAdminIgroneWorkflow =
    isSafetracesAdmin();

  const editFullPermission =
    !!~[ACCESS_LEVEL_ADMIN].indexOf(accessLevel) &&
    !!~[ROLE_SAFETRACES].indexOf(roleName);
  const editPermission =
    !!~[ACCESS_LEVEL_ADMIN].indexOf(accessLevel) ||
    !!~[ACCESS_LEVEL_EDITOR].indexOf(accessLevel);

  const editSamplesDisabled =
    PROJECT_STATUS_WORKFLOW.indexOf(projectStatus) >=
    PROJECT_STATUS_WORKFLOW.indexOf(PROJECT_STATUSES.EXECUTION_READY);
  const editDetailsDisabled =
    PROJECT_STATUS_WORKFLOW.indexOf(projectStatus) >=
    PROJECT_STATUS_WORKFLOW.indexOf(PROJECT_STATUSES.EXECUTED);

  return (
    <Stack direction="column" mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={6} lg={3}>
          <Typography variant={'body1'}>
            <b>Test Type:</b> {TEST_TYPES_LABELS[filteredTest.testtype]}
          </Typography>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Typography variant={'body1'}>
            <b>Sprayer:</b> {sprayer}
          </Typography>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Typography variant={'body1'}>
            <b>Test Area:</b> {filteredTest.buildingdata.sq_ft} sq.ft{' '}
            {overrideEditPermissionSafetracesAdminIgroneWorkflow ||
            (!isViewer() &&
              !disableEdits &&
              editPermission &&
              !editDetailsDisabled) ? (
              <IconButton
                aria-label="Edit test area"
                size="small"
                onClick={() => {
                  openEditModal({
                    type: 'custom_area',
                    value: filteredTest.buildingdata.sq_ft,
                    testId: filteredTest.testid,
                    data: {
                      sq_ft: filteredTest.buildingdata.sq_ft,
                      celling_height: filteredTest.buildingdata.celling_height,
                      type: filteredTest.testtype,
                      segment,
                      preparedScenariosList,
                    },
                  });
                }}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            ) : null}
          </Typography>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Typography variant={'body1'}>
            <b>Ceiling Height:</b> {filteredTest.buildingdata.celling_height} ft{' '}
            {!isViewer() &&
              !disableEdits &&
              editPermission &&
              !editDetailsDisabled && (
                <IconButton
                  aria-label="Edit test area"
                  size="small"
                  onClick={() => {
                    openEditModal({
                      type: 'custom_area',
                      value: filteredTest.buildingdata.sq_ft,
                      testId: filteredTest.testid,
                      data: {
                        sq_ft: filteredTest.buildingdata.sq_ft,
                        celling_height:
                          filteredTest.buildingdata.celling_height,
                        type: filteredTest.testtype,
                        segment,
                        preparedScenariosList,
                      },
                    });
                  }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              )}
          </Typography>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Typography variant={'body1'}>
            <b>No. of OP:</b>{' '}
            {originPointsList.length > 0 ? originPointsList.length : ''}{' '}
            {!isViewer() &&
              !disableEdits &&
              editFullPermission &&
              !editSamplesDisabled && (
                <IconButton
                  aria-label="Edit number of origin points"
                  size="small"
                  onClick={() => {
                    openEditModal({
                      type: 'origin_points',
                      value: originPointsList.length,
                      testId: filteredTest.testid,
                      data: {
                        segment,
                        preparedScenariosList,
                        test: filteredTest,
                      },
                    });
                  }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              )}
          </Typography>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Typography variant={'body1'}>
            <b>No. of SP:</b>{' '}
            {samplePointsList.length > 0 ? samplePointsList.length : ''}{' '}
            {!isViewer() &&
              !disableEdits &&
              editFullPermission &&
              !editSamplesDisabled && (
                <IconButton
                  aria-label="Edit number of sample points"
                  size="small"
                  onClick={() => {
                    openEditModal({
                      type: 'sample_points',
                      value: samplePointsList.length,
                      testId: filteredTest.testid,
                      data: { segment, test: filteredTest },
                    });
                  }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              )}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant={'body1'}>
            {segment.floorplan && (
              <>
                {!isSafetracesAdmin() && !isViewer() && !disableEdits && (
                  <b>
                    Floorplan (
                    <Link href={floorPlanPlacementURL}>Manage OPs/SPs</Link>)
                  </b>
                )}
                {!isSafetracesAdmin() && (isViewer() || disableEdits) ? (
                  <b>
                    Floorplan (
                    <Link href={floorPlanPlacementURL}>View OPs/SPs</Link>)
                  </b>
                ) : null}
                {isSafetracesAdmin() &&
                projectStatus !== PROJECT_STATUSES.PUBLISHED ? (
                  <b>
                    Floorplan (
                    <Link href={floorPlanPlacementURL}>Manage OPs/SPs</Link>)
                  </b>
                ) : null}
                {isSafetracesAdmin() &&
                projectStatus === PROJECT_STATUSES.PUBLISHED ? (
                  <b>
                    Floorplan (
                    <Link href={floorPlanPlacementURL}>View OPs/SPs</Link>)
                  </b>
                ) : null}{' '}
              </>
            )}
            {!segment.floorplan && (
              <>
                <b>Floorplan</b>{' '}
              </>
            )}
            <Floorplan
              projectId={projectId}
              buildingId={filteredTest.buildingid || ''}
              filteredTest={filteredTest}
              segment={segment}
              editDisabled={disableEdits || editDetailsDisabled}
            />
          </Typography>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Typography variant={'body1'}>
            <b>Tag Series:</b> {platekind}
          </Typography>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Typography variant={'body1'}>
            <b>Scenarios:</b> {preparedScenariosList?.length}{' '}
            {!isViewer() && !disableEdits && !editSamplesDisabled && (
              <IconButton
                aria-label="Edit number of scenarios"
                size="small"
                onClick={() => {
                  openEditModal({
                    type: 'scenarios',
                    value: preparedScenariosList.length,
                    testId: filteredTest.testid,
                    data: { segment },
                  });
                }}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            )}
          </Typography>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Typography variant={'body1'}>
            <b>Survey date:</b>{' '}
            {surveydate ? format(parseISO(surveydate), 'MMM do, yyyy') : ''}{' '}
            {!isViewer() && !disableEdits && !editDetailsDisabled && (
              <IconButton
                aria-label="Edit survey date"
                size="small"
                onClick={() => {
                  openEditModal({
                    type: 'surveydate',
                    value: surveydate,
                    testId: filteredTest.testid,
                    data: { segment },
                  });
                }}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            )}
          </Typography>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Typography variant={'body1'}>
            <b>Interval Count</b> {intervalCount}
          </Typography>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Typography variant={'body1'}>
            <b>Interval Duration</b>{' '}
            {intervalDuration === 'custom_ver'
              ? 'Custom (5 min)'
              : intervalDuration}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={9}>
          <Typography variant={'body1'}>
            <b>Comments:</b> {comments}{' '}
            {overrideEditPermissionSafetracesAdminIgroneWorkflow ||
            (!isViewer() && !disableEdits && !editDetailsDisabled) ? (
              <IconButton
                aria-label="Edit comments"
                size="small"
                onClick={() => {
                  openEditModal({
                    type: 'comments',
                    value: comments,
                    testId: filteredTest.testid,
                    data: { segmentId },
                  });
                }}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            ) : null}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'body1'} sx={{ mb: 2 }}>
            <b>Tags</b>
          </Typography>
          <ScenariosTableSingleTest
            scenarios={preparedScenariosList}
            originPoints={originPointsList}
            projectId={projectId}
            test={filteredTest}
            editDisabled={disableEdits}
            projectStatus={projectStatus}
          ></ScenariosTableSingleTest>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Verification;
