import { createSlice } from '@reduxjs/toolkit';
import { COLORS, TEST_TYPES, colorsFilterValues } from 'Constants';

import { formDefaultColorsFilteredObj } from 'Utils';

const defaultColors = formDefaultColorsFilteredObj();

const getInitialColorsValue = () => {
  try {
    const lsColors = JSON.parse(localStorage.getItem(COLORS))
      ? verifyAndCompleteColorsFilterObj(
          JSON.parse(localStorage.getItem(COLORS)),
        )
      : defaultColors;

    return lsColors;
  } catch (err) {
    localStorage.setItem(COLORS, JSON.stringify(defaultColors));
    return defaultColors;
  }
};

const verifyAndCompleteColorsFilterObj = (colorsObj) => {
  const result = {};

  if (!colorsObj || !Object.keys(colorsObj) || !Object.keys(colorsObj).length) {
    localStorage.setItem(COLORS, JSON.stringify(defaultColors));

    return defaultColors;
  }

  for (let testType of Object.values(TEST_TYPES)) {
    if (!colorsObj[testType]) {
      colorsObj[testType] = {
        colors: {},
      };
    }
    const colorsTestType = Object.assign({}, colorsObj[testType].colors);

    for (let color of Object.values(colorsFilterValues)) {
      if (typeof colorsTestType[color] !== 'boolean') {
        colorsTestType[color] = true;
      }
    }
    result[testType] = Object.assign({}, colorsObj[testType]);
  }

  localStorage.setItem(COLORS, JSON.stringify(result));

  return Object.assign({}, result);
};

const colors = getInitialColorsValue();

export const slice = createSlice({
  name: 'filterColorProgress',
  initialState: {
    colors,
  },
  reducers: {
    setColors: (state, action) => {
      const colors = verifyAndCompleteColorsFilterObj(action.payload);

      state.colors = colors;
    },
    setColor: (state, action) => {
      const newColors = formDefaultColorsFilteredObj(action.payload);
      localStorage.setItem(COLORS, JSON.stringify(newColors));

      state.colors = newColors;
    },
  },
});

export const { setColors, setColor } = slice.actions;

export default slice.reducer;

export const colorsSelector = (state) => {
  const result = {};
  const { colors } = state.colors;
  for (let testType of Object.keys(colors)) {
    result[testType] = { ...colors[testType] };
    result[testType].colors = { ...colors[testType].colors };
  }

  return { colors: result };
};
