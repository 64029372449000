import React from 'react';

import { Skeleton, Stack } from '@mui/material';

export default function SkeletonGroupLoading({
  rowsAmount = 8,
  singleHeight = 20,
}) {
  return (
    <Stack
      playwrighttestid="skeleton-group-loading-block"
      spacing={1}
      sx={{ width: '100%' }}
    >
      {Array(rowsAmount)
        .fill(1)
        .map((_, i) => (
          <Skeleton key={_ + i} variant="rounded" height={singleHeight} />
        ))}
    </Stack>
  );
}
