import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import axios from 'axios';
import axiosThrottle from 'axios-request-throttle';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import { store } from './App/store';
import { LS_TOKEN } from './Constants';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { CssBaseline } from '@mui/material';

axiosThrottle.use(axios, { requestsPerSecond: 5 });

axios.interceptors.request.use((config) => {
  const { removeHeaderAuth } = config;
  if (removeHeaderAuth) {
    return config;
  }

  const token = localStorage.getItem(LS_TOKEN);
  if (token) {
    config.headers['Authorization'] = `${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // console.log('Axios Response interceptor error: ', JSON.stringify(error));
    if (error && error.message === 'Request failed with status code 403') {
      console.log('Authorization failed Error message: ', error.message);
      localStorage.clear();
      window.location.replace(window.location.origin + '/login');
    } else if (
      error &&
      error.message === 'Request failed with status code 401'
    ) {
      console.log('Unauthorized Error message: ', error.message);
      // TODO: Let user know that they do not have permission for the selected feature
    } else {
      console.log('Axios Error message: ', error.message);
      // TODO: Handle response errors in the application
      // eslint-disable-next-line no-undef
      return Promise.reject(error);
    }
  },
);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <CssBaseline />
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
