import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Stack } from '@mui/material';

import authentication from 'Api/authentication';

import AddCompanyBtn from 'Components/Companies/Buttons/AddCompanyBtn';
import CompaniesTable from 'Components/Companies/CompaniesTable';
import InputSearch from 'Components/Mix/InputSearch';
import Title from 'Components/Mix/Title';
import ErrorMessage from 'Components/UI/ErrorMessage';

import {
  alertCompaniesListDidUpdate,
  useAuthDispatch,
  useIsMounted,
} from 'Context';

import { setPageTitle } from 'Features/Main/mainSlice';

import { getDataFromResponse, isValidResponse } from 'Utils';

function Companies() {
  const dispatch = useAuthDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [mainError, setMainError] = useState('');
  const [companies, setCompanies] = useState([]);
  const [searchParam, setSearchParam] = useState('');

  const [page, setPage] = useState(0);

  const globalDispatch = useDispatch();
  globalDispatch(setPageTitle('Companies - Admin'));

  const mounted = useIsMounted();

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      setIsLoading(true);
      const [response, responsePermissions] = await Promise.all([
        authentication.getCompanies(),
        authentication.getCompaniesRemovalPermissions(),
      ]);

      if (isValidResponse(response)) {
        const _companies = getDataFromResponse(response);
        if (isValidResponse(responsePermissions)) {
          const permissions = getDataFromResponse(responsePermissions);
          for (let company of _companies) {
            const { companyid } = company;
            const permissionsInfo = permissions[companyid];
            if (permissionsInfo && Object.keys(permissionsInfo)) {
              const { can_be_deleted, reason } = permissionsInfo;
              company.canBeDeleted = can_be_deleted;
              company.restrictDeleteReason = reason;
              company.keyParamsEditable = can_be_deleted;
            }
          }
        }
        if (mounted.current) {
          setCompanies(_companies);
        }
      } else {
        if (mounted.current) {
          setMainError(response.data?.error?.message.toString());
        }
      }
    } catch (e) {
      if (mounted.current) {
        setMainError(e.message);
      }
    } finally {
      if (mounted.current) {
        setIsLoading(false);
      }
    }
  };

  const alertCompaniesUpdate = () => {
    alertCompaniesListDidUpdate(dispatch);
  };

  const afterCompaniesUpdate = async () => {
    alertCompaniesUpdate();
    await fetchCompanies();
  };

  return (
    <>
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
      <Stack direction="column" justifyContent="space-between">
        <Title text="Companies" />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 1, mt: 2 }}
        >
          <Box style={{ width: '240px' }}>
            <InputSearch
              id="input-search-companies"
              placeholder="Search companies"
              disabled={isLoading}
              onChange={(value) => {
                setPage(0);
                setIsLoading(true);
                setSearchParam(value);
                setTimeout(() => {
                  setIsLoading(false);
                }, 400);
                setTimeout(() => {
                  const el = document.getElementById('input-search-companies');
                  if (el) {
                    el.focus();
                  }
                }, 405);
              }}
            />
          </Box>
          <AddCompanyBtn afterAdd={afterCompaniesUpdate} disabled={isLoading} />
        </Stack>
        <CompaniesTable
          afterCompaniesUpdate={afterCompaniesUpdate}
          companiesData={companies}
          companiesFetching={isLoading}
          searchParam={searchParam}
          page={page}
          updatePage={(val) => {
            setIsLoading(true);
            setTimeout(() => {
              setPage(val);
              setIsLoading(false);
            }, 500);
          }}
        />
      </Stack>
    </>
  );
}

export default React.memo(Companies);
