import { scenarios as scenariosApi } from 'Api';
import {
  PROJECT_STATUSES,
  PROJECT_STATUS_WORKFLOW,
  TAGS_LIST,
} from 'Constants';
import React, { useMemo, useState } from 'react';

import { useTestContext } from '../SingleTest/context';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import {
  Checkbox,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material';

import DeleteDialog from 'Components/Dialogs/DeleteDialog';
import ErrorMessage from 'Components/UI/ErrorMessage';

import { isSafetracesAdmin, isViewer } from 'Config/roles';

import { useIsMounted } from 'Context';

import {
  StyledTableCell,
  StyledTableRow,
  StyledTestTableCell,
} from 'Layout/layoutStyles';

import { getErrorMessageFromResponse, isValidResponse } from 'Utils';

/**
 * Scenarios table in Single Test page for all tests types excluding genSurvs (as they don't have scenarios)
 * and healthcare tests (as they handle scenarios differently)
 */
export default function SingleTestCommonScenariosTable({
  scenarios,
  originPoints,
  projectId,
  test,
  editDisabled,
  projectStatus,
}) {
  const mounted = useIsMounted();
  const overrideEditPermissionSafetracesAdminIgroneWorkflow =
    isSafetracesAdmin();
  const { openEditModal, reFetchProject } = useTestContext();
  const semiHeadersTextStyle = {
    fontVariant: 'all-petite-caps',
    fontWeight: 'bolder',
    fontSize: '1.2em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'rgba(0, 0, 0, 0.6)',
  };

  const [resetTagModalOpen, setResetTagModalOpen] = useState(false);
  const [resetTagScenarioId, setResetTagScenarioId] = useState(null);
  const [resetScenarioTagInProgress, setResetScenarioTagInProgress] =
    useState(false);

  const [
    scenarioExecutionChangeInProgress,
    setScenarioExecutionChangeInProgress,
  ] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const samplesByScenarioId = useMemo(() => {
    const samplesByScenarioId = {};
    for (let scenario of test.scnrs) {
      samplesByScenarioId[scenario.scenarioid] = scenario.snumbers;
    }

    return samplesByScenarioId;
  }, [test]);

  const allowedTagsByScenarioId = useMemo(() => {
    if (!scenarios) return;

    const usedTags = scenarios.reduce((acc, scenario) => {
      if (scenario.tag && !acc.includes(scenario.tag)) {
        acc.push(scenario.tag);
      }
      return acc;
    }, []);
    const allowedTagsByScenarioId = {};

    for (let { tag, scenarioid } of scenarios) {
      allowedTagsByScenarioId[scenarioid] = TAGS_LIST.filter(
        (_tag) => !usedTags.includes(_tag) || tag === _tag,
      );
    }
    return allowedTagsByScenarioId;
  }, [scenarios]);

  const SCENARIOS_CONFIG_TABLE = {
    description: {
      key: 'scenariodescription',
      label: 'Description',
      align: 'left',
    },
    OPs: {
      key: 'OPs',
      label: 'OP Name',
      align: 'left',
    },
    tag: {
      key: 'tag',
      label: 'Tag',
      align: 'center',
    },
    lotNumber: {
      key: 'lotNumber',
      label: 'Tag Lot Number',
      align: 'center',
    },
    samples: {
      key: 'samples',
      label: 'Samples',
      align: 'center',
    },
    scenarioexecuted: {
      key: 'scenarioexecuted',
      label: 'Executed',
      align: 'center',
    },
  };

  const openTagResetDrawer = (scenarioId) => {
    setResetTagScenarioId(scenarioId);
    setResetTagModalOpen(true);
  };

  const closeTagResetDrawer = () => {
    setResetTagModalOpen(false);
    setResetTagScenarioId(null);
  };

  const handleTagReset = async () => {
    setResetScenarioTagInProgress(true);
    try {
      const response = await scenariosApi.resetScenarioTag(resetTagScenarioId);

      if (isValidResponse(response)) {
        reFetchProject();
        setResetTagModalOpen(false);
        setResetTagScenarioId(null);
      } else throw new Error(getErrorMessageFromResponse(response));
    } catch (err) {
      if (mounted.current) {
        console.error(`Error while deleting scenario tag: ${err.message}`);
      }
    } finally {
      if (mounted.current) setResetScenarioTagInProgress(false);
    }
  };

  const setScenarioExecuted = async (scenarioId, value) => {
    setScenarioExecutionChangeInProgress(true);
    try {
      const response = await scenariosApi.updateScenarios(scenarioId, {
        scenarioexecuted: value,
      });

      if (isValidResponse(response)) {
        reFetchProject();
        setScenarioExecutionChangeInProgress(false);
      } else throw new Error(getErrorMessageFromResponse(response));
    } catch (err) {
      if (mounted.current) {
        setScenarioExecutionChangeInProgress(false);
        setErrMsg(err.message);
        console.error(`Error while updating scenario: ${err.message}`);
      }
    }
  };

  return (
    <React.Fragment>
      <ErrorMessage
        message={errMsg}
        handleCloseErrorAlert={() => setErrMsg('')}
      />
      {!scenarios && (
        <React.Fragment>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </React.Fragment>
      )}
      {scenarios && (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <StyledTableRow border="initial">
                <StyledTableCell widthcol={2} headertype="secondary" />
                {scenarios.map(({ scenarioname, scenarioid }) => (
                  <StyledTestTableCell
                    widthcol={4}
                    headertype="secondary"
                    key={`scenario-${scenarioid}`}
                    align="left"
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography
                        style={{
                          textTransform: 'capitalize',
                          ...semiHeadersTextStyle,
                          fontSize: '1.3em',
                        }}
                        variant="subtitle2"
                      >
                        {scenarioname}
                      </Typography>
                    </Stack>
                  </StyledTestTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {Object.values(SCENARIOS_CONFIG_TABLE).map((rowConfig) => {
                return (
                  <React.Fragment key={rowConfig.key}>
                    <StyledTableRow
                      coloring="even"
                      sx={{ '& > *': { borderBottom: 'unset' } }}
                    >
                      <StyledTableCell>
                        <Typography
                          variant="subtitle2"
                          sx={semiHeadersTextStyle}
                        >
                          {rowConfig.label}
                        </Typography>
                      </StyledTableCell>
                      {Object.values(scenarios).map((scenario) => (
                        <StyledTableCell
                          key={`${scenario.scenarioid}-${rowConfig.key}`}
                          align={rowConfig.align}
                        >
                          <Stack direction="row" alignItems="center">
                            <Typography
                              variant="subtitle2"
                              sx={{ textTransform: 'capitalize' }}
                            >
                              {rowConfig.key === 'samples'
                                ? samplesByScenarioId[scenario.scenarioid]
                                : rowConfig.key !== 'scenarioexecuted'
                                ? scenario[rowConfig.key]
                                : null}
                            </Typography>
                            {rowConfig.key === 'scenariodescription' &&
                            !isViewer() &&
                            (!editDisabled ||
                              overrideEditPermissionSafetracesAdminIgroneWorkflow) ? (
                              <IconButton
                                aria-label="Edit comments"
                                size="small"
                                sx={{ ml: 2 }}
                                onClick={() => {
                                  openEditModal({
                                    type: 'scenario_comments',
                                    value: scenario.scenariodescription,
                                    data: { scenarioId: scenario.scenarioid },
                                  });
                                }}
                              >
                                <EditIcon fontSize="inherit" />
                              </IconButton>
                            ) : null}
                            {rowConfig.key === 'tag' &&
                              !isViewer() &&
                              !editDisabled && (
                                <IconButton
                                  onClick={() =>
                                    openTagResetDrawer(scenario.scenarioid)
                                  }
                                  aria-label="reset scenario tag"
                                  size="small"
                                >
                                  <DeleteOutlineIcon
                                    color="error"
                                    fontSize="inherit"
                                  />
                                </IconButton>
                              )}
                            {rowConfig.key === 'tag' &&
                              !isViewer() &&
                              !editDisabled && (
                                <IconButton
                                  aria-label="Edit Tag"
                                  size="small"
                                  sx={{ ml: 2 }}
                                  onClick={() => {
                                    openEditModal({
                                      type: 'tags',
                                      value: scenario.tag ?? '',
                                      testId: '',
                                      data: {
                                        originPoints,
                                        allowedTags:
                                          allowedTagsByScenarioId[
                                            scenario.scenarioid
                                          ] || [],
                                        scenario,
                                        projectId,
                                      },
                                    });
                                  }}
                                >
                                  <EditIcon fontSize="inherit" />
                                </IconButton>
                              )}

                            {rowConfig.key === 'scenarioexecuted' ? (
                              <Checkbox
                                checked={scenario[rowConfig.key]}
                                disabled={
                                  !scenario.tag ||
                                  scenarioExecutionChangeInProgress ||
                                  PROJECT_STATUS_WORKFLOW.indexOf(
                                    projectStatus,
                                  ) <
                                    PROJECT_STATUS_WORKFLOW.indexOf(
                                      PROJECT_STATUSES.EXECUTION_READY,
                                    )
                                }
                                size="small"
                                onChange={(e) =>
                                  setScenarioExecuted(
                                    scenario.scenarioid,
                                    e.target.checked,
                                  )
                                }
                              />
                            ) : null}
                          </Stack>
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <DeleteDialog
        type="reset"
        itemName="tag"
        open={resetTagModalOpen}
        handleClose={closeTagResetDrawer}
        handleDelete={handleTagReset}
        title="Reset tag"
        isLoading={resetScenarioTagInProgress}
      />
    </React.Fragment>
  );
}
