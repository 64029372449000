import { ERROR_MESSAGE_DEFAULT } from 'Constants';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ResultsContext } from '../Results';

import { Alert, Box, Stack, TextField } from '@mui/material';

import results from 'Api/results';

import UploadButton from 'Components/Buttons/UploadButton';
import ConfirmDialog from 'Components/Dialogs/ConfirmDialog';

import { useIsMounted } from 'Context';

import { getErrorMessageFromResponse, isValidResponse } from 'Utils';

function ResultsFileUpload({ projectId }) {
  const mounted = useIsMounted();

  const { afterResultFileUpload } = useContext(ResultsContext);

  const [isFileHovering, setIsFileHovering] = useState(false);
  const [isReadyForUpload, setIsReadyForUpload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);

  const [errorMessage, setErrorMessage] = useState(null);

  const [dataOverwriteConfirmed, setDataOverwriteConfirmed] = useState(false);
  const [filesOverwriteConfirmed, setFilesOverwriteConfirmed] = useState(false);

  const [showOverwriteModal, setShowOverwriteModal] = useState(false);

  const [overwriteModalType, setOverwriteModalType] = useState(null);
  const [overwriteModalTitle, setOverwriteModalTitle] = useState(``);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    reValidateMode: 'onChange',
  });

  const fileList = watch('file_upload');

  useEffect(() => {
    if (mounted.current) {
      setIsReadyForUpload(fileList?.length);
    }
  }, [fileList]);

  const handleFilesUpload = async (files) => {
    setIsUploading(true);
    setErrorMessage(null);

    for (let file of files) {
      const fr = new FileReader();

      fr.onload = async () => {
        filesToUpload.push({ filename: file?.name, content: fr.result });
        /** If it's the last file to read, then upload */
        if (filesToUpload.length === files.length) {
          await uploadFiles();
        }
      };

      fr.readAsDataURL(file);
    }
  };

  const uploadFiles = async (
    overwriteValues = false,
    overwriteFiles = false,
  ) => {
    if (!Array.isArray(filesToUpload) || !filesToUpload.length) {
      setFilesToUpload([]);
      setIsReadyForUpload(false);
      setIsUploading(false);
      setErrorMessage(ERROR_MESSAGE_DEFAULT);
      return;
    }

    let shouldUpdateResultsList = false;

    setIsUploading(true);

    try {
      const response = await results.uploadResults(projectId, {
        files: filesToUpload,
        confirm: dataOverwriteConfirmed || overwriteValues,
        confirmfiles: filesOverwriteConfirmed || overwriteFiles,
      });

      if (isValidResponse(response)) {
        setValue('file_upload', null);
        shouldUpdateResultsList = true;
        resetStateToDefault();
      } else {
        const errorResponseMsg = getErrorMessageFromResponse(response);

        if (!!~['confirm', 'confirmfiles'].indexOf(errorResponseMsg)) {
          setModalConfirmationConfig(
            errorResponseMsg === 'confirmfiles' ? 'files' : 'values',
          );
          setShowOverwriteModal(true);
        } else {
          throw new Error(errorResponseMsg);
        }
      }
    } catch (e) {
      if (mounted.current) {
        setErrorMessage(e.message || `Error uploading the file`);
      }
    } finally {
      if (
        shouldUpdateResultsList &&
        typeof afterResultFileUpload === 'function'
      ) {
        afterResultFileUpload();
      }

      if (mounted.current) {
        setIsUploading(false);
      }
    }
  };

  const resetStateToDefault = useCallback(() => {
    setShowOverwriteModal(false);
    setIsReadyForUpload(false);
    setFilesToUpload([]);
    setIsUploading(false);
    setDataOverwriteConfirmed(false);
    setFilesOverwriteConfirmed(false);
    setErrorMessage(null);
  }, []);

  const setModalConfirmationConfig = (confirmationType) => {
    if (!~['values', 'files'].indexOf(confirmationType)) {
      setOverwriteModalTitle('');
      setOverwriteModalType('');
      return false;
    }

    const title =
      confirmationType === 'files'
        ? `Are you sure that old files will be replaced? It will affect results' snapshot`
        : `Are you sure that old values will be replaced? It will affect results' snapshot`;

    setOverwriteModalTitle(title);
    setOverwriteModalType(confirmationType);
  };

  const confirmValuesOverwriteAndUpload = useCallback(() => {
    setDataOverwriteConfirmed(true);
    setShowOverwriteModal(false);
    uploadFiles(true, filesOverwriteConfirmed);
  }, [filesOverwriteConfirmed]);

  const confirmFilesOverwriteAndUpload = useCallback(() => {
    setFilesOverwriteConfirmed(true);
    setShowOverwriteModal(false);
    uploadFiles(dataOverwriteConfirmed, true);
  }, [dataOverwriteConfirmed]);

  return (
    <Box className="results-upload-box">
      <form
        onSubmit={handleSubmit(
          async (data) => await handleFilesUpload(data?.file_upload),
        )}
      >
        <Stack spacing={2}>
          <Stack alignItems="start" gap={2} direction="column">
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              inputProps={{
                accept: '.xls,.xlsx',
                id: 'results-file-upload',
                multiple: true,
              }}
              type="file"
              {...register('file_upload', {
                required: 'File required',
                validate: {
                  lessThan10MB: (files) =>
                    Object.values(files)?.reduce(
                      (res, file) => res && file?.size < 10000000,
                      true,
                    ) || 'File is too large. Max file size is 10MB',
                },
              })}
              sx={{
                borderRadius: '5px',
                border: isFileHovering
                  ? '1px dotted black'
                  : '1px solid transparent',
              }}
              onDragEnter={() => {
                setIsFileHovering(true);
              }}
              onDragLeave={() => {
                setIsFileHovering(false);
              }}
              onDragEnd={() => {
                setIsFileHovering(false);
              }}
              error={!!(errors && errors.file_upload)}
              helperText={errors?.file_upload?.message}
            />
            {!!isReadyForUpload && (
              <UploadButton
                disabled={!isReadyForUpload || isUploading}
                loading={isUploading}
              ></UploadButton>
            )}
          </Stack>
          {!!errorMessage && (
            <Alert
              severity="error"
              onClose={() => {
                setErrorMessage(null);
              }}
            >
              {errorMessage}
            </Alert>
          )}
        </Stack>
      </form>
      <ConfirmDialog
        open={showOverwriteModal}
        title={overwriteModalTitle}
        handleConfirm={
          overwriteModalType === 'values'
            ? confirmValuesOverwriteAndUpload
            : overwriteModalType === 'files'
            ? confirmFilesOverwriteAndUpload
            : null
        }
        okBtnText="Overwrite"
        isLoading={isUploading}
        showContent={false}
        handleClose={resetStateToDefault}
      />
    </Box>
  );
}

export default React.memo(ResultsFileUpload);
