import React from 'react';

const darkCell = {
  background: 'rgb(0, 137, 150)',
  color: 'white',
  padding: '5px',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: 'smaller',
};

const lightCell = {
  background: 'white',
  borderRight: '1px solid gray',
  borderBottom: '1px solid gray',
  color: 'black',
  padding: '5px',
  textAlign: 'center',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: 'smaller',
};

const OPsAndSPs = ({ OPs, SPs }) => {
  return (
    <div>
      {Array.isArray(OPs) && (
        <table
          style={{
            border: '1px solid gray',
            borderRight: 'none',
            borderBottom: 'none',
            width: '99.5%',
            borderSpacing: 0,
          }}
        >
          <thead>
            <tr>
              <th style={darkCell} width="50%">
                OP number
              </th>
              <th style={darkCell} width="50%">
                OP name
              </th>
            </tr>
          </thead>
          <tbody>
            {OPs.sort((a, b) => a.number.localeCompare(b.number)).map((op) => (
              <tr key={op.number}>
                <td style={lightCell} width="50%">
                  {op.number ?? 'n/a'}
                </td>
                <td style={lightCell} width="50%">
                  {op.name ?? 'n/a'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <br />
      {Array.isArray(SPs) && (
        <table
          style={{
            border: '1px solid gray',
            borderRight: 'none',
            borderBottom: 'none',
            width: '99.5%',
            borderSpacing: 0,
          }}
        >
          <thead>
            <tr>
              <th style={darkCell} width="50%">
                SP number
              </th>
              <th style={darkCell} width="50%">
                SP name
              </th>
            </tr>
          </thead>
          <tbody>
            {SPs.sort((a, b) => a.number.localeCompare(b.number)).map((sp) => (
              <tr key={sp.number}>
                <td style={lightCell} width="50%">
                  {sp.number ?? 'n/a'}
                </td>
                <td style={lightCell} width="50%">
                  {sp.name ?? 'n/a'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default OPsAndSPs;
