import { ERROR_AUTOCLOSE_TIMEOUT_MS } from 'Constants';
import React, { useState } from 'react';

import AddBorderButton from '../../Buttons/AddBorderButton';
import AddEditCompanyModal from '../AddEditCompanyModal';

import authentication from 'Api/authentication';

import ErrorMessage from 'Components/UI/ErrorMessage';

import { useIsMounted } from 'Context';

import { isValidResponse } from 'Utils';

export default function AddCompanyBtn({ afterAdd, disabled }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [addInProgress, setAddInProgress] = useState(false);
  const [mainError, setMainError] = useState('');
  const mounted = useIsMounted();

  const addCompany = async (values) => {
    setAddInProgress(true);
    let isSuccess = false;
    try {
      const { companyname, companyaddress, contact, type } = values;
      const bodyObj = {
        companyName: companyname,
        companyAddress: companyaddress,
        contact,
        type,
      };
      const invalidData = Object.values(bodyObj).reduce(
        (res, value) => res || !value,
        false,
      );
      if (invalidData) {
        const error = new Error('Invalid request data');
        throw error;
      }
      const response = await authentication.createCompany(bodyObj);
      isSuccess = isValidResponse(response);
      if (!isSuccess) {
        throw new Error(response?.data?.error?.message);
      }
    } catch (err) {
      console.log('Add company error: ', err);
      setMainError(err?.message || `Couldn't save changes`);
      setTimeout(() => setMainError(''), ERROR_AUTOCLOSE_TIMEOUT_MS);
    } finally {
      if (mounted.current) {
        setAddInProgress(false);
      }
      if (isSuccess && typeof afterAdd === 'function') {
        if (mounted.current) {
          setModalOpen(false);
        }
        afterAdd();
      }
    }
  };

  return (
    <div>
      <AddBorderButton
        disableStyles={true}
        text="Add company"
        clickAction={() => setModalOpen(true)}
        extraBtnProps={{ disableFocusRipple: true }}
        disabled={disabled}
      />
      <AddEditCompanyModal
        open={modalOpen}
        type="add"
        handleClose={() => setModalOpen(false)}
        handleSubmit={(values) => addCompany(values)}
        actionsDisabled={addInProgress}
      />
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
    </div>
  );
}
