import { LS_SAFETRACE_SET_COMPANY_ID, LS_TOKEN } from 'Constants';
import { DeviceUUID } from 'device-uuid';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { LockOutlined } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Button,
  Container,
  CssBaseline,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import config from 'Config/config';

import { login, loginGoogle, useAuthDispatch, useAuthState } from 'Context';

import GoogleAuth from 'Pages/Login/GoogleAuth';

function Login() {
  const [open, setOpen] = useState(false);
  const [closed, setClosed] = useState(true);
  const [loginInProcess, setLoginInProcess] = useState(false);
  const [isMFANeeded, setIsMFANeeded] = useState(false);
  const [savedGoogleData, setSavedGoogleData] = useState(false);

  const dispatch = useAuthDispatch();
  const { loading, errorMessage } = useAuthState();
  let location = useLocation();
  let navigate = useNavigate();
  const {
    register,
    getValues,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm();

  if (errorMessage !== null && !open && closed) {
    setClosed(false);
    setOpen(true);
  }
  if (errorMessage === null && !closed) {
    setClosed(true);
  }

  function finishLogin(response) {
    setOpen(false);
    setClosed(true);
    console.log(response);
    if (
      response &&
      !response.success &&
      response.error.message === 'MFA failed'
    ) {
      setIsMFANeeded(true);
    }
    if (
      !response ||
      typeof response.data === 'undefined' ||
      typeof response.data.token === 'undefined'
    )
      return false;

    localStorage.setItem(LS_TOKEN, response.data.token);

    let { from } =
      localStorage.getItem(LS_SAFETRACE_SET_COMPANY_ID) !== null
        ? location.state || { from: { pathname: '/' } }
        : { from: { pathname: '/' } };
    window.location.replace(from.pathname);
  }

  const onSubmit = async (data) => {
    setLoginInProcess(true);
    let payload = {
      email: data.email.trim(),
      password: data.password,
      hash: new DeviceUUID().get(),
      code: data.code,
    };
    login(dispatch, payload).then((response) => {
      finishLogin(response);
      setTimeout(() => setLoginInProcess(false), 500);
    });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const LoginWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }));

  const LoginForm = styled('form')(({ theme }) => ({
    marginTop: theme.spacing(1),
    width: '100%',
  }));

  const handleLogin = async (googleData) => {
    if (!savedGoogleData) {
      setSavedGoogleData(googleData);
      loginGoogle(dispatch, {
        ...googleData,
        hash: new DeviceUUID().get(),
      }).then((response) => {
        finishLogin(response);
      });
    } else {
      const code = getValues('code');
      if (code === '') {
        setError('code', { type: 'focus' }, { shouldFocus: true });
        return;
      }
      loginGoogle(dispatch, {
        ...googleData,
        hash: new DeviceUUID().get(),
        code,
      }).then((response) => {
        finishLogin(response);
      });
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <LoginWrapper>
        <Avatar
          sx={{
            m: 1,
            bgcolor: 'primary.main',
          }}
        >
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <LoginForm onSubmit={handleSubmit(onSubmit)}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="login"
            label="Email Address"
            name="email"
            {...register('email', {
              required: 'Email field required',
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Entered value does not match email format',
              },
            })}
            error={!!(errors && errors.email)}
            helperText={errors?.email?.message}
            disabled={loading || loginInProcess}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            {...register('password', {
              required: 'Password field required',
            })}
            error={!!(errors && errors.password)}
            helperText={errors?.password?.message}
            disabled={loading || loginInProcess}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {isMFANeeded && (
            <>
              <Typography component="h5" variant="body1">
                Enter the code you received by email
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="code"
                label="Authentication code"
                id="code"
                {...register('code')}
                error={!!(errors && errors.password)}
                disabled={loading || loginInProcess}
                inputRef={(input) => input && input.focus()}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12 /*5*/}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{
                  my: 2,
                }}
                disabled={loading || loginInProcess}
              >
                Sign In
              </Button>
            </Grid>
            <Grid item xs={7}>
              {isMFANeeded && !!savedGoogleData ? (
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{
                    my: 2,
                  }}
                  onClick={() => handleLogin(savedGoogleData)}
                  disabled={loading || loginInProcess}
                >
                  Finish Google login
                </Button>
              ) : (
                <>
                  {/*<GoogleAuth
                    clientId={config.google_client_id}
                    onSuccess={handleLogin}
                    onFailure={handleLogin}
                  />*/}
                </>
              )}
            </Grid>
          </Grid>
        </LoginForm>
        {/* <Button
          onClick={() => navigate('/signup')}
          fullWidth
          variant="contained"
          color="primary"
          disabled={loading || loginInProcess}
        >
          Sign Up
        </Button> */}
        <Button onClick={() => navigate('/recover')}>forgot password?</Button>
      </LoginWrapper>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert onClose={handleClose} severity="error" variant="filled">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Login;
