import React from 'react';

import { List } from '@mui/material';

import nav from 'Config/nav';
import { hasPermission } from 'Config/roles';

import LeftMenuItem from 'Layout/LeftMenu/LeftMenuItem';

function LeftMenu() {
  const navConfig = nav;

  const MenuItems = React.useMemo(() => {
    return navConfig
      .filter((navItem) => hasPermission(navItem))
      .map((navItem) => {
        if (navItem.children) {
          navItem.children = navItem.children.filter((navChildItem) =>
            hasPermission(navChildItem),
          );
        }

        return <LeftMenuItem key={navItem?.name} navItem={navItem} />;
      });
  }, []);

  return <List>{MenuItems}</List>;
}
export default React.memo(LeftMenu);
