import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { setPageTitle } from '../../Features/Main/mainSlice';
import { StyledTableCell, StyledTableRow } from '../../Layout/layoutStyles';
import { isValidResponse } from '../../Utils';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Delete from '@mui/icons-material/Delete';
import FeedIcon from '@mui/icons-material/Feed';
import PrintIcon from '@mui/icons-material/Print';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemSecondaryAction,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import portfolios from 'Api/portfolios';

import { useAuthState } from 'Context';

export default function Documents() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const newAxiosInstance = axios.create();
  const [documentData, setDocumentData] = useState({});
  const [portfoliosData, setPortfoliosData] = useState([]);
  const [portfolio, setPortfolio] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [err, setErr] = useState('');
  const [openUpload, setOpenUpload] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const userData = JSON.parse(localStorage.getItem('currentUser'));

  const globalDispatch = useDispatch();
  globalDispatch(setPageTitle('Documents'));

  const authState = useAuthState();

  const download = () => {
    let a = document.createElement('a');
    a.href = documentData.documentUrl;
    a.download = `${documentData.documentName}.docx`;
    a.click();
  };

  const print = () => {
    alert('print');
  };

  const createUrl = async (url) => {
    const preUrl = await newAxiosInstance.get(
      url,
      {
        responseType: 'blob',
      },
      null,
    );
    let urlBlob = new Blob([preUrl.data], {
      type: preUrl.data.type,
    });
    let createObjectURL =
      window.URL.createObjectURL || window.webkitURL.createObjectURL;
    return createObjectURL(urlBlob);
  };

  const fetchPortfolios = async () => {
    const documentUrl = await portfolios.getDocuments();
    const portfolioWithSummaries =
      await portfolios.getPortfoliosWithSummaries();
    setPortfoliosData(portfolioWithSummaries.data.data);

    setDocumentData({
      documentUrl: documentUrl.data.data.documentUrl,
      documentName: 'Executive summary example',
    });
  };

  useEffect(() => {
    fetchPortfolios();
  }, [authState?.viewCompanyId]);

  const handleOpenUpload = (portfolio) => {
    setPortfolio(portfolio);
    setOpenUpload(true);
  };

  const handleCloseUpload = () => {
    setPortfolio({});
    setOpenUpload(false);
  };

  const handleOpenDelete = (portfolioId, summaryId) => {
    setDeleteData({ portfolioId, summaryId });
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setDeleteData({});
    setOpenDelete(false);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
    setErr(null);
    setMessage(null);
  };

  const uploadSummary = async (data) => {
    let fileReader = new FileReader();
    fileReader.onload = async () => {
      const upload = await portfolios.uploadSummary(portfolio.portfolioid, [
        {
          filename: data.file_upload[0].name,
          content: fileReader.result,
        },
      ]);
      if (!isValidResponse(upload)) {
        setErr(upload.data.error.message);
        setOpenAlert(true);
        await fetchPortfolios();
      } else {
        setMessage(upload.data.data.message);
        setOpenAlert(true);
        await fetchPortfolios();
        handleCloseUpload();
      }
    };
    fileReader.readAsDataURL(data.file_upload[0]);
  };

  const deleteSummary = async () => {
    const { portfolioId, summaryId } = deleteData;
    const response = await portfolios.deleteSummary(portfolioId, summaryId);
    if (!isValidResponse(response)) {
      setErr(response.data.error.message);
      setOpenAlert(true);
      await fetchPortfolios();
    } else {
      setMessage(response.data.data.message);
      setOpenAlert(true);
      await fetchPortfolios();
      handleCloseDelete();
    }
  };

  const downloadSummary = async (url, filename) => {
    const downloadUrl = await createUrl(url);

    let a = document.createElement('a');

    a.href = downloadUrl;
    a.download = `${filename}`;

    a.click();
    await fetchPortfolios();
  };

  return (
    <>
      {userData.accessLevel === 'Admin' &&
      userData.roleName === 'Safetracer' ? (
        <div style={{ width: '100%' }}>
          <div style={{ width: '100%' }}>
            <Typography variant="h6">
              Another professionally written text goes here to let the customer
              know the contract is prepared based on their portfolio documents
              with all the tests listed
            </Typography>
            <List component="div">
              <ListItem>
                <ListItemIcon>
                  <FeedIcon />
                </ListItemIcon>
                <ListItemText primary={'Executive summary example'} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    sx={{ mr: 1 }}
                    onClick={() => download()}
                  >
                    <CloudDownloadIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" onClick={print}>
                    <PrintIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
            <h3>Portfolio list</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell sx={{ width: 300, pl: 10 }} align="left">
                      Portfolio Name
                    </StyledTableCell>
                    <StyledTableCell />
                    <StyledTableCell />
                    <StyledTableCell />
                    <StyledTableCell />
                    <StyledTableCell />
                    <StyledTableCell sx={{ width: 300 }} align="center">
                      Action
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {portfoliosData ? (
                    portfoliosData.map((portfolio) => (
                      <React.Fragment key={portfolio.portfolioid}>
                        <StyledTableRow
                          sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}
                          key={portfolio.portfolioid}
                        >
                          <StyledTableCell align="left" sx={{ pl: 10 }}>
                            {portfolio.portfolioname}
                          </StyledTableCell>
                          <StyledTableCell />
                          <StyledTableCell />
                          <StyledTableCell />
                          <StyledTableCell />
                          <StyledTableCell />
                          <StyledTableCell align="center">
                            <Button
                              variant="contained"
                              onClick={() => handleOpenUpload(portfolio)}
                            >
                              Upload
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                        {portfolio.summaries ? (
                          portfolio.summaries.map((summary) => (
                            <TableRow key={summary.summaryid}>
                              <StyledTableCell align="left" sx={{ pl: 15 }}>
                                {summary.filename}
                              </StyledTableCell>
                              <StyledTableCell />
                              <StyledTableCell />
                              <StyledTableCell />
                              <StyledTableCell />
                              <StyledTableCell />
                              <StyledTableCell align="center">
                                <IconButton
                                  edge="end"
                                  sx={{ mr: 1 }}
                                  onClick={async () =>
                                    await downloadSummary(
                                      summary.url,
                                      summary.filename,
                                    )
                                  }
                                >
                                  <CloudDownloadIcon />
                                </IconButton>
                                <IconButton
                                  edge="end"
                                  sx={{ mr: 1 }}
                                  onClick={() =>
                                    handleOpenDelete(
                                      portfolio.portfolioid,
                                      summary.summaryid,
                                    )
                                  }
                                >
                                  <Delete />
                                </IconButton>
                              </StyledTableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <StyledTableCell align="left" sx={{ pl: 15 }}>
                              No summaries
                            </StyledTableCell>
                            <StyledTableCell />
                            <StyledTableCell />
                            <StyledTableCell />
                            <StyledTableCell />
                            <StyledTableCell />
                            <StyledTableCell />
                          </TableRow>
                        )}
                      </React.Fragment>
                    ))
                  ) : (
                    <TableRow>
                      <StyledTableCell />
                      <StyledTableCell />
                      <StyledTableCell sx={{ textAlign: 'center' }}>
                        <Typography sx={{ textAlign: 'center' }} variant="h4">
                          Portfolio list is empty
                        </Typography>
                      </StyledTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Dialog onClose={handleCloseUpload} open={openUpload}>
              <form
                onSubmit={handleSubmit(
                  async (data) => await uploadSummary(data),
                )}
              >
                <DialogTitle>Upload Executive Summary</DialogTitle>
                <DialogContent
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    type="file"
                    {...register('file_upload', {
                      required: 'File required',
                      validate: {
                        lessThan10MB: (files) =>
                          files[0]?.size < 10000000 || 'Max 10MB',
                      },
                    })}
                    error={!!(errors && errors.file_upload)}
                    helperText={errors?.file_upload?.message}
                  />
                </DialogContent>
                <DialogActions>
                  <Button variant="outlined" onClick={handleCloseUpload}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    Upload
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
            <Dialog onClose={handleCloseDelete} open={openDelete}>
              <DialogTitle>Confirm deletion</DialogTitle>
              <DialogActions>
                <Button variant="outlined" onClick={handleCloseDelete}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => await deleteSummary()}
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              onClose={handleAlertClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {!err ? (
                <Alert
                  onClose={handleAlertClose}
                  severity="success"
                  variant="filled"
                >
                  {message}
                </Alert>
              ) : (
                <Alert
                  onClose={handleAlertClose}
                  severity="error"
                  variant="filled"
                >
                  {err}
                </Alert>
              )}
            </Snackbar>
          </div>
        </div>
      ) : null}
    </>
  );
}
