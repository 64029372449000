export function scaleCoordinates({
  fromDimensions,
  toDimensions,
  fromCoordinates,
}) {
  if (
    !fromDimensions ||
    !toDimensions ||
    !fromDimensions.width ||
    !fromDimensions.height ||
    !toDimensions.width ||
    !toDimensions.height
  ) {
    return {
      x: 0,
      y: 0,
    };
  }

  const scaleX = toDimensions.width / fromDimensions.width;
  const scaleY = toDimensions.height / fromDimensions.height;

  return {
    x: fromCoordinates.x * scaleX ?? 0,
    y: fromCoordinates.y * scaleY ?? 0,
  };
}
const _templateSizeConstrains = {
  imgSize: {
    width: 2798,
    height: 1942,
  },
  topLeft: {
    x: 540,
    y: 795,
  },
  bottomRight: {
    x: 3338,
    y: 2737,
  },
};

export const getScaledImageSize = (
  from,
  to = _templateSizeConstrains.imgSize,
) => {
  const wRatio = to.width / from.width;
  const hRatio = to.height / from.height;

  const ratio = from.height * wRatio <= to.height ? wRatio : hRatio;

  return {
    width: from.width * ratio,
    height: from.height * ratio,
  };
};

export const getMergePosition = (
  innerImgSize,
  topLeft = _templateSizeConstrains.topLeft,
  bottomRight = _templateSizeConstrains.bottomRight,
) => {
  const containerSize = {
    width: bottomRight.x - topLeft.x,
    height: bottomRight.y - topLeft.y,
  };

  return {
    x: containerSize.width / 2 - innerImgSize.width / 2 + topLeft.x,
    y: containerSize.height / 2 - innerImgSize.height / 2 + topLeft.y,
  };
};

export const cropImage = (image, cropData) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = getScaledImageSize(cropData).width;
  canvas.height = getScaledImageSize(cropData).height;

  context.drawImage(
    image,
    cropData.x,
    cropData.y,
    cropData.width,
    cropData.height,
    0,
    0,
    getScaledImageSize(cropData).width,
    getScaledImageSize(cropData).height,
  );

  const croppedData = context.getImageData(
    0,
    0,
    getScaledImageSize(cropData).width,
    getScaledImageSize(cropData).height,
  );

  const canvasCropped = document.createElement('canvas');
  const contextCropped = canvasCropped.getContext('2d');

  canvasCropped.width = getScaledImageSize(cropData).width;
  canvasCropped.height = getScaledImageSize(cropData).height;

  contextCropped.putImageData(croppedData, 0, 0);

  return canvasCropped.toDataURL(`image/png`, 1);
};

export const cropImageWithoutScale = (image, cropData) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = cropData.width;
  canvas.height = cropData.height;

  context.drawImage(
    image,
    cropData.x,
    cropData.y,
    cropData.width,
    cropData.height,
    0,
    0,
    cropData.width,
    cropData.height,
  );

  const croppedData = context.getImageData(
    0,
    0,
    cropData.width,
    cropData.height,
  );

  const canvasCropped = document.createElement('canvas');
  const contextCropped = canvasCropped.getContext('2d');

  canvasCropped.width = cropData.width;
  canvasCropped.height = cropData.height;

  contextCropped.putImageData(croppedData, 0, 0);

  return canvasCropped.toDataURL(`image/png`, 1);
};

export const cropImageFromURL = (url, cropData, callback) => {
  const originalImage = new Image();
  originalImage.setAttribute('crossOrigin', 'anonymous');
  originalImage.src = url;
  originalImage.addEventListener('load', function () {
    const croppedURL = cropImageWithoutScale(originalImage, cropData);
    callback(croppedURL);
  });
};

export const cropImageFromUrlPromisified = ({ url, cropData }) => {
  return new Promise((resolve, reject) => {
    const originalImage = new Image();
    originalImage.setAttribute('crossOrigin', 'anonymous');
    originalImage.addEventListener('load', () => {
      const croppedURL = cropImageWithoutScale(originalImage, cropData);
      resolve(croppedURL);
    });
    originalImage.addEventListener('error', (e) => {
      console.error('Failed to load the image', e);
      reject(new Error('Failed to load the image'));
    });
    originalImage.src = url;
  });
};

export const fileNameUtil = {
  getExtension: (fileName) => {
    if (!fileName || typeof fileName !== 'string') {
      return null;
    }

    const _filename = fileName.toLowerCase();

    if (
      _filename.endsWith('.jpg') ||
      _filename.endsWith('.jpeg') ||
      _filename.endsWith('.png')
    ) {
      const parts = fileName.split('.');

      return parts[parts.length - 1];
    }

    return null;
  },
  removeExtension: (fileName) => {
    const extension = fileNameUtil.getExtension(fileName);

    return extension ? fileName.split('.' + extension)[0] : fileName;
  },

  getFileNamesWithOutExtensions: (fileList) =>
    fileList?.map(({ filename }) => fileNameUtil.removeExtension(filename)),

  appendSuffixToFileName: (fileName, fileList) => {
    let suffixInt = 1;
    let fileNames = fileNameUtil.getFileNamesWithOutExtensions(fileList);
    if (fileNames.includes(fileName)) {
      while (fileNames.includes(fileName + ' (' + suffixInt + ')')) {
        suffixInt++;
      }
      return fileName + ' (' + suffixInt + ')';
    }
    return fileName;
  },

  appendExtensionToFileName: (fileName) => {
    return fileName + '.png';
  },

  isFileNameAlreadyUsed: (fileName, fileList) => {
    return fileNameUtil
      .getFileNamesWithOutExtensions(fileList)
      .map((item) => {
        return item.toLowerCase();
      })
      .includes(fileName.toLowerCase());
  },

  isFileNameInvalid: (fileName) => {
    if (typeof fileName !== 'string' || !fileName.trim().length) {
      return true;
    }

    const re = /^(con|prn|aux|nul|((com|lpt)[0-9]))$|([<>:"\\|?*])|(\.|\s)$/gi;
    return re.test(fileName);
  },
};

export const pdfToImage = (file) => {
  return new Promise((resolve, reject) => {
    try {
      import('pdfjs-dist').then((pdfjs) => {
        import('pdfjs-dist/build/pdf.worker.entry').then((pdfjsWorker) => {
          pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
          const { getDocument } = pdfjs;
          const reader = new FileReader();
          reader.onload = (e) => {
            const pdfData = new Uint8Array(e.target.result);
            getDocument(pdfData).promise.then((pdf) => {
              pdf.getPage(1).then((page) => {
                const scale = 1.5;
                const viewport = page.getViewport({ scale });
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                const renderContext = {
                  canvasContext: context,
                  viewport: viewport,
                };
                page.render(renderContext).promise.then(() => {
                  canvas.toBlob((blob) => {
                    resolve(blob);
                  });
                });
              });
            });
          };
          reader.readAsArrayBuffer(file);
        });
      });
    } catch (e) {
      console.error("Can't convert pdf to image", e.toString());
      reject('Error converting pdf to image');
    }
  });
};
