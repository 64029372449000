import React from 'react';

import { Table, TableBody, TableHead } from '@mui/material';

import {
  StyledTableCell,
  StyledTableRow,
} from 'Layout/layoutStyles';

export default function GenSurvOPTable(props) {
  const { originPoints } = props;
  
  return (
    <React.Fragment>
      <Table size="small" aria-label="purchases">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>OP Name</StyledTableCell>
            <StyledTableCell>Tag</StyledTableCell>
            <StyledTableCell>Tag Lot Number</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {originPoints.map((OP, i) => (
            <React.Fragment key={i}>
              {!!OP.tags && OP.tags.length > 0 && (
                <StyledTableRow key={OP.opnumber}>
                  <StyledTableCell component="th" scope="row">
                    {OP.opnumber}
                  </StyledTableCell>
                  <StyledTableCell>
                    {!!OP.tagLotNumber && !!OP.tagLotNumber[0] && (
                      <>{OP.tagLotNumber.map((v) => v.tag ?? '').join(', ')}</>
                    )}
                    {!OP.tagLotNumber[0] && <>{OP.tags[0].tag}</>}
                  </StyledTableCell>
                  <StyledTableCell>
                    {OP.tagLotNumber.map((v) => v.lotNumber ?? '').join(', ')}
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}