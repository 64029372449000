import { samplePlans } from 'Api';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  Skeleton,
  Tooltip,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';

import originpoints from 'Api/originpoints';

import TagForm from 'Components/SingleProject/Main/Forms/TagForm';
import { useProjectContext } from 'Components/SingleProject/context';

import { isViewer } from 'Config/roles';

import { projectDataSelector } from 'Features/Projects/projectsSlice';

import { StyledTableCell, StyledTableRow } from 'Layout/layoutStyles';

import { getErrorMessageFromResponse, isValidResponse } from 'Utils';

function Row(props) {
  const { tag, loading, handleSubmit } = props;

  return (
    <React.Fragment>
      <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <StyledTableCell align="center">
          {loading ? (
            <Skeleton sx={{ margin: 'auto' }}>
              <span>{tag[0].tag}</span>
            </Skeleton>
          ) : (
            tag[0].tag
          )}
        </StyledTableCell>
        <StyledTableCell align="center">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loading ? (
              <Box sx={{ width: '100%' }}>
                <Skeleton>
                  {!isViewer() ? (
                    <FormControl fullWidth sx={{ width: '1000px' }}>
                      <InputLabel></InputLabel>
                      <Select variant="standard" value={''}></Select>
                    </FormControl>
                  ) : null}
                </Skeleton>
              </Box>
            ) : (
              <Box sx={{ width: '100%' }}>
                <React.Fragment>
                  {!isViewer() ? (
                    <TagForm
                      tag={tag[0]}
                      onSubmit={(values) => {
                        return handleSubmit(tag, values);
                      }}
                    />
                  ) : (
                    <>
                      <span>{tag[0].tagLotNumber}</span>
                      {tag[0].tagLotNumberIsSterile && (
                        <Tooltip
                          title="Sterile"
                          placement="top"
                          aria-label="Sterile"
                        >
                          <LocalHospitalIcon sx={{ color: '#008996' }} />
                        </Tooltip>
                      )}
                    </>
                  )}
                </React.Fragment>
              </Box>
            )}
          </Box>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function ProjectTagsTable(props) {
  const { tags, fetchTags } = props;

  const { projectId } = useParams();
  const { setMainError } = useProjectContext();
  const { isProjectTagsLoading, permissions } =
    useSelector(projectDataSelector);
  const { canEditTests } = permissions;
  const [tag, setTag] = useState(false);

  useEffect(() => {
    // If tag is already set but the list of tags has changed, update the tag for new
    // tag lot numbers to appear in the form
    if (tag) {
      const tagName = tag[0].tag;
      const newTag = tags[tagName];
      setTag(newTag);
    }
  }, [tags]);

  function updateTag(tag, values) {
    if (!values.tagLotNumber) return;

    const tagLotNumbers = [];

    tag.forEach((item) => {
      tagLotNumbers.push({
        OPId: item.opid,
        tag: item.tag,
        tagLotNumber: values.tagLotNumber,
        expirationDate: item.expirationDate,
      });
    });

    originpoints
      .setMultipleTagLotNumber(tagLotNumbers)
      .then(async (response) => {
        let errMsg;
        let allSuccess = true;
        try {
          await samplePlans.removeSamplePlan(projectId);
        } catch (e) {
          console.log(e);
        }

        if (!isValidResponse(response)) {
          allSuccess = false;
          errMsg = getErrorMessageFromResponse(response);
        }

        if (!allSuccess) {
          setMainError(errMsg);
        }
      })
      .catch((reason) => {
        console.log(reason);
      })
      .finally(() => {
        fetchTags();
      });
  }

  return (
    <>
      {tags !== null && Object.keys(tags).length !== 0 && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <TableContainer component={Paper}>
                <Table aria-label="table" sx={{ tableLayout: 'fixed' }}>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="center" widthcol={6}>
                        Tag
                      </StyledTableCell>
                      <StyledTableCell align="center" widthcol={18}>
                        Lot Number*
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(tags).map((key, index) => (
                      <Row
                        key={key}
                        tag={tags[key]}
                        index={index}
                        editDisabled={!canEditTests || isProjectTagsLoading}
                        loading={isProjectTagsLoading}
                        handleSubmit={(tag, values) => updateTag(tag, values)}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
