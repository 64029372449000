import React, { useEffect, useRef, useState } from 'react';

import DoneIcon from '@mui/icons-material/Done';
import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import originpoints from 'Api/originpoints';
import samplePoints from 'Api/samplePoints';

import { useFloorplanContext } from 'Components/Floorplans/context';

const PointsPaper = ({ title, children }) => {
  return (
    <Paper sx={{ p: 2 }}>
      <Typography sx={{ mb: 2 }} variant="h5">
        {title}
      </Typography>
      <Stack spacing={1}>{children}</Stack>
    </Paper>
  );
};

const SpOpEditableList = ({ OPs, SPs, reFetchOpsAndSps, setError }) => {
  return (
    <Stack
      spacing={3}
      sx={{
        overflowY: 'scroll',
        minWidth: '280px',
        maxWidth: '280px',
        maxHeight: '500px',
      }}
    >
      <Box>
        {OPs && OPs.length ? (
          <PointsPaper title="Origin points">
            {OPs.map((op) => (
              <PointItem
                key={op.opid}
                id={op.opid}
                type="OP"
                name={op.opname}
                placeholder={op.opnumber}
                reFetch={reFetchOpsAndSps}
                setError={setError}
              />
            ))}
          </PointsPaper>
        ) : null}
      </Box>
      <Box>
        {SPs && SPs.length ? (
          <PointsPaper title="Sample points">
            {SPs.map((sp) => (
              <PointItem
                key={sp.spid}
                sp={sp}
                reFetch={reFetchOpsAndSps}
                id={sp.spid}
                type="SP"
                name={sp.spname}
                placeholder={sp.spnumber}
                setError={setError}
              />
            ))}
          </PointsPaper>
        ) : null}
      </Box>
    </Stack>
  );
};

export default SpOpEditableList;

const PointItem = ({ id, name, placeholder, type, reFetch, setError }) => {
  const {
    focusOnPlaceblePointOnTextFieldFocus,
    resetFocusOnPlaceblePoint,
    pointFocusTextFieldId,
  } = useFloorplanContext();

  const textFieldRef = useRef(null);

  const setFocus = () => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  };

  const [pointName, setPointName] = useState(name);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (pointFocusTextFieldId === `${type}-${id}`) {
      setFocus();
    }
  }, [pointFocusTextFieldId]);

  useEffect(() => {
    setPointName(name);
  }, [name]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    updateName();
  };

  const updateName = async () => {
    setIsUpdating(true);
    try {
      const bodyObj =
        type === 'OP'
          ? {
              OPId: id,
              OPName: pointName,
            }
          : {
              SPId: id,
              SPName: pointName,
            };
      const res =
        type === 'OP'
          ? await originpoints.updateOriginPoint(bodyObj)
          : await samplePoints.updateSamplePoint(bodyObj);
      if (res?.data && res?.data?.success) {
        setIsSuccess(true);
        await reFetch();
      } else {
        setError(
          `Error while updating the ${type === 'OP' ? 'OP' : 'SP'} details`,
        );
      }
    } catch (e) {
      setError(
        `Error while updating the ${type === 'OP' ? 'OP' : 'SP'} details`,
      );
    } finally {
      setIsUpdating(false);
    }
  };

  const renderLoadingIcon = () => {
    if (isUpdating) return <CircularProgress size="30px" />;
    if (isSuccess) return <DoneIcon color="success" />;
  };

  return (
    <Box display="flex" alignItems="center">
      <form onSubmit={handleFormSubmit}>
        <TextField
          value={pointName ?? ''}
          inputRef={textFieldRef}
          variant="standard"
          onChange={(e) => {
            setPointName(e.target.value);
          }}
          size="small"
          onBlur={() => {
            updateName();
            resetFocusOnPlaceblePoint();
          }}
          onFocus={() => {
            focusOnPlaceblePointOnTextFieldFocus(`${type}-${id}`);
          }}
          placeholder={placeholder}
        />
      </form>
      <Box width="30px">{renderLoadingIcon()}</Box>
    </Box>
  );
};
