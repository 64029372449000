import React from 'react';

import { Link, Typography, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { getPartnerById, renderIcons } from 'Components/TestPackages/utils';

const SiteContractItem = ({ siteContract }) => {
  const isLargeScreen = useMediaQuery('(min-width:900px)');

  const renderPartnerName = () => {
    const partnerName = getPartnerById(siteContract.partner)?.companyname;
    return partnerName ? <strong>{partnerName}</strong> : null;
  };

  return (
    <Grid item xs={12} style={{ paddingLeft: 0 }}>
      <Link
        href={`/test-packages/${siteContract.sitecontractid}`}
        underline="none"
        sx={(theme) => ({
          border: '1px solid transparent',
          borderRadius: '4px',
          display: 'block',
          '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
          },
        })}
      >
        <Paper sx={{ padding: 1 }}>
          <Grid container>
            <Grid item md={6}>
              <Typography variant="h5">
                {siteContract.sitecontractname}
              </Typography>
              <Typography>{renderPartnerName()}</Typography>
              <Typography>Total tests: {siteContract.totaltests}</Typography>
            </Grid>
            <Grid item sm={12} md={6}>
              {renderIcons(
                siteContract?.testsmetadata || siteContract,
                isLargeScreen ? 'row-reverse' : 'row',
              )}
            </Grid>
          </Grid>
        </Paper>
      </Link>
    </Grid>
  );
};

export default SiteContractItem;
