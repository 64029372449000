import React, { useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';

import { useFloorplanContext } from '../context';
import { scaleCoordinates } from '../utils';
import FloorPlanHDAImage from './FloorPlanHDAImage';

import { Stack } from '@mui/material';

import floorplansApi from 'Api/floorPlans';

import { isValidResponse } from 'Utils';

const FloorplanHDAComponent = ({
  HDAs,
  refetchHDA,
  markerSize = 3,
  isImageLoaded,
  setIsImageLoaded,
  updateStatus,
  setUpdateStatus,
  hdaToCreateSpaceType,
  hdaTypesInFp,
  beforeHDACreation,
  clearErrors,
}) => {
  const {
    setError,
    setIsLoading,
    floorplanId,
    buildingId,
    projectId,
    floorplanData,
    setHDACreateMetadata,
  } = useFloorplanContext();
  const [floorPlanImageData, setFloorPlanImageData] = useState(null);
  const [naturalDimensions, setNaturalDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [scaledDimensions, setScaledDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [scaledHDAs, setScaledHDAs] = useState([]);
  const boundingBox = useRef(null);
  const dimensionsRef = useRef(null);
  const clickHandlerRef = useRef(null);

  const [, drop] = useDrop(
    () => ({
      accept: ['HDA'],
      drop: (item, monitor) => {
        const offset = monitor.getClientOffset();
        if (offset && boundingBox.current) {
          const scaledX =
            offset.x - boundingBox.current.getBoundingClientRect().left;
          const scaledY =
            offset.y - boundingBox.current.getBoundingClientRect().top;
          const naturalCoords = scaleCoordinates({
            fromDimensions: scaledDimensions,
            toDimensions: naturalDimensions,
            fromCoordinates: {
              x: scaledX,
              y: scaledY,
            },
          });
          updateCoordinates({
            hdaid: item.id,
            x: naturalCoords.x,
            y: naturalCoords.y,
          });
        }
      },
    }),
    [naturalDimensions, scaledDimensions],
  );

  useEffect(() => {
    setScaledHDAs(
      HDAs.map((hda) => {
        const coords = scaleCoordinates({
          fromDimensions: naturalDimensions,
          toDimensions: scaledDimensions,
          fromCoordinates: {
            x: Number.parseInt(hda.coordX),
            y: Number.parseInt(hda.coordY),
          },
        });
        return {
          ...hda,
          coordX: coords.x,
          coordY: coords.y,
        };
      }),
    );

    dimensionsRef.current = {
      naturalDimensions,
      scaledDimensions,
    };
  }, [HDAs, naturalDimensions, scaledDimensions]);

  useEffect(() => {
    if (floorplanData?.floorplanid) {
      fetchFloorPlanImage();
    }
  }, [floorplanData?.floorplanid]);

  useEffect(() => {
    if (!clickHandlerRef.current) {
      clickHandlerRef.current = {};
    }
    clickHandlerRef.current.hdaToCreateSpaceType = hdaToCreateSpaceType;
    clickHandlerRef.current.floorplanId = floorplanId;
  }, [hdaToCreateSpaceType, floorplanId]);

  const handleClickNewHDACreate = (coordX, coordY) => {
    try {
      if (typeof beforeHDACreation === 'function') {
        beforeHDACreation();
      }
      const _hdaToCreateSpaceType =
        clickHandlerRef.current.hdaToCreateSpaceType;
      const _floorplanId = clickHandlerRef.current.floorplanId;

      const creationInProgress = clickHandlerRef.current.creationInProgress;

      if (creationInProgress) return;
      if (!_hdaToCreateSpaceType) return;

      const coordsToSave = scaleCoordinates({
        fromDimensions: dimensionsRef.current.scaledDimensions,
        toDimensions: dimensionsRef.current.naturalDimensions,
        fromCoordinates: {
          x: coordX,
          y: coordY,
        },
      });

      if (_hdaToCreateSpaceType) {
        createHDA(
          _floorplanId,
          coordsToSave.x,
          coordsToSave.y,
          _hdaToCreateSpaceType,
        );
        return;
      }

      setHDACreateMetadata({
        x: coordsToSave.x,
        y: coordsToSave.y,
      });
    } catch (err) {
      console.log('Error: ', err);
      return false;
    }
  };

  const createHDA = async (_floorplanId, x, y, type) => {
    try {
      clickHandlerRef.current.creationInProgress = true;

      const bodyObj = {
        x,
        y,
        type,
      };

      const response = await floorplansApi.createHDA(_floorplanId, bodyObj);
      if (!isValidResponse(response)) {
        setError('Could not create HDA');
      }
      if (typeof refetchHDA === 'function') {
        refetchHDA(_floorplanId);
      }
    } catch (err) {
      console.log('Error: ', err);
    } finally {
      clickHandlerRef.current.creationInProgress = false;
    }
  };

  const fetchFloorPlanImage = async () => {
    setIsLoading(true);
    try {
      const req = await floorplansApi.getPresignedURL({
        buildingId: buildingId ?? '',
        projectId: buildingId ? '' : projectId,
        floorPlanId: floorplanId,
        action: 'getObject',
        heatmapVersion: 1,
      });

      if (req.status === 200 && req.data?.preSignedURL) {
        const img = new Image();
        img.src = req.data.preSignedURL;
        img.onload = () => {
          setNaturalDimensions({
            width: img.naturalWidth,
            height: img.naturalHeight,
          });
        };

        setFloorPlanImageData({
          url: req.data.preSignedURL,
          cropData: req?.data?.metadata?.cropData,
        });
      } else {
        setError(`No floorplan was found`);
      }
    } catch (e) {
      console.error('Error loading the floorplan image', e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateCoordinates = async ({ hdaid, x, y }) => {
    setUpdateStatus('loading');
    try {
      const resp = await floorplansApi.updateHDA(hdaid, {
        x,
        y,
      });
      if (isValidResponse(resp)) {
        if (typeof refetchHDA === 'function') {
          await refetchHDA();
        }
      } else {
        setError('Error occurred while updating the HDA details');
        setUpdateStatus('error');
      }
      setUpdateStatus('success');
      setTimeout(() => {
        setUpdateStatus('idle');
      }, 5000);
    } catch (e) {
      console.error('Error when updating the HDA', e);
      setError('Error when updating the HDA');
    }
  };

  return (
    <Stack sx={{ width: '65%' }}>
      <FloorPlanHDAImage
        dropRef={drop}
        boundingRef={boundingBox}
        imageData={floorPlanImageData}
        setScaledDimensions={setScaledDimensions}
        naturalDimensions={naturalDimensions}
        scaledDimensions={scaledDimensions}
        isImageLoaded={isImageLoaded}
        updateStatus={updateStatus}
        setIsImageLoaded={setIsImageLoaded}
        HDAs={scaledHDAs}
        markerSize={markerSize}
        handleClickNewHDACreate={handleClickNewHDACreate}
        clearErrors={clearErrors}
        hdaTypesInFp={hdaTypesInFp}
      />
    </Stack>
  );
};

export default FloorplanHDAComponent;
