import React from 'react';

import {
  Box,
  CircularProgress,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

const CircularProgressWithLabel = ({
  label,
  progressPercent,
  color,
  loading = false,
}) => {
  return (
    <Stack m={1} alignItems="center">
      <Box sx={{ position: 'relative', width: '40px', height: '40px' }}>
        {!loading ? (
          <>
            <CircularProgress
              variant="determinate"
              value={progressPercent}
              color={color}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >
                {progressPercent}%
              </Typography>
            </Box>
          </>
        ) : (
          <Skeleton variant="circular" height={40} />
        )}
      </Box>
      {!loading ? (
        <Typography variant="caption">{label}</Typography>
      ) : (
        <Skeleton>
          <Typography variant="caption">{label}</Typography>
        </Skeleton>
      )}
    </Stack>
  );
};

export default CircularProgressWithLabel;
