import React from 'react';

const SingleOP = ({
  x,
  y,
  scale,
  opnumber,
  showLabel,
  color,
  spCircleValue = 35,
}) => {
  const opLabel = () => {
    return (
      <g className="tooltip">
        <rect
          x={parseFloat(x) - (22 * (spCircleValue / 35)) / scale}
          y={parseFloat(y) + (2 * (spCircleValue / 35)) / scale}
          width={(45 * (spCircleValue / 35)) / scale}
          height={(16 * (spCircleValue / 35)) / scale}
          fill="lightblue"
          fillOpacity="0.85"
          stroke="black"
          strokeWidth={1 / scale}
        />
        <text
          x={parseFloat(x)}
          y={parseFloat(y) + (7 * (spCircleValue / 35) * 1.58) / scale}
          textAnchor="middle"
          alignmentBaseline="middle"
          fontFamily="Arial"
          fontSize={(16 * (0.7 * (spCircleValue / 35))) / scale + 'px'}
        >
          {opnumber}
        </text>
      </g>
    );
  };

  if (typeof x !== 'number' || typeof y !== 'number') return;

  return (
    <g>
      <circle
        cx={x}
        cy={y}
        r={10 / scale}
        fill={color}
        fillOpacity="0.8"
        stroke="black"
        strokeWidth="1"
      />
      <circle
        className={'circleForHover'}
        cx={x}
        cy={y}
        r={40}
        fillOpacity="0"
      />
      {!!showLabel && opLabel()}
    </g>
  );
};

export default SingleOP;
