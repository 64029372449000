import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Stack } from '@mui/material';

import authentication from 'Api/authentication';
import users from 'Api/users';

import InputSearch from 'Components/Mix/InputSearch';
import Title from 'Components/Mix/Title';
import ErrorMessage from 'Components/UI/ErrorMessage';
import AddUserBtn from 'Components/Users/Buttons/AddUserBtn';
import UsersTable from 'Components/Users/UsersTable';

import { isPartnerAdmin, isSafetracesAdmin } from 'Config/roles';

import { useAuthState, useIsMounted } from 'Context';

import { setPageTitle } from 'Features/Main/mainSlice';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  getLSViewCompanyId,
  getUserData,
  isValidResponse,
} from 'Utils';

export default function Users() {
  const [usersList, setUsersList] = useState([]);
  const [rolesData, setRolesData] = useState({});
  const [searchParam, setSearchParam] = useState('');
  const [page, setPage] = useState(0);

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({
    companyname: '',
    type: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [permissionsLoading, setPermissionsLoading] = useState(false);

  const [mainError, setMainError] = useState('');

  const mounted = useIsMounted();
  const authState = useAuthState();
  const [canManageUsers, setCanManageUsers] = useState(
    isSafetracesAdmin() ||
      (isPartnerAdmin() && getUserData().companyId === authState.viewCompanyId),
  );

  const dispatch = useDispatch();
  dispatch(
    isSafetracesAdmin() ? setPageTitle('Users - Admin') : setPageTitle('Users'),
  );

  useEffect(() => {
    setPage(0);

    const _selectedCompany = companies.find(
      (company) => company.companyid === authState.viewCompanyId,
    );
    if (_selectedCompany) {
      setSelectedCompany(_selectedCompany);
    }

    const _canManageUsers =
      isSafetracesAdmin() ||
      (isPartnerAdmin() && getUserData().companyId === authState.viewCompanyId);
    setCanManageUsers(_canManageUsers);

    fetchUsers();
    fetchPermissions();
  }, [authState.viewCompanyId]);

  const fetchUsers = async () => {
    if (mounted.current) {
      setIsLoading(true);
      setUsersList([]);
    }
    try {
      const response = await users.getUsersList(getLSViewCompanyId());
      if (isValidResponse(response)) {
        const result = response.data.data;
        if (mounted.current) {
          setUsersList(result.length ? result : []);
        }
      } else {
        throw new Error(getErrorMessageFromResponse(response));
      }
    } catch (e) {
      if (mounted.current) {
        setUsersList([]);
        setMainError(e.message);
      }
      console.log(e.toString());
    } finally {
      if (mounted.current) {
        setIsLoading(false);
      }
    }
  };

  const fetchPermissions = async () => {
    setPermissionsLoading(true);
    try {
      const responses = await Promise.all([
        users.getAllRoles(),
        users.getAllPermissionLevels(),
        authentication.getCompanies(),
      ]);

      for (let resp of responses) {
        if (!isValidResponse(resp))
          throw new Error(`Couldn't get persmissions data`);
      }

      const [roles, accesses, _companies] = responses.map((resp) =>
        getDataFromResponse(resp),
      );

      const _selectedCompany = _companies.find(
        (company) => company.companyid === authState.viewCompanyId,
      );

      if (mounted.current) {
        setRolesData({
          roles,
          accesses,
          selectedCompany: _selectedCompany.companyname,
        });
        setCompanies(_companies);
        setSelectedCompany(_selectedCompany);
      }
    } catch (e) {
      if (mounted.current) {
        setRolesData({});
        setMainError(e.toString());
      }
    } finally {
      if (mounted.current) {
        setPermissionsLoading(false);
      }
    }
  };

  const handleChangePage = (newPage) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setPage(newPage);
    }, 500);
  };

  return (
    <>
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
      <Stack direction="column" justifyContent="space-between">
        <Title text="Users" />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 1, mt: 2 }}
        >
          <Box style={{ width: '240px' }}>
            <InputSearch
              id="input-search-users"
              placeholder="Search users"
              disabled={isLoading}
              onChange={(value) => {
                setPage(0);
                setIsLoading(true);
                setSearchParam(value);
                setTimeout(() => {
                  setIsLoading(false);
                }, 400);
                setTimeout(() => {
                  const el = document.getElementById('input-search-users');
                  if (el) {
                    el.focus();
                  }
                }, 405);
              }}
            />
          </Box>
          <AddUserBtn
            sx={{ visibility: canManageUsers ? 'visible' : 'hidden' }}
            rolesData={rolesData}
            companyName={selectedCompany.companyname}
            companyType={selectedCompany.type}
            disabled={permissionsLoading || isLoading}
            afterAdd={fetchUsers}
          />
        </Stack>
        <UsersTable
          usersList={usersList}
          rolesData={rolesData}
          isLoading={isLoading || permissionsLoading}
          permissionsLoading={permissionsLoading}
          roles={rolesData.roles || []}
          accesses={rolesData.accesses || []}
          afterUsersListUpdate={fetchUsers}
          canManageUsers={canManageUsers}
          searchParam={searchParam}
          page={page}
          handleChangePage={handleChangePage}
        />
      </Stack>
    </>
  );
}
