import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import getIcon from './useIcons';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { isSelectedMenu } from 'Utils';

function LeftMenuItem({ navItem }) {
  const Icon = getIcon(navItem.icon);
  const location = useLocation();
  const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
    marginLeft: theme.spacing(1),
  }));

  let defaultOpen;
  if (navItem?.children) {
    const childSelected = navItem.children.reduce((selected, child) => {
      return selected || isSelectedMenu(location.pathname, child.to);
    }, false);

    defaultOpen = childSelected;
  }
  const [open, setOpen] = useState(!!defaultOpen);

  if (navItem.children) {
    const handleClick = () => {
      setOpen(!open);
    };

    return (
      <div key={navItem.name}>
        <ListItem button onClick={handleClick}>
          <ListItemIconStyled>
            <Icon />
          </ListItemIconStyled>
          <ListItemText primary={navItem.name} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={open} timeout="auto">
          <List component="div" disablePadding>
            {navItem.children.map((navChildItem) => {
              const ChildIcon = getIcon(navChildItem.icon);
              return (
                <ListItem
                  key={navChildItem.name}
                  button
                  component={Link}
                  to={navChildItem.to}
                  selected={
                    isSelectedMenu(location.pathname, navChildItem.to) ?? false
                  }
                  sx={{
                    pl: 4,
                  }}
                >
                  <ListItemIconStyled>
                    <ChildIcon />
                  </ListItemIconStyled>
                  <ListItemText primary={navChildItem.name} />
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </div>
    );
  } else {
    return (
      <ListItem
        button
        component={Link}
        to={navItem.to}
        key={navItem.name}
        selected={isSelectedMenu(location.pathname, navItem.to) ?? false}
      >
        <ListItemIconStyled>
          <Icon />
        </ListItemIconStyled>
        <ListItemText primary={navItem.name} />
      </ListItem>
    );
  }
}

export default React.memo(LeftMenuItem);
