import React from 'react';
import { useNavigate } from 'react-router-dom';

//import { layoutStyles } from '../../layoutStyles';
import IconButton from '@mui/material/IconButton';

const MainLogo = ({ onClickLocation }) => {
  //const classes = layoutStyles();
  const navigate = useNavigate();
  return (
    <IconButton
      // className={classes.toolbarNest}
      onClick={() => navigate(onClickLocation || '/')}
      sx={{
        display: { sm: 'none', md: 'block' },
      }}
      style={{ display: 'flex' }}
    >
      <img
        src={'/veriDART-BY-SAFETRACES.png'}
        style={{ height: 44, width: 200, margin: 10 }}
        alt=""
      />
    </IconButton>
  );
};

export default React.memo(MainLogo);
