import React, { useState } from 'react';

import { useTestScenariosContext } from '../context';

import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Stack, Tooltip } from '@mui/material';

import ErrorMessage from 'Components/UI/ErrorMessage';

import { isSafetracesAdmin } from 'Config/roles';

export default function EditScenarioCharacteristicButton({
  scenarioId,
  sx,
  field = '',
  restrictedEdit = false,
}) {
  const [mainError, setMainError] = useState('');
  const {
    startEditScenarioProcess,
    editPermission,
    forceRestrictAllEdits,
    forceRestrictAllEditsReason,
  } = useTestScenariosContext();

  const overrideEditPermissionSafetracesAdminIgroneWorkflow =
    isSafetracesAdmin();

  return (
    <Stack>
      <Tooltip title={forceRestrictAllEditsReason || ''} placement="top">
        <span>
          <IconButton
            size="small"
            sx={{ ...sx }}
            disabled={
              (!editPermission || forceRestrictAllEdits) &&
              !(
                overrideEditPermissionSafetracesAdminIgroneWorkflow &&
                restrictedEdit
              )
            }
            onClick={() =>
              startEditScenarioProcess(
                scenarioId,
                field,
                restrictedEdit ? 'restricted' : 'regular',
              )
            }
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
        </span>
      </Tooltip>
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
    </Stack>
  );
}
