import React from 'react';

import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function CancelDialog({
  open,
  handleClose,
  handleCancel,
  title = '',
  isLoading = false,
  itemName = '',
  buttonName = 'Cancel Item',
}) {
  return (
    <Dialog open={open}>
      <DialogTitle sx={{ width: 350 }}>{title}</DialogTitle>
      <DialogContent>{`Are you sure cancel ${itemName} ?`}</DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} sx={{ mr: 2 }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isLoading}
          color={'warning'}
          onClick={handleCancel}
        >
          {buttonName}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
