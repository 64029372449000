import React, { useCallback, useState } from 'react';

import { Stack } from '@mui/material';

import results from 'Api/results';

import DownloadButton from 'Components/Buttons/DownloadButton';

import { useIsMounted } from 'Context';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  isValidResponse,
} from 'Utils';

function ResultsDataDownloads({
  hasHealthcareResults,
  hasVerlikeResults,
  hasGeneralResults,
  projectId,
  projectName,
  projectCreationDate,
  onError,
}) {
  const mounted = useIsMounted();

  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [downloadType, setDownloadType] = useState(``);

  const handleDownloadIndicators = (isDownloading, type = `augmented`) => {
    if (mounted.current) {
      setDownloadInProgress(isDownloading);
      setDownloadType(isDownloading ? type : ``);
    }
  };

  const downloadAugmentedData = async (
    verificationFormat = false,
    healthcareFormat = false,
    downloadType,
  ) => {
    try {
      handleDownloadIndicators(true, downloadType);

      const response = await results.getAugmentedData(
        projectId,
        verificationFormat,
        healthcareFormat,
      );

      if (isValidResponse(response)) {
        const data = getDataFromResponse(response);

        if (!data) {
          throw new Error();
        }

        const csvFile = new Blob([data], { type: 'text/csv' });

        const createObjectURL =
          window.URL.createObjectURL || window.webkitURL.createObjectURL;

        const url = createObjectURL(csvFile);
        const link = document.createElement('a');

        link.href = url;
        link.download = `${projectCreationDate} PID ${projectId} ${projectName} Portal Augmented Data.csv`;

        link.click();

        return true;
      } else {
        throw new Error(getErrorMessageFromResponse(response));
      }
    } catch (err) {
      console.error(`handleDownloadAugmentedData Error: `, err);
      if (typeof onError === 'function') {
        onError(err.message);
      }

      return false;
    } finally {
      handleDownloadIndicators(false, downloadType);
    }
  };

  const handleDownloadAugmentedDataLegacy = useCallback(async () => {
    await downloadAugmentedData(false, false, 'augmentedLegacy');
  }, [projectId, projectName, projectCreationDate, onError]);

  const handleDownloadAugmentedDataVerLike = useCallback(async () => {
    await downloadAugmentedData(true, false, 'augmentedVerLike');
  }, [projectId, projectName, projectCreationDate, onError]);

  const handleDownloadAugmentedDataHealthcare = useCallback(async () => {
    await downloadAugmentedData(false, true, 'augmentedHealthcare');
  }, [projectId, projectName, projectCreationDate, onError]);

  const handleDownloadVerSummary = useCallback(async () => {
    try {
      handleDownloadIndicators(true, `verSummary`);

      const response = await results.getVerificationSummary(projectId);

      if (isValidResponse(response)) {
        const { content, filename } = getDataFromResponse(response);

        const csvFile = new Blob([content], { type: 'text/csv' });

        const createObjectURL =
          window.URL.createObjectURL || window.webkitURL.createObjectURL;
        const url = createObjectURL(csvFile);

        const link = document.createElement('a');
        link.href = url;
        link.download = `${filename}.csv`;

        link.click();

        return true;
      } else {
        throw new Error(getErrorMessageFromResponse(response));
      }
    } catch (err) {
      console.error(`handleDownloadVerSummary Error: `, err);
      if (typeof onError === 'function') {
        onError(err.message);
      }

      return false;
    } finally {
      handleDownloadIndicators(false);
    }
  }, [projectId, onError]);

  return (
    <Stack direction="row" gap={2}>
      {!!hasVerlikeResults ? (
        <DownloadButton
          text="Augmented data"
          disabled={downloadInProgress && downloadType === 'augmentedVerLike'}
          loading={downloadInProgress && downloadType === 'augmentedVerLike'}
          clickAction={handleDownloadAugmentedDataVerLike}
          sx={{ width: '180px' }}
        />
      ) : null}
      {!!hasHealthcareResults ? (
        <DownloadButton
          text="Augmented data (CET)"
          disabled={
            downloadInProgress && downloadType === 'augmentedHealthcare'
          }
          loading={downloadInProgress && downloadType === 'augmentedHealthcare'}
          clickAction={handleDownloadAugmentedDataHealthcare}
          sx={{ width: '220px' }}
        />
      ) : null}
      {!!hasGeneralResults && (
        <DownloadButton
          text="Augmented data (Legacy)"
          disabled={downloadInProgress && downloadType === 'augmentedLegacy'}
          loading={downloadInProgress && downloadType === 'augmentedLegacy'}
          clickAction={handleDownloadAugmentedDataLegacy}
          sx={{ width: '240px' }}
        />
      )}
      {!!hasVerlikeResults && (
        <DownloadButton
          text="Summary"
          variant="outlined"
          disabled={downloadInProgress && downloadType === 'verSummary'}
          loading={downloadInProgress && downloadType === 'verSummary'}
          clickAction={handleDownloadVerSummary}
          sx={{ width: '120px' }}
        />
      )}
    </Stack>
  );
}

export default React.memo(ResultsDataDownloads);
