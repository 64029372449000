import React, { useCallback } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import TestsDroppableBox from './TestsDroppableBox';

import { Stack } from '@mui/material';

const TestOrderDragAndDrop = ({ tests, updateTests, disableActions }) => {
  const onDragEnd = useCallback((event) => {
    try {
      if (event.reason !== 'DROP') return;

      const { destination, source } = event;

      const newIndex = destination.index;
      const oldIndex = source.index;

      const itemToReplace = tests.splice(oldIndex, 1)[0];
      tests.splice(newIndex, 0, itemToReplace);

      const newTests = tests.map((test, index) => {
        test.order = index + 1;
        return test;
      });

      updateTests(newTests);
    } catch (err) {
      console.log(`onDragEnd Error: `, err);
    }
  }, []);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Stack spacing={2} mt={2} style={{ width: '90%' }}>
        <TestsDroppableBox tests={tests} disableActions={disableActions} />
      </Stack>
    </DragDropContext>
  );
};

export default TestOrderDragAndDrop;
