import React, { createContext, useContext, useEffect, useState } from 'react';

import portfoliosApi from 'Api/portfolios';

import {
  checkIfContractsHasTestCoverage,
  checkIfSiteContractsHasTestCoverage,
  fetchPortfoliosDetailed,
  fetchTestsCoverageForContract,
  fetchTestsCoverageForPortfolio,
  injectTestCoverageDataIntoContractSiteContracts,
  injectTestCoverageDataIntoPortfoliosContracts,
} from 'Components/Portfolios/utils';

import { isValidResponse } from 'Utils';

const PortfolioContext = createContext({});

let testCoverageLoadingQueue = [];

export const PortfolioContextProvider = ({ children }) => {
  const [portfolios, setPortfolios] = useState({});
  const [initialLoading, setIsInitialLoading] = useState(true);
  const [sites, setSites] = useState({});
  const [toast, setToast] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [testCoverageBuffer, setTestCoverageBuffer] = useState({
    contracts: {},
    sc_contracts: {},
  });
  const [testCoverageIsUpdating, setTestCoverageIsUpdating] = useState(null);

  const [drawerMeta, setDrawerMeta] = useState({
    type: 'site',
    id: null,
    portfolioId: null,
  });

  let testCoverageInjectDataTimeoutId;

  useEffect(() => {
    testCoverageLoadingQueue = [];
    return () => {
      testCoverageLoadingQueue = [];
    };
  }, []);

  const setSuccessToast = (message) => {
    setToast({
      severity: 'success',
      message,
    });
  };

  const setErrorToast = (message) => {
    setToast({
      severity: 'error',
      message,
    });
  };

  const setWarningToast = (message) => {
    setToast({
      severity: 'warning',
      message,
    });
  };

  const fetchPortfoliosAndSites = async (
    firstLoadingForThisCompany = false,
  ) => {
    setIsLoading(true);
    if (firstLoadingForThisCompany) {
      setIsInitialLoading(true);
    }
    try {
      const [portfolioData, sitesResponse] = await Promise.all([
        fetchPortfoliosDetailed(),
        portfoliosApi.getPortfolioSites(),
      ]);

      if (isValidResponse(sitesResponse)) {
        setSites(sitesResponse.data?.data);
      } else {
        setErrorToast(sitesResponse.data?.error?.message.toString());
      }
      setPortfolios(portfolioData);
    } catch (e) {
      setErrorToast(e.toString());
    } finally {
      setIsLoading(false);
      setIsInitialLoading(false);
    }
  };

  const openEditModal = ({ type, id, portfolioId }) => {
    setDrawerMeta({ type, id, portfolioId });
    setIsDrawerOpen(true);
  };

  /**
   * Checks if tests coverage data is present in portfolio's contracts.
   * If not, checks if such update request is already in process (queue by portfolio ID).
   * If not, tries to obtain data from testCoverageBuffer, and if there are none sends
   * request to the server.
   * Then updates testCoverageBuffer, removes task from the queue
   * and schedules delayedInjectTestCoverageDataToPortfolios using setTimeout.
   * @param {number} portfolioId
   * @returns {Promise<boolean>}
   */
  const requestTestsCoverageDataForPortfolioUpdate = async (portfolioId) => {
    try {
      const queueId = portfolioId.toString();
      if (testCoverageLoadingQueue.includes(queueId)) {
        return;
      }

      const portfolioContractsDataIsPresent = checkIfContractsHasTestCoverage(
        portfolios,
        portfolioId,
      );

      if (portfolioContractsDataIsPresent) {
        return true;
      }

      setTestCoverageIsUpdating(true);
      testCoverageLoadingQueue.push(queueId);

      const contractsCoverageData =
        testCoverageBuffer.contracts[queueId] ||
        (await fetchTestsCoverageForPortfolio(portfolioId));

      if (!contractsCoverageData) throw new Error();

      testCoverageBuffer.contracts[queueId] = contractsCoverageData;
      setTestCoverageBuffer({ ...testCoverageBuffer });

      testCoverageLoadingQueue.splice(
        testCoverageLoadingQueue.indexOf(queueId),
        1,
      );

      if (!testCoverageInjectDataTimeoutId) {
        testCoverageInjectDataTimeoutId = setTimeout(
          delayedInjectTestCoverageDataToPortfolios,
          0,
        );
      }

      return true;
    } catch (err) {
      setErrorToast(`Couldn't load data about tests' status.`);
      return false;
    }
  };

  /**
   * Checks if tests coverage data is present in contract's site contracts.
   * If not, checks if such update request is already in process (queue key with portfolio + contract IDs).
   * If not, tries to obtain data from testCoverageBuffer, and if there are none sends
   * request to the server.
   * Then updates testCoverageBuffer, removes task from the queue
   * and schedules delayedInjectTestCoverageDataToPortfolios using setTimeout.
   * @param {number} portfolioId
   * @param {number} contractId
   * @returns {Promise<boolean>}
   */
  const requestTestsCoverageDataForContractUpdate = async (
    portfolioId,
    contractId,
  ) => {
    try {
      const queueId = `${portfolioId}_${contractId}`;
      if (testCoverageLoadingQueue.includes(queueId)) {
        return;
      }

      const dataIsPresent = checkIfSiteContractsHasTestCoverage(
        portfolios,
        portfolioId,
        contractId,
      );

      if (dataIsPresent) {
        return true;
      }

      setTestCoverageIsUpdating(true);
      testCoverageLoadingQueue.push(queueId);

      const siteContractsCoverageData =
        testCoverageBuffer.sc_contracts[queueId] ||
        (await fetchTestsCoverageForContract(contractId));

      if (!siteContractsCoverageData) throw new Error();

      testCoverageBuffer.sc_contracts[queueId] = siteContractsCoverageData;
      setTestCoverageBuffer({ ...testCoverageBuffer });
      testCoverageLoadingQueue.splice(
        testCoverageLoadingQueue.indexOf(queueId),
        1,
      );

      if (!testCoverageInjectDataTimeoutId) {
        testCoverageInjectDataTimeoutId = setTimeout(
          delayedInjectTestCoverageDataToPortfolios,
          0,
        );
      }

      return true;
    } catch (err) {
      setErrorToast(`Couldn't load data about tests' status.`);
      return false;
    }
  };

  /**
   * Checks if the queue is empty. If not, reschedules it for delayMs number of ms.
   * If the queue is empty, consequentially adds data from **testCoverageBuffer** to **portfolios** and
   * updates portfolios in state.
   * Inner portfolios' objects end up mutated, yet not changed.
   * @param {number} delayMs [100 by default]
   * @returns {boolean} Falsy both in case of error and in case of reschedule
   */
  const delayedInjectTestCoverageDataToPortfolios = (delayMs = 100) => {
    try {
      if (testCoverageInjectDataTimeoutId) {
        clearTimeout(testCoverageInjectDataTimeoutId);
        testCoverageInjectDataTimeoutId = null;
      }

      if (testCoverageLoadingQueue.length) {
        testCoverageInjectDataTimeoutId = setTimeout(
          delayedInjectTestCoverageDataToPortfolios,
          delayMs,
        );
        return false;
      }

      const { contracts: tcContracts, sc_contracts: tcSc_contracts } =
        testCoverageBuffer;

      let newPortfolioObj = { ...portfolios };

      for (let portflioIdStr of Object.keys(tcContracts)) {
        const portflioId = +portflioIdStr;
        const coverageData = tcContracts[portflioIdStr];

        newPortfolioObj = injectTestCoverageDataIntoPortfoliosContracts(
          portflioId,
          newPortfolioObj,
          coverageData,
        );

        if (!newPortfolioObj) throw new Error();
      }

      for (let key of Object.keys(tcSc_contracts)) {
        const [portflioId, contractId] = key.split('_');
        const coverageData = tcSc_contracts[key];

        newPortfolioObj = injectTestCoverageDataIntoContractSiteContracts(
          +portflioId,
          +contractId,
          newPortfolioObj,
          coverageData,
        );

        if (!newPortfolioObj) throw new Error();
      }
      setPortfolios(newPortfolioObj);
      setTimeout(() => setTestCoverageIsUpdating(false), 0);

      return true;
    } catch (err) {
      return false;
    }
  };

  return (
    <PortfolioContext.Provider
      value={{
        portfolios,
        setPortfolios,
        toast,
        setToast,
        setSuccessToast,
        setErrorToast,
        setWarningToast,
        sites,
        setSites,
        fetchPortfoliosAndSites,
        requestTestsCoverageDataForPortfolioUpdate,
        requestTestsCoverageDataForContractUpdate,
        testCoverageIsUpdating,
        openEditModal,
        isDrawerOpen,
        setIsDrawerOpen,
        drawerMeta,
        isLoading: isLoading || initialLoading,
        setIsLoading,
        initialLoading,
      }}
    >
      {children}
    </PortfolioContext.Provider>
  );
};

export const usePortfolioContext = () => {
  return useContext(PortfolioContext);
};
