import { TEST_TYPES } from 'Constants';
import React, { useCallback, useState } from 'react';

import { Box, Skeleton, Stack } from '@mui/material';

import projects from 'Api/projects';
import tags from 'Api/tags';

import DownloadButton from 'Components/Buttons/DownloadButton';

import { useIsMounted } from 'Context';

import { getDataFromResponse, isValidResponse } from 'Utils';

function OrderForm({ loading, projectId, projectName, onError }) {
  const mounted = useIsMounted();

  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const handleTagSolutionFormatting = (ofObj, tagsObj) => {
    const orderFormdata = [
      ['', '', 'veriDART Kit Packaging List', ''],
      ['SafeTraces', 'Phil Arnold', '', ''],
      ['4473 Willow Rd 260', '+1 650-307-3362', '', ''],
      ['"Pleasanton, CA. 94588"', 'Phil@SafeTraces.com', '', ''],
      ['USA', '', '', ''],
      [''],
      ['Project Name', 'PID', 'Customer Name', 'Partner Name'],
    ];
    orderFormdata.push([
      ofObj?.projectInfo?.projectName,
      ofObj?.projectInfo?.projectId,
      ofObj?.projectInfo?.endCustomer,
      ofObj?.projectInfo?.partner,
    ]);
    orderFormdata.push(
      ...[
        [''],
        ['Ship To:', 'Phone:', 'Ship Via (FedEX / UPS / DHL)', 'Phone:'],
        ['Address:', 'Fax:', 'Tracking Number (s)', 'Fax:'],
        ['', 'Email:', '', 'Email:'],
        ['', 'Contact', '', 'Contact'],
        ['', '', '', ''],
        ['Item Number', 'Description', 'Qty', 'Comments'],
      ],
    );

    orderFormdata.push(['', '"VVF Equipment Kit"', ofObj.dilutionKit, '']);

    orderFormdata.push(['', '"CE Equipment Kit"', ofObj.CEEquipmentKit, '']);

    orderFormdata.push([
      '',
      '"Sterile Survey/Flex Equipment Kit"',
      ofObj.flexKit,
      '',
    ]);

    const hasDilution = ofObj.testTypes.some((i) =>
      [
        TEST_TYPES.DILUTION,
        TEST_TYPES.LARGE_DILUTION,
        TEST_TYPES.SMALL_DILUTION,
      ].includes(i),
    );
    const hasDilOrMiniSurOrRecirc = ofObj.testTypes.some((i) =>
      [
        TEST_TYPES.DILUTION,
        TEST_TYPES.LARGE_DILUTION,
        TEST_TYPES.SMALL_DILUTION,
        TEST_TYPES.MIN_SURVEY,
        TEST_TYPES.RECIRCULATION,
      ].includes(i),
    );

    if (hasDilution) {
      orderFormdata.push([
        '20-00209-001',
        '"SAMPLER, DNA, 4 CHANNEL, Production v1"',
        '4',
        '',
      ]);
    }
    orderFormdata.push([
      '20-00103-001',
      '"Air Sampler, SKC, 3LPM 19"',
      ofObj.numberOfAirSamplers ?? '0',
      '',
    ]);

    if (hasDilOrMiniSurOrRecirc) {
      orderFormdata.push([
        '20-00213-001 ',
        '"E-SPRAYER-3, 90 PSI, 12V"',
        '4',
        '',
      ]);
    }
    if (ofObj.numberOfPelicanKits) {
      orderFormdata.push([
        '95-00131-001 ',
        '"Case, Pelican, iM2750 w/TrekPak Foam"',
        ofObj.numberOfPelicanKits,
        '',
      ]);
    }
    if (hasDilOrMiniSurOrRecirc) {
      orderFormdata.push(
        ...[
          [
            '20-00215-001',
            '"E-Sprayer Controller, Wireless Remote, Veridart"',
            '4',
            '',
          ],
          [
            '20-00208-0011',
            '"Atomizer, Microfog with Fittings (Nozzles)"',
            '5',
            '',
          ],
          [
            '85-00061-003',
            '"Cable, m12, 4-pin, male, female, right angle, 3 Meters"',
            '4',
            '',
          ],
          [
            '85-00062-001',
            '"Cable, m12, 4-pin, male, female, 1 Meter"',
            '4',
            '',
          ],
          [
            '92-00199-001',
            '"Power Supply, 12VDC, 2A, 5.5 x 2.1mm DC jack"',
            '4',
            '',
          ],
          [
            '85-00059-001',
            '"Splitter, Power Supply, 1x Female, 2x Male, 5.5 x 2.1 mm"',
            '5',
            '',
          ],
          ['', '"1m Power Supply Extension"', '4', ''],
        ],
      );
    }

    if (ofObj.numberOfPelicanKits) {
      orderFormdata.push([
        '96-00007-001',
        '"Air Compressor, Rechargeable Li-Ion, 150psi, Avid"',
        ofObj.numberOfPelicanKits,
        '',
      ]);
    }

    if (hasDilOrMiniSurOrRecirc) {
      orderFormdata.push([
        ['85-00060-020', '"Extension Cord, 20 foot, 16/2"', '4', ''],
      ]);
    }

    if (ofObj.numberOfPelicanKits) {
      orderFormdata.push([
        '',
        '"Bosch Laser Tape Measure"',
        ofObj.numberOfPelicanKits,
        '',
      ]);
    }

    if (hasDilOrMiniSurOrRecirc) {
      orderFormdata.push([['', 'Test in Progress Sign', '4', '']]);
    }

    if (ofObj.numberOfPelicanKits) {
      orderFormdata.push(
        ...[
          ['', 'Info Pack', ofObj.numberOfPelicanKits, ''],
          ['', 'Alcohol Wipes - Pack', ofObj.numberOfPelicanKits, ''],
        ],
      );
    }

    if (ofObj.totalTestCount) {
      orderFormdata.push(
        ...[
          ['', '9 x 12 Ziplock Bags', Math.ceil(ofObj.totalTestCount / 2), ''],
        ],
      );
    }

    if (ofObj.numberOfSampleCassettes) {
      orderFormdata.push([
        '25-00031-101',
        '"Sample Cassette, 3-ring, 1um."',
        ofObj.numberOfSampleCassettes,
        '',
      ]);
    }

    if (ofObj.totalTestCount) {
      orderFormdata.push([
        '95-00028-001',
        'Nitrile Gloves Large PAIR',
        Math.ceil(ofObj.totalTestCount * 2),
        '',
      ]);
    }

    if (ofObj.numberOfOPSheets) {
      orderFormdata.push([
        '55-00127-001',
        '"Origin Point Label, Printed"',
        ofObj.numberOfOPSheets,
        '',
      ]);
    }
    if (ofObj.numberOfSPSheets) {
      orderFormdata.push([
        '55-00126-001',
        '"Sample Point Label, Printed"',
        ofObj.numberOfSPSheets,
        '',
      ]);
    }
    if (ofObj.numberOfSampleLabelSheets) {
      orderFormdata.push([
        '55-00125-001',
        '"Sample Collector Label, Printed"',
        ofObj.numberOfSampleLabelSheets,
        '',
      ]);
    }

    const tagData = parseTagCount(tagsObj);

    const hasSyringes = tagData.syringes.length > 0;
    const has35Bucko = tagData.bucko35.length > 0;
    const has60Bucko = tagData.bucko60.length > 0;

    if (hasSyringes) {
      // Syringe calculations
      let innerSyringeId = 147;
      tagData.syringes.forEach((item) => {
        const arrItem = [
          `99-00${innerSyringeId}-001`,
          `"Syringe ${item.tag}, 5 mL"`,
          item.count,
        ];
        orderFormdata.push(arrItem);
        innerSyringeId++;
      });
    }

    if (has35Bucko) {
      let outerId = 499;
      let innerId = 300;
      let currentTagLetter = '';
      let currentTagNumber = '';
      tagData.bucko35.forEach((item) => {
        const { tagNumber, tagLetter } = parseTagName(item.tag);

        if (tagLetter !== currentTagLetter) {
          outerId++;
          innerId = 300;
          currentTagLetter = tagLetter;
        }
        // Increment inner ID when tag number changes
        if (tagNumber !== currentTagNumber) {
          innerId++;
          currentTagNumber = tagNumber;
        }
        orderFormdata.push([
          `25-00${outerId}-${innerId}-35`,
          `"veriDART, Tag Spray Bottle Kit ${item.tag} 35ml"`,
          item.count,
        ]);
      });
    }

    if (has60Bucko) {
      let outerId = 499;
      let innerId = 300;
      let currentTagLetter = '';
      let currentTagNumber = '';
      tagData.bucko60.forEach((item) => {
        const { tagNumber, tagLetter } = parseTagName(item.tag);
        if (tagLetter !== currentTagLetter) {
          outerId++;
          innerId = 300;
          currentTagLetter = tagLetter;
        }
        // Increment inner ID when tag number changes
        if (tagNumber !== currentTagNumber) {
          innerId++;
          currentTagNumber = tagNumber;
        }
        orderFormdata.push([
          `25-00${outerId}-${innerId}-60`,
          `"veriDART, Tag Spray Bottle Kit ${item.tag} 60ml"`,
          item.count,
        ]);
      });
    }

    orderFormdata.push([['']]);

    return orderFormdata;
  };

  const handleDownload = useCallback(async () => {
    try {
      setDownloadInProgress(true);

      const tagsResponse = await tags.getTagsCount(projectId);
      const orderFormResponse = await projects.getProjectOrderFormData(
        projectId,
      );

      if (isValidResponse(tagsResponse) && isValidResponse(orderFormResponse)) {
        const _orderForm = getDataFromResponse(orderFormResponse);
        const _tags = getDataFromResponse(tagsResponse);

        const downloadData = handleTagSolutionFormatting(
          _orderForm,
          _tags,
          projectId,
        );

        const csvContent =
          'data:text/csv;charset=utf-8,' +
          downloadData.map((e) => e.join(',')).join('\n');

        if (!csvContent) {
          throw new Error();
        }

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `${projectName}-order-form.csv`);
        document.body.appendChild(link); // Required for FF

        link.click();

        return true;
      } else {
        throw new Error();
      }
    } catch (err) {
      console.error(`Order Form Download Error: `, err);
      if (typeof onError === 'function') {
        onError(err.message);
      }
      return false;
    } finally {
      if (mounted.current) {
        setDownloadInProgress(false);
      }
    }
  }, [projectId, projectName]);

  return (
    <Stack direction="column">
      {loading && (
        <Skeleton variant="rounded" height={35} sx={{ maxWidth: '480px' }} />
      )}
      {!loading && (
        <Box sx={{ minHeight: 40 }}>
          <Stack direction="row" gap={2}>
            <DownloadButton
              disabled={downloadInProgress}
              loading={downloadInProgress}
              clickAction={handleDownload}
              sx={{ width: '140px' }}
            />
          </Stack>
        </Box>
      )}
    </Stack>
  );
}

export default React.memo(OrderForm);

/*
 * This function takes the tag count response and returns an object with three arrays
 * One for syringes and two for Bucko (35ml and 60ml)
 * Each array contains objects with the tag name and OP count
 */
const parseTagCount = (tagCountObject) => {
  const syringes = [];
  const bucko35 = [];
  const bucko60 = [];
  for (const tagName in tagCountObject) {
    const counts = tagCountObject[tagName];
    for (const sprayerName in counts) {
      const count = counts[sprayerName];
      if (sprayerName.toLowerCase() === 'bucko') {
        /* Bucko solution calculations:
         * if it's used 4, 5 or 6 times then a 60ml bottle can be used (holds 6 OP sprays)
         * if it's used 1, 2 or 3 times then a 35ml bottle can be used (holds 3 OP sprays)
         * Calculate the minimum number of 60ml bottles and 35ml bottles needed
         */
        let bottle35count = 0;
        let bottle60count = 0;
        if (count > 3) {
          bottle60count = Math.floor(count / 6);
          const remainder = count % 6;
          if (remainder > 3) {
            bottle60count = bottle60count + 1;
          } else {
            bottle35count = 1;
          }
        } else {
          bottle35count = 1;
        }
        if (bottle35count > 0) {
          bucko35.push({ tag: tagName, count: bottle35count });
        }
        if (bottle60count > 0) {
          bucko60.push({ tag: tagName, count: bottle60count });
        }
      } else {
        syringes.push({ tag: tagName, count });
      }
    }
  }
  return {
    syringes: syringes.sort((a, b) => a.tag.localeCompare(b.tag)),
    bucko35: bucko35.sort((a, b) => a.tag.localeCompare(b.tag)),
    bucko60: bucko60.sort((a, b) => a.tag.localeCompare(b.tag)),
  };
};

const parseTagName = (tagName) => {
  const tagLetter = tagName.match(/(?<=Tag-)[A-Za-z]/gi);
  const tagNumber = tagName.match(/(?<=Tag-[A-Za-z])[0-9]+/gi);
  return {
    tagLetter: tagLetter.length > 0 ? tagLetter[0] : null,
    tagNumber: tagNumber.length > 0 ? tagNumber[0] : null,
  };
};
