import {
  HEATMAP_HEALTH_CARE_LIKE_BEHAVIOR,
  HEATMAP_VERIFICATION_LIKE_BEHAVIOR,
  TEST_TYPES,
  pointByConditionValues,
} from 'Constants';
import { colorsFilterValues } from 'Constants';
import {
  addHeatmapOverviewSlideForSingleHeatmap,
  createPresentation,
} from 'Utils/presentationUtils';
import * as htmlToImage from 'html-to-image';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Snackbar,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import resultsApi from 'Api/results';
import testsApi from 'Api/tests';

import BreadcrumbsHeatmap from 'Components/Breadcrumbs/BreadcrumbsHeatmap';
import HeatMap from 'Components/HeatMap';
import ElementsControlPanel from 'Components/HeatMap/ElementsControlPanel';
import NewHeatmap from 'Components/HeatMap/NewHeatmap';
import ScalingSavingsControlsSection from 'Components/HeatMap/ScalingSavingsControlsSection';
import SpOpRearrangeDialog from 'Components/HeatMap/SpOpRearrangeDialog';
import MapInteractionCustom from 'Components/MapInteractionCustom';
import ColorsPaper from 'Components/Mix/ColorsPaper';
import SelectColors from 'Components/Select/SelectColors';
import ErrorMessage from 'Components/UI/ErrorMessage';
import Loading from 'Components/UI/Loading';

import { breadcrumbsSelector } from 'Features/Breadcrumbs/breadcrumbsSlice';
import { colorsSelector } from 'Features/Colors/colorsSlice';
import { inProgressSelector } from 'Features/Filter/inProgressSlice';
import {
  heatmapSelector,
  setFinishLoading,
  setHeatmaps,
  setLoadingFailed,
  setNotError,
  setStartLoading,
} from 'Features/Heatmap/heatmapSlice';
import { fetchHeatmaps } from 'Features/Heatmaps/heatmapsSlice';
import { fetchBuildings } from 'Features/Results/resultsSlice';
import { fetchBuildingTest, testsSelector } from 'Features/Tests/testsSlice';

import {
  getNewMapValueOnScaleChange,
  heatmapCalculateResult,
  heatmapDownloadFloorPlan,
  heatmapIntervals,
  isValidResponse,
  renderTestInfoForHeatmap,
} from 'Utils';

export default function Heatmap() {
  const ref = useRef(null);
  // Internal values
  const [shouldUseNewHeatmap, setShouldUseNewHeatmap] = useState(false);
  const [resultsData, setResultsData] = useState({});
  const [displayNumbers, setDisplayNumbers] = useState();
  const [heatmapComment, setHeatmapComment] = useState('');
  const { heatmaps, isLoading, error } = useSelector(heatmapSelector);
  const testsData = useSelector(testsSelector);
  const [date, setDate] = useState('');
  const [currentHeatmaps, setCurrentHeatmaps] = useState([]);
  const [heatmapNoFloorPlan, setHeatmapNoFloorPlan] = useState(false);
  const [showLegend, setShowLegend] = useState(true);
  const dispatch = useDispatch();
  const [mapValue, setMapValue] = useState({
    scale: 1,
    translation: { x: 0, y: 0 },
  });
  const [pointsByCondition, setPointsByCondition] = useState(
    pointByConditionValues.CONDITION_ALL,
  );
  const [significance, setSignificance] = useState(null);
  const [calculatedResult, setCalculatedResult] = useState('');
  const [projectId, setProjectId] = useState(0);
  const [projectName, setProjectName] = useState(null);
  const [isDownload, setIsDownload] = useState(false);
  const [overwriteImage, setOverwriteImage] = useState(null);
  const [heatmapName, setHeatmapName] = useState('');
  const [alert, setAlert] = useState({
    message: '',
  });
  const [intervals, setIntervals] = useState([]);
  const [disablePan, setDisablePan] = useState(false);
  const { inProgress } = useSelector(inProgressSelector);
  const { colors } = useSelector(colorsSelector);
  const userData = JSON.parse(localStorage.getItem('currentUser'));

  const { breadcrumbs } = useSelector(breadcrumbsSelector);
  const [mainError, setMainError] = useState(null);
  const [verificationLikeBehavior, setVerificationLikeBehavior] =
    useState(false);
  const [isHealthCareTest, setIsHealthCareTest] = useState(false);
  const [isReviewed, setIsReviewed] = useState(false);
  const [isSpOpRearrangeDialogOpen, setSpOpRearrangeDialogOpen] =
    useState(false);
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      comment: ' ',
      calculate_result: ' ',
    },
  });

  const [elementsViewSettings, setElementsViewSettings] = useState({
    sliderHeatmap: 0,
    sliderCropBox: 0,
    sliderSp: 35,
    showSpOpValue: true,
    minScaleHeatmap: 0.1,
    hasAlreadyForcedViewSettings: false,
  });

  const [scalingSaveParams, setScalingSaveParams] = useState({
    shouldSaveViewParams: false,
    saveForAllHeatmapsInATest: false,
  });

  // Query parameters
  const { buildingId, testId, heatmapId } = useParams();

  // Add all the functions here that the child can call.
  const mapRef = {};

  const fetchDisplayNumbers = async () => {
    const response = await testsApi.getDisplayNumbersBuilding(buildingId);
    if (isValidResponse(response)) {
      setDisplayNumbers(response.data.data);
    }
  };

  useEffect(() => {
    fetchDisplayNumbers();
  }, []);

  useEffect(() => {
    dispatch(fetchBuildings(colors, buildingId, false, inProgress));
    dispatch(fetchBuildingTest(buildingId, colors, testId, false, inProgress));
    dispatch(fetchHeatmaps(testId, colors, false, inProgress));
    setDate(testsData.currentTestDate);

    fetchHeatMap();
  }, [buildingId, testId, testsData.currentTestDate]);

  useEffect(() => {
    dispatch(fetchBuildings(colors, buildingId, false, inProgress));
    dispatch(fetchBuildingTest(buildingId, colors, testId, false, inProgress));
    dispatch(fetchHeatmaps(testId, colors, false, inProgress));
    setDate(testsData.currentTestDate);

    fetchHeatMap(true);
  }, [heatmapId]);

  const fetchHeatMap = async (updateScaleParams = false) => {
    try {
      dispatch(setStartLoading());
      const heatMaResponse = await resultsApi.getHeatMap(heatmapId);
      if (isValidResponse(heatMaResponse)) {
        const resultData = heatMaResponse.data.data;
        if (displayNumbers) {
          resultData.displayNumbers = displayNumbers;
        }
        const isVerificationLike =
          !!~HEATMAP_VERIFICATION_LIKE_BEHAVIOR.indexOf(resultData.testtype);
        const isHealthCare = !!~HEATMAP_HEALTH_CARE_LIKE_BEHAVIOR.indexOf(
          resultData.testtype,
        );
        setResultsData(resultData);
        setVerificationLikeBehavior(isVerificationLike);
        setIsHealthCareTest(isHealthCare);

        const commentValue = resultData.heatmapcomments ?? '';
        setHeatmapComment(commentValue);
        setValue('comment', commentValue);
        setIsReviewed(resultData.reviewed);
        setScalingSaveParams((prevSettings) =>
          Object.assign({}, prevSettings, {
            shouldSaveViewParams: prevSettings.saveForAllHeatmapsInATest
              ? false
              : !!resultData.heatmapmetadata.viewParams,
            saveForAllHeatmapsInATest: prevSettings.saveForAllHeatmapsInATest
              ? true
              : !resultData.heatmapmetadata.viewParams,
          }),
        );
        if (!resultData.heatmapmetadata.viewParams && updateScaleParams) {
          setMapValue({
            scale: 1,
            translation: { x: 0, y: 0 },
          });
        }
        if (
          resultData.heatmapmetadata.viewParams &&
          (!elementsViewSettings.hasAlreadyForcedViewSettings ||
            updateScaleParams)
        ) {
          const { scale, translation, showPointsLabel, spSize } =
            resultData.heatmapmetadata.viewParams;
          setMapValue({
            scale,
            translation,
          });
          setElementsViewSettings((prevSettings) =>
            Object.assign({}, prevSettings, {
              sliderHeatmap: scale,
              showSpOpValue: showPointsLabel,
              sliderSp: spSize,
              hasAlreadyForcedViewSettings: true,
            }),
          );
        }
        setProjectId(resultData.projectid);
        setProjectName(resultData.projectname);
        setHeatmapName(resultData.heatmapname);

        const heatmapList = resultData.heatmaps;

        if (isVerificationLike && !resultData.significance) {
          const resultsSP =
            Array.isArray(heatmapList) && heatmapList[0] && heatmapList[0].sp;

          if (Array.isArray(resultsSP)) {
            const [testValid, testPassed] = resultsSP.reduce(
              (res, sp) => {
                const pass = !!(sp.result && sp.result.pass);
                const isValid = !!(sp.result && sp.result.isValid);
                res[0] = res[0] && isValid;
                res[1] = res[1] && pass;
                return res;
              },
              [true, true],
            );
            if (!testValid) {
              resultData.significance = colorsFilterValues.MEDIUM;
            } else {
              resultData.significance = testPassed
                ? colorsFilterValues.LOW
                : colorsFilterValues.HIGH;
            }
          }
        }
        setSignificance(resultData.significance ?? null);
        setIntervals(heatmapIntervals(resultData));

        let hasFloorPlan = false;

        for (let item of heatmapList) {
          if (!item.floorplan) continue;

          hasFloorPlan = true;
          if (
            !shouldUseNewHeatmap &&
            resultData.testtype !== TEST_TYPES.DILUTION &&
            item.fpheatmapversion
          ) {
            setShouldUseNewHeatmap(true);
          }
          item.url = await heatmapDownloadFloorPlan(
            item.floorplan,
            resultData.projectid,
          );

          if (resultData.testtype === TEST_TYPES.DILUTION) {
            const dimensions = {
              left: 999999999,
              top: 999999999,
              right: 0,
              bottom: 0,
            };
            const { opcoordx, opcoordy } = item.op[0];
            item.center = {
              x: parseFloat(opcoordx),
              y: parseFloat(opcoordy),
            };
            item.sp.forEach((value) => {
              if (value.spcoordx <= dimensions.left) {
                dimensions.left = parseFloat(value.spcoordx);
              }
              if (value.spcoordx >= dimensions.right) {
                dimensions.right = parseFloat(value.spcoordx);
              }
              if (value.spcoordy <= dimensions.top) {
                dimensions.top = parseFloat(value.spcoordy);
              }
              if (value.spcoordy >= dimensions.bottom) {
                dimensions.bottom = parseFloat(value.spcoordy);
              }
            });
            item.dimensions = dimensions;
          }
        }

        if (resultData.testtype === TEST_TYPES.VERIFICATION && !hasFloorPlan) {
          setHeatmapNoFloorPlan(true);
        }

        setElementsViewSettings((prevSettings) =>
          Object.assign({}, prevSettings, {
            sliderCropBox: parseFloat(resultData.scale),
          }),
        );

        dispatch(setHeatmaps(heatmapList));
        setCurrentHeatmaps(heatmapList);
        const calculatedResultValue = heatmapCalculateResult(resultData);
        setCalculatedResult(calculatedResultValue);
        if (resultData.testtype === TEST_TYPES.DILUTION) {
          setValue('calculate_result', resultData.calculatedresult || '');
        } else {
          setValue('calculate_result', calculatedResultValue);
        }
      } else {
        dispatch(setLoadingFailed(heatMaResponse.data?.error?.message));
      }
      dispatch(setFinishLoading());
    } catch (e) {
      dispatch(setLoadingFailed(e.message));
    }
  };

  /**
   * What to do after image loaded
   * @param w
   * @param h
   */
  const handleImageLoaded = (w, h) => {
    if (w === 1 || h === 1) {
      return;
    }
    let newScale;
    let divHeight = ref.current.clientHeight;
    let divWidth = ref.current.clientWidth;

    // Detect ration between image and div
    let ratioH = h / divHeight;
    let ratioW = w / divWidth;
    // Detect by which ratio need to scale an image
    newScale = ratioH > ratioW ? 1 / ratioH - 0.01 : 1 / ratioW - 0.015;

    newScale = newScale / resultsData.heatmaps.length - 0.002;

    setElementsViewSettings((prevSettings) =>
      Object.assign({}, prevSettings, {
        minScaleHeatmap: newScale,
      }),
    );

    // Overwrite mapValue
    setMapValue((prevMapValue) =>
      Object.assign({}, prevMapValue, {
        scale: prevMapValue.scale === 1 ? newScale : prevMapValue.scale,
      }),
    );
  };

  /**
   * Download rendered image with floor plan and all results on it
   */
  const handleDownloadFloorPlanWithResults = async (cropToViewBox) => {
    if (cropToViewBox) {
      await captureAndSetMapBorderContent();
    } else {
      setOverwriteImage(null);
    }
    setIsDownload(true);
  };

  const captureAndSetMapBorderContent = async () => {
    setShowLegend(false);
    const mapBorder = document.getElementById('mapBorder');
    const dataUrl = await htmlToImage.toPng(mapBorder, {
      backgroundColor: '#fff',
    });
    setShowLegend(true);
    setOverwriteImage(dataUrl);
  };

  const downloadSlide = async () => {
    setShowLegend(false);

    const pres = createPresentation();

    const heatmap = document.getElementById('mapBorder');
    const img = await htmlToImage.toPng(heatmap, { backgroundColor: '#fff' });

    const heatmapData = { ...resultsData };
    heatmapData.surveyDate = date;
    heatmapData.projectId = projectId;
    heatmapData.heatmapComment = heatmapComment;

    addHeatmapOverviewSlideForSingleHeatmap(pres, heatmapData, {
      data: img,
      size: {
        width: heatmap.clientWidth,
        height: heatmap.clientHeight,
      },
    });

    let fileName = `PID_${projectId}_${heatmapData.testname}_${heatmapData.testid}`;
    const scenarioNumber = parseInt(heatmapData.scenario?.scenarioindex);
    if (scenarioNumber) {
      fileName += `_${scenarioNumber}`;
    }
    fileName += `_${heatmapName}.pptx`;

    pres.writeFile({ fileName });
    setShowLegend(true);
  };

  /**
   * Function for cropping image (with removing header)
   *
   * @param base64
   * @returns {Promise<unknown>}
   */
  const cropImage = (base64) => {
    // eslint-disable-next-line no-undef
    return new Promise((resolve) => {
      let img = new Image();
      img.src = base64;
      img.onload = () => {
        const MAX_WIDTH = 500;
        const MAX_HEIGHT = 300;
        const SAFETRACES_LOGO_HEIGHT = 700;
        const MAP_INFO_WIDTH = 130;

        let width = img.width - MAP_INFO_WIDTH;
        let height = img.height - SAFETRACES_LOGO_HEIGHT;

        // Change the resizing logic
        if (height > MAX_HEIGHT) {
          width = width * (MAX_HEIGHT / height);
          height = MAX_HEIGHT;
        } else if (width > MAX_WIDTH) {
          height = height * (MAX_WIDTH / width);
          width = MAX_WIDTH;
        }

        // Create dummy canvas element
        let canvas = document.createElement('canvas');
        canvas.width = MAX_WIDTH;
        canvas.height = MAX_HEIGHT;

        // In context crop and resize result
        let ctx = canvas.getContext('2d');
        ctx.drawImage(
          img,
          0,
          SAFETRACES_LOGO_HEIGHT,
          img.width - MAP_INFO_WIDTH,
          img.height - SAFETRACES_LOGO_HEIGHT,
          0,
          0,
          width,
          height,
        );
        resolve(canvas.toDataURL());
      };
    });
  };

  const onSubmit = async (dataForm) => {
    dispatch(setStartLoading());
    const commentValue = dataForm.comment.trim();
    const calculateResultValue = dataForm.calculate_result.trim();
    setHeatmapComment(commentValue);
    setCalculatedResult(calculateResultValue);

    generateHeatmap((thumbnail) => {
      const viewParams = {};
      const shouldUpdateViewParams =
        scalingSaveParams.saveForAllHeatmapsInATest ||
        scalingSaveParams.shouldSaveViewParams;
      if (shouldUpdateViewParams) {
        const { scale, translation } = mapValue;
        const { showSpOpValue, sliderSp } = elementsViewSettings;
        const containerElement = document.getElementById('mapBorder');
        const { width: borderWidth, height: borderHeight } =
          containerElement.getBoundingClientRect();

        viewParams.scale = scale;
        viewParams.translation = translation;
        viewParams.showPointsLabel = showSpOpValue;
        viewParams.spSize = sliderSp;
        viewParams.containerBorderWidth = borderWidth;
        viewParams.containerBorderHeight = borderHeight;
      }

      const data = {
        heatmapthumbnail: thumbnail,
        heatmapcomments: commentValue,
        calculatedresult: calculateResultValue,
        significance: significance,
        reviewed: isReviewed,
        scale:
          resultsData.testtype === TEST_TYPES.DILUTION
            ? elementsViewSettings.sliderCropBox
            : 0,
        saveViewParams:
          scalingSaveParams.shouldSaveViewParams ||
          scalingSaveParams.saveForAllHeatmapsInATest,
        viewParams,
        saveForAllHeatmapsInATest: scalingSaveParams.saveForAllHeatmapsInATest,
      };
      resultsApi
        .updateHeatmap(resultsData.heatmapid, data)
        .then(() => {
          dispatch(fetchHeatmaps(testId, colors, true, inProgress));
          fetchHeatMap();
          dispatch(setFinishLoading());
          setAlert({
            message: 'Heatmap successfully updated',
          });
        })
        .catch(() => {
          dispatch(
            setLoadingFailed('An error occurred while updating the heatmap'),
          );
        });
    });
  };

  // const handleResetScaling = async (resetType) => {
  //   dispatch(setStartLoading());
  //   const data = {
  //     saveViewParams: true,
  //     viewParams: null,
  //     saveForAllHeatmapsInATest: resetType === 'all',
  //   };
  //   resultsApi
  //     .updateHeatmap(resultsData.heatmapid, data)
  //     .then(() => {
  //       dispatch(fetchHeatmaps(testId, colors, true, inProgress));
  //       fetchHeatMap();
  //       dispatch(setFinishLoading());
  //       setAlert({
  //         message:
  //           resetType === 'all'
  //             ? 'Heatmaps in the test successfully updated'
  //             : 'Heatmap successfully updated',
  //       });
  //     })
  //     .catch(() => {
  //       dispatch(
  //         setLoadingFailed(
  //           resetType === 'all'
  //             ? 'An error occurred while updating heatmaps in the test'
  //             : 'An error occurred while updating the heatmap',
  //         ),
  //       );
  //     });
  // };

  /**
   * Crop image, then save thumbnail to server
   */
  const generateHeatmap = (callback) => {
    if (heatmapNoFloorPlan) {
      callback('');
      return;
    }
    // Render an image from HTML code in HeatMap component
    htmlToImage
      .toPng(document.getElementById('thisHeatmap'), {
        backgroundColor: '#fff',
      })
      .then(function (dataUrl) {
        // Reset to default
        let currentMapValue = mapValue;
        setMapValue({
          scale: 1,
          translation: currentMapValue.translation,
        });
        // Execute crop
        cropImage(dataUrl)
          .then((result) => {
            callback(result);
          })
          .finally(() => {
            // Restore from default
            setMapValue(currentMapValue);
          });
      });
  };

  const handlePointByConditionChange = (event, newCondition) => {
    setPointsByCondition(newCondition);
    const conditionName = event.target.value;
    const tmpHeatmaps = [...heatmaps];
    let newList = [];
    for (let item of tmpHeatmaps) {
      let newItem = Object.assign({}, item);
      if (newItem.sp) {
        let newSpList;
        if (conditionName === pointByConditionValues.CONDITION_LESS) {
          newSpList = newItem.sp.filter((item) => item.reduction < 3);
        } else if (conditionName === pointByConditionValues.CONDITION_GREATER) {
          newSpList = newItem.sp.filter((item) => item.reduction >= 3);
        } else {
          newSpList = newItem.sp;
        }
        newItem.sp = newSpList;
      }
      newList.push(newItem);
    }
    setCurrentHeatmaps(newList);
  };

  const handleChangeColors = (event) => {
    setSignificance(event.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({ message: '', type: alert.type ?? 'success' });
  };

  const handleCloseErrorAlert = () => {
    setMainError(null);
  };

  const handleOutHeatmap = () => {
    setDisablePan(true);
  };

  const handleOverToHeatmap = () => {
    setDisablePan(false);
  };

  const handleOnChangeMapInteraction = (value) => {
    setMapValue(value);

    if (elementsViewSettings.sliderHeatmap !== value.scale) {
      setElementsViewSettings((prevSettings) =>
        Object.assign({}, prevSettings, {
          sliderHeatmap: value.scale,
        }),
      );
    }
  };

  useEffect(() => {
    if (!elementsViewSettings.sliderHeatmap) return;

    if (ref.current) {
      const { x, y } = mapValue.translation;
      const newMapValue =
        getNewMapValueOnScaleChange(
          { x, y },
          elementsViewSettings.sliderHeatmap,
          mapValue.scale,
          ref,
        ) || mapValue;
      setMapValue(newMapValue);
    }
  }, [elementsViewSettings.sliderHeatmap]);

  let heightMapBorder = '80vh';
  let widthMapBorder = '60vw';
  if (isMobile) {
    heightMapBorder = '30vh';
    widthMapBorder = '80vw';
  }

  const oldHeatmap = () => {
    return (
      <div style={{ position: 'relative' }}>
        <div id={'thisHeatmap'}>
          <div style={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'nowrap',
              }}
            >
              {!!overwriteImage && (
                <HeatMap
                  activeFloorPlanUrl={currentHeatmaps[0].url}
                  onImageLoaded={handleImageLoaded}
                  resultsData={resultsData}
                  heatMap={currentHeatmaps[0]}
                  mapValue={mapValue}
                  isDownload={isDownload}
                  interval={intervals[0]}
                  showLast={false}
                  showFirst={true}
                  sliderSp={elementsViewSettings.sliderSp}
                  comment={heatmapComment}
                  calculatedResult={calculatedResult}
                  significance={significance}
                  breadcrumbs={breadcrumbs}
                  sliderCropBox={elementsViewSettings.sliderCropBox}
                  showSpOpValue={elementsViewSettings.showSpOpValue}
                  overwriteImage={overwriteImage}
                  showLegend={showLegend}
                />
              )}
              {!!currentHeatmaps &&
                !overwriteImage &&
                currentHeatmaps.map((item, index) => (
                  <Box key={index}>
                    <HeatMap
                      activeFloorPlanUrl={item.url}
                      onImageLoaded={handleImageLoaded}
                      resultsData={resultsData}
                      heatMap={item}
                      mapValue={mapValue}
                      isDownload={isDownload}
                      interval={intervals[index]}
                      showLast={currentHeatmaps.length - 1 === index}
                      showFirst={index === 0}
                      sliderSp={elementsViewSettings.sliderSp}
                      comment={heatmapComment}
                      calculatedResult={calculatedResult}
                      significance={significance}
                      breadcrumbs={breadcrumbs}
                      sliderCropBox={elementsViewSettings.sliderCropBox}
                      showSpOpValue={elementsViewSettings.showSpOpValue}
                      overwriteImage={overwriteImage}
                      showLegend={showLegend}
                    />
                  </Box>
                ))}
            </Box>
          </div>
        </div>
      </div>
    );
  };

  const newHeatmap = () => {
    if (
      !currentHeatmaps ||
      !Array.isArray(currentHeatmaps) ||
      !currentHeatmaps.length
    )
      return;

    return (
      <div style={{ position: 'relative' }}>
        <div id={'thisHeatmap'} className="newHeatmap">
          <div style={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'nowrap',
              }}
            >
              <NewHeatmap
                activeFloorPlanUrl={currentHeatmaps[0].url}
                onImageLoaded={handleImageLoaded}
                resultsData={resultsData}
                heatMap={currentHeatmaps[0]}
                scale={mapValue.scale}
                isDownload={isDownload}
                showLegend={showLegend}
                interval={intervals[0]}
                sliderSp={elementsViewSettings.sliderSp}
                comment={heatmapComment}
                calculatedResult={calculatedResult}
                significance={significance}
                showSpOpValue={elementsViewSettings.showSpOpValue}
                overwriteImage={overwriteImage}
              />
            </Box>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {!!mainError ||
        (!!error && (
          <ErrorMessage
            message={mainError || error}
            handleCloseErrorAlert={() => {
              handleCloseErrorAlert();
              dispatch(setNotError());
            }}
          />
        ))}
      {!!isLoading && <Loading />}
      <div style={{ width: '100%' }}>
        <BreadcrumbsHeatmap
          testId={testId}
          buildingId={buildingId}
          heatmapId={heatmapId}
          heatmapName={heatmapName}
          date={date}
          projectId={projectId}
          projectName={projectName}
          resultsData={resultsData}
        />
        <Snackbar
          open={alert.message !== ''}
          autoHideDuration={4500}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert
            severity={alert.type ?? 'success'}
            onClose={handleClose}
            variant="filled"
          >
            {alert.message}
          </Alert>
        </Snackbar>
        <Grid container spacing={heatmapNoFloorPlan ? 1 : 2}>
          {!heatmapNoFloorPlan && (
            <Grid item xs={12} md={12} lg={8}>
              <Box>
                <Box
                  ref={ref}
                  id={'mapBorder'}
                  style={{
                    height: heightMapBorder,
                    width: widthMapBorder,
                    border: '1px solid #444',
                    overflow: 'hidden',
                    resize: 'both',
                  }}
                >
                  <Box
                    onMouseOver={handleOverToHeatmap}
                    onMouseOut={handleOutHeatmap}
                  >
                    <MapInteractionCustom
                      mapRef={mapRef}
                      value={mapValue}
                      onChange={(value) => handleOnChangeMapInteraction(value)}
                      minScale={elementsViewSettings.minScaleHeatmap}
                      maxScale={2}
                      disablePan={disablePan}
                    >
                      {shouldUseNewHeatmap ? newHeatmap() : oldHeatmap()}
                    </MapInteractionCustom>
                  </Box>
                </Box>
              </Box>
            </Grid>
          )}
          <Grid item xs={12} md={12} lg={heatmapNoFloorPlan ? 7 : 4}>
            <Box sx={{ mb: 2 }}>
              <Paper elevation={1}>
                <Box pl={2} pr={2} pb={1} mb={2}>
                  {renderTestInfoForHeatmap(resultsData)}
                </Box>
              </Paper>
            </Box>
            {!heatmapNoFloorPlan && (
              <ElementsControlPanel
                showDilutionSlider={
                  resultsData.testtype === TEST_TYPES.DILUTION
                }
                settings={elementsViewSettings}
                updateSettings={setElementsViewSettings}
                disabled={isLoading || isDownload}
              />
            )}
            <Box>
              {!heatmapNoFloorPlan ? (
                <React.Fragment>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: '8px',
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleDownloadFloorPlanWithResults(false)}
                      sx={{
                        flexGrow: 1,
                        display: 'none',
                      }}
                    >
                      Download PNG
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleDownloadFloorPlanWithResults(true)}
                      sx={{
                        flexGrow: 1,
                        display: 'none',
                      }}
                    >
                      Download zoomed PNG
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: '8px',
                      mt: 1,
                      mb: 4,
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={downloadSlide}
                      disabled={isLoading || isDownload}
                    >
                      Download presentation slide
                    </Button>
                    <Button
                      variant="outlined"
                      disabled={isLoading || isDownload}
                      onClick={() => setSpOpRearrangeDialogOpen(true)}
                    >
                      Rearrange OP & SP
                    </Button>

                    <SpOpRearrangeDialog
                      open={isSpOpRearrangeDialogOpen}
                      handleClose={() => {
                        fetchHeatMap();
                        setSpOpRearrangeDialogOpen(false);
                      }}
                      testId={testId}
                    />
                  </Box>
                </React.Fragment>
              ) : null}
              {verificationLikeBehavior && !!currentHeatmaps && (
                <Box>
                  {currentHeatmaps[0] && currentHeatmaps[0].buildingdata && (
                    <Paper sx={{ mb: 2, p: 2 }}>
                      <Typography
                        variant="overline"
                        display="block"
                        gutterBottom
                      >
                        {resultsData.testtype === TEST_TYPES.VERIFICATION
                          ? 'Building data'
                          : 'Test area'}
                      </Typography>
                      <Typography>
                        Area sq. ft: {currentHeatmaps[0].buildingdata.sq_ft}
                      </Typography>
                      <Typography>
                        Height ft:{' '}
                        {currentHeatmaps[0].buildingdata.celling_height}
                      </Typography>
                    </Paper>
                  )}
                </Box>
              )}
              <Box
                sx={{
                  display: isHealthCareTest ? 'none' : 'flex',
                  justifyContent: !verificationLikeBehavior
                    ? 'space-around'
                    : 'flex-start',
                  mb: 4,
                }}
              >
                {(userData.accessLevel === 'Admin' ||
                  userData.accessLevel === 'Editor') &&
                (userData.roleName === 'Safetracer' ||
                  userData.roleName === 'Partner') ? (
                  <SelectColors
                    value={significance}
                    handleChange={(e) => handleChangeColors(e)}
                    disabled={
                      verificationLikeBehavior || isLoading || isDownload
                    }
                    type={
                      resultsData.testtype
                        ? verificationLikeBehavior
                          ? 'result'
                          : 'significance'
                        : ''
                    }
                  />
                ) : (
                  <ColorsPaper color={significance} />
                )}
                {!verificationLikeBehavior && !isHealthCareTest && (
                  <ToggleButtonGroup
                    color="primary"
                    value={pointsByCondition}
                    exclusive
                    onChange={handlePointByConditionChange}
                    sx={{ ml: 3 }}
                  >
                    <ToggleButton
                      value={pointByConditionValues.CONDITION_LESS}
                      sx={{ py: 1 }}
                    >{`< 3 Log`}</ToggleButton>
                    <ToggleButton
                      sx={{ py: 1 }}
                      value={pointByConditionValues.CONDITION_ALL}
                    >
                      All
                    </ToggleButton>
                    <ToggleButton
                      sx={{ py: 1 }}
                      value={pointByConditionValues.CONDITION_GREATER}
                    >{`≥ 3 Log`}</ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Box>
              {(userData.accessLevel === 'Admin' ||
                userData.accessLevel === 'Editor') &&
              (userData.roleName === 'Safetracer' ||
                userData.roleName === 'Partner') ? (
                <Box>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Box
                      sx={{
                        mb: 2,
                        display:
                          !resultsData.testtype ||
                          verificationLikeBehavior ||
                          isHealthCareTest
                            ? 'none'
                            : '',
                      }}
                    >
                      <TextField
                        minRows={3}
                        maxRows={7}
                        fullWidth
                        multiline
                        label="Calculated Result"
                        placeholder="Calculated Result"
                        name="calculate_result"
                        {...register('calculate_result')}
                      />
                    </Box>
                    <Box sx={{ mb: !heatmapNoFloorPlan ? 1 : 2 }}>
                      <TextField
                        minRows={3}
                        maxRows={7}
                        inputProps={{
                          maxLength: 5000,
                        }}
                        fullWidth
                        multiline
                        label="Comments"
                        placeholder="Comments"
                        name="comment"
                        {...register('comment')}
                      />
                    </Box>
                    {!heatmapNoFloorPlan ? (
                      <ScalingSavingsControlsSection
                        updateSettings={setScalingSaveParams}
                        settings={scalingSaveParams}
                        disabled={isLoading || isDownload}
                        // canReset={!!resultsData?.heatmapmetadata?.viewParams}
                        // handleReset={handleResetScaling}
                      />
                    ) : null}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 4,
                      }}
                    >
                      <Stack>
                        <Button
                          variant="outlined"
                          sx={{ width: '100%', margin: 'auto' }}
                        >
                          Cancel
                        </Button>
                      </Stack>
                      <Stack direction="row">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isReviewed}
                              onChange={(e) => setIsReviewed(e.target.checked)}
                              disabled={
                                (!verificationLikeBehavior &&
                                  !isHealthCareTest &&
                                  !significance) ||
                                isLoading ||
                                isDownload
                              }
                            />
                          }
                          label="Reviewed"
                        />
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{ width: '100%', pl: 6, pr: 6, margin: 'auto' }}
                        >
                          Save
                        </Button>
                      </Stack>
                    </Box>
                  </form>
                </Box>
              ) : (
                <Box sx={{ mb: 2 }}>
                  {!verificationLikeBehavior && (
                    <Typography>{calculatedResult}</Typography>
                  )}
                  <Typography>{heatmapComment}</Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
