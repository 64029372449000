import { DASHBOARD_VIEW_TYPES } from 'Constants';
import React from 'react';
import { generatePath } from 'react-router';

import AppBreadcrumbs from './AppBreadcrumbs';

import { useDashboardContext } from 'Components/Dashboard/context';

import { ROUTE_DASHBOARD } from 'Config/routes';

function BreadcrumbsDashboard() {
  const { getEntityPath, getEntityLabel, type, portfolioId, buildingId } =
    useDashboardContext();

  const currentTypeIndex = DASHBOARD_VIEW_TYPES.indexOf(type);
  if (!currentTypeIndex) return;

  const breadcrumbs = DASHBOARD_VIEW_TYPES.slice(0, currentTypeIndex + 1).map(
    (typeName, i) => {
      if (typeName === 'all') {
        return {
          text: 'Dashboard',
          link: generatePath(ROUTE_DASHBOARD),
        };
      }

      const entityId = typeName === 'portfolio' ? portfolioId : buildingId;
      const text = getEntityLabel(typeName, entityId);
      const link =
        i !== currentTypeIndex ? getEntityPath(typeName, entityId) : null;

      return { text, link };
    },
  );

  return (
    <AppBreadcrumbs
      links={breadcrumbs}
      containerStyle={{
        display: 'flex',
        marginBottom: 'auto',
        marginTop: 'auto',
      }}
    />
  );
}

export default BreadcrumbsDashboard;
