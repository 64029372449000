import React from 'react';

import SearchOffIcon from '@mui/icons-material/SearchOff';
import { Box, Paper, Typography } from '@mui/material';

export default function NoDataDisplay({ title = 'No Data Found' }) {
  return (
    <Box
      playwrighttestid="no-data-display-block"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Paper
        elevation={0}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '40px',
        }}
      >
        <SearchOffIcon style={{ fontSize: 80, color: '#ccc' }} />
        <Typography variant="h6" align="center">
          {title}
        </Typography>
      </Paper>
    </Box>
  );
}
