import React from 'react';
import { Navigate } from 'react-router-dom';

import Layout from '../../Layout/Layout';

import { useAuthState } from 'Context';

const AuthRoutes = () => {
  const userDetails = useAuthState();

  if (userDetails.access_token) {
    return <Layout />;
  }

  return <Navigate to="/login" replace />;
};

export default AuthRoutes;
