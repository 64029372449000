import React from 'react';

import { colorsFilterValues } from '../../Constants';

export default function ColorsPaper({ color }) {
  let colorView;
  switch (color) {
    case colorsFilterValues.HIGH:
      colorView = 'red';
      break;
    case colorsFilterValues.LOW:
      colorView = 'green';
      break;
    case colorsFilterValues.MEDIUM:
      colorView = 'yellow';
      break;
    default:
      colorView = 'grey';
      break;
  }

  return (
    <>
      <div
        style={{
          border: 1,
          borderStyle: 'solid',
          borderColor: '#9b9b9b',
          borderRadius: 3,
          width: 40,
          height: 40,
        }}
      >
        <div
          style={{
            borderRadius: 3,
            width: 30,
            height: 30,
            marginTop: 4,
            marginLeft: 4,
            backgroundColor: colorView,
          }}
        />
      </div>
    </>
  );
}
