import React from 'react';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Box, Stack, Typography } from '@mui/material';
import { Divider, Tooltip } from '@mui/material';

const TestItem = ({
  testName,
  dividerVisible = true,
  dragHandleProps,
  disableActions,
}) => {
  const prefixIconStyle = {
    height: '24px',
    width: '24px',
    color: 'rgba(0, 0, 0, 0.57)',
    position: 'relative',
    display: 'inline',
    top: '2px',
    left: '4px',
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ pt: 0.5, pb: 1, position: 'relative' }}
        >
          <Tooltip title={''} placement="top">
            <div {...dragHandleProps}>
              {' '}
              <DragHandleIcon
                style={{
                  ...prefixIconStyle,
                  cursor: disableActions ? '' : 'grab',
                  color: disableActions ? '#D3D3D3' : '',
                }}
              />
            </div>
          </Tooltip>
          <Typography sx={{ ml: 2, py: 1 }}>{testName}</Typography>
        </Stack>
        <Box
          style={{
            paddingLeft: '32px',
            visibility: !dividerVisible ? 'hidden' : '',
          }}
        >
          <Divider />
        </Box>
      </div>
    </>
  );
};

export default TestItem;
