import axios from 'axios';

import config from 'Config/config';

const BASEURL = 'https://wv7baab9lg.execute-api.eu-west-1.amazonaws.com/dev';

const originpoints = {
  getOriginPoint: async (segmentId) => {
    return await axios.get(BASEURL + '/' + segmentId, {});
  },
  getAllOriginPointsForTest: async (testId, source = null) => {
    const options = {};
    if (source) {
      options.cancelToken = source.token;
    }
    return await axios.get(`${BASEURL}?testId=${testId}`, options);
  },
  getAllOriginPointsForProject: async (projectId, includeAllTags) => {
    return await axios.get(
      `${BASEURL}?projectId=${projectId}&includeAllTags=${Number(
        includeAllTags,
      )}`,
    );
  },
  createOriginPoints: async (data) => {
    return await axios.post(BASEURL + '/create', data);
  },
  setTagLotNumber: async (data) => {
    return await axios.put(BASEURL + '/taglotnumber', data);
  },
  setMultipleTagLotNumber: async (data) => {
    return await axios.put(BASEURL + '/multipletaglotnumbers', data);
  },
  getLabelsForProject: async (projectId, source) => {
    const options = {};
    if (source) {
      options.cancelToken = source.token;
    }

    return await axios.get(`${BASEURL}/labels?projectId=${projectId}`, options);
  },
  createOriginpoints: async (data) => {
    return await axios.post(BASEURL + '/create', data);
  },
  setOpTag: async (data) => {
    return await axios.put(BASEURL + '/tag', data);
  },
  updateOriginPoint: async (opData) => {
    return await axios.put(BASEURL, opData);
  },
};

export default originpoints;
