import { createSlice } from '@reduxjs/toolkit';

import resultsApi from 'Api/results';
import testsApi from 'Api/tests';

function startLoading(state) {
  state.isLoading = true;
}

function finishLoading(state) {
  state.isLoading = false;
}

function loadingFailed(state, action) {
  state.isLoading = false;
  state.error = action.payload;
}

export const slice = createSlice({
  name: 'heatmaps',
  initialState: {
    heatmaps: [],
    heatmapDisplayNumbers: [],
    currentTestId: 0,
    isLoading: false,
    error: null,
  },
  reducers: {
    setStartLoading: startLoading,
    setFinishLoading: finishLoading,
    setLoadingFailed: loadingFailed,
    setCurrentTestId: (state, action) => {
      state.currentTestId = action.payload;
    },
    setHeatmapsSuccess: (state, action) => {
      state.isLoading = true;
      state.heatmaps = action.payload.heatmaps;
      state.heatmapDisplayNumbers = action.payload.heatmapDisplayNumbers;
    },
    setNotError: (state) => {
      state.error = null;
    },
  },
});

export const fetchHeatmaps =
  (testId, colors, forceUpdate = false, inProgress = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setStartLoading());
      const { heatmaps, currentTestId } = getState().heatmaps;
      if (
        !heatmaps ||
        !heatmaps.length ||
        currentTestId !== testId ||
        forceUpdate
      ) {
        dispatch(setCurrentTestId(testId));
        const heatmapResponse = await resultsApi.getHeatMapListForTest(
          testId,
          colors,
          inProgress,
        );
        const _heatmaps = heatmapResponse.data.data || [];
        const heatmapsIds = [];
        const uniqueHeatmaps = [];
        for (let heatmap of _heatmaps) {
          const { heatmapid } = heatmap;
          if (!heatmapsIds.includes(heatmapid)) {
            heatmapsIds.push(heatmapid);
            uniqueHeatmaps.push(heatmap);
          }
        }
        const displayNumbersResponse = await testsApi.getHeatmapDisplayNumbers(
          testId,
        );
        dispatch(
          setHeatmapsSuccess({
            heatmaps: uniqueHeatmaps,
            heatmapDisplayNumbers: displayNumbersResponse.data.data,
          }),
        );
      }
      dispatch(setFinishLoading());
    } catch (err) {
      dispatch(setLoadingFailed(err.toString()));
    }
  };

export const {
  setStartLoading,
  setFinishLoading,
  setLoadingFailed,
  setHeatmapsSuccess,
  setCurrentTestId,
  setNotError,
} = slice.actions;

export default slice.reducer;

export const heatmapsSelector = (state) => state.heatmaps;
