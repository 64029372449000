import React, { createContext, useContext, useState } from 'react';

import { ACCESS_LEVEL_ADMIN, ACCESS_LEVEL_EDITOR } from 'Config/roles';

import { getUserData } from 'Utils';

const TestContext = createContext({});

export const TestContextProvider = ({ children, value }) => {
  const { accessLevel } = getUserData();
  const editPermission =
    !!~[ACCESS_LEVEL_ADMIN].indexOf(accessLevel) ||
    !!~[ACCESS_LEVEL_EDITOR].indexOf(accessLevel);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerMeta, setDrawerMeta] = useState({
    type: 'sample_type',
    testId: null,
    id: null,
    value: null,
    data: null,
  });

  const openEditModal = ({ type, id, testId, value, data }) => {
    setDrawerMeta({ type, id, testId, value, data });
    setIsDrawerOpen(true);
  };

  return (
    <TestContext.Provider
      value={{
        openEditModal,
        isDrawerOpen,
        setIsDrawerOpen,
        drawerMeta,
        reFetchProject: value.reFetchProject,
        projectId: value.projectId,
        disableEdits: value.disableEdits,
        forceResrictAllEdits: !editPermission,
      }}
    >
      {children}
    </TestContext.Provider>
  );
};

export const useTestContext = () => {
  const {
    projectId,
    isDrawerOpen,
    setIsDrawerOpen,
    openEditModal,
    drawerMeta,
    reFetchProject,
    forceResrictAllEdits,
    disableEdits,
  } = useContext(TestContext);
  return {
    projectId,
    isDrawerOpen,
    setIsDrawerOpen,
    openEditModal,
    drawerMeta,
    reFetchProject,
    forceResrictAllEdits,
    disableEdits,
  };
};
