import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Stack } from '@mui/material';

import buildingsApi from 'Api/buildings';

import Title from 'Components/Mix/Title';
import Sites from 'Components/Sites';
import FloorplanUploadModal from 'Components/Sites/FloorplanUploadModal';
import ErrorMessage from 'Components/UI/ErrorMessage';

import { useAuthState, useIsMounted } from 'Context';

import { setPageTitle } from 'Features/Main/mainSlice';

import { getDataFromResponse, isValidResponse } from 'Utils';

const SitesPage = () => {
  const authState = useAuthState();
  const [buildingsData, setBuildingsData] = useState([]);
  const [buildingsInitialLoaded, setBuildingsInitialLoaded] = useState(false);
  const [sitessInitialLoaded, setSitessInitialLoaded] = useState(false);

  const isInitialLoading = !buildingsInitialLoaded || !sitessInitialLoaded;

  const [isFetching, setIsFetching] = useState(false);
  const [sitesData, setSitesData] = useState([]);
  const [mainError, setMainError] = useState('');
  const [floorplanUploadBuildingData, setFloorplanUploadBuildingData] =
    useState(null);
  const mounted = useIsMounted();

  const globalDispatch = useDispatch();
  globalDispatch(setPageTitle('Sites & Buildings'));

  useEffect(() => {
    fetchBuildings();
    fetchSites();
  }, [authState?.viewCompanyId]);

  const fetchBuildings = useCallback(async () => {
    setIsFetching(true);
    try {
      const response = await buildingsApi.getBuildingsByCompanyId(
        authState?.viewCompanyId,
      );
      if (mounted.current) {
        if (isValidResponse(response)) {
          const data = getDataFromResponse(response);
          setBuildingsData(data);
        } else {
          setBuildingsData([]);
        }
        setBuildingsInitialLoaded(true);
      }
    } catch (e) {
      if (mounted.current) {
        setMainError(`Could not load data. Please, try again.`);
      }
      console.error(e);
    } finally {
      if (mounted.current) {
        setIsFetching(false);
      }
    }
  }, [authState?.viewCompanyId]);

  const fetchSites = useCallback(async () => {
    try {
      const response = await buildingsApi.getSitesByCompanyId(
        authState?.viewCompanyId,
      );
      if (mounted.current) {
        if (isValidResponse(response)) {
          const data = getDataFromResponse(response);
          setSitesData(data);
        } else {
          setSitesData([]);
        }
        setSitessInitialLoaded(true);
      }
    } catch (e) {
      if (mounted.current) {
        setMainError(`Could not load data. Please, try again.`);
      }
      console.error(e);
    }
  }, [authState?.viewCompanyId]);

  const handleError = useCallback(
    (errorMessage = '', shouldUpdateData = false) => {
      setMainError(errorMessage);
      if (shouldUpdateData) {
        fetchBuildings();
        fetchSites();
      }
    },
    [],
  );

  return (
    <>
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
      <Stack>
        <Title text="Sites & Buildings" />
        <FloorplanUploadModal
          open={!!floorplanUploadBuildingData}
          buildingId={floorplanUploadBuildingData?.id}
          buildingName={floorplanUploadBuildingData?.name}
          handleClose={() => setFloorplanUploadBuildingData(null)}
        />
        {Array.isArray(buildingsData) && (
          <Sites
            isInitialLoading={isInitialLoading}
            buildingsData={buildingsData}
            sitesData={sitesData}
            handleError={handleError}
            isFetching={isFetching}
            updateBuildingsData={setBuildingsData}
            handleOpenFloorplanUploadModal={(buildingId) => {
              const building = buildingsData.find(
                (building) => building.buildingid === buildingId,
              );
              setFloorplanUploadBuildingData({
                id: building.buildingid,
                name: building.buildingname,
              });
            }}
          />
        )}
      </Stack>
    </>
  );
};

export default SitesPage;
