import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import TestsDraggableList from './TestsDraggableList';

import { Box } from '@mui/material';

import testsApi from 'Api/tests';

import ErrorMessage from 'Components/UI/ErrorMessage';

import { useIsMounted } from 'Context';

import { isValidResponse } from 'Utils';

const TestsDroppable = function TestsDroppable({
  tests,
  isLoading,
  buildingId,
  afterUpdate,
}) {
  const [enabled, setEnabled] = useState(false);
  /**
   * This is used to render the Droppable elements after an animation frame,
   * because react-beautiful-dnd library has some issues with React 18 compatibility.
   */
  useEffect(() => {
    const animation = window.requestAnimationFrame(() => setEnabled(true));

    return () => {
      window.cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return (
    <Droppable droppableId="test-list-droppable" type="tests">
      {(provided) => (
        <Box ref={provided.innerRef} {...provided.droppableProps}>
          <TestsDraggableList
            isLoading={isLoading}
            tests={tests}
            buildingId={buildingId}
            afterUpdate={afterUpdate}
          />
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  );
};

export default function TestsListDnD({
  tests,
  buildingId,
  isLoading,
  afterUpdate,
}) {
  const [orderedTests, setOrderedTests] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const mounted = useIsMounted();

  useEffect(() => {
    setOrderedTests(tests);
  }, [tests]);

  const updateTestOrderLocal = useCallback(
    (startIndex, endIndex) => {
      const arrCopy = [...orderedTests];

      const test = arrCopy.splice(startIndex, 1)[0];
      arrCopy.splice(endIndex, 0, test);

      setOrderedTests(arrCopy);
    },
    [orderedTests],
  );

  const updateTestOrder = useCallback(
    async (testId, testIdOnTarget) => {
      try {
        const bodyObj = {
          buildingId,
          testId,
          testIdOnTarget,
        };

        const updateResponse = await testsApi.updateTestsOrder(testId, bodyObj);
        if (isValidResponse(updateResponse)) {
          if (typeof afterUpdate === 'function') {
            afterUpdate();
          }
          if (mounted.current) {
            setErrorMsg('');
          }
        } else throw new Error();
      } catch (err) {
        if (mounted.current) {
          setOrderedTests(tests);
          setErrorMsg(`Could not update the tests order`);
        }
      }
    },
    [tests, buildingId, afterUpdate],
  );

  const onDragEnd = useCallback(
    async (data) => {
      setIsFetching(true);

      const { destination, source, draggableId, reason } = data;

      if (reason !== 'DROP' || !destination) return;

      try {
        const testId = parseInt(draggableId.replace('test-', ''), 10);

        const endIndex = destination.index;
        const startIndex = source.index;

        updateTestOrderLocal(startIndex, endIndex);

        const testIdOnTarget = tests[endIndex].testid;

        await updateTestOrder(testId, testIdOnTarget);

        return;
      } catch (err) {
        console.log('onDragEnd err: ', err);
      } finally {
        if (mounted.current) {
          setIsFetching(false);
        }
      }
    },
    [tests, orderedTests],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <ErrorMessage
        message={errorMsg}
        handleCloseErrorAlert={() => setErrorMsg('')}
      />
      <TestsDroppable
        isLoading={isLoading || isFetching}
        tests={orderedTests}
        buildingId={buildingId}
        afterUpdate={afterUpdate}
      />
    </DragDropContext>
  );
}
