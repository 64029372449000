import React from 'react';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';

export default function ScaleSliders({
  sliderHeatmap,
  handleSlideHeatmapChange,
  sliderSp,
  handleSlideSpChange,
  minScaleHeatmap,
  minWidth = 150,
  disabled,
}) {
  return (
    <>
      <Grid container spacing={2} sx={{ px: 1 }}>
        <Grid item xs={6} sx={{ minWidth: minWidth }}>
          <Typography gutterBottom>Heatmap</Typography>
          <Slider
            value={sliderHeatmap}
            onChange={handleSlideHeatmapChange}
            aria-labelledby="input-slider"
            step={0.01}
            min={minScaleHeatmap}
            max={2}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6} sx={{ minWidth: minWidth }}>
          <Typography gutterBottom>SP</Typography>
          <Slider
            value={sliderSp}
            onChange={handleSlideSpChange}
            aria-labelledby="input-slider"
            step={0.1}
            min={10}
            max={75}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </>
  );
}
