import React, { useRef, useState } from 'react';

import CalculatorForm from '../Forms/CalculatorForm';
import '../styles.css';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { useIsMounted } from 'Context';

const CalculatorDataAddEditModal = ({
  open,
  type,
  handleClose,
  handleSubmit,
  transitionDuration = 220,
  data = null,
}) => {
  const mounted = useIsMounted();
  const calculatorFormRef = useRef(null);

  const headerOptions = {
    add: `Add configuration`,
    edit: `Edit configuration`,
  };
  const okBtnText = {
    add: 'Add',
    edit: 'Update',
  };
  const [actionsDisabled, setActionsDisabled] = useState(false);

  const onSubmit = async (values) => {
    try {
      setActionsDisabled(true);

      await handleSubmit(values);
    } catch (err) {
      console.log(type, 'Error: ', err);
    } finally {
      if (mounted.current) {
        setActionsDisabled(false);
      }
    }
  };

  if (!~['add', 'edit'].indexOf(type)) return null;

  return (
    <Dialog
      open={open}
      transitionDuration={transitionDuration}
      disableEscapeKeyDown
      PaperProps={{
        sx: {
          p: 1,
          pb: 2,
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '640px',
        },
      }}
    >
      <DialogTitle>{headerOptions[type] || ''}</DialogTitle>
      <DialogContent>
        <CalculatorForm
          ref={calculatorFormRef}
          onSubmit={onSubmit}
          defaultValues={data || {}}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between', width: '40%' }}>
        <Button
          style={{ width: '100%', marginRight: '8px' }}
          variant="outlined"
          onClick={handleClose}
          disabled={actionsDisabled}
        >
          Cancel
        </Button>
        <Button
          style={{ width: '100%' }}
          variant="contained"
          disabled={actionsDisabled}
          onClick={() => calculatorFormRef.current?.requestSubmit()}
          endIcon={
            actionsDisabled ? (
              <CircularProgress color="inherit" size={'1rem'} />
            ) : null
          }
        >
          {okBtnText[type]}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CalculatorDataAddEditModal;
