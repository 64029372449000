import React, { useEffect, useState } from 'react';

import { Alert, Snackbar } from '@mui/material';

import { usePortfolioContext } from 'Components/Portfolios/context';

const PortfolioSnackbar = () => {
  const autoHideDuration = 10000;
  const { toast } = usePortfolioContext();
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (toast.message?.length > 0) {
      setIsOpen(true);
    }
  }, [toast]);

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        variant="filled"
        severity={toast.severity ?? 'info'}
        sx={{ width: '100%' }}
        onClose={handleClose}
      >
        {toast?.message}
      </Alert>
    </Snackbar>
  );
};

export default PortfolioSnackbar;
