import { PROJECT_STATUSES } from 'Constants';
import React, { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';

import { useFloorplanContext } from '../context';

import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, CircularProgress, TextField } from '@mui/material';

import originpoints from 'Api/originpoints';

import { isSafetracesAdmin } from 'Config/roles';

const OPItem = ({ op, reFetch }) => {
  const { setError, testData, projectData } = useFloorplanContext();
  const [opName, setOpName] = useState(op.opname);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const canDragSTAdmin =
    !!projectData?.status && projectData?.status !== PROJECT_STATUSES.PUBLISHED;
  const canDragAnyUser =
    typeof testData?.testexecuted === 'boolean' && !testData.testexecuted;
  const [, drag] = useDrag(
    {
      type: 'OP',
      item: {
        ...op,
        type: 'OP',
        id: op.opid,
      },
      canDrag: isSafetracesAdmin() ? canDragSTAdmin : canDragAnyUser,
    },
    [op, opName, canDragSTAdmin, canDragAnyUser],
  );

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    }
  }, [isSuccess]);

  useEffect(() => {
    setOpName(op.opname);
  }, [op.opname]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    updateOPName();
  };

  const updateOPName = async () => {
    setIsUpdating(true);
    try {
      const res = await originpoints.updateOriginPoint({
        OPId: op.opid,
        OPName: opName,
      });
      if (res?.data && res?.data?.success) {
        setIsSuccess(true);
        await reFetch();
      } else {
        setError('Error while updating the OP details');
      }
    } catch (e) {
      setError('Error while updating the OP details');
    } finally {
      setIsUpdating(false);
    }
  };

  const resetOPCoordinates = async () => {
    setIsUpdating(true);
    try {
      const res = await originpoints.updateOriginPoint({
        OPId: op.opid,
        OPCoordX: -1,
        OPCoordY: -1,
      });
      if (res?.data && res?.data?.success) {
        setIsSuccess(true);
        reFetch();
      } else {
        setError('Error while updating the OP details');
      }
    } catch (e) {
      setError('Error while updating the OP details');
    } finally {
      setIsUpdating(false);
    }
  };

  const renderLoadingIcon = () => {
    if (isUpdating) return <CircularProgress size="30px" />;
    if (isSuccess) return <DoneIcon color="success" />;
  };

  const renderDraggableMarker = () => {
    const x = Number.parseInt(op.opcoordx);
    const y = Number.parseInt(op.opcoordy);
    if (x >= 0 && y >= 0) {
      return (
        <Button onClick={resetOPCoordinates} disabled={!canDragAnyUser}>
          Reset
        </Button>
      );
    } else {
      return (
        <div
          ref={drag}
          className="object drop-item"
          style={{ width: `45px`, height: `27px`, position: 'relative' }}
        >
          <svg height="100%" width="100%" preserveAspectRatio="xMidYMid meet">
            <rect width="100%" height="100%" fill="saddlebrown" />
            <rect x="10%" y="15%" width="80%" height="70%" fill="linen" />
            <text
              x="50%"
              y="50%"
              dominantBaseline="middle"
              textAnchor="middle"
              fontSize="8px"
            >
              {op.opnumber}
            </text>
          </svg>
        </div>
      );
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <form onSubmit={handleFormSubmit}>
        <TextField
          value={opName ?? ''}
          variant="standard"
          onChange={(e) => {
            setOpName(e.target.value);
          }}
          size="small"
          onBlur={updateOPName}
          placeholder={op.opnumber}
          disabled={!isSafetracesAdmin() && !canDragAnyUser}
        />
      </form>

      <Box width="30px">{renderLoadingIcon()}</Box>
      {renderDraggableMarker()}
    </Box>
  );
};

export default OPItem;
