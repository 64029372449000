import React from 'react';
import { useSearchParams } from 'react-router-dom';

import BreadcrumbsFloorplans from 'Components/Breadcrumbs/BreadcrumbsFloorplans';
import LoadingAndAlerts from 'Components/Floorplans/LoadingAndAlerts';
import ParameterProvider from 'Components/Floorplans/ParameterProvider';
import { FloorplanContextProvider } from 'Components/Floorplans/context';
import OpsSpsPlacement from '../../Components/Floorplans/OpsSpsPlacement';

const FloorplanPlacementPage = () => {
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const { projectId, buildingId, testId } = params;

  return (
    <FloorplanContextProvider>
      <ParameterProvider
        projectId={projectId}
        buildingId={buildingId}
        testId={testId}
      />
      <LoadingAndAlerts />
      <BreadcrumbsFloorplans />
      <OpsSpsPlacement />
    </FloorplanContextProvider>
  );
};

export default FloorplanPlacementPage;
