import React from 'react';

import { SecondaryBorderButton } from '../../Layout/layoutStyles';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Tooltip } from '@mui/material';

export default function AddBorderButton({
  clickAction,
  sx,
  tooltipText = '',
  text = '',
  disableStyles = false,
  extraBtnProps = {},
}) {
  return (
    <Tooltip title={tooltipText} placement="top">
      <span>
        <SecondaryBorderButton
          variant="outlined"
          sx={{ ...sx }}
          onClick={clickAction}
          startIcon={<AddCircleIcon />}
          disable_styles={(disableStyles && disableStyles.toString()) || ''}
          {...extraBtnProps}
        >
          {text}
        </SecondaryBorderButton>
      </span>
    </Tooltip>
  );
}
