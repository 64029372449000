const sizeScalingUtils = {
  iconSize: (fromDimensions, toDimensions) => {
    //console.log("ImageCanvas iconSize");
    //console.log(fromDimensions);
    //console.log(toDimensions);
    // typically going from bit to small
    const scaleWidthRatio = toDimensions.width / fromDimensions.width;
    const scaleHeightRatio = toDimensions.height / fromDimensions.height;
    //console.log("scaleWidthRatio: " + scaleWidthRatio);
    //console.log("scaleHeightRatio: " + scaleHeightRatio);
    /*
     * tiny 0.015
     * small 0.025
     * medium 0.035
     * large 0.045
     * huge 0.075
     */
    const xy =
      (fromDimensions.width > fromDimensions.height
        ? fromDimensions.width
        : fromDimensions.height) *
      0.035 *
      (scaleWidthRatio > scaleHeightRatio ? scaleWidthRatio : scaleHeightRatio);
    const result = {
      width: xy,
      height: xy,
    };

    //console.log("ImageCanvas iconSize");
    //console.log(result);
    return result;
  },

  toCoordiates: (fromDimensions, fromX, fromY, toDimension) => {
    //todo validate parameters
    //console.log("toCoordinates " + fromX + ", " + fromY);
    if (fromX === null || fromY === null) {
      return {
        x: null,
        y: null,
      };
    }

    const scaleWidthRatio = toDimension.width / fromDimensions.width;
    const scaleHeightRatio = toDimension.height / fromDimensions.height;

    const result = {
      x: fromX * scaleWidthRatio,
      y: fromY * scaleHeightRatio,
    };
    /*
      console.log("toCoordinates fromDimensions.width " + fromDimensions.width 
                  + ",  fromDimensions.height " + fromDimensions.height 
                  + ", fromX " + fromX 
                  + ", fromY " + fromY
                  + ", toDimension.width " + toDimension.width
                  + ", toDimension.height " + toDimension.height);
      */
    return result;
  },

  centerToZeroPoint: (x, y, width, height) => {
    //console.log("ImageCanvas centerToZeroPoint " + x + ", " + y + ", " +  width + ", " +  height);

    if (x !== null && y !== null) {
      const zeroX = x - width / 2.0;
      const zeroY = y - height / 2.0;

      const result = { x: zeroX, y: zeroY };
      //console.log(result);
      return result;
    } else {
      const result = { x: null, y: null };
      return result;
    }
  },

  zeroPointToCenter: (x, y, width, height) => {
    //console.log("ImageCanvas zeroPointToCenter " + x + ", " + y + ", " +  width + ", " +  height);
    const centerX = x + width / 2.0;
    const centerY = y + height / 2.0;

    const result = { x: centerX, y: centerY };
    //console.log(result);
    return result;
  },
};

export default sizeScalingUtils;
