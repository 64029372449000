import { LS_BREADCRUMBS } from 'Constants';
import React from 'react';
import { useSelector } from 'react-redux';

import AppBreadcrumbs from './AppBreadcrumbs';
import {
  getBuildingProjectsBreadcrumb,
  getPortfolioBreadcrumb,
  getTextBreadcrumb,
} from './dataUtils';

import { buildingProjectResultsSelector } from 'Features/BuildingProjectsResults/buildingProjectResultsSlice';
import { resultsSelector } from 'Features/Results/resultsSlice';

export default function BreadcrumbsFloorplanProjectHeatmap({
  buildingId,
  projectId,
  floorplanId,
  floorplanName,
  floorplanLoading,
}) {
  const {
    buildings,
    currentBuildingName,
    isLoading: buildingDataLoading,
  } = useSelector(resultsSelector);
  const { projects, isLoading: projectsDataLoading } = useSelector(
    buildingProjectResultsSelector,
  );

  const floorplanFromProjects = projects
    .find((project) => parseInt(project.id) === parseInt(projectId, 10))
    ?.heatmaps.find(({ mainfloorplanid, floorplanid }) => {
      return (
        parseInt(floorplanId) ===
        (parseInt(mainfloorplanid) || parseInt(floorplanid))
      );
    });
  const floorplanNameFromProjects =
    floorplanFromProjects?.mainfpname || floorplanFromProjects?.filename;

  const breadcrumbs = [
    getPortfolioBreadcrumb(buildings, buildingId, true),
    !buildingDataLoading
      ? getBuildingProjectsBreadcrumb(
          buildingId,
          currentBuildingName,
          projectsDataLoading ? [] : projects,
          projectId,
        )
      : null,
    floorplanNameFromProjects
      ? getTextBreadcrumb(floorplanNameFromProjects)
      : !floorplanLoading
      ? getTextBreadcrumb(floorplanName)
      : null,
  ];

  localStorage.setItem(
    LS_BREADCRUMBS,
    breadcrumbs.map((item) => item?.text || '').join(' / '),
  );

  return <AppBreadcrumbs links={breadcrumbs} />;
}
