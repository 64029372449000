import { PROJECT_STATUSES_LABELS } from 'Constants';
import React, { Fragment } from 'react';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import DeleteProjectBtn from './DeleteProjectBtn';

import SourceIcon from '@mui/icons-material/Source';
import {
  IconButton,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material';

import TablePaginationActions from 'Components/Companies/TablePaginationActions';
import TableBodyEmptyRowsFiller from 'Components/UI/TableBodyEmptyRowsFiller';
import TableBodySkeleton from 'Components/UI/TableBodySkeleton';
import TableEmptyDataDisplay from 'Components/UI/TableEmptyDataDisplay';

import { isViewer } from 'Config/roles';
import { ROUTE_PROJECT_DETAILS, ROUTE_PROJECT_DOWNLOADS } from 'Config/routes';

import {
  ProjectsStyledTableSortLabel,
  StyledTableCell,
  StyledTableRow,
} from 'Layout/layoutStyles';

export default function ProjectsTable({
  page,
  rowsPerPage,
  totalProjects,
  setPage,
  projectsList,
  afterProjectsListUpdate,
  isLoading,
  orderBy,
  order,
  onRequestSort,
}) {
  const navigate = useNavigate();
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    totalProjects < rowsPerPage ? 0 : rowsPerPage - projectsList.length;

  const renderActions = (
    projectId,
    name,
    hasTestPackages,
    origin,
    status,
    tests,
    displayOnly = false,
    hidden,
  ) => {
    if (isViewer()) return null;

    const deleteDisabled = hasTestPackages || tests?.length > 0;
    const disableDeleteExplanation = `You cannot delete project with tests and test package`;
    const displayDeleteButton =
      (['project_portal', 'offline_customer_portal'].includes(origin) &&
        status === 'new') ||
      displayOnly;

    return (
      <Stack direction="row" justifyContent="flex-end">
        <DeleteProjectBtn
          disabled={deleteDisabled}
          disabledReason={
            !displayDeleteButton || hidden ? '' : disableDeleteExplanation
          }
          projectName={`${name}`}
          projectId={projectId}
          afterDelete={afterProjectsListUpdate}
          displayOnly={displayOnly}
          hidden={!displayDeleteButton || hidden}
        />
        <Tooltip title={displayOnly ? '' : `Go to the project's downloads`}>
          <IconButton
            variant="contained"
            href={
              !displayOnly
                ? generatePath(ROUTE_PROJECT_DOWNLOADS, {
                    projectId,
                  })
                : null
            }
            disabled={displayOnly}
            sx={{ visibility: hidden ? 'hidden' : 'visible' }}
          >
            <SourceIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  const handleClick = (event, projectId) => {
    event.preventDefault();
    navigate(
      generatePath(ROUTE_PROJECT_DETAILS, {
        projectId: projectId,
      }),
    );
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const formSingleProjectRow = (project, i) => {
    const {
      id: projectId,
      name,
      endCustomer,
      partners,
      createdAt,
      origin,
      status,
      hasTestPackages,
      tests,
    } = project;

    return (
      <StyledTableRow key={`key=${i}`}>
        <StyledTableCell>
          <Link
            href={`/projects/${projectId}`}
            onClick={(event) => handleClick(event, projectId)}
          >
            {projectId}
          </Link>
        </StyledTableCell>
        <StyledTableCell>
          <Link
            href={`/projects/${projectId}`}
            onClick={(event) => handleClick(event, projectId)}
          >
            {name}
          </Link>
        </StyledTableCell>
        <StyledTableCell>{endCustomer}</StyledTableCell>
        <StyledTableCell>{partners}</StyledTableCell>
        <StyledTableCell align="center">{createdAt}</StyledTableCell>
        <StyledTableCell align="center" style={{ whiteSpace: 'nowrap' }}>
          {PROJECT_STATUSES_LABELS[status]}
        </StyledTableCell>
        <StyledTableCell align="center">
          {renderActions(
            projectId,
            name,
            hasTestPackages,
            origin,
            status,
            tests,
          )}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small" sx={{ tableLayout: 'fixed' }}>
        <ProjectsTableHeader {...{ orderBy, order, onRequestSort }} />
        <TableBody>
          {isLoading && (
            <TableBodySkeleton
              columns={7}
              rows={totalProjects < rowsPerPage ? totalProjects : rowsPerPage}
              shouldRenderActionsCell={true}
              actionsComponent={renderActions(
                null,
                null,
                null,
                null,
                null,
                null,
                true,
              )}
            />
          )}
          {!isLoading ? (
            <React.Fragment>
              {!projectsList.length ? (
                <TableEmptyDataDisplay columns={7} />
              ) : (
                <React.Fragment>
                  {projectsList.map((project, i) =>
                    formSingleProjectRow(project, i),
                  )}
                  <TableBodyEmptyRowsFiller rowsAmount={emptyRows}>
                    {renderActions(
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      true,
                      true,
                    )}
                  </TableBodyEmptyRowsFiller>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : null}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={6}
              count={totalProjects}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={null}
              SelectProps={{ style: { display: 'none' } }}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[rowsPerPage]}
              ActionsComponent={TablePaginationActions}
              labelDisplayedRows={({ from, to, count }) =>
                totalProjects ? `${from}–${to} of ${count}` : ''
              }
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

const ProjectsTableHeader = ({ orderBy, order, onRequestSort }) => {
  const tableConfig = {
    projectId: {
      widthcol: 2.5,
      headerName: 'Project Id',
      orderName: 'id',
      align: 'left',
    },
    projectName: {
      widthcol: 5.5,
      headerName: 'Project Name',
      align: 'left',
      orderName: 'name',
    },
    customer: {
      widthcol: 4,
      headerName: 'Customer',
      align: 'left',
    },
    partner: {
      widthcol: 4,
      headerName: 'Partner',
      align: 'left',
    },
    created: {
      widthcol: 3,
      headerName: 'Created On',
      align: 'center',
      orderName: 'createdate',
    },
    status: {
      widthcol: 2,
      headerName: 'Status',
      align: 'center',
      orderName: 'status',
    },
    action: {
      widthcol: 3,
      headerName: 'Actions',
      align: 'center',
    },
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {Object.keys(tableConfig).map((column, i) => (
          <Fragment key={`header-${i}`}>
            {typeof tableConfig[column].orderName !== 'undefined' && (
              <StyledTableCell
                align={tableConfig[column].align}
                widthcol={tableConfig[column].widthcol}
                sx={{
                  py: 1.5,
                  width: `calc((100% / 24) * ${tableConfig[column].widthcol})`,
                }}
              >
                <ProjectsStyledTableSortLabel
                  active={orderBy === tableConfig[column].orderName}
                  direction={
                    orderBy === tableConfig[column].orderName ? order : 'asc'
                  }
                  onClick={createSortHandler(tableConfig[column].orderName)}
                >
                  {tableConfig[column].headerName}
                </ProjectsStyledTableSortLabel>
              </StyledTableCell>
            )}
            {typeof tableConfig[column].orderName === 'undefined' && (
              <StyledTableCell
                align={tableConfig[column].align}
                widthcol={tableConfig[column].widthcol}
                sx={{
                  py: 1.5,
                  width: `calc((100% / 24) * ${tableConfig[column].widthcol})`,
                }}
              >
                {tableConfig[column].headerName}
              </StyledTableCell>
            )}
          </Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
};
