import React, { forwardRef } from 'react';
import { useForm } from 'react-hook-form';

import { Grid, MenuItem, TextField, Typography } from '@mui/material';

const UserForm = forwardRef((props, ref) => {
  const { onSubmit, defaultValues, roles, accesses, companyType } = props;

  const roleName =
    companyType === 'self'
      ? 'Safetracer'
      : companyType === 'Customer'
      ? 'Customer'
      : 'Partner';

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(
    defaultValues
      ? {
          defaultValues: Object.assign(defaultValues, {
            roleName,
          }),
        }
      : {
          roleName,
        },
  );

  return (
    <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Firstname"
          autoComplete="current-firstname"
          {...register('firstname', {
            required: 'Firstname field required',
          })}
          error={!!(errors && errors.firstname)}
          helperText={errors?.firstname?.message}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Lastname"
          autoComplete="current-lastname"
          {...register('lastname', {
            required: 'Lastname field required',
          })}
          error={!!(errors && errors.lastname)}
          helperText={errors?.lastname?.message}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Email Address"
          {...register('email', {
            required: 'Email field required',
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Entered value does not match email format',
            },
          })}
          error={!!(errors && errors.email)}
          helperText={errors?.email?.message}
        />
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: '1px' }}>
        <Grid item xs={6}>
          <TextField
            select
            variant="outlined"
            fullWidth
            label="Role"
            disabled
            defaultValue={defaultValues?.rolename || roleName}
            {...register('roleName')}
            error={!!(errors && errors.roleName)}
            helperText={errors?.roleName?.message}
          >
            {roles.map((role) => (
              <MenuItem key={role.roleid} value={role.rolename}>
                <Typography>{role.rolename}</Typography>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            variant="outlined"
            fullWidth
            label="Access"
            defaultValue={defaultValues?.accesslevel || ''}
            {...register('accessLevel')}
            error={!!(errors && errors.accessLevel)}
            helperText={errors?.accessLevel?.message}
          >
            {accesses.map((access) => (
              <MenuItem key={access.permissionid} value={access.accesslevel}>
                <Typography>{access.accesslevel}</Typography>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </form>
  );
});

UserForm.displayName = 'UserForm';

export default UserForm;
