import { ERROR_MESSAGE_DEFAULT } from 'Constants';
import React, { useEffect, useState } from 'react';
import XLSX from 'xlsx-color';

import '../styles.css';

import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';

import buildingsApi from 'Api/buildings';

import ErrorMessage from 'Components/UI/ErrorMessage';

import { useIsMounted } from 'Context';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  isValidResponse,
} from 'Utils';

const DownloadEACHDataBtn = ({ disabled, buildingId, buildingName }) => {
  const [errorMsg, setErrorMsg] = useState('');
  const mounted = useIsMounted();

  const downloadCalculatedData = async () => {
    try {
      const response = await buildingsApi.downloadBuildingEACHData(buildingId);
      if (!isValidResponse(response)) {
        throw new Error(getErrorMessageFromResponse(response));
      }
      const data = getDataFromResponse(response);
      if (!data || !Object.keys(data)) {
        throw new Error('Invalid response data');
      }
      const { content, filename } = data;
      const { labels, values, MERVs, names, unitsLabels } = content;

      /**
       * 1. Calculate rows and columns amount.
       * 2 is added to columns because there are Labels and Units columns.
       * 1 is added to rows because of the header row.
       */
      const rowsAmount = labels.length + 1;
      const columnsAmount = 2 + values.length;

      const headerColorStyle = getXlsxCellStyleObj(`CCCCFF`);
      const alphabetArr = Array.from(`ABCDEFGHIJKLMNOPQRSTUVWXYZ`);

      /**
       * 2. Create new XLSX book and fill it with the calculated above amount of columns and rows.
       */
      const xlsxBook = XLSX.utils.book_new();
      const contentSheet = XLSX.utils.aoa_to_sheet(
        Array(rowsAmount)
          .fill(1)
          .map((_, index) =>
            alphabetArr
              .slice(0, columnsAmount)
              .map((letter) => `${letter}${index}`),
          ),
      );

      /**
       * 3. Give the header's cells (A1, B1, etc) names and color it.
       * Cols is responsible for the column width, the width number is the
       * expected amount of symbols.
       */
      contentSheet['!cols'] = [];

      for (let columnNumber = 0; columnNumber < columnsAmount; columnNumber++) {
        const cellObj = contentSheet[`${alphabetArr[columnNumber]}1`];
        const widthConfigArr = contentSheet['!cols'];

        cellObj.s = headerColorStyle;
        switch (columnNumber) {
          case 0:
            cellObj.v = `Building: ${buildingName}`;
            widthConfigArr.push(getXlsxCellWidthObj(48));
            break;
          case 1:
            cellObj.v = `Units`;
            widthConfigArr.push(getXlsxCellWidthObj(8));
            break;
          default:
            const configurationName = names[columnNumber - 2];
            cellObj.v = configurationName || '';

            widthConfigArr.push(getXlsxCellWidthObj(28));
            break;
        }
      }

      /**
       * 4. Fill in data (starting from the second row, because the header row was treated above)
       */
      for (let row = 2; row <= rowsAmount; row++) {
        const dataIndex = row - 2;

        contentSheet[`A${row}`].v = labels[dataIndex] || '';
        contentSheet[`B${row}`].v = unitsLabels[dataIndex] || '';

        /**
         * 4.1. Fill in data for each MERV rating in values.
         */
        for (let j = 0; j < values.length; j++) {
          const valuesForSingleMerv = values[j];
          const columnIndex = j + 2;

          contentSheet[`${alphabetArr[columnIndex]}${row}`].v =
            valuesForSingleMerv[dataIndex] || '';

          /**
           * 4.2. Color the cell with data about the Total Effective ACH_e.
           */
          if (labels[dataIndex] === 'Total Effective ACH_e') {
            let fillColor =
              valuesForSingleMerv[dataIndex] >= 3 ? '33CC33' : 'FF0000';

            contentSheet[`${alphabetArr[columnIndex]}${row}`].s =
              getXlsxCellStyleObj(fillColor);
          }
        }
      }

      XLSX.utils.book_append_sheet(xlsxBook, contentSheet);

      XLSX.writeFile(xlsxBook, `${filename}.xlsx`);
    } catch (err) {
      console.log('downloadCalculatedData Error: ', err);
      if (mounted.current) {
        setErrorMsg(err.message || ERROR_MESSAGE_DEFAULT);
      }
    }
  };

  const getXlsxCellStyleObj = React.useCallback((color) => {
    return {
      fill: {
        fgColor: { rgb: color },
      },
    };
  }, []);

  const getXlsxCellWidthObj = React.useCallback((width) => {
    return {
      wch: width,
    };
  }, []);

  return (
    <>
      <ErrorMessage
        message={errorMsg}
        handleCloseErrorAlert={() => setErrorMsg('')}
      />
      <Button
        size="small"
        disabled={disabled}
        color="primary"
        variant="contained"
        startIcon={<DownloadIcon />}
        onClick={downloadCalculatedData}
      >
        Download results
      </Button>
    </>
  );
};

export default DownloadEACHDataBtn;
