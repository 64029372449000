import { UNMOUNT_IGNORED_ERR_MSG } from 'Constants';
import axios from 'axios';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import React, { useCallback, useEffect, useState } from 'react';

import { Box, Skeleton, Stack } from '@mui/material';

import samplePlans from 'Api/samplePlans';

import CreateButton from 'Components/Buttons/CreateButton';
import DownloadButton from 'Components/Buttons/DownloadButton';

import { useIsMounted } from 'Context';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  isValidResponse,
  isValidStatusResponse,
} from 'Utils';

function SamplePlan({ loading, projectId, projectName, shouldExist, onError }) {
  const mounted = useIsMounted();

  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [creatingInProgress, setCreatingInProgress] = useState(false);
  const [downloadType, setDownloadType] = useState(``);
  const [localLoading, setLocalLoading] = useState(false);

  const [samplePlanCreated, setSamplePlanCreated] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    getSamplePlan(source);

    return () => {
      source.cancel(UNMOUNT_IGNORED_ERR_MSG);
    };
  }, [projectId]);

  const getSamplePlan = async (source) => {
    setLocalLoading(true);
    try {
      const response = await samplePlans.getSamplePlan(projectId, source);
      setLocalLoading(false);
      if (
        isValidResponse(response) &&
        typeof response.data !== 'undefined' &&
        typeof response.data.data !== 'undefined' &&
        typeof response.data.data.samplePlan !== 'undefined' &&
        response.data.data.samplePlan.length > 0
      ) {
        if (mounted.current) {
          setSamplePlanCreated(true);
        }
        return true;
      } else {
        if (mounted.current) {
          setSamplePlanCreated(false);
        }
        return false;
      }
    } catch (err) {
      if (err.message === UNMOUNT_IGNORED_ERR_MSG) return;

      if (mounted.current) {
        setLocalLoading(false);
        console.log(`Get Sample Plan Error: `, err);
      }
      if (typeof onError === 'function') {
        onError(err);
      }
      return false;
    }
  };

  const reCreateSamplePlan = async () => {
    try {
      samplePlans.removeSamplePlan(projectId).then(async () => {
        await createSamplePlan();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const createSamplePlan = async () => {
    setSamplePlanCreated(false);
    setCreatingInProgress(true);

    try {
      const createResponse = await samplePlans.createSamplePlan(projectId);

      if (isValidStatusResponse(createResponse)) {
        const samplePlanCreated = await getSamplePlan();
        setCreatingInProgress(false);
        if (!samplePlanCreated) {
          throw new Error('Unable to create Plate Layout');
        }
      } else throw new Error(getErrorMessageFromResponse(createResponse));
    } catch (err) {
      setCreatingInProgress(false);
      console.log(`Create Sample Plan Error: `, err);
      if (typeof onError === 'function') {
        onError(err);
      }
    }
  };

  const handleDownloadIndicators = (isDownloading, type = `regular`) => {
    if (mounted.current) {
      setDownloadInProgress(isDownloading);
      setDownloadType(isDownloading ? type : ``);
    }
  };

  const handleDownload = useCallback(async () => {
    try {
      handleDownloadIndicators(true);

      const response = await samplePlans.getSamplePlan(projectId);

      if (isValidResponse(response)) {
        const { samplePlan } = getDataFromResponse(response);

        const csvContent = formatSamplePlanToCSV(samplePlan);

        if (!csvContent) {
          throw new Error();
        }

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute(
          'download',
          `PID ${projectId}-${projectName}-Wide.csv`,
        );
        document.body.appendChild(link); // Required for FF

        link.click();

        return true;
      } else {
        throw new Error(getErrorMessageFromResponse(response));
      }
    } catch (err) {
      console.error(`Sample Plan Download Error: `, err);
      if (typeof onError === 'function') {
        onError(err.message);
      }

      return false;
    } finally {
      handleDownloadIndicators(false);
    }
  }, [projectId, projectName, onError]);

  const handleDownloadQs3 = useCallback(async () => {
    try {
      handleDownloadIndicators(true, `zipQS`);

      const response = await samplePlans.getZipUrl(projectId);

      if (response && response.data) {
        const { data } = response;

        const zip = new JSZip();

        for (const [key, value] of Object.entries(data)) {
          const plateNumber = key.slice(0, -4); // remove file format from key
          zip.file(
            `PID-${projectId}-${projectName}-${plateNumber}-Long.csv`,
            typeof value === 'object' ? JSON.stringify(value) : value,
          );
        }

        zip.generateAsync({ type: 'blob' }).then(function (blob) {
          saveAs(blob, `PID-${projectId}-${projectName}-Long.zip`);
        });

        return true;
      } else {
        throw new Error(getErrorMessageFromResponse(response));
      }
    } catch (err) {
      console.error(`Sample Plan QS3 Download Error: `, err);
      if (typeof onError === 'function') {
        onError(err.message);
      }

      return false;
    } finally {
      handleDownloadIndicators(false);
    }
  }, [projectId, projectName, onError]);

  const formatSamplePlanToCSV = (samplePlan) => {
    try {
      const downloadData = [];

      let headersArr;

      for (let plateNum = 0; plateNum < samplePlan.length; plateNum++) {
        const plateData = samplePlan[plateNum];
        const { rows, unique, uniqueNum, typeC } = plateData;

        /**
         * Add header row before each plate.
         */
        if (!headersArr) {
          headersArr = Object.keys(rows[0]);
        }

        downloadData.push(headersArr);

        for (let i = 0; i < rows.length; i++) {
          const stripData = rows[i];
          if (unique && typeC) {
            stripData.plateid = stripData.plateid + `U${uniqueNum}`;
          }

          rows[i] = Object.values(rows[i]);
        }

        downloadData.push(...rows);
      }

      return (
        'data:text/csv;charset=utf-8,' +
        downloadData.map((e) => e.join(',')).join('\n')
      );
    } catch (err) {
      console.error(`formatSamplePlanToCSV Error: `, err);

      return null;
    }
  };

  return (
    <Stack direction="column">
      {(loading || localLoading) && (
        <Skeleton variant="rounded" height={35} sx={{ maxWidth: '480px' }} />
      )}
      {!loading && !localLoading && (
        <Box sx={{ minHeight: 40 }}>
          <Stack direction="row" gap={2}>
            {!samplePlanCreated && (
              <CreateButton
                tooltipText={
                  !shouldExist
                    ? `It is not possible to create a Plate Layout for this project`
                    : ''
                }
                disabled={samplePlanCreated || !shouldExist}
                loading={creatingInProgress}
                clickAction={createSamplePlan}
                variant={'contained'}
                text={'Create Plate Layout'}
              />
            )}
            {samplePlanCreated && (
              <CreateButton
                tooltipText={
                  !shouldExist
                    ? `It is not possible to create a Plate Layout for this project`
                    : ''
                }
                disabled={!samplePlanCreated && shouldExist}
                loading={creatingInProgress}
                clickAction={reCreateSamplePlan}
                variant={'contained'}
                text={'Recreate Plate Layout'}
              />
            )}

            <DownloadButton
              disabled={
                !samplePlanCreated ||
                (downloadInProgress && downloadType === 'regular')
              }
              loading={downloadInProgress && downloadType === 'regular'}
              tooltipText={
                !shouldExist ? `The project does not have a Plan Layout` : ''
              }
              clickAction={handleDownload}
              sx={{ width: '140px' }}
            />
            <DownloadButton
              text="Download QS3"
              disabled={
                !samplePlanCreated ||
                (downloadInProgress && downloadType === 'zipQS')
              }
              loading={downloadInProgress && downloadType === 'zipQS'}
              tooltipText={
                !shouldExist ? `The project does not have a Plan Layout` : ''
              }
              clickAction={handleDownloadQs3}
              sx={{ width: '180px' }}
            />
          </Stack>
        </Box>
      )}
    </Stack>
  );
}

export default React.memo(SamplePlan);
