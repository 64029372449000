import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import users from '../../Api/users';
import { isValidResponse } from '../../Utils';

import { Restore } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Button,
  Container,
  CssBaseline,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

function RecoverPassword() {
  const [open, setOpen] = useState(false);
  const [err, setErr] = useState(null);
  const [message, setMessage] = useState(null);
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    const res = await users.recoverPassword({ email: data.email });
    if (isValidResponse(res)) {
      setErr(res.data.error.message);
      setOpen(true);
    } else {
      setMessage(`Message was sent to ${data.email}`);
      setOpen(true);
      reset();
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setErr(null);
    setMessage(null);
  };

  const RecoverPasswordWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }));

  const RecoverPasswordForm = styled('form')(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(1),
  }));

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <RecoverPasswordWrapper>
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <Restore />
        </Avatar>
        <Typography component="h1" variant="h5">
          Recover password
        </Typography>
        <RecoverPasswordForm
          onSubmit={handleSubmit(async (data) => onSubmit(data))}
        >
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Email Address"
            {...register('email', {
              required: 'Email field required',
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Entered value does not match email format',
              },
            })}
            error={!!(errors && errors.email)}
            helperText={errors?.email?.message}
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{
                  my: 2,
                }}
              >
                Recover
              </Button>
            </Grid>
          </Grid>
        </RecoverPasswordForm>
        {/* <Button onClick={() => navigate('/signup')}>
          create new account
        </Button> */}
        <Button onClick={() => navigate('/login')}>to login</Button>
      </RecoverPasswordWrapper>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {err === null ? (
          <Alert onClose={handleClose} severity="success" variant="filled">
            {message}
          </Alert>
        ) : (
          <Alert onClose={handleClose} severity="error" variant="filled">
            {err}
          </Alert>
        )}
      </Snackbar>
    </Container>
  );
}

export default RecoverPassword;
