export const TAG_LIST = [
  'Tag-A1',
  'Tag-A2',
  'Tag-A3',
  'Tag-A4',
  'Tag-A5',
  'Tag-A6',
  'Tag-A7',
  'Tag-A8',
  'Tag-B1',
  'Tag-B2',
  'Tag-B3',
  'Tag-B4',
  'Tag-B5',
  'Tag-B6',
  'Tag-B7',
  'Tag-B8',
  'Tag-C1',
  'Tag-C2',
  'Tag-C3',
  'Tag-C4',
  'Tag-C5',
  'Tag-C6',
  'Tag-C7',
  'Tag-C8',
];

export const PLATE_KIND_LIST = ['A', 'B', 'C'];
