import { DASHBOARD_VIEW_TYPES, PROJECT_STATUSES } from 'Constants';
import {
  PRESENTATION_DEFAULT_LEFT_MARGIN,
  PRESENTATION_DEFAULT_SIZE,
  createPresentation,
  createTemplateSlideForPresentation,
} from 'Utils/presentationUtils';
import * as htmlToImage from 'html-to-image';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import { useParams } from 'react-router-dom';

import buildingsApi from 'Api/buildings';
import commonApi from 'Api/common';

import { getScaledImageSize } from 'Components/Floorplans/utils';

import {
  ROUTE_DASHBOARD,
  ROUTE_DASHBOARD_BUILDING,
  ROUTE_DASHBOARD_PORTFOLIO,
} from 'Config/routes';

import { useAuthState, useIsMounted } from 'Context';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  getLSViewCompanyId,
  isValidResponse,
} from 'Utils';

const DashboardContext = createContext({});

const CHARTS_IDS_NAMES_MAP = {
  'chart-adjusted-each-by-hda':
    'Adjusted Equivalent Air Change Rate (eACH) by High Density Area',
  'chart-each-by-floor': 'eACH by Floor',
  'chart-portfolio-buildings': "Portfolio's buildings overview",
  'main-overview-tests': 'Tests',
  'main-overview-projects': 'Projects',
  'main-overview-samples': 'Samples',
};
const CHARTS_IDS_PRESENTATION_TITLES_MAP = {
  'chart-adjusted-each-by-hda': 'eACH by High Density Area',
  'chart-each-by-floor': 'eACH by Floor',
  'chart-portfolio-buildings': "Portfolio's buildings overview",
  'main-overview-tests': 'Tests',
  'main-overview-projects': 'Projects',
  'main-overview-samples': 'Samples',
};

const defaultFilterParams = {
  filterByCompany: false,
  filterByTestsCompletion: false,
  periodType: 'yearly',
  period: null,
  projectStatuses: Object.values(PROJECT_STATUSES),
};

export const DashboardContextProvider = ({ type, children }) => {
  const { portfolioId, buildingId } = useParams();
  const mounted = useIsMounted();
  const authState = useAuthState();
  const [errorMsg, setErrorMsg] = useState('');

  const [isPNGDownload, setIsPNGDownload] = useState(false);
  const [isSlideDownload, setIsSlideDownload] = useState(false);

  const [mainOverviewFilterParams, setMainOverviewFilterParams] =
    useState(defaultFilterParams);

  const [isMainOverviewLoading, setMainOverviewLoading] = useState(false);
  const [isPortfolioOverviewLoading, setPortfolioOverviewLoading] =
    useState(false);
  const [isBuildingOverviewLoading, setBuildingOverviewLoading] =
    useState(false);

  const [mainOverviewData, setMainOverviewData] = useState(null);
  const [portfolioOverviewData, setPortfolioOverviewData] = useState(null);
  const [buildingOverviewData, setBuildingOverviewData] = useState(null);

  const [navigationData, setNavigationData] = useState(null);
  const [navigationDataIsLoading, setNavigationDataIsLoading] = useState(true);

  useEffect(() => {
    loadNavigationData();
    if (type === 'all') {
      fetchMainOverviewData(defaultFilterParams);
    }
  }, []);

  useEffect(
    function onSelectedCompanyChange() {
      if (mainOverviewFilterParams.filterByCompany) {
        fetchMainOverviewData(mainOverviewFilterParams);
      }
    },
    [authState.viewCompanyId],
  );

  useEffect(
    function onPortfolioChange() {
      if (portfolioId) {
        fetchPortfolioOverviewData(portfolioId);
      }
    },
    [portfolioId],
  );

  useEffect(
    function onBuildingChange() {
      if (buildingId) {
        fetchBuildingOverviewData(buildingId);
      }
    },
    [buildingId],
  );

  const updateMainOverviewFilterAndFetchData = async (key, value) => {
    const newFilterParams = Object.assign({}, mainOverviewFilterParams, {
      [key]: value,
    });
    if (key === 'periodType') {
      newFilterParams.period = null;
    }
    setMainOverviewFilterParams(newFilterParams);
    fetchMainOverviewData(newFilterParams);
  };

  const getChartNameForDownload = (id) => {
    const buildingName = getEntityLabel('building', buildingId);
    const portfolioName = getEntityLabel('portfolio', portfolioId);
    const additionToGeneralName =
      type === 'all'
        ? ''
        : type === 'building'
        ? `${buildingName} - `
        : `${portfolioName} - `;

    return `${additionToGeneralName}${CHARTS_IDS_NAMES_MAP[id]}`;
  };

  const handleDownloadPNGChart = async (id) => {
    setIsPNGDownload(true);
    const chart = document.getElementById(id);
    const chartName = getChartNameForDownload(id);

    try {
      const imgUrl = await htmlToImage.toPng(chart, {
        backgroundColor: '#fff',
        width: chart.offsetWidth * 2,
        height: chart.offsetHeight * 2,
        style: {
          transform: 'scale(2)',
          transformOrigin: 'top left',
        },
      });

      const a = document.createElement('a');
      a.href = imgUrl;
      a.download = `${chartName}.png`;
      a.click();
    } catch (err) {}

    setIsPNGDownload(false);
  };

  const handleDownloadSlide = async (id) => {
    try {
      setIsSlideDownload(true);
      const chartName = getChartNameForDownload(id);

      const pres = createPresentation();

      const slide = createTemplateSlideForPresentation(
        pres,
        CHARTS_IDS_PRESENTATION_TITLES_MAP[id],
      );

      const chart = document.getElementById(id);
      const img = await htmlToImage.toPng(chart, {
        backgroundColor: '#fff',
        width: chart.offsetWidth * 2,
        height: chart.offsetHeight * 2,
        style: {
          transform: 'scale(2)',
          transformOrigin: 'top left',
        },
      });

      const slideImgSizeInInch = getScaledImageSize(
        { width: chart.clientWidth, height: chart.clientHeight },
        {
          width: PRESENTATION_DEFAULT_SIZE.w * 0.6,
          height: PRESENTATION_DEFAULT_SIZE.h * 0.7,
        },
      );

      slide.addImage({
        data: img,
        x: `${
          ((1 -
            (slideImgSizeInInch.width - PRESENTATION_DEFAULT_LEFT_MARGIN) /
              PRESENTATION_DEFAULT_SIZE.w) *
            100) /
          2
        }%`,
        y: '25%',
        w: slideImgSizeInInch.width,
        h: slideImgSizeInInch.height,
      });

      pres.writeFile({ fileName: `${chartName}.pptx` });
      setIsSlideDownload(false);
    } catch (err) {
      console.log(err);
    }
  };

  const loadNavigationData = async () => {
    try {
      const response = await buildingsApi.getPortfoliosBuildingsForCompany();
      if (isValidResponse(response)) {
        const navData = getDataFromResponse(response);
        setNavigationData(navData);
      } else throw new Error(getErrorMessageFromResponse(response));
    } catch (err) {
      console.log(err);
      setErrorMsg(err.message);
    } finally {
      setNavigationDataIsLoading(false);
    }
  };

  const fetchMainOverviewData = async ({
    filterByCompany,
    filterByTestsCompletion,
    periodType,
    period,
    projectStatuses,
  }) => {
    try {
      if (periodType === 'quarterly' && !period) {
        return;
      }
      setMainOverviewLoading(true);

      const response = await commonApi.getReportMainOverview(
        filterByCompany ? getLSViewCompanyId() : null,
        periodType,
        period,
        filterByTestsCompletion,
        projectStatuses,
      );

      if (isValidResponse(response)) {
        const data = getDataFromResponse(response);
        if (mounted.current) {
          setMainOverviewData(data);
        }
      } else throw new Error(getErrorMessageFromResponse(response));
    } catch (err) {
      if (mounted.current) {
        setErrorMsg(err.message);
      }
    } finally {
      if (mounted.current) {
        setMainOverviewLoading(false);
      }
    }
  };

  const fetchPortfolioOverviewData = async (portfolioId) => {
    try {
      setPortfolioOverviewLoading(true);
      const response = await commonApi.getReportPortfolioOverview(portfolioId);

      if (isValidResponse(response)) {
        const data = getDataFromResponse(response);
        if (mounted.current) {
          setPortfolioOverviewData(data);
        }
      } else throw new Error(getErrorMessageFromResponse(response));
    } catch (err) {
      if (mounted.current) {
        setErrorMsg(err.message);
      }
    } finally {
      if (mounted.current) {
        setPortfolioOverviewLoading(false);
      }
    }
  };

  const fetchBuildingOverviewData = async (buildingId) => {
    try {
      setBuildingOverviewLoading(true);
      const response = await commonApi.getReportBuildingOverview(buildingId);

      if (isValidResponse(response)) {
        const data = getDataFromResponse(response);
        if (mounted.current) {
          setBuildingOverviewData(data);
        }
      } else throw new Error(getErrorMessageFromResponse(response));
    } catch (err) {
      if (mounted.current) {
        setErrorMsg(err.message);
      }
    } finally {
      if (mounted.current) {
        setBuildingOverviewLoading(false);
      }
    }
  };

  const getEntityLabel = (entityType, entityId) => {
    if (!entityId) return '';

    let objToSearch = {};
    switch (entityType) {
      case 'portfolio':
        objToSearch = navigationData;
        break;
      case 'building':
        for (let portfolioObj of Object.values(navigationData)) {
          if (portfolioObj.buildings[entityId]) {
            objToSearch = portfolioObj.buildings;
            break;
          }
        }
        break;
      default:
        break;
    }

    return objToSearch[entityId]?.name || '';
  };

  const getEntityPath = (entityType, entityId) => {
    const entityTypeIndex = DASHBOARD_VIEW_TYPES.indexOf(entityType);
    if (!~entityTypeIndex || !entityId) return;

    let link;

    switch (entityType) {
      case 'portfolio':
        link = generatePath(ROUTE_DASHBOARD_PORTFOLIO, {
          portfolioId: entityId,
        });
        break;
      case 'building':
        let portfolioId;
        for (let [_portfolioId, portfolioObj] of Object.entries(
          navigationData,
        )) {
          if (portfolioObj.buildings[entityId]) {
            portfolioId = _portfolioId;
          }
        }
        if (!portfolioId) return ROUTE_DASHBOARD;

        link = generatePath(ROUTE_DASHBOARD_BUILDING, {
          portfolioId,
          buildingId: entityId,
        });
        break;
      default:
        break;
    }

    return link;
  };

  return (
    <DashboardContext.Provider
      value={{
        portfolioId,
        buildingId,
        type,
        navigationData,
        errorMsg,
        getEntityLabel,
        getEntityPath,
        handleDownloadPNGChart,
        handleDownloadSlide,
        // Data for charts
        mainOverviewData,
        portfolioOverviewData,
        buildingOverviewData,
        // Loading indicators
        navigationDataIsLoading,
        isPNGDownload,
        isSlideDownload,
        isMainOverviewLoading,
        isPortfolioOverviewLoading,
        isBuildingOverviewLoading,
        // Main overview filter related
        mainOverviewFilterParams,
        updateMainOverviewFilterAndFetchData,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  const {
    portfolioId,
    buildingId,
    mainOverviewData,
    type,
    navigationData,
    navigationDataIsLoading,
    errorMsg,
    getEntityLabel,
    getEntityPath,
    handleDownloadPNGChart,
    isPNGDownload,
    isSlideDownload,
    portfolioOverviewData,
    buildingOverviewData,
    isMainOverviewLoading,
    isPortfolioOverviewLoading,
    isBuildingOverviewLoading,
    mainOverviewFilterParams,
    updateMainOverviewFilterAndFetchData,
    handleDownloadSlide,
  } = useContext(DashboardContext);

  const isLoading =
    isPNGDownload ||
    isSlideDownload ||
    navigationDataIsLoading ||
    (type === 'all' && isMainOverviewLoading) ||
    (type === 'portfolio' && isPortfolioOverviewLoading) ||
    (type === 'building' && isBuildingOverviewLoading);

  return {
    portfolioId,
    buildingId,
    mainOverviewData,
    type,
    navigationData,
    navigationDataIsLoading,
    errorMsg,
    getEntityLabel,
    getEntityPath,
    handleDownloadPNGChart,
    isLoading,
    portfolioOverviewData,
    buildingOverviewData,
    mainOverviewFilterParams,
    updateMainOverviewFilterAndFetchData,
    handleDownloadSlide,
  };
};
