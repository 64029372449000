import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchRoutes } from 'react-router';
import { useLocation } from 'react-router-dom';

import SelectCompanyModal from '../Components/Modals/SelectCompanyModal';
import CustomAppBar from './CustomAppBar/CustomAppBar';
import LayoutContent from './LayoutContent';
import LeftMenu from './LeftMenu/LeftMenu';
import { DrawerStyled } from './layoutStyles';

import CssBaseline from '@mui/material/CssBaseline';

import { isPartnerUser, isSafetracesUser } from 'Config/roles';
import routes, { ROUTES_SAFETRACER_COMPANY_NO_CHOICE } from 'Config/routes';

import { useAuthState } from 'Context';

import { setShowMenu } from 'Features/Compare/compareSlice';
import { compareSelector } from 'Features/Compare/compareSlice';

import { getHeatmapCompare, getLSViewCompanyId } from 'Utils';

export default function Layout() {
  const userData = JSON.parse(localStorage.getItem('currentUser'));
  const location = useLocation();
  const dispatchCompare = useDispatch();

  const authState = useAuthState();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const nonCustomerUser = isSafetracesUser() || isPartnerUser();

  const [lsCompanyRequired, setLSCompanyRequired] = useState(false);
  const [companyChoiceModalOpen, setCompanyChoiceModalOpen] = useState(false);
  const [afterModalClose, setAfterModalClose] = useState(false);

  const showHeatmapCompare = useSelector(compareSelector).isShowMenu;

  useEffect(() => {
    const companyLSRequired = setCompanyLSRequirements();
    if (companyLSRequired && nonCustomerUser && !authState.viewCompanyId) {
      setCompanyChoiceModalOpen(true);
    }
    dispatchCompare(setShowMenu(getHeatmapCompare().length));
  }, []);

  useEffect(() => {
    if (nonCustomerUser && !authState.viewCompanyId) {
      setCompanyChoiceModalOpen(true);
    }
  }, [authState.viewCompanyId]);

  useEffect(() => {
    setCompanyLSRequirements();
  }, [location.pathname]);

  const setCompanyLSRequirements = () => {
    let currentPath = window.location.pathname;

    const matchedRoutes = matchRoutes(routes, currentPath);
    if (matchedRoutes) {
      currentPath = matchedRoutes[0].route.path;
    }

    const companyLSRequired =
      !~ROUTES_SAFETRACER_COMPANY_NO_CHOICE.indexOf(currentPath);

    setLSCompanyRequired(companyLSRequired);
    if (!companyLSRequired) {
      setAfterModalClose(false);
    }

    return companyLSRequired;
  };

  const closeCompanyChoiceModal = useCallback(() => {
    setCompanyChoiceModalOpen(false);
    setAfterModalClose(true);
  }, []);

  const onDrawerMouseOver = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const onDrawerMouseOut = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  return (
    <div style={{ display: 'flex' }}>
      <CssBaseline />
      <CustomAppBar
        userEmail={(userData && userData.email) || ''}
        userRoleName={(userData && userData.roleName) || ''}
        mountCompanySelect={nonCustomerUser}
        hideCompanyChoice={companyChoiceModalOpen || !lsCompanyRequired}
        openCompanySelect={afterModalClose}
        showHeatmapCompare={showHeatmapCompare}
      />
      <DrawerStyled
        variant="permanent"
        open={drawerOpen}
        onMouseOver={onDrawerMouseOver}
        onMouseOut={onDrawerMouseOut}
      >
        <LeftMenu />
      </DrawerStyled>
      {!!getLSViewCompanyId() && <LayoutContent routes={routes} />}
      {lsCompanyRequired && (
        <SelectCompanyModal
          open={companyChoiceModalOpen}
          handleClose={closeCompanyChoiceModal}
        />
      )}
    </div>
  );
}
