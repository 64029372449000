import {
  HEATMAP_GENERAL_BEHAVIOUR,
  HEATMAP_VERIFICATION_LIKE_BEHAVIOR,
} from 'Constants';
import React, { useEffect, useState } from 'react';

import SignificanceOptions from './SignificanceOptions';

import { copyColorsFilterObj } from 'Utils';

function SignificanceMenu({
  filterColors,
  handleFilterColors,
  testType,
  disabled,
}) {
  const [checkedObj, setCheckedObj] = useState();
  const [testTypesToMap, setTestTypesToMap] = useState(null);

  useEffect(() => {
    setCheckedObj(copyColorsFilterObj(filterColors));
  }, [filterColors]);

  useEffect(() => {
    const similarTestTypes =
      (!!~HEATMAP_GENERAL_BEHAVIOUR.indexOf(testType) &&
        HEATMAP_GENERAL_BEHAVIOUR) ||
      (!!~HEATMAP_VERIFICATION_LIKE_BEHAVIOR.indexOf(testType) &&
        HEATMAP_VERIFICATION_LIKE_BEHAVIOR);

    if (similarTestTypes) {
      setTestTypesToMap(similarTestTypes);
    }
  }, [testType]);

  const handleFilterChange = (filterObj) => {
    setCheckedObj(filterObj);
    handleFilterColors(filterObj);
  };

  const updateChecked = (valuesObj, color) => {
    const newCheckedObj = copyColorsFilterObj(checkedObj);

    for (let type of testTypesToMap) {
      const updatedColors = { ...checkedObj[type].colors };
      updatedColors[color] = valuesObj[color];

      newCheckedObj[type].colors = updatedColors;
    }

    handleFilterChange(newCheckedObj);
  };

  const commonCheckedObj = React.useMemo(() => {
    if (!testTypesToMap) return;

    const dataObj = copyColorsFilterObj(checkedObj);
    const result = {};

    for (let type of testTypesToMap) {
      if (dataObj[type] && dataObj[type].colors) {
        const colorsOfType = dataObj[type].colors;
        for (let color of Object.keys(colorsOfType)) {
          result[color] = result[color] || colorsOfType[color];
        }
      }
    }

    return result;
  }, [testTypesToMap, checkedObj]);

  if (!checkedObj || !testTypesToMap) return;

  return (
    <div style={{ minWidth: '160px' }}>
      <SignificanceOptions
        testType={testType}
        onChange={updateChecked}
        values={commonCheckedObj}
        disabled={disabled}
      />
    </div>
  );
}

export default SignificanceMenu;
