import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { List, Skeleton, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import FilterSearchColors from 'Components/Mix/FilterSearchColors';
import Title from 'Components/Mix/Title';
import ResultsItem from 'Components/Results/ResultsItem';
import ResultsItemSkeleton from 'Components/Results/ResultsItemSkeleton';
import ErrorMessage from 'Components/UI/ErrorMessage';
import NoDataDisplay from 'Components/UI/NoDataDisplay';

import { useAuthState } from 'Context';

import { colorsSelector, setColors } from 'Features/Colors/colorsSlice';
import {
  inProgressSelector,
  setInProgress,
} from 'Features/Filter/inProgressSlice';
import { setPageTitle } from 'Features/Main/mainSlice';
import {
  fetchBuildings,
  resultsSelector,
  setNotError,
} from 'Features/Results/resultsSlice';

import { filterItem } from 'Utils';

export default function Results() {
  const [initialLoad, setInitialLoad] = useState(true);
  const { buildings, isLoading, error } = useSelector(resultsSelector);
  const [search, setSearch] = useState('');
  const { colors } = useSelector(colorsSelector);
  const { inProgress } = useSelector(inProgressSelector);
  const dispatch = useDispatch();
  const authState = useAuthState();

  useEffect(() => {
    dispatch(fetchBuildings(colors, null, false, inProgress));
    dispatch(setPageTitle('Results'));
    setInitialLoad(false);
  }, []);

  useEffect(() => {
    dispatch(fetchBuildings(colors, null, true, inProgress));
  }, [authState.viewCompanyId]);

  const handleFilterChange = useCallback(
    (colorsObj) => {
      dispatch(setColors(colorsObj));
      dispatch(fetchBuildings(colorsObj, null, true, inProgress));
    },
    [colors, inProgress],
  );

  const handleInProgressSwitch = useCallback(
    (inProgressValue) => {
      dispatch(setInProgress(inProgressValue));
      dispatch(fetchBuildings(colors, null, true, inProgressValue));
    },
    [colors],
  );

  const handleCloseErrorAlert = () => {
    dispatch(setNotError());
  };

  const buildingsByPortfolios = useMemo(() => {
    if (!Array.isArray(buildings) || !buildings) return {};

    return buildings
      .filter((item) => filterItem(item, search))
      .reduce((acc, item) => {
        if (!acc[item.portfolioid]) {
          acc[item.portfolioid] = {
            portfolioName: item.portfolioname,
            buildings: [],
          };
        }
        acc[item.portfolioid].buildings.push(item);
        return acc;
      }, {});
  }, [search, buildings]);

  return (
    <Stack style={{ width: '100%' }}>
      {!!error && (
        <ErrorMessage
          message={error}
          handleCloseErrorAlert={handleCloseErrorAlert}
        />
      )}
      <Box sx={{ height: '60px' }}>
        <Title text="Results" />
      </Box>
      <FilterSearchColors
        filterColors={colors}
        inProgress={inProgress}
        handleSearch={setSearch}
        searchFieldLabel={'Search for Buildings'}
        handleFilterChange={handleFilterChange}
        handleInProgressSwitch={handleInProgressSwitch}
        disabled={isLoading || initialLoad}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
        }}
      >
        {isLoading || initialLoad ? (
          <ResultsPageSkeleton />
        ) : (
          <React.Fragment>
            {Object.entries(buildingsByPortfolios).length ? (
              Object.entries(buildingsByPortfolios).map(
                ([portfolioId, { portfolioName, buildings }]) => (
                  <Box
                    key={`portfolio-${portfolioId}`}
                    style={{ marginBottom: 10, width: '100%' }}
                  >
                    <Typography
                      variant="h4"
                      color={'primary'}
                      style={{ marginBottom: 10 }}
                    >
                      {portfolioName}
                    </Typography>
                    <List>
                      {buildings.map((building) => (
                        <ResultsItem
                          key={`building-${building.buildingid}`}
                          item={building}
                        />
                      ))}
                    </List>
                  </Box>
                ),
              )
            ) : (
              <Box sx={{ width: '100%', height: '500px', display: 'flex' }}>
                <NoDataDisplay />
              </Box>
            )}
          </React.Fragment>
        )}
      </Box>
    </Stack>
  );
}

function ResultsPageSkeleton() {
  return Array(3)
    .fill(0)
    .map((_, i) => (
      <Box
        key={`results-portfolio-skeleton-${_ + i}`}
        style={{ marginBottom: 10, width: '100%' }}
      >
        <Typography variant="h4" color="primary" style={{ marginBottom: 10 }}>
          <Skeleton width={820} />
        </Typography>
        <List>
          {Array(1)
            .fill(1)
            .map((_, i) => (
              <ResultsItemSkeleton
                key={`results-portfolio-skeleton-building-${_ + i}`}
              />
            ))}
        </List>
      </Box>
    ));
}
