import {
  HEATMAP_HEALTH_CARE_LIKE_BEHAVIOR,
  HEATMAP_VERIFICATION_LIKE_BEHAVIOR,
  TEST_TYPES,
} from 'Constants';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import resultsApi from '../../Api/results';
import testsApi from '../../Api/tests';
import BreadcrumbsCompare from '../../Components/Breadcrumbs/BreadcrumbsCompare';
import NewHeatmap from '../../Components/HeatMap/NewHeatmap';
import MapInteractionCustom from '../../Components/MapInteractionCustom';
import ColorsPaper from '../../Components/Mix/ColorsPaper';
import ScaleSliders from '../../Components/Mix/ScaleSliders';
import ErrorMessage from '../../Components/UI/ErrorMessage';
import Loading from '../../Components/UI/Loading';
import { ROUTE_RESULTS } from '../../Config/routes';
import {
  compareSelector,
  setFinishLoading,
  setLoadingFailed,
  setNotError,
  setShowMenu,
  setStartLoading,
} from '../../Features/Compare/compareSlice';
import {
  getCalcResultLabelFromVerLikeTest,
  getHeatmapCompare,
  getNewMapValueOnScaleChange,
  heatmapCalculateResult,
  heatmapDownloadFloorPlan,
  heatmapIntervals,
  isValidResponse,
  removeHeatmapCompare,
  renderTestInfoForHeatmap,
} from '../../Utils';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

export default function Compare() {
  const { isLoading, error } = useSelector(compareSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sliderCropBox, setSliderCropBox] = useState(0);
  const [mapValue, setMapValue] = useState({
    scale: 0.08,
    translation: { x: 0, y: 0 },
  });
  const [, setRenderAgain] = useState(null);
  const [heatmaps, setHeatmaps] = useState([]);
  const [sliderHeatmap, setSliderHeatmap] = useState(0.08);
  const [sliderSp, setSliderSp] = useState(35);
  const [disablePan, setDisablePan] = useState(false);
  const [resultsList, setResultsList] = useState([]);
  const ref = useRef(null);
  const mapRef = {};
  const minScaleHeatmap = 0.08;

  const fetchHeatMap = async () => {
    try {
      dispatch(setStartLoading());
      let newHeatmaps = [];
      let allResultsList = [];
      for (let heatmapId of getHeatmapCompare()) {
        const heatMaResponse = await resultsApi.getHeatMap(heatmapId);
        if (isValidResponse(heatMaResponse)) {
          const resultData = heatMaResponse.data.data;
          setSliderCropBox(parseFloat(resultData.scale));
          const heatmapList = resultData.heatmaps;
          allResultsList.push(resultData);
          for (let item of heatmapList) {
            item.url = await heatmapDownloadFloorPlan(
              item.floorplan,
              resultData.projectid,
            );
            if (resultData.testtype === TEST_TYPES.DILUTION) {
              const dimensions = {
                left: 999999999,
                top: 999999999,
                right: 0,
                bottom: 0,
              };
              item.center = {
                x: parseFloat(item.op[0].opcoordx),
                y: parseFloat(item.op[0].opcoordy),
              };
              item.sp.forEach((value) => {
                if (value.spcoordx <= dimensions.left) {
                  dimensions.left = parseFloat(value.spcoordx);
                }
                if (value.spcoordx >= dimensions.right) {
                  dimensions.right = parseFloat(value.spcoordx);
                }
                if (value.spcoordy <= dimensions.top) {
                  dimensions.top = parseFloat(value.spcoordy);
                }
                if (value.spcoordy >= dimensions.bottom) {
                  dimensions.bottom = parseFloat(value.spcoordy);
                }
              });
              item.dimensions = dimensions;
            }
          }
          // Also fetch the display numbers for the tests by project id
          const displayNumbersResponse =
            await testsApi.getDisplayNumbersProject(resultData.projectid);
          if (isValidResponse(displayNumbersResponse)) {
            resultData.displayNumbers = displayNumbersResponse.data.data;
          }

          newHeatmaps.push(resultData);
        }
      }
      setResultsList(allResultsList);
      setHeatmaps(newHeatmaps);
      dispatch(setFinishLoading());
    } catch (e) {
      dispatch(setLoadingFailed(e.message));
    }
  };

  useEffect(() => {
    fetchHeatMap();
  }, []);

  const handleImageLoaded = (
    naturalWidth,
    naturalHeight,
    setScaleOnLoad = false,
  ) => {
    if (setScaleOnLoad) {
      let newScale = minScaleHeatmap;
      let newMapValue = mapValue;
      newMapValue.scale = newScale;

      const { height } = ref.current.getBoundingClientRect();

      // Attempt to scale the image so that it takes up the around 75% of the box
      const scale = (height / naturalHeight) * 0.75;
      if (scale > newScale) {
        newScale = scale;
        newMapValue.scale = newScale;

        // Align in the middle
        const { width, height } = ref.current.getBoundingClientRect();
        const x = (width - naturalWidth * newScale) / 2;
        const y = (height - naturalHeight * newScale) / 2;

        newMapValue.translation = { x, y };
      }

      setMapValue(newMapValue);
      setSliderHeatmap(newScale);
      setTimeout(() => {
        setRenderAgain(true);
      }, 500);
    }
  };

  const handleCloseErrorAlert = () => {
    dispatch(setNotError());
  };

  const handleOnChangeMapInteraction = (value) => {
    setMapValue(value);
    setSliderHeatmap(value.scale);
  };

  const handleSlideHeatmapChange = (event, newValue) => {
    if (ref.current) {
      const { x, y } = mapValue.translation;
      const newMapValue =
        getNewMapValueOnScaleChange({ x, y }, newValue, mapValue.scale, ref) ||
        mapValue;
      setMapValue(newMapValue);
    }
    setSliderHeatmap(newValue);
  };

  const handleSlideSpChange = (event, newValue) => {
    setSliderSp(newValue);
  };

  const handleRemoveHeatmap = async (heatmapId) => {
    removeHeatmapCompare(heatmapId);
    const heatmapsCount = getHeatmapCompare().length;
    dispatch(setShowMenu(heatmapsCount));
    await fetchHeatMap();
    if (!heatmapsCount) {
      setTimeout(() => {
        navigate(generatePath(ROUTE_RESULTS));
      }, 500);
    }
  };

  const handleOutHeatmap = () => {
    setDisablePan(true);
  };

  const handleOverToHeatmap = () => {
    setDisablePan(false);
  };

  const renderInfoAndResult = (heatmap) => {
    if (!heatmap) return null;
    if (!!~HEATMAP_HEALTH_CARE_LIKE_BEHAVIOR.indexOf(heatmap.testtype))
      return (
        <Grid item xs={10}>
          {renderTestInfoForHeatmap(heatmap)}
        </Grid>
      );
    return (
      <Grid item xs={10}>
        {renderTestInfoForHeatmap(heatmap)}
        <Typography
          color="text.secondary"
          variant="subtitle1"
          sx={{ mb: 1, mt: 1 }}
        >
          Calculated Result
        </Typography>
        <Typography color="text.primary">
          {(!!~HEATMAP_VERIFICATION_LIKE_BEHAVIOR.indexOf(heatmap.testtype) &&
            getCalcResultLabelFromVerLikeTest(heatmap)) ||
            heatmapCalculateResult(heatmap)}
        </Typography>
      </Grid>
    );
  };

  return (
    <>
      {!!error && (
        <ErrorMessage
          message={error}
          handleCloseErrorAlert={handleCloseErrorAlert}
        />
      )}
      {!!isLoading && <Loading />}
      <div style={{ width: '100%' }}>
        {!!heatmaps &&
          heatmaps.map((heatmap, heatmapIndex) => (
            <Box
              sx={{ display: 'flex', justifyContent: 'flex-start' }}
              key={heatmapIndex}
            >
              <Box sx={{ mb: 4, mr: 3 }}>
                {heatmapIndex === 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mb: 3,
                    }}
                  >
                    <BreadcrumbsCompare />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'start',
                        mb: -3,
                      }}
                    >
                      <ScaleSliders
                        handleSlideHeatmapChange={handleSlideHeatmapChange}
                        sliderHeatmap={sliderHeatmap}
                        handleSlideSpChange={handleSlideSpChange}
                        sliderSp={sliderSp}
                        minScaleHeatmap={minScaleHeatmap}
                      />
                    </Box>
                  </Box>
                )}
                <Typography variant="h6" sx={{ mb: 1 }}>
                  {heatmap.testname + ' / ' + heatmap.heatmapname}
                </Typography>
                <Box
                  ref={ref}
                  style={{
                    height: '40vh',
                    width: '60vw',
                    border: '1px solid #444',
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    onMouseOver={handleOverToHeatmap}
                    onMouseOut={handleOutHeatmap}
                  >
                    <MapInteractionCustom
                      leftPadding={'30px'}
                      mapRef={mapRef}
                      scale={sliderHeatmap}
                      value={mapValue}
                      onChange={(value) => handleOnChangeMapInteraction(value)}
                      minScale={minScaleHeatmap}
                      maxScale={2}
                      disablePan={disablePan}
                    >
                      <div style={{ position: 'relative' }}>
                        <div style={{ width: '100%' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              flexWrap: 'nowrap',
                            }}
                          >
                            {heatmap.heatmaps.map((item, index) => (
                              <NewHeatmap
                                key={heatmapIndex + index}
                                activeFloorPlanUrl={item.url}
                                onImageLoaded={(width, height) =>
                                  // Set scale and positioning for the first heatmap only
                                  handleImageLoaded(
                                    width,
                                    height,
                                    heatmapIndex === 0,
                                  )
                                }
                                resultsData={heatmap}
                                heatMap={item}
                                sliderSp={sliderSp}
                                mapValue={mapValue}
                                isDownload={false}
                                showLast={heatmap.heatmaps.length - 1 === index}
                                interval={
                                  heatmapIntervals(resultsList[heatmapIndex])[
                                    index
                                  ]
                                }
                                sliderCropBox={sliderCropBox}
                                showSpOpValue={true}
                                scale={sliderHeatmap}
                              />
                            ))}
                          </Box>
                        </div>
                      </div>
                    </MapInteractionCustom>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ mt: 4 }}>
                {heatmapIndex === 0 && <Box sx={{ height: 70 }} />}
                <Grid container spacing={2}>
                  {renderInfoAndResult(heatmap)}
                  <Grid item xs={2}>
                    <Box sx={{ ml: 2 }}>
                      <ColorsPaper color={heatmap.significance ?? ''} />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      color="text.secondary"
                      variant="subtitle1"
                      sx={{ mb: 1 }}
                    >
                      Comment
                    </Typography>
                    <Typography color="text.primary">
                      {heatmap.heatmapcomments ?? '---'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <IconButton
                      sx={{
                        cursor: 'pointer',
                        float: 'right',
                      }}
                      onClick={() => handleRemoveHeatmap(heatmap.heatmapid)}
                    >
                      <Tooltip title="Remove" placement="top">
                        <RemoveCircleIcon sx={{ color: 'red' }} />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ))}
      </div>
    </>
  );
}
