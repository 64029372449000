import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Stack, Typography } from '@mui/material';

import { usePortfolioContext } from 'Components/Portfolios/context';

const PortfolioSummary = ({ portfolio }) => {
  const { openEditModal } = usePortfolioContext();
  const handleTitleChangeClick = (e) => {
    e.stopPropagation();
    openEditModal({
      type: 'portfolio',
      portfolioId: portfolio.portfolioid,
    });
  };
  return (
    <Box width="100%">
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        marginTop={1}
        pr={2}
        width="100%"
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h5" component="h3" paddingBottom={1}>
            {portfolio.portfolioname}
          </Typography>
          <IconButton
            aria-label="edit portfolio title"
            size="small"
            onClick={handleTitleChangeClick}
          >
            <EditIcon />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  );
};

export default PortfolioSummary;
