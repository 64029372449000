import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { hasPermission } from '../Config/roles';
import { DrawerHeaderStyled, MainStyled } from './layoutStyles';

const LayoutContent = ({ routes }) => {
  const RoutesMemo = React.useMemo(
    () =>
      routes
        .filter((item) => hasPermission(item))
        .map((route) => {
          const isComponentProps =
            route.componentProps && Object.keys(route.componentProps)?.length;
          const props = (isComponentProps && route.componentProps) || {};
          return (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              element={<route.component {...props} />}
            />
          );
        }),
    [routes],
  );

  return (
    <MainStyled>
      <DrawerHeaderStyled />
      <Routes>{RoutesMemo}</Routes>
    </MainStyled>
  );
};

export default React.memo(LayoutContent);
