import React from 'react';

import ChartTitle from './ChartTitle';
import ChartProjects from './Charts/ChartProjects';
import ChartSamplesByTestTypes from './Charts/ChartSamplesByTestTypes';
import ChartTestsByTypes from './Charts/ChartTestsByTypes';
import MainOverviewFilters from './MainOverviewFilters';
import './styles.css';

import { Box, Stack } from '@mui/material';

export default function MainOverview() {
  const renderChartDescription = (text) => {
    return (
      <Box className="noselect" sx={{ marginBottom: 1, marginRight: 1, mt: 2 }}>
        {text}
      </Box>
    );
  };

  return (
    <Stack gap={2} direction="column">
      <MainOverviewFilters />
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-around',
          p: 2,
          m: 2,
        }}
      >
        <Stack direction="column" alignItems="center">
          <ChartTitle titleText="Projects" chartId="main-overview-projects" />
          <Box
            id="main-overview-projects"
            sx={{ width: '100%', maxWidth: '400px', height: '340px' }}
          >
            <ChartProjects />
          </Box>
          {renderChartDescription(
            'Number of projects completed within a given time period.',
          )}
        </Stack>
        <Stack direction="column" alignItems="center">
          <ChartTitle titleText="Tests" chartId="main-overview-tests" />
          <Box
            id="main-overview-tests"
            sx={{ width: '100%', maxWidth: '400px', height: '340px' }}
          >
            <ChartTestsByTypes />
          </Box>
          {renderChartDescription(
            'Number of tests completed within a given time period.',
          )}
        </Stack>
      </Stack>
      <Stack direction="column" alignItems="center">
        <ChartTitle titleText="Samples" chartId="main-overview-samples" />
        <Box
          id="main-overview-samples"
          sx={{ width: '100%', maxWidth: '400px', height: '340px' }}
        >
          <ChartSamplesByTestTypes />
        </Box>
        {renderChartDescription(
          'Number of samples within a given time period.',
        )}
      </Stack>
    </Stack>
  );
}
