import React, { useEffect, useMemo, useState } from 'react';

import { Box, Grid, MenuItem, Stack, TextField } from '@mui/material';

import InputSearch from 'Components/Mix/InputSearch';
import Title from 'Components/Mix/Title';
import SiteContractItem from 'Components/TestPackages/SiteContractItem';
import {
  getPartnersFromLS,
  sortAndFilterSiteContracts,
} from 'Components/TestPackages/utils';
import NoDataDisplay from 'Components/UI/NoDataDisplay';
import SkeletonGroupLoading from 'Components/UI/SkeletonGroupLoading';

const sortTypes = [
  'End date (descending)',
  'End date (ascending)',
  'Start date (descending)',
  'Start date (ascending)',
];

const SiteContractList = ({ loading, siteContracts }) => {
  const [searchTerm, setSearchTerm] = useState(null);
  const [sortType, setSortType] = useState('End date (descending)');
  const [selectedPartner, setSelectedPartner] = useState(-1);
  const [loadingImitation, setLoadingImitation] = useState(false);

  const loadingView = loadingImitation || loading;

  const partners = useMemo(() => {
    return getPartnersFromLS();
  }, []);

  const [contracts, setContracts] = useState(
    sortAndFilterSiteContracts(
      siteContracts,
      sortType,
      selectedPartner,
      searchTerm,
    ),
  );

  useEffect(() => {
    setContracts(
      sortAndFilterSiteContracts(
        siteContracts,
        sortType,
        selectedPartner,
        searchTerm,
      ),
    );
  }, [siteContracts, searchTerm, sortType, selectedPartner]);

  const filtersAndSortersSection = () => {
    return (
      <Stack direction="row" spacing={2}>
        <TextField
          select
          value={sortType ?? ''}
          label="Sort by"
          size="medium"
          onChange={(e) => {
            setLoadingImitation(true);
            setSortType(e.target.value);
            setTimeout(() => setLoadingImitation(false), 500);
          }}
          sx={{ width: '220px' }}
          variant="standard"
          disabled={loadingView}
        >
          {sortTypes.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          value={selectedPartner ?? -1}
          label="By partner"
          size="medium"
          onChange={(e) => {
            setLoadingImitation(true);
            setSelectedPartner(e.target.value);
            setTimeout(() => setLoadingImitation(false), 500);
          }}
          sx={{ minWidth: '150px' }}
          variant="standard"
          disabled={loadingView}
        >
          <MenuItem key="key" value={-1}>
            All
          </MenuItem>
          {partners?.map((p) => (
            <MenuItem key={p.companyid} value={p.companyid}>
              {p.companyname}
            </MenuItem>
          ))}
        </TextField>
        <Box sx={{ w: '240px', display: 'flex', alignItems: 'end' }}>
          <InputSearch
            id="site-contracts-search-input"
            placeholder="Search"
            disabled={loadingView}
            onChange={(val) => {
              setLoadingImitation(true);
              setSearchTerm(val);
              setTimeout(() => {
                setLoadingImitation(false);
              }, 100);
              setTimeout(() => {
                const el = document.getElementById(
                  'site-contracts-search-input',
                );
                if (el) {
                  el.focus();
                }
              }, 105);
            }}
          />
        </Box>
      </Stack>
    );
  };

  return (
    <Stack spacing={2}>
      <Title text="Site contracts" />
      {filtersAndSortersSection()}
      <Grid container spacing={1}>
        {loadingView ? <SkeletonGroupLoading /> : null}
        {!loadingView && Array.isArray(contracts)
          ? contracts.map((sc) => (
              <SiteContractItem
                key={`${sc.sitecontractid}-${sc.startdate}-${sc.enddate}-${sc.sitecontractname}`}
                siteContract={sc}
              />
            ))
          : null}
        {!loadingView &&
        (!Array.isArray(contracts) || contracts.length === 0) ? (
          <NoDataDisplay title="No test contracts found" />
        ) : null}
      </Grid>
    </Stack>
  );
};

export default SiteContractList;
