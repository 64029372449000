import { COLORS, LS_TOKEN } from 'Constants';
import { matchRoutes } from 'react-router';

import authentication from '../Api/authentication';
import routes, {
  ROUTES_COMPANY_CHANGE_NO_REDIRECT,
  ROUTES_COMPANY_CHANGE_REDIRECTION_CONFIG,
  ROUTE_DASHBOARD,
  ROUTE_HOME,
} from '../Config/routes';
import { User } from './Classes/User';
import {
  eventCompaniesListDidUpdate,
  eventCompaniesListUpdateHandled,
  eventLoginError,
  eventLoginNeedMFA,
  eventLoginSuccess,
  eventLogout,
  eventRequestLogin,
  eventViewCompanyChange,
} from './reducer';

import {
  setPartnerViewCompanyId,
  setPartnerViewCompanyName,
  setSafetraceCompanyId,
  setSafetraceCompanyName,
} from 'Utils';

export async function login(dispatch, loginPayload, google = false) {
  try {
    dispatch(eventRequestLogin());
    const response = !google
      ? await authentication.login(loginPayload)
      : await authentication.loginGoogle(loginPayload);
    let data = response.data;

    if (data.success) {
      dispatch(eventLoginSuccess(data.data));

      const user = new User();
      user.saveData({
        ...data.data.user,
        access_token: data.data.token,
        id: data.data.user.userId,
      });
      return data;
    }
    if (
      typeof data.error !== 'undefined' &&
      data.error.message === 'MFA failed'
    ) {
      dispatch(eventLoginNeedMFA(data));
      return data;
    }

    dispatch(eventLoginError(data.error.message));
  } catch (error) {
    dispatch(eventLoginError('Server error: ' + error.message));
  }
}

export const loginGoogle = async (dispatch, googleData) => {
  let requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      token: googleData.credential,
    }),
    hash: googleData.hash,
  };

  if (typeof googleData.code !== 'undefined') {
    requestOptions = { ...requestOptions, code: googleData.code };
  }

  return login(dispatch, requestOptions, true);
};

export async function logout(dispatch) {
  await authentication.logout();
  dispatch(eventLogout());
  localStorage.removeItem(LS_TOKEN);
  localStorage.removeItem(COLORS);
  const user = new User();
  user.remove();
}

export function changeSafetracesViewCompany(
  dispatch,
  companyId,
  companyName,
  force = false,
  navigate,
) {
  const _companyId = parseInt(companyId, 10);
  if (_companyId || force) {
    setSafetraceCompanyId(_companyId);
    setSafetraceCompanyName(companyName);
    dispatch(eventViewCompanyChange(_companyId));
    /**
     * Redirect only when user interacts with resources new selected company doesn't have.
     * Shouldn't redirect when user is in main pages e.g 'users'.
     * TODO: replace redirection to default route with slightly more complex logic (single
     * portfolio => portfolios, single contact => contacts, etc)
     */
    let currentRoute = window.location.pathname;
    const matchedRoutes = matchRoutes(routes, currentRoute);
    if (matchedRoutes) {
      currentRoute = matchedRoutes[0].route.path;
    }

    if (!~ROUTES_COMPANY_CHANGE_NO_REDIRECT.indexOf(currentRoute)) {
      const redirectTo =
        ROUTES_COMPANY_CHANGE_REDIRECTION_CONFIG[currentRoute] ||
        ROUTE_DASHBOARD;

      navigate(redirectTo);
    }
  }
}
export function changePartnerViewCompany(
  dispatch,
  companyId,
  companyName,
  force = false,
  navigate,
) {
  const _companyId = parseInt(companyId, 10);
  if (_companyId || force) {
    setPartnerViewCompanyId(_companyId);
    setPartnerViewCompanyName(companyName);
    dispatch(eventViewCompanyChange(_companyId));
    /**
     * Redirect only when user interacts with resources new selected company doesn't have.
     * Shouldn't redirect when user is in main pages e.g 'users'.
     * TODO: replace redirection to default route with slightly more complex logic (single
     * portfolio => portfolios, single contact => contacts, etc)
     */
    const currentRoute = window.location.pathname;
    if (!~ROUTES_COMPANY_CHANGE_NO_REDIRECT.indexOf(currentRoute)) {
      navigate(ROUTE_HOME);
    }
  }
}

export function alertCompaniesListDidUpdate(dispatch) {
  dispatch(eventCompaniesListDidUpdate());
}

export function alertCompaniesListUpdateHandled(dispatch) {
  dispatch(eventCompaniesListUpdateHandled());
}
