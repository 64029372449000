import React from 'react';
import { useDispatch } from 'react-redux';

import { Stack } from '@mui/material';

import Title from 'Components/Mix/Title';
import PortfolioActions from 'Components/Portfolios/ModalButtons/PortfolioActions';
import PortfolioList from 'Components/Portfolios/PortfolioList';
import PortfolioSnackbar from 'Components/Portfolios/PortfolioSnackbar';
import { PortfolioContextProvider } from 'Components/Portfolios/context';

import { useAuthState } from 'Context';

import { setPageTitle } from 'Features/Main/mainSlice';

export default function PortfoliosNew() {
  const authState = useAuthState();
  const dispatch = useDispatch();
  dispatch(setPageTitle('Portfolios - Admin'));

  return (
    <PortfolioContextProvider>
      <PortfolioSnackbar />
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Title text={'Portfolios'} />
        <PortfolioActions />
      </Stack>
      <PortfolioList viewCompanyId={authState.viewCompanyId} />
    </PortfolioContextProvider>
  );
}
