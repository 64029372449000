import { ERROR_AUTOCLOSE_TIMEOUT_MS } from 'Constants';
import React, { useState } from 'react';

import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import users from 'Api/users';

import ErrorMessage from 'Components/UI/ErrorMessage';

import { useIsMounted } from 'Context';

import { isValidResponse } from 'Utils';

export default function ApproveUserBtn({
  afterApprove,
  userEmail,
  roles,
  accesses,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [approveData, setApproveData] = useState({});
  const [approveInProgress, setApproveInProgress] = useState(false);
  const [mainError, setMainError] = useState('');
  const mounted = useIsMounted();

  const handleApprove = async () => {
    setApproveInProgress(true);
    let isSuccess = false;
    try {
      if (!approveData.roleName || !approveData.accessLevel || !userEmail) {
        throw new Error('Invalid data');
      }
      approveData.email = userEmail;
      const response = await users.approveUser(approveData);
      isSuccess = isValidResponse(response);
      if (!isSuccess) {
        throw new Error(response?.data?.error?.message);
      }
    } catch (err) {
      console.log('Reject user error: ', err);
      setMainError(err?.message || `Couldn't reject user`);
      setTimeout(() => setMainError(''), ERROR_AUTOCLOSE_TIMEOUT_MS);
    } finally {
      if (mounted.current) {
        setApproveInProgress(false);
      }
      if (isSuccess && typeof afterApprove === 'function') {
        if (mounted.current) {
          setModalOpen(false);
        }
        afterApprove();
      }
    }
  };

  return (
    <div>
      <Button
        onClick={() => setModalOpen(true)}
        disableFocusRipple
        variant="contained"
        size="small"
      >
        Approve
      </Button>
      <Dialog open={modalOpen}>
        <DialogTitle sx={{ textAlign: 'center' }}>
          Set role and access level to approve user
        </DialogTitle>
        <DialogContent sx={{ width: 400 }}>
          <Grid container>
            <TextField
              select
              variant="outlined"
              label="Role"
              defaultValue={''}
              required
              onChange={(event) =>
                setApproveData({
                  ...approveData,
                  roleName: event.target.value,
                })
              }
              margin="normal"
              fullWidth
            >
              {roles.map((role) => (
                <MenuItem key={role.roleid} value={role.rolename}>
                  <Typography>{role.rolename}</Typography>
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              variant="outlined"
              label="Access"
              defaultValue={''}
              required
              onChange={(event) =>
                setApproveData({
                  ...approveData,
                  accessLevel: event.target.value,
                })
              }
              margin="normal"
              fullWidth
            >
              {accesses.map((access) => (
                <MenuItem key={access.permissionid} value={access.accesslevel}>
                  <Typography>{access.accesslevel}</Typography>
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            disabled={approveInProgress}
            onClick={() => {
              setModalOpen(false);
              setApproveData({});
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApprove}
            disabled={approveInProgress}
          >
            Approve
          </Button>
        </DialogActions>
      </Dialog>
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
    </div>
  );
}
