import React, { useEffect, useState } from 'react';

import DownloadEACHDataBtn from './Buttons/DownloadEACHDataBtn';
import CalculatorDataListItem from './Mix/CalculatorDataListItem';
import CalculatorDataAddEditModal from './Modals/CalculatorDataAddEditModal';
import './styles.css';

import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Divider,
  IconButton,
  List,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

import buildingsApi from 'Api/buildings';

import DeleteDialog from 'Components/Dialogs/DeleteDialog';
import ErrorMessage from 'Components/UI/ErrorMessage';

import { isViewer } from 'Config/roles';

import { useIsMounted } from 'Context';

import { getErrorMessageFromResponse, isValidResponse } from 'Utils';

const CalculatorEACH = (props) => {
  const {
    isLoading,
    buildingData,
    buildingId,
    afterUpdate,
    buildingName,
    isPartnerEditor,
  } = props;

  const dataKeys = [
    'name',
    'floorServed',
    'servedArea',
    'servedCeilingHeight',
    'totalSupplyAir',
    'totalOutdoorAir',
    'filterMervRating',
    'airDistributionEffectiveness',
  ];

  const [data, setData] = useState(undefined);
  const [errorMsg, setErrorMsg] = useState('');
  const mounted = useIsMounted();

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editModalType, setEditModalType] = useState('');
  const [editModalData, setEditModalData] = useState(null);
  const [editModalAction, setEditModalAction] = useState(null);

  const [isFetching, setIsFetching] = useState(false);
  const [deleteAction, setDeleteAction] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      formatData(buildingData);
    }
    if (isLoading) {
      setData(null);
    }
  }, [buildingData, isLoading]);

  const formatData = (dataObj) => {
    if (!dataObj || typeof dataObj === 'string') {
      setData([]);

      return;
    }

    const arrToMap = Array.isArray(dataObj) ? dataObj : [dataObj];

    const newData = arrToMap.map((item) => {
      return dataKeys.reduce((res, key) => {
        if (key === 'filterMervRating' && Array.isArray(item[key])) {
          res[key] = item[key][0];
          return res;
        }

        res[key] = '';
        if (
          item[key] &&
          (typeof parseInt(item[key], 10) === 'number' ||
            ['floorServed', 'name'].includes(key))
        ) {
          res[key] = item[key];
        }

        return res;
      }, {});
    });

    if (mounted.current) {
      setData(newData);
    }
  };

  const ListSkeleton = React.useMemo(() => {
    return Array(6)
      .fill(1)
      .map((_, key) => <Skeleton key={key} />);
  }, []);

  const updateCalculatorData = async (values) => {
    let success = false;
    setIsFetching(true);
    try {
      const dataForCalculations = values;

      const updateBuilding = await buildingsApi.updateOneBuilding(buildingId, {
        dataForCalculations,
      });

      if (isValidResponse(updateBuilding)) {
        if (typeof afterUpdate === 'function') {
          afterUpdate();
        }
        success = true;
      } else throw new Error(getErrorMessageFromResponse(updateBuilding));
    } catch (err) {
      console.log(`onFormSubmit Error: `, err);
      if (mounted.current) {
        setErrorMsg(err.message);
      }
    } finally {
      if (mounted.current) {
        setIsFetching(false);
      }
      return success;
    }
  };

  const addConfiguration = async (values) => {
    if (checkIfConfigurationExist(values)) {
      setErrorMsg('Such configuration already exist');
      return false;
    }

    const newData = [...data, values];

    const success = await updateCalculatorData(newData);
    if (success) {
      handleEditModalClose();
    }

    return success;
  };

  const deleteConfiguration = async (configuration) => {
    const configurations = data.filter(
      (conf) => !isTheSameConfiguration(configuration, conf),
    );

    const success = await updateCalculatorData(configurations);
    if (success) {
      closeConfirmDelete();
    }

    return success;
  };

  const editConfiguration = async (values, oldConfiguration) => {
    if (checkIfConfigurationExist(values, true)) {
      setErrorMsg('Such configuration already exist');
      return false;
    }

    const configurations = data.map((conf) =>
      isTheSameConfiguration(oldConfiguration, conf) ? values : conf,
    );

    const success = await updateCalculatorData(configurations);
    if (success) {
      handleEditModalClose();
    }
    return success;
  };

  const checkIfConfigurationExist = (values, strict = false) => {
    for (let configuration of data) {
      if (isTheSameConfiguration(configuration, values, strict)) {
        return true;
      }
    }
    return false;
  };

  const isTheSameConfiguration = (objA, objB, strict = false) =>
    dataKeys
      .map((key) => {
        if (['floorServed', 'name'].includes(key)) {
          if (strict) {
            const valA = objA[key];
            const valB = objB[key];
            return !!(valA && valB && valA === valB);
          }
          return true;
        }
        return parseFloat(objA[key], 10) === parseFloat(objB[key], 10);
      })
      .reduce((res, isSame) => res && isSame, true);

  const handleEditModalOpen = (type = 'add', formData) => {
    setEditModalType(type);
    setEditModalData(formData || null);

    const submitAction = () =>
      type === 'edit'
        ? (values) => editConfiguration(values, formData)
        : addConfiguration;

    setEditModalAction(submitAction);
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setEditModalAction(null);
    setEditModalData(null);
    setEditModalType('');
  };

  const openConfirmDelete = (item) => {
    const action = () => () => deleteConfiguration(item);
    setDeleteAction(action);
    setConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setDeleteAction(null);
    setConfirmDelete(false);
  };

  return (
    <>
      <ErrorMessage
        message={errorMsg}
        handleCloseErrorAlert={() => setErrorMsg('')}
      />
      <Box className="buildingDetailsSection">
        <Stack
          className="buildingDetailsSection-header-container"
          direction="row"
        >
          <Typography
            className="buildingDetailsSection-header-container--text"
            variant="h5"
            sx={{ mr: 2 }}
          >
            eACH calculator
          </Typography>
          {!isPartnerEditor && !isViewer() && (
            <IconButton
              color="primary"
              disabled={isFetching || isLoading}
              onClick={() => handleEditModalOpen()}
            >
              <AddIcon />
            </IconButton>
          )}
          {!isViewer() && (
            <DownloadEACHDataBtn
              disabled={isFetching || isLoading || !data?.length}
              buildingData={buildingData}
              buildingId={buildingId}
              buildingName={buildingName}
            />
          )}
        </Stack>
        <Box className="buildingDetailsSection-form-container">
          {isLoading && ListSkeleton}
          {!isLoading && !!data?.length && (
            <List>
              {data.map((dataPiece, i) => (
                <React.Fragment key={i}>
                  <CalculatorDataListItem
                    key={i}
                    isLoading={isLoading || isFetching}
                    data={dataPiece}
                    buildingId={buildingId}
                    handleEdit={() => handleEditModalOpen('edit', dataPiece)}
                    handleDelete={() => openConfirmDelete(dataPiece)}
                    isPartnerEditor={isPartnerEditor}
                  />
                  {i !== data.length - 1 && (
                    <Divider
                      sx={{
                        width: '90%',
                        display: 'flex',
                        ml: 'auto',
                        mr: 'auto',
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
            </List>
          )}
          {!isLoading && !!(data && !data.length) && (
            <Typography>There is no configurations yet.</Typography>
          )}
        </Box>
      </Box>
      <CalculatorDataAddEditModal
        open={editModalOpen}
        type={editModalType}
        handleClose={handleEditModalClose}
        handleSubmit={editModalAction}
        data={editModalData}
      />
      <DeleteDialog
        open={confirmDelete}
        handleClose={closeConfirmDelete}
        handleDelete={deleteAction}
        isLoading={isFetching || isLoading}
        itemName={'configuration'}
      />
    </>
  );
};

export default CalculatorEACH;
