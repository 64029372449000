import { ERROR_AUTOCLOSE_TIMEOUT_MS } from 'Constants';
import React, { useState } from 'react';

import projects from 'Api/projects';

import DeleteBorderButton from 'Components/Buttons/DeleteBorderButton';
import DeleteDialog from 'Components/Dialogs/DeleteDialog';
import ErrorMessage from 'Components/UI/ErrorMessage';

import { useIsMounted } from 'Context';

import { getErrorMessageFromResponse, isValidResponse } from 'Utils';

export default function DeleteProjectBtn({
  displayOnly = false,
  afterDelete,
  projectId,
  projectName,
  disabled,
  disabledReason,
  hidden,
}) {
  const buttonDisabledProps = disabled
    ? {
        disabled,
        tooltipText: !!disabled && disabledReason,
      }
    : {};

  const mounted = useIsMounted();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [mainError, setMainError] = useState('');

  const deleteProject = async () => {
    setDeleteInProgress(true);

    let isSuccess = false;
    try {
      const response = await projects.deleteOneProject(projectId);

      isSuccess = isValidResponse(response);
      if (!isSuccess) {
        throw new Error(getErrorMessageFromResponse(response));
      }
    } catch (err) {
      console.error('Delete project error: ', err);

      if (mounted.current) {
        setMainError(err?.message || `Couldn't delete project`);
      }
      setTimeout(() => {
        if (mounted.current) {
          setMainError('');
        }
      }, ERROR_AUTOCLOSE_TIMEOUT_MS);
    } finally {
      if (mounted.current) {
        setDeleteInProgress(false);
        setDialogOpen(false);
      }
      if (isSuccess && typeof afterDelete === 'function') {
        afterDelete();
      }
    }
  };

  return displayOnly ? (
    <DeleteBorderButton
      sx={{ border: 0, visibility: hidden ? 'hidden' : '' }}
      variant="text"
    />
  ) : (
    <>
      <DeleteDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        title="Delete project"
        itemName={projectName || ''}
        isLoading={deleteInProgress}
        handleDelete={deleteProject}
      />
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
      <DeleteBorderButton
        variant="text"
        clickAction={() => setDialogOpen(true)}
        sx={{ border: 0, visibility: hidden ? 'hidden' : '' }}
        {...buttonDisabledProps}
      />
    </>
  );
}
