import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';

import AddBoxIcon from '@mui/icons-material/AddBox';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import tagsApi from 'Api/tags';
import usersApi from 'Api/users';

import { TagList } from 'Components/Tags/TagList';
import { PLATE_KIND_LIST, TAG_LIST } from 'Components/Tags/config';

import { getErrorMessageFromResponse, isValidResponse } from 'Utils';

const TagsComponent = () => {
  const [tags, setTags] = useState([]);
  const [users, setUsers] = useState([]);
  const [tagProperties, setTagProperties] = useState([]);
  const [tagAddModal, setTagAddModal] = useState(false);
  const [tagName, setTagName] = useState('');
  const [tagPlateKind, setTagPlateKind] = useState(null);
  const [tagIsSterile, setTagIsSterile] = useState(false);
  const [tagSlope, setTagSlope] = useState(null);
  const [tagIntercept, setTagIntercept] = useState(null);
  const [tagLotNumber, setTagLotNumber] = useState(null);
  const [tagManufactureDate, setTagManufactureDate] = useState(null);
  const [tagExpirationDate, setTagExpirationDate] = useState(null);
  const [tagManufacturedBy, setTagManufacturedBy] = useState(null);
  const [tagNotes, setTagNotes] = useState(null);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');

  /**
   * Open modal for adding new tag
   */
  const handleTagAddModal = () => {
    setTagAddModal(!tagAddModal);
  };

  /**
   * Editing details about tag
   *
   * @param field
   * @param value
   */
  const handleChange = (field, value) => {
    switch (field) {
      case 'tag':
        setTagName(value.target.value);
        const plateKind = value.target.value.split('-')[1][0];
        if (PLATE_KIND_LIST.includes(plateKind)) {
          setTagPlateKind(plateKind);
        }
        break;
      case 'platekind':
        setTagPlateKind(value.target.value);
        break;
      case 'tagslope':
        setTagSlope(value.target.value);
        break;
      case 'tagintercept':
        setTagIntercept(value.target.value);
        break;
      case 'taglotnumber':
        setTagLotNumber(value.target.value);
        break;
      case 'manufacturedate':
        if (value === null) {
          setTagManufactureDate(null);
        } else {
          setTagManufactureDate(format(value, 'yyyy-MM-dd'));
        }
        break;
      case 'expirationdate':
        if (value === null) {
          setTagExpirationDate(null);
        } else {
          setTagExpirationDate(format(value, 'yyyy-MM-dd'));
        }
        break;
      case 'manufacturedby':
        setTagManufacturedBy(value.target.value);
        break;
      case 'tagnotes':
        setTagNotes(value.target.value);
        break;
      default:
        break;
    }
  };

  /**
   * Create new tag
   *
   * @returns {Promise<void>}
   */
  const handleTagAddSubmit = async () => {
    const response = await tagsApi.addOneTag(
      tagPlateKind,
      tagName,
      tagManufactureDate,
      tagExpirationDate,
      tagSlope,
      tagIntercept,
      tagNotes,
      tagLotNumber,
      tagManufacturedBy,
      tagIsSterile,
    );

    if (isValidResponse(response)) {
      const { success } = response.data;
      if (success) {
        // Refresh list of all tags
        await fetchTags();
        // Refresh list of all tag properties to be downloaded
        await fetchTagProperties();

        // Set defaults to null
        setTagName(null);
        setTagPlateKind(null);
        setTagSlope(null);
        setTagIntercept(null);
        setTagLotNumber(null);
        setTagManufactureDate(null);
        setTagExpirationDate(null);
        setTagManufacturedBy(null);
        setTagNotes(null);
        // Close Tag Add modal
        setTagAddModal(false);
      } else {
        let msgAlert = 'Something went wrong';
        const { error } = response.data;
        if (error && error.message && typeof error.message === 'string') {
          msgAlert = error.message;
        }
        handleAlert(true, msgAlert);
      }
    } else {
      const errorMessage = getErrorMessageFromResponse(response);
      handleAlert(true, errorMessage);
    }
  };

  /**
   * Get list of all tags
   *
   * @returns {Promise<void>}
   */
  const fetchTags = async () => {
    const response = await tagsApi.getAllTags();
    if (isValidResponse(response)) {
      setTags(response.data.data);
    } else {
      const errorMessage = getErrorMessageFromResponse(response);
      setAlertMsg(errorMessage);
      setAlertOpen(true);
    }
  };

  /**
   * Get list of all users
   *
   * @returns {Promise<void>}
   */
  const fetchUsers = async () => {
    const response = await usersApi.getUsers();
    if (isValidResponse(response)) {
      setUsers(response.data.data);
    } else {
      const errorMessage = getErrorMessageFromResponse(response);
      setAlertMsg(errorMessage);
      setAlertOpen(true);
    }
  };

  /**
   * Get list of all tags
   *
   * @returns {Promise<void>}
   */
  const fetchTagProperties = async () => {
    const response = await tagsApi.getAllTagProperties();
    if (isValidResponse(response)) {
      setTagProperties(response.data.data);
    } else {
      const errorMessage = getErrorMessageFromResponse(response);
      setAlertMsg(errorMessage);
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchTags();
    fetchTagProperties();
  }, []);

  const handleAlert = (open, message = '') => {
    setAlertOpen(open);
    if (open) {
      setAlertMsg(message);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack>
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography variant="h4">Tags</Typography>
          {alertOpen && (
            <Alert
              severity="error"
              onClose={() => {
                setAlertOpen(false);
              }}
            >
              {alertMsg}
            </Alert>
          )}
          <Box>
            <CSVLink
              data={tagProperties}
              style={{
                textDecoration: 'none',
              }}
              filename={
                `Tag-Lot-Properties-` +
                new Date().toISOString().slice(0, 10) +
                `.csv`
              }
            >
              <Button size="small" variant="outlined">
                Download
              </Button>
            </CSVLink>
            <Tooltip title="Add Tag" placement="top" aria-label="add-tag">
              <IconButton
                onClick={handleTagAddModal}
                aria-label="add-tag-button"
              >
                <AddBoxIcon style={{ height: '40px', width: '40px' }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
        <TagList
          users={users}
          tags={tags}
          fetchTags={fetchTags}
          fetchTagProperties={fetchTagProperties}
          setAlertOpen={setAlertOpen}
          setAlertMsg={setAlertMsg}
        />
      </Stack>
      <Dialog open={tagAddModal} onClose={handleTagAddModal} fullWidth>
        <DialogTitle>Create new tag</DialogTitle>
        <DialogContent>
          <Stack spacing={1} pt={1}>
            <FormControl required={false}>
              <InputLabel id="tagNames">Tag name</InputLabel>
              <Select
                labelId="tagNames"
                id="Name"
                name="tag-name"
                onChange={(newValue) => handleChange('tag', newValue)}
                value={tagName ?? ''}
                autoFocus
                label="Tag name"
              >
                {TAG_LIST.map((tagName, i) => (
                  <MenuItem key={`${i}-${tagName}`} value={tagName}>
                    {tagName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl required={false}>
              <InputLabel id="Platekind">Platekind</InputLabel>
              <Select
                labelId="Platekind"
                id="Platekind"
                label="Platekind"
                name="tag-platekind"
                disabled={Boolean(tagPlateKind)}
                onChange={(newValue) => handleChange('platekind', newValue)}
                value={tagPlateKind ?? ''}
              >
                {PLATE_KIND_LIST.map((tagPlatekind, i) => (
                  <MenuItem key={`${i}-${tagPlatekind}`} value={tagPlatekind}>
                    {tagPlatekind}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              fullWidth={true}
              control={
                <Switch
                  checked={tagIsSterile}
                  onChange={() => setTagIsSterile(!tagIsSterile)}
                  name="tag-is-sterile"
                />
              }
              label="Sterile"
            />
            <TextField
              fullWidth={true}
              label="Slope"
              name="tag-slope"
              autoComplete="tag-slope"
              onChange={(newValue) => handleChange('tagslope', newValue)}
              value={tagSlope ?? ''}
            />
            <TextField
              fullWidth={true}
              label="Intercept"
              name="tag-intercept"
              autoComplete="tag-intercept"
              onChange={(newValue) => handleChange('tagintercept', newValue)}
              value={tagIntercept ?? ''}
            />
            <TextField
              fullWidth={true}
              label="Lot Number"
              name="tag-lotnumber"
              autoComplete="tag-lotnumber"
              onChange={(newValue) => handleChange('taglotnumber', newValue)}
              value={tagLotNumber ?? ''}
            />
            <MobileDatePicker
              label="Manufacture Date (Bulk Saliva)"
              value={tagManufactureDate}
              name="manufacturedate"
              inputFormat={'MMM do, yyyy'}
              onChange={(newValue) => setTagManufactureDate(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
            <MobileDatePicker
              label="Expiration Date (Bulk Saliva)"
              value={tagExpirationDate}
              name="expirationdate"
              inputFormat={'MMM do, yyyy'}
              onChange={(newValue) => setTagExpirationDate(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
            <TextField
              fullWidth={true}
              label="Manufactured By"
              name="tag-manufacturedby"
              autoComplete="tag-manufacturedby"
              onChange={(newValue) => handleChange('manufacturedby', newValue)}
              value={tagManufacturedBy ?? ''}
            />
            <TextField
              fullWidth={true}
              label="Notes"
              name="tag-notes"
              autoComplete="tag-notes"
              onChange={(newValue) => handleChange('tagnotes', newValue)}
              value={tagNotes ?? ''}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleTagAddSubmit}
            disabled={
              !(
                tagPlateKind &&
                tagName &&
                tagManufactureDate &&
                tagSlope &&
                tagIntercept &&
                tagLotNumber
              )
            }
            size="small"
            className="modal-button"
          >
            Submit
          </Button>
          <Button
            onClick={handleTagAddModal}
            size="small"
            className="modal-button"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default TagsComponent;
