import { createSlice } from '@reduxjs/toolkit';

function startLoading(state) {
  state.isLoading = true;
}

function finishLoading(state) {
  state.isLoading = false;
}

function loadingFailed(state, action) {
  state.isLoading = false;
  state.error = action.payload;
}

export const slice = createSlice({
  name: 'heatmap',
  initialState: {
    heatmaps: [],
    resultsData: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    setStartLoading: startLoading,
    setFinishLoading: finishLoading,
    setLoadingFailed: loadingFailed,
    setHeatmaps: (state, action) => {
      state.isLoading = false;
      state.heatmaps = action.payload;
    },
    setNotError: (state) => {
      state.error = null;
    },
  },
});

export const {
  setStartLoading,
  setFinishLoading,
  setLoadingFailed,
  setHeatmaps,
  setNotError,
} = slice.actions;

export default slice.reducer;

export const heatmapSelector = (state) => state.heatmap;
