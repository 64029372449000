import React from 'react';
import { useSelector } from 'react-redux';

import { resultsSelector } from '../../Features/Results/resultsSlice';
import { testsSelector } from '../../Features/Tests/testsSlice';
import AppBreadcrumbs from './AppBreadcrumbs';
import {
  getBuildingBreadcrumb,
  getPortfolioBreadcrumb,
  getTextBreadcrumb,
} from './dataUtils';

function BreadcrumbsHeatmaps({ buildingId, testId, date }) {
  const { buildings, currentBuildingName } = useSelector(resultsSelector);
  const { tests, currentTestName } = useSelector(testsSelector);
  const lastItem = getTextBreadcrumb(currentTestName);
  lastItem.text = `${lastItem.text} (${date})`;
  let breadcrumbs = [
    getPortfolioBreadcrumb(buildings, buildingId),
    getBuildingBreadcrumb(tests, buildingId, currentBuildingName, testId),
    lastItem,
  ];
  return <AppBreadcrumbs links={breadcrumbs} />;
}

export default React.memo(BreadcrumbsHeatmaps);
