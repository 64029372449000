import { ERROR_AUTOCLOSE_TIMEOUT_MS } from 'Constants';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import authentication from 'Api/authentication';
import projects from 'Api/projects';

import EditBorderButton from 'Components/Buttons/EditBorderButton';
import EditProjectDetails from 'Components/SingleProject/Main/Modals/EditProjectDetailsModal';
import { useProjectContext } from 'Components/SingleProject/context';

import { isSafetracesAdmin } from 'Config/roles';

import { useIsMounted } from 'Context';

import { projectDataSelector } from 'Features/Projects/projectsSlice';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  isValidResponse,
} from 'Utils';

export default function EditProjectDetailsBtn() {
  const { projectDetails, isProjectDetailsLoading, projectId } =
    useSelector(projectDataSelector);

  const { fetchProjectDetails, setMainError } = useProjectContext();

  const [modalOpen, setModalOpen] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [projectCustomers, setProjectCustomers] = useState([]);
  const [projectPartners, setProjectPartners] = useState([]);

  const mounted = useIsMounted();

  useEffect(() => {
    fetchCustomers();
    fetchPartners();
  }, []);

  const fetchCustomers = async () => {
    if (!isSafetracesAdmin()) return;
    try {
      const response = await authentication.getCustomers();
      if (isValidResponse(response) && response.data.data.length) {
        const customers = [];
        getDataFromResponse(response).forEach((value) => {
          customers.push({ name: value.companyname });
        });

        setProjectCustomers(customers);
      }
    } catch (err) {
      console.log('fetchCustomers', err);
    }
  };

  const fetchPartners = async () => {
    if (!isSafetracesAdmin()) return;
    try {
      const response = await authentication.getPartners();
      if (isValidResponse(response) && response.data.data.length) {
        const partners = [{ name: 'Safetraces' }];
        getDataFromResponse(response).forEach((value) => {
          partners.push({ name: value.companyname });
        });
        setProjectPartners(partners);
      }
    } catch (err) {
      console.log('fetchPartners', err);
    }
  };

  const saveProjectDetails = async (values) => {
    setSaveInProgress(true);
    let isSuccess = false;

    const projectEditData = {
      projectName: values.name,
      address: values.address,
      partners: values.partner,
      endCustomer: values.customer,
      users: projectDetails.users,
      totalSegments: projectDetails.totalSegments,
      description: values.description,
    };

    try {
      const res = await projects.updateOneProject(projectId, projectEditData);
      if (!isValidResponse(res)) {
        throw new Error(getErrorMessageFromResponse(res));
      }
      isSuccess = true;
    } catch (err) {
      console.log('Save Project details error: ', err);
      setMainError(err?.message || `Couldn't save project details`);
      setTimeout(() => setMainError(''), ERROR_AUTOCLOSE_TIMEOUT_MS);
    } finally {
      if (mounted.current) {
        setSaveInProgress(false);
        if (isSuccess) {
          setModalOpen(false);
        }
      }
      fetchProjectDetails();
    }
  };

  return (
    <React.Fragment>
      <EditBorderButton
        disableStyles
        text="Edit project details"
        clickAction={() => setModalOpen(true)}
        extraBtnProps={{
          disableFocusRipple: true,
          disabled: isProjectDetailsLoading,
        }}
      />
      <EditProjectDetails
        projectData={projectDetails}
        projectCustomers={projectCustomers}
        projectPartners={projectPartners}
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        handleSubmit={saveProjectDetails}
        actionsDisabled={saveInProgress || isProjectDetailsLoading}
        hasTestPackages={projectDetails.hasTestPackages}
      />
    </React.Fragment>
  );
}
