import { getLSViewCompanyId } from '../Utils';

let token = localStorage.getItem('currentUser')
  ? JSON.parse(localStorage.getItem('currentUser')).access_token
  : '';

const _viewCompanyId = getLSViewCompanyId();

export const initialState = {
  user: '',
  access_token: '' || token,
  loading: false,
  errorMessage: null,
  viewCompanyId: null || _viewCompanyId,
  companiesListDidUpdate: false,
  companiesListUpdateHandled: false,
};

export const ACTION_REQUEST_LOGIN = 'REQUEST_LOGIN';
export const ACTION_LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const ACTION_LOGIN_NEED_MFA = 'LOGIN_NEED_MFA';
export const ACTION_LOGOUT = 'LOGOUT';
export const ACTION_LOGIN_ERROR = 'LOGIN_ERROR';
export const ACTION_CHANGE_VIEW_COMPANY_ID = 'CHANGE_VIEW_COMPANY_ID';
export const ACTION_COMPANIES_LIST_DID_UPDATE = 'COMPANIES_LIST_DID_UPDATE';
export const ACTION_COMPANIES_LIST_UPDATED = 'COMPANIES_LIST_UPDATE_HANDLED';

export const eventLoginSuccess = (data) => {
  return {
    type: ACTION_LOGIN_SUCCESS,
    payload: data,
  };
};

export const eventLoginNeedMFA = (data) => {
  return {
    type: ACTION_LOGIN_NEED_MFA,
    payload: data,
  };
};
export const eventRequestLogin = () => {
  return {
    type: ACTION_REQUEST_LOGIN,
  };
};

export const eventLogout = () => {
  return {
    type: ACTION_LOGOUT,
  };
};

export const eventLoginError = (data) => {
  return {
    type: ACTION_LOGIN_ERROR,
    error: data,
  };
};

export const eventViewCompanyChange = (viewCompanyId) => {
  return {
    type: ACTION_CHANGE_VIEW_COMPANY_ID,
    viewCompanyId,
  };
};

export const eventCompaniesListDidUpdate = () => {
  return {
    type: ACTION_COMPANIES_LIST_DID_UPDATE,
  };
};

export const eventCompaniesListUpdateHandled = () => {
  return {
    type: ACTION_COMPANIES_LIST_UPDATED,
  };
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case ACTION_REQUEST_LOGIN:
      return {
        ...initialState,
        loading: true,
      };
    case ACTION_LOGIN_SUCCESS: {
      return {
        ...initialState,
        access_token: action.payload.token,
        loading: false,
        errorMessage: null,
      };
    }

    case ACTION_LOGIN_NEED_MFA: {
      return {
        ...initialState,
        loading: false,
        needMFA: true,
        errorMessage: null,
      };
    }

    case ACTION_LOGOUT:
      return {
        ...initialState,
        access_token: '',
      };

    case ACTION_LOGIN_ERROR:
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };

    case ACTION_CHANGE_VIEW_COMPANY_ID:
      return {
        ...initialState,
        viewCompanyId: action.viewCompanyId,
      };
    case ACTION_COMPANIES_LIST_DID_UPDATE:
      return {
        ...initialState,
        companiesListDidUpdate: true,
        companiesListUpdateHandled: false,
      };
    case ACTION_COMPANIES_LIST_UPDATED:
      return {
        ...initialState,
        companiesListDidUpdate: false,
        companiesListUpdateHandled: true,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
