import React from 'react';
import { useSelector } from 'react-redux';

import { resultsSelector } from '../../Features/Results/resultsSlice';
import AppBreadcrumbs from './AppBreadcrumbs';
import { getPortfolioBreadcrumb, getTextBreadcrumb } from './dataUtils';

function BreadcrumbForTest({ buildingId }) {
  const { buildings, currentBuildingName } = useSelector(resultsSelector);
  let breadcrumbs = [
    getPortfolioBreadcrumb(buildings, buildingId),
    currentBuildingName ? getTextBreadcrumb(currentBuildingName) : null,
  ];
  return <AppBreadcrumbs links={breadcrumbs} />;
}

export default React.memo(BreadcrumbForTest);
