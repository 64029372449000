import React, { useRef } from 'react';

import CompanyForm from './Forms/CompanyForm';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function AddEditCompanyModal({
  open,
  type,
  handleClose,
  handleSubmit,
  transitionDuration = 220,
  company = null,
  actionsDisabled,
}) {
  const companyFormRef = useRef(null);

  const headerOptions = {
    add: `Add company`,
    edit: `Edit company`,
  };
  const okBtnText = {
    add: 'Create',
    edit: 'Save',
  };

  if (!~['add', 'edit'].indexOf(type)) return null;

  const canEditKeyParams =
    type === 'add' || (company && company.keyParamsEditable);

  return (
    <div>
      <Dialog
        open={open}
        transitionDuration={transitionDuration}
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            p: 1,
            pb: 2,
            justifyContent: 'center',
            alignItems: 'center',
            width: '440px',
          },
        }}
      >
        <DialogTitle>{headerOptions[type] || ''}</DialogTitle>
        <DialogContent>
          <CompanyForm
            ref={companyFormRef}
            onSubmit={(values) => handleSubmit(values)}
            defaultValues={company || null}
            keyFieldsDisabled={!canEditKeyParams}
          />
        </DialogContent>
        <DialogActions
          style={{ justifyContent: 'space-between', width: '60%' }}
        >
          <Button
            style={{ width: '100%', marginRight: '8px' }}
            variant="outlined"
            onClick={handleClose}
            disabled={actionsDisabled}
          >
            Cancel
          </Button>
          <Button
            style={{ width: '100%' }}
            variant="contained"
            disabled={actionsDisabled}
            onClick={() => companyFormRef.current?.requestSubmit()}
            endIcon={
              actionsDisabled ? (
                <CircularProgress color="inherit" size={'1rem'} />
              ) : null
            }
          >
            {okBtnText[type]}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
