import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  HEALTHCARE_TEST_TYPES,
  PROJECT_STATUSES,
  PROJECT_STATUS_WORKFLOW,
} from 'Constants';

import originpoints from 'Api/originpoints';
import projects from 'Api/projects';
import testsApi from 'Api/tests';

import {
  ACCESS_LEVEL_ADMIN,
  ACCESS_LEVEL_EDITOR,
  ROLE_PARTNER,
  ROLE_SAFETRACES,
  isSafetracesAdmin,
} from 'Config/roles';

import { getDataFromResponse, getUserData, isValidResponse } from 'Utils';

export const slice = createSlice({
  name: 'projectSingle',
  initialState: () => {
    return {
      projectId: null,
      projectData: null,
      projectStatus: null,
      projectDetails: {},
      projectTests: [],
      projectOriginPoints: [],
      projectTags: {
        common: null,
        healthcare: null,
      },
      extendedTagsFetched: false,
      isLoading: true,
      initialLoading: true,
      loadingConfig: {
        projectDetails: 'idle',
        projectStatus: 'idle',
        projectTests: 'idle',
        projectTest: 'idle',
        projectTags: 'idle',
        projectTagsExtended: 'idle',
        projectOriginPoints: 'idle',
        options: {},
      },
      permissions: getPermissionsForActionsInProjectBasedOnUserAndStatus(),
      error: '',
    };
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectData.pending, (state, action) => {
        if (state.projectId !== action.meta.arg) {
          state.projectData = null;
          state.projectStatus = null;
        }
        state.isLoading = true;
        state.error = '';
      })
      .addCase(fetchProjectData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.initialLoading = false;
        const project = action.payload;

        state.projectData = project;
        state.projectId = project.id;
        state.projectStatus = project.status;
        state.permissions =
          getPermissionsForActionsInProjectBasedOnUserAndStatus(project.status);
        state.projectDetails = extractDataPartFromProjectData(
          project,
          'projectDetails',
        );
        state.projectTests = extractDataPartFromProjectData(
          project,
          'projectTests',
        );
        state.projectTags = extractDataPartFromProjectData(
          project,
          'projectTags',
        );
        state.projectOriginPoints = extractDataPartFromProjectData(
          project,
          'projectOriginPoints',
        );

        state.error = '';
      })
      .addCase(fetchProjectData.rejected, (state) => {
        state.isLoading = false;
        state.initialLoading = false;
        state.projectData = null;
        state.projectId = null;
        state.error = 'Failed to fetch project data';
      })
      .addCase(fetchProjectDataPartial.pending, (state, action) => {
        state.loadingConfig[action.meta.arg.part] = 'pending';
        state.loadingConfig.options = action.meta.arg.options || {};
      })
      .addCase(fetchProjectDataPartial.fulfilled, (state, action) => {
        const part = action.meta.arg.part;
        // if (action.meta.arg.includeAllTags) {
        //   state.extendedTagsFetched = true;
        // }
        if (typeof action.meta.arg.includeAllTags === 'boolean') {
          state.extendedTagsFetched = action.meta.arg.includeAllTags;
        }

        state.loadingConfig[part] = 'succeeded';
        state.loadingConfig.options = {};
        state.projectData = action.payload;
        state.projectStatus = action.payload.status;
        state.permissions =
          getPermissionsForActionsInProjectBasedOnUserAndStatus(
            action.payload.status,
          );
        let _part = part;
        if (part === 'projectTagsExtended') {
          _part = 'projectTags';
        }
        if (part === 'projectTest') {
          _part = 'projectTests';
        }
        state[_part] = extractDataPartFromProjectData(action.payload, _part);
      })
      .addCase(fetchProjectDataPartial.rejected, (state, action) => {
        state.loadingConfig[action.meta.arg.part] = 'failed';
      });
  },
});

const getPermissionsForActionsInProjectBasedOnUserAndStatus = (
  projectStatus,
) => {
  const user = getUserData() || {};
  if (!projectStatus || !user) return {};

  const { roleName, accessLevel } = user;
  const editPermission = !!~[ACCESS_LEVEL_ADMIN, ACCESS_LEVEL_EDITOR].indexOf(
    accessLevel,
  );
  const editProjectDetailsPermission =
    editPermission && !!~[ROLE_SAFETRACES].indexOf(roleName);

  const canUpgradeProjectStatus =
    editPermission &&
    !!~[ROLE_SAFETRACES, ROLE_PARTNER].indexOf(roleName) &&
    !!~[
      PROJECT_STATUSES.IN_PROGRESS,
      PROJECT_STATUSES.EXECUTION_READY,
      PROJECT_STATUSES.READY_FOR_LAB,
      PROJECT_STATUSES.RESULTS_GENERATED,
    ].indexOf(projectStatus);

  const canDowngradeProjectStatus =
    (editPermission &&
      !!~[ROLE_SAFETRACES].indexOf(roleName) &&
      !!~[
        PROJECT_STATUSES.EXECUTION_READY,
        PROJECT_STATUSES.READY_FOR_LAB,
        PROJECT_STATUSES.EXECUTED,
        PROJECT_STATUSES.PUBLISHED,
      ].indexOf(projectStatus)) ||
    (isSafetracesAdmin() && projectStatus === PROJECT_STATUSES.IN_PROGRESS);

  const canAddTests = !~[
    PROJECT_STATUSES.READY_FOR_LAB,
    PROJECT_STATUSES.RESULTS_GENERATED,
    PROJECT_STATUSES.PUBLISHED,
  ].indexOf(projectStatus);

  const canEditTests =
    editPermission && !~[PROJECT_STATUSES.PUBLISHED].indexOf(projectStatus);

  const canReorderTests = !!~[
    PROJECT_STATUSES.NEW,
    PROJECT_STATUSES.IN_PROGRESS,
  ].indexOf(projectStatus);

  const canSetAllTestsToCompleted =
    PROJECT_STATUS_WORKFLOW.indexOf(projectStatus) <
      PROJECT_STATUS_WORKFLOW.indexOf(PROJECT_STATUSES.PUBLISHED) &&
    PROJECT_STATUS_WORKFLOW.indexOf(projectStatus) >=
      PROJECT_STATUS_WORKFLOW.indexOf(PROJECT_STATUSES.EXECUTION_READY);

  return {
    editPermission,
    canUpgradeProjectStatus,
    canDowngradeProjectStatus,
    editProjectDetailsPermission,
    canAddTests,
    canEditTests,
    canReorderTests,
    canSetAllTestsToCompleted,
  };
};

const extractDataPartFromProjectData = (projectData, part) => {
  switch (part) {
    case 'projectDetails':
      return {
        name: projectData.name,
        id: projectData.id,
        status: projectData.status,
        address: projectData.address,
        endCustomer: projectData.endCustomer,
        description: projectData.description,
        partners: projectData.partners,
        testPackageName: projectData.testPackageName,
        siteContractName: projectData.siteContractName,
        contractName: projectData.contractName,
        portfolioName: projectData.portfolioName,
        hasTestPackages: projectData.hasTestPackages,
        users: projectData.users,
        totalSegments: projectData.totalSegments,
      };
    case 'projectStatus':
      return projectData.status;
    case 'projectTags':
      return projectData.projectTags;
    case 'projectTests':
      return projectData.tests;
    case 'projectOriginPoints':
      return projectData.projectOriginPoints;
    default:
      return {};
  }
};

export const { setError } = slice.actions;

export default slice.reducer;

export const projectDataSelector = (state) => {
  const projectsState = state.projects;

  return {
    ...projectsState,
    isProjectDetailsLoading:
      projectsState.isLoading ||
      projectsState.loadingConfig.projectDetails === 'pending',
    isProjectStatusLoading:
      projectsState.isLoading ||
      projectsState.loadingConfig.projectStatus === 'pending',
    isProjectTestsLoading:
      projectsState.isLoading ||
      projectsState.loadingConfig.projectTests === 'pending',
    loadingTestId:
      projectsState.loadingConfig.projectTest === 'pending' &&
      projectsState.loadingConfig.options.testId,
    isProjectTagsLoading:
      projectsState.tagsInitialLoading ||
      projectsState.loadingConfig.projectTags === 'pending' ||
      projectsState.loadingConfig.projectTests === 'pending',
    isProjectTagsExtendedLoading:
      projectsState.tagsInitialLoading ||
      projectsState.loadingConfig.projectTagsExtended === 'pending',
  };
};

export const fetchProjectData = createAsyncThunk(
  'project/fetchProjectData',
  async (projectId, thunkApi) => {
    const includeAllTags = thunkApi.getState().projects.extendedTagsFetched;

    return await fetchProject(projectId, { includeAllTags });
  },
);

/**
 * Name is a bit misleading, it fetches entire project data and updates it in the store
 * Only loading handlers are separated
 */
const fetchProjectDataPartial = createAsyncThunk(
  'project/fetchProjectDataPartial',
  async ({ projectId, includeAllTags }, thunkApi) => {
    const _includeAllTags = thunkApi.getState().projects.extendedTagsFetched;

    return await fetchProject(projectId, {
      includeAllTags:
        typeof includeAllTags === 'boolean' ? includeAllTags : _includeAllTags,
    });
  },
);

export const fetchProjectDetails = (projectId) =>
  fetchProjectDataPartial({ projectId, part: 'projectDetails', options: {} });

export const fetchProjectStatus = (projectId) =>
  fetchProjectDataPartial({ projectId, part: 'projectStatus', options: {} });

export const fetchProjectTests = (projectId) =>
  fetchProjectDataPartial({ projectId, part: 'projectTests', options: {} });

export const fetchProjectTest = (projectId, testId) =>
  fetchProjectDataPartial({
    projectId,
    part: 'projectTest',
    options: { testId },
  });

export const fetchProjectTags = (projectId, includeAllTags) =>
  fetchProjectDataPartial({ projectId, part: 'projectTags', includeAllTags });

export const fetchProjectTagsExtended = (projectId) =>
  fetchProjectDataPartial({
    projectId,
    part: 'projectTagsExtended',
    includeAllTags: true,
  });

const fetchProject = async (projectId, options) => {
  try {
    const includeAllTags = options?.includeAllTags || false;

    const statusUpdateResponse = await projects.autoUpdateStatus(projectId);
    if (!isValidResponse(statusUpdateResponse)) {
      throw new Error('Failed to update project status');
    }

    const [response, testNumbersResponse, originPointsResponse] =
      await Promise.all([
        projects.getOneProject(projectId, true),
        testsApi.getDisplayNumbersProject(projectId),
        await originpoints.getAllOriginPointsForProject(
          projectId,
          includeAllTags,
        ),
      ]);

    if (
      !isValidResponse(response) ||
      !isValidResponse(testNumbersResponse) ||
      !isValidResponse(originPointsResponse)
    ) {
      throw new Error('Failed to fetch project data');
    }

    const segmentsTestIdsMap = {};

    const testNumbersConfig = getDataFromResponse(testNumbersResponse);
    const project = getDataFromResponse(response);
    if (Array.isArray(project.tests)) {
      for (let test of project.tests) {
        const { testid, segmentId } = test;
        test.orderNumber = testNumbersConfig[testid];
        segmentsTestIdsMap[segmentId] = testid;
      }
    }

    const originPointsData = getDataFromResponse(originPointsResponse);
    const [_tagsCommon, _tagsHealthcare] = getParsedTags(originPointsData);

    project.projectTags = {
      common: _tagsCommon,
      healthcare: _tagsHealthcare,
    };

    project.extendedTagsFetched = includeAllTags;
    for (let op of originPointsData) {
      op.testid = segmentsTestIdsMap[op.segmentid];
    }
    project.projectOriginPoints = originPointsData;

    return project;
  } catch (e) {
    console.log(e.toString());
    return null;
  }
};

const getParsedTags = (projectTags) => {
  let tagsTmpCommon = {};
  let tagsTmpHealthcare = {};
  projectTags.forEach((obj) => {
    const { opid, tags, tagLotNumber, tagLotNumbersList, testtype } = obj;
    const tagsTmp = Object.values(HEALTHCARE_TEST_TYPES).includes(testtype)
      ? tagsTmpHealthcare
      : tagsTmpCommon;
    tags.forEach(function (tag) {
      let tagName = tag.tag;

      if (!tagName.startsWith('')) {
        return;
      }

      if (tagsTmp[tagName] === undefined || null) {
        tagsTmp[tagName] = [];
      }

      let item = {
        opid: opid,
        tag: tag.tag,
        tagLotNumber: null,
        expirationDate: null,
      };

      if (tagLotNumber !== undefined) {
        tagLotNumber.forEach(function (tagLot) {
          if (tagName === tagLot.tag) {
            if (tagLot.lotNumber !== 'null') {
              item.tagLotNumber = tagLot.lotNumber;
            }
            if (tagLot.expirationDate !== 'null') {
              item.expirationDate = tagLot.expirationDate;
            }
            item.tag = tagLot.tag;
          }
        });
      }
      // Adding tag lot numbers by tag name
      item.numbers = tagLotNumbersList?.filter((i) => i.tag === tagName);
      item.tagLotNumberIsSterile = item.tagLotNumber
        ? tagLotNumbersList?.filter(
            (i) => i.tag === tagName && i.taglotnumber === item.tagLotNumber,
          )[0]?.issterile
        : false;
      tagsTmp[tagName].push(item);
    });
  });

  // Order object by key names
  return [
    Object.keys(tagsTmpCommon)
      .sort()
      .reduce((obj, key) => {
        obj[key] = tagsTmpCommon[key];
        return obj;
      }, {}),
    Object.keys(tagsTmpHealthcare)
      .sort()
      .reduce((obj, key) => {
        obj[key] = tagsTmpHealthcare[key];
        return obj;
      }, {}),
  ];
};
