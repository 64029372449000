import React from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';

import './App.css';
import AuthRoutes from './Components/Routes/AuthRoutes';
import Login from './Pages/Login';
import RecoverPassword from './Pages/RecoverPassword';

import { ThemeProvider } from '@mui/material';
import { grey } from '@mui/material/colors';
import { StyledEngineProvider, createTheme } from '@mui/material/styles';

import { AuthProvider } from 'Context';

const theme = createTheme({
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    h1: {
      color: '#80BC00',
    },
    h2: {
      color: '#2A3783',
    },
    h3: {
      color: '#008996',
    },
  },
  palette: {
    primary: {
      main: '#008996',
    },
    secondary: {
      main: '#80BC00',
    },
    black: {
      main: '#000000DD',
    },
    grey: {
      main: 'rgba(73,73,73,0.87)',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: `${grey[400]} rgb(0,0,0,0)`,
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: `rgb(0, 0, 0, 0)`,
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            //display: 'none',
            borderRadius: 8,
            backgroundColor: grey[400],
            minHeight: 12,
            border: `4px solid white`,
          },
          // '& *:hover::-webkit-scrollbar-thumb': {
          //   display: 'unset',
          // },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
            {
              backgroundColor: grey[400],
            },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
            {
              backgroundColor: grey[400],
            },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
            {
              backgroundColor: grey[400],
            },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: grey[200],
          },
        },
      },
    },
  },
});

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Router>
            <Routes>
              <Route
                exact
                path="/login"
                name="Login Page"
                element={<Login />}
              />
              {/* <Route
                exact
                path="/signup"
                name="SignUp Page"
                element={<SignUp />}
              /> */}
              <Route
                exact
                path="/recover"
                name="Recover Password Page"
                element={<RecoverPassword />}
              />
              <Route path="/" element={<Navigate to={'/results'} replace />} />
              <Route exact path="*" element={<AuthRoutes />} />
            </Routes>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
