import { LS_COMPARE_HEATMAPS } from 'Constants';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import ErrorMessage from '../../../Components/UI/ErrorMessage';

import { Button } from '@mui/material';

import { ROUTE_COMPARE, ROUTE_RESULTS } from 'Config/routes';

import { setClearAll, setShowMenu } from 'Features/Compare/compareSlice';

import { getHeatmapCompare } from 'Utils';

const HeatmapCompareMenu = () => {
  const dispatchCompare = useDispatch();
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatchCompare(setShowMenu(getHeatmapCompare().length));
  }, []);

  const clearCompareHeatmaps = () => {
    localStorage.removeItem(LS_COMPARE_HEATMAPS);
    dispatchCompare(setShowMenu(false));
    dispatchCompare(setClearAll(true));
    if (location.pathname === ROUTE_COMPARE) {
      navigate(ROUTE_RESULTS);
    }
  };

  const handleCloseErrorAlert = () => {
    setError(null);
  };

  return (
    <>
      {!!error && (
        <ErrorMessage
          message={error}
          handleCloseErrorAlert={handleCloseErrorAlert}
        />
      )}
      <Button
        variant="contained"
        onClick={() => navigate('/compare')}
        sx={{ mr: 3 }}
      >
        Compare heatmaps
      </Button>
      <Button variant="contained" onClick={clearCompareHeatmaps} sx={{ mr: 3 }}>
        REMOVE ALL
      </Button>
    </>
  );
};

export default React.memo(HeatmapCompareMenu);
