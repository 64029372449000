import React, { useState } from 'react';

import '../styles.css';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CropIcon from '@mui/icons-material/Crop';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from '@mui/material';

import { useIsMounted } from 'Context';

const FloorplanListItem = ({
  fpId,
  name,
  address,
  isOk,
  buildingid,
  floorNumber,
  hasParentFP,
  handleDownload,
  handleDelete,
  handleEdit,
  handlePreview,
  deleteDisabled,
  previewDisabled,
  editDisabled,
  previewLoading,
  displayEditActions = true,
  selectable = false,
  isSelected = false,
  handleSelect,
  cropData,
  loading,
  handleRedirectToHDA,
  handleTestAreaCreationStart,
}) => {
  const mounted = useIsMounted();
  const [isDownloading, setIsDownloading] = useState(false);

  const secondaryText = () => {
    if (!address && !floorNumber) return null;

    const res = [];

    if (typeof address === 'string' && !!address.trim()) {
      res.push(address);
    }
    if (typeof floorNumber === 'string' && !!floorNumber.trim()) {
      res.push(`Floor number: ${floorNumber}`);
    }

    return res.join('. ');
  };

  const onDownload = async () => {
    if (typeof handleDownload !== 'function') return;

    setIsDownloading(true);
    const res = await handleDownload(fpId, name, cropData);
    if (mounted.current && res) {
      setIsDownloading(false);
    }
  };

  return (
    <ListItem sx={{ pl: 0 }}>
      <ListItemIcon>
        {!selectable && (
          <>
            <IconButton
              size="small"
              color="primary"
              onClick={onDownload}
              disabled={isDownloading}
              className={isDownloading ? 'icon-btn-loading-color' : ``}
              sx={{ mr: 1 }}
            >
              <FileDownloadOutlinedIcon />
            </IconButton>
            <IconButton
              size="small"
              color="primary"
              onClick={() => handlePreview(fpId, name, cropData)}
              disabled={previewDisabled}
              sx={{ mr: 1 }}
              className={previewLoading ? 'icon-btn-loading-color' : ``}
            >
              <VisibilityIcon />
            </IconButton>
          </>
        )}
        {!!selectable && (
          <IconButton
            size="small"
            color="primary"
            onClick={handleSelect}
            sx={{ mr: 1 }}
            disabled={!isOk}
          >
            {isSelected ? (
              <RadioButtonCheckedIcon />
            ) : (
              <RadioButtonUncheckedIcon />
            )}
          </IconButton>
        )}
      </ListItemIcon>
      <ListItemText
        sx={{ pr: '165px' }}
        primary={
          selectable ? (
            <p onClick={handleSelect} style={{ margin: 0, cursor: 'pointer' }}>
              {name}
            </p>
          ) : (
            name
          )
        }
        secondary={secondaryText()}
      />
      {!!selectable ? (
        <ListItemSecondaryAction>
          {!!isOk && !hasParentFP ? (
            <Tooltip title="Create Test Area">
              <IconButton
                size="small"
                color="primary"
                onClick={() => handleTestAreaCreationStart(fpId)}
                disabled={isDownloading || loading}
                className={
                  isDownloading || loading ? 'icon-btn-loading-color' : ``
                }
                sx={{ mr: 1 }}
              >
                <CropIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          {!!isOk && !hasParentFP ? (
            <Tooltip title="Manage HDA">
              <IconButton
                size="small"
                color="primary"
                onClick={handleRedirectToHDA}
                sx={{ mr: 1 }}
              >
                <AccountTreeIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          {!!isOk ? (
            <IconButton
              size="small"
              color="primary"
              onClick={onDownload}
              disabled={isDownloading || loading}
              className={
                isDownloading || loading ? 'icon-btn-loading-color' : ``
              }
              sx={{ mr: 1 }}
            >
              <FileDownloadOutlinedIcon />
            </IconButton>
          ) : null}
          {!!displayEditActions ? (
            <React.Fragment>
              {!!isOk ? (
                <IconButton
                  size="small"
                  color="primary"
                  disabled={isDownloading || loading}
                  className={
                    isDownloading || loading ? 'icon-btn-loading-color' : ``
                  }
                  onClick={editDisabled ? () => {} : () => handleEdit(fpId)}
                >
                  <EditIcon />
                </IconButton>
              ) : null}
              <IconButton
                size="small"
                color="error"
                onClick={
                  deleteDisabled
                    ? () => {}
                    : () => handleDelete(fpId, name, buildingid, isOk)
                }
                sx={{ ml: 1 }}
                disabled={isDownloading || loading}
              >
                {isOk ? (
                  <DeleteIcon />
                ) : (
                  <Tooltip title="This floor plan is broken. It needs to be removed.">
                    <ErrorOutlineTwoToneIcon />
                  </Tooltip>
                )}
              </IconButton>
            </React.Fragment>
          ) : null}
        </ListItemSecondaryAction>
      ) : null}
    </ListItem>
  );
};

export default React.memo(FloorplanListItem);
