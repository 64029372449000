import { createSlice } from '@reduxjs/toolkit';

import resultsApi from 'Api/results';

import { getDataFromResponse, isValidResponse } from 'Utils';

const updateNeededTimeoutInSeconds = 60 * 5;

export const slice = createSlice({
  name: 'buildingProjectResults',
  initialState: {
    projects: [],
    currentBuildingId: null,
    lastUpdatedAt: null,
    isLoading: true,
    error: null,
  },
  reducers: {
    setStartLoading: (state) => {
      state.lastUpdatedAt = Date.now();
      state.isLoading = true;
    },
    setFinishLoading: (state) => {
      state.isLoading = false;
    },
    setLoadingFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setNotError: (state) => {
      state.error = null;
    },
    setBuildingId: (state, action) => {
      state.currentBuildingId = action.payload;
    },
    setLastUpdatedAt: (state, action) => {
      state.lastUpdatedAt = action.payload;
    },
    setBuildingProjects: (state, action) => {
      state.projects = action.payload;
    },
  },
});

export const fetchBuildingProjects =
  (buildingId) => async (dispatch, getState) => {
    try {
      dispatch(setStartLoading());

      const { currentBuildingId, lastUpdatedAt } =
        getState().buildingProjectResults;

      const now = Date.now();
      const diffSeconds = (now - lastUpdatedAt) / 1000;

      const updateNeeded =
        parseInt(currentBuildingId) !== parseInt(buildingId) ||
        diffSeconds > updateNeededTimeoutInSeconds;

      if (!updateNeeded) {
        setTimeout(() => dispatch(setFinishLoading()), 300);
      }

      dispatch(setBuildingId(buildingId));

      const response = await resultsApi.getHeatMapListForBuilding(buildingId);
      if (!isValidResponse(response)) throw new Error('Invalid response');

      const data = getDataFromResponse(response);
      if (!data) throw new Error('Invalid data');

      const projectsWithHeatmaps = data.filter(
        (project) => project.heatmaps.length > 0,
      );

      dispatch(setBuildingProjects(projectsWithHeatmaps));

      const end = Date.now();
      const diffTime = end - now;

      if (diffTime < 300) {
        setTimeout(() => dispatch(setFinishLoading()), 300 - diffTime);
      } else {
        dispatch(setFinishLoading());
      }
    } catch (err) {
      dispatch(setLoadingFailed(err.toString()));
    }
  };

export const {
  setStartLoading,
  setFinishLoading,
  setLoadingFailed,
  setNotError,
  setBuildingId,
  setBuildingProjects,
} = slice.actions;

export default slice.reducer;

export const buildingProjectResultsSelector = (state) =>
  state.buildingProjectResults;
