import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import './styles.css';

import results from 'Api/results';

import ProjectCard from 'Components/ResultTests/ProjectCard';
import { ProjectCardSkeleton } from 'Components/ResultTests/ProjectCard';

import {
  ACCESS_LEVEL_ADMIN,
  ACCESS_LEVEL_EDITOR,
  ROLE_PARTNER,
  ROLE_SAFETRACES,
} from 'Config/roles';

import { getUserData, isValidResponse } from 'Utils';

export default function ProjectsDraggableList({
  projects,
  buildingId,
  isLoading,
  afterUpdate,
  handleProjectHeatmapsDownload,
  downloadingHeatmapsProjectId,
  showSkeletonList,
}) {
  const { roleName, accessLevel } = getUserData();
  const [cardsExpansionConfig, setCardExpansionConfig] = useState({});
  const [resultsGenerating, setResultsGenerating] = useState(false);

  const dragPermission =
    !!~[ACCESS_LEVEL_ADMIN, ACCESS_LEVEL_EDITOR].indexOf(accessLevel) &&
    !!~[ROLE_PARTNER, ROLE_SAFETRACES].indexOf(roleName);

  const updateExpansionConfig = (projectId) => {
    setCardExpansionConfig((prev) => ({
      ...prev,
      [projectId]:
        typeof prev[projectId] === 'boolean' ? !prev[projectId] : false,
    }));
  };

  const containerStyle = {
    width: 'fit-content',
    marginBottom: '24px',
    borderRadius: '6px',
    marginTop: '12px',
    backgroundColor: 'white',
  };

  const regenerateResultsSnapshot = async (projectId) => {
    setResultsGenerating(true);
    try {
      const response = await results.regenerateResultsSnapshotForProject(
        projectId,
      );
      if (!isValidResponse(response)) throw new Error();
    } catch (err) {
      console.log('Error: ', err);
    } finally {
      if (typeof afterUpdate === 'function') {
        afterUpdate();
      }
      setResultsGenerating(false);
    }
  };

  if (showSkeletonList)
    return Array(2)
      .fill(1)
      .map((_, i) => (
        <div style={containerStyle} key={_ + i}>
          <ProjectCardSkeleton isDraggable={dragPermission} />
        </div>
      ));

  return (
    <>
      {projects.map(
        ([
          projectId,
          { name, id, tests, hasResultsSnapshot, projectOrderNum },
        ]) => (
          <Draggable
            draggableId={`project-${projectId}`}
            index={projectOrderNum}
            key={id}
            isDragDisabled={!dragPermission || isLoading || resultsGenerating}
          >
            {(provided) => {
              const style = {
                ...containerStyle,
                ...provided.draggableProps.style,
              };

              return (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  style={style}
                >
                  <ProjectCard
                    key={`project-card-${projectId}`}
                    expanded={
                      typeof cardsExpansionConfig[projectId] === 'boolean'
                        ? cardsExpansionConfig[projectId]
                        : true
                    }
                    handleExpandClick={() => updateExpansionConfig(projectId)}
                    projectId={id}
                    regenerateResultsSnapshot={() =>
                      regenerateResultsSnapshot(id)
                    }
                    name={name}
                    hasResultsSnapshot={hasResultsSnapshot}
                    buildingId={buildingId}
                    isDraggable={dragPermission}
                    dragHandleProps={provided.dragHandleProps || {}}
                    afterUpdate={afterUpdate}
                    tests={tests}
                    isLoading={isLoading || resultsGenerating}
                    handleProjectHeatmapsDownload={
                      handleProjectHeatmapsDownload
                    }
                    downloadingHeatmapsProjectId={downloadingHeatmapsProjectId}
                  />
                </div>
              );
            }}
          </Draggable>
        ),
      )}
    </>
  );
}
