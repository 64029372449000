import mergeImages from 'merge-images';
import React, { useCallback, useContext, useState } from 'react';

import {
  cropImageFromUrlPromisified,
  getMergePosition,
  getScaledImageSize,
} from '../../../Floorplans/utils';
import { FloorplansContext } from '../Floorplans';
import '../styles.css';
import FloorplanListItem from './FloorplanListItem';
import FloorplanEditModal from './Modals/FloorplanEditModal';
import FloorplanPreviewModal from './Modals/FloorplanPreviewModal';

import { Divider, List, Stack } from '@mui/material';

import floorPlans from 'Api/floorPlans';

import DeleteDialog from 'Components/Dialogs/DeleteDialog';
import FloorPlanTemplateImage from 'Components/Floorplans/Template-for-Floor-Plan.png';
import Resizer from 'Components/Floorplans/migratedComponents/Resizer';

import { useIsMounted } from 'Context';

import { getErrorMessageFromResponse, isValidResponse } from 'Utils';

function FloorplansFileList({ fileList, projectId, afterUpdate, onError }) {
  const mounted = useIsMounted();

  const { afterFloorplanFileDelete } = useContext(FloorplansContext);

  const [shouldConfirmDelete, setShouldConfirmDelete] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const [showPreview, setShowPreview] = useState(false);
  const [fileForPreview, setFileForPreview] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [fileToEdit, setFileToEdit] = useState(null);

  const [floorplansDownloaded, setFloorplansDownloaded] = useState({});

  const [isFetching, setIsFetching] = useState(false);

  const openDeleteConfirmation = useCallback(
    (id, name) => {
      setShouldConfirmDelete(true);
      setFileToDelete({ id, name });
    },
    [projectId],
  );

  const resetFileDelete = useCallback(() => {
    if (mounted.current) {
      setShouldConfirmDelete(false);
      setTimeout(() => setFileToDelete(null), 200);
    }
  }, []);

  const getFloorplanUrl = async ({ id, name, cropData }) => {
    let url = ``;
    let image = null;

    try {
      const response = await floorPlans.getPresignedURL({
        projectId,
        filename: name,
        action: 'getObject',
        heatmapVersion: 1,
      });

      if (response?.data?.preSignedURL) {
        const { preSignedURL } = response.data;
        if (!preSignedURL) {
          throw new Error(`No presigned URL`);
        }

        const _fp = await floorPlans.downloadFloorPlan(preSignedURL);
        if (!_fp) {
          throw new Error(`Broken presigned URL`);
        }

        const { data } = _fp;
        const { type } = data;

        const blob = new Blob([data], { type });

        const createObjectURL =
          window.URL.createObjectURL || window.webkitURL.createObjectURL;

        url = createObjectURL(blob);

        if (cropData) {
          url = await cropImageFromUrlPromisified({
            url,
            cropData,
          });
        }

        if (url) {
          image = await new Promise((resolve, reject) => {
            const image = new Image();
            image.src = url;

            image.addEventListener('load', () => {
              resolve(image);
            });
            image.addEventListener('error', (e) => {
              console.error('Failed to load the image', e);
              reject(new Error('Failed to load the image'));
            });
          });
        }
      }
    } catch (err) {
      url = null;
      image = null;
      console.log(`Floorplan URL obtain Error: `, err);
    } finally {
      if (url) {
        floorplansDownloaded[id] = { url, image };
        setFloorplansDownloaded({ ...floorplansDownloaded });
      }
      return [url, image];
    }
  };

  const handleDownloadFile = useCallback(
    async (id, name, cropData) => {
      try {
        const [url, image] = floorplansDownloaded[id]?.url
          ? [floorplansDownloaded[id]?.url, floorplansDownloaded[id]?.image]
          : await getFloorplanUrl({ id, name, cropData });
        if (!url || !image) {
          throw new Error();
        }
        const size = getScaledImageSize(image);
        const offset = getMergePosition(size);

        const fpToMergeUrl = Resizer.resizeAndRotateImage(
          image,
          size.width,
          size.height,
          size.width,
          size.height,
          'PNG',
          100,
          0,
        );

        const resultURL = await mergeImages(
          [
            { src: FloorPlanTemplateImage, x: 0, y: 0 },
            {
              src: fpToMergeUrl,
              x: offset.x,
              y: offset.y,
              opacity: 1,
            },
          ],
          { crossOrigin: 'anonymous' },
        );

        const link = document.createElement('a');

        link.setAttribute('href', resultURL);
        link.setAttribute('download', name);
        document.body.appendChild(link); // Required for FF

        link.click();
        link.remove();
      } catch (err) {
        console.error(`handleDownloadFile error: `, err);
      } finally {
        return true;
      }
    },
    [floorplansDownloaded],
  );

  const handleFileDelete = useCallback(async () => {
    setIsFetching(true);

    let isSuccess = false;
    try {
      const response = await floorPlans.deleteFloorPlan(fileToDelete.id);

      isSuccess = isValidResponse(response);

      if (!isSuccess) {
        throw new Error(getErrorMessageFromResponse(response));
      }
    } catch (err) {
      console.error('Delete file error: ', err);
      if (typeof onError === 'function') {
        onError(err?.message);
      }
    } finally {
      if (mounted.current) {
        setIsFetching(false);
      }
      if (isSuccess) {
        resetFileDelete();
      }

      if (typeof afterFloorplanFileDelete === 'function') {
        afterFloorplanFileDelete();
      }
    }
  }, [fileToDelete, onError, afterFloorplanFileDelete]);

  const handlePreviewOpen = useCallback(
    async (id, name, cropData) => {
      try {
        setFileForPreview(name);

        const url = floorplansDownloaded[id]?.url
          ? floorplansDownloaded[id].url
          : (await getFloorplanUrl({ id, name, cropData }))[0];

        if (!url) {
          throw new Error(`No URL`);
        }

        setPreviewURL(url);
        setShowPreview(true);
      } catch (err) {
        if (mounted.current) {
          setFileForPreview(null);
          setPreviewURL(null);
          setShowPreview(false);
        }
        console.log(`handlePreview Error: `, err);
      }
    },
    [floorplansDownloaded],
  );

  const handleEditOpen = useCallback((id) => {
    try {
      const selectedFP = fileList.find((floorPlan) => floorPlan.id === id);
      if (!selectedFP) {
        throw new Error('Floorplan for edit is not found');
      }
      setFileToEdit(selectedFP);
      setShowEditModal(true);
    } catch (err) {
      console.log(`handleEditOpen Error: `, err);
    }
  }, []);

  const resetEdit = useCallback(() => {
    setShowEditModal(false);
    setTimeout(() => setFileToEdit(null), 300);
  }, []);

  const updateFloorplanData = useCallback(
    async (values) => {
      try {
        const { id, address, floorNumber } = values;
        const bodyObj = {
          address,
          floornumber: floorNumber,
        };

        const response = await floorPlans.updateFloorPlan(id, bodyObj);
        if (isValidResponse(response)) {
          if (typeof afterUpdate === 'function') {
            afterUpdate();
          }
          resetEdit();
        } else {
          throw new Error(getErrorMessageFromResponse(response));
        }
      } catch (err) {
        console.error(`updateFloorplanData error: `, err);
        if (typeof onError === 'function') {
          onError(err?.message);
        }
      }
    },
    [afterUpdate, onError],
  );

  return (
    <React.Fragment>
      <Stack direction="column" sx={{ maxWidth: '600px' }}>
        <List>
          {fileList.map(
            (
              { name, address, id, floorNumber, hasHeatmapVersion, metadata },
              i,
            ) => (
              <React.Fragment key={i}>
                <FloorplanListItem
                  fpId={id}
                  name={name}
                  address={address}
                  hasHeatmapVersion={hasHeatmapVersion}
                  floorNumber={floorNumber}
                  deleteDisabled={fileToDelete?.id === id}
                  previewDisabled={fileForPreview === id}
                  previewLoading={
                    fileForPreview === name && !previewURL && !showPreview
                  }
                  editDisabled={fileToEdit?.id === id}
                  handleDownload={handleDownloadFile}
                  handleDelete={openDeleteConfirmation}
                  handlePreview={handlePreviewOpen}
                  handleEdit={handleEditOpen}
                  displayEditActions={false}
                  cropData={metadata?.cropData}
                />
                {i !== fileList.length - 1 && (
                  <Divider
                    sx={{
                      width: '90%',
                      display: 'flex',
                      ml: 'auto',
                      mr: 'auto',
                    }}
                  />
                )}
              </React.Fragment>
            ),
          )}
        </List>
      </Stack>
      <DeleteDialog
        open={shouldConfirmDelete}
        itemName={fileToDelete?.name}
        handleClose={resetFileDelete}
        isLoading={isFetching}
        handleDelete={handleFileDelete}
      />
      <FloorplanPreviewModal
        open={showPreview}
        imgUrl={previewURL}
        fileName={fileForPreview}
        handleClose={() => {
          setShowPreview(false);
          setTimeout(() => {
            setPreviewURL(null);
            setFileForPreview(null);
          }, 500);
        }}
      />
      <FloorplanEditModal
        open={showEditModal}
        handleClose={resetEdit}
        file={fileToEdit}
        handleSubmit={updateFloorplanData}
      />
    </React.Fragment>
  );
}

export default React.memo(FloorplansFileList);
