import React from 'react';

import { Skeleton } from '@mui/material';

import Title from 'Components/Mix/Title';

export default function PageTitle({ loading, name, id }) {
  if (loading) {
    return (
      <Skeleton sx={{ minWidth: '300px', mb: 2 }}>
        <Title text="Something to inherit height from" />
      </Skeleton>
    );
  }

  return <Title text={`${name} (Id ${id})`} color="primary" />;
}
