import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useProjectContext } from '../../context';

import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';

import { projectDataSelector } from 'Features/Projects/projectsSlice';

const TagForm = (props) => {
  const { fetchProjectTagsExtended } = useProjectContext();
  const {
    isProjectTagsLoading,
    isProjectTagsExtendedLoading,
    extendedTagsFetched,
  } = useSelector(projectDataSelector);
  const { onSubmit, tag } = props;

  const [tagLotValue, setTagLotValue] = useState(tag.tagLotNumber || '');
  const [open, setOpen] = useState(false);

  const renderMenuItems = () => {
    if (!tag.numbers) {
      return <MenuItem value={tag.tagLotNumber}>{tag.tagLotNumber}</MenuItem>;
    }

    const items = tag.numbers.map((number, index) => (
      <MenuItem
        key={index}
        value={number.taglotnumber}
        sx={{ display: 'flex' }}
      >
        <span>{number.taglotnumber}</span>
        {number.issterile && (
          <Tooltip title="Sterile" placement="top" aria-label="Sterile">
            <LocalHospitalIcon sx={{ color: '#008996', ml: '5px' }} />
          </Tooltip>
        )}
      </MenuItem>
    ));

    // Usually we fetch only the 3 latest tag lot numbers for the menu.
    // If it is an old lot number, it won't be in the list and select would be empty
    // which is incorrect behavior, so we add it to the list
    if (
      tag.tagLotNumber &&
      !tag.numbers.find((n) => n.taglotnumber === tag.tagLotNumber)
    ) {
      items.push(
        <MenuItem key={tag.numbers.length} value={tag.tagLotNumber}>
          <Typography>{tag.tagLotNumber}</Typography>
        </MenuItem>,
      );
    }

    if (!extendedTagsFetched) {
      items.push(
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            fetchProjectTagsExtended();
          }}
          disableRipple
          key={tag.numbers.length + 1}
          value="more"
        >
          <MoreHorizIcon sx={{ mr: 1 }} />
          More
          {isProjectTagsLoading || isProjectTagsExtendedLoading ? (
            <CircularProgress
              color="primary"
              size={'1rem'}
              sx={{ ml: 'auto' }}
            />
          ) : null}
        </MenuItem>,
      );
    }

    return items;
  };

  return (
    <FormControl variant="standard" sx={{ width: '100%', maxWidth: '180px' }}>
      <InputLabel id="select-tagLotNumber-label">Tag Lot Number</InputLabel>
      <Select
        variant="standard"
        labelId="select-tagLotNumber-label"
        id="select-tagLotNumber"
        label="Tag Lot Number"
        value={tagLotValue}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={(e) => {
          if (!e.defaultPrevented) {
            setOpen(false);
          }
        }}
        onChange={(e) => {
          if (e.target.value !== 'more') {
            setTagLotValue(e.target.value);
            onSubmit({ tagLotNumber: e.target.value });
          }
        }}
      >
        {renderMenuItems()}
      </Select>
    </FormControl>
  );
};

TagForm.displayName = 'TagForm';

export default TagForm;
