import { ERROR_AUTOCLOSE_TIMEOUT_MS } from 'Constants';
import React, { useState } from 'react';

import DeleteBorderButton from '../../Buttons/DeleteBorderButton';
import DeleteDialog from '../../Dialogs/DeleteDialog';

import authentication from 'Api/authentication';

import ErrorMessage from 'Components/UI/ErrorMessage';

import { isValidResponse } from 'Utils';

export default function DeleteCompanyButton({
  displayOnly = false,
  afterDelete,
  companyId,
  disabled,
  disabledReason,
  companyName,
  sx,
}) {
  const buttonDisabledProps = disabled
    ? {
        disabled,
        tooltipText: !!disabled && disabledReason,
      }
    : {};

  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [mainError, setMainError] = useState('');

  const deleteCompany = async () => {
    setDeleteInProgress(true);
    let isSuccess = false;
    try {
      const response = await authentication.removeOneCompany(companyId);
      isSuccess = isValidResponse(response);
    } catch (err) {
      console.log('Delete company error: ', err);
      setMainError(err?.message || `Couldn't delete the company`);
      setTimeout(() => setMainError(''), ERROR_AUTOCLOSE_TIMEOUT_MS);
    } finally {
      setDeleteInProgress(false);
      setDialogOpen(false);
      if (isSuccess && typeof afterDelete === 'function') {
        afterDelete();
      }
    }
  };
  return displayOnly ? (
    <DeleteBorderButton sx={{ border: 0, ...sx }} variant="text" />
  ) : (
    <>
      <DeleteDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        title="Delete company"
        itemName={(companyName && `the company "${companyName}"`) || ''}
        isLoading={deleteInProgress}
        handleDelete={deleteCompany}
      />
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
      <DeleteBorderButton
        variant="text"
        clickAction={() => setDialogOpen(true)}
        sx={{ border: 0, ...sx }}
        {...buttonDisabledProps}
      />
    </>
  );
}
