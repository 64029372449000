import React from 'react';

import { Alert, Button, CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function DeleteDialog({
  open,
  handleClose,
  handleDelete,
  title = '',
  isLoading = false,
  itemName = '',
  extraText,
  type = 'delete',
}) {
  return (
    <Dialog open={open} sx={{ p: 7 }}>
      {!!title && <DialogTitle sx={{ width: 350 }}>{title}</DialogTitle>}
      <DialogContent>
        {`Are you sure you want to ${type === 'delete' ? 'delete' : 'reset'} ${
          itemName ? itemName : 'it'
        }?`}
        {!!extraText && (
          <Alert sx={{ mt: 1 }} severity="warning">
            {extraText}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={isLoading}
          onClick={handleClose}
          sx={{ mr: 1 }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isLoading}
          color={'error'}
          onClick={handleDelete}
          endIcon={
            isLoading ? (
              <CircularProgress color="inherit" size={'1rem'} />
            ) : null
          }
        >
          {type === 'reset' ? 'Reset' : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
