import { useCallback, useEffect, useState } from 'react';

import { escapeRegex } from 'Components/TestPackages/utils';

export const useSitesData = (buildingsData, sitesData) => {
  const [portfolios, setPortfolios] = useState([]);
  const [sites, setSites] = useState([]);
  const [sitesConfig, setSitesConfig] = useState({});
  const [filledConfig, setFilledConfig] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);

  useEffect(() => {
    const { portfolioItems, buildingItems, siteItems } = organiseAndFilterSites(
      buildingsData,
      searchTerm,
      sitesConfig,
    );
    setPortfolios(portfolioItems);
    setSites(siteItems);
    setBuildings(buildingItems);
  }, [searchTerm, buildingsData, sitesConfig]);

  useEffect(() => {
    const _config = formSitesConfig(sitesData);
    setSitesConfig(_config);
    setFilledConfig(true);
  }, [sitesData]);

  const swapBuildingLocally = useCallback(
    (buildingId, oldSiteId, newSiteId, oldIndex, newIndex) => {
      if (!buildingId || !oldSiteId || !newSiteId) return;

      const site = sites.find((site) => site.id === oldSiteId);
      const { buildings } = site;

      const building = buildings.splice(oldIndex, 1)[0];
      const siteChanged = oldSiteId !== newSiteId;

      let buildingsArrToAdd = buildings;

      if (siteChanged) {
        building.siteId = newSiteId;
        const newSite = sites.find((site) => site.id === newSiteId);
        buildingsArrToAdd = newSite.buildings;
      }

      buildingsArrToAdd.splice(newIndex, 0, building);

      const localIndexConfig = buildingsArrToAdd.reduce((conf, b, i) => {
        conf[b.id] = i;
        b.localOrder = i;
        return conf;
      }, {});

      const { updatedBuilding, newBuildingsData } = updateSiteBuildingData(
        buildingsData,
        buildingId,
        newSiteId,
        sitesConfig,
        localIndexConfig,
        siteChanged,
      );

      return { updatedBuilding, newBuildingsData };
    },
    [sites, buildings, sitesConfig],
  );

  const addBuildingLocally = useCallback(
    (building) => {
      try {
        const {
          buildingid,
          buildingname,
          portfolioid,
          portfolioname,
          regionid,
          siteid,
          territoryid,
        } = building;

        const portfolio = portfolios.filter((p) => +p.id === +portfolioid)[0];
        const site = portfolio.sites.filter((s) => +s.id === +siteid)[0];

        site.has_building = true;
        sitesConfig[siteid].has_building = true;

        const { buildings: siteBuildings, name } = site;
        const localOrder =
          typeof siteBuildings.slice(-1).localOrder === 'number'
            ? siteBuildings.slice(-1).localOrder + 1
            : null;

        const buildingObj = {
          id: buildingid,
          hasTestpackage: false,
          localOrder,
          name: buildingname,
          siteId: siteid,
        };

        siteBuildings.push(buildingObj);

        buildingsData.push({
          buildingid,
          buildingname,
          has_testpackage: false,
          portfolioname,
          portfolioid,
          siteid,
          regionid,
          territoryid,
          sitename: name,
        });

        return buildingsData;
      } catch (err) {
        console.log('addBuildingLocally Error: ', err);
        return false;
      }
    },
    [portfolios, sitesConfig],
  );

  const deleteBuildingLocally = useCallback(
    (buildingId) => {
      try {
        let keyToDelete = null;
        for (let [key, building] of Object.entries(buildingsData)) {
          if (+building.buildingid === +buildingId) {
            keyToDelete = key;

            const { portfolioid, siteid } = building;

            const portfolio = portfolios.filter(
              (p) => +p.id === +portfolioid,
            )[0];
            const site = portfolio.sites.filter((s) => +s.id === +siteid)[0];

            const { buildings: siteBuildings } = site;
            const deleteIndex = siteBuildings.findIndex(
              (b) => +b.id === +buildingId,
            );

            siteBuildings.splice(deleteIndex, 1);

            if (!siteBuildings.length) {
              site.has_building = false;
              sitesConfig[siteid].has_building = false;
            }
            break;
          }
        }

        if (!keyToDelete) throw new Error();

        buildingsData.splice(keyToDelete, 1);

        return [...buildingsData];
      } catch (err) {
        console.log(`deleteBuildingLocally error: `, err);
        return false;
      }
    },
    [portfolios, sitesConfig, buildingsData],
  );

  return {
    portfolios,
    sites,
    buildings,
    searchTerm,
    filledConfig,
    setSearchTerm,
    swapBuildingLocally,
    addBuildingLocally,
    deleteBuildingLocally,
  };
};

function updateSiteBuildingData(
  buildingsData,
  buildingId,
  newSiteId,
  sitesConfig,
  localIndexConfig = {},
  siteChanged,
) {
  const result = {
    updatedBuilding: {},
    newBuildingsData: [],
  };
  try {
    const buildingsToAddOrder = Object.keys(localIndexConfig);
    const { updatedBuilding } = result;

    for (let b of buildingsData) {
      if (siteChanged) {
        const buildingToUpdate = b.buildingid === buildingId;
        if (buildingToUpdate) {
          const siteConfig = sitesConfig[newSiteId];

          siteConfig.has_building = true;

          const {
            portfolioid,
            territoryid,
            portfolioname,
            name: siteName,
          } = siteConfig;

          b.siteid = newSiteId;
          b.sitename = siteName;
          b.portfolioid = portfolioid;
          b.portfolioname = portfolioname;
          b.territoryid = territoryid;

          updatedBuilding.siteId = newSiteId;
          updatedBuilding.buildingId = buildingId;
          updatedBuilding.portfolioId = portfolioid;
          updatedBuilding.territoryId = territoryid;
        }
      }
      if (!!~buildingsToAddOrder.indexOf(`` + b.buildingid)) {
        b.localBuildingIndex = localIndexConfig[b.buildingid];
      }
    }

    result.newBuildingsData = [...buildingsData];
  } catch (err) {
    console.log(`updateSiteBuildingData Error: `, err);
  } finally {
    return result;
  }
}

export function isValidBuildingObj(buildObj) {
  try {
    return [`siteId`, `buildingId`, `portfolioId`, `territoryId`].reduce(
      (res, key) => {
        return (
          res &&
          buildObj[key] &&
          typeof parseInt(buildObj[key], 10) === 'number'
        );
      },
      true,
    );
  } catch (err) {
    return false;
  }
}

function organiseAndFilterSites(buildingsData, searchTerm, sitesConfig) {
  const portfolioMap = new Map();
  const siteMap = new Map();
  const buildingMap = new Map();

  const applySearch = typeof searchTerm === 'string' && !!searchTerm;
  const reg = new RegExp(escapeRegex(searchTerm ?? ''), 'i');

  buildingsData.forEach((row) => {
    let include = !applySearch;
    if (
      applySearch &&
      (reg.test(row.portfolioname) ||
        reg.test(row.sitename) ||
        reg.test(row.buildingname))
    ) {
      include = true;
    }
    if (include) {
      portfolioMap.set(row.portfolioid, {
        id: row.portfolioid,
        name: row.portfolioname,
        sites: [],
      });
      siteMap.set(row.siteid, {
        id: row.siteid,
        name: row.sitename,
        portfolioId: row.portfolioid,
        has_sitecontract: sitesConfig[row.siteid]?.has_sitecontract,
        has_building: sitesConfig[row.siteid]?.has_building,
        buildings: [],
      });
      buildingMap.set(row.buildingid, {
        id: row.buildingid,
        name: row.buildingname,
        address: row.buildingaddress,
        area: row.totalsqeft,
        numOfFloors: row.numberoffloors,
        floorArea: row.floorsqeft,
        siteId: row.siteid,
        hasTestpackage: row.has_testpackage,
        localOrder:
          typeof row.localBuildingIndex === 'number'
            ? row.localBuildingIndex
            : null,
      });
    }
  });

  /**
   * Handling of sites with no buildings which are visible only in
   * edit mode.
   */
  if (!applySearch) {
    for (let [siteId, siteData] of Object.entries(sitesConfig)) {
      if (!siteMap.get(siteId)) {
        const {
          has_sitecontract,
          has_building,
          name,
          portfolioid,
          portfolioname,
        } = siteData;

        if (!portfolioMap.get(portfolioid)) {
          portfolioMap.set(portfolioid, {
            id: portfolioid,
            name: portfolioname,
            sites: [],
          });
        }

        const siteObj = {
          id: +siteId,
          name,
          has_sitecontract,
          has_building,
          portfolioId: portfolioid,
          buildings: [],
        };

        siteMap.set(+siteId, siteObj);
      }
    }
  }

  buildingMap.forEach((b) => {
    const site = siteMap.get(b.siteId);
    if (site) site.buildings.push(b);
  });

  siteMap.forEach((s) => {
    const portfolio = portfolioMap.get(s.portfolioId);
    if (portfolio) portfolio.sites.push(s);
  });

  return {
    portfolioItems: Array.from(portfolioMap.values()).sort(
      (a, b) => a.id - b.id,
    ),
    siteItems: Array.from(siteMap.values()),
    buildingItems: Array.from(buildingMap.values()),
  };
}

function formSitesConfig(sitesData) {
  if (!Array.isArray(sitesData)) return {};

  try {
    const config = sitesData.reduce(
      (
        res,
        {
          has_sitecontract,
          has_building,
          siteid,
          sitename,
          portfolioid,
          territoryid,
          regionid,
          portfolioname,
        },
      ) => {
        res[siteid] = {
          has_sitecontract,
          has_building,
          name: sitename,
          portfolioid,
          territoryid,
          regionid,
          portfolioname,
        };

        return res;
      },
      {},
    );

    return config;
  } catch (err) {
    console.error('formSitesConfig err', err);
    return {};
  }
}
