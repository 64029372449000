import React from 'react';

import { useTestContext } from '../context';
import {
  EditArea,
  EditComments,
  EditCustomArea,
  EditFloorplan,
  EditOP,
  EditPlatekind,
  EditSP,
  EditSampleRate,
  EditSampleType,
  EditScenarioComments,
  EditScenarios,
  EditSprayer,
  EditSurveyDate,
  EditTags,
} from './TestEditModals';

import { Modal, Paper } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const EditTestDetailsModal = ({
  isDrawerOpen: isDrawerOpenProp,
  setIsDrawerOpen: setIsDrawerOpenProp,
  drawerMeta: drawerMetaProp,
  reFetchProject: reFetchProjectProp,
}) => {
  const {
    isDrawerOpen: isDrawerOpenContext,
    setIsDrawerOpen: setIsDrawerOpenContext,
    drawerMeta: drawerMetaContext,
    reFetchProject: reFetchProjectContext,
  } = useTestContext();

  const reFetchProject = reFetchProjectProp || reFetchProjectContext;
  const setIsDrawerOpen = setIsDrawerOpenProp || setIsDrawerOpenContext;

  const getDrawerMeta = () => drawerMetaProp || drawerMetaContext || {};

  const renderComponent = () => {
    const drawerMeta = getDrawerMeta();
    switch (drawerMeta?.type) {
      case 'sample_type':
        return (
          <EditSampleType
            testId={drawerMeta?.testId}
            value={drawerMeta?.value}
            testType={drawerMeta?.data?.testType}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
          />
        );
      case 'sample_rate':
        return (
          <EditSampleRate
            testId={drawerMeta?.testId}
            value={drawerMeta?.value}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
          />
        );
      case 'sprayer':
        return (
          <EditSprayer
            testId={drawerMeta?.testId}
            value={drawerMeta?.value}
            sprayers={drawerMeta?.data.sprayers}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
          />
        );
      case 'comments':
        return (
          <EditComments
            testId={drawerMeta?.testId}
            value={drawerMeta?.value}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
            segmentId={drawerMeta?.data.segmentId}
          />
        );
      case 'platekind':
        return (
          <EditPlatekind
            testId={drawerMeta?.testId}
            value={drawerMeta?.value}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
            segmentId={drawerMeta?.data.segmentId}
            projectId={drawerMeta?.data.projectId}
          />
        );
      case 'surveydate':
        return (
          <EditSurveyDate
            testId={drawerMeta?.testId}
            value={drawerMeta?.value}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
            segmentId={drawerMeta?.data.segmentId}
            segment={drawerMeta?.data.segment}
          />
        );
      case 'area':
        return (
          <EditArea
            testId={drawerMeta?.testId}
            buildingData={drawerMeta?.data}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
          />
        );
      case 'custom_area':
        return (
          <EditCustomArea
            testId={drawerMeta?.testId}
            buildingData={drawerMeta?.data}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
            segment={drawerMeta?.data.segment}
            preparedScenariosList={drawerMeta?.data.preparedScenariosList}
            shouldRecalculateAfterUpdate={
              drawerMeta?.data.shouldRecalculateAfterUpdate
            }
          />
        );
      case 'origin_points':
        return (
          <EditOP
            testId={drawerMeta?.testId}
            value={drawerMeta?.value}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
            segment={drawerMeta?.data.segment}
            test={drawerMeta?.data.test}
            preparedScenariosList={drawerMeta?.data.preparedScenariosList}
            sampleType={drawerMeta?.data.sampleType}
          />
        );
      case 'sample_points':
        return (
          <EditSP
            testId={drawerMeta?.testId}
            value={drawerMeta?.value}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
            segment={drawerMeta?.data.segment}
            test={drawerMeta?.data.test}
          />
        );
      case 'scenarios':
        return (
          <EditScenarios
            testId={drawerMeta?.testId}
            value={drawerMeta?.value}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
            segment={drawerMeta?.data.segment}
          />
        );
      case 'tags':
        return (
          <EditTags
            testId={drawerMeta?.testId}
            value={drawerMeta?.value}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
            originPoints={drawerMeta?.data.originPoints}
            allowedTags={drawerMeta?.data.allowedTags}
            scenario={drawerMeta?.data.scenario}
            projectId={drawerMeta?.data.projectId}
          />
        );
      case 'scenario_comments':
        return (
          <EditScenarioComments
            value={drawerMeta?.value}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
            scenarioId={drawerMeta?.data.scenarioId}
          />
        );
      case 'floorplans':
        return (
          <EditFloorplan
            value={drawerMeta?.value}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
            reFetchProject={reFetchProject}
            buildingId={drawerMeta?.data.buildingId}
            projectId={drawerMeta?.data.projectId}
            testId={drawerMeta?.data.segment?.testid}
            floorplans={drawerMeta?.data.floorplans}
            segment={drawerMeta?.data.segment}
            hdaId={drawerMeta?.data.hdaId}
          />
        );
      default:
      //setIsDrawerOpen(false);
    }
  };

  return (
    <Modal
      open={
        typeof isDrawerOpenProp === 'boolean'
          ? isDrawerOpenProp
          : typeof isDrawerOpenContext === 'boolean'
          ? isDrawerOpenContext
          : false
      }
      onClose={setIsDrawerOpen}
    >
      <Paper
        sx={Object.assign(
          { ...style },
          getDrawerMeta().type === 'floorplans' ? { width: 850 } : {},
        )}
        variant="outlined"
      >
        {renderComponent()}
      </Paper>
    </Modal>
  );
};

export default EditTestDetailsModal;
