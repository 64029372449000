import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Save } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import segments from 'Api/segments';

import { getErrorMessageFromResponse, isValidResponse } from 'Utils';

const EditComments = ({ cancel, value, testId, reFetchProject, segmentId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { handleSubmit, register, formState, errors } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);
    segments
      .updateSegment(segmentId, {
        testId: testId,
        comments: data.comments,
      })
      .then((response) => {
        if (!isValidResponse(response)) {
          throw new Error(getErrorMessageFromResponse(response));
        }
        reFetchProject();
        cancel();
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderForm = () => {
    if (!isLoading) {
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              multiline
              fullWidth
              maxRows={6}
              label="Comments"
              defaultValue={value}
              {...register('comments')}
              error={!!(errors && errors.comments)}
              helperText={errors?.comments?.message}
            />
          </FormControl>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              startIcon={<Save />}
              disabled={!formState.isDirty}
              type="submit"
            >
              Save
            </Button>
            <ButtonGroup>
              <Button onClick={cancel}>Cancel</Button>
            </ButtonGroup>
          </Stack>
        </form>
      );
    }
    return null;
  };

  return (
    <Stack p={2} spacing={2}>
      {isLoading && <LinearProgress />}
      <Typography variant="h5">Edit comments</Typography>
      <Box py={2}>{renderForm()}</Box>
      {!!error && <Alert severity="error">{error.message}</Alert>}
    </Stack>
  );
};

export default EditComments;
