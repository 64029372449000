import {
  HEALTHCARE_TEST_TYPES_LABELS,
  PROJECT_STATUSES,
  PROJECT_STATUS_WORKFLOW,
} from 'Constants';
import { format, parseISO } from 'date-fns';
import React, { useMemo } from 'react';

import {
  Biotech as BiotechIcon,
  CalendarMonth as CalendarMonthIcon,
  Coronavirus as CoronavirusIcon,
  CropSquare as CropSquareIcon,
  Edit as EditIcon,
  Height as HeightIcon,
  HelpOutline as HelpOutlineIcon,
  HourglassEmpty as HourglassEmptyIcon,
  ImageOutlined as ImageOutlinedIcon,
  ViewInAr as ViewInArIcon,
} from '@mui/icons-material';
import {
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import { ScenariosTableSingleTest } from 'Components/Scenarios';
import { CreateScenarioButton } from 'Components/Scenarios/Buttons';
import { TestScenariosContextProvider } from 'Components/Scenarios/context';
import Floorplan from 'Components/SingleTest/Elements/Floorplan';
import { useTestContext } from 'Components/SingleTest/context';

import { isSafetracesAdmin } from 'Config/roles';

const TestCharacteristicItem = ({
  icon = null,
  label,
  value = '',
  action = null,
  explanationMsg = '',
  variant = 'primary',
  sx,
}) => {
  return (
    <Stack direction="row" sx={{ ...sx }}>
      {icon}
      <Typography
        sx={{
          ml: icon ? 1 : 0,
          fontWeight: variant === 'secondary' ? 300 : 'bold',
        }}
      >
        {label}
      </Typography>
      {!!value && <Typography sx={{ ml: 1 }}>{value}</Typography>}
      {action ? action : null}
      {explanationMsg ? (
        <Tooltip
          placement="top"
          title={<span style={{ fontSize: '1rem' }}>{explanationMsg}</span>}
        >
          <span
            style={{
              marginLeft: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <HelpOutlineIcon fontSize="small" color="info" />
          </span>
        </Tooltip>
      ) : null}
    </Stack>
  );
};

const AreaTypeSettings = ({ area, data }) => {
  const { opAmount, spAmount, intervalCount } = data;

  const headerText = {
    '4way': '4-Way AS',
    SKC: 'SKC',
  };

  const OPsection = () => {
    return (
      <TestCharacteristicItem
        icon={<CoronavirusIcon color="primary" />}
        label="Origin Points:"
        value={opAmount || ''}
        action={
          null
          // <IconButton
          //   aria-label="Edit number of origin points"
          //   size="small"
          //   sx={{ ml: 1 }}
          //   onClick={() => {
          //     openEditModal({
          //       type: 'origin_points_healthcare',
          //       value: opAmount,
          //       testId: test.testid,
          //       data: {
          //         preparedScenariosList: [],
          //         test,
          //       },
          //     });
          //   }}
          // >
          //   <EditIcon fontSize="inherit" />
          // </IconButton>
        }
      />
    );
  };

  const SPsection = () => {
    return (
      <TestCharacteristicItem
        icon={<BiotechIcon color="primary" />}
        label="Sample Points:"
        value={spAmount || ''}
        action={
          null
          //   <IconButton
          //     aria-label="Edit number of sample points"
          //     size="small"
          //     sx={{ ml: 1 }}
          //     onClick={() => {
          //       openEditModal({
          //         type: 'sample_points_healthcare',
          //         value: spAmount,
          //         testId: test.testid,
          //         data: { test: test },
          //       });
          //     }}
          //   >
          //     <EditIcon fontSize="inherit" />
          //   </IconButton>
        }
      />
    );
  };

  const intervalDurationSection = () => {
    return (
      <TestCharacteristicItem
        value={area === '4way' ? '4-way' : 'SKC'}
        label="Interval Duration:"
        explanationMsg="Interval duration depends on specific scenario settings"
      />
    );
  };

  const intervalCountSection = () => {
    return (
      <TestCharacteristicItem
        icon={<HourglassEmptyIcon color="primary" />}
        label="Interval Count:"
        value={intervalCount}
      />
    );
  };

  return (
    <>
      <Typography
        variant="subtitle1"
        sx={{ textAlign: 'center', fontWeight: 300 }}
      >
        {headerText[area]}
      </Typography>
      <Grid container mt={1} spacing={1} sx={{ overflow: 'hidden', pb: 2 }}>
        <Grid item xs={6}>
          {!!opAmount ? OPsection() : SPsection()}
        </Grid>
        <Grid item xs={6}>
          {intervalCountSection()}
        </Grid>
        <Grid item xs={6}>
          {!!opAmount ? SPsection() : null}
        </Grid>
        <Grid item xs={6}>
          {intervalDurationSection()}
        </Grid>
      </Grid>
    </>
  );
};

const Healthcare = ({
  test,
  originPointsList,
  samplePointsList,
  surveydate,
  comments,
  preparedScenariosList,
  segmentId,
  segment,
  floorPlanPlacementURL,
  projectStatus,
}) => {
  const {
    projectId,
    openEditModal,
    reFetchProject,
    forceResrictAllEdits: forceResrictAllEditsFromContext,
  } = useTestContext();

  const editSamplesDisabled =
    PROJECT_STATUS_WORKFLOW.indexOf(projectStatus) >=
    PROJECT_STATUS_WORKFLOW.indexOf(PROJECT_STATUSES.EXECUTION_READY);
  const editDetailsDisabled =
    PROJECT_STATUS_WORKFLOW.indexOf(projectStatus) >=
    PROJECT_STATUS_WORKFLOW.indexOf(PROJECT_STATUSES.EXECUTED);

  const forceResrictAllEdits =
    forceResrictAllEditsFromContext ||
    (typeof test?.testexecuted === 'boolean' && test?.testexecuted) ||
    editDetailsDisabled;
  const overrideEditPermissionSafetracesAdminIgroneWorkflow =
    isSafetracesAdmin();

  const testAreaSection = () => {
    const { testtype, testid: testId, buildingdata } = test;
    const { celling_height, sq_ft } = buildingdata || {};

    return (
      <Grid item xs={5} lg={4}>
        <Stack direction="column">
          <TestCharacteristicItem
            label="Test Area:"
            icon={<ViewInArIcon color="primary" />}
            action={
              overrideEditPermissionSafetracesAdminIgroneWorkflow ||
              (!forceResrictAllEdits && !editDetailsDisabled) ? (
                <IconButton
                  aria-label="Edit test area"
                  size="small"
                  sx={{ ml: 1 }}
                  onClick={() => {
                    openEditModal({
                      type: 'custom_area',
                      value: sq_ft,
                      testId,
                      data: {
                        sq_ft,
                        celling_height,
                        type: testtype,
                        segment,
                        shouldRecalculateAfterUpdate: false,
                      },
                    });
                  }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              ) : null
            }
          />
          {!!sq_ft && (
            <TestCharacteristicItem
              variant="secondary"
              icon={<CropSquareIcon color="disabled" />}
              label="Square:"
              value={`${sq_ft} sq.ft`}
              sx={{ ml: 2, pt: 1 }}
            />
          )}
          {!!celling_height && (
            <TestCharacteristicItem
              icon={<HeightIcon color="disabled" />}
              label="Ceiling Height:"
              sx={{ ml: 2, pt: 1 }}
              value={`${celling_height} ft`}
              variant="secondary"
            />
          )}
        </Stack>
      </Grid>
    );
  };

  const testConfigData = useMemo(() => {
    const config = {};
    const mapKeys = {
      inner: '4way',
      outer: 'SKC',
    };

    if (test?.testmetadata) {
      const { intervalCount, opAmount, spAmount } =
        Object.values(test.testmetadata)[0] || {};

      for (let key of Object.keys(mapKeys)) {
        const areaKey = mapKeys[key];

        config[areaKey] = {
          area: areaKey,
          opAmount: opAmount[key],
          spAmount: spAmount[key],
          intervalCount: intervalCount[key],
        };
      }
    }

    return config;
  }, [test]);

  return (
    <Stack direction="column" mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={7} lg={5}>
          <Stack direction="column" spacing={2}>
            <TestCharacteristicItem
              label="Test Type:"
              value={HEALTHCARE_TEST_TYPES_LABELS[test.testtype]}
            />
            <TestCharacteristicItem
              icon={<CalendarMonthIcon color="primary" />}
              label="Survey Date:"
              value={
                surveydate ? format(parseISO(surveydate), 'MMM do, yyyy') : ''
              }
              action={
                !forceResrictAllEdits && !editDetailsDisabled ? (
                  <IconButton
                    aria-label="Edit survey date"
                    size="small"
                    sx={{ ml: 1 }}
                    onClick={() => {
                      openEditModal({
                        type: 'surveydate',
                        value: surveydate,
                        testId: test.testid,
                        data: { segment },
                      });
                    }}
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                ) : null
              }
            />
            <Stack>
              <TestCharacteristicItem
                icon={<ImageOutlinedIcon color="primary" />}
                label="Floorplan:"
                action={
                  <Floorplan
                    projectId={projectId}
                    buildingId={test.buildingid || ''}
                    filteredTest={test}
                    segment={segment}
                    editDisabled={editDetailsDisabled || forceResrictAllEdits}
                  />
                }
              />
              {segment.floorplan && (
                <>
                  {!forceResrictAllEdits && !isSafetracesAdmin() ? (
                    <Typography sx={{ ml: 1, mt: 0.5 }}>
                      <Link href={floorPlanPlacementURL}>Manage OPs/SPs</Link>
                    </Typography>
                  ) : null}
                  {forceResrictAllEdits && !isSafetracesAdmin() ? (
                    <Typography sx={{ ml: 1, mt: 0.5 }}>
                      <Link href={floorPlanPlacementURL}>View OPs/SPs</Link>
                    </Typography>
                  ) : null}
                  {isSafetracesAdmin() &&
                  projectStatus !== PROJECT_STATUSES.PUBLISHED ? (
                    <Typography sx={{ ml: 1, mt: 0.5 }}>
                      <Link href={floorPlanPlacementURL}>Manage OPs/SPs</Link>
                    </Typography>
                  ) : null}
                  {isSafetracesAdmin() &&
                  projectStatus === PROJECT_STATUSES.PUBLISHED ? (
                    <Typography sx={{ ml: 1, mt: 0.5 }}>
                      <Link href={floorPlanPlacementURL}>View OPs/SPs</Link>
                    </Typography>
                  ) : null}{' '}
                </>
              )}
            </Stack>
          </Stack>
        </Grid>
        {testAreaSection()}
      </Grid>
      <Grid container mt={0.5}>
        <Grid item xs={12} md={5}>
          <AreaTypeSettings
            area="4way"
            data={testConfigData['4way']}
            opAmount={originPointsList?.length || 0}
            spAmount={samplePointsList?.length || 0}
            test={test}
          />
        </Grid>
        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
          <Divider orientation="vertical" flexItem />
        </Grid>
        <Grid item xs={12} md={5}>
          <AreaTypeSettings
            area="SKC"
            data={testConfigData['SKC']}
            opAmount={originPointsList?.length || 0}
            spAmount={samplePointsList?.length || 0}
            test={test}
          />
        </Grid>
      </Grid>
      <TestCharacteristicItem
        sx={{ mt: 3 }}
        label="Comments:"
        value={comments}
        action={
          overrideEditPermissionSafetracesAdminIgroneWorkflow ||
          !forceResrictAllEdits ? (
            <IconButton
              aria-label="Edit comments"
              size="small"
              sx={{ ml: 1 }}
              onClick={() => {
                openEditModal({
                  type: 'comments',
                  value: comments,
                  testId: test.testid,
                  data: { segmentId },
                });
              }}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          ) : null
        }
      />
      <Stack direction="column" sx={{ mt: 2 }}>
        <TestScenariosContextProvider
          value={{
            projectId,
            testId: test.testid,
            testType: test.testtype,
            testExecuted: test.testexecuted,
            segmentId: test.segmentId,
            reFetchProject,
            projectStatus,
          }}
        >
          <Stack direction="row" spacing={1} mb={1}>
            <Typography variant="h6">Scenarios</Typography>
            {!editSamplesDisabled ? <CreateScenarioButton /> : null}
          </Stack>
          <ScenariosTableSingleTest
            scenarios={preparedScenariosList}
            originPoints={originPointsList}
            test={test}
            editDisabled={forceResrictAllEdits}
          ></ScenariosTableSingleTest>
        </TestScenariosContextProvider>
      </Stack>
    </Stack>
  );
};

export default Healthcare;
