import React, { useEffect, useState } from 'react';

import { Alert, Snackbar } from '@mui/material';

function MainAlert({ message, type = 'success' }) {
  const [alert, setAlert] = useState({ message: '', type: '' });

  useEffect(() => {
    setAlert({ message, type });
  }, [message, type]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({ message: '', type });
  };

  return (
    <>
      {!!alert.type && (
        <Snackbar
          open={!!alert.message}
          autoHideDuration={4500}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert severity={alert.type} onClose={handleClose} variant="filled">
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default React.memo(MainAlert);
