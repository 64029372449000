import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import samplePlans from '../../../../Api/samplePlans';

import { Save } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import originpoints from 'Api/originpoints';

import { useIsMounted } from 'Context';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  isValidResponse,
} from 'Utils';

const EditTags = ({
  cancel,
  value,
  reFetchProject,
  originPoints,
  allowedTags,
  scenario,
  testId,
  projectId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [originPointsToUpdate, setOriginPointsToUpdate] = useState([]);

  const { handleSubmit, register, formState, errors } = useForm();
  const mounted = useIsMounted();

  useEffect(() => {
    if (originPoints) {
      setOriginPointsToUpdate(originPoints);
    } else {
      getOriginPoints();
    }
  }, []);

  const getOriginPoints = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await originpoints.getAllOriginPointsForTest(testId);
      if (isValidResponse(response)) {
        const data = getDataFromResponse(response);
        if (mounted.current) {
          setOriginPointsToUpdate(data);
          setIsLoading(false);
        }
      } else throw new Error(getErrorMessageFromResponse(response));
    } catch (err) {
      if (mounted.current) {
        setError(err);
        setIsLoading(false);
      }
    }
  };

  const onSubmit = (data) => {
    setIsLoading(true);

    Promise.all(
      originPointsToUpdate.map((value) =>
        originpoints.setOpTag({
          OPId: value.opid,
          scenarioId: scenario.scenarioid.toString(),
          tag: data.tag,
        }),
      ),
    )
      .then(async (res) => {
        let hasError = false;
        res.forEach((value) => {
          if (!isValidResponse(value)) {
            hasError = true;
            setError(getErrorMessageFromResponse(value));
          }
        });
        if (!hasError) {
          try {
            await samplePlans.removeSamplePlan(projectId);
          } catch (e) {
            console.log(e);
          }
          reFetchProject();
          cancel();
        }
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderForm = () => {
    if (!isLoading) {
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <TextField
              select
              focused
              variant="outlined"
              fullWidth
              label="Tag"
              defaultValue={value}
              {...register('tag')}
              error={!!(errors && errors.tag)}
              helperText={errors?.tag?.message}
            >
              {allowedTags.map((value) => (
                <MenuItem key={value} value={value}>
                  <Typography>{value}</Typography>
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              startIcon={<Save />}
              disabled={!formState.isDirty}
              type="submit"
            >
              Save
            </Button>
            <ButtonGroup>
              <Button onClick={cancel}>Cancel</Button>
            </ButtonGroup>
          </Stack>
        </form>
      );
    }
    return null;
  };

  return (
    <Stack p={2} spacing={2}>
      {isLoading && <LinearProgress />}
      <Typography variant="h5">Edit Tag</Typography>
      <Box py={2}>{renderForm()}</Box>
      {!!error && <Alert severity="error">{error}</Alert>}
    </Stack>
  );
};

export default EditTags;
