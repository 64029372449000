import { ERROR_MESSAGE_DEFAULT } from 'Constants';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Save } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  DialogContentText,
  FormControl,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import originpoints from 'Api/originpoints';
import resultsApi from 'Api/results';
import samplePlans from 'Api/samplePlans';
import samplePoints from 'Api/samplePoints';
import scenarios from 'Api/scenarios';
import segments from 'Api/segments';
import tests from 'Api/tests';

import { areaSectionConfig } from 'Components/SingleTest/config';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  isValidResponse,
} from 'Utils';

const EditCustomArea = ({
  cancel,
  testId,
  reFetchProject,
  buildingData,
  segment,
  preparedScenariosList,
  shouldRecalculateAfterUpdate = true,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sqFt, setSqFt] = useState(0);
  const [validationMethod, setValidationMethod] = useState({
    valFunc: (val) => !!parseInt(val, 10) || '',
  });

  const [open, setOpen] = React.useState(false);

  const {
    handleSubmit,
    register,
    formState,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (typeof areaSectionConfig[buildingData.type] !== 'undefined') {
      setValidationMethod({
        valFunc:
          areaSectionConfig[buildingData.type].form.restrict.range_validator
            .sq_ft,
      });
    }
  }, []);

  const onSubmit = (data) => {
    const obj = { ...buildingData };

    obj.sq_ft = data.sq_ft;
    obj.celling_height = data.celling_height;
    setSqFt(obj.sq_ft);

    setIsLoading(true);
    tests
      .updateOneTest(testId, {
        buildingData: obj,
      })
      .then(async (testUpdateResponse) => {
        if (!isValidResponse(testUpdateResponse)) {
          throw new Error(getErrorMessageFromResponse(testUpdateResponse));
        }
        const updatedTest = getDataFromResponse(testUpdateResponse);
        const testIsExecuted = updatedTest[0]?.testexecuted;

        const recalculateResultsResponse =
          await resultsApi.recalculateResultsForTest(testId);

        if (!isValidResponse(recalculateResultsResponse)) {
          throw new Error(
            getErrorMessageFromResponse(recalculateResultsResponse),
          );
        }
        if (shouldRecalculateAfterUpdate && !testIsExecuted) {
          setOpen(true);
        } else {
          handleDoNotChange();
        }
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDoNotChange = () => {
    reFetchProject();
    cancel();
  };

  const handleChange = () => {
    const OP = Math.ceil(sqFt / 2000);
    const SP = Math.ceil(sqFt / 4000);

    setIsLoading(true);
    segments
      .updateSegment(segment.segmentid, {
        testId: testId,
        sp: SP,
      })
      .then(async () => {
        const response = await segments.getSegments(testId);
        let newSegment;
        if (isValidResponse(response)) {
          newSegment = response.data.data[0];
        } else {
          setError('Error happened');
          cancel();
        }
        try {
          await samplePlans.removeSamplePlan(newSegment.projectid);
        } catch (e) {
          console.log(e);
        }

        segments
          .createSegments({
            projectId: segment.projectid,
            testId: testId,
            totalSegments: 1,
            op: OP,
            intervalcount: 4,
            sp: SP,
          })
          .then(async () => {
            const response = await segments.getSegments(testId);
            let newSegment;
            if (isValidResponse(response)) {
              newSegment = response.data.data[0];
            } else {
              setError('Error happened');
              cancel();
            }
            await segments.updateTestPlan({
              segmentId: newSegment.segmentid,
              testId: testId,
              surveyDate: segment.surveydate,
              comments: segment.comments,
            });
            await segments.updateSegment(newSegment.segmentid, {
              testId: testId,
              floorPlan: segment.floorplan,
              comments: segment.comments,
            });
            await originpoints.createOriginpoints({
              testId: testId,
            });
            await scenarios.createScenarios({
              testId: testId,
              segmentId: newSegment.segmentid,
              totalScenarios: preparedScenariosList.length,
            });
            await samplePoints.createSamplePoints({
              testId: testId,
            });
            reFetchProject();
            cancel();
          })
          .catch((err) => {
            setError(err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setError(err);
      });
  };

  const renderForm = () => {
    if (!isLoading) {
      return (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                fullWidth
                label="Sq.ft."
                defaultValue={
                  buildingData.sq_ft !== 'null' ? buildingData.sq_ft : ''
                }
                {...register('sq_ft', {
                  validate: {
                    lessThanTen: validationMethod.valFunc,
                    isANumber: (val) => Number(val) || `Value must be a number`,
                  },
                })}
                error={!!(errors && errors.sq_ft)}
                helperText={errors?.sq_ft?.message}
              />
              <br />
              <TextField
                variant="outlined"
                fullWidth
                label="Ceiling Height, ft"
                defaultValue={
                  buildingData.celling_height !== 'null'
                    ? buildingData.celling_height
                    : ''
                }
                {...register('celling_height', {
                  validate: (v) => Number(v) || `Value must be a number`,
                })}
                error={!!(errors && errors.celling_height)}
                helperText={errors?.celling_height?.message}
              />
            </FormControl>
            <Stack direction="row" justifyContent="space-between" mt={2}>
              <Button
                variant="contained"
                startIcon={<Save />}
                disabled={!formState.isDirty}
                type="submit"
              >
                Save
              </Button>
              <ButtonGroup>
                <Button onClick={cancel}>Cancel</Button>
              </ButtonGroup>
            </Stack>
          </form>
          <Dialog
            open={open}
            onClose={handleDoNotChange}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Recalculation of the number of OP and SP'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you want to recalculate the number of OP and SP or leave it
                unchanged?
              </DialogContentText>

              <Stack direction="row" justifyContent="space-between" mt={2}>
                <Button onClick={handleChange} variant="contained" autoFocus>
                  Recalculate
                </Button>
                <Button onClick={handleDoNotChange} variant="contained">
                  Do not change
                </Button>
              </Stack>
            </DialogContent>
          </Dialog>
        </>
      );
    }
    return null;
  };

  return (
    <Stack p={2} spacing={2}>
      {isLoading && <LinearProgress />}
      <Typography variant="h5">Edit test area</Typography>
      <Box py={2}>{renderForm()}</Box>
      {!!error && (
        <Alert severity="error">
          {error?.message || ERROR_MESSAGE_DEFAULT}
        </Alert>
      )}
    </Stack>
  );
};

export default EditCustomArea;
