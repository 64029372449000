import React, { forwardRef } from 'react';
import { useForm } from 'react-hook-form';

import { Grid, MenuItem, TextField, Typography } from '@mui/material';

const EditProjectDetailsForm = forwardRef((props, ref) => {
  const {
    onSubmit,
    projectName,
    projectAddress,
    projectDescription,
    projectCustomer,
    projectPartner,
    projectCustomers,
    projectPartners,
    defaultValues,
    hasTestPackages,
  } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues || {},
  });

  return (
    <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Name"
            defaultValue={projectName}
            {...register('name', {
              required: 'Project name field required',
              minLength: {
                value: 3,
                message: 'Name must have at least 3 characters',
              },
            })}
            error={!!(errors && errors.name)}
            helperText={errors?.name?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Address"
            defaultValue={projectAddress}
            {...register('address')}
            error={!!(errors && errors.address)}
            helperText={errors?.address?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Description"
            defaultValue={projectDescription}
            {...register('description')}
            error={!!(errors && errors.description)}
            helperText={errors?.description?.message}
          />
        </Grid>
        <Grid item xs={12}>
          {!hasTestPackages && projectCustomers.length > 0 && (
            <TextField
              select
              variant="outlined"
              fullWidth
              label="Customer"
              defaultValue={projectCustomer}
              sx={{ minWidth: 153, mt: 2 }}
              {...register('customer')}
              error={!!(errors && errors.customer)}
              helperText={errors?.customer?.message}
            >
              {projectCustomers.map((value) => (
                <MenuItem key={value.name} value={value.name}>
                  <Typography>{value.name}</Typography>
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>
        <Grid item xs={12}>
          {!hasTestPackages && projectPartners.length > 0 && (
            <TextField
              select
              variant="outlined"
              fullWidth
              label="Partner"
              defaultValue={projectPartner}
              sx={{ minWidth: 153, mt: 3 }}
              {...register('partner')}
              error={!!(errors && errors.partner)}
              helperText={errors?.partner?.message}
            >
              {projectPartners.map((value) => (
                <MenuItem key={value.name} value={value.name}>
                  <Typography>{value.name}</Typography>
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>
      </Grid>
    </form>
  );
});

EditProjectDetailsForm.displayName = 'EditProjectDetailsForm';

export default EditProjectDetailsForm;
