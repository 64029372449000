import { ERROR_AUTOCLOSE_TIMEOUT_MS } from 'Constants';
import React, { useState } from 'react';

import AddBorderButton from '../../Buttons/AddBorderButton';
import AddEditUserModal from '../AddEditUserModal';

import users from 'Api/users';

import ErrorMessage from 'Components/UI/ErrorMessage';

import { useIsMounted } from 'Context';

import { isValidResponse } from 'Utils';

export default function AddUserBtn({
  afterAdd,
  companyName,
  companyType,
  rolesData,
  disabled,
  sx,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [addInProgress, setAddInProgress] = useState(false);
  const [mainError, setMainError] = useState('');
  const mounted = useIsMounted();

  const addUser = async (values) => {
    setAddInProgress(true);
    let isSuccess = false;
    try {
      const bodyObj = {
        ...values,
        companyName,
      };
      const response = await users.inviteUser(bodyObj);
      isSuccess = isValidResponse(response);
      if (!isSuccess) {
        throw new Error(response?.data?.error?.message);
      }
    } catch (err) {
      console.log('Add user error: ', err);
      setMainError(err?.message || `Couldn't add user`);
      setTimeout(() => setMainError(''), ERROR_AUTOCLOSE_TIMEOUT_MS);
    } finally {
      if (mounted.current) {
        setAddInProgress(false);
      }
      if (isSuccess && typeof afterAdd === 'function') {
        if (mounted.current) {
          setModalOpen(false);
        }
        afterAdd();
      }
    }
  };

  return (
    <div>
      <AddBorderButton
        disableStyles={true}
        text="Add user"
        clickAction={() => setModalOpen(true)}
        extraBtnProps={{ disableFocusRipple: true, disabled }}
        sx={{ ...sx }}
      />
      <AddEditUserModal
        open={modalOpen}
        type="add"
        handleClose={() => setModalOpen(false)}
        handleSubmit={(values) => addUser(values)}
        actionsDisabled={addInProgress}
        rolesData={rolesData}
        companyType={companyType}
      />
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
    </div>
  );
}
