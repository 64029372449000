import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Save } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  FormControl,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import buildings from 'Api/buildings';

import { usePortfolioContext } from 'Components/Portfolios/context';

const AddSite = ({ cancel, portfolioId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { handleSubmit, register, formState } = useForm();
  const { sites, fetchPortfoliosAndSites } = usePortfolioContext();

  const territories = portfolioId ? sites[portfolioId].territories : [];

  const onSubmit = (data) => {
    setIsLoading(true);
    buildings
      .createSite(data)
      .then((res) => {
        if (res?.data?.success) {
          fetchPortfoliosAndSites();
          cancel();
        } else {
          setError(res?.data?.error?.message);
        }
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Stack p={2} spacing={2}>
      {isLoading && <LinearProgress />}
      <Typography variant="h5">Add a new site</Typography>
      <Box py={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <TextField
              id="site-id"
              fullWidth
              label="Site name"
              margin="normal"
              required
              {...register('name')}
            />
          </FormControl>
          <FormControl fullWidth sx={{ marginTop: 1 }}>
            <TextField
              id="territory-select"
              label="Territory"
              fullWidth
              select
              inputProps={register('territoryId')}
              defaultValue=""
              required
            >
              {territories.map((t) => (
                <MenuItem key={t.id} value={t.id}>
                  {t.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              startIcon={<Save />}
              disabled={!formState.isDirty}
              type="submit"
            >
              Save
            </Button>
            <Button onClick={cancel}>Cancel</Button>
          </Stack>
        </form>
      </Box>
      {!!error && <Alert severity="error">Error when creating the site</Alert>}
    </Stack>
  );
};

export default AddSite;
