import axios from 'axios';

import config from 'Config/config';

const BASEURL = 'https://5xvteygzcg.execute-api.eu-west-1.amazonaws.com/dev' + '';

const common = {
  getSprayers: async () => {
    return await axios.get(BASEURL + '/sprayers', {});
  },
  /**
   * @param {number} companyId
   * @param {'yearly' | 'quarterly'} periodType
   * @param {number} period
   * @param {boolean} filterByTestsCompletion
   * @param {boolean} projectStatuses
   */
  getReportMainOverview: async (
    companyId,
    periodType,
    period,
    filterByTestsCompletion,
    projectStatuses,
  ) => {
    let queryParams = `?companyId=${
      companyId || -1
    }&periodType=${periodType}&period=${period}&filterByTestsCompletion=${Number(
      filterByTestsCompletion,
    )}`;
    if (projectStatuses.length) {
      queryParams += `&projectStatuses=${projectStatuses.join(',')}`;
    }

    return await axios.get(BASEURL + `/reports/mainOverview${queryParams}`);
  },
  getReportPortfolioOverview: async (portfolioId) => {
    return await axios.get(
      `${BASEURL}/reports/portfolioOverview/${portfolioId}`,
    );
  },
  getReportBuildingOverview: async (buildingId) => {
    return await axios.get(`${BASEURL}/reports/buildingOverview/${buildingId}`);
  },
};

export default common;
