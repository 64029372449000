import { UNMOUNT_IGNORED_ERR_MSG } from 'Constants';
import axios from 'axios';
import React, { createContext, useEffect, useMemo, useState } from 'react';

import ResultsDataDownloads from './Results/ResultsDataDownloads';
import ResultsFileList from './Results/ResultsFileList';
import ResultsFileUpload from './Results/ResultsFileUpload';

import UpdateIcon from '@mui/icons-material/Update';
import {
  Button,
  CircularProgress,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

import projects from 'Api/projects';
import results from 'Api/results';

import {
  ACCESS_LEVEL_ADMIN,
  ROLE_SAFETRACES,
  isPartnerEditorWithLabAccess,
} from 'Config/roles';

import { useIsMounted } from 'Context';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  getUserData,
  handleProjectStatusDecision,
  isValidResponse,
} from 'Utils';

export const ResultsContext = createContext({});

function Results({
  loading,
  projectId,
  projectName,
  projectStatus,
  projectCreationDate,
  onError,
  hasFiles,
  hasResults,
  refetchProject,
  hasHealthcareResults,
  hasVerlikeResults,
  hasGeneralResults,
}) {
  const mounted = useIsMounted();

  const { roleName, accessLevel } = getUserData();
  const editPermission =
    (!!~[ACCESS_LEVEL_ADMIN].indexOf(accessLevel) &&
      !!~[ROLE_SAFETRACES].indexOf(roleName)) ||
    isPartnerEditorWithLabAccess();

  const [isFetching, setIsFetching] = useState(false);
  const [filesData, setFilesData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (hasFiles) {
      fetchFileList(source);
    } else if (typeof hasFiles === 'boolean') {
      setFilesData([]);
    }
    return () => {
      source.cancel(UNMOUNT_IGNORED_ERR_MSG);
    };
  }, [hasFiles]);

  const fetchFileList = async (source) => {
    if (!projectId) return;

    setIsFetching(true);

    try {
      const response = await results.getResultsFilesList(projectId, source);

      if (isValidResponse(response)) {
        const data = getDataFromResponse(response);
        if (!Array.isArray(data)) {
          throw new Error();
        }

        const fileNames = data.map(({ filename }) => filename).sort();

        let shouldUpdateList = true;
        if (
          filesData &&
          filesData.length &&
          filesData.length === fileNames.length &&
          fileNames.join('') === filesData.join('')
        ) {
          shouldUpdateList = false;
        }

        if (shouldUpdateList && mounted.current) {
          setFilesData(fileNames || []);
        }
      } else {
        throw new Error(getErrorMessageFromResponse(response));
      }
    } catch (err) {
      if (err.message === UNMOUNT_IGNORED_ERR_MSG) return;

      console.error(`fetchFileList Error: `, err);
      if (mounted.current) {
        setFilesData([]);
      }
      if (typeof onError === 'function') {
        onError();
      }
    } finally {
      if (mounted.current) {
        setIsFetching(false);
      }
    }
  };

  const updateProjectStatus = async (status) => {
    try {
      const projectStatusUpdate = await projects.updateStatus(
        projectId,
        status,
      );
      if (!isValidResponse(projectStatusUpdate)) {
        throw new Error(getErrorMessageFromResponse(projectStatusUpdate));
      }

      return true;
    } catch (err) {
      console.log(`updateProjectStatus Error: `, err);

      return false;
    } finally {
      if (typeof refetchProject === 'function') {
        refetchProject();
      }
    }
  };

  const regenerateResultsSnapshot = async () => {
    setIsLoading(true);
    try {
      const response = await results.regenerateResultsSnapshotForProject(
        projectId,
      );
      if (!isValidResponse(response)) throw new Error();
    } catch (err) {
      console.log('Error: ', err);
    } finally {
      if (typeof refetchProject === 'function') {
        refetchProject();
      }
      setIsLoading(false);
    }
  };

  const ResultsContextValue = useMemo(() => {
    return {
      afterResultFileDelete: async (success, file) => {
        try {
          if (success) {
            const fileIndex = filesData.indexOf(file);

            if (!~fileIndex) throw new Error();

            const newFilesData = filesData
              .slice(0, fileIndex)
              .concat(filesData.slice(fileIndex + 1));

            setFilesData(newFilesData);

            const isLastFile = !newFilesData.length;

            const [shouldUpdate, newStatus] = handleProjectStatusDecision(
              'resultsDelete',
              projectStatus,
              { isLastFile },
            );

            if (shouldUpdate) {
              updateProjectStatus(newStatus);
            } else {
              await fetchFileList();
            }
            refetchProject();
          } else {
            await fetchFileList();
          }
        } catch (err) {
          console.error(`afterResultFileDelete Error: `, err);
          await fetchFileList();
        }
      },
      afterResultFileUpload: async () => {
        const [shouldUpdate, newStatus] = handleProjectStatusDecision(
          'resultsUpload',
          projectStatus,
        );

        if (shouldUpdate) {
          updateProjectStatus(newStatus);
        } else {
          await fetchFileList();
        }
        refetchProject();
        return true;
      },
      editPermission,
    };
  }, [projectStatus, filesData]);

  return (
    <Stack direction="column">
      {(loading || !filesData) && (
        <Stack direction="column" gap={2} sx={{ maxWidth: '800px' }}>
          <Skeleton variant="rounded" height={35} sx={{ maxWidth: '480px' }} />
          {!!editPermission && <Skeleton variant="rounded" height={56} />}
          <Skeleton variant="rounded" height={120} />
        </Stack>
      )}
      {!loading && filesData && (
        <ResultsContext.Provider value={ResultsContextValue}>
          <Stack direction="column" gap={2}>
            {!!editPermission && <ResultsFileUpload projectId={projectId} />}
            {!!editPermission && !!hasResults ? (
              <Stack>
                <Button
                  sx={{ width: 'fit-content' }}
                  onClick={regenerateResultsSnapshot}
                  startIcon={
                    isLoading ? (
                      <CircularProgress color="inherit" size={'1rem'} />
                    ) : (
                      <UpdateIcon />
                    )
                  }
                >
                  Regenerate results snapshot
                </Button>
              </Stack>
            ) : null}
            {!!hasResults && (
              <ResultsDataDownloads
                hasHealthcareResults={hasHealthcareResults}
                hasVerlikeResults={hasVerlikeResults}
                hasGeneralResults={hasGeneralResults}
                projectId={projectId}
                projectName={projectName}
                projectCreationDate={projectCreationDate}
                onError={onError}
              />
            )}
            {!!hasFiles && (
              <ResultsFileList
                projectId={projectId}
                fileList={filesData}
                onError={onError}
              />
            )}
            {typeof hasFiles === 'boolean' && !hasFiles && !isFetching && (
              <Typography>
                There are no results files in the project.
              </Typography>
            )}
          </Stack>
        </ResultsContext.Provider>
      )}
    </Stack>
  );
}

export default React.memo(Results);
