import React from 'react';

import { fileNameUtil } from '../utils.js';

import { Box, Typography } from '@mui/material';

class RenamePanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      errorMsg: null,
      saveFileName:
        props.sourceFileName !== undefined ? props.sourceFileName : null,
      enteredFileName: '',
      canSave: false,
      isTestArea: !!props.isTestArea,
    };

    this.validateFileName = this.validateFileName.bind(this);
    this.onFileNameChange = this.onFileNameChange.bind(this);
  }
  componentDidMount() {
    this.setState({
      enteredFileName: fileNameUtil.removeExtension(this.props.sourceFileName),
    });
  }

  validateFileName(fullFileName, success, error) {
    const fileName = fileNameUtil.removeExtension(fullFileName);

    if (
      fileNameUtil.isFileNameAlreadyUsed(fileName, this.props.floorPlanFiles)
    ) {
      this.setState(
        {
          hasError: true,
          errorMsg: 'The file name has already been used for this project',
          canSave: false,
        },
        error(),
      );
      return;
    }
    if (fileNameUtil.isFileNameInvalid(fileName)) {
      this.setState(
        {
          hasError: true,
          errorMsg: 'The file name is not valid.',
          canSave: false,
        },
        error(),
      );
      return;
    }
    if (this.state.hasError === true || this.state.canSave === false) {
      this.setState(
        {
          hasError: false,
          errorMsg: null,
          canSave: true,
        },
        success(),
      );
    } else {
      success();
    }
  }

  onFileNameChange(e) {
    this.setState({ enteredFileName: e.target.value });
    this.validateFileName(
      e.target.value.trim(),
      () => {
        //success
        if (
          this.props.onChange !== undefined &&
          typeof this.props.onChange === 'function'
        ) {
          const result = {
            target: {
              canSave: true,
              saveFileNameValue: e.target.value.trim(),
            },
          };
          this.props.onChange(result);
        }
      },
      () => {
        if (
          this.props.onChange !== undefined &&
          typeof this.props.onChange === 'function'
        ) {
          const result = {
            target: { canSave: false },
          };
          this.props.onChange(result);
        }
      },
    );
  }

  render() {
    let fileNames = fileNameUtil
      .getFileNamesWithOutExtensions(this.props.floorPlanFiles)
      .map((item, i) => {
        return <div key={i} style={{ marginTop: '8px', width: '100%' }}>{item}</div>;
      });

    const styles = {
      renamePanel: {
        display: 'flex',
      },
      fileList: {
        flex: 1,
        width: '33%',
        //background: 'red',
        marginTop: '20px',
      },
      fileNameEdit: {
        width: '67%',
        //background: 'yellow',
        height: '100%',
        margin: '20px',
      },
    };

    return (
      <Box>
        <Box sx={styles.renamePanel}>
          <Box sx={styles.fileList}>
            <span>
              <Typography variant="h6" style={{ marginTop: '0px' }}>
                File names used in this project
              </Typography>
            </span>
            {fileNames}
          </Box>
          <Box sx={styles.fileNameEdit}>
            <div>
              <Typography variant="body1">
                Floor plan file names are distinct per veriDART<sup>tm</sup>{' '}
                project, so you can't use a file name more than once in a
                project. If you need to use different areas of a single floor
                plan, give the areas meaningful file names, like Break Area,
                Lobby, or whatever makes sense for the project.
              </Typography>
            </div>
            <div style={{ marginTop: '20px' }}>
              <Typography variant="body1">
                The following special characters are not allowed in a file name:
              </Typography>
            </div>
            <div>
              <Typography variant="caption">\ / : * ? " {'< >'} |</Typography>
            </div>
            <div style={{ marginTop: '20px' }}>
              <label>Floor plan file name:</label>
              <input
                type="text"
                id="floorPlanName"
                name="floorPlanName"
                onChange={this.onFileNameChange}
                style={{ width: '100%' }}
                value={this.state.enteredFileName}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <Typography variant="body1" style={{ color: 'red' }}>
                {this.state.errorMsg}
              </Typography>
            </div>
          </Box>
        </Box>
      </Box>
    );
  }
}
export default RenamePanel;
