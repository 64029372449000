import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Delete, Save } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import buildings from 'Api/buildings';

import { usePortfolioContext } from 'Components/Portfolios/context';

const EditRegion = ({ id, cancel }) => {
  const [region, setRegion] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { handleSubmit, register, setValue, formState } = useForm();
  const { fetchPortfoliosAndSites } = usePortfolioContext();

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      fetchRegionById(id);
    }
  }, []);

  const fetchRegionById = async (id) => {
    const data = await buildings.getRegion(id);
    setRegion(data?.data?.data ?? null);
    setValue('name', data?.data?.data?.name ?? '');
    setIsLoading(false);
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    buildings
      .updateRegion(region.id, data)
      .then(() => {
        fetchPortfoliosAndSites();
        cancel();
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelete = () => {
    setIsLoading(true);
    buildings
      .deleteRegion(region.id)
      .then(() => {
        fetchPortfoliosAndSites();
        cancel();
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderForm = () => {
    if (region && !isLoading) {
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <TextField
              id="region-id"
              fullWidth
              label="Region name"
              required
              margin="normal"
              {...register('name')}
            />
          </FormControl>
          {region?.territories?.length > 0 && (
            <Alert severity="warning" sx={{ marginTop: 1 }}>
              You cannot delete the region because it has the following
              territories associated with it:{' '}
              {region.territories.map((t) => t.name).join(', ')}
            </Alert>
          )}
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              startIcon={<Save />}
              disabled={!formState.isDirty}
              type="submit"
            >
              Save
            </Button>
            <ButtonGroup>
              <Button
                startIcon={<Delete />}
                color="error"
                disabled={region?.territories?.length > 0 ?? true}
                onClick={handleDelete}
              >
                Delete
              </Button>
              <Button onClick={cancel}>Cancel</Button>
            </ButtonGroup>
          </Stack>
        </form>
      );
    }
    return null;
  };

  return (
    <Stack p={2} spacing={2}>
      {isLoading && <LinearProgress />}
      <Typography variant="h5">Edit region {id}</Typography>
      <Box py={2}>{renderForm()}</Box>
      {!!error && (
        <Alert severity="error">Error when updating the region</Alert>
      )}
    </Stack>
  );
};

export default EditRegion;
