import React, { useMemo, useState } from 'react';

import { Box, Divider, List, Typography } from '@mui/material';

import floorPlansApi from 'Api/floorPlans';

import DeleteDialog from 'Components/Dialogs/DeleteDialog';
import { useFloorplanContext } from 'Components/Floorplans/context';
import FloorplanListItem from 'Components/SingleProject/ProjectDownloads/Floorplans/FloorplanListItem';
import FloorplanEditModal from 'Components/SingleProject/ProjectDownloads/Floorplans/Modals/FloorplanEditModal';

import { useIsMounted } from 'Context';

import { getErrorMessageFromResponse, isValidResponse } from 'Utils';

export default function FloorplansListSelectable({
  selectedFPId,
  handleSelect,
  handleDownload,
  handleRedirectToHDA,
  handleTestAreaCreationStart,
  loading,
}) {
  const {
    fetchAllData,
    floorPlans,
    projectData,
    setError,
    isLoading,
    setIsLoading,
  } = useFloorplanContext();

  const mounted = useIsMounted();

  const [showEditModal, setShowEditModal] = useState(false);
  const [fileToEdit, setFileToEdit] = useState(null);

  const [shouldConfirmDelete, setShouldConfirmDelete] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  /**
   * Sort all floorplans. Main - those with self image on S3,
   * children - areas related to main floorplans.
   */
  const mainFloorplans = useMemo(() => {
    return floorPlans.reduce((res, floorPlan) => {
      const { floorplanid, mainfloorplanid } = floorPlan;
      if (!mainfloorplanid) {
        if (!res[floorplanid]) {
          res[floorplanid] = {
            _children: [],
          };
        }

        res[floorplanid] = { ...floorPlan, ...res[floorplanid] };
      }

      if (mainfloorplanid) {
        if (!res[mainfloorplanid]) {
          res[mainfloorplanid] = {
            _children: [],
          };
        }

        res[mainfloorplanid]._children.push(floorPlan);
      }

      return res;
    }, {});
  }, [floorPlans]);

  const listHeadersConfig = Object.values(mainFloorplans).reduce((conf, fp) => {
    const floorNumber = parseInt(fp.floornumber) || -1;
    if (!conf[floorNumber]) {
      conf[floorNumber] = {
        text: ~floorNumber ? `Floor ${floorNumber}` : 'Other',
        value: floorNumber,
      };
    }
    return conf;
  }, {});

  /**
   * Open modal for editing floor plan details
   * @param floorPlan
   */
  const handleModalEditOpen = (
    id,
    name,
    address,
    floorNumber,
    buildingId,
    hasParentFP,
    hasConnectionToAnyTest,
  ) => {
    setFileToEdit({
      id,
      name,
      address,
      floorNumber,
      hasParentFP,
      buildingId,
      hasConnectionToAnyTest,
    });
    setShowEditModal(true);
  };

  /**w
   * Close edit modal
   */
  const handleModalEditClose = () => {
    setShowEditModal(false);
    setTimeout(() => setFileToEdit(null), 500);
  };

  /**
   * Update details about floor plan (address, floor number)
   */
  const handleEdit = async (values) => {
    setIsLoading(true);

    try {
      const { id, name, address, floorNumber, buildingid } = values;
      const bodyObj = {
        fileName: name,
        address,
        buildingid,
        floornumber: floorNumber,
      };

      const response = await floorPlansApi.updateFloorPlan(id, bodyObj);

      if (isValidResponse(response)) {
        fetchAllData();
        handleModalEditClose();
      } else throw new Error(getErrorMessageFromResponse(response));
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const openDeleteConfirmation = (id, name, buildingid, isOk) => {
    setShouldConfirmDelete(true);
    setFileToDelete({ id, name, buildingid, isOk });
  };

  const resetFileDelete = () => {
    if (mounted.current) {
      setShouldConfirmDelete(false);
      setTimeout(() => setFileToDelete(null), 200);
    }
  };

  /**
   * Removing of a floor plan
   * @param id
   * @returns {Promise<void>}
   */
  const handleDelete = async () => {
    setIsLoading(true);

    try {
      const { id, isOk, buildingid, name } = fileToDelete;

      let response;
      if (!isOk) {
        response = await floorPlansApi.deleteBrokenFloorPlan(
          id,
          buildingid,
          name,
        );
      } else {
        response = await floorPlansApi.deleteFloorPlan(id);
      }
      if (isValidResponse(response)) {
        await fetchAllData();
      } else throw new Error(getErrorMessageFromResponse(response));
    } catch (err) {
      setError(err.message);
    } finally {
      if (mounted.current) {
        setIsLoading(false);
        resetFileDelete();
      }
    }
  };

  const renderFloorplansList = (floorNumber, parentId = null) => {
    const floorplansToRender = parentId
      ? mainFloorplans[parentId]._children
      : Object.values(mainFloorplans).filter((fp) => {
          const _floorNumber = parseInt(fp.floornumber) || -1;
          return _floorNumber === floorNumber;
        });

    const items = floorplansToRender.map(
      (
        {
          floorplanid: id,
          filename,
          address,
          floornumber,
          buildingid,
          projectid,
          metadata,
          isOk,
          hasConnectionToAnyTest,
        },
        i,
      ) => (
        <React.Fragment key={i}>
          <FloorplanListItem
            selectable
            fpId={id}
            name={filename}
            address={address}
            hasParentFP={!!parentId}
            isOk={isOk}
            buildingid={buildingid}
            floorNumber={floornumber}
            handleEdit={() =>
              handleModalEditOpen(
                id,
                filename,
                address,
                floornumber,
                buildingid,
                !!parentId,
                hasConnectionToAnyTest,
              )
            }
            editDisabled={projectData?.testCompleted}
            handleDelete={openDeleteConfirmation}
            deleteDisabled={fileToDelete?.id === id}
            isSelected={+id === +selectedFPId}
            handleDownload={() => handleDownload(id)}
            handleSelect={() =>
              handleSelect(
                id,
                filename,
                buildingid,
                projectid,
                parentId,
                metadata,
              )
            }
            loading={loading || isLoading}
            handleRedirectToHDA={(e) => handleRedirectToHDA(e, id)}
            handleTestAreaCreationStart={handleTestAreaCreationStart}
          />
          {i !== floorplansToRender.length - 1 && (
            <Divider
              sx={{
                width: '90%',
                display: 'flex',
                ml: 'auto',
                mr: 'auto',
              }}
            />
          )}
          {!parentId && (
            <Box width="95%" sx={{ ml: 'auto' }}>
              <List>{renderFloorplansList(floorNumber, id)}</List>
            </Box>
          )}
        </React.Fragment>
      ),
    );

    return items;
  };

  if (!Array.isArray(floorPlans)) {
    return null;
  }

  return (
    <>
      <List>
        {Object.values(listHeadersConfig).map((conf) => (
          <React.Fragment key={conf.value}>
            <Typography variant="h6" sx={{ ml: '1rem' }}>
              {conf.text}
            </Typography>
            {renderFloorplansList(conf.value)}
          </React.Fragment>
        ))}
      </List>
      <FloorplanEditModal
        open={showEditModal}
        handleClose={() => handleModalEditClose()}
        handleSubmit={handleEdit}
        file={fileToEdit || {}}
      />
      <DeleteDialog
        open={shouldConfirmDelete}
        itemName={fileToDelete?.name}
        handleClose={resetFileDelete}
        isLoading={isLoading}
        handleDelete={handleDelete}
      />
    </>
  );
}
