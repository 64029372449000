import React, { useCallback, useContext, useState } from 'react';

import { ResultsContext } from '../Results';
import '../styles.css';

import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
} from '@mui/material';

import results from 'Api/results';

import DeleteDialog from 'Components/Dialogs/DeleteDialog';

import { useIsMounted } from 'Context';

import { getErrorMessageFromResponse, isValidResponse } from 'Utils';

const FileListItem = React.memo(function FileListItem({
  name,
  handleDownload,
  handleDelete,
  isDownloading,
  editPermission,
}) {
  return (
    <ListItem sx={{ pl: 0 }}>
      <ListItemIcon>
        <IconButton
          size="small"
          color="primary"
          onClick={() => handleDownload(name)}
          disabled={isDownloading}
          className={isDownloading ? 'icon-btn-loading-color' : ``}
          sx={{ mr: 1 }}
        >
          <FileDownloadOutlinedIcon />
        </IconButton>
      </ListItemIcon>
      <ListItemText sx={{ pr: '96px ' }} primary={name} />
      {!!editPermission && (
        <ListItemSecondaryAction>
          <IconButton
            size="small"
            color="error"
            onClick={() => handleDelete(name)}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
});

function ResultsFileList({ fileList, projectId }) {
  const mounted = useIsMounted();

  const [shouldConfirmDelete, setShouldConfirmDelete] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const [isFetching, setIsFetching] = useState(false);

  const [downloadingFiles, setDownloadingFiles] = useState([]);

  const { afterResultFileDelete, editPermission } = useContext(ResultsContext);

  const openDeleteConfirmation = useCallback(
    (name) => {
      setShouldConfirmDelete(true);
      setFileToDelete(name);
    },
    [projectId],
  );

  const resetFileDelete = useCallback(() => {
    if (mounted.current) {
      setShouldConfirmDelete(false);
      setTimeout(() => setFileToDelete(null), 500);
    }
  }, []);

  const handleDownloadFile = useCallback(
    async (name) => {
      downloadingFiles.push(name);
      setDownloadingFiles([...downloadingFiles]);

      try {
        const response = await results.getPresignedURL(projectId, name);
        if (response?.data?.preSignedURL) {
          const { preSignedURL } = response.data;

          const link = document.createElement('a');

          link.setAttribute('href', preSignedURL);
          link.setAttribute('download', `download`);
          document.body.appendChild(link); // Required for FF

          link.click();
          link.remove();
        }
      } catch (err) {
        console.error(`handleDownloadFile error: `, err);
      } finally {
        if (mounted.current && !!~downloadingFiles.indexOf(name)) {
          const newListDownloads = downloadingFiles.filter(
            (fileName) => fileName !== name,
          );
          setDownloadingFiles(newListDownloads);
        }
      }
    },
    [projectId, downloadingFiles],
  );

  const handleFileDelete = useCallback(async () => {
    setIsFetching(true);

    let isSuccess = false;
    try {
      const response = await results.deleteResultsFile(projectId, fileToDelete);

      isSuccess = isValidResponse(response);

      if (!isSuccess) {
        throw new Error(getErrorMessageFromResponse(response));
      }
    } catch (err) {
      console.log('Delete file error: ', err);
    } finally {
      if (mounted.current) {
        setIsFetching(false);
      }
      if (isSuccess) {
        resetFileDelete();
      }

      if (typeof afterResultFileDelete === 'function') {
        afterResultFileDelete(isSuccess, fileToDelete);
      }
    }
  }, [projectId, fileToDelete]);

  return (
    <React.Fragment>
      <Stack direction="column" sx={{ maxWidth: '800px' }}>
        <List>
          {fileList.map((name, i) => (
            <React.Fragment key={i}>
              <FileListItem
                name={name}
                key={i}
                isDownloading={!!~downloadingFiles.indexOf(name)}
                handleDownload={handleDownloadFile}
                handleDelete={openDeleteConfirmation}
                editPermission={editPermission}
              />
              {i !== fileList.length - 1 && (
                <Divider
                  sx={{
                    width: '90%',
                    display: 'flex',
                    ml: 'auto',
                    mr: 'auto',
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </List>
      </Stack>
      {!!editPermission && (
        <DeleteDialog
          open={shouldConfirmDelete}
          itemName={fileToDelete}
          handleClose={resetFileDelete}
          loading={isFetching}
          handleDelete={handleFileDelete}
        />
      )}
    </React.Fragment>
  );
}

export default React.memo(ResultsFileList);
