import { createSlice } from '@reduxjs/toolkit';
import { PROGRESS } from 'Constants';

const inProgress = JSON.parse(localStorage.getItem(PROGRESS)) ? true : false;

export const slice = createSlice({
  name: 'inProgress',
  initialState: {
    inProgress: inProgress,
  },
  reducers: {
    setInProgress: (state, action) => {
      const inProgress = action.payload;

      localStorage.setItem(PROGRESS, inProgress);

      state.inProgress = inProgress;
    },
  },
});

export const { setInProgress } = slice.actions;

export default slice.reducer;

export const inProgressSelector = (state) => state.inProgress;
