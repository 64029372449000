import stringSimilarity from 'string-similarity';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ArticleIcon from '@mui/icons-material/Article';
import BiotechIcon from '@mui/icons-material/Biotech';
import BusinessIcon from '@mui/icons-material/Business';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import Dashboard from '@mui/icons-material/Dashboard';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import Event from '@mui/icons-material/Event';
import Group from '@mui/icons-material/Group';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import SummarizeIcon from '@mui/icons-material/Summarize';

//icons from Config/nav.js
const icons = {
  Event,
  PlaylistAddCheckIcon,
  BiotechIcon,
  Dashboard,
  Group,
  BusinessIcon,
  LocalOfferIcon,
  ApartmentIcon,
  ArticleIcon,
  SummarizeIcon,
  AdminPanelSettingsIcon,
  BusinessCenterIcon,
  DesignServicesIcon,
};

function useIcons(word) {
  const iconsNames = Object.keys(icons);

  const matches = stringSimilarity.findBestMatch(word, iconsNames);
  const bestMatch = matches.bestMatch.target;
  return icons[bestMatch];
}
export default useIcons;
