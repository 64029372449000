import {
  SP_COLLECTION_METHODS,
  SP_COLLECTION_METHODS_FLEX,
  TEST_TYPES,
} from 'Constants';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Save } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import segmentsApi from 'Api/segments';

const EditSampleType = ({
  cancel,
  value,
  testId,
  reFetchProject,
  testType,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const selectOptions =
    testType === TEST_TYPES.RECIRCULATION
      ? SP_COLLECTION_METHODS_FLEX
      : SP_COLLECTION_METHODS;
  const { handleSubmit, register, formState, errors } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);
    segmentsApi
      .updateSamples({
        testId: testId,
        collectionMethod: data.smapleType,
      })
      .then(() => {
        reFetchProject();
        cancel();
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderForm = () => {
    if (!isLoading) {
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <TextField
              select
              variant="outlined"
              fullWidth
              label="Sample Type"
              defaultValue={value}
              {...register('smapleType')}
              error={!!(errors && errors.smapleType)}
              helperText={errors?.smapleType?.message}
            >
              {Object.keys(selectOptions).map((key) => (
                <MenuItem key={key} value={key}>
                  <Typography>{selectOptions[key]}</Typography>
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              startIcon={<Save />}
              disabled={!formState.isDirty}
              type="submit"
            >
              Save
            </Button>
            <ButtonGroup>
              <Button onClick={cancel}>Cancel</Button>
            </ButtonGroup>
          </Stack>
        </form>
      );
    }
    return null;
  };

  return (
    <Stack p={2} spacing={2}>
      {isLoading && <LinearProgress />}
      <Typography variant="h5">Edit sample type</Typography>
      <Box py={2}>{renderForm()}</Box>
      {!!error && (
        <Alert severity="error">Error when updating the sample type</Alert>
      )}
    </Stack>
  );
};

export default EditSampleType;
