import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { logout, useAuthDispatch, useIsMounted } from '../../Context';
import SelectCompanyList from '../Select/SelectCompanyList';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const SelectCompanyModal = ({ open, handleClose }) => {
  const mounted = useIsMounted();
  const [isAnyChoice, setIsAnyChoice] = useState(null);
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();

  const headerOptions = {
    choose: `Please, choose the company`,
    list_empty: `You don't have any associated customer company`,
  };

  const handleLogout = () => {
    logout(dispatch);

    navigate('/login');
  };

  const handleContentAlert = (val) => {
    if (mounted.current) {
      setIsAnyChoice(val);
    }
  };

  return (
    <Dialog
      open={open}
      transitionDuration={{ exit: 500 }}
      disableEscapeKeyDown
      PaperProps={{
        sx: {
          padding: 4,
          justifyContent: 'center',
          alignItems: 'center',
          visibility: 'visible',
          width: '540px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          whiteSpace: 'nowrap',
        }}
      >
        {!(isAnyChoice === false)
          ? headerOptions['choose']
          : headerOptions['list_empty']}
      </DialogTitle>
      <DialogContent>
        {!(isAnyChoice === false) ? (
          <SelectCompanyList
            sx={{ width: '400px', maxWidth: '70vw', pt: 1, pb: 2 }}
            afterUpdateFn={handleClose}
            alertIsContent={(value) => handleContentAlert(value)}
          />
        ) : (
          <Button variant="contained" onClick={handleLogout}>
            Log out
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(SelectCompanyModal);
