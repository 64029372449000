import React from 'react';

import { Tooltip } from '@mui/material';

import { SecondaryButtonStyled, greenColor } from 'Layout/layoutStyles';

export default function SecondaryButton({
  clickAction,
  sx,
  text,
  tooltipText = '',
  disabled = true,
}) {
  return (
    <Tooltip title={tooltipText} placement="top">
      <span>
        <SecondaryButtonStyled
          variant="outlined"
          sx={{
            height: '100%',
            backgroundColor: disabled ? 'rgba(0, 0, 0, 0.12)' : greenColor,
            ...sx,
          }}
          onClick={clickAction}
          disabled={disabled}
        >
          {text}
        </SecondaryButtonStyled>
      </span>
    </Tooltip>
  );
}
