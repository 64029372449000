/* eslint-disable */

class ProjectDataModel {
  constructor(
    projectId,
    _project,
    _tests,
    _segments,
    _samplePoints,
    _sampleCollectors,
    _samples,
    _originPoints,
    _scenarios,
    _floorPlans,
  ) {
    this.projectId = projectId;
    this.project = _project;
    this.tests = _tests || [];
    this.segments = _segments || [];
    this.originPoints = _originPoints || [];
    this.samplePoints = _samplePoints || [];
    this.samples = _samples || [];
    this.sampleCollectors = _sampleCollectors || [];
    this.scenarios = _scenarios || [];
    this.floorPlans = _floorPlans || [];

    this.tags = [];
    this.intervals = [];
  }

  build() {
    this.normalize();
  }

  addSegmentValuesToTests() {
    this.tests.map((test) => {
      const s = this.segments.filter((s) => s.testId === test.id);
      if (s.length > 0) {
        test.opCount = s.reduce((a, b) => ({
          opCount: a.opCount + b.opCount,
        })).opCount;
        test.spCount = s.reduce((a, b) => ({
          spCount: a.spCount + b.spCount,
        })).spCount;
        test.floorplan = s[0].floorplan;
        test.intervalCount = s[0].intervalCount;
        test.intervalDuration = Number(s[0].intervalDuration);
        test.testDate = s[0].surveyDate;
      } else {
        test.opCount = 0;
        test.spCount = 0;
        test.floorplan = null;
        test.intervalCount = null;
        test.intervalDuration = null;
        test.testDate = null;
      }
    });
  }

  addTestsMetadataToTests() {
    this.scenarios.map((scenario) => {
      scenario.testmetadata = this.segments.filter(
        (segment) => segment.id === scenario.segmentid,
      )[0]?.testmetadata?.scenariosData?.[scenario.name];
    });
  }

  addCollectionMethodsToTest() {
    this.tests.map((test) => {
      let collectionMethods = [];

      const samplePoints = this.samplePoints.filter(
        (s) => s.testId === test.id,
      );
      //console.log(samplePoints);
      if (samplePoints.length > 0) {
        const cm = samplePoints[0].collectionMethod;

        if (cm === 'filterandswab') {
          collectionMethods.push('filter');
          collectionMethods.push('swab');
        } else if (cm !== undefined && cm !== null) {
          collectionMethods.push(cm);
        } else {
          collectionMethods.push('n/a');
        }
      } else {
        collectionMethods.push('n/a');
      }
      test.collectionMethods = collectionMethods;
    });
  }

  addPlaceholderScenarioToTests() {
    this.tests.map((test) => {
      const s = this.scenarios.filter((s) => s.testId === test.id);
      if (s.length < 1) {
        this.scenarios.push({
          testId: test.id,
          description: 'default',
          id: null,
          name: 'scenario1',
          segmentid: null,
        });
      }
    });
  }

  addSequentialNumberToTests() {
    let number = 0;

    this.tests
      .sort(function (a, b) {
        if (a.testordernumber && b.testordernumber) {
          if (a.testordernumber < b.testordernumber) {
            return -1;
          }
          if (a.testordernumber > b.testordernumber) {
            return 1;
          }
        } else {
          if (a.id < b.id) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }
        }

        return 0;
      })
      .map((test) => {
        test.sequenceNumber = ++number;
      });
  }

  addSequentialNumberToScenarios() {
    this.tests.map((test) => {
      let number = 0;
      this.scenarios
        .filter((s) => s.testId === test.id)
        .map((scenario) => {
          scenario.sequenceNumber = ++number;
        });
    });
  }

  addScenarioSequenceNumberToOpTags() {
    this.scenarios.map((scenario) => {
      let originPoints = this.originPoints.filter(
        (s) => s.testId === scenario.testId,
      );

      // a null scenario id means it was added for general survey tests that have no scenarios, may also be mini survey??

      if (originPoints.length > 0) {
        originPoints.map((op) => {
          if (op.tags !== null && op.tags.length > 0) {
            op.tags.map((tag) => {
              if (scenario.id !== null) {
                if (tag.scenarioId == scenario.id) {
                  tag.scenarioSequenceNumber = scenario.sequenceNumber;
                }
              } else {
                tag.scenarioSequenceNumber = 1;
              }
            });
          }
        });
      }
    });
  }

  addIntervalNumberAndScenarioSequenceNumberToSamples() {
    this.tests.map((test) => {
      let sampleTypes = [];
      this.samples
        .filter((s) => s.testId === test.id)
        .map((sample) => {
          if (!sampleTypes.includes(sample.type)) {
            sampleTypes.push({ type: sample.type, count: 0 });
          }
        });

      this.samplePoints
        .filter((s) => s.testId === test.id)
        .map((samplePoint) => {
          this.scenarios
            .filter((s) => s.testId === test.id)
            .sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.spName) {
                return 1;
              }
              return 0;
            })
            .map((scenario) => {
              sampleTypes.map((sampleType) => {
                sampleType.count = 0;
              });

              this.samples
                .filter(
                  (s) =>
                    s.testId === test.id &&
                    s.spId === samplePoint.id &&
                    s.scenarioId === scenario.id,
                )
                .sort(function (a, b) {
                  if (Number(a.startTime) < Number(b.startTime)) {
                    return -1;
                  }
                  if (Number(a.startTime) > Number(b.startTime)) {
                    return 1;
                  }
                  return 0;
                })
                .map((sample) => {
                  //console.log(sampleTypes.filter(t => t.type == sample.type));
                  sample.intervalNumber =
                    sampleTypes.filter((t) => t.type == sample.type)[0]
                      .count++ + 1;
                  sample.scenarioSequenceNumber = scenario.sequenceNumber;
                });
            });
        });
    });
  }

  normalize() {
    this.addSegmentValuesToTests();
    this.addPlaceholderScenarioToTests();
    this.addSequentialNumberToTests();
    this.addSequentialNumberToScenarios();
    this.addIntervalNumberAndScenarioSequenceNumberToSamples();
    this.addCollectionMethodsToTest();
    this.addScenarioSequenceNumberToOpTags();
    this.addTestsMetadataToTests();
  }

  getOriginPoints(testId) {
    //console.log("getOriginPoints " + testId);
    const result = this.originPoints
      .filter((item) => item.testId === testId)
      .sort(function (a, b) {
        const aNum = a.number.toUpperCase();
        const bNum = b.number.toUpperCase();
        if (aNum < bNum) {
          return -1;
        }
        if (aNum > bNum) {
          return 1;
        }
        return 0;
      });
    //console.log(result);
    return result;
  }

  getOriginPointsByScenario(scenarioId) {
    return this.originPoints
      .map((item) => {
        if (
          item.tags !== null &&
          item.tags.filter((tag) => tag.scenarioId === scenarioId) > 0
        ) {
          return item;
        }
      })
      .filter((item) => item !== undefined)

      .sort(function (a, b) {
        const aNum = a.number.toUpperCase();
        const bNum = b.number.toUpperCase();
        if (aNum < bNum) {
          return -1;
        }
        if (aNum > bNum) {
          return 1;
        }
        return 0;
      });
  }

  getSamplePoints(testId) {
    return this.samplePoints
      .filter((item) => item.testId === testId)
      .sort(function (a, b) {
        const aNum = a.number.toUpperCase();
        const bNum = b.number.toUpperCase();
        if (aNum < bNum) {
          return -1;
        }
        if (aNum > bNum) {
          return 1;
        }
        return 0;
      });
  }

  getScenarios(testId) {
    return this.scenarios
      .filter((item) => item.testId === testId)
      .sort(function (a, b) {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      });
  }

  getTests() {
    return this.tests.sort(function (a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
  }

  getFloorPlan(testId) {
    const item = this.floorPlans.find((fp) => fp.testId === testId);
    if (item) {
      return item.floorPlanData;
    } else {
      return {};
    }
  }
}

export default ProjectDataModel;
