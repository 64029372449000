import React, { useState } from 'react';

import contractsApi from 'Api/contracts';
import portfoliosApi from 'Api/portfolios';

import DeleteBorderButton from 'Components/Buttons/DeleteBorderButton';
import DeleteDialog from 'Components/Dialogs/DeleteDialog';
import { usePortfolioContext } from 'Components/Portfolios/context';

import { isSafetracesAdmin } from 'Config/roles';

import { isTrainingCompanySelected, isValidStatusResponse } from 'Utils';

const DeletePortfolioButton = ({ portfolio }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setErrorToast, setSuccessToast, fetchPortfoliosAndSites } =
    usePortfolioContext();

  const canForceDelete = isSafetracesAdmin() && isTrainingCompanySelected();
  const isUsed = Object.values(portfolio.contracts).length !== 0;
  const useForceDelete = isUsed && canForceDelete;

  const handleDeleteButtonClick = () => {
    setIsOpen(true);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  let tooltipText = null;
  if (useForceDelete) {
    if (isUsed) {
      tooltipText =
        'Force delete the portfolio and all its contracts and entities';
    } else {
      tooltipText = 'Force delete the portfolio and all its entities';
    }
  }

  const handleActualDelete = async () => {
    try {
      setIsLoading(true);
      if (!portfolio?.portfolioid) {
        setIsLoading(false);
        setErrorToast('Cannot delete portfolio, no ID specified.');
        return;
      }
      if (!useForceDelete) {
        const response = await portfoliosApi.removePortfolioDeep(
          portfolio.portfolioid,
        );
        if (isValidStatusResponse(response)) {
          handleDialogClose();
          await fetchPortfoliosAndSites();
          setSuccessToast('Portfolio successfully deleted');
        } else {
          setErrorToast(response.data?.error?.message);
        }
      } else {
        // Before using the portfolio force delete endpoint, we have to delete all the contracts inside it
        const contractIds = Object.keys(portfolio.contracts);
        await Promise.all(
          contractIds.map((contractId) =>
            contractsApi.removeContractDeep(contractId),
          ),
        );
        const response = await portfoliosApi.removePortfolioDeep(
          portfolio.portfolioid,
        );
        if (isValidStatusResponse(response)) {
          handleDialogClose();
          await fetchPortfoliosAndSites();
          setSuccessToast('Portfolio successfully force-deleted');
        } else {
          setErrorToast('Unable to force-delete portfolio, unexpected error.');
        }
      }
    } catch (e) {
      setErrorToast(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <DeleteBorderButton
        disabled={isUsed && !canForceDelete}
        clickAction={handleDeleteButtonClick}
        tooltipText={tooltipText}
        text={
          useForceDelete ? 'Force delete the portfolio' : 'Delete portfolio'
        }
      />
      <DeleteDialog
        open={isOpen}
        handleClose={handleDialogClose}
        handleDelete={handleActualDelete}
        isLoading={isLoading}
        title={isUsed ? 'Force delete the portfolio' : 'Delete portfolio'}
        extraText={
          useForceDelete
            ? 'This will delete the portfolio and all associated entities, including files hosted on the S3 file server. ' +
              'It will also cancel and delete all contracts, site contracts etc. ' +
              'This action cannot be undone.'
            : 'This action will also delete the linked entities (buildings, sites, regions & territories), including any files linked to those entities, such as floor plans, HDA files, etc.'
        }
        itemName={portfolio.portfolioname}
      />
    </>
  );
};

export default DeletePortfolioButton;
