import { LEGACY_TEST_TYPES, TEST_TYPES, TEST_TYPES_LABELS } from 'Constants';
import React, { forwardRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Grid, MenuItem, TextField, Typography } from '@mui/material';

const AddTestForm = forwardRef((props, ref) => {
  const { onSubmit, defaultValues, hasVerification, hasHealthcare, buildings } =
    props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues || {},
  });

  const [types, setTypes] = useState([]);

  useEffect(() => {
    const types = [];

    const typesToMap = Object.entries(TEST_TYPES).reduce(
      (res, [key, value]) => {
        if ([TEST_TYPES.GENERAL, ...LEGACY_TEST_TYPES].includes(value)) {
          return res;
        }

        res[key] = value;
        return res;
      },
      {},
    );
    const typesLabels = TEST_TYPES_LABELS;

    for (const value of Object.values(typesToMap)) {
      types.push({ name: typesLabels[value], type: value });
    }

    setTypes(types);
  }, [hasVerification, hasHealthcare]);

  const defaultBuilding = Array.isArray(buildings)
    ? buildings[0]?.buildingid
    : null;

  return (
    <form ref={ref} style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Name"
            {...register('name', {
              required: 'Test name field is required',
              minLength: {
                value: 3,
                message: 'Name must have at least 3 characters',
              },
            })}
            error={!!(errors && errors.name)}
            helperText={errors?.name?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Goal"
            {...register('goal', {
              required: 'Goal is required',
            })}
            error={!!(errors && errors.goal)}
            helperText={errors?.goal?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Overview"
            {...register('overview', {
              required: 'Overview is required',
            })}
            error={!!(errors && errors.overview)}
            helperText={errors?.overview?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            variant="outlined"
            fullWidth
            label="Type"
            defaultValue={''}
            sx={{ minWidth: 153, mt: 1 }}
            {...register('type', {
              required: 'Test type is required',
            })}
            error={!!(errors && errors.type)}
            helperText={errors?.type?.message}
          >
            {types.map(({ type, name }) => (
              <MenuItem key={type} value={type}>
                <Typography>{name}</Typography>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <TextField
            select
            variant="outlined"
            fullWidth
            label="Building"
            defaultValue={defaultBuilding}
            sx={{ minWidth: 153, mt: 1 }}
            {...register('building')}
            error={!!(errors && errors.building)}
            helperText={errors?.building?.message}
          >
            {buildings?.map(({ buildingid, buildingname }) => (
              <MenuItem key={buildingid} value={buildingid}>
                <Typography>{buildingname}</Typography>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </form>
  );
});

AddTestForm.displayName = 'AddTestForm';

export default AddTestForm;
