import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Save } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import tests from 'Api/tests';

const EditSprayer = ({ cancel, value, testId, reFetchProject, sprayers }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { handleSubmit, register, formState, errors } = useForm();

  const onSubmit = (data) => {
    let sprayer = null;
    sprayers.forEach((value) => {
      if (value.sprayername === data.sprayer) {
        sprayer = value.sprayerid;
      }
    });
    if (sprayer) {
      setIsLoading(true);
      tests
        .updateOneTest(testId, {
          sprayerId: sprayer,
        })
        .then(() => {
          reFetchProject();
          cancel();
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      cancel();
    }
  };

  const renderForm = () => {
    if (!isLoading) {
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <TextField
              select
              variant="outlined"
              fullWidth
              label="Sprayer"
              defaultValue={value}
              {...register('sprayer')}
              error={!!(errors && errors.sprayer)}
              helperText={errors?.sprayer?.message}
            >
              {sprayers.map((value) => (
                <MenuItem key={value.sprayername} value={value.sprayername}>
                  <Typography>{value.sprayername}</Typography>
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              startIcon={<Save />}
              disabled={!formState.isDirty}
              type="submit"
            >
              Save
            </Button>
            <ButtonGroup>
              <Button onClick={cancel}>Cancel</Button>
            </ButtonGroup>
          </Stack>
        </form>
      );
    }
    return null;
  };

  return (
    <Stack p={2} spacing={2}>
      {isLoading && <LinearProgress />}
      <Typography variant="h5">Edit sprayer</Typography>
      <Box py={2}>{renderForm()}</Box>
      {!!error && <Alert severity="error">Error when updating sprayer</Alert>}
    </Stack>
  );
};

export default EditSprayer;
