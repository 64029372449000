import React, { useState } from 'react';

import { useTestScenariosContext } from '../context';

import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton, Stack, Tooltip } from '@mui/material';

import ErrorMessage from 'Components/UI/ErrorMessage';

import { isSafetracesAdmin } from 'Config/roles';

export default function EditScenarioSettingsButton({ scenarioId, sx }) {
  const {
    startEditScenarioProcess,
    editPermission,
    forceRestrictAllEdits,
    forceRestrictAllEditsReason,
  } = useTestScenariosContext();

  const [mainError, setMainError] = useState('');
  const overrideEditPermissionSafetracesAdminIgroneWorkflow =
    isSafetracesAdmin();

  return (
    <Stack>
      <Tooltip title={forceRestrictAllEditsReason || ''} placement="top">
        <span>
          <IconButton
            size="small"
            sx={{ ...sx }}
            disabled={
              !overrideEditPermissionSafetracesAdminIgroneWorkflow &&
              (!editPermission || forceRestrictAllEdits)
            }
            onClick={() =>
              startEditScenarioProcess(scenarioId, '', 'restricted')
            }
          >
            <SettingsIcon fontSize="inherit" />
          </IconButton>
        </span>
      </Tooltip>
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
    </Stack>
  );
}
