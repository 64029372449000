import {
  AIR_DISTRIBUTION_EFFECT_OPTIONS,
  MERV_FILTER_RATING_OPTIONS,
} from 'Constants';
import React, { forwardRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import AirDistributionExplanationModal from '../Modals/AirDistributionExplanationModal';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Grid, IconButton, MenuItem, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const dataKeysLabels = {
  name: 'Name',
  floorServed: `Floor(s) served`,
  servedArea: 'Served Area',
  servedCeilingHeight: 'Served Ceiling',
  totalSupplyAir: 'Total Supply Air',
  totalOutdoorAir: 'Total Outdoor Air',
};

const CalculatorForm = forwardRef((props, ref) => {
  const { onSubmit, defaultValues, viewMode } = props;

  const [explanationModalVisible, setExplanationModalVisible] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues || {
      airDistributionEffectiveness: 1,
    },
  });

  const TextFieldDisabledViewOnly = styled(TextField)(() => ({
    '.MuiFormControl-root &.MuiTextField-root': {},
    '.MuiFormLabel-root': {
      '&.MuiInputLabel-root': {
        '&.Mui-disabled': {
          color: 'unset',
        },
      },
    },
    '.MuiInputBase-root': {
      '&.MuiInput-root': {
        '&.Mui-disabled input': {
          WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)',
          color: 'rgba(0, 0, 0, 0.87)',
        },
        '&::before': {
          borderBottomStyle: 'solid',
        },
      },
    },
  }));

  const formTextFieldComponent = (label, key) => {
    let defaultValue = (defaultValues && defaultValues[key]) || '';
    if (Array.isArray(defaultValue)) {
      defaultValue.sort((a, b) => parseInt(a, 10) - parseInt(b, 10)).join(', ');
    }

    const standartProps = {
      variant: 'standard',
      label,
      margin: 'dense',
      fullWidth: true,
      defaultValue,
    };

    if (viewMode) {
      return (
        <TextFieldDisabledViewOnly
          {...standartProps}
          disabled={true}
        ></TextFieldDisabledViewOnly>
      );
    }

    return (
      <TextField
        {...standartProps}
        {...register(key, {
          required: `${label} is required`,
          valueAsNumber: !['floorServed', 'name'].includes(key),
          setValueAs: (v) => v.trim(),
          validate: (value) =>
            ['floorServed', 'name'].includes(key) ||
            parseFloat(value, 10) > 0 ||
            'Value must be a positive number',
        })}
        error={!!(errors && errors[key])}
        helperText={errors?.[key]?.message}
      />
    );
  };

  const formSelectFieldComponent = (
    label,
    key,
    options = [],
    additionalSelectProps,
  ) => {
    if (viewMode) {
      return formTextFieldComponent(label, key);
    }
    let defaultValue = (defaultValues && defaultValues[key]) || '';
    if (!defaultValue && key === 'airDistributionEffectiveness') {
      defaultValue = '1';
    }

    if (additionalSelectProps && additionalSelectProps.multiple) {
      if (!Array.isArray(defaultValue)) {
        defaultValue = defaultValue ? [parseInt(defaultValue, 10)] : [];
      }
    }
    return (
      <TextField
        select
        variant="standard"
        margin="dense"
        fullWidth
        defaultValue={defaultValue}
        label={label}
        {...register(key, {
          required:
            typeof label === 'string'
              ? `${label} is required`
              : 'This field is required',
        })}
        error={!!(errors && errors[key])}
        helperText={errors && errors[key]?.message}
        SelectProps={{
          ...additionalSelectProps,
          MenuProps: { style: { maxHeight: '280px' } },
        }}
      >
        {Object.values(options).map((type) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const LabelZoneEffectiveness = React.useMemo(() => {
    return (
      <div style={{ display: 'flex', alignItems: 'self-start' }}>
        <span>Zone Air Distribution Effectiveness</span>
        <IconButton
          onClick={() => setExplanationModalVisible(true)}
          sx={{ ml: 0.5 }}
          color="primary"
          size="small"
          style={{ position: 'relative', bottom: '4px' }}
        >
          <HelpOutlineIcon />
        </IconButton>
      </div>
    );
  }, []);

  return (
    <>
      <AirDistributionExplanationModal
        open={explanationModalVisible}
        handleClose={() => setExplanationModalVisible(false)}
      />
      <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {Object.keys(dataKeysLabels).map((key, i) => (
            <Grid key={key} item xs={!!i ? 6 : 12}>
              {formTextFieldComponent(dataKeysLabels[key], key)}
            </Grid>
          ))}
          <Grid item xs={6}>
            {formSelectFieldComponent(
              'Central AHU FIlter MERV Rating',
              'filterMervRating',
              MERV_FILTER_RATING_OPTIONS,
              { multiple: false },
            )}
          </Grid>
          <Grid item xs={6}>
            {formSelectFieldComponent(
              LabelZoneEffectiveness,
              'airDistributionEffectiveness',
              AIR_DISTRIBUTION_EFFECT_OPTIONS,
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
});

CalculatorForm.displayName = 'CalculatorForm';

export default CalculatorForm;
