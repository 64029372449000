import axios from 'axios';

import config from 'Config/config';

import { formSignificanceParamsFromColorsObj, getLSViewCompanyId } from 'Utils';

const BASEURL = 'https://6k2edvu452.execute-api.eu-west-1.amazonaws.com/dev' + '';

const results = {
  testApi: async () => {
    console.log(BASEURL + '/testapi');
    return await axios.post(BASEURL + '/testapi');
  },
  getAugmentedData: async (
    projectId,
    verificationLikePart = false,
    healthcarePart = false,
  ) => {
    let reqString = `/getaugmenteddata/${projectId}`;
    if (verificationLikePart) {
      reqString += '?verificationLikePart=1';
    } else if (healthcarePart) {
      reqString += '?healthcarePart=1';
    }
    return await axios.get(BASEURL + reqString);
  },
  uploadResults: async (projectId, data) => {
    return await axios.post(BASEURL + '/uploadresults/' + projectId, data);
  },
  recalculateResultsForTest: async (testId) => {
    return await axios.post(`${BASEURL}/updateheatmapsdata?testId=${testId}`);
  },
  regenerateResultsSnapshotForProject: async (projectId) => {
    return await axios.post(
      `${BASEURL}/regenerateResultsSnapshotForProject/${projectId}`,
    );
  },
  getTestsList: async (projectId) => {
    return await axios.get(BASEURL + '/testslist/' + projectId);
  },
  getHeatMapByProjectId: async (projectId, data) => {
    return await axios.post(BASEURL + '/getheatmap/' + projectId, data);
  },
  isResultsUploaded: async (projectId) => {
    return await axios.get(BASEURL + '/isresultsuploaded/' + projectId);
  },
  getHeatMapListForTest: async (testId, colors = null, inProgress = false) => {
    const companyId = getLSViewCompanyId() || 0;
    let queryString = `?companyId=${companyId}&testId=${testId}`;
    if (colors) {
      const significanceParams = formSignificanceParamsFromColorsObj(
        colors,
        inProgress,
      );

      queryString += significanceParams;
    }
    if (inProgress) {
      queryString += `&inProgress=${inProgress}`;
    }
    return await axios.get(BASEURL + '/getheatmaplist/' + queryString);
  },
  getHeatMapListForProject: async (projectId, source) => {
    const options = {};
    if (source) {
      options.cancelToken = source.token;
    }
    return await axios.get(
      `${BASEURL}/getheatmaplist?projectId=${projectId}`,
      options,
    );
  },
  getHeatmapListForFloorplanInProject: async (
    projectId,
    floorplanId,
    source,
  ) => {
    const options = {};
    if (source) {
      options.cancelToken = source.token;
    }
    return await axios.get(
      `${BASEURL}/getheatmaplist?projectId=${projectId}&floorplanId=${floorplanId}`,
      options,
    );
  },
  getHeatMapListForBuilding: async (buildingId, source) => {
    const options = {};
    if (source) {
      options.cancelToken = source.token;
    }

    return await axios.get(
      `${BASEURL}/getheatmaplist?buildingId=${buildingId}`,
      options,
    );
  },
  /**
   * Similar to getHeatMap (by id) but for multiple heatmaps in one project
   * @param {Number[]} heatMapIds
   */
  getHeatMapListDetailed: async (heatMapIds) => {
    if (!Array.isArray(heatMapIds) || !heatMapIds.length) return null;

    return await axios.get(
      `${BASEURL}/heatmapsListDetailed?heatmapsIds=${heatMapIds.join(',')}`,
    );
  },
  getHeatMap: async (heatMapId) => {
    return await axios.get(BASEURL + '/getheatmapbyid/' + heatMapId);
  },
  updateHeatmap: async (heatmapId, data) => {
    return await axios.post(BASEURL + '/updateheatmap/' + heatmapId, data);
  },
  downloadHeatmaps: async (ids, isZip = false) => {
    let queryString = `?ids=${ids}`;
    if (isZip) {
      queryString += `&isZip=${isZip}`;
    }
    return await axios.get(`${BASEURL}/downloadHeatmaps${queryString}`);
  },
  cropHeatmapFloorPlan: async (projectId, fileName) => {
    return await axios.get(
      `${BASEURL}/cropHeatmap?projectId=${projectId}&fileName=${fileName}`,
    );
  },
  getResultsFilesList: async (projectId, source) => {
    const options = {};
    if (source) {
      options.cancelToken = source.token;
    }
    return await axios.get(`${BASEURL}/resultsfileslist/${projectId}`, options);
  },
  getVerificationSummary: async (projectId) => {
    return await axios.get(`${BASEURL}/verificationSummary/${projectId}`);
  },
  deleteResultsFile: async (projectId, fileName) => {
    return await axios.get(
      `${BASEURL}/deleteResultsFile?projectId=${projectId}&filename=${fileName}`,
    );
  },
  getPresignedURL: async (projectId, fileName) => {
    return await axios.get(
      `${BASEURL}/getPresignedURL?projectId=${projectId}&filename=${fileName}`,
    );
  },
  markAllHeatmapsReviewedForTest: async (testId, reviewed) => {
    return await axios.put(
      `${BASEURL}/markAllHeatmapsReviewedForTest/${testId}`,
      { reviewed },
    );
  },
};

export default results;
