import axios from 'axios';

import config from 'Config/config';

const BASEURL = 'https://7zq218534b.execute-api.eu-west-1.amazonaws.com/dev' + '';

const scenarios = {
  createScenarios: async (data) => {
    return await axios.post(BASEURL + '/create', data);
  },
  getScenarios: async (segmentId, source) => {
    const options = {};
    if (source) {
      options.cancelToken = source.token;
    }
    return await axios.get(BASEURL + '?segmentId=' + segmentId, options);
  },
  updateScenarios: async (scenarioId, data) => {
    return await axios.put(BASEURL + '/' + scenarioId, data);
  },
  // HEALTHCARE SCENARIOS SECTION
  createHealthcareScenario: async (data) => {
    return await axios.post(`${BASEURL}/createHealthcareScenario`, data);
  },
  deleteHealthcareScenario: async (scenarioId) => {
    return await axios.delete(
      `${BASEURL}/deleteHealthcareScenario/${scenarioId}`,
    );
  },
  updateHealthcareScenarioTag: async (scenarioId, data) => {
    return await axios.put(`${BASEURL}/${scenarioId}/tag`, data);
  },
  resetScenarioTag: async (scenarioId) => {
    return await axios.put(`${BASEURL}/resetTag/${scenarioId}`);
  },
};

export default scenarios;
