import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Stack } from '@mui/material';

import Title from 'Components/Mix/Title';
import {
  AddTestBtn,
  CompleteAllTestsBtn,
  EditProjectSurveyDateBtn,
  ReorderTestsBtn,
} from 'Components/SingleProject/Main/Buttons';
import EditTestDetailsModal from 'Components/SingleProject/Main/Modals/EditTestDetailsModal';
import ProjectTestsTable from 'Components/SingleProject/Main/Tables/ProjectTestsTable';

import { projectDataSelector } from 'Features/Projects/projectsSlice';

export default function ProjectTestsSection() {
  const { projectTests, initialLoading, permissions } =
    useSelector(projectDataSelector);
  const [disableActions, setDisableActions] = useState(false);

  const {
    canEditTests,
    canAddTests,
    canReorderTests,
    canSetAllTestsToCompleted,
  } = permissions;

  const hasTests = Array.isArray(projectTests) && projectTests.length > 0;

  return (
    <Stack>
      <Stack direction="row" sx={{ mb: 1 }} justifyContent="space-between">
        <Title text="Tests" />
        {!initialLoading && canEditTests ? (
          <Stack direction="row" spacing={1}>
            <EditTestDetailsModal />
            {hasTests && canSetAllTestsToCompleted ? (
              <CompleteAllTestsBtn setLoading={setDisableActions} />
            ) : null}
            {hasTests && canReorderTests ? <ReorderTestsBtn /> : null}
            {hasTests ? <EditProjectSurveyDateBtn /> : null}
            {canAddTests ? <AddTestBtn /> : null}
          </Stack>
        ) : null}
      </Stack>
      <ProjectTestsTable disableActions={disableActions} />
    </Stack>
  );
}
