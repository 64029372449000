import React, { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import '../../styles.css';
import TestItem from './TestItem';

export default function TestsDraggableList({
  tests,
  isInDragAction,
  disableActions,
}) {
  const [noDragMode, setNoDragMode] = useState(true);

  useEffect(() => {
    setNoDragMode(disableActions);
  }, [disableActions]);

  return (
    <div>
      {tests.map((test, index) => {
        return (
          <Draggable
            draggableId={`test-${test.id}`}
            index={index}
            key={test.id}
            isDragDisabled={noDragMode}
          >
            {(provided, snapshot) => {
              const style = {
                boxShadow: snapshot.isDragging
                  ? 'rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px'
                  : '',
                borderRadius: '6px',
                backgroundColor: 'white',
                ...provided.draggableProps.style,
              };
              return (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  style={style}
                >
                  <TestItem
                    testId={test.id}
                    testName={test.name}
                    dividerVisible={index !== tests.length - 1}
                    key={test.id}
                    dragHandleProps={provided.dragHandleProps || {}}
                    disableActions={disableActions}
                    showTooltip={!isInDragAction && !disableActions}
                  />
                </div>
              );
            }}
          </Draggable>
        );
      })}
    </div>
  );
}
