import * as React from 'react';

import ImportContactsTwoToneIcon from '@mui/icons-material/ImportContactsTwoTone';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

export default function TableEmptyDataDisplay({
  columns,
  height = 280,
  iconHeight = 82,
}) {
  return (
    <TableRow style={{ height: `${height}px`, justifyContent: 'center' }}>
      <TableCell colSpan={columns} align="center">
        <ImportContactsTwoToneIcon
          color="disabled"
          sx={{ height: `${iconHeight}px`, width: `${iconHeight}px` }}
        />
        <Typography variant="subtitle1">Oops, no data!</Typography>
      </TableCell>
    </TableRow>
  );
}
