import React, { useContext, useEffect, useMemo, useState } from 'react';

import { BuildingsSitesContext } from '../index';
import '../styles.css';

import ApartmentIcon from '@mui/icons-material/Apartment';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, IconButton, Link, Skeleton, Stack } from '@mui/material';
import { Divider, Tooltip } from '@mui/material';

import DeleteBuildingButton from 'Components/BuildingDetails/Buttons/DeleteBuildingButton';

const BuildingItem = ({
  buildingId,
  buildingName,
  dividerVisible = true,
  mode = 'view',
  dragHandleProps,
  disableActions,
  showTooltip,
  loading,
}) => {
  const buildingsHandlers = useContext(BuildingsSitesContext);

  const [innerMode, setInnerMode] = useState(null);
  const [switchMode, setSwitchMode] = useState(false);
  const tooltipText = `Building has scheduled Test Package(s), thus it can not be edited`;

  useEffect(() => {
    if (!innerMode) {
      setInnerMode(mode);
    } else {
      setSwitchMode(true);
      setTimeout(() => {
        setSwitchMode(false);
        setInnerMode(mode);
      }, 300);
    }
  }, [mode]);

  const prefixIconStyle = {
    height: '24px',
    width: '24px',
    color: 'rgba(0, 0, 0, 0.57)',
    position: 'relative',
    display: 'inline',
    top: '2px',
    left: '4px',
  };

  const linkStyle = {
    textDecoration: 'none',
    fontSize: '1.1em',
    marginLeft: '24px',
    position: 'absolute',
    left: 10,
    width: 'calc(100% - 60px)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: '8px',
  };

  const PrefixIcon = useMemo(() => {
    if (mode === 'edit') {
      return (
        <DragHandleIcon
          style={{
            ...prefixIconStyle,
            cursor: disableActions ? '' : 'grab',
            color: disableActions ? '#D3D3D3' : '',
          }}
        />
      );
    } else {
      return (
        <ApartmentIcon
          style={{
            ...prefixIconStyle,
            cursor: 'default',
            left: 0,
          }}
        />
      );
    }
  }, [innerMode, disableActions]);

  const Actions = useMemo(() => {
    if (innerMode !== 'edit') return null;

    return (
      <span
        style={{
          position: 'absolute',
          right: '6px',
          marginTop: '3px',
          display: 'block',
        }}
      >
        <Tooltip title="Upload Floor plan" placement="top">
          <span>
            <IconButton
              disabled={loading}
              color="primary"
              sx={{ mr: 1 }}
              onClick={() =>
                buildingsHandlers.handleOpenFloorplanUploadModal(buildingId)
              }
            >
              <FileUploadIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
        <DeleteBuildingButton
          disabled={disableActions}
          disabledReason={(showTooltip && disableActions && tooltipText) || ''}
          buildingId={buildingId}
          buildingName={buildingName}
          afterDelete={() =>
            buildingsHandlers.afterBuildingDelete(true, buildingId)
          }
        />
      </span>
    );
  }, [innerMode, loading, showTooltip, disableActions]);

  return (
    <>
      {switchMode && <Skeleton />}
      <div style={{ display: switchMode ? 'none' : '' }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ pt: 0.5, pb: 1, position: 'relative' }}
        >
          <Tooltip
            title={(showTooltip && disableActions && tooltipText) || ''}
            placement="top"
          >
            <div {...dragHandleProps}>{PrefixIcon}</div>
          </Tooltip>
          <Link href={`/sites/${buildingId}`} style={linkStyle}>
            {buildingName}
          </Link>
          {Actions}
        </Stack>
        <Box
          style={{
            paddingLeft: '32px',
            visibility: !dividerVisible ? 'hidden' : '',
          }}
        >
          <Divider />
        </Box>
      </div>
    </>
  );
};

export default BuildingItem;
