import axios from 'axios';

import config from 'Config/config';

const BASEURL = 'https://4y23s1dfb4.execute-api.eu-west-1.amazonaws.com/dev' + '';

const segments = {
  createSegments: async (data) => {
    return await axios.post(BASEURL + '/create', data);
  },
  updateSegment: async (segmentId, segmentToBeUpdated) => {
    return await axios.put(
      BASEURL + '/' + parseInt(segmentId),
      segmentToBeUpdated,
    );
  },
  updateSamples: async (data) => {
    return await axios.put(BASEURL + '/samples', data);
  },
  getSegments: async (testId, projectId, source) => {
    const options = {};
    if (source) {
      options.cancelToken = source.token;
    }

    if (testId !== null) {
      return await axios.get(BASEURL + '?testId=' + testId, options);
    } else {
      return await axios.get(BASEURL + '?projectId=' + projectId, null, {
        ...options,
        params: { projectId },
      });
    }
  },
  updateTestPlan: async (testPlanToBeUpdated) => {
    return await axios.put(BASEURL + '/testPlan', testPlanToBeUpdated);
  },
  recreateSamples: async (projectId) => {
    return await axios.put(BASEURL + `/recreateSamples/${projectId}`);
  },
};

export default segments;
