import React, { useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Typography } from '@mui/material';

import floorPlans from 'Api/floorPlans';

import { useTestContext } from 'Components/SingleTest/context';

import { isViewer } from 'Config/roles';

import { getDataFromResponse, isValidResponse } from 'Utils';

const Floorplan = (props) => {
  const { buildingId, projectId, filteredTest, segment, editDisabled } = props;
  const [floorplans, setFloorplans] = useState(null);
  const { openEditModal } = useTestContext();

  useEffect(() => {
    fetchFloorplans();
  }, []);

  const fetchFloorplans = async () => {
    if (!!buildingId) {
      const buildingResponse = await floorPlans.getFloorPlansForBuilding(
        buildingId,
        true,
      );
      if (isValidResponse(buildingResponse)) {
        const _floorplans = getDataFromResponse(buildingResponse);
        if (Array.isArray(_floorplans)) {
          setFloorplans(_floorplans.filter((fp) => fp.floorplanid !== -1));
        }
      }
    }
  };

  const getHdaName = () => {
    if (!Array.isArray(floorplans)) return '';

    const hdaList =
      floorplans.find((fp) => fp.filename === segment.floorplan)?.hdaList || [];
    const hdaId =
      filteredTest?.testmetadata?.[segment?.segmentid]?.hdaId || null;

    if (hdaId) {
      const hda = hdaList.find((hda) => hda.id === hdaId);
      if (hda) {
        return hda.hdaname;
      }
    }

    return '';
  };

  return (
    <React.Fragment>
      <Typography sx={{ mx: 1 }}>
        {segment.floorplan || ''}
        {getHdaName() ? ` (${getHdaName()})` : ''}
      </Typography>
      {floorplans !== null && !!buildingId && !isViewer() && !editDisabled && (
        <IconButton
          aria-label="edit-floorplan"
          size="small"
          onClick={() => {
            openEditModal({
              type: 'floorplans',
              value: segment.floorplan || '',
              testId: filteredTest.testid,
              data: {
                buildingId,
                projectId,
                floorplans,
                segment,
                hdaId:
                  filteredTest?.testmetadata?.[segment?.segmentid]?.hdaId ||
                  null,
              },
            });
          }}
        >
          <EditIcon fontSize="inherit" />
        </IconButton>
      )}
    </React.Fragment>
  );
};
export default Floorplan;
