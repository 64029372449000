import React, { useRef } from 'react';

import EditProjectDetailsForm from '../Forms/EditProjectDetailsForm';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

export default function EditProjectDetailsModal({
  projectData,
  projectCustomers,
  projectPartners,
  open,
  handleClose,
  handleSubmit,
  transitionDuration = 220,
  actionsDisabled,
  hasTestPackages,
}) {
  const projectDetailsFormRef = useRef(null);

  return (
    <Dialog
      open={open}
      transitionDuration={transitionDuration}
      disableEscapeKeyDown
      PaperProps={{
        sx: {
          p: 1,
          pb: 2,
          justifyContent: 'center',
          alignItems: 'center',
          width: '440px',
        },
      }}
    >
      <DialogTitle>Edit project details</DialogTitle>
      <DialogContent>
        <EditProjectDetailsForm
          ref={projectDetailsFormRef}
          onSubmit={(values) => handleSubmit(values)}
          projectName={projectData.name}
          projectAddress={projectData.address}
          projectDescription={projectData.description}
          projectCustomer={projectData.endCustomer}
          projectPartner={projectData.partners}
          projectCustomers={projectCustomers}
          projectPartners={projectPartners}
          hasTestPackages={hasTestPackages}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between', width: '60%' }}>
        <Button
          style={{ width: '100%', marginRight: '8px' }}
          variant="outlined"
          onClick={handleClose}
          disabled={actionsDisabled}
        >
          Cancel
        </Button>
        <Button
          style={{ width: '100%' }}
          variant="contained"
          disabled={actionsDisabled}
          onClick={() => projectDetailsFormRef.current?.requestSubmit()}
          endIcon={
            actionsDisabled ? (
              <CircularProgress color="inherit" size={'1rem'} />
            ) : null
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
