import React from 'react';

import AppBreadcrumbs from '../AppBreadcrumbs';
import {
  getProjectBreadcrumb,
  getProjectsBreadcrumb,
  getTextBreadcrumb,
} from '../dataUtils';

function BreadcrumbsProjectDownloads({ currentProjectName, projectId }) {
  let breadcrumbs = [
    getProjectsBreadcrumb(),
    getProjectBreadcrumb(projectId, currentProjectName),
    getTextBreadcrumb(`Downloads`),
  ];
  return <AppBreadcrumbs links={breadcrumbs} />;
}

export default React.memo(BreadcrumbsProjectDownloads);
