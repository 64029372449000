import React from 'react';
import { TestPackageItemsContextProvider } from 'Components/TestPackageItem/context';
import TestPackageItems from 'Components/TestPackageItem/TestPackageItems';

const TestPackageItemPage = () => {
  return (
    <TestPackageItemsContextProvider>
      <TestPackageItems />
    </TestPackageItemsContextProvider>
  );
};

export default TestPackageItemPage;
