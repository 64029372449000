import React, { forwardRef } from 'react';
import { useForm } from 'react-hook-form';

import { Grid, TextField, Tooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 450,
    fontSize: '14px',
  },
});

const TextFieldDisabledViewOnly = styled(TextField)(() => ({
  '.MuiFormControl-root &.MuiTextField-root': {},
  '.MuiFormLabel-root': {
    '&.MuiInputLabel-root': {
      '&.Mui-disabled': {
        color: 'unset',
      },
    },
  },
  '.MuiInputBase-root': {
    '&.MuiInput-root': {
      '&.Mui-disabled input': {
        WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)',
        color: 'rgba(0, 0, 0, 0.87)',
      },
      '&::before': {
        borderBottomStyle: 'solid',
      },
    },
  },
}));

const FloorplanDetailsForm = forwardRef((props, ref) => {
  const { onSubmit, defaultValues, viewMode } = props;

  const isInheritedFloorplan = !!props.defaultValues?.hasParentFP;
  const hasConnectionToAnyTest = props.defaultValues?.hasConnectionToAnyTest;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues || {},
  });

  const dataConfig = {
    name: isInheritedFloorplan
      ? {
          label: 'Name',
          isTextField: true,
          required: true,
          disabled: hasConnectionToAnyTest,
          disabledReason: `The floor plan is connected to a test, so its name cannot be edited. To change its name, first unlink it from the test(s).`,
        }
      : {},
    address: {
      label: `Address`,
      isTextField: true,
      required: false,
    },
    floorNumber: {
      label: 'Floor number',
      isTextField: true,
      required: true,
    },
  };

  const formTextFieldComponent = (
    { label, required, isSelect, disabled, disabledReason },
    key,
  ) => {
    if (!label || isSelect) return null;

    let defaultValue = (defaultValues && defaultValues[key]) || '';

    const standartProps = {
      variant: 'standard',
      label,
      margin: 'dense',
      fullWidth: true,
      defaultValue,
    };

    if (viewMode) {
      return (
        <TextFieldDisabledViewOnly
          {...standartProps}
          disabled={true}
        ></TextFieldDisabledViewOnly>
      );
    }

    if (disabled) {
      return (
        <CustomWidthTooltip
          title={disabledReason}
          placement="bottom-start"
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 20],
                },
              },
            ],
          }}
        >
          <span>
            <TextField
              {...standartProps}
              {...register(key, {
                required: required && `${label} is required`,
                setValueAs: (v) => v?.trim(),
              })}
              error={!!(errors && errors[key])}
              helperText={errors?.[key]?.message}
              disabled={disabled}
            />
          </span>
        </CustomWidthTooltip>
      );
    }

    return (
      <TextField
        {...standartProps}
        {...register(key, {
          required: required && `${label} is required`,
          setValueAs: (v) => v?.trim(),
        })}
        error={!!(errors && errors[key])}
        helperText={errors?.[key]?.message}
      />
    );
  };

  return (
    <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {Object.keys(dataConfig).map((key) => (
          <React.Fragment key={key}>
            {dataConfig[key]?.isTextField && (
              <Grid item xs={12}>
                {formTextFieldComponent(dataConfig[key], key)}
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </form>
  );
});

FloorplanDetailsForm.displayName = 'FloorplanDetailsForm';

export default FloorplanDetailsForm;
