import {
  ERROR_AUTOCLOSE_TIMEOUT_MS,
  PROJECT_STATUSES,
  PROJECT_STATUSES_LABELS,
} from 'Constants';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useProjectContext } from '../../context';

import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { IconButton } from '@mui/material';

import projects from 'Api/projects';

import ConfirmDialog from 'Components/Dialogs/ConfirmDialog';

import { useIsMounted } from 'Context';

import { projectDataSelector } from 'Features/Projects/projectsSlice';

import { getErrorMessageFromResponse, isValidResponse } from 'Utils';

export default function EditProjectStatusBtn({ sx, direction = 'up' }) {
  const { projectStatus, isProjectDetailsLoading, projectId } =
    useSelector(projectDataSelector);
  const { fetchProjectStatus, setMainError } = useProjectContext();

  const currentToUpdatedStatusMapping = {
    up: {
      [PROJECT_STATUSES.IN_PROGRESS]: PROJECT_STATUSES.EXECUTION_READY,
      [PROJECT_STATUSES.EXECUTION_READY]: PROJECT_STATUSES.READY_FOR_LAB,
      [PROJECT_STATUSES.READY_FOR_LAB]: PROJECT_STATUSES.EXECUTED,
      [PROJECT_STATUSES.RESULTS_GENERATED]: PROJECT_STATUSES.PUBLISHED,
    },
    down: {
      [PROJECT_STATUSES.IN_PROGRESS]: PROJECT_STATUSES.NEW,
      [PROJECT_STATUSES.EXECUTION_READY]: PROJECT_STATUSES.IN_PROGRESS,
      [PROJECT_STATUSES.READY_FOR_LAB]: PROJECT_STATUSES.EXECUTION_READY,
      [PROJECT_STATUSES.EXECUTED]: PROJECT_STATUSES.READY_FOR_LAB,
      [PROJECT_STATUSES.PUBLISHED]: PROJECT_STATUSES.RESULTS_GENERATED,
    },
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const mounted = useIsMounted();

  const updateProjectStatus = async () => {
    setSaveInProgress(true);
    let isSuccess = false;

    try {
      const res = await projects.updateStatus(
        projectId,
        currentToUpdatedStatusMapping[direction][projectStatus],
      );
      if (!isValidResponse(res)) {
        throw new Error(getErrorMessageFromResponse(res));
      }
      isSuccess = true;
    } catch (err) {
      console.log('Save Project details error: ', err);
      setMainError(err?.message || `Couldn't save project details`);
      setTimeout(() => setMainError(''), ERROR_AUTOCLOSE_TIMEOUT_MS);
    } finally {
      if (mounted.current) {
        setSaveInProgress(false);
        if (isSuccess) {
          setModalOpen(false);
        }
      }
      fetchProjectStatus();
    }
  };

  return (
    <React.Fragment>
      <IconButton
        sx={{ ...sx }}
        size="small"
        color="primary"
        onClick={() => setModalOpen(true)}
      >
        {direction === 'up' ? <ArrowCircleUpIcon /> : <ArrowCircleDownIcon />}
      </IconButton>
      <ConfirmDialog
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        action={`set project status to ${
          PROJECT_STATUSES_LABELS[
            currentToUpdatedStatusMapping[direction][projectStatus]
          ]
        }`}
        isLoading={saveInProgress || isProjectDetailsLoading}
        handleConfirm={updateProjectStatus}
      />
    </React.Fragment>
  );
}
