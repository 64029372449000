import React from 'react';
import { generatePath } from 'react-router';

import { ROUTE_RESULTS } from '../../Config/routes';
import AppBreadcrumbs from './AppBreadcrumbs';

function BreadcrumbsCompare() {
  let breadcrumbs = [
    {
      text: 'Portfolio',
      link: generatePath(ROUTE_RESULTS),
    },
    {
      text: 'Compare',
    },
  ];
  return <AppBreadcrumbs links={breadcrumbs} />;
}

export default React.memo(BreadcrumbsCompare);
