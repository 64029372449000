import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import ImageIcon from '@mui/icons-material/Image';
import {
  Alert,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import buildings from 'Api/buildings';

import DeleteIconButton from 'Components/Buttons/DeleteIconButton';
import DeleteDialog from 'Components/Dialogs/DeleteDialog';

import { isViewer } from 'Config/roles';

import { getErrorMessageFromResponse, isValidResponse } from 'Utils';

const BuildingDisplayImage = ({
  refetch,
  setIsLoading,
  images,
  buildingId,
  isLoading,
}) => {
  const [isFileHovering, setIsFileHovering] = useState(false);
  const [isReadyForUpload, setIsReadyForUpload] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showDeleteConfirmation, setShowDeleteModal] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    reValidateMode: 'onChange',
  });

  const fileList = watch('building_image_upload');

  useEffect(() => {
    setIsReadyForUpload(!!fileList?.length);
  }, [fileList]);

  const scaleAndSendImage = async () => {
    try {
      setIsLoading(true);
      setErrorMessage(null);

      const _image = fileList[0];
      const targetWidth = 400;

      if (!_image) {
        return;
      }

      const loadImage = (file) =>
        new Promise((resolve) => {
          const reader = new FileReader();

          reader.onload = (event) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.src = event.target.result;
          };

          reader.readAsDataURL(file);
        });

      // Load and scale the image
      const image = await loadImage(_image);
      const scaleFactor = targetWidth / image.width;
      const targetHeight = image.height * scaleFactor;

      // Create a canvas and draw the scaled image
      const canvas = document.createElement('canvas');
      canvas.width = targetWidth;
      canvas.height = targetHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0, targetWidth, targetHeight);

      // Get the base64 data URL
      const base64DataUrl = canvas.toDataURL(_image.type);

      const response = await buildings.uploadBuildingImage(buildingId, {
        filename: _image.name,
        content: base64DataUrl,
        fileType: _image.type,
      });
      if (isValidResponse(response)) {
        refetch();
        setValue('building_image_upload', []);
        setIsLoading(false);
      } else throw new Error(getErrorMessageFromResponse(response));
    } catch (err) {
      console.error(err);
      setErrorMessage('Something went wrong. Please try again.');
      setIsLoading(false);
    }
  };

  const openDeleteConfirmation = () => {
    try {
      if (images[0].filename) {
        setShowDeleteModal(true);
        setErrorMessage(null);
      }
    } catch (err) {
      setShowDeleteModal(false);
    }
  };

  const closeConfirmDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const deleteBuildingImage = async () => {
    try {
      if (!images[0].filename) return;

      setIsLoading(true);
      setErrorMessage(null);

      const response = await buildings.deleteBuildingImage(buildingId);
      if (isValidResponse(response)) {
        refetch();
        setIsLoading(false);
        setShowDeleteModal(false);
      } else throw new Error(getErrorMessageFromResponse(response));
    } catch (err) {
      console.error(err);
      setErrorMessage('Something went wrong. Please try again.');
      setIsLoading(false);
    }
  };

  return (
    <Stack spacing={2} maxWidth={800}>
      <Typography variant="h5" mt={4}>
        Display Image
      </Typography>
      {images?.length > 0 && (
        <Stack spacing={2} direction="row">
          <List>
            <ListItem>
              <ListItemIcon>
                <ImageIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography sx={{ mr: 2 }}>{images[0]?.filename}</Typography>
                }
              />
              <ListItemSecondaryAction edge="end" aria-label="delete">
                <DeleteIconButton onClick={openDeleteConfirmation} />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Stack>
      )}
      {!images?.length && (
        <form onSubmit={handleSubmit(scaleAndSendImage)}>
          <Stack spacing={2}>
            {!isViewer() && (
              <>
                <Typography>
                  Select or drag and drop an image file. We recommend using an
                  image with dimensions of 400 pixels by 280 pixels.
                </Typography>
                <Stack alignItems="flex-start">
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    inputProps={{
                      accept: 'image/*',
                      multiple: false,
                    }}
                    type="file"
                    {...register('building_image_upload', {
                      required: 'File required',
                      validate: {
                        lessThan5MB: (files) =>
                          files[0]?.size < 5000000 ||
                          'File is too large. Max file size is 5MB',
                      },
                    })}
                    sx={{
                      borderRadius: '5px',
                      border: isFileHovering
                        ? '1px dotted black'
                        : '1px solid transparent',
                    }}
                    onDragEnter={() => {
                      setIsFileHovering(true);
                    }}
                    onDragLeave={() => {
                      setIsFileHovering(false);
                    }}
                    onDragEnd={() => {
                      setIsFileHovering(false);
                    }}
                    error={!!(errors && errors.building_image_upload)}
                    helperText={errors?.building_image_upload?.message}
                  />
                  {isReadyForUpload && (
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={!isReadyForUpload}
                      m={2}
                    >
                      Upload
                    </Button>
                  )}
                </Stack>
              </>
            )}
            {errorMessage && (
              <Alert
                severity="error"
                onClose={() => {
                  setErrorMessage(null);
                }}
              >
                {errorMessage}
              </Alert>
            )}
          </Stack>
        </form>
      )}
      {!!images.length && (
        <DeleteDialog
          open={showDeleteConfirmation}
          handleClose={closeConfirmDeleteModal}
          handleDelete={deleteBuildingImage}
          itemName={`image ${images[0].filename}`}
          isLoading={isLoading}
        />
      )}
    </Stack>
  );
};

export default BuildingDisplayImage;
