import React from 'react';
import { useSelector } from 'react-redux';

import { LS_BREADCRUMBS } from '../../Constants';
import { heatmapsSelector } from '../../Features/Heatmaps/heatmapsSlice';
import { resultsSelector } from '../../Features/Results/resultsSlice';
import { testsSelector } from '../../Features/Tests/testsSlice';
import AppBreadcrumbs from './AppBreadcrumbs';
import {
  getBuildingBreadcrumb,
  getPortfolioBreadcrumb,
  getTestsBreadcrumb,
  getTextBreadcrumb,
} from './dataUtils';

function BreadcrumbsHeatmap({
  buildingId,
  testId,
  heatmapId,
  heatmapName,
  date,
  projectId,
  projectName,
  resultsData,
}) {
  const { buildings, currentBuildingName } = useSelector(resultsSelector);
  const { tests, currentTestName, currentTestIndex } =
    useSelector(testsSelector);
  const { heatmaps, heatmapDisplayNumbers } = useSelector(heatmapsSelector);
  const lastItem = getTextBreadcrumb(heatmapName);
  const heatmapIndex = heatmapDisplayNumbers[heatmapId];
  const scenario = resultsData?.scenario;

  if (scenario) {
    lastItem.text = `${scenario.scenariodisplayname} #${scenario.scenarioindex}`;
  }
  lastItem.text = `${lastItem.text} / heatmap ${
    heatmapIndex ? '#' + heatmapIndex : ''
  } (${date})`;
  const breadcrumbs = [
    getPortfolioBreadcrumb(buildings, buildingId),
    getBuildingBreadcrumb(tests, buildingId, currentBuildingName, testId),
    getTestsBreadcrumb(
      heatmaps,
      buildingId,
      testId,
      heatmapId,
      `${currentTestName} test #${currentTestIndex}, ${projectName} PID:${projectId}`,
      heatmapDisplayNumbers,
    ),
    lastItem,
  ];

  let breadcrumbsOnlyText = breadcrumbs.map((item) => item.text).join(' / ');
  localStorage.setItem(LS_BREADCRUMBS, breadcrumbsOnlyText);

  return <AppBreadcrumbs links={breadcrumbs} />;
}

export default React.memo(BreadcrumbsHeatmap);
