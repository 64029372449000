import React, { useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

import 'Components/Dashboard/styles.css';

const SwitchStyled = styled(Switch)(({ theme, disabled }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'rgb(170,180,190, 0.8)',
      },
    },
  },
  '& .MuiSvgIcon-root': {
    width: '32px',
    height: '32px',
    padding: '8px',
    backgroundColor: disabled
      ? theme.palette.action.disabled
      : theme.palette.primary.main,
    borderRadius: '100%',
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: 'rgb(170,180,190, 0.8)',
    borderRadius: 10,
  },
}));

/**
 * @param {{
 * value: 'view' | 'edit' }} props
 * @returns
 */
function ViewEditSwitch({ onChange, value, disabled }) {
  const [boolVal, setBoolVal] = useState(value === 'edit');

  useEffect(() => {
    setBoolVal(value === 'edit');
  }, [value]);

  if (!~['view', 'edit'].indexOf(value)) return null;

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <SwitchStyled
            sx={{ m: 1 }}
            checkedIcon={<EditIcon />}
            icon={<VisibilityIcon />}
          />
        }
        disabled={disabled}
        onChange={(e) => {
          onChange(e.target.checked ? 'edit' : 'view');
        }}
        checked={boolVal}
        label={
          <span
            className="noselect"
            style={{
              fontVariant: 'all-petite-caps',
              fontSize: '20px',
              position: 'relative',
              bottom: 2,
            }}
          >
            {value}
          </span>
        }
      />
    </FormGroup>
  );
}

export default ViewEditSwitch;
