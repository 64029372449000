import {
  EXCEL_FILE_TYPES,
  LEGACY_TEST_TYPES,
  TEST_TYPES,
  TEST_TYPES_LABELS,
} from 'Constants';
import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Add } from '@mui/icons-material';
import { Alert, Button, IconButton, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import contractsApi from 'Api/contracts';

import { usePortfolioContext } from 'Components/Portfolios/context';
import { parseXLSXFile } from 'Components/Portfolios/utils';

import {
  isStatusExistEntity,
  isValidResponse,
  xlsxStringToNumber,
} from 'Utils';

const AddContractButton = ({ portfolio }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataToBeSavedAlert, setDataToBeSavedAlert] = useState(null);
  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const { setSuccessToast, setErrorToast, fetchPortfoliosAndSites } =
    usePortfolioContext();

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
    setDataToBeSavedAlert(null);
    clearErrors();
    reset();
  };

  const saveContract = async (dataXlsx) => {
    try {
      if (!portfolio.portfolioid) {
        setIsLoading(false);
        setErrorToast('Portfolio not selected');
        return;
      }

      const contractObj = getContractDataFromParsedFile(dataXlsx);

      if (Object.keys(contractObj).length <= 1) {
        setIsLoading(false);
        setErrorToast('Can not create Contract!!!');
        return;
      }

      const totalTests = getTotalTestsInAContract(contractObj);
      if (!totalTests) {
        setIsLoading(false);
        setErrorToast('Can not create contract with no tests');
        return;
      }
      const response = await contractsApi.createContract(contractObj);
      if (isValidResponse(response)) {
        handleCloseDialog();
        setSuccessToast('Upload successfully handled');
        await fetchPortfoliosAndSites();
      } else if (isStatusExistEntity(response)) {
        setError('file_upload', {
          type: 'custom',
          message: 'Contract for this Portfolio already exist',
        });
      } else {
        setError('file_upload', {
          type: 'custom',
          message: response.data?.error,
        });
      }
    } catch (e) {
      setIsLoading(false);
      setErrorToast(e.message);
    }
  };

  const onContractSubmit = async (data) => {
    try {
      setIsLoading(true);
      parseXLSXFile(data.file_upload[0], saveContract);
    } catch (e) {
      setErrorToast(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getContractDataFromParsedFile = (dataXlsx) => {
    let contractObj = {
      portfolioId: portfolio.portfolioid,
    };

    for (const item of dataXlsx) {
      if (item.length <= 1) {
        continue;
      }
      const firstElement = item[0].toString();
      if (firstElement.includes('Contract Number')) {
        contractObj.contractName = item[1];
      }

      if (firstElement.includes('Start Date')) {
        contractObj.startDate = moment(new Date(item[1])).format('YYYY-MM-DD');
      }

      if (firstElement.includes('End Date')) {
        contractObj.endDate = moment(new Date(item[1])).format('YYYY-MM-DD');
      }
    }

    // Find row with tests
    let rowWithTests = 0;
    for (let i = 0; i < dataXlsx.length; i++) {
      if (dataXlsx[i].length < 1) continue;
      const firstElement = dataXlsx[i][0].toString().toLowerCase();
      const allTestTypes = Object.values(TEST_TYPES_LABELS).map((t) =>
        t.toLowerCase(),
      );
      if (allTestTypes.includes(firstElement)) {
        rowWithTests = i;
        break;
      }
    }
    if (!rowWithTests) return contractObj;

    const testTypesFromFile = dataXlsx[rowWithTests];
    const testAmountsFromFile = dataXlsx[rowWithTests + 1];

    if (testTypesFromFile.length !== testAmountsFromFile.length)
      return contractObj;
    // Replace test types labels from file to test types key for save
    const testTypesKeys = testTypesFromFile.map((t) => {
      for (let [testTypeKey, testTypeLabel] of Object.entries(
        TEST_TYPES_LABELS,
      )) {
        if (testTypeLabel.toLowerCase() === t.toLowerCase()) {
          return testTypeKey;
        }
      }
      return '';
    });

    let shouldErrorLegacyTestType = false;

    const testTypesObj = testTypesKeys.reduce((res, testTypeKey, i) => {
      if (testTypeKey && !LEGACY_TEST_TYPES.includes(testTypeKey)) {
        res[testTypeKey] = parseInt(testAmountsFromFile[i]);
      }
      if (LEGACY_TEST_TYPES.includes(testTypeKey)) {
        shouldErrorLegacyTestType = true;
      }
      return res;
    }, {});

    contractObj = Object.assign(contractObj, testTypesObj);
    contractObj.shouldErrorLegacyTestType = shouldErrorLegacyTestType;

    return contractObj;
  };

  const getTotalTestsInAContract = (contractObj) => {
    let totalTests = 0;
    for (let testType of Object.values(TEST_TYPES)) {
      const amountOfTestsOfTestType = parseInt(contractObj[testType]) || 0;
      totalTests += amountOfTestsOfTestType;
    }
    return totalTests;
  };

  const preprocessContract = (file) => {
    const updateToastData = (data) => {
      const contractObj = getContractDataFromParsedFile(data);
      let summary = [];
      const totalTests = getTotalTestsInAContract(contractObj);
      for (let testType of Object.values(TEST_TYPES)) {
        const amountOfTestsOfTestType = parseInt(contractObj[testType]) || 0;
        if (amountOfTestsOfTestType) {
          summary.push(
            `${TEST_TYPES_LABELS[testType]}: ${amountOfTestsOfTestType}`,
          );
        }
      }
      setDataToBeSavedAlert({
        severity: totalTests ? 'info' : 'error',
        message: !totalTests
          ? 'No tests were found in a contract'
          : `${
              contractObj.shouldErrorLegacyTestType
                ? `Warning: The contract contains test types that are not supported.`
                : ''
            } Identified tests to be saved within the contract: ${summary.join(
              ', ',
            )}`,
      });
    };

    parseXLSXFile(file, updateToastData);

    return true;
  };

  return (
    <>
      <IconButton
        title="Add new contract"
        color="success"
        size="large"
        onClick={handleOpenDialog}
      >
        <Add />
      </IconButton>
      <Dialog
        open={isOpen}
        PaperProps={{
          sx: { width: '540px' },
        }}
      >
        <form onSubmit={handleSubmit(onContractSubmit)}>
          <DialogTitle>
            Add Contract to &quot;{portfolio.portfolioname}&quot;
          </DialogTitle>
          <DialogContent>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              type="file"
              name="file_upload"
              id="file_upload"
              {...register('file_upload', {
                required: 'File required',
                validate: {
                  lessThan10MB: (files) =>
                    files[0]?.size < 10000000 || 'Max 10MB',
                  acceptedFormats: (files) =>
                    EXCEL_FILE_TYPES.includes(files[0]?.type) || 'Only XLSX',
                  testTypesValidation: (files) => preprocessContract(files[0]),
                },
              })}
              inputProps={{
                accept: EXCEL_FILE_TYPES.join(','),
              }}
              error={!!(errors && errors.file_upload)}
              helperText={errors?.file_upload?.message}
              disabled={isLoading}
            />
            {dataToBeSavedAlert?.message ? (
              <Alert severity={dataToBeSavedAlert.severity}>
                {dataToBeSavedAlert.message}
              </Alert>
            ) : null}
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button variant="outlined" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={isLoading}
            >
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default AddContractButton;
