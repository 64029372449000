import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { generatePath } from 'react-router-dom';

import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { Button, Grid } from '@mui/material';

import EditProjectDetailsBtn from 'Components/SingleProject/Main/Buttons/EditProjectDetailsBtn';

import { ROUTE_PROJECT_DOWNLOADS } from 'Config/routes';

import { projectDataSelector } from 'Features/Projects/projectsSlice';

export default function ProjectActions() {
  const { projectId, permissions } = useSelector(projectDataSelector);
  const { editProjectDetailsPermission } = permissions;
  const navigate = useNavigate();

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Button
          variant="contained"
          onClick={(event) => {
            if (!projectId) return;
            const path = generatePath(ROUTE_PROJECT_DOWNLOADS, { projectId });

            if (event.metaKey || event.ctrlKey) {
              window.open(path);
              return;
            }
            navigate(path);
          }}
          endIcon={<FolderOutlinedIcon />}
          style={{ width: '160px' }}
          size="small"
        >
          Downloads
        </Button>
      </Grid>
      <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
        {editProjectDetailsPermission ? <EditProjectDetailsBtn /> : null}
      </Grid>
    </Grid>
  );
}
