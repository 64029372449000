import React from 'react';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';

export default function DilutionSlider({
  sliderCropBox,
  handleSlideCropBoxChange,
  disabled,
}) {
  return (
    <>
      <Grid container spacing={2} sx={{ px: 1 }}>
        <Typography gutterBottom>Floorplan</Typography>
        <Slider
          value={sliderCropBox}
          onChange={handleSlideCropBoxChange}
          aria-labelledby="input-slider"
          step={0.05}
          min={0}
          max={0.6}
          disabled={disabled}
        />
      </Grid>
    </>
  );
}
