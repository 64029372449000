import axios from 'axios';

import config from 'Config/config';

const BASEURL = 'https://51bl12jzc4.execute-api.eu-west-1.amazonaws.com/dev' + '';

const tests = {
  getTest: async (testId) => {
    return await axios.get(BASEURL + '/' + testId);
  },
  getTestDetails: async (testId) => {
    return await axios.get(`${BASEURL}/details/${testId}`);
  },
  getTestsStatusesForPortfolio: async (portfolioId) => {
    return await axios.get(
      `${BASEURL}/overallStatuses?portfolioId=${portfolioId}`,
    );
  },
  getTestsStatusesForContract: async (contractId) => {
    return await axios.get(
      `${BASEURL}/overallStatuses?contractId=${contractId}`,
    );
  },
  createTests: async (data) => {
    return await axios.post(BASEURL, data);
  },
  createTest: async (data, incrementCounts) => {
    const url = `${BASEURL}/create`;
    if (incrementCounts) {
      return await axios.post(url, data, { params: { incrementCounts: true } });
    }
    return await axios.post(url, data);
  },
  createHealthcareTest: async (data, updateRelatedCounts = false) => {
    return await axios.post(
      `${BASEURL}/healthcare/create?updateRelatedCounts=${+updateRelatedCounts}`,
      data,
    );
  },
  removeOneTest: async (testId) => {
    return await axios.delete(BASEURL + '/' + testId, {});
  },
  updateTestsOrder: async (testId, bodyObj) => {
    return await axios.put(BASEURL + `/testsResultOrder/${testId}`, bodyObj);
  },
  updateTestsOrderByProjectsOrderChange: async (bodyObj) => {
    return await axios.put(BASEURL + `/testsResultOrderUsingProjects`, bodyObj);
  },
  getDisplayNumbersBuilding: async (buildingId) => {
    return await axios.get(
      BASEURL + `/displayNumbers?buildingId=${buildingId}&type=building`,
    );
  },
  getDisplayNumbersProject: async (projectId) => {
    return await axios.get(
      BASEURL + `/displayNumbers?projectId=${projectId}&type=project`,
    );
  },
  getHeatmapDisplayNumbers: async (testId) => {
    return await axios.get(BASEURL + `/heatmapDisplayNumbers?testId=${testId}`);
  },
  setSampleRate: async (data) => {
    return await axios.put(BASEURL + '/samplerate', data);
  },
  updateOneTest: async (testId, data) => {
    return await axios.put(BASEURL + '/' + testId, data);
  },
  updateTestName: async (testId, data) => {
    return await axios.put(BASEURL + '/testName/' + testId, data);
  },
  updateTestsOrderNumbers: async (data) => {
    return await axios.put(BASEURL + '/updateTestsOrderNumbers/', data);
  },
};

export default tests;
