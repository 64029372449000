import { configureStore } from '@reduxjs/toolkit';

import breadcrumbsSlice from '../Features/Breadcrumbs/breadcrumbsSlice';
import colorsSlice from '../Features/Colors/colorsSlice';
import compareSlice from '../Features/Compare/compareSlice';
import inProgress from '../Features/Filter/inProgressSlice';
import floorPlanCropSlice from '../Features/FloorplanCrop/floorPlanCropSlice';
import heatmapSlice from '../Features/Heatmap/heatmapSlice';
import heatmapsSlice from '../Features/Heatmaps/heatmapsSlice';
import mainSlice from '../Features/Main/mainSlice';
import resultsSlice from '../Features/Results/resultsSlice';
import testsSlice from '../Features/Tests/testsSlice';

import buildingProjectResultsSlice from 'Features/BuildingProjectsResults/buildingProjectResultsSlice';
import projectsSlice from 'Features/Projects/projectsSlice';

export const store = configureStore({
  reducer: {
    heatmap: heatmapSlice,
    colors: colorsSlice,
    results: resultsSlice,
    tests: testsSlice,
    heatmaps: heatmapsSlice,
    breadcrumbs: breadcrumbsSlice,
    inProgress: inProgress,
    compare: compareSlice,
    floorPlanCrop: floorPlanCropSlice,
    main: mainSlice,
    buildingProjectResults: buildingProjectResultsSlice,
    projects: projectsSlice,
  },
});
