import React from 'react';

import { useTestScenariosContext } from '../context';

import DeleteDialog from 'Components/Dialogs/DeleteDialog';

export default function DeleteScenarioModal() {
  const testScenariosContext = useTestScenariosContext();

  if (!testScenariosContext) {
    console.error(`Component must be used inside TestScenariosContext`);
    return null;
  }

  const {
    forceRestrictAllEdits,
    scenarioForDeleteId,
    cancelScenarioDelete,
    handleDeleteScenario,
  } = testScenariosContext;

  return (
    <DeleteDialog
      open={!!scenarioForDeleteId}
      handleClose={cancelScenarioDelete}
      handleDelete={handleDeleteScenario}
      title="Delete scenario"
      isLoading={forceRestrictAllEdits}
    />
  );
}
