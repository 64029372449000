import React from 'react';

import { Skeleton } from '@mui/material';

import BreadcrumbsProjectDownloads from 'Components/Breadcrumbs/ProjectsBreadcrumbs/BreadcrumbsProjectDownloads';

export default function Breadcrumbs({ loading, name, id }) {
  if (loading) {
    return (
      <Skeleton sx={{ minWidth: '350px' }}>
        <BreadcrumbsProjectDownloads
          currentProjectName={'Something to inherit height from'}
          projectId={0}
        />
      </Skeleton>
    );
  }

  return (
    <BreadcrumbsProjectDownloads currentProjectName={name} projectId={id} />
  );
}
