import React, { useMemo } from 'react';

import '../styles.css';
import NotesTable from './NotesTable';
import ProjectConfigurationTestPage from './ProjectConfigurationTestPage';
import ProjectScope from './ProjectScope';

import { Box, Typography } from '@mui/material';

const ProjectConfigurationForPrint = React.forwardRef(
  (
    {
      projectData,
      projectId,
      projectName,
      paperWidth = 816,
      paperHeight = 1056,
      setFPLoaded,
    },
    ref,
  ) => {
    const paperPadding = 24;

    const LogoImg = useMemo(() => {
      return <img src="/veriDART-BY-SAFETRACES.png" alt="logo"></img>;
    }, []);

    const tests = React.useMemo(() => {
      if (projectData?.builder) {
        return projectData?.builder.getTests().map((test) => {
          const floorPlan = projectData?.builder.getFloorPlan(test.id);
          return {
            ...test,
            floorPlan,
          };
        });
      }

      return [];
    }, [projectData?.builder]);

    return (
      <div
        style={{
          width: paperWidth,
          height: paperHeight,
          padding: paperPadding,
        }}
        ref={ref}
      >
        <div className="projectConfiguration-print-container">
          <Box className="projectConfiguration-logo">{LogoImg}</Box>
          <Box>
            <Typography
              variant="h3"
              color="textPrimary"
              style={{ fontFamily: 'Gotham', fontSize: '25px' }}
            >
              Project Configuration
            </Typography>
          </Box>
          <ProjectScope
            projectId={projectId}
            projectName={projectName}
            address={projectData?.address}
            customer={projectData?.customer}
            details={projectData?.projectScopeDetails}
            dataIssues={projectData?.dataIssues}
            status={projectData?.status}
            description={projectData?.description}
            partner={projectData?.partner}
            tagInfo={projectData?.tagInfo}
          />

          {projectData?.dataIssues.filter((i) => i.testId === undefined)
            .length > 0 && (
            <div style={{ color: 'red', marginTop: '20px' }}>
              <Typography
                variant="h5"
                align="left"
                style={{ fontFamily: 'Gotham' }}
              >
                Warnings
              </Typography>
              <ol>
                {projectData?.dataIssues
                  .filter((i) => i.testId === undefined)
                  .map((dataIssue, i) => {
                    return <li key={i}>{dataIssue.description}</li>;
                  })}
              </ol>
            </div>
          )}
          <Box style={{ paddingTop: '40px' }}>
            <NotesTable header="Project Notes" rowsAmount={6} />
          </Box>
          {tests
            .sort((a, b) =>
              a.testordernumber
                ? a.testordernumber - b.testordernumber
                : a.id - b.id,
            )
            .map((test, index) => (
              <ProjectConfigurationTestPage
                key={index}
                test={test}
                number={index + 1}
                projectName={projectName}
                projectId={projectId}
                warnings={projectData?.dataIssues.filter(
                  (i) => i.testId === test.id,
                )}
                sprayData={projectData?.testsDetailedData?.sprayData[test.id]}
                scenarioData={
                  projectData?.testsDetailedData?.scenarioData[test.id]
                }
                scenarioDataWithOPs={
                  projectData?.testsDetailedData?.scenarioDataWithOPs[test.id]
                }
                scenariosWithSPs={
                  projectData?.testsDetailedData?.scenariosWithSPs[test.id]
                }
                testMeta={
                  projectData?.testsDetailedData?.testsMeta.filter(
                    ({ testid }) => testid === test.id,
                  )[0] || {}
                }
                OPs={projectData?.testsDetailedData?.OPs[test.id]}
                SPs={projectData?.testsDetailedData?.SPs[test.id]}
                allOPs={projectData?.originPoints}
                allSPs={projectData?.samplePoints}
                setFPLoaded={setFPLoaded}
              />
            ))}
        </div>
      </div>
    );
  },
);

export default ProjectConfigurationForPrint;
