import React, { useState } from 'react';

import { Alert, AlertTitle, Grid, Stack, Typography } from '@mui/material';

import contractsApi from 'Api/contracts';
import projectsApi from 'Api/projects';

import CancelDialog from 'Components/Dialogs/CancelDialog';
import DeleteDialog from 'Components/Dialogs/DeleteDialog';
import Title from 'Components/Mix/Title';
import AddTestPackageButton from 'Components/TestPackageItem/AddTestPackageButton';
import TestPackageItem from 'Components/TestPackageItem/TestPackageItem';
import TestPackageSummary from 'Components/TestPackageItem/TestPackageSummary';
import { useTestPackageItems } from 'Components/TestPackageItem/context';
import SkeletonGroupLoading from 'Components/UI/SkeletonGroupLoading';
import StaticLoadingProgress from 'Components/UI/StaticLoadingProgress';

import { getErrorMessageFromResponse, isValidStatusResponse } from 'Utils';

const TestPackagePage = () => {
  const {
    testPackages,
    buildings,
    setIsLoading,
    setSuccessMessage,
    setMainError,
    setStartProject,
    setSelectedTestPackageList,
    loadAllData,
    canCancelAnyProject,
    isLoading,
    availableTests,
    isInitialLoading,
  } = useTestPackageItems();

  const [openCancelProjectDialog, setOpenCancelProjectDialog] = useState(false);

  const [openDeleteTestPackageDialog, setOpenDeleteTestPackageDialog] =
    useState(false);
  const [actionItemId, setActionItemId] = useState(0);
  const [actionItemName, setActionItemName] = useState('');

  const handleOpenCancelProjectDialog = (projectId, projectName) => {
    setActionItemId(projectId);
    setActionItemName(projectName);
    setOpenCancelProjectDialog(true);
  };

  const handleCloseCancelProjectDialog = () => {
    setActionItemId(0);
    setOpenCancelProjectDialog(false);
    setTimeout(() => setActionItemName(''), 100);
  };

  const handleCancelProject = async () => {
    try {
      setIsLoading(true);
      setSuccessMessage('');
      if (!actionItemId) {
        setIsLoading(false);
        setMainError('Can not cancel project. Please, try again later.');
        return;
      }
      const response = canCancelAnyProject
        ? await projectsApi.removeProjectDeep(actionItemId)
        : await projectsApi.removeProjectUpdateTestPackages(actionItemId);
      if (isValidStatusResponse(response)) {
        setSuccessMessage('Project was succesfully removed.');
        handleCloseCancelProjectDialog();
        await updateList();
      } else {
        setMainError(getErrorMessageFromResponse(response));
      }
    } catch (e) {
      setMainError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const updateList = async () => {
    setStartProject(false);
    setSelectedTestPackageList([]);
    await loadAllData();
  };

  const handleOpenDeleteTestPackageDialog = (
    testPackageId,
    testPackageName,
  ) => {
    setActionItemId(testPackageId);
    setActionItemName(testPackageName);
    setOpenDeleteTestPackageDialog(true);
  };

  const handleCloseDeleteTestPackageDialog = () => {
    setActionItemId(0);
    setOpenDeleteTestPackageDialog(false);
    setTimeout(() => setActionItemName(''), 100);
  };

  const handleDeleteTestPackage = async () => {
    try {
      setIsLoading(true);
      setSuccessMessage('');
      if (!actionItemId) {
        setIsLoading(false);
        setMainError('Can not delete Test Package!!!');
        return;
      }
      const response = await contractsApi.removeTestPackage(actionItemId);
      if (isValidStatusResponse(response)) {
        setSuccessMessage('Test Projects has been successfully deleted.');
        await updateList();
        handleCloseDeleteTestPackageDialog();
      } else {
        setMainError(response.data?.error.message);
      }
    } catch (e) {
      setMainError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const renderWarnings = (buildings) => {
    if (!Array.isArray(buildings) || buildings?.length === 0) {
      return (
        <Grid item xs={12}>
          <Alert severity="warning">
            <AlertTitle>
              <strong>Warning(s)</strong>
            </AlertTitle>
            <ul>
              <li>
                <Typography>
                  There are no buildings associated with this site contract. Add
                  a building to the new site before creating test packages
                </Typography>
              </li>
            </ul>
          </Alert>
        </Grid>
      );
    }
    return null;
  };
  return (
    <Stack spacing={2}>
      <Title text="Test Packages" />
      {isInitialLoading ? <SkeletonGroupLoading /> : null}
      {!isInitialLoading ? (
        <Grid container spacing={1} sx={{ pl: 0 }}>
          {renderWarnings(buildings)}
          <TestPackageSummary />
          <Stack sx={{ mt: 2, mb: 1, width: '100%' }}>
            <StaticLoadingProgress isLoading={isLoading} />
          </Stack>
          {testPackages.map((tp) => (
            <TestPackageItem
              key={`${tp?.testpackageid}-${tp?.testpackagename}-${tp?.testpackagedate}}`}
              testPackage={tp}
              handleOpenCancelProjectDialog={() => {
                handleOpenCancelProjectDialog(tp.projectid, tp.projectname);
              }}
              handleOpenDeleteTestPackageDialog={() => {
                handleOpenDeleteTestPackageDialog(
                  tp.testpackageid,
                  tp.testpackagename,
                );
              }}
            />
          ))}
          {testPackages.length === 0 && availableTests.total > 0 && (
            <Grid item xs={12}>
              <AddTestPackageButton />
            </Grid>
          )}
        </Grid>
      ) : null}
      <CancelDialog
        itemName={actionItemName}
        isLoading={isLoading}
        title={'Cancel Project'}
        open={openCancelProjectDialog}
        handleClose={handleCloseCancelProjectDialog}
        handleCancel={handleCancelProject}
        buttonName={'Cancel Project'}
      />
      <DeleteDialog
        open={openDeleteTestPackageDialog}
        isLoading={isLoading}
        handleClose={handleCloseDeleteTestPackageDialog}
        handleDelete={handleDeleteTestPackage}
        title={'Delete Test Package'}
        itemName={actionItemName}
      />
    </Stack>
  );
};

export default TestPackagePage;
