import { TEST_TYPES_LABELS } from 'Constants';
import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

class TestDetails extends React.Component {
  renderWarnings() {
    if (Array.isArray(this.props.warnings) && this.props.warnings.length > 0) {
      return (
        <>
          <Typography>Warnings:</Typography>
          <ol
            style={{ margin: '25px', fontFamily: `sans-serif`, color: 'red' }}
          >
            {this.props.warnings.map((w) => (
              <li key={w.description}>{w.description}</li>
            ))}
          </ol>
        </>
      );
    } else {
      return (
        <>
          <Typography>No warnings</Typography>
        </>
      );
    }
  }

  render() {
    return (
      <Box
        id="TestDetails"
        display="flex"
        justifyContent="space-between"
        pt={5}
      >
        <Box>
          <Typography
            variant="h5"
            color="textPrimary"
            align="left"
            style={{ fontFamily: 'Gotham', fontSize: '1.2rem' }}
          >
            PID: {this.props.projectId} , {this.props.projectName}
          </Typography>
          {!this.props.headerOnly && (
            <>
              <Typography>
                Test type: {TEST_TYPES_LABELS[this.props.test.type]}
              </Typography>
              <Typography>
                Completed: {this.props.test.isCompleted ? 'Yes' : 'No'}
              </Typography>
              {this.renderWarnings()}
            </>
          )}
        </Box>
        <Box>
          <Typography
            variant="h5"
            color="textPrimary"
            align="right"
            style={{ fontFamily: 'Gotham', fontSize: '1.2rem' }}
          >
            Test Number - {this.props.order}
          </Typography>
          <Typography
            variant="h5"
            color="textPrimary"
            align="right"
            style={{ fontFamily: 'Gotham', fontSize: '1.2rem' }}
          >
            Test Name - {this.props.test.name}
          </Typography>
        </Box>
      </Box>
    );
  }
}

export default TestDetails;
