import { PROJECT_STATUSES_LABELS } from 'Constants';
import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';

import EditProjectStatusBtn from 'Components/SingleProject/Main/Buttons/EditProjectStatusBtn';

import { projectDataSelector } from 'Features/Projects/projectsSlice';

export default function ProjectDetailsView() {
  const {
    projectDetails,
    isProjectDetailsLoading,
    isProjectStatusLoading,
    projectStatus,
    permissions,
  } = useSelector(projectDataSelector);

  const { canUpgradeProjectStatus, canDowngradeProjectStatus } = permissions;

  return (
    <Grid container spacing={2} mt={2} mb={2}>
      <SingleCharacteristicRow
        loading={isProjectDetailsLoading}
        title={'Name'}
        text={projectDetails.name}
      />
      <SingleCharacteristicRow
        loading={isProjectDetailsLoading || isProjectStatusLoading}
        title="Status"
        text={PROJECT_STATUSES_LABELS[projectStatus]}
        actions={
          <Stack direction="row" spacing={1}>
            {!projectStatus ? <EditProjectStatusBtn /> : null}
            {canDowngradeProjectStatus ? (
              <EditProjectStatusBtn direction="down" />
            ) : null}
            {canUpgradeProjectStatus ? <EditProjectStatusBtn /> : null}
          </Stack>
        }
      />

      <SingleCharacteristicRow
        loading={isProjectDetailsLoading}
        title={'Address'}
        text={projectDetails.address}
      />
      <SingleCharacteristicRow
        loading={isProjectDetailsLoading}
        title={'Customer'}
        text={projectDetails.endCustomer}
      />

      <SingleCharacteristicRow
        loading={isProjectDetailsLoading}
        title={'Description'}
        text={projectDetails.description}
      />

      <SingleCharacteristicRow
        loading={isProjectDetailsLoading}
        title={'Partner'}
        text={projectDetails.partners}
      />
      <SingleCharacteristicRow
        loading={isProjectDetailsLoading}
        title={'Test Package'}
        text={projectDetails.testPackageName}
      />

      <SingleCharacteristicRow
        loading={isProjectDetailsLoading}
        title={'Site Contract'}
        text={projectDetails.siteContractName}
      />
      <SingleCharacteristicRow
        loading={isProjectDetailsLoading}
        title={'Contract'}
        text={projectDetails.contractName}
      />

      <SingleCharacteristicRow
        loading={isProjectDetailsLoading}
        title={'Portfolio'}
        text={projectDetails.portfolioName}
      />
    </Grid>
  );
}

const SingleCharacteristicRow = ({ loading, title, text, actions }) => {
  return (
    <Grid item xs={6} md={4}>
      <Stack
        playwrighttestid={`projectDetailsItem-${title}`}
        direction="row"
        alignItems="center"
      >
        <Typography
          playwrighttestid={`projectDetailsItem-${title}-label`}
          sx={{ whiteSpace: 'nowrap' }}
          variant={'body1'}
        >
          <b>{title}:</b>
        </Typography>
        {loading ? (
          <Skeleton
            sx={{ ml: 1, width: '-webkit-fill-available', maxWidth: '100%' }}
          >
            {actions ? (
              <Stack direction="row">
                <Typography>Text</Typography>
                {actions}
              </Stack>
            ) : (
              <Typography
                sx={{
                  ml: 1,
                  width: '-webkit-fill-available',
                  maxWidth: '100%',
                }}
                variant="body1"
              >
                {'Text'}
              </Typography>
            )}
          </Skeleton>
        ) : (
          <Stack
            playwrighttestid={`projectDetailsItem-${title}-data`}
            direction="row"
            alignItems="center"
          >
            <Typography sx={{ ml: 1 }} variant={'body1'}>
              {text}
            </Typography>
            {actions ? <Box sx={{ ml: 1 }}>{actions}</Box> : null}
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};
