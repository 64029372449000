import { TEST_TYPES } from 'Constants';
import React from 'react';

import Notes from './NotesTable';
import AnnotatedFloorPlan from './PCTestPageComponents/AnnotatedFloorPlan';
import GeneralSurveyOPTable from './PCTestPageComponents/GeneralSurveyOPTable';
import OPsAndSPs from './PCTestPageComponents/OPsAndSPs';
import ScenariosTable from './PCTestPageComponents/ScenariosTable';
import TestDetails from './PCTestPageComponents/TestDetails';
import TestSprayTable from './PCTestPageComponents/TestSprayTable';

const ProjectConfigurationTestPage = ({
  test,
  number,
  projectName,
  projectId,
  warnings,
  SPs,
  OPs,
  scenarioData,
  sprayData,
  scenariosWithSPs,
  scenarioDataWithOPs,
  testMeta,
  allOPs,
  allSPs,
  setFPLoaded,
}) => {
  return (
    <React.Fragment key={number}>
      <div style={{ pageBreakAfter: 'always', pageBreakBefore: 'always' }}>
        <TestDetails
          test={test}
          order={number}
          projectName={projectName}
          projectId={projectId}
          warnings={warnings}
        />
        <Notes header={test.name + ' Notes'} rowsAmount="4" />
        <br />
        <TestSprayTable details={sprayData} test={test} />
        <br />
        {!!~[
          TEST_TYPES.GENERAL,
          TEST_TYPES.LARGE_SURVEY,
          TEST_TYPES.SMALL_SURVEY,
        ].indexOf(test?.type) && <GeneralSurveyOPTable OPs={allOPs} />}
        <ScenariosTable
          scenariosDataFromTest={
            testMeta?.testmetadata[testMeta?.segmentid]?.scenariosData || {}
          }
          scenariosWithSPs={scenariosWithSPs}
          scenarios={scenarioData}
          scenarioDataWithOPs={scenarioDataWithOPs}
          testType={testMeta.testtype}
        />
      </div>
      <div style={{ pageBreakAfter: 'always', pageBreakBefore: 'always' }}>
        <TestDetails
          test={test}
          order={number}
          projectName={projectName}
          projectId={projectId}
          warnings={warnings}
          headerOnly
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <OPsAndSPs OPs={OPs} SPs={SPs} />
          <div style={{ flexGrow: 1, padding: '0 10px' }}>
            <AnnotatedFloorPlan
              originPoints={allOPs.filter((item) => item.testId === test.id)}
              samplePoints={allSPs.filter((item) => item.testId === test.id)}
              imageFile={test.floorPlan?.url}
              fileName={test.floorplan}
              size={{ width: 700, height: 1400 }}
              testId={test.id}
              setFPLoaded={() => setFPLoaded(test.floorplan)}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProjectConfigurationTestPage;
