import { generatePath } from 'react-router';

import {
  ROUTE_BUILDING_PROJECTS_RESULTS,
  ROUTE_FLOORPLANNER,
  ROUTE_HEATMAP,
  ROUTE_HEATMAPS,
  ROUTE_PROJECTS,
  ROUTE_PROJECT_DETAILS,
  ROUTE_PROJECT_FLOORPLAN_HEATMAP,
  ROUTE_PROJECT_TEST_DETAILS,
  ROUTE_RESULTS,
  ROUTE_SITES,
  ROUTE_SITES_BUILDING,
  ROUTE_TESTS,
} from 'Config/routes';

function getBuildingsList(buildings, buildingId) {
  let currentPortfolioId = 0;
  return buildings
    .filter(
      (item) =>
        Number.parseInt(item.buildingid) !== Number.parseInt(buildingId),
    )
    .map((item) => {
      if (item.portfolioid !== currentPortfolioId) {
        currentPortfolioId = item.portfolioid;
        return {
          parentText: item.portfolioname,
          link: generatePath(ROUTE_TESTS, { buildingId: item.buildingid }),
          text: item.buildingname,
        };
      } else {
        return {
          link: generatePath(ROUTE_TESTS, { buildingId: item.buildingid }),
          text: item.buildingname,
        };
      }
    });
}

export function getBuildingName(buildings, buildingId) {
  let building = buildings.find(
    (item) => Number.parseInt(item.buildingid) === Number.parseInt(buildingId),
  );
  return building ? building.buildingname : '';
}

function getTestsList(tests, buildingId, testId) {
  return tests
    .filter((item) => Number.parseInt(item.testid) !== Number.parseInt(testId))
    .map((item) => ({
      link: generatePath(ROUTE_HEATMAPS, {
        buildingId: buildingId,
        testId: item.testid,
      }),
      text: `${item.testname} #${item.index ?? item.testid} (${
        item.surveydate
      })`,
    }));
}

function getFloorplansForProjectList(projects, projectId, buildingId) {
  const project = projects.find(
    (project) => parseInt(project.id) === parseInt(projectId),
  );
  const heatmaps = project?.heatmaps || [];

  const fpIds = [];
  const floorplans = [];

  for (let { mainfpname, filename, mainfloorplanid, floorplanid } of heatmaps) {
    const floorplanName = mainfpname || filename;
    const floorplanId = mainfloorplanid || floorplanid;

    if (!fpIds.includes(floorplanId)) {
      fpIds.push(floorplanId);
      floorplans.push({
        floorplanName,
        floorplanId,
      });
    }
  }

  floorplans.sort((a, b) => a.floorplanId - b.floorplanId);

  return floorplans.map(({ floorplanId, floorplanName }) => ({
    link: generatePath(ROUTE_PROJECT_FLOORPLAN_HEATMAP, {
      projectId,
      floorplanId,
      buildingId,
    }),
    text: floorplanName,
  }));
}

export function getTestName(tests, testId) {
  let test = tests.find((item) => {
    return Number.parseInt(item.testid) === Number.parseInt(testId);
  });
  return test ? test.testname : '';
}

export function getTestDate(tests, testId) {
  let test = tests.find((item) => {
    return Number.parseInt(item.testid) === Number.parseInt(testId);
  });
  return test ? test.surveydate : '';
}

export function getTestIndex(tests, testId) {
  let test = tests.find((item) => {
    return Number.parseInt(item.testid) === Number.parseInt(testId);
  });
  return test ? test.displayNumber : '';
}

export function getTestType(tests, testId) {
  const test = tests.find((item) => {
    return Number.parseInt(item.testid) === Number.parseInt(testId);
  });
  return test ? test.testtype : '';
}

export function getHeatmapName(heatmaps, heatmapId) {
  let heatmap = heatmaps.find((item) => {
    return Number.parseInt(item.heatmapid) === Number.parseInt(heatmapId);
  });
  return heatmap ? heatmap.heatmapname : '';
}

export function getHeatMapList(
  heatmaps,
  buildingId,
  testId,
  heatmapId,
  heatmapDisplayNumbers,
) {
  return heatmaps
    .filter(
      (item) => Number.parseInt(item.heatmapid) !== Number.parseInt(heatmapId),
    )
    .map((item) => ({
      link: generatePath(ROUTE_HEATMAP, {
        buildingId: buildingId,
        testId: testId,
        heatmapId: item.heatmapid,
      }),
      text: `${item.heatmapname} ${
        heatmapDisplayNumbers[item.heatmapid]
          ? '#' + heatmapDisplayNumbers[item.heatmapid]
          : ''
      }`,
    }));
}

export function getTextBreadcrumb(text) {
  return { text: text };
}

export function getPortfolioBreadcrumb(
  breadcrumbsBuildings,
  buildingId,
  disableSubmenu = false,
) {
  return {
    text: 'Portfolio',
    link: generatePath(ROUTE_RESULTS),
    submenu: !disableSubmenu
      ? getBuildingsList(breadcrumbsBuildings, buildingId)
      : null,
  };
}

export function getProjectsBreadcrumb() {
  return {
    text: 'Projects',
    link: generatePath(ROUTE_PROJECTS),
  };
}

export function getProjectBreadcrumb(projectId, projectName) {
  return {
    text: projectName,
    link: generatePath(ROUTE_PROJECT_DETAILS, { projectId: projectId }),
  };
}

export function getFloorplansBreadcrumb(buildingId, projectId, testId) {
  const searchParams = [];
  if (buildingId) {
    searchParams.push(`buildingId=${buildingId}`);
  }
  if (projectId) {
    searchParams.push(`projectId=${projectId}`);
  }
  if (testId) {
    searchParams.push(`testId=${testId}`);
  }
  return {
    text: 'Floor Plans',
    link:
      ROUTE_FLOORPLANNER +
      `${searchParams.length ? `?${searchParams.join('&')}` : ''}`,
  };
}

export function getProjectBreadcrumbForHeatmaps({
  projectId,
  projectName,
  tests,
  testId,
}) {
  return {
    text: `${projectName} PID:${projectId}`,
    link: generatePath(ROUTE_PROJECT_DETAILS, { projectId: projectId }),
    submenu: tests
      .filter(
        (item) => Number.parseInt(item.testid) !== Number.parseInt(testId),
      )
      .map((item) => ({
        link: generatePath(ROUTE_PROJECT_TEST_DETAILS, {
          projectId: projectId,
          testId: item.testid,
        }),
        text: `${item.testname} #${item.index ?? item.testid} (${
          item.surveydate
        })`,
      })),
  };
}

export function getProjectTestBreadcrumb(projectId, testId, testName) {
  return {
    text: testName,
    link: generatePath(ROUTE_PROJECT_TEST_DETAILS, { projectId, testId }),
  };
}

export function getBuildingBreadcrumb(tests, buildingId, buildingName, testId) {
  return {
    text: buildingName,
    link: generatePath(ROUTE_TESTS, { buildingId: buildingId }),
    submenu: getTestsList(tests, buildingId, testId),
  };
}

export function getBuildingProjectsBreadcrumb(
  buildingId,
  buildingName,
  projects,
  projectId,
) {
  return {
    text: buildingName,
    link: generatePath(ROUTE_BUILDING_PROJECTS_RESULTS, {
      buildingId: buildingId,
    }),
    submenu: getFloorplansForProjectList(projects, projectId, buildingId),
  };
}

export function getTestsBreadcrumb(
  heatmaps,
  buildingId,
  testId,
  heatmapId,
  testName,
  heatmapDisplayNumbers,
) {
  return {
    text: testName,
    link: generatePath(ROUTE_HEATMAPS, {
      buildingId: buildingId,
      testId: testId,
    }),
    submenu: getHeatMapList(
      heatmaps,
      buildingId,
      testId,
      heatmapId,
      heatmapDisplayNumbers,
    ),
  };
}

export function getSitesBuildingsBreadcrumb() {
  return {
    text: 'Sites & Buildings',
    link: generatePath(ROUTE_SITES),
  };
}

export function getBuildingNameBreadcrumb(buildingId, buildingName) {
  return {
    text: buildingName,
    link: generatePath(ROUTE_SITES_BUILDING, { buildingId }),
  };
}
