import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import './styles.css';
import ProjectConfigurationForPrint from './views/ProjectConfigurationForPrint';

import { Stack } from '@mui/material';

import DownloadButton from 'Components/Buttons/DownloadButton';

function ProjectConfigurationPrint({
  projectId,
  projectName,
  loading,
  tooltipText = '',
  projectData,
  setFPLoaded,
}) {
  const configurationRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => configurationRef.current,
    documentTitle: `${projectName}-Configuration`,
  });

  return (
    <Stack direction="column">
      <DownloadButton
        text="Project Configuration"
        disabled={!projectData || loading}
        loading={loading}
        tooltipText={tooltipText}
        icon="print"
        clickAction={handlePrint}
        sx={{ width: '230px' }}
      />
      {!!projectData && (
        <div style={{ display: 'none' }}>
          <ProjectConfigurationForPrint
            ref={configurationRef}
            projectId={projectId}
            projectName={projectName}
            paperWidth={1200}
            paperHeight={830}
            projectData={projectData}
            setFPLoaded={setFPLoaded}
          />
        </div>
      )}
    </Stack>
  );
}

export default React.memo(ProjectConfigurationPrint);
