import React from 'react';
import { generatePath } from 'react-router';
import { useNavigate, useParams } from 'react-router-dom';

import ImageIcon from '@mui/icons-material/Image';
import { Box, Skeleton, Stack, Typography } from '@mui/material';

import NoDataDisplay from 'Components/UI/NoDataDisplay';

import { ROUTE_PROJECT_FLOORPLAN_HEATMAP } from 'Config/routes';

const HeatmapsListByFloor = ({ projectId, heatmaps }) => {
  const navigate = useNavigate();
  const { buildingId } = useParams();

  const floorsFilesConfig = heatmaps.reduce((acc, heatmap) => {
    const { floorplanid, mainfloorplanid, mainfpname, filename, floornumber } =
      heatmap;
    const intFloorNumber = parseInt(floornumber, 10) || -1;
    const mainFpId = mainfloorplanid || floorplanid;
    const mainFpName = mainfpname || filename;

    if (!acc[intFloorNumber]) {
      acc[intFloorNumber] = {
        ids: [],
        floorplans: [],
      };
    }
    if (acc[intFloorNumber].ids.includes(mainFpId)) {
      return acc;
    }

    acc[intFloorNumber].ids.push(mainFpId);
    acc[intFloorNumber].floorplans.push({
      id: mainFpId,
      name: mainFpName,
    });

    return acc;
  }, {});

  const getFloorNumberHeaderText = (floorNumber) => {
    const intFloorNumber = parseInt(floorNumber, 10);
    if (intFloorNumber === -1) {
      return 'Other';
    }
    return `Floor ${intFloorNumber}`;
  };

  const handleFloorplanClick = (event, floorplanId) => {
    const _path = generatePath(ROUTE_PROJECT_FLOORPLAN_HEATMAP, {
      projectId,
      floorplanId,
      buildingId,
    });
    /** This is for opening it in new tab through command + click or ctrl + click */
    if (event.metaKey || event.ctrlKey) {
      window.open(_path);
      return;
    }
    navigate(_path);
  };

  return (
    <Stack spacing={2}>
      {Object.keys(floorsFilesConfig).map((floorNumber) => {
        return (
          <Stack key={`floor-${getFloorNumberHeaderText(floorNumber)}`}>
            <Typography variant="h6">
              {getFloorNumberHeaderText(floorNumber)}
            </Typography>
            <Stack sx={{ mt: 1, ml: 2 }} spacing={1}>
              {floorsFilesConfig[floorNumber].floorplans.map((floorplan) => {
                return (
                  <Stack
                    key={`project-${projectId}-fp-${floorplan.id}`}
                    direction="row"
                    spacing={1}
                  >
                    <ImageIcon color="disabled" />
                    <Typography
                      key={floorplan.id}
                      gutterBottom
                      variant="body1"
                      component="div"
                      onClick={(e) => handleFloorplanClick(e, floorplan.id)}
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      {floorplan.name}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default function ResultsBuildingProjectsDisplay({ projects, loading }) {
  if (loading) {
    return <ResultsBuildingProjectsDisplaySkeleton />;
  }

  if (!loading && (!projects || !projects.length)) {
    return (
      <Box sx={{ height: '500px', width: '100%', display: 'flex' }}>
        <NoDataDisplay />
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing={2}>
      {projects
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .map((project) => {
          return (
            <Stack key={project.id} direction="column" spacing={2}>
              <Typography color="primary" variant="h5">
                {project.name}
              </Typography>
              <Stack sx={{ paddingLeft: 2 }}>
                <HeatmapsListByFloor
                  projectId={project.id}
                  heatmaps={project.heatmaps}
                />
              </Stack>
            </Stack>
          );
        })}
    </Stack>
  );
}

function ResultsBuildingProjectsDisplaySkeleton() {
  return (
    <Stack direction="column" spacing={2}>
      {Array(3)
        .fill(1)
        .map((_, i) => (
          <Stack
            key={`project-results-skeleton-${_ + i}`}
            direction="column"
            spacing={2}
          >
            <Typography color="primary" variant="h5">
              <Skeleton width={900} />
            </Typography>
            <Stack sx={{ paddingLeft: 2 }}>
              <Stack spacing={2}>
                {[1].map((floorNumber) => {
                  return (
                    <Stack
                      key={`project-results-skeleton-floor-${
                        _ + i
                      }-${floorNumber}`}
                    >
                      <Typography variant="h6">
                        <Skeleton width={200} />
                      </Typography>
                      <Stack sx={{ mt: 1, ml: 2 }} spacing={1}>
                        {Array(3)
                          .fill(1)
                          .map((_, i) => {
                            return (
                              <Stack
                                key={`project-results-skeleton-fp-name-${
                                  _ + i
                                }`}
                                direction="row"
                                spacing={1}
                              >
                                <ImageIcon color="disabled" />
                                <Typography gutterBottom variant="body1">
                                  <Skeleton width={440} />
                                </Typography>
                              </Stack>
                            );
                          })}
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
}
