import axios from 'axios';

import config from 'Config/config';

const BASEURL = 'https://6z8x2uhxgi.execute-api.eu-west-1.amazonaws.com/dev' + '';
const BASEURLSAMPLES = BASEURL + '/samples';

const samplePoints = {
  createSamplePoints: async (data) => {
    return await axios.post(BASEURL + '/create', data);
  },
  getAllSamplePointsForTest: async (testId, source) => {
    const options = {};
    if (source) {
      options.cancelToken = source.token;
    }

    return await axios.get(`${BASEURL}?testId=${testId}`, options);
  },
  getAllSamplePointsForProject: async (projectId) => {
    return await axios.get(`${BASEURL}?projectId=${projectId}`);
  },
  getLabelsForProject: async (projectId, source) => {
    const options = {};
    if (source) {
      options.cancelToken = source.token;
    }

    return await axios.get(`${BASEURL}/labels?projectId=${projectId}`, options);
  },
  getAllSamples: async (testId, projectId, source) => {
    const options = {};
    if (source) {
      options.cancelToken = source.token;
    }

    if (testId !== null) {
      const response = await axios.get(
        BASEURLSAMPLES + '?testId=' + testId,
        options,
      );
      return response;
    } else {
      const response = await axios.get(
        BASEURLSAMPLES + '?projectId=' + projectId,
        options,
      );
      return response;
    }
  },
  getDetails: async (testId) => {
    const response = await axios.get(BASEURL + '/details?testId=' + testId, {});
    return response;
  },
  updateSamplePoint: async (spData) => {
    return await axios.put(BASEURL, spData);
  },
};

export default samplePoints;
