import { ERROR_AUTOCLOSE_TIMEOUT_MS } from 'Constants';
import React, { useState } from 'react';

import EditBorderButton from '../../Buttons/EditBorderButton';
import AddEditCompanyModal from '../AddEditCompanyModal';

import authentication from 'Api/authentication';

import ErrorMessage from 'Components/UI/ErrorMessage';

import { useIsMounted } from 'Context';

import { isValidResponse } from 'Utils';

export default function EditCompanyBtn({
  displayOnly = false,
  afterEdit,
  company,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [editInProgress, setEditInProgress] = useState(false);
  const [mainError, setMainError] = useState('');
  const mounted = useIsMounted();

  const editCompany = async (values) => {
    setEditInProgress(true);
    let isSuccess = false;
    try {
      const { companyid, companyname, companyaddress, contact, type } = values;
      const bodyObj = {
        companyName: companyname,
        companyAddress: companyaddress,
        contact,
        type,
      };

      const invalidData =
        !companyid ||
        Object.values(bodyObj).reduce((res, value) => res || !value, false);

      if (invalidData) {
        const error = new Error('Invalid request data');
        throw error;
      }
      const response = await authentication.updateOneCompany(
        companyid,
        bodyObj,
      );
      isSuccess = isValidResponse(response);
      if (!isSuccess) {
        throw new Error(response?.data?.error?.message);
      }
    } catch (err) {
      console.log('Edit company error', err);
      if (mounted.current) {
        setMainError(err?.message || `Couldn't save changes`);
        setTimeout(() => setMainError(''), ERROR_AUTOCLOSE_TIMEOUT_MS);
      }
    } finally {
      if (mounted.current) {
        setEditInProgress(false);
      }
      if (isSuccess && typeof afterEdit === 'function') {
        setModalOpen(false);
        afterEdit();
      }
    }
  };

  return displayOnly ? (
    <EditBorderButton variant="text" />
  ) : (
    <>
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
      <EditBorderButton
        variant="text"
        clickAction={() => setModalOpen(true)}
        extraBtnProps={{ disableFocusRipple: true }}
      />
      <AddEditCompanyModal
        open={modalOpen}
        type="edit"
        handleClose={() => setModalOpen(false)}
        handleSubmit={(values) => editCompany(values)}
        company={company}
        actionsDisabled={editInProgress}
      />
    </>
  );
}
