import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import EditBorderButton from 'Components/Buttons/EditBorderButton';
import EditTestOrderModal from 'Components/SingleProject/Main/Modals/EditTestOrderModal';

import { projectDataSelector } from 'Features/Projects/projectsSlice';

export default function ReorderTestsBtn() {
  const [modalOpen, setModalOpen] = useState(false);
  const { projectTests, isProjectTestsLoading } =
    useSelector(projectDataSelector);

  return (
    <React.Fragment>
      <EditBorderButton
        disableStyles={true}
        text="Edit test order"
        clickAction={() => setModalOpen(true)}
        extraBtnProps={{
          disableFocusRipple: true,
          disabled: isProjectTestsLoading,
        }}
      />
      <EditTestOrderModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        projectTests={projectTests}
      />
    </React.Fragment>
  );
}
