import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from '@mui/material';

export default function DeleteIconButton({
  onClick,
  sx,
  disabled = false,
  tooltipText = '',
  iconSize = 'small',
  size = 'small',
  color = 'error',
}) {
  return (
    <Tooltip title={tooltipText} placement="top">
      <span>
        <IconButton
          size={size}
          disabled={disabled}
          color={color}
          sx={{ ...sx }}
          onClick={onClick}
        >
          <DeleteIcon fontSize={iconSize} />
        </IconButton>
      </span>
    </Tooltip>
  );
}
