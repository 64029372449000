import React from 'react';
import { useSelector } from 'react-redux';

import { Skeleton } from '@mui/material';

import Title from 'Components/Mix/Title';

import { projectDataSelector } from 'Features/Projects/projectsSlice';

export default function ProjectTitle() {
  const { projectDetails, isProjectDetailsLoading } =
    useSelector(projectDataSelector);

  if (isProjectDetailsLoading) return <Title text={<Skeleton />} />;

  return <Title text={`${projectDetails.name} (Id ${projectDetails.id})`} />;
}
