import React from 'react';

import { Stack } from '@mui/material';

import TerritorySelector from 'Components/Portfolios/PortfolioDetails/TerritorySelector';

import { getCompanies } from 'Utils';

const PortfolioDetails = ({ portfolio, sites, territories, regions }) => {
  const endCustomer = getCompanies().find(
    (c) => c.companyid === portfolio.endcustomer,
  );
  const contracts = Array.from(Object.values(portfolio.contracts));
  return (
    <Stack spacing={1}>
      <Stack spacing={1} justifyContent="center">
        <TerritorySelector
          portfolio={portfolio}
          contracts={contracts}
          sites={sites}
          territories={territories}
          regions={regions}
          endCustomer={endCustomer}
        />
      </Stack>
    </Stack>
  );
};

export default PortfolioDetails;
