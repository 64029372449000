import React, { Fragment, useEffect, useState } from 'react';

import {
  BreadcrumbMainLink,
  BreadcrumbSubLink,
  DropDownMenuBox,
} from '../../Layout/layoutStyles';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Skeleton, Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { grey } from '@mui/material/colors';

function AppBreadcrumbs({ links, containerStyle = null }) {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const refBreadcrumbs = React.createRef();

  const handleClick = (event, param) => {
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    switch (param) {
      case 1:
        setOpen1((prev) => !prev);
        break;
      case 2:
        setOpen2((prev) => !prev);
        break;
      case 3:
        setOpen3((prev) => !prev);
        break;
      case 4:
        setOpen4((prev) => !prev);
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
  };

  const handleCloseAway = (event) => {
    if (
      refBreadcrumbs.current &&
      !refBreadcrumbs.current.contains(event.target)
    ) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleCloseAway);
    return () => {
      document.addEventListener('mousedown', handleCloseAway);
    };
  });

  useEffect(() => {
    handleClose();
  }, [links]);

  const MenuItem = ({ item }) => {
    return (
      <ListItem component="div" disablePadding>
        <ListItemButton>
          <BreadcrumbSubLink to={item.link}>{item.text}</BreadcrumbSubLink>
        </ListItemButton>
      </ListItem>
    );
  };

  const ComponentLink = ({ item, arrowIndex }) => {
    let attributesMenu = {};
    switch (arrowIndex) {
      case 1:
        attributesMenu = {
          open: open1,
        };
        break;
      case 2:
        attributesMenu = {
          open: open2,
        };
        break;
      case 3:
        attributesMenu = {
          open: open3,
        };
        break;
      case 4:
        attributesMenu = {
          open: open4,
        };
        break;
      default:
        break;
    }

    return (
      <>
        <BreadcrumbMainLink to={item.link}>{item.text}</BreadcrumbMainLink>
        {!!item.submenu && !!item.submenu.length && (
          <KeyboardArrowDownIcon
            sx={{ color: 'grey', ml: 0.5 }}
            onClick={(e) => handleClick(e, arrowIndex)}
          />
        )}
        <Box style={{ position: 'relative' }}>
          {attributesMenu.open && (
            <DropDownMenuBox onClose={handleClose}>
              {item.submenu.map((m, index) => {
                if (m.parentText) {
                  return (
                    <Fragment key={index}>
                      <ListItem component="div" disablePadding>
                        <ListItemText
                          primary={m.parentText}
                          sx={{
                            whiteSpace: 'nowrap',
                            ml: 1,
                            color: grey['600'],
                          }}
                        />
                      </ListItem>
                      <MenuItem item={m} key={index} />
                    </Fragment>
                  );
                } else {
                  return <MenuItem item={m} key={index} />;
                }
              })}
            </DropDownMenuBox>
          )}
        </Box>
        <Box sx={{ mx: 1 }}>/</Box>
      </>
    );
  };

  const ComponentText = ({ item }) => {
    return <Typography color="text.primary">{item.text}</Typography>;
  };

  let arrowIndex = 0;
  return (
    <div
      style={
        !containerStyle
          ? { display: 'flex', marginBottom: 15, marginTop: 5 }
          : containerStyle
      }
      ref={refBreadcrumbs}
    >
      {!!links &&
        links.map((item, index) => {
          if (!item) {
            return (
              <React.Fragment key={index}>
                <Typography sx={{ width: '120px' }}>
                  <Skeleton />
                </Typography>
                {index + 1 !== links.length ? (
                  <Box sx={{ mx: 1 }}>/</Box>
                ) : null}
              </React.Fragment>
            );
          }
          if (item.link) {
            arrowIndex++;
            return (
              <ComponentLink item={item} key={index} arrowIndex={arrowIndex} />
            );
          } else {
            return <ComponentText item={item} key={index} />;
          }
        })}
    </div>
  );
}

export default AppBreadcrumbs;
