import { HEALTHCARE_TEST_TYPES } from 'Constants';
import React from 'react';

import ScenariosTableHealthcare from './ScenariosTableHealthcare';
import SingleTestCommonScenariosTable from './SingleTestCommonScenariosTable';

export default function ScenariosTableSingleTest(props) {
  if (!props?.test?.testtype || !props?.test?.testid) {
    return null;
  }
  return (
    <React.Fragment key={`scenarios-${props?.test?.testid}`}>
      {Object.values(HEALTHCARE_TEST_TYPES).includes(props.test.testtype) ? (
        <ScenariosTableHealthcare {...props} />
      ) : (
        <SingleTestCommonScenariosTable {...props} />
      )}
    </React.Fragment>
  );
}
