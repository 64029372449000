import { colorsFilterValues } from 'Constants';
import React, { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export default function SelectColors({ value, disabled, handleChange, type }) {
  const [viewConfig, setViewConfig] = useState({
    selectExtraProps: {
      IconComponent: undefined,
    },
  });

  useEffect(() => {
    const config = {};

    config[colorsFilterValues.HIGH] =
      type === 'significance' ? 'High' : 'Failed';
    config[colorsFilterValues.MEDIUM] =
      type === 'significance' ? 'Medium' : 'Invalid';
    config[colorsFilterValues.LOW] = type === 'significance' ? 'Low' : 'Passed';
    config['label'] = type === 'significance' ? 'Significance' : 'Result';

    config.selectExtraProps = {};
    if (disabled) {
      config.selectExtraProps.IconComponent = undefined;
    }
    setViewConfig(config);
  }, [type, disabled]);

  return (
    <>
      <Box sx={{ width: 150 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            {viewConfig['label'] || ''}
          </InputLabel>
          <Select
            value={value ? value : ''}
            label="Significance"
            onChange={handleChange}
            disabled={disabled}
            {...viewConfig.selectExtraProps}
          >
            <MenuItem value={colorsFilterValues.HIGH}>
              <Box
                sx={{
                  height: 20,
                  display: 'flex',
                }}
              >
                <Box
                  sx={{
                    height: 20,
                    backgroundColor: 'red',
                    paddingLeft: '30px',
                    marginRight: '5px',
                  }}
                />
                <Typography sx={{ marginLeft: 1 }}>
                  {viewConfig[colorsFilterValues.HIGH]}
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem value={colorsFilterValues.MEDIUM}>
              <Box
                sx={{
                  height: 20,
                  display: 'flex',
                }}
              >
                <Box
                  sx={{
                    height: 20,
                    backgroundColor: 'yellow',
                    paddingLeft: '30px',
                    marginRight: '5px',
                  }}
                />
                <Typography sx={{ marginLeft: 1 }}>
                  {viewConfig[colorsFilterValues.MEDIUM]}
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem value={colorsFilterValues.LOW}>
              <Box
                sx={{
                  height: 20,
                  display: 'flex',
                }}
              >
                <Box
                  sx={{
                    height: 20,
                    backgroundColor: 'green',
                    paddingLeft: '30px',
                    marginRight: '5px',
                  }}
                />
                <Typography sx={{ marginLeft: 1 }}>
                  {viewConfig[colorsFilterValues.LOW]}
                </Typography>
              </Box>
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
}
