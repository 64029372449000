import * as React from 'react';

import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loading() {
  return (
    <Box sx={{ width: '100%', position: 'relative', textAlign: 'center' }}>
      <CircularProgress
        color="success"
        sx={{
          position: 'absolute',
          zIndex: 100,
          top: 'calc(50vh - (40px / 2) - 80px)', // 80px - header height, 40px - icon size
          left: `calc(50vw - 73px - (40px / 2))`, // 73px - menu width, 40px - icon size
        }}
      />
    </Box>
  );
}
