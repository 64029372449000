import {
  HEATMAP_VERIFICATION_LIKE_BEHAVIOR,
  PROJECT_STATUSES_LABELS,
} from 'Constants';
import React, { useMemo } from 'react';

import '../styles.css';

import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

function ProjectScopeView({
  projectId,
  projectName,
  address,
  customer,
  details,
  dataIssues,
  description,
  tagInfo,
  partner,
  status,
}) {
  const getTodayDate = () => {
    let today = new Date();
    return (
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      today.getDate().toString().padStart(2, '0') +
      ' ' +
      today.getHours().toString().padStart(2, '0') +
      ':' +
      today.getMinutes().toString().padStart(2, '0') +
      ':' +
      today.getSeconds().toString().padStart(2, '0')
    );
  };

  const ProjectInfoTable = () => {
    return (
      <TableContainer className="projectConfiguration-standartTable--container">
        <Table size="small" aria-label="Project Info">
          <TableBody>
            <TableRow>
              <TableCell
                style={{
                  background: 'rgb(0, 137, 150)',
                  color: 'white',
                }}
              >
                Project ID
              </TableCell>
              <TableCell colSpan={3}>{projectId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{
                  background: 'rgb(0, 137, 150)',
                  color: 'white',
                }}
              >
                Project Name
              </TableCell>
              <TableCell>{projectName}</TableCell>
              <TableCell
                style={{
                  background: 'rgb(0, 137, 150)',
                  color: 'white',
                }}
              >
                Status
              </TableCell>
              <TableCell>{PROJECT_STATUSES_LABELS[status]}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{
                  background: 'rgb(0, 137, 150)',
                  color: 'white',
                }}
              >
                Address
              </TableCell>
              <TableCell>{address}</TableCell>
              <TableCell
                style={{
                  background: 'rgb(0, 137, 150)',
                  color: 'white',
                }}
              >
                Customer
              </TableCell>
              <TableCell>{customer}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{
                  background: 'rgb(0, 137, 150)',
                  color: 'white',
                }}
              >
                Description
              </TableCell>
              <TableCell>{description}</TableCell>
              <TableCell
                style={{
                  background: 'rgb(0, 137, 150)',
                  color: 'white',
                }}
              >
                Partner
              </TableCell>
              <TableCell>{partner}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const tagsFilteredData = useMemo(() => {
    if (!tagInfo || !Array.isArray(tagInfo)) {
      return [];
    }

    const cache = [];

    const result = [];

    for (let singleTag of tagInfo) {
      const { tag, lotNumber, expirationDate } = singleTag;

      const strData = `${tag}${lotNumber}${expirationDate}`;

      if (!~cache.indexOf(strData)) {
        result.push(singleTag);
        cache.push(strData);
      }
    }

    return result;
  }, [tagInfo]);

  const TagsInfoTable = () => {
    return (
      <TableContainer className="projectConfiguration-standartTable--container">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Tag</TableCell>
              <TableCell align="left">Lot Number</TableCell>
              <TableCell align="left">Exp. Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tagsFilteredData.map(({ tag, lotNumber, expirationDate }, i) => (
              <TableRow key={i}>
                <TableCell align="left">{tag}</TableCell>
                <TableCell align="left">{lotNumber}</TableCell>
                <TableCell align="left">{expirationDate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const TestsTable = () => {
    let currentTestId = 0;
    let scenario = 0;
    return (
      <TableContainer className="projectConfiguration-standartTable--container">
        <Table size="small" aria-label="test data rows">
          <TableHead>
            <TableRow>
              {[
                'Done',
                'Issues',
                'Test Num',
                'Test Id',
                'Test Date',
                'Test Name',
                'Scenario',
                'Scenario Desc',
                'OP Count',
                'OP Tags',
                'Sample Type',
                'SP Count',
                'Interval Count',
                'Interval Duration',
                'Sample Count',
                'SN Start',
                'SN End',
              ].map((cellName, i) => (
                <TableCell align="center" key={i}>
                  {cellName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {details !== undefined &&
              details.map(
                (
                  {
                    testId,
                    isCompleted,
                    testSequenceNumber,
                    testDate,
                    testName,
                    scenarioDescriptions,
                    opCount,
                    opTags,
                    sampleType,
                    spCount,
                    intervalCount,
                    intervalDuration,
                    type,
                    sampleCount,
                    startingSampleNumber,
                    endingSampleNumber,
                  },
                  i,
                ) => {
                  if (currentTestId !== testId) {
                    currentTestId = testId;
                    scenario = 0;
                  }
                  scenario++;
                  return (
                    <TableRow key={i}>
                      <TableCell>
                        {isCompleted === true ? 'yes' : 'no'}
                      </TableCell>
                      <TableCell>
                        {dataIssues.filter((i) => i.testId === testId).length >
                          0 && (
                          <WarningTwoToneIcon className="projectConfiguration-projectScope--warningIcon" />
                        )}
                      </TableCell>
                      <TableCell>{testSequenceNumber}</TableCell>
                      <TableCell>{testId}</TableCell>
                      <TableCell>
                        {testDate !== null ? testDate.split('T')[0] : 'n/a'}
                      </TableCell>
                      <TableCell>{testName}</TableCell>
                      <TableCell>{scenario}</TableCell>
                      <TableCell>{scenarioDescriptions}</TableCell>
                      <TableCell>{opCount}</TableCell>
                      <TableCell>{opTags}</TableCell>
                      <TableCell>{sampleType}</TableCell>
                      <TableCell>{spCount}</TableCell>
                      <TableCell>{intervalCount}</TableCell>
                      <TableCell>
                        {intervalDuration ||
                          (!!~HEATMAP_VERIFICATION_LIKE_BEHAVIOR.indexOf(
                            type,
                          ) &&
                            'Custom (5)')}
                      </TableCell>
                      <TableCell>{sampleCount}</TableCell>
                      <TableCell>{startingSampleNumber}</TableCell>
                      <TableCell>{endingSampleNumber}</TableCell>
                    </TableRow>
                  );
                },
              )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div className="projectConfiguration-projectScope">
      <div style={{ paddingBottom: '10px' }}>
        <Typography variant="subtitle1">
          Prepared on {getTodayDate()}
        </Typography>
      </div>
      {ProjectInfoTable()}
      <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
        <Typography variant="h6">Project Scope</Typography>
      </div>

      {Array.isArray(tagInfo) && tagInfo.length > 0 && TagsInfoTable()}
      <br />
      <br />
      <div>{TestsTable()}</div>
    </div>
  );
}

export default ProjectScopeView;
