import {
  ACCESS_LEVEL_ADMIN,
  ACCESS_LEVEL_EDITOR,
  ACCESS_LEVEL_VIEWER,
  ROLE_CUSTOMER,
  ROLE_PARTNER,
  ROLE_SAFETRACES,
} from './roles';
import {
  ROUTE_COMPANIES,
  ROUTE_DASHBOARD, // ROUTE_CONTRACTS,
  ROUTE_DOCUMENTS,
  ROUTE_PORTFOLIOS,
  ROUTE_PROJECTS,
  ROUTE_RESULTS,
  ROUTE_SITES,
  ROUTE_TAGS,
  ROUTE_TEST_PACKAGES,
  ROUTE_USERS,
} from './routes';

const nav = [
  {
    to: ROUTE_DASHBOARD,
    name: 'Dashboard',
    icon: ' Dashboard',
    permission: [ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN],
  },
  {
    to: ROUTE_RESULTS,
    name: 'Results',
    icon: 'event',
  },
  // {
  //   to: ROUTE_CONTRACTS,
  //   name: 'Contracts',
  //   icon: 'feed',
  // },
  {
    name: 'Admin',
    icon: 'AdminPanelSettingsIcon',
    permission: [ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN],
    children: [
      {
        to: ROUTE_PORTFOLIOS,
        name: 'Portfolios',
        icon: 'PlaylistAddCheckCircleIcon',
        permission: [ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN],
      },
      {
        to: ROUTE_USERS,
        name: 'Users',
        icon: 'group',
        permission: [ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN],
      },
      {
        to: ROUTE_COMPANIES,
        name: 'Companies',
        icon: 'BusinessCenterIcon',
        permission: [ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN],
      },
      {
        to: ROUTE_TAGS,
        name: 'Tags',
        icon: 'LocalOfferIcon',
        permission: [ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN],
      },
    ],
  },
  {
    to: ROUTE_SITES,
    name: 'Sites & Buildings',
    icon: 'ApartmentIcon',
    permission: [
      ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN,
      ROLE_SAFETRACES + ACCESS_LEVEL_EDITOR,
      ROLE_SAFETRACES + ACCESS_LEVEL_VIEWER,
      ROLE_PARTNER + ACCESS_LEVEL_ADMIN,
      ROLE_PARTNER + ACCESS_LEVEL_EDITOR,
      ROLE_PARTNER + ACCESS_LEVEL_VIEWER,
    ],
  },
  {
    to: ROUTE_TEST_PACKAGES,
    name: 'Test packages',
    icon: ' BiotechIcon',
    permission: [
      ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN,
      ROLE_SAFETRACES + ACCESS_LEVEL_EDITOR,
      ROLE_PARTNER + ACCESS_LEVEL_ADMIN,
      ROLE_PARTNER + ACCESS_LEVEL_EDITOR,
    ],
  },
  {
    to: ROUTE_PROJECTS,
    name: 'Projects',
    icon: 'DesignServicesIcon',
    permission: [
      ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN,
      ROLE_SAFETRACES + ACCESS_LEVEL_EDITOR,
      ROLE_SAFETRACES + ACCESS_LEVEL_VIEWER,
      ROLE_PARTNER + ACCESS_LEVEL_ADMIN,
      ROLE_PARTNER + ACCESS_LEVEL_EDITOR,
      ROLE_PARTNER + ACCESS_LEVEL_VIEWER,
    ],
  },
  {
    to: ROUTE_USERS,
    name: 'Users',
    icon: 'group',
    permission: [ROLE_PARTNER + ACCESS_LEVEL_ADMIN],
  },
  {
    to: ROUTE_DOCUMENTS,
    name: 'Documents',
    icon: 'SummarizeIcon',
    permission: [ROLE_SAFETRACES + ACCESS_LEVEL_ADMIN],
  },
];

export default nav;
