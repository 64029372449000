import React, { useMemo } from 'react';

import OriginPointsLabelsPrint from './Labels/OriginPointsLabelsPrint';
import SamplePointsLabelsPrint from './Labels/SamplePointsLabelsPrint';
import SamplesLabelsPrint from './Labels/SamplesLabelsPrint';

import { Alert, Box, Skeleton, Stack } from '@mui/material';

function Labels({
  loading,
  projectId,
  projectName,
  hasSamples,
  hasOP,
  hasSP,
  onError,
}) {
  const LabelsUsageAlert = useMemo(() => {
    if (!hasSP && !hasOP && !hasSamples) {
      return null;
    }

    return (
      <Alert sx={{ mb: 2, maxWidth: '520px' }} severity="info">
        <div>
          For <span style={{ fontWeight: 600 }}>samples</span>, please use label
          Online Label OL28 which you can find{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.onlinelabels.com/products/ol28?src=dlc-410"
          >
            here
          </a>
        </div>
        <div>
          {' '}
          For <span style={{ fontWeight: 600 }}>Origin Points</span> and{' '}
          <span style={{ fontWeight: 600 }}>Sample Points</span>, please use
          label Online Label OL996LP which you can find{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.onlinelabels.com/products/ol28?src=dlc-410"
          >
            here
          </a>
        </div>
      </Alert>
    );
  }, [hasSP || hasOP || hasSamples]);

  return (
    <Stack direction="column">
      {loading && (
        <Skeleton variant="rounded" height={35} sx={{ maxWidth: '580px' }} />
      )}
      {!loading && (
        <>
          {LabelsUsageAlert}
          <Box sx={{ minHeight: 40 }}>
            <Stack direction="row" gap={2}>
              <OriginPointsLabelsPrint
                projectId={projectId}
                hasOP={hasOP}
                projectName={projectName}
                onError={onError}
              />
              <SamplePointsLabelsPrint
                projectId={projectId}
                hasSP={hasSP}
                projectName={projectName}
                onError={onError}
              />
              <SamplesLabelsPrint
                projectId={projectId}
                hasSamples={hasSamples}
                projectName={projectName}
                onError={onError}
              />
            </Stack>
          </Box>
        </>
      )}
    </Stack>
  );
}

export default React.memo(Labels);
