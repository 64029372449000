import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const createDataObj = (description, value) => ({ description, value });

const dataConfig = {
  'Well-Mixed Air Distribution Systems': [
    createDataObj('Ceiling supply of cool air', '1.0'),
    createDataObj('Ceiling supply of warm air and floor return', '1.0'),
    createDataObj(
      'Ceiling supply of warm air 15°F (8°C) or more above space temperature and ceiling return',
      0.8,
    ),
    createDataObj(
      `Ceiling supply of warm air less than 15°F (8°C) above average space temperature where the
      supply air-jet velocity is less than 150 fom (0.8 m/s) within 4.5 ft (1.4 m) of the floor and ceiling return`,
      0.8,
    ),
    createDataObj(
      `Ceiling supply of warm air less than 15°F (8°C) above average space temperature where the supply air-jet velocity
        is equal to or greater than 150 fpm (0.8 m/s) within 4.5 ft (1.4 m) of the floor and ceiling return`,
      '1.0',
    ),
    createDataObj(`Floor supply of warm air and floor return`, '1.0'),
    createDataObj('Floor supply of warm air and ceiling return', 0.7),
    createDataObj(
      'Makeup supply outlet located more than half the length of the space from the exhaust, return, or both',
      0.8,
    ),
    createDataObj(
      'Makeup supply outlet located less than half the length of the space from the exhaust, return, or both',
      0.5,
    ),
  ],
  'Stratified Air Distribution Systems': [
    createDataObj(
      `Floor supply of cool air where the vertical throw is greater than or equal to 60 fpm (0.25 m/s) at a height of 4.5 ft (1.4 m)
    above the floor and ceiling return at a height less than or equal to 18 ft (5.5 m) above the floor`,
      1.05,
    ),
    createDataObj(
      `Floor supply of cool air where the vertical throw is less than or equal to 60 fpm (0.25 m/s) at a height of 4.5 ft (1.4 m)
    above the floor and ceiling return at a height less than or equal to 18 ft (5.5 m) above the floor`,
      1.2,
    ),
    createDataObj(
      `Floor supply of cool air where the vertical throw is less than or equal to 60 fpm (0.25 m/s) at a height of 4.5 ft (1.4 m)
    above the floor and ceiling return at a height greater than 18 ft (5.5 m) above the floor`,
      1.5,
    ),
  ],
  'Personalized Ventilation Systems': [
    createDataObj(
      `Personalized air at a height of 4.5 ft (1.4 m) above the floor combined with ceiling supply of cool air and ceiling return`,
      '1.40',
    ),
    createDataObj(
      `Personalized air at a height of 4.5 ft (1.4 m) above the floor combined with ceiling supply of warm air and ceiling return`,
      '1.40',
    ),
    createDataObj(
      `Personalized air at a height of 4.5 ft (1.4 m) above the floor combined with a stratified air distribution system with nonaspirating floor supply devices and ceiling return`,
      '1.20',
    ),
    createDataObj(
      `Personalized air at a height of 4.5 ft (1.4 m) above the floor combined with a stratified air distribution system with aspirating floor supply devices and ceiling return`,
      '1.50',
    ),
  ],
};

function CategoryRow(props) {
  const { categoryName, data, defaultOpen } = props;
  const [open, setOpen] = useState(defaultOpen);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          <Typography component="h4">{categoryName}</Typography>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            sx={{ display: 'flex', ml: 'auto' }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ mt: 1 }}>
              <Table size="small" aria-label="info">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: '1.05em', fontWeight: 600 }}>
                      Air Distribution Configuration
                    </TableCell>
                    <TableCell align="right">
                      <span
                        style={{
                          fontSize: '1.05em',
                          fontStyle: 'italic',
                          fontWeight: 600,
                        }}
                      >
                        E
                      </span>
                      <span
                        style={{
                          fontSize: '0.8em',
                          position: 'relative',
                          top: '2px',
                        }}
                      >
                        z
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map(({ description, value }, index) => (
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                      key={`${categoryName}-data-${index}`}
                    >
                      <TableCell component="th" scope="row">
                        {description}
                      </TableCell>
                      <TableCell>{value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function AirDistributionExplanationModal({ open, handleClose }) {
  return (
    <div>
      <Dialog
        open={open}
        transitionDuration={220}
        PaperProps={{
          sx: {
            p: 1,
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
        maxWidth="md"
        onClose={handleClose}
      >
        <DialogTitle>Zone Air Distribution Effectiveness</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper} elevation={0}>
            <Table
              sx={{ width: 650 }}
              aria-label="collapsible table"
              size="small"
            >
              <TableBody>
                {Object.keys(dataConfig).map((subheaderName, index) => (
                  <CategoryRow
                    key={index}
                    categoryName={subheaderName}
                    data={dataConfig[subheaderName]}
                    defaultOpen={index === 0}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
}
