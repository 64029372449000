import { createSlice } from '@reduxjs/toolkit';

import buildingsApi from 'Api/buildings';
import testApi from 'Api/tests';

import {
  getTestDate,
  getTestIndex,
  getTestName,
  getTestType,
} from 'Components/Breadcrumbs/dataUtils';

import { getDataFromResponse, isValidResponse } from 'Utils';

function startLoading(state) {
  state.isLoading = true;
}

function finishLoading(state) {
  state.isLoading = false;
}

function loadingFailed(state, action) {
  state.isLoading = false;
  state.error = action.payload;
}

export const slice = createSlice({
  name: 'tests',
  initialState: {
    tests: [],
    currentTestName: '',
    currentTestId: 0,
    currentBuildingId: 0,
    currentTestDate: '',
    currentTestType: '',
    currentTestIndex: 0,
    isLoading: true,
    error: null,
  },
  reducers: {
    setStartLoading: startLoading,
    setFinishLoading: finishLoading,
    setLoadingFailed: loadingFailed,
    setCurrentBuildingId: (state, action) => {
      state.currentBuildingId = action.payload;
    },
    setCurrentTestName: (state, action) => {
      state.currentTestName = action.payload;
    },
    setCurrentTestId: (state, action) => {
      state.currentTestId = action.payload;
    },
    setTestsSuccess: (state, action) => {
      state.isLoading = true;
      // Injecting an index number to each test
      state.tests = action.payload.map((test, index) => {
        test.index = index + 1;
        return test;
      });
      state.tests = action.payload;
    },
    setNotError: (state) => {
      state.error = null;
    },
    setCurrentTestDate: (state, action) => {
      state.currentTestDate = action.payload;
    },
    setCurrentTestType: (state, action) => {
      state.currentTestType = action.payload;
    },
    setCurrentTestIndex: (state, action) => {
      state.currentTestIndex = action.payload;
    },
  },
});

export const fetchBuildingTest =
  (
    buildingId,
    colors,
    testId = null,
    forceUpdate = false,
    inProgress = false,
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch(setStartLoading());
      const { currentTestId, currentBuildingId, tests } = getState().tests;

      if (parseInt(currentBuildingId) !== parseInt(buildingId)) {
        dispatch(setTestsSuccess([]));
      }

      if (
        !tests ||
        !tests.length ||
        currentBuildingId !== buildingId ||
        forceUpdate
      ) {
        dispatch(setCurrentBuildingId(buildingId));
        const response = await buildingsApi.getBuildingTests(
          buildingId,
          colors,
          inProgress,
        );

        const displayNumbersRes = await testApi.getDisplayNumbersBuilding(
          buildingId,
        );

        const displayNumberConfig = isValidResponse(displayNumbersRes)
          ? getDataFromResponse(displayNumbersRes)
          : {};

        const _newTests = response.data.data || [];

        const testIdsUnique = [];
        const newTests = [];
        for (let test of _newTests) {
          if (testIdsUnique.includes(test.testid)) {
            continue;
          }
          testIdsUnique.push(test.testid);
          newTests.push(test);
        }

        for (let test of newTests) {
          test.displayNumber = +displayNumberConfig[test.testid] || null;
        }

        dispatch(setCurrentTestIndex(+displayNumberConfig[testId]));

        dispatch(setCurrentTestDate(getTestDate(newTests, testId)));
        dispatch(setTestsSuccess(newTests));
        if (!forceUpdate) {
          dispatch(setCurrentTestId(testId));
          let testName = getTestName(newTests, testId);
          let testType = getTestType(newTests, testId);
          if ((!testName || !testType) && testId) {
            const responseTest = await testApi.getTest(testId);
            const test = responseTest.data.data;
            testName = test.testname;
            testType = test.testtype;
          }
          dispatch(setCurrentTestName(testName));
          dispatch(setCurrentTestType(testType));
        }
      } else {
        if (testId !== null && currentTestId !== testId) {
          dispatch(setCurrentTestId(testId));
          dispatch(setCurrentTestDate(getTestDate(tests, testId)));
          const testName = getTestName(tests, testId);
          const testType = getTestType(tests, testId);

          dispatch(setCurrentTestType(testType));
          dispatch(setCurrentTestName(testName));
        }
        dispatch(setCurrentTestIndex(getTestIndex(tests, testId)));
      }
      dispatch(setFinishLoading());
    } catch (err) {
      dispatch(setLoadingFailed(err.toString()));
    }
  };

export const {
  setStartLoading,
  setFinishLoading,
  setLoadingFailed,
  setTestsSuccess,
  setCurrentTestName,
  setCurrentTestId,
  setCurrentBuildingId,
  setNotError,
  setCurrentTestDate,
  setCurrentTestType,
  setCurrentTestIndex,
} = slice.actions;

export default slice.reducer;

export const testsSelector = (state) => state.tests;
