import React, { useEffect, useState } from 'react';
import { matchRoutes } from 'react-router';
import { useNavigate } from 'react-router-dom';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from '@mui/material';

import authenticationApi from 'Api/authentication';

import { isPartnerUser } from 'Config/roles';
import routes, {
  ROUTES_PARTNER_COMPANY_CHOICE_ALL_TYPES,
  ROUTES_SAFETRACER_COMPANY_CHOICE_ALL_TYPES,
  ROUTES_SAFETRACER_COMPANY_CHOICE_PARTNER_ONLY,
  ROUTES_SAFETRACER_COMPANY_NO_CHOICE,
} from 'Config/routes';

import {
  alertCompaniesListUpdateHandled,
  changePartnerViewCompany,
  changeSafetracesViewCompany,
  useAuthDispatch,
  useAuthState,
  useIsMounted,
} from 'Context';

import {
  getCompanies,
  getLSViewCompanyId,
  isValidResponse,
  setCompanies,
} from 'Utils';

function SelectCompanyList(props) {
  const externalSx = props.sx || {};
  let type = props.type;
  const navigate = useNavigate();

  const mounted = useIsMounted();

  const {
    afterUpdateFn,
    defaultOpen,
    shouldCloseAfterDefaultOpen,
    partnerCompanyId,
    alertIsContent,
  } = props;

  const selectActions = {
    all: {
      GET_COMPANIES: async () => await authenticationApi.getCompanies(),
      CHANGE_VIEW_COMPANY: (val, companyName, force = false) =>
        changeSafetracesViewCompany(
          dispatch,
          val,
          companyName,
          force,
          navigate,
        ),
    },
    partner: {
      GET_COMPANIES: async () =>
        await authenticationApi.getPartnerCustomers(
          partnerCompanyId || null,
          true,
        ),
      CHANGE_VIEW_COMPANY: (val, companyName, force = false) =>
        changePartnerViewCompany(dispatch, val, companyName, force, navigate),
    },
  };

  const authState = useAuthState();
  const dispatch = useAuthDispatch();

  const [selectValue, setSelectValue] = useState(authState.viewCompanyId || '');
  const [companiesCustomer, setCompaniesCustomer] = useState([]);
  const [companiesPartner, setCompaniesPartner] = useState([]);
  const [open, setOpen] = useState(false);
  const [companiesLoading, setCompaniesLoading] = useState(false);

  const handleChange = (event) => {
    const val = event.target.value;
    changeCompany(val);
  };

  const getSelectType = () => (isPartnerUser() ? 'partner' : 'all');

  const changeCompany = (val, force = false) => {
    if (!val && force) {
      selectActions[type].CHANGE_VIEW_COMPANY(val, '', true);
      return;
    }

    const company = getCompanies().find((i) => i.companyid === val);
    const companyName = company?.companyname ?? '';
    type = type ?? getSelectType();
    selectActions[type].CHANGE_VIEW_COMPANY(val, companyName);
    if (typeof afterUpdateFn === 'function') {
      afterUpdateFn();
    }
  };

  useEffect(() => {
    type = type ?? getSelectType();

    fetchCompanyList();
  }, []);

  useEffect(() => {
    if (mounted.current) {
      setOpen(!!defaultOpen);
    }
    if (defaultOpen && shouldCloseAfterDefaultOpen) {
      setTimeout(() => {
        if (mounted.current) {
          setOpen(false);
        }
      }, 1500);
    }
  }, [defaultOpen]);

  useEffect(() => {
    if (authState.viewCompanyId && authState.viewCompanyId !== selectValue) {
      if (mounted.current) {
        setSelectValue(authState.viewCompanyId);
      }
      changeCompany(authState.viewCompanyId);
    }
  }, [authState.viewCompanyId]);

  useEffect(() => {
    if (!mounted.current) return;
    const { companiesListUpdateHandled, companiesListDidUpdate } = authState;
    if (companiesListDidUpdate && !companiesListUpdateHandled) {
      fetchCompanyList();
    }
  }, [authState.companiesListDidUpdate]);

  const fetchCompanyList = async () => {
    try {
      setCompaniesLoading(true);
      type = type ?? getSelectType();
      await new Promise((res) => setTimeout(res, 1000));
      const response = await selectActions[type].GET_COMPANIES();
      if (isValidResponse(response)) {
        const result = response.data.data;
        if (alertIsContent && typeof alertIsContent === 'function') {
          alertIsContent(!!result.length);
        }

        if (result.length) {
          const filteredByPartners = [];
          const filteredByCustomers = [];

          for (let company of result) {
            const companyType = company.type && company.type.toLowerCase();
            if (companyType === 'customer') {
              filteredByCustomers.push(company);
            }
            if (companyType === 'self') {
              filteredByPartners.unshift(company);
            }
            if (companyType === 'partner') {
              filteredByPartners.push(company);
            }
          }

          if (mounted.current) {
            setCompaniesCustomer(filteredByCustomers);
            setCompaniesPartner(filteredByPartners);
          }
          setCompanies(result);
          alertCompaniesListUpdateHandled(dispatch);

          if (filteredByCustomers.length === 1 && !getLSViewCompanyId()) {
            changeCompany(filteredByCustomers[0].companyid);
          }
        } else {
          if (mounted.current) {
            setCompanies([]);
          }
        }
      }
    } catch (e) {
      if (mounted.current) {
        setCompanies([]);
      }
      console.log(e.toString());
    } finally {
      if (mounted.current) {
        setCompaniesLoading(false);
      }
    }
  };

  const handleClose = () => {
    if (mounted.current) {
      setOpen(false);
    }
  };

  const handleOpen = () => {
    if (mounted.current) {
      setOpen(true);
    }
  };

  const selectItem = (item) => (
    <MenuItem value={item.companyid} key={item.companyid}>
      {item.companyname}
    </MenuItem>
  );

  const getDefaultViewType = () => {
    type = type ?? getSelectType();

    let currentPath = window.location.pathname;

    const matchedRoutes = matchRoutes(routes, currentPath);
    if (matchedRoutes) {
      currentPath = matchedRoutes[0].route.path;
    }

    const pathPartnerOnly =
      ~ROUTES_SAFETRACER_COMPANY_CHOICE_PARTNER_ONLY.indexOf(currentPath);
    const pathNoChoice =
      ~ROUTES_SAFETRACER_COMPANY_NO_CHOICE.indexOf(currentPath);
    const pathAllTypesST =
      ~ROUTES_SAFETRACER_COMPANY_CHOICE_ALL_TYPES.indexOf(currentPath);
    const pathAllTypesPartner =
      ~ROUTES_PARTNER_COMPANY_CHOICE_ALL_TYPES.indexOf(currentPath);

    if (type === 'all' && (pathAllTypesST || pathNoChoice)) {
      return 'all';
    }
    if (type === 'partner' && pathAllTypesPartner) {
      return 'all';
    }
    if (pathPartnerOnly) {
      return 'partners';
    }
    return 'customers';
  };

  const getOptions = () => {
    const viewType = type || getDefaultViewType();
    if (viewType === 'all') {
      const typeNames = {
        customer: 'Customer',
        partner: 'Partner',
      };
      const companies = getCompanies();
      const res = [];
      const resObj = {};
      for (let i = 0; i < companies.length; i++) {
        if (!resObj[companies[i].type]) {
          resObj[companies[i].type] = [];
        }
        resObj[companies[i].type].push(selectItem(companies[i]));
      }
      for (let type of Object.keys(resObj)) {
        res.push(
          <ListSubheader key={type}>
            {typeNames[type.toLowerCase()]}
          </ListSubheader>,
        );
        res.push(...resObj[type]);
      }
      return res;
    }
    if (viewType === 'customers') {
      if (Array.isArray(companiesCustomer)) {
        return companiesCustomer.map((item) => selectItem(item));
      }
    }
    if (viewType === 'partners') {
      if (Array.isArray(companiesPartner)) {
        return companiesPartner.map((item) => selectItem(item));
      }
    }
    return [];
  };

  const selectOptions = React.useMemo(
    () => getOptions(),
    [window.location.pathname, companiesCustomer, companiesPartner],
  );

  const resetCompanyChoice = () => changeCompany(0, true);

  const checkIfCompanyPresentInOptions = () => {
    const companyFromStorage = getLSViewCompanyId();
    if (!companyFromStorage || !selectOptions.length) return;

    let isCompanyPresent = false;
    for (let option of selectOptions) {
      const optionCompanyId = option?.props?.value || null;
      if (companyFromStorage === optionCompanyId) {
        isCompanyPresent = true;
        break;
      }
    }
    if (!isCompanyPresent) {
      resetCompanyChoice();
    }
  };

  useEffect(() => {
    checkIfCompanyPresentInOptions();
  }, [selectOptions]);

  return (
    <Box sx={{ minWidth: 150, marginRight: 2, ...externalSx }}>
      <FormControl fullWidth className="low-select">
        <InputLabel id="demo-simple-select-label">Companies</InputLabel>
        <Select
          id="initial-company-select"
          value={selectOptions?.length ? selectValue : ''}
          label="Company List"
          onChange={handleChange}
          onClose={handleClose}
          onOpen={handleOpen}
          sx={{ py: 0.1 }}
          open={open}
          disabled={!!companiesLoading}
          IconComponent={
            companiesLoading ? NullableReactElement : ArrowDropDown
          }
          endAdornment={
            companiesLoading ? (
              <div style={{ margin: 'auto 0px' }}>
                <CircularProgress
                  color="inherit"
                  style={{ display: 'block' }}
                  size={'16px'}
                />
              </div>
            ) : null
          }
          MenuProps={{
            sx: {
              maxHeight: 300,
            },
          }}
        >
          {selectOptions}
        </Select>
      </FormControl>
    </Box>
  );
}

const NullableReactElement = () => <span></span>;

export default React.memo(SelectCompanyList);
