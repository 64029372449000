import React, { useEffect, useState } from 'react';

import DeleteCompanyButton from './Buttons/DeleteCompanyButton';
import EditCompanyBtn from './Buttons/EditCompanyBtn';
import TableFilterBtn from './Buttons/TableFilterBtn';
import TablePaginationActions from './TablePaginationActions';

import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

import TableBodyEmptyRowsFiller from 'Components/UI/TableBodyEmptyRowsFiller';
import TableBodySkeleton from 'Components/UI/TableBodySkeleton';
import TableEmptyDataDisplay from 'Components/UI/TableEmptyDataDisplay';

import {
  LongTextTableCell,
  StyledTableCell,
  StyledTableRow,
} from 'Layout/layoutStyles';

export default function CompaniesTable({
  afterCompaniesUpdate,
  companiesData,
  companiesFetching,
  searchParam,
  page,
  updatePage,
}) {
  const defaultFiltersConfig = {
    type: {
      customers: {
        value: false,
        label: 'Customer',
        data_value: 'Customer',
      },
      partners: {
        value: false,
        label: 'Partner',
        data_value: 'Partner',
      },
    },
  };

  const rowsPerPage = 10;

  const [tableData, setTableData] = useState([]);
  const [filtersConfig, setFiltersConfig] = useState(defaultFiltersConfig);
  const [noMatchDataAnyFilters, setNoMatchDataAnyFilters] = useState(false);

  const filterDataMapping = {
    type: {
      Customer: 'customers',
      Partner: 'partners',
    },
  };

  useEffect(() => {
    setFilteredTableData();
  }, [companiesData, searchParam]);

  const updateFilters = (columnKey, filterKey, value) => {
    if (!filtersConfig[columnKey] || !filtersConfig[columnKey][filterKey]) {
      return null;
    }
    filtersConfig[columnKey][filterKey].value = value;
    const updatedConfig = Object.assign({}, filtersConfig);
    setFiltersConfig(updatedConfig);
  };

  const applyFilters = () => {
    updatePage(0);
    setFilteredTableData();
  };

  const resetFilters = (columnKey) => {
    if (!filtersConfig[columnKey]) return;
    console.log(filtersConfig, 'reset');
    for (let filter of Object.values(filtersConfig[columnKey])) {
      filter.value = false;
    }
    updatePage(0);
    setFilteredTableData();
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  const setFilteredTableData = () => {
    if (!companiesData || !Array.isArray(companiesData)) {
      setTableData([]);
      return;
    }

    const enabledFilters = [];

    for (let configKey of Object.keys(filtersConfig)) {
      let filterEnabled = false;
      for (let filters of Object.values(filtersConfig[configKey])) {
        if (filters.value) {
          filterEnabled = true;
          break;
        }
      }
      if (filterEnabled) {
        enabledFilters.push(configKey);
      }
    }

    let searchRegex;
    if (searchParam && typeof searchParam === 'string') {
      searchRegex = new RegExp(`${searchParam.trim()}`, 'i');
    }

    const companies = [];
    for (let company of companiesData) {
      if (searchRegex) {
        const { companyname } = company;
        if (!companyname.match(searchRegex)) {
          continue;
        }
      }
      let matchAllFilters = true;
      for (let filterKey of enabledFilters) {
        const data_value = company[filterKey];
        const configKeyFromData = filterDataMapping[filterKey][data_value];
        const filterConfigInnerObj =
          filtersConfig[filterKey] &&
          filtersConfig[filterKey][configKeyFromData];
        if (!filterConfigInnerObj || !filterConfigInnerObj.value) {
          matchAllFilters = false;
          break;
        }
      }
      if (matchAllFilters && company.type !== 'self') {
        companies.push(company);
      }
    }
    /**
     * Length of companies data compares with 1 and not null because 'self' company will always be present and kicked out of the initial
     * list no matter which filters are used.
     */
    const noFiltersMatch = companiesData.length > 1 && !companies.length;
    setNoMatchDataAnyFilters(noFiltersMatch);
    setTableData(companies);
  };

  const handleAddressClick = (e) => {
    const { style } = e.target;
    style.whiteSpace = 'normal';
  };

  return (
    <Stack direction="column" justifyContent="space-between">
      <TableContainer component={Paper}>
        <Table
          aria-label="Table with admin companies"
          size="small"
          sx={{ tableLayout: 'fixed' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell widthcol={4}>Name</StyledTableCell>
              <StyledTableCell widthcol={8} align="center">
                Address
              </StyledTableCell>
              <StyledTableCell widthcol={7} align="center">
                Contact
              </StyledTableCell>
              <StyledTableCell widthcol={2} align="center">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  Type
                  <TableFilterBtn
                    config={filtersConfig['type']}
                    updateFilters={(key, value) =>
                      updateFilters('type', key, value)
                    }
                    onOk={applyFilters}
                    onReset={() => resetFilters('type')}
                    disabled={companiesFetching}
                  />
                </div>
              </StyledTableCell>
              <StyledTableCell widthcol={3} align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {(!companiesData.length || companiesFetching) && (
              <TableBodySkeleton
                columns={5}
                rows={rowsPerPage}
                shouldRenderActionsCell
                actionsComponent={
                  <Stack direction="row" justifyContent="end">
                    <EditCompanyBtn displayOnly />
                    <DeleteCompanyButton displayOnly />
                  </Stack>
                }
              />
            )}
            {!companiesFetching && (
              <>
                {noMatchDataAnyFilters && <TableEmptyDataDisplay columns={5} />}
                {!!tableData.length &&
                  tableData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((company, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell>{company.companyname}</StyledTableCell>
                        <LongTextTableCell
                          onClick={(e) => handleAddressClick(e)}
                          align="center"
                        >
                          {company.companyaddress}
                        </LongTextTableCell>
                        <StyledTableCell align="center">
                          {company.contact}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {company.type}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Stack direction="row" justifyContent="end">
                            <EditCompanyBtn
                              afterEdit={afterCompaniesUpdate}
                              company={company}
                            />
                            <DeleteCompanyButton
                              afterDelete={afterCompaniesUpdate}
                              companyId={company.companyid}
                              disabled={!company.canBeDeleted}
                              disabledReason={company.restrictDeleteReason}
                              companyName={company.companyname}
                            />
                          </Stack>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                <TableBodyEmptyRowsFiller rowsAmount={emptyRows}>
                  <DeleteCompanyButton
                    displayOnly
                    sx={{ visibility: 'hidden' }}
                  />
                </TableBodyEmptyRowsFiller>
              </>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={5}
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={null}
                SelectProps={{ style: { display: 'none' } }}
                page={page}
                onPageChange={updatePage}
                rowsPerPageOptions={[rowsPerPage]}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={({ from, to, count }) =>
                  tableData.length ? `${from}–${to} of ${count}` : ''
                }
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Stack>
  );
}
