import { UNMOUNT_IGNORED_ERR_MSG } from 'Constants';
import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

import './styles.css';

import { Box, Stack } from '@mui/material';

import samplePoints from 'Api/samplePoints';

import DownloadButton from 'Components/Buttons/DownloadButton';

import { useIsMounted } from 'Context';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  getLabelsDimensions,
  getLabelsInnerContainerConfig,
  isValidResponse,
} from 'Utils';

const SingleSPLabel = React.memo(
  ({
    logo,
    projectId,
    projectName,
    spNumber,
    surveyDate,
    testNumber,
    scenarioText,
    width = 288,
    height = 192,
    position,
  }) => {
    const warningText = `Do not remove`;
    const scenarioTextThreshold = 80;

    return (
      <Stack
        className="spLabel noselect"
        style={{
          width: `${width}px`,
          height: `${height}px`,
          position: 'absolute',
          top: position?.top,
          left: position?.left,
        }}
        direction="column"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box className="spLabel-logo">{logo}</Box>
          <Box className="spLabel-spNumber">{spNumber}</Box>
        </Stack>
        <div className="spLabel-divider" />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack className="spLabel-secondRow--details" direction="column">
            <Box>Test: {testNumber}</Box>
            <Box>Scenario:</Box>
            <Box
              className={`spLabel-secondRow--details---samples ${
                scenarioText.length > scenarioTextThreshold
                  ? 'spLabel-secondRow--details---samples--small'
                  : ''
              }`}
            >
              {scenarioText}
            </Box>
          </Stack>
          <Box className="spLabel-secondRow--warning">{warningText}</Box>
        </Stack>
        <div className="spLabel-divider" />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box className="spLabel-thirdRow--projectInfo">
            {projectId}: {projectName}
          </Box>
          <Box className="spLabel-thirdRow--date">{surveyDate}</Box>
        </Stack>
      </Stack>
    );
  },
);

const SPLabelsForPrint = React.forwardRef(
  (
    { data, projectId, projectName, paperWidth = 816, paperHeight = 1056 },
    ref,
  ) => {
    const paperPadding = {
      top: 24,
      left: 114,
      bottom: 24,
      right: 114,
    };
    const labelsSpacing = {
      vertical: 12,
      horizontal: 12,
    };
    const labelWidth = 288;
    const labelHeight = 192;

    const LogoImg = useMemo(() => {
      return <img src="/SafeTraces_Logo.png" alt="logo"></img>;
    }, []);

    const labelsDimensionsConfig = useMemo(
      () =>
        getLabelsDimensions(
          paperWidth,
          paperHeight,
          paperPadding,
          labelsSpacing,
          data?.length,
          labelWidth,
          labelHeight,
        ),
      [paperWidth, paperHeight],
    );

    const innerContainerConfig = useMemo(
      () =>
        getLabelsInnerContainerConfig(
          paperWidth,
          paperPadding,
          labelWidth,
          labelsSpacing,
        ),
      [paperWidth, paperHeight],
    );

    return (
      <div
        style={{
          width: paperWidth,
          height: paperHeight,
          padding: paperPadding,
        }}
        ref={ref}
      >
        <div
          className="spLabels-print-container"
          style={{
            left: innerContainerConfig?.left,
            width: innerContainerConfig?.width,
            top: innerContainerConfig?.top,
          }}
        >
          {data.map(({ spNumber, surveyDate, testNumber, scenarioText }, i) => (
            <SingleSPLabel
              key={i}
              logo={LogoImg}
              projectName={projectName}
              projectId={projectId}
              spNumber={spNumber}
              surveyDate={surveyDate}
              testNumber={testNumber}
              scenarioText={scenarioText}
              width={labelWidth}
              height={labelHeight}
              position={labelsDimensionsConfig[i]}
            />
          ))}
        </div>
      </div>
    );
  },
);

function SamplePointsLabelsPrint({ projectId, projectName, hasSP, onError }) {
  const mounted = useIsMounted();

  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [labelsData, setLabelsData] = useState(null);

  const labelsRef = useRef();

  useEffect(() => {
    const source = axios.CancelToken.source();

    getLabels(source);

    return () => {
      source.cancel(UNMOUNT_IGNORED_ERR_MSG);
    };
  }, [projectId]);

  const getLabels = async (source) => {
    if (!projectId) return;

    setDownloadInProgress(true);

    try {
      const response = await samplePoints.getLabelsForProject(
        projectId,
        source,
      );

      if (isValidResponse(response)) {
        const labelsData = getDataFromResponse(response);

        const labels = formatLabelsData(labelsData);

        if (mounted.current) {
          setLabelsData(labels);
          setDownloadInProgress(false);
        }
      } else {
        throw new Error(getErrorMessageFromResponse(response));
      }
    } catch (err) {
      if (err.message === UNMOUNT_IGNORED_ERR_MSG) return;

      console.error(`getLabels Error: `, err);
      if (mounted.current) {
        setDownloadInProgress(false);
        setLabelsData(null);
      }
      if (typeof onError === 'function') {
        onError(err);
      }
    }
  };

  const formatLabelsData = (labels) => {
    if (!Array.isArray(labels) || !labels.length) return [];

    const formattedLabels = labels.map((label) => {
      const { samples } = label;
      let scenarioTextForSamples = ``;

      if (Array.isArray(samples) && samples.length) {
        let scenarioNum = 1;
        const res = [];

        while (samples.length) {
          res.push(`${scenarioNum}:${samples.splice(0, 4).join(',')}`);
          scenarioNum++;
        }

        scenarioTextForSamples = res.join(',');
      }

      const _label = {
        spNumber: label.spnumber,
        surveyDate: label.surveydate,
        testNumber: label.testNumber,
        scenarioText: scenarioTextForSamples,
      };

      return _label;
    });

    return formattedLabels;
  };

  const handlePrint = useReactToPrint({
    content: () => labelsRef.current,
    documentTitle: `${projectName}-SP-Labels`,
  });

  const PrintButton = React.useMemo(() => {
    return (
      <DownloadButton
        text="Sample Points"
        disabled={!hasSP || downloadInProgress || !labelsData}
        loading={downloadInProgress}
        tooltipText={
          !hasSP
            ? `The project does not have sample points`
            : !labelsData
            ? `Data for the labels is loading`
            : ''
        }
        icon="print"
        clickAction={handlePrint}
        sx={{ width: '180px' }}
      />
    );
  }, [hasSP, downloadInProgress, labelsData]);

  return (
    <Stack direction="column">
      {PrintButton}
      {!!labelsData && (
        <div style={{ display: 'none' }}>
          <SPLabelsForPrint
            ref={labelsRef}
            data={labelsData}
            projectId={projectId}
            projectName={projectName}
            paperWidth={816}
            paperHeight={1056}
          />
        </div>
      )}
    </Stack>
  );
}

export default React.memo(SamplePointsLabelsPrint);
