import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import authentication from '../../Api/authentication';
import MainAlert from '../../Components/Mix/MainAlert';
import Title from '../../Components/Mix/Title';
import ErrorMessage from '../../Components/UI/ErrorMessage';
import Loading from '../../Components/UI/Loading';
import { getUserData, isValidResponse } from '../../Utils';

import { Box, Button, TextField } from '@mui/material';

export default function ChangePassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [mainError, setMainError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const {
    register,
    handleSubmit,
    watch,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      setSuccessMessage('');
      let sendObj = {
        email: getUserData('email'),
        password: data.current_password,
        newPassword: data.new_password,
      };
      const response = await authentication.updateUserPassword(sendObj);
      if (!isValidResponse(response)) {
        setError('current_password', {
          type: 'custom',
          message: response.data?.error?.message,
        });
      } else {
        setSuccessMessage('Password has been successfully changed.');
        reset();
      }
    } catch (e) {
      setMainError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseErrorAlert = () => {
    setMainError(null);
  };

  return (
    <>
      <Title text={'Change password'} />
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={handleCloseErrorAlert}
        />
      )}
      {!!isLoading && <Loading />}
      {!!successMessage && <MainAlert message={successMessage} />}
      <Box sx={{ width: 400, ml: 3, mt: 3 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            type="password"
            label="Current Password"
            name="current_password"
            id="current_password"
            {...register('current_password', {
              required: 'Password field required',
              minLength: {
                value: 4,
                message: 'Password must have at least 4 characters',
              },
            })}
            error={!!(errors && errors.current_password)}
            helperText={errors?.current_password?.message}
            disabled={isLoading}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            type="password"
            label="New Password"
            name="new_password"
            id="new_password"
            {...register('new_password', {
              required: 'New Password field required',
              minLength: {
                value: 4,
                message: 'Password must have at least 4 characters',
              },
            })}
            error={!!(errors && errors.new_password)}
            helperText={errors?.new_password?.message}
            disabled={isLoading}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            type="password"
            label="Confirm Password"
            name="confirm_password"
            id="confirm_password"
            {...register('confirm_password', {
              validate: (value) =>
                value === watch('new_password') || "Passwords don't match.",
            })}
            error={!!(errors && errors.confirm_password)}
            helperText={errors?.confirm_password?.message}
            disabled={isLoading}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isLoading}
            sx={{ mt: 3 }}
          >
            Save
          </Button>
        </form>
      </Box>
    </>
  );
}
