import React from 'react';

import { Grid, Paper, Typography } from '@mui/material';

import { useTestPackageItems } from 'Components/TestPackageItem/context';
import { renderIcons } from 'Components/TestPackages/utils';

const TestPackageSummary = () => {
  const { availableTests } = useTestPackageItems();

  return (
    <Grid item xs={12} style={{ paddingLeft: 0 }}>
      <Paper
        elevation={3}
        sx={{
          padding: 1,
          backgroundColor: '#f2f2f2',
          border: '1px solid transparent',
        }}
      >
        <Grid container>
          <Grid item md={3} xs={12}>
            <Typography variant="h5">
              {availableTests.total <= 0
                ? 'No tests available for this site contract'
                : 'Available Tests'}
            </Typography>
            {availableTests.total > 0 && (
              <Typography>
                Total Tests: {availableTests.total >= 0 && availableTests.total}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={5} lg={6}>
            {availableTests.total >= 0 &&
              renderIcons(availableTests, 'row', 'warning', '#ed6c02')}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default TestPackageSummary;
