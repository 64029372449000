import React, { useRef, useState } from 'react';

import DetailsForm from '../Forms/DetailsForm';
import '../styles.css';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
} from '@mui/material';

import { useIsMounted } from 'Context';

const AddEditBuildingModal = ({
  open,
  type = 'add',
  handleClose,
  handleSubmit,
  transitionDuration = 220,
  data = null,
}) => {
  const mounted = useIsMounted();
  const formRef = useRef(null);
  const [fullFormMode, setFullFormMode] = useState(true);

  const headerOptions = {
    add: `Add building`,
    edit: `Edit building`,
  };
  const okBtnText = {
    add: 'Add',
    edit: 'Update',
  };

  const [actionsDisabled, setActionsDisabled] = useState(false);

  const onSubmit = async (values) => {
    try {
      setActionsDisabled(true);

      await handleSubmit(values);
    } catch (err) {
      console.log(type, 'Error: ', err);
    } finally {
      if (mounted.current) {
        setActionsDisabled(false);
      }
    }
  };

  if (!~['add', 'edit'].indexOf(type)) return null;

  return (
    <Dialog
      open={open}
      transitionDuration={transitionDuration}
      disableEscapeKeyDown
      PaperProps={{
        sx: {
          p: 1,
          pb: 2,
          justifyContent: 'center',
          alignItems: 'center',
          width: '420px',
          height: '420px',
        },
      }}
    >
      <DialogTitle>{headerOptions[type] || ''}</DialogTitle>
      <DialogContent
        sx={{
          width: '100%',
          mb: 2,
        }}
      >
        <Box sx={{ width: '100%', height: '100%' }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) => setFullFormMode(e.target.checked)}
                  defaultChecked
                />
              }
              label="Full details"
            />
          </FormGroup>
          <DetailsForm
            ref={formRef}
            onSubmit={onSubmit}
            defaultValues={data || {}}
            showLabels={false}
            simpleMode={!fullFormMode}
            fullWidth={true}
          />
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between', width: '70%' }}>
        <Button
          style={{ width: '100%', marginRight: '8px' }}
          variant="outlined"
          onClick={handleClose}
          disabled={actionsDisabled}
        >
          Cancel
        </Button>
        <Button
          style={{ width: '100%' }}
          variant="contained"
          disabled={actionsDisabled}
          onClick={() => formRef.current?.requestSubmit()}
          endIcon={
            actionsDisabled ? (
              <CircularProgress color="inherit" size={'1rem'} />
            ) : null
          }
        >
          {okBtnText[type]}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditBuildingModal;
