export const areaSectionConfig = {
  dilutionLarge: {
    header: 'Test area',
    alertText: 'Test settings are not available without test area information',
    form: {
      restrict: {
        range_validator: {
          sq_ft: (val) =>
            (Number(val) &&
              !!(parseInt(val, 10) && val > 4000 && val <= 8000)) ||
            (!!Number(val)
              ? `Large Dilution expects test area to be greater than 4000 sq.ft and less than 8000 sq.ft`
              : `Value must be a number`),

          celling_height: (val) => Number(val) || `Value must be a number`,
        },
        on_validation_fail: {
          sq_ft: {
            textReject: `Large Dilution expects test area to be greater than 4000 sq.ft and less than 8000 sq.ft`,
          },
        },
      },
    },
  },
  dilutionSmall: {
    header: 'Test area',
    alertText: 'Test settings are not available without test area information',
    form: {
      restrict: {
        range_validator: {
          sq_ft: (val) =>
            (Number(val) && !!(parseInt(val, 10) && val > 0 && val <= 4000)) ||
            (Number(val)
              ? `Small Dilution expects test area to be less than 4000 sq.ft`
              : `Value must be a number`),
          celling_height: (val) => !!parseInt(val, 10),
        },
        on_validation_fail: {
          sq_ft: {
            textReject: `Small Dilution expects test area to be less than 4000 sq.ft`,
          },
        },
      },
    },
  },
};
