import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Modal, Paper } from '@mui/material';

import EditBorderButton from 'Components/Buttons/EditBorderButton';
import { useProjectContext } from 'Components/SingleProject/context';
import EditSurveyDate from 'Components/SingleTest/Modals/TestEditModals/EditSurveyDate';

import { projectDataSelector } from 'Features/Projects/projectsSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

export default function EditProjectSurveyDateBtn() {
  const [modalOpen, setModalOpen] = useState(false);
  const { fetchProjectTests } = useProjectContext();
  const { projectTests, isProjectTestsLoading, projectId } =
    useSelector(projectDataSelector);
  const [buttonTitle, setButtonTitle] = useState('');
  const [earliestDate, setEarliestDate] = useState('');

  useEffect(() => {
    const allTestsHaveDate = projectTests.every((t) => !!t.surveyDate);
    const testsWithDates = projectTests.filter((t) => !!t.surveyDate);

    const _earliestDate = !testsWithDates.length
      ? moment()
      : testsWithDates.reduce((acc, t) => {
          return moment(t.surveyDate).isBefore(acc)
            ? moment(t.surveyDate)
            : acc;
        }, moment(testsWithDates[0].surveyDate));

    setEarliestDate(_earliestDate.utc().format('YYYY-MM-DD'));
    setButtonTitle(
      allTestsHaveDate
        ? `Edit survey date (${_earliestDate.utc().format('MM-DD-YYYY')})`
        : 'Set survey date',
    );
  }, [projectTests]);

  return (
    <React.Fragment>
      <EditBorderButton
        disableStyles={true}
        text={buttonTitle}
        clickAction={() => setModalOpen(true)}
        extraBtnProps={{
          disableFocusRipple: true,
          disabled: isProjectTestsLoading,
        }}
      />
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <Paper sx={style} variant="outlined">
          <EditSurveyDate
            projectId={projectId}
            type="project"
            reFetchProject={fetchProjectTests}
            cancel={() => setModalOpen(false)}
            value={earliestDate}
          />
        </Paper>
      </Modal>
    </React.Fragment>
  );
}
