import { HEALTHCARE_TEST_TYPES } from 'Constants';
import React from 'react';

import {
  ACCESS_LEVEL_ADMIN,
  ACCESS_LEVEL_EDITOR,
  ROLE_CUSTOMER,
  ROLE_PARTNER,
  ROLE_SAFETRACES,
} from 'Config/roles';

const editPermission = [
  ACCESS_LEVEL_ADMIN + ROLE_SAFETRACES,
  ACCESS_LEVEL_EDITOR + ROLE_SAFETRACES,
  ACCESS_LEVEL_ADMIN + ROLE_CUSTOMER,
  ACCESS_LEVEL_EDITOR + ROLE_CUSTOMER,
  ACCESS_LEVEL_ADMIN + ROLE_PARTNER,
  ACCESS_LEVEL_EDITOR + ROLE_PARTNER,
];

const editFullPermission = [ACCESS_LEVEL_ADMIN + ROLE_SAFETRACES];

// This is the config for the table in the Scenarios page
// TODO: clean this when we have the final data model
export const SCENARIOS_HEALTHCARE_DATA_CONFIG_TABLE = {
  displayName: {
    key: 'displayName',
    label: 'Name',
    align: 'left',
    editable: true,
    editPermission: editPermission,
    dataOrigin: 'testMetadata',
    presentInCollapsibleTable: true,
    valueAsNumber: false,
    requiredInForm: false,
    presentInRestrictedForm: true,
  },
  description: {
    key: 'description',
    label: 'Description',
    align: 'left',
    editable: true,
    editPermission: editPermission,
    presentInCollapsibleTable: true,
    valueAsNumber: false,
    requiredInForm: false,
    presentInRestrictedForm: true,
  },
  testDate: {
    key: 'testDate',
    label: 'Test Date',
    align: 'left',
    editable: true,
    dataOrigin: 'testMetadata',
    isDate: true,
    dateType: 'date',
    editPermission: editFullPermission,
    presentInCollapsibleTable: false,
    valueAsNumber: false,
    requiredInForm: false,
    specialValidator: (value) => {
      const dateRegExp = /^\d{2}-\d{2}-\d{4}$/;
      const isValid = dateRegExp.test(value);
      return isValid || 'Date must be in format MM-DD-YYYY';
    },
  },
  testStartTime: {
    key: 'testStartTime',
    label: 'Test Start Time',
    align: 'left',
    dataOrigin: 'testMetadata',
    editPermission: editPermission,
    editable: true,
    isDate: true,
    dateType: 'time',
    presentInCollapsibleTable: false,
    valueAsNumber: false,
    requiredInForm: false,
    specialValidator: (value) => {
      const timeRegExp = /^(0?[1-9]|1[0-2])(:[0-5]\d)?(\s?[AP]M)$/i;
      const isValid = timeRegExp.test(value);

      return isValid || 'Time must be in format HH:MM AM/PM';
    },
  },
  opCount: {
    key: 'opCount',
    label: 'OP count',
    align: 'left',
    editable: false,
    presentInCollapsibleTable: false,
    valueAsNumber: true,
    requiredInForm: false,
  },
  op001Tag: {
    key: 'op001Tag',
    opKeyInNormalizedData: `OP-001`,
    label: {
      [HEALTHCARE_TEST_TYPES.POSITIVE]: 'OP-001 Tag',
      [HEALTHCARE_TEST_TYPES.NEGATIVE]: 'OP Tag',
      [HEALTHCARE_TEST_TYPES.NEUTRAL]: 'OP Tag',
    },
    align: 'left',
    editPermission: editPermission,
    editable: true,
    presentInCollapsibleTable: true,
    valueAsNumber: false,
    requiredInForm: false,
    specialValidator: (value) => {
      const allowedTags = [
        'Tag-C1',
        'Tag-C2',
        'Tag-C3',
        'Tag-C4',
        'Tag-C5',
        'Tag-C6',
        'Tag-C7',
        'Tag-C8',
      ];
      const isValid = allowedTags.includes(value);

      return isValid || 'Tag is not allowed, choose one of the C plates tags';
    },
  },
  op002Tag: {
    key: 'op002Tag',
    opKeyInNormalizedData: `OP-002`,
    label: 'OP-002 Tag',
    align: 'left',
    editPermission: editPermission,
    editable: true,
    presentInCollapsibleTable: true,
    valueAsNumber: false,
    requiredInForm: false,
    allowedTestTypes: [HEALTHCARE_TEST_TYPES.POSITIVE],
    specialValidator: (value) => {
      const allowedTags = [
        'Tag-B1',
        'Tag-B2',
        'Tag-B3',
        'Tag-B4',
        'Tag-B5',
        'Tag-B6',
        'Tag-B7',
        'Tag-B8',
      ];
      const isValid = allowedTags.includes(value);

      return isValid || 'Tag is not allowed, choose one of the B plates tags';
    },
  },
  sp001_sampleNumbers: {
    key: 'sp001_sampleNumbers',
    spKeyInNormalizedData: `SP-001`,
    label: 'SP-001 Sample Numbers',
    align: 'left',
    editable: false,
    presentInCollapsibleTable: true,
    valueAsNumber: false,
    requiredInForm: false,
  },
  sp002_sampleNumbers: {
    key: 'sp002_sampleNumbers',
    spKeyInNormalizedData: `SP-002`,
    label: 'SP-002',
    align: 'left',
    editable: false,
    presentInCollapsibleTable: true,
    valueAsNumber: false,
    requiredInForm: false,
  },
  sp003_sampleNumbers: {
    key: 'sp003_sampleNumbers',
    spKeyInNormalizedData: `SP-003`,
    label: 'SP-003',
    align: 'left',
    editable: false,
    presentInCollapsibleTable: true,
    valueAsNumber: false,
    requiredInForm: false,
  },
  sp004_sampleNumbers: {
    key: 'sp004_sampleNumbers',
    spKeyInNormalizedData: `SP-004`,
    label: 'SP-004',
    align: 'left',
    editable: false,
    presentInCollapsibleTable: true,
    valueAsNumber: false,
    requiredInForm: false,
  },
  sp005_sampleNumbers: {
    key: 'sp005_sampleNumbers',
    spKeyInNormalizedData: `SP-005`,
    label: 'SP-005',
    align: 'left',
    editable: false,
    presentInCollapsibleTable: true,
    valueAsNumber: false,
    requiredInForm: false,
  },
  // eachUserInput: {
  //   key: 'ACH',
  //   label: (
  //     <React.Fragment>
  //       <span style={{ fontSize: '0.8em', fontVariant: 'none' }}>e</span>
  //       <span>ACH</span>
  //     </React.Fragment>
  //   ), // 'eACH user input',
  //   align: 'left',
  //   editPermission: editPermission,
  //   editable: true,
  //   dataOrigin: 'testMetadata',
  //   presentInCollapsibleTable: true,
  //   valueAsNumber: true,
  //   requiredInForm: false,
  // },
  hvac_ach: {
    key: 'HVAC_ACH',
    label: 'HVAC ACH',
    align: 'left',
    editPermission: editPermission,
    editable: true,
    dataOrigin: 'testMetadata',
    presentInCollapsibleTable: true,
    valueAsNumber: true,
    requiredInForm: false,
  },
  pec_ach: {
    key: 'PEC_ACH',
    label: 'PEC ACH',
    align: 'left',
    editPermission: editPermission,
    editable: true,
    dataOrigin: 'testMetadata',
    presentInCollapsibleTable: true,
    valueAsNumber: true,
    requiredInForm: false,
  },
  selectorKnobAchSetting: {
    key: 'selectorKnobAchSetting',
    label: 'Selector Knob ACH Setting',
    align: 'left',
    editable: false,
    dataOrigin: 'testMetadata',
    presentInCollapsibleTable: true,
    valueAsNumber: false,
    requiredInForm: false,
  },
  testDurationSelectorNob: {
    key: 'testDurationSelectorNob',
    label: 'Test Duration (min)',
    align: 'left',
    editable: false,
    dataOrigin: 'testMetadata',
    presentInCollapsibleTable: true,
    valueAsNumber: true,
    requiredInForm: false,
  },
  achPassFail: {
    key: 'achPassFail',
    label: 'ACH pass/fail',
    align: 'left',
    editPermission: editPermission,
    editable: true,
    dataOrigin: 'testMetadata',
    presentInCollapsibleTable: false,
    valueAsNumber: true,
    requiredInForm: false,
  },
  leakagePassFail: {
    key: 'leakagePassFail',
    label: 'Leakage pass/fail',
    align: 'left',
    editPermission: editPermission,
    editable: true,
    dataOrigin: 'testMetadata',
    presentInCollapsibleTable: false,
    allowedTestTypes: [HEALTHCARE_TEST_TYPES.NEGATIVE],
    valueAsNumber: true,
    requiredInForm: false,
  },
  infiltration: {
    key: 'infiltration',
    label: 'Infiltration pass/fail',
    align: 'left',
    editPermission: editPermission,
    editable: true,
    dataOrigin: 'testMetadata',
    presentInCollapsibleTable: false,
    allowedTestTypes: [HEALTHCARE_TEST_TYPES.POSITIVE],
    valueAsNumber: true,
    requiredInForm: false,
  },
  deltaPsp002vsp001: {
    key: 'deltaPsp002vsp001',
    label: (
      <React.Fragment>
        <span>D</span>
        <span style={{ fontSize: '0.8em', fontVariant: 'none' }}>elta</span>
        <span>P SP-002</span>
        <span style={{ fontSize: '0.8em', fontVariant: 'none' }}>{' vs '}</span>
        <span>SP-001</span>
      </React.Fragment>
    ),
    align: 'left',
    editPermission: editPermission,
    editable: true,
    dataOrigin: 'testMetadata',
    presentInCollapsibleTable: false,
    valueAsNumber: true,
    requiredInForm: false,
  },
  deltaPsp003vsp001: {
    key: 'deltaPsp003vsp001',
    label: (
      <React.Fragment>
        <span>D</span>
        <span style={{ fontSize: '0.8em', fontVariant: 'none' }}>elta</span>
        <span>P SP-003</span>
        <span style={{ fontSize: '0.8em', fontVariant: 'none' }}>{' vs '}</span>
        <span>SP-001</span>
      </React.Fragment>
    ),
    align: 'left',
    editPermission: editPermission,
    editable: true,
    dataOrigin: 'testMetadata',
    presentInCollapsibleTable: false,
    valueAsNumber: true,
    requiredInForm: false,
  },
  deltaPsp004vsp001: {
    key: 'deltaPsp004vsp001',
    label: (
      <React.Fragment>
        <span>D</span>
        <span style={{ fontSize: '0.8em', fontVariant: 'none' }}>elta</span>
        <span>P SP-004</span>
        <span style={{ fontSize: '0.8em', fontVariant: 'none' }}>{' vs '}</span>
        <span>SP-001</span>
      </React.Fragment>
    ),
    align: 'left',
    editPermission: editPermission,
    editable: true,
    dataOrigin: 'testMetadata',
    presentInCollapsibleTable: false,
    valueAsNumber: true,
    requiredInForm: false,
  },
  deltaPsp005vsp001: {
    key: 'deltaPsp005vsp001',
    label: (
      <React.Fragment>
        <span>D</span>
        <span style={{ fontSize: '0.8em', fontVariant: 'none' }}>elta</span>
        <span>P SP-005</span>
        <span style={{ fontSize: '0.8em', fontVariant: 'none' }}>{' vs '}</span>
        <span>SP-001</span>
      </React.Fragment>
    ),
    align: 'left',
    editPermission: editPermission,
    editable: true,
    dataOrigin: 'testMetadata',
    presentInCollapsibleTable: false,
    valueAsNumber: true,
    requiredInForm: false,
  },
  scenarioexecuted: {
    key: 'scenarioExecuted',
    label: 'Executed',
    align: 'left',
    editPermission: editPermission,
    editable: false,
    presentInCollapsibleTable: true,
    valueAsNumber: false,
  },
};
