import { UNMOUNT_IGNORED_ERR_MSG } from 'Constants';
import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

import './styles.css';

import { Box, Stack } from '@mui/material';

import samplePlans from 'Api/samplePlans';

import DownloadButton from 'Components/Buttons/DownloadButton';

import { useIsMounted } from 'Context';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  getLabelsDimensions,
  getLabelsInnerContainerConfig,
  isValidResponse,
} from 'Utils';

const projectNameTextThreshold = 25;
const projectNameMaxTextThreshold = 50;

const SingleSampleLabel = React.memo(
  ({
    projectId,
    projectName,
    spNumber,
    sampleNumber,
    sampleType,
    surveyDate,
    width = 288,
    height = 192,
    position,
  }) => {
    return (
      <Stack
        className="sampleLabel noselect"
        style={{
          width: `${width}px`,
          height: `${height}px`,
          position: 'absolute',
          top: position?.top,
          left: position?.left,
        }}
        direction="column"
      >
        <Box className="sampleLabel-title">{sampleNumber}</Box>
        <Box className="sampleLabel-row--regular">{spNumber}</Box>
        <Box
          className={`sampleLabel-row--regular ${
            projectName.length > projectNameTextThreshold
              ? 'sampleLabel-row--small'
              : ''
          }`}
        >
          {sampleType}
        </Box>
        <div
          className={`sampleLabel-divider  ${
            projectName.length > projectNameTextThreshold
              ? 'sampleLabel-divider--small'
              : ''
          }`}
        />
        <Box
          className={`sampleLabel-row--primary ${
            projectName.length > projectNameTextThreshold
              ? 'sampleLabel-row--primary--small'
              : ''
          }`}
        >
          {projectId}-
          {projectName
            .split(' ')
            .splice(0, 9)
            .join(' ')
            .substring(0, projectNameMaxTextThreshold)}
          {projectName.length > projectNameMaxTextThreshold ? '...' : ''}
        </Box>
        <div
          className={`sampleLabel-divider  ${
            projectName.length > projectNameTextThreshold
              ? 'sampleLabel-divider--small'
              : ''
          }`}
        />
        <Box
          className={`sampleLabel-row--regular ${
            projectName.length > projectNameTextThreshold
              ? 'sampleLabel-row--small'
              : ''
          }`}
        >
          {surveyDate || ``}
        </Box>
      </Stack>
    );
  },
);

const SamplesLabelsForPrint = React.forwardRef(
  (
    { data, projectId, projectName, paperWidth = 816, paperHeight = 1056 },
    ref,
  ) => {
    const paperPadding = {
      top: 48,
      left: 38.65,
      bottom: 48,
      right: 38.65,
    };
    const labelsSpacing = {
      vertical: 0,
      horizontal: 11.3,
    };
    const labelWidth = 72;
    const labelHeight = 96;

    const labelsDimensionsConfig = useMemo(
      () =>
        getLabelsDimensions(
          paperWidth,
          paperHeight,
          paperPadding,
          labelsSpacing,
          data?.length,
          labelWidth,
          labelHeight,
        ),
      [paperWidth, paperHeight],
    );

    const innerContainerConfig = useMemo(
      () =>
        getLabelsInnerContainerConfig(
          paperWidth,
          paperPadding,
          labelWidth,
          labelsSpacing,
        ),
      [paperWidth, paperHeight],
    );

    return (
      <div
        style={{
          width: paperWidth,
          height: paperHeight,
          padding: paperPadding,
        }}
        ref={ref}
      >
        <div
          className="samplesLabels-print-container"
          style={{
            left: innerContainerConfig?.left,
            width: innerContainerConfig?.width,
            top: innerContainerConfig?.top,
          }}
        >
          {data.map(({ spNumber, sampleNumber, surveyDate, sampleType }, i) => (
            <SingleSampleLabel
              key={i}
              projectName={projectName}
              projectId={projectId}
              spNumber={spNumber}
              sampleNumber={sampleNumber}
              sampleType={sampleType}
              surveyDate={surveyDate}
              width={labelWidth}
              height={labelHeight}
              position={labelsDimensionsConfig[i]}
            />
          ))}
        </div>
      </div>
    );
  },
);

function SamplesLabelsPrint({ projectId, projectName, hasSamples, onError }) {
  const mounted = useIsMounted();

  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [labelsData, setLabelsData] = useState(null);

  const labelsRef = useRef();

  useEffect(() => {
    const source = axios.CancelToken.source();

    getLabels(source);

    return () => {
      source.cancel(UNMOUNT_IGNORED_ERR_MSG);
    };
  }, [projectId]);

  const getLabels = async (source) => {
    if (!projectId) return;

    setDownloadInProgress(true);

    try {
      const response = await samplePlans.getLabelsForProject(projectId, source);

      if (isValidResponse(response)) {
        const labelsData = getDataFromResponse(response);

        const labels = formatLabelsData(labelsData);

        if (mounted.current) {
          setLabelsData(labels);
          setDownloadInProgress(false);
        }
      } else {
        throw new Error(getErrorMessageFromResponse(response));
      }
    } catch (err) {
      if (err.message === UNMOUNT_IGNORED_ERR_MSG) return;

      console.error(`getLabels Error: `, err);
      if (mounted.current) {
        setDownloadInProgress(false);
        setLabelsData(null);
      }
      if (typeof onError === 'function') {
        console.log(err, 'err');
        onError(err);
      }
    }
  };

  const formatLabelsData = (labels) => {
    if (!Array.isArray(labels) || !labels.length) return [];

    return labels.map((label) => {
      return {
        spNumber: label.SPNumber,
        sampleNumber: label.sampleNumber,
        sampleType: label.sampleType,
        surveyDate: label.surveydate,
      };
    });
  };

  const handlePrint = useReactToPrint({
    content: () => labelsRef.current,
    documentTitle: `${projectName}-Samples-Labels`,
  });

  const PrintButton = React.useMemo(() => {
    return (
      <DownloadButton
        text="Samples"
        disabled={!hasSamples || downloadInProgress || !labelsData}
        loading={downloadInProgress}
        tooltipText={
          !hasSamples
            ? `The project does not have samples`
            : !labelsData
            ? `Data for the labels is loading`
            : ''
        }
        icon="print"
        clickAction={handlePrint}
        sx={{ width: '140px' }}
      />
    );
  }, [hasSamples, downloadInProgress, labelsData]);

  return (
    <Stack direction="column">
      {PrintButton}
      {!!labelsData && (
        <div style={{ display: 'none' }}>
          <SamplesLabelsForPrint
            ref={labelsRef}
            data={labelsData}
            projectId={projectId}
            projectName={projectName}
            paperWidth={816}
            paperHeight={1056}
          />
        </div>
      )}
    </Stack>
  );
}

export default React.memo(SamplesLabelsPrint);
