import React from 'react';

import { SvgIcon } from '@mui/material';

const SampleContractSheetIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <rect
        className="cls-1"
        x="7.2458234"
        y="6.7967186"
        width="5.8260546"
        height="0.75331825"
        id="rect6"
      />
      <rect
        className="cls-1"
        x="7.2458234"
        y="9.5830097"
        width="10.553038"
        height="0.75331825"
        id="rect8"
      />
      <rect
        className="cls-1"
        x="7.2458234"
        y="12.37259"
        width="10.553038"
        height="0.75331825"
        id="rect10"
      />
      <rect
        className="cls-1"
        x="7.2458234"
        y="15.158882"
        width="10.553038"
        height="0.75331825"
        id="rect12"
      />
      <rect
        className="cls-1"
        x="7.2458234"
        y="17.948462"
        width="6.2669806"
        height="0.75331825"
        id="rect14"
      />
      <path
        className="cls-1"
        d="M 20.444417,5.7835221 17.938272,3.2406618 16.703032,1.9873243 H 3.9064599 V 22.761146 H 21.682899 V 7.0368596 L 20.447658,5.7835221 Z m 0,15.7209959 H 5.1417003 V 3.2406618 H 15.681771 v 4.9475577 h 4.762646 z"
        id="path16"
      />
    </SvgIcon>
  );
};

export default SampleContractSheetIcon;
