import React from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import { Button, CircularProgress, Tooltip } from '@mui/material';

export default function DownloadButton({
  clickAction,
  sx = {},
  disabled = false,
  tooltipText = '',
  text = 'Download',
  loading = false,
  variant = 'contained',
  size = 'small',
  extraBtnProps = {},
  icon = 'download',
}) {
  const ButtonIcon = React.useMemo(() => {
    if (icon === 'print') {
      return <PrintIcon />;
    }
    return <DownloadIcon />;
  }, [icon]);

  return (
    <Tooltip title={tooltipText} placement="top">
      <span>
        <Button
          disabled={disabled}
          variant={variant}
          size={size}
          sx={{ ...sx }}
          onClick={clickAction}
          startIcon={
            loading ? (
              <CircularProgress color="inherit" size={'1rem'} />
            ) : (
              ButtonIcon
            )
          }
          {...extraBtnProps}
        >
          {text}
        </Button>
      </span>
    </Tooltip>
  );
}
