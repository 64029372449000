import { scenarios } from 'Api';
import {
  HEALTHCARE_TEST_TYPES,
  PROJECT_STATUSES,
  PROJECT_STATUS_WORKFLOW,
  TAGS_LIST,
} from 'Constants';
import React, { useEffect, useState } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import {
  Checkbox,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableHead,
  Typography,
} from '@mui/material';

import DeleteDialog from 'Components/Dialogs/DeleteDialog';
import { useProjectContext } from 'Components/SingleProject/context';
import ErrorMessage from 'Components/UI/ErrorMessage';

import { useIsMounted } from 'Context';

import {
  StyledTableCell,
  StyledTableRow,
  StyledTestTableCell,
} from 'Layout/layoutStyles';

import { getErrorMessageFromResponse, isValidResponse } from 'Utils';

export default function ScenariosTable({
  test,
  editable,
  projectStatus,
  disableActions,
}) {
  const mounted = useIsMounted();

  const [tagsInTest, setTagsInTest] = useState([]);
  const [multipleTagPerScenario, setMultipleTagPerScenario] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [resetTagModalOpen, setResetTagModalOpen] = useState(false);
  const [resetTagScenarioId, setResetTagScenarioId] = useState(null);
  const [resetScenarioTagInProgress, setResetScenarioTagInProgress] =
    useState(false);
  const [
    scenarioExecutionChangeInProgress,
    setScenarioExecutionChangeInProgress,
  ] = useState(false);
  const { openEditModal, fetchProjectTest, refetchProjectTags } =
    useProjectContext();
  const semiHeadersTextStyle = {
    fontVariant: 'all-petite-caps',
    fontWeight: 'bolder',
    fontSize: '1.2em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'rgba(0, 0, 0, 0.6)',
  };

  useEffect(() => {
    const { testtype } = test;
    const isHealthcareTest = Object.values(HEALTHCARE_TEST_TYPES).includes(
      testtype,
    );
    if (isHealthcareTest) {
      setMultipleTagPerScenario(true);
    }

    setTagsInTest(getTagsInTest(test));
  }, []);

  const getTagsInTest = (test) => {
    if (!test || !Array.isArray(test.scnrs)) return [];

    return test.scnrs.reduce((res, { tags }) => {
      if (tags && !res.includes(tags)) {
        res.push(tags);
      }
      return res;
    }, []);
  };

  const openTagsEditDrawer = (scenario) => {
    const allowedTags = TAGS_LIST.filter(
      (t) => !tagsInTest.includes(t) || t === scenario.tags,
    );
    const testId = test.testid;
    const data = {
      allowedTags,
      scenario,
      projectId: test.projectid,
    };

    openEditModal({
      type: 'tags',
      testId,
      value: scenario.tags || '',
      data,
    });
  };

  const openTagResetDrawer = (scenario) => {
    setResetTagScenarioId(scenario.scenarioid);
    setResetTagModalOpen(true);
  };

  const closeTagResetDrawer = () => {
    setResetTagModalOpen(false);
    setResetTagScenarioId(null);
  };

  const handleTagReset = async () => {
    setResetScenarioTagInProgress(true);
    try {
      const response = await scenarios.resetScenarioTag(resetTagScenarioId);

      if (isValidResponse(response)) {
        fetchProjectTest(test.testid);
        refetchProjectTags();
        setResetTagScenarioId(null);
      } else throw new Error(getErrorMessageFromResponse(response));
    } catch (err) {
      if (mounted.current) {
        console.error(`Error while deleting scenario tag: ${err.message}`);
      }
    } finally {
      if (mounted.current) {
        setResetScenarioTagInProgress(false);
        closeTagResetDrawer();
      }
    }
  };

  const setScenarioExecuted = async (scenarioId, value) => {
    setScenarioExecutionChangeInProgress(true);
    try {
      const response = await scenarios.updateScenarios(scenarioId, {
        scenarioexecuted: value,
      });

      if (isValidResponse(response)) {
        fetchProjectTest(test.testid);
        setScenarioExecutionChangeInProgress(false);
      } else throw new Error(getErrorMessageFromResponse(response));
    } catch (err) {
      if (mounted.current) {
        setScenarioExecutionChangeInProgress(false);
        setErrMsg(err.message);
        console.error(`Error while updating scenario: ${err.message}`);
      }
    }
  };
  const SCENARIOS_CONFIG_TABLE = {
    tag: {
      key: 'tags',
      label: 'Tag',
      align: 'center',
    },
    samples: {
      key: 'snumbers',
      label: 'Samples',
      align: 'center',
    },
    executed: {
      key: 'executed',
      label: 'Executed',
      align: 'center',
    },
  };

  return (
    <React.Fragment>
      <ErrorMessage
        message={errMsg}
        handleCloseErrorAlert={() => setErrMsg('')}
      />
      <Table size="small" aria-label="purchases">
        <TableHead>
          <StyledTableRow border="initial">
            <StyledTableCell widthcol={2} headertype="secondary" />
            {test.scnrs.map(({ name, scenarioid }) => (
              <StyledTestTableCell
                widthcol={4}
                headertype="secondary"
                key={`scenario-${scenarioid}`}
                align="left"
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography
                    style={{
                      textTransform: 'capitalize',
                      ...semiHeadersTextStyle,
                      fontSize: '1.3em',
                    }}
                    variant="subtitle2"
                  >
                    {name}
                  </Typography>
                </Stack>
              </StyledTestTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {Object.values(SCENARIOS_CONFIG_TABLE).map((rowConfig) => {
            return (
              <React.Fragment key={rowConfig.key}>
                <StyledTableRow
                  coloring="even"
                  sx={{ '& > *': { borderBottom: 'unset' } }}
                >
                  <StyledTableCell>
                    <Typography variant="subtitle2" sx={semiHeadersTextStyle}>
                      {rowConfig.label}
                    </Typography>
                  </StyledTableCell>
                  {Object.values(test.scnrs).map((scenario) => (
                    <StyledTableCell
                      key={`${scenario.scenarioid}-${rowConfig.key}`}
                      align={rowConfig.align}
                    >
                      <Stack direction="row" alignItems="center">
                        <Typography
                          variant="subtitle2"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {rowConfig.key === 'tags' &&
                            !!editable &&
                            !scenario.executed && (
                              <IconButton
                                onClick={() => openTagResetDrawer(scenario)}
                                aria-label="reset scenario tag"
                                size="small"
                                disabled={disableActions}
                              >
                                <DeleteOutlineIcon
                                  color={disableActions ? '' : 'error'}
                                  fontSize="inherit"
                                />
                              </IconButton>
                            )}
                          {rowConfig.key === 'tags' &&
                            !!editable &&
                            !scenario.executed && (
                              <IconButton
                                onClick={() => openTagsEditDrawer(scenario)}
                                aria-label="edit scenario tag"
                                size="small"
                                disabled={disableActions}
                              >
                                <EditIcon fontSize="inherit" />
                              </IconButton>
                            )}
                          {rowConfig.key === 'tags' && multipleTagPerScenario
                            ? scenario.tagsArr.join(', ')
                            : rowConfig.key === 'tags'
                            ? scenario.tags
                            : null}
                          {rowConfig.key === 'snumbers'
                            ? scenario[rowConfig.key]
                            : null}
                          {rowConfig.key === 'executed' ? (
                            <Checkbox
                              checked={scenario[rowConfig.key]}
                              disabled={
                                disableActions ||
                                !scenario.tags ||
                                scenarioExecutionChangeInProgress ||
                                PROJECT_STATUS_WORKFLOW.indexOf(projectStatus) <
                                  PROJECT_STATUS_WORKFLOW.indexOf(
                                    PROJECT_STATUSES.EXECUTION_READY,
                                  ) ||
                                PROJECT_STATUS_WORKFLOW.indexOf(
                                  projectStatus,
                                ) >=
                                  PROJECT_STATUS_WORKFLOW.indexOf(
                                    PROJECT_STATUSES.PUBLISHED,
                                  )
                              }
                              size="small"
                              onChange={(e) =>
                                setScenarioExecuted(
                                  scenario.scenarioid,
                                  e.target.checked,
                                )
                              }
                            />
                          ) : null}
                        </Typography>
                      </Stack>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
      <DeleteDialog
        type="reset"
        itemName="tag"
        open={resetTagModalOpen}
        handleClose={closeTagResetDrawer}
        handleDelete={handleTagReset}
        title="Reset tag"
        isLoading={resetScenarioTagInProgress}
      />
    </React.Fragment>
  );
}
