import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { Box } from '@mui/material';

import testsApi from 'Api/tests';

import ProjectsDraggableList from 'Components/ResultTests/ProjectsDraggableList';
import ErrorMessage from 'Components/UI/ErrorMessage';
import NoDataDisplay from 'Components/UI/NoDataDisplay';

import { useIsMounted } from 'Context';

import { isValidResponse } from 'Utils';

const ProjectsDroppable = function ProjectsDroppable({
  projects,
  isLoading,
  buildingId,
  afterUpdate,
  handleProjectHeatmapsDownload,
  downloadingHeatmapsProjectId,
  showSkeletonList,
}) {
  const [enabled, setEnabled] = useState(false);
  /**
   * This is used to render the Droppable elements after an animation frame,
   * because react-beautiful-dnd library has some issues with React 18 compatibility.
   */
  useEffect(() => {
    const animation = window.requestAnimationFrame(() => setEnabled(true));

    return () => {
      window.cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return (
    <Droppable droppableId="projects-list-droppable" type="projects">
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{ margin: '20px 0px', width: 'fit-content' }}
        >
          <ProjectsDraggableList
            isLoading={isLoading}
            projects={projects}
            buildingId={buildingId}
            afterUpdate={afterUpdate}
            handleProjectHeatmapsDownload={handleProjectHeatmapsDownload}
            downloadingHeatmapsProjectId={downloadingHeatmapsProjectId}
            showSkeletonList={showSkeletonList}
          />
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  );
};

export default function ProjectsListDnD({
  buildingId,
  isLoading,
  afterUpdate,
  projects,
  handleProjectHeatmapsDownload,
  downloadingHeatmapsProjectId,
  showSkeletonList,
}) {
  const [orderedProjects, setOrderedProjects] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const mounted = useIsMounted();

  useEffect(() => {
    setOrderedProjects(formOrderedEntiresArrFromProjects());
  }, [projects]);

  const formOrderedEntiresArrFromProjects = (projectsObj = null) => {
    const projectsEntries = projectsObj
      ? Object.entries(projectsObj)
      : Object.entries(projects);

    return projectsEntries.sort((a, b) => {
      return a[1].projectOrderNum - b[1].projectOrderNum;
    });
  };

  const updateProjectsOrderLocal = (projectId, endProjectId) => {
    const oldOrderProjects = orderedProjects.map(([id]) => parseInt(id));

    const startIndex = oldOrderProjects.indexOf(projectId);
    const endIndex = oldOrderProjects.indexOf(endProjectId);

    oldOrderProjects.splice(startIndex, 1);
    oldOrderProjects.splice(endIndex, 0, projectId);

    const newProjectsTestsObj = {};
    for (let projectId of oldOrderProjects) {
      newProjectsTestsObj[projectId] = Object.assign({}, projects[projectId], {
        projectOrderNum: oldOrderProjects.indexOf(projectId) + 1,
      });
    }

    setOrderedProjects(formOrderedEntiresArrFromProjects(newProjectsTestsObj));
  };

  const updateProjectsOrder = async (projectId, projectIdOnTarget) => {
    try {
      const bodyObj = {
        buildingId,
        projectId,
        projectIdOnTarget,
      };

      const updateResponse =
        await testsApi.updateTestsOrderByProjectsOrderChange(bodyObj);
      if (isValidResponse(updateResponse)) {
        if (typeof afterUpdate === 'function') {
          afterUpdate();
        }
        if (mounted.current) {
          setErrorMsg('');
        }
      } else throw new Error();
    } catch (err) {
      if (mounted.current) {
        setOrderedProjects(formOrderedEntiresArrFromProjects());
        setErrorMsg(`Could not update the projects order`);
      }
    }
  };

  const onDragEnd = async (data) => {
    setIsFetching(true);

    const { destination, source, reason } = data;

    if (reason !== 'DROP' || !destination) return;

    try {
      const projectIdOnTarget = orderedProjects[destination.index - 1][1].id;
      const projectId = orderedProjects[source.index - 1][1].id;

      if (projectId === projectIdOnTarget) return;

      updateProjectsOrderLocal(projectId, projectIdOnTarget);

      await updateProjectsOrder(projectId, projectIdOnTarget);

      return;
    } catch (err) {
      console.log('onDragEnd err: ', err);
    } finally {
      if (mounted.current) {
        setIsFetching(false);
      }
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <ErrorMessage
        message={errorMsg}
        handleCloseErrorAlert={() => setErrorMsg('')}
      />
      {showSkeletonList || orderedProjects.length ? (
        <ProjectsDroppable
          isLoading={isLoading || isFetching}
          projects={orderedProjects}
          buildingId={buildingId}
          afterUpdate={afterUpdate}
          handleProjectHeatmapsDownload={handleProjectHeatmapsDownload}
          downloadingHeatmapsProjectId={downloadingHeatmapsProjectId}
          showSkeletonList={showSkeletonList}
        />
      ) : (
        <Box sx={{ height: '500px', display: 'flex', width: '100%' }}>
          <NoDataDisplay />
        </Box>
      )}
    </DragDropContext>
  );
}
