import React from 'react';

import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmDialog({
  open,
  handleClose,
  handleConfirm,
  title = 'Confirmation is required',
  isLoading = false,
  action = '',
  showContent = true,
  okBtnText = 'Confirm',
}) {
  return (
    <Dialog open={open}>
      <DialogTitle sx={{ width: 420 }}>{title}</DialogTitle>
      {!!showContent && (
        <DialogContent>
          {action
            ? `Are you sure you want to ${action}?`
            : 'Please, confirm this action'}
        </DialogContent>
      )}
      <DialogActions sx={{ justifyContent: 'center', mb: 1 }}>
        <Button
          variant="outlined"
          onClick={handleClose}
          sx={{ mr: 1 }}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isLoading}
          onClick={handleConfirm}
        >
          {okBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
