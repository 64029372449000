import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Delete, Save } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import buildings from 'Api/buildings';

import { usePortfolioContext } from 'Components/Portfolios/context';

const EditSite = ({ id, cancel }) => {
  const [site, setSite] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { handleSubmit, register, setValue, formState } = useForm();
  const { fetchPortfoliosAndSites } = usePortfolioContext();

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      fetchSiteById(id);
    }
  }, []);

  const fetchSiteById = async (id) => {
    const data = await buildings.getSite(id);
    setSite(data?.data?.data ?? null);
    setValue('name', data?.data?.data?.name ?? '');
    setValue('territoryId', data?.data?.data?.territoryId ?? '');
    setIsLoading(false);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    buildings
      .updateSite(site.id, data)
      .then(() => {
        fetchPortfoliosAndSites();
        cancel();
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelete = () => {
    setIsLoading(true);
    buildings
      .deleteSite(site.id)
      .then(() => {
        fetchPortfoliosAndSites();
        cancel();
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderForm = () => {
    if (site && !isLoading) {
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <TextField
              id="site-id"
              fullWidth
              label="Site name"
              margin="normal"
              required
              {...register('name')}
            />
          </FormControl>
          <FormControl fullWidth sx={{ marginTop: 1 }}>
            <TextField
              id="territory-select"
              label="Territory"
              fullWidth
              required
              select
              inputProps={register('territoryId')}
              defaultValue={site.territoryId}
            >
              {site.territories.map((t) => (
                <MenuItem key={t.id} value={t.id}>
                  {t.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          {site?.siteContracts?.length > 0 && (
            <Alert severity="warning" sx={{ marginTop: 1 }}>
              You cannot delete the site because it has the following site
              contract(s) associated with it:
              <ul>
                {site.siteContracts?.map((sc) => (
                  <li key={sc.id}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`/test-packages/${sc.id}`}
                    >
                      {sc.name}
                    </a>
                  </li>
                ))}
              </ul>
            </Alert>
          )}
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              startIcon={<Save />}
              disabled={!formState.isDirty}
              type="submit"
            >
              Save
            </Button>
            <ButtonGroup>
              <Button
                startIcon={<Delete />}
                color="error"
                disabled={site?.siteContracts?.length > 0 ?? true}
                onClick={handleDelete}
              >
                Delete
              </Button>
              <Button onClick={cancel}>Cancel</Button>
            </ButtonGroup>
          </Stack>
        </form>
      );
    }
    return null;
  };

  return (
    <Stack p={2} spacing={2}>
      {isLoading && <LinearProgress />}
      <Typography variant="h5">Edit site {id}</Typography>
      <Box py={2}>{renderForm()}</Box>
      {!!error && <Alert severity="error">Error when updating the site</Alert>}
    </Stack>
  );
};

export default EditSite;
