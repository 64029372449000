import React from 'react';
import { useForm } from 'react-hook-form';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';

import contractsApi from 'Api/contracts';
import projectsApi from 'Api/projects';

import SecondaryButton from 'Components/Buttons/SecondaryButton';
import { useTestPackageItems } from 'Components/TestPackageItem/context';
import { createTests } from 'Components/TestPackages/utils';

import { getUserData, isValidResponse } from 'Utils';

const CreateProjectButton = () => {
  const {
    startProject,
    setStartProject,
    setMainError,
    openCreateProjectDialog,
    setOpenCreateProjectDialog,
    selectedTestPackageList,
    isLoading,
    setIsLoading,
    setSuccessMessage,
    loadAllData,
    siteContract,
    setSelectedTestPackageList,
  } = useTestPackageItems();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const handleOpenCreateProjectDialog = () => {
    if (!Object.keys(selectedTestPackageList).length) {
      setMainError('Please Select at least one Test Package');
      return;
    }
    setOpenCreateProjectDialog(true);
  };

  const onCreateProjectSubmit = async (data) => {
    try {
      setIsLoading(true);
      setSuccessMessage('');
      let buildingAddress = '';
      if (siteContract.buildingaddress) {
        buildingAddress = Object.values(siteContract.buildingaddress).join(',');
      }

      let projectSendObj = {
        name: data.projectName,
        address: buildingAddress,
        description: data.description,
        partners: siteContract.partnername,
        endcustomer: siteContract.endcustomer,
        users: getUserData('firstName') + ' ' + getUserData('lastName'),
      };

      const responseProject = await projectsApi.createProject(projectSendObj);

      if (isValidResponse(responseProject)) {
        const projectId = responseProject.data.data[0]?.projectid;
        if (!projectId) {
          setMainError('Can not create Project');
          return;
        }

        let testPackagesUpdateSendObj = {
          projectId,
          testPackageIds: Object.keys(selectedTestPackageList),
        };
        const responseTestPackagesUpdate =
          await contractsApi.updateTestPackagesProject(
            testPackagesUpdateSendObj,
          );
        if (isValidResponse(responseTestPackagesUpdate)) {
          if (data.testPackages) {
            for (let testList of data.testPackages) {
              if (!testList) continue;

              const isSuccess = await createTests(
                testList,
                projectId,
                setMainError,
                lastActionAfterCreateProject,
              );
              if (!isSuccess) {
                await lastActionAfterCreateProject();
                return;
              }
            }
            setSuccessMessage('All process successfully handled');
            await lastActionAfterCreateProject();
          }
        } else {
          setMainError(responseTestPackagesUpdate.data?.error?.message);
        }
      } else {
        setMainError(responseProject.data?.error?.message);
      }
    } catch (e) {
      setMainError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const updateList = async () => {
    setStartProject(false);
    setSelectedTestPackageList([]);
    await loadAllData();
  };

  const lastActionAfterCreateProject = async () => {
    handleCloseCreateProjectDialog();
    setValue('testPackages', []);
    reset();
    await updateList();
  };

  const handleCloseCreateProjectDialog = () => {
    setOpenCreateProjectDialog(false);
  };

  return (
    <>
      <SecondaryButton
        tooltipText={
          startProject
            ? 'Create project from selected Test Packages'
            : 'Add test packages to project first'
        }
        text={'Start Project'}
        clickAction={handleOpenCreateProjectDialog}
        disabled={!startProject}
      />
      <Dialog open={openCreateProjectDialog}>
        <form onSubmit={handleSubmit(onCreateProjectSubmit)}>
          <DialogTitle>Create Project</DialogTitle>
          <DialogContent sx={{ width: 400 }}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Project Name"
              name="projectName"
              id="projectName"
              {...register('projectName', {
                required: 'Project Name field required',
                minLength: {
                  value: 3,
                  message: 'Project Name must have at least 3 characters',
                },
              })}
              error={!!(errors && errors.projectName)}
              helperText={errors?.projectName?.message}
              disabled={isLoading}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              minRows={3}
              maxRows={7}
              multiline
              label="Description"
              name="description"
              id="description"
              {...register('description', {
                required: 'Description field required',
                minLength: {
                  value: 3,
                  message: 'Description must have at least 3 characters',
                },
              })}
              error={!!(errors && errors.description)}
              helperText={errors?.description?.message}
              disabled={isLoading}
            />
            {!!selectedTestPackageList &&
              Object.values(selectedTestPackageList).map((testPack) => (
                <Box key={testPack.id} sx={{ mt: 2 }}>
                  <Typography variant="subtitle1">{testPack.name}</Typography>
                  {!!testPack.list &&
                    testPack.list.map((test, index2) => (
                      <Box key={testPack.id + test.identifier + index2}>
                        {!!test.count &&
                          test.count.map((t, index3) => (
                            <Box
                              key={
                                testPack.id + test.identifier + index2 + index3
                              }
                            >
                              <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label={test.label}
                                name={`testPackages.${testPack.id}.${test.identifier}.${index3}`}
                                id={test.identifier + index3}
                                defaultValue={test.value + ': ' + (index3 + 1)}
                                {...register(
                                  `testPackages.${testPack.id}.${test.identifier}.${index3}`,
                                  {
                                    required: `${test.label} field required`,
                                    minLength: {
                                      value: 3,
                                      message: `${test.label} must have at least 3 characters`,
                                    },
                                  },
                                )}
                                error={!!(errors && errors[test.identifier])}
                                helperText={errors[test.identifier]?.message}
                                disabled={isLoading}
                              />
                            </Box>
                          ))}
                        <input
                          type="hidden"
                          {...register(
                            `testPackages.${testPack.id}.buildingId`,
                          )}
                          name={`testPackages.${testPack.id}.buildingId`}
                          value={testPack.buildingId}
                        />
                      </Box>
                    ))}
                </Box>
              ))}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleCloseCreateProjectDialog}>
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={isLoading}
            >
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CreateProjectButton;
