import React from 'react';

import ChartTitle from './ChartTitle';
import ChartPortfolioBuildings from './Charts/ChartPortfolioBuildings';

import { Box, Stack } from '@mui/material';

export default function PortfolioOverview() {
  return (
    <Stack gap={2} sx={{ alignItems: 'center' }}>
      <ChartTitle
        titleText="Portfolio's buildings overview"
        chartId="chart-portfolio-buildings"
      />
      <Box
        id="chart-portfolio-buildings"
        sx={{ width: '100%', height: '500px' }}
      >
        <ChartPortfolioBuildings />
      </Box>
    </Stack>
  );
}
