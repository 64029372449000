const LOCAL_STORAGE_USER_KEY = 'currentUser';

class User {
  id = null;

  parseData() {
    let data;
    try {
      data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
    } catch (e) {
      console.error("Can't parse user info");
      return;
    }

    if (!data.id) {
      console.error("Can't parse user info");
    }

    this.id = data.id;
  }

  saveData(data) {
    try {
      localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(data));
    } catch (e) {
      console.error("Can't parse user info");
    }
  }

  getId() {
    if (this.id == null) {
      this.parseData();
    }
    return this.id;
  }

  remove() {
    localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
  }
}

export { LOCAL_STORAGE_USER_KEY, User };
