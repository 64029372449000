import { ST_CHART_COLORS } from 'Constants';
import React, { useEffect, useState } from 'react';
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  XAxis,
  YAxis,
} from 'recharts';

import { useDashboardContext } from 'Components/Dashboard/context';

export default function ChartPortfolioBuildings() {
  const [chartData, setChartData] = useState([]);
  const [buildingKeyNameConfig, setBuildingKeyNameConfig] = useState({});
  const [maxEACHXAxis, setMaxEACHXAxis] = useState(10);

  const { portfolioOverviewData } = useDashboardContext();

  useEffect(() => {
    if (portfolioOverviewData) {
      const [keyNameConf, dataForChart, maxEACHInData] = parseDataFromServer(
        portfolioOverviewData,
      );

      if (Math.ceil(maxEACHInData) + 2 > maxEACHXAxis) {
        setMaxEACHXAxis(Math.ceil(maxEACHInData) + 2);
      }

      setBuildingKeyNameConfig(keyNameConf);
      setChartData(dataForChart);
    }
  }, [portfolioOverviewData]);

  const parseDataFromServer = (dataFromServer) => {
    const dataForChart = [];
    const keyNameConf = {};
    let buildingKey = 1;
    let maxEACHInData = 0;

    for (let buildingId of Object.keys(dataFromServer)) {
      const buildingData = dataFromServer[buildingId];
      const { buildingName, testsEACH } = buildingData;
      keyNameConf[buildingKey] = buildingName;

      testsEACH.sort((a, b) => a - b);

      const testsEachMean =
        testsEACH.reduce((a, b) => a + b, 0) / testsEACH.length;

      dataForChart.push({
        buildingKey_meanEACH: buildingKey,
        eACH: testsEachMean,
      });

      for (let eACH of testsEACH) {
        dataForChart.push({
          eACH,
          buildingKey_testEACH: buildingKey,
        });
      }

      dataForChart.push({
        eACH: testsEACH[0],
        [`buildingKey_eachMinMax_${buildingKey}`]: buildingKey,
      });
      dataForChart.push({
        eACH: testsEACH[testsEACH.length - 1],
        [`buildingKey_eachMinMax_${buildingKey}`]: buildingKey,
      });

      if (maxEACHInData < testsEACH[testsEACH.length - 1]) {
        maxEACHInData = testsEACH[testsEACH.length - 1];
      }

      buildingKey++;
    }

    return [keyNameConf, dataForChart, maxEACHInData];
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={500}
        height={500}
        data={chartData}
        margin={{
          top: 30,
          right: 20,
          bottom: 50,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          dataKey="eACH"
          type="number"
          domain={[0, maxEACHXAxis]}
          ticks={Array(maxEACHXAxis + 1)
            .fill(1)
            .map((_, i) => i)}
          interval={0}
          label={<CustomizedLabel maxEACHXAxis={maxEACHXAxis} />}
        />
        <YAxis
          padding={{ top: 40 }}
          tickFormatter={(val) => `${buildingKeyNameConfig[val]}` || ''}
          ticks={Object.keys(buildingKeyNameConfig).map((key) => +key)}
        />
        {!!portfolioOverviewData
          ? Object.keys(portfolioOverviewData).map((buildingId, i) => (
              <Line
                key={buildingId}
                type="monotone"
                dataKey={`buildingKey_eachMinMax_${i + 1}`}
                stroke="black"
                dot={<CustomizedDot />}
                tooltip={null}
              />
            ))
          : null}
        <ReferenceLine
          x={4.61}
          stroke="red"
          label={{
            position: 'insideTopLeft',
            value: '4.61 eACH (99% Reduction/H) | UL VVF Standart',
          }}
          strokeWidth={3}
        />
        <Scatter
          dataKey="buildingKey_testEACH"
          fill="#C6DEED"
          shape={<CustomScatterDot />}
        />
        <Scatter
          dataKey="buildingKey_meanEACH"
          fill="black"
          shape={<CustomScatterDotMeanValue />}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

const CustomScatterDotMeanValue = (props) => {
  const { cx, cy, buildingKey_meanEACH, eACH } = props;
  if (!buildingKey_meanEACH) return null;

  return (
    <g>
      <circle
        cx={cx}
        cy={cy}
        r={8}
        fill="white"
        stroke="black"
        strokeWidth={1}
      />
      <text
        x={cx}
        y={cy}
        textAnchor="middle"
        alignmentBaseline="middle"
        fill="black"
        fontSize={8}
      >
        {eACH.toFixed(1)}
      </text>
    </g>
  );
};

const CustomScatterDot = (props) => {
  const { cx, cy, buildingKey_testEACH } = props;
  if (!buildingKey_testEACH) return null;

  return (
    <g>
      <circle
        cx={cx}
        cy={cy}
        r={4}
        fill="#C6DEED"
        stroke="black"
        strokeWidth={1}
      />
    </g>
  );
};

const CustomizedDot = (props) => {
  const { cx, cy, value } = props;
  if (!value) return null;

  return (
    <svg
      x={cx - 0.5}
      y={cy - 10}
      width={20}
      height={20}
      fill="black"
      viewBox="0 0 1024 1024"
    >
      <rect width="70" height="1024" />
    </svg>
  );
};

const CustomizedLabel = (props) => {
  const { viewBox, maxEACHXAxis } = props;
  const { width, x, y } = viewBox;

  const offsetY = 30;
  const offsetYText = 15;
  const r = '5';
  const widthOfStroke = 3;
  const heightOfBracket = 20;

  const defZoneEndXCoord = 4.5;
  const energyZoneStartXCoord = 6;

  const defZoneWidthPercent = defZoneEndXCoord / maxEACHXAxis;
  const defZoneTextXStart = (width * (defZoneEndXCoord / 2)) / maxEACHXAxis;
  const energyZoneXStart = width * (energyZoneStartXCoord / maxEACHXAxis);
  const energyZoneTextXStart =
    (width *
      (energyZoneStartXCoord + (maxEACHXAxis - energyZoneStartXCoord) / 2)) /
    maxEACHXAxis;
  const energyZoneWidthPercent =
    (maxEACHXAxis - energyZoneStartXCoord) / maxEACHXAxis;

  return (
    <g>
      <rect
        x={x}
        y={y + offsetY}
        width={width * defZoneWidthPercent}
        fill={ST_CHART_COLORS.blue}
        height={heightOfBracket}
        rx={r}
        ry={r}
      />
      <rect
        x={x + widthOfStroke}
        y={y + offsetY}
        width={width * defZoneWidthPercent - widthOfStroke * 2}
        fill="white"
        height={heightOfBracket - widthOfStroke}
      />
      <text
        x={defZoneTextXStart + widthOfStroke + x}
        y={y + offsetY + heightOfBracket + offsetYText}
        textAnchor="middle"
        alignmentBaseline="middle"
        fill="black"
        fontSize={
          (width * (defZoneEndXCoord / 2) + widthOfStroke + x) / 14 > 16
            ? 16
            : (width * (defZoneEndXCoord / 2) + widthOfStroke + x) / 14
        }
      >
        Deficiency zone
      </text>
      <rect
        x={energyZoneXStart + x}
        y={y + offsetY}
        width={width * energyZoneWidthPercent}
        fill={ST_CHART_COLORS.blue}
        height={heightOfBracket}
        rx={r}
        ry={r}
      />
      <rect
        x={energyZoneXStart + widthOfStroke + x}
        y={y + offsetY}
        width={width * energyZoneWidthPercent - widthOfStroke * 2}
        fill="white"
        height={heightOfBracket - widthOfStroke}
      />
      <text
        x={energyZoneTextXStart + x}
        y={y + offsetY + heightOfBracket + offsetYText}
        textAnchor="middle"
        alignmentBaseline="middle"
        fill="black"
        fontSize={
          (energyZoneXStart + widthOfStroke + x) / 28 > 16
            ? 16
            : (energyZoneXStart + widthOfStroke + x) / 28
        }
      >
        Energy & Cost Savings Zone
      </text>
    </g>
  );
};
