import React from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, ButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ResetButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'leaveColoredBorder',
})(({ theme, leaveColoredBorder }) => ({
  ...(leaveColoredBorder && {
    borderColor: `${theme.palette.primary.light}`,
    '&.Mui-disabled': {
      borderColor: `${theme.palette.primary.light}`,
    },
  }),
}));

export default function FilterButton({
  onClick,
  text = '',
  filterOpen = false,
  btn_id = '',
  ariaControls = '',
  filterActive = false,
  onReset,
  sx,
  disabled,
}) {
  return (
    <ButtonGroup
      size="small"
      variant="outlined"
      sx={{ ...sx }}
      disabled={disabled}
    >
      <Button
        id={btn_id}
        aria-controls={filterOpen ? ariaControls : undefined}
        aria-haspopup="true"
        aria-expanded={filterOpen ? 'true' : undefined}
        onClick={onClick}
        startIcon={<FilterAltIcon />}
        endIcon={<KeyboardArrowDownIcon />}
        disableElevation
        className="on-click-transofrm-svg"
        disableTouchRipple
        disabled={disabled}
      >
        {text}
      </Button>
      <ResetButton
        onClick={onReset}
        disabled={!filterActive || disabled}
        leaveColoredBorder={filterActive || !disabled}
        disableTouchRipple
      >
        <ClearIcon fontSize="small" />
      </ResetButton>
    </ButtonGroup>
  );
}
