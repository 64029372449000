import { createSlice } from '@reduxjs/toolkit';

import buildingsApi from '../../Api/buildings';

import { getBuildingName } from 'Components/Breadcrumbs/dataUtils';

import { isValidResponse, shouldShowErrorFromResponse } from 'Utils';

function startLoading(state) {
  state.isLoading = true;
}

function finishLoading(state) {
  state.isLoading = false;
}

function loadingFailed(state, action) {
  state.isLoading = false;
  state.error = action.payload;
}

export const slice = createSlice({
  name: 'results',
  initialState: {
    buildings: [],
    currentBuildingName: '',
    currentBuildingId: '',
    isLoading: true,
    error: null,
  },
  reducers: {
    setStartLoading: startLoading,
    setFinishLoading: finishLoading,
    setLoadingFailed: loadingFailed,
    setCurrentBuildingName: (state, action) => {
      state.currentBuildingName = action.payload;
    },
    setCurrentBuildingId: (state, action) => {
      state.currentBuildingId = action.payload;
    },
    setFetchBuildingsSuccess: (state, action) => {
      state.buildings = action.payload;
    },
    setNotError: (state) => {
      state.error = null;
    },
  },
});

export const fetchBuildings =
  (colors, buildingId = null, forceUpdate = false, inProgress = false) =>
  async (dispatch, getState) => {
    try {
      const now = Date.now();

      dispatch(setStartLoading());
      const { currentBuildingId, buildings } = getState().results;
      if (!buildings || !buildings.length || forceUpdate) {
        const response = await buildingsApi.getAllBuildings(colors, inProgress);

        if (!isValidResponse(response)) {
          if (!shouldShowErrorFromResponse(response)) {
            dispatch(setFinishLoading());
            return;
          }
          const errorMessage = response?.data?.error?.message || '';
          throw new Error(errorMessage);
        }

        const newBuildings = response.data.data;
        dispatch(setFetchBuildingsSuccess(newBuildings));
        if (!forceUpdate) {
          dispatch(setCurrentBuildingId(buildingId));
          let buildingName = getBuildingName(newBuildings, buildingId);
          if (!buildingName && buildingId) {
            const responseBuilding = await buildingsApi.getBuilding(buildingId);
            const building = responseBuilding.data.data;
            buildingName = building.buildingname;
          }
          dispatch(setCurrentBuildingName(buildingName));
        }
      } else {
        if (buildingId != null && currentBuildingId !== buildingId) {
          dispatch(setCurrentBuildingId(buildingId));
          const buildingName = getBuildingName(buildings, buildingId);
          dispatch(setCurrentBuildingName(buildingName));
        }
      }

      const end = Date.now();
      if (end - now < 300) {
        setTimeout(() => {
          dispatch(setFinishLoading());
        }, 300 - (end - now));
      } else {
        dispatch(setFinishLoading());
      }
    } catch (err) {
      dispatch(setLoadingFailed(err.toString()));
    }
  };

export const {
  setStartLoading,
  setLoadingFailed,
  setFinishLoading,
  setFetchBuildingsSuccess,
  setCurrentBuildingName,
  setCurrentBuildingId,
  setNotError,
} = slice.actions;

export default slice.reducer;

export const resultsSelector = (state) => state.results;
