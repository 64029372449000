import React, { useCallback } from 'react';

import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import PortfolioDetails from 'Components/Portfolios/PortfolioDetails/PortfolioDetails';
import PortfolioSummary from 'Components/Portfolios/PortfolioItem/PortfolioSummary';

export default function PortfolioItem({
  portfolio,
  portfolioSites,
  portfolioTerritories,
  portfolioRegions,
  requestTestsCoverageDataForPortfolioUpdate,
}) {
  const updateTestsData = useCallback(async (_, isOpen) => {
    if (!isOpen) return;

    const portfolioId = portfolio?.portfolioid;
    // Timeout for accordion's animation to finish, so that transition smoothness won't be lost.
    setTimeout(
      () => requestTestsCoverageDataForPortfolioUpdate(portfolioId),
      250,
    );
  }, [portfolio.portfolioid, requestTestsCoverageDataForPortfolioUpdate]);

  return (
    <Accordion onChange={updateTestsData}>
      <AccordionSummary
        expandIcon={<ExpandMore fontSize="large" />}
        aria-controls={portfolio.portfolioid + '-accordion'}
      >
        <PortfolioSummary
          portfolio={portfolio}
          sites={portfolioSites}
          territories={portfolioTerritories}
          regions={portfolioRegions}
        />
      </AccordionSummary>
      <AccordionDetails>
        <PortfolioDetails
          portfolio={portfolio}
          sites={portfolioSites}
          territories={portfolioTerritories}
          regions={portfolioRegions}
        />
      </AccordionDetails>
    </Accordion>
  );
}
