export const pointByConditionValues = {
  CONDITION_ALL: 'all',
  CONDITION_GREATER: 'greater',
  CONDITION_LESS: 'less',
};

export const colorsFilterValues = {
  HIGH: 'high',
  LOW: 'low',
  MEDIUM: 'medium',
};

export const colorsFilterLabels = {
  custom: {
    [colorsFilterValues.HIGH]: 'High',
    [colorsFilterValues.LOW]: 'Low',
    [colorsFilterValues.MEDIUM]: 'Medium',
  },
  verLike: {
    [colorsFilterValues.LOW]: 'Pass',
    [colorsFilterValues.HIGH]: 'Failure',
    [colorsFilterValues.MEDIUM]: 'Invalid',
  },
};

export const colorsFilterColors = {
  [colorsFilterValues.HIGH]: '#e57373',
  [colorsFilterValues.LOW]: '#81c784',
  [colorsFilterValues.MEDIUM]: '#fbc02d',
};

export const LS_SAFETRACE_SET_COMPANY_ID = 'safetrace_set_company_id';
export const LS_SAFETRACE_SET_COMPANY_NAME = 'safetrace_set_company_name';
export const LS_PARTNER_SET_COMPANY_ID = 'partner_set_company_id';
export const LS_PARTNER_SET_COMPANY_NAME = 'partner_set_company_name';
export const LS_COMPARE_HEATMAPS = 'compare_heatmaps';
export const LS_BREADCRUMBS = 'breadcrumbs';
export const LS_COMPANIES = 'companies';
export const LOG_REDUCTION_LIMIT = 4.7;
export const PROGRESS = 'progress';
export const COLORS = 'colors';
export const LS_TOKEN = 'token';
export const REQ_ERROR_SHOULD_BE_HIDDEN_INDICATOR = 'client_400_hide';
export const ERROR_AUTOCLOSE_TIMEOUT_MS = 3000;

const GENERAL_SURVEY_TYPES = {
  LARGE_SURVEY: 'largeSurvey',
  SMALL_SURVEY: 'smallSurvey',
};

export const HEALTHCARE_TEST_TYPES = {
  NEGATIVE: 'negativePressureRoom',
  POSITIVE: 'positivePressureRoom',
  NEUTRAL: 'neutralPressureRoom',
};

export const TEST_TYPES = {
  GENERAL: 'generalSurvey',
  MIN_SURVEY: 'miniSurvey',
  DILUTION: 'dilution',
  SMALL_DILUTION: 'dilutionSmall',
  LARGE_DILUTION: 'dilutionLarge',
  RECIRCULATION: 'recirculation',
  VERIFICATION: 'verification',
  ...GENERAL_SURVEY_TYPES,
  ...HEALTHCARE_TEST_TYPES,
};

export const LEGACY_TEST_TYPES = [
  TEST_TYPES.LARGE_DILUTION,
  TEST_TYPES.SMALL_DILUTION,
  TEST_TYPES.DILUTION,
  TEST_TYPES.MIN_SURVEY,
];

export const HEALTHCARE_TEST_TYPES_LABELS = {
  [HEALTHCARE_TEST_TYPES.NEGATIVE]: 'Negative Pressure Room',
  [HEALTHCARE_TEST_TYPES.POSITIVE]: 'Positive Pressure Room',
  [HEALTHCARE_TEST_TYPES.NEUTRAL]: 'Neutral Pressure Room',
};

export const FILTER_TEST_TYPES_LABELS = {
  [TEST_TYPES.VERIFICATION]: 'Verification',
  [TEST_TYPES.GENERAL]: 'General Survey',
  [TEST_TYPES.RECIRCULATION]: 'Flex',
  [TEST_TYPES.LARGE_SURVEY]: 'Large Survey',
  [TEST_TYPES.SMALL_SURVEY]: 'Small Survey',
  ...HEALTHCARE_TEST_TYPES_LABELS,
};

export const TEST_TYPES_LABELS = {
  [TEST_TYPES.VERIFICATION]: 'Verification',
  [TEST_TYPES.SMALL_DILUTION]: 'Dilution Small',
  [TEST_TYPES.LARGE_DILUTION]: 'Dilution Large',
  [TEST_TYPES.GENERAL]: 'General Survey',
  [TEST_TYPES.MIN_SURVEY]: 'Mini Survey',
  [TEST_TYPES.RECIRCULATION]: 'Flex',
  [TEST_TYPES.DILUTION]: 'Dilution (legacy)',
  [TEST_TYPES.LARGE_SURVEY]: 'Large Survey',
  [TEST_TYPES.SMALL_SURVEY]: 'Small Survey',
  ...HEALTHCARE_TEST_TYPES_LABELS,
};

export const TEST_TYPES_LABELS_SHORT = {
  [TEST_TYPES.VERIFICATION]: 'Ver.',
  [TEST_TYPES.SMALL_DILUTION]: 'Dil. S',
  [TEST_TYPES.LARGE_DILUTION]: 'Dil. L.',
  [TEST_TYPES.GENERAL]: 'Gen. Surv.',
  [TEST_TYPES.MIN_SURVEY]: 'Mini Surv.',
  [TEST_TYPES.RECIRCULATION]: 'Flex.',
  [TEST_TYPES.DILUTION]: 'Dil. (leg.)',
};

export const TEST_TYPES_MAPPING = {
  smallsurveytests: TEST_TYPES.GENERAL,
  largesurveytests: TEST_TYPES.GENERAL,
  minisurveytests: TEST_TYPES.MIN_SURVEY,
  smalldilutiontests: TEST_TYPES.SMALL_DILUTION,
  largedilutiontests: TEST_TYPES.LARGE_DILUTION,
  recirculationtests: TEST_TYPES.RECIRCULATION,
  verificationtests: TEST_TYPES.VERIFICATION,
};

export const TYPES_TEST_MAPPING = {
  [TEST_TYPES.SMALL_SURVEY]: 'smallsurveytests',
  [TEST_TYPES.LARGE_SURVEY]: 'largesurveytests',
  [TEST_TYPES.GENERAL]: 'largesurveytests',
  [TEST_TYPES.MIN_SURVEY]: 'minisurveytests',
  [TEST_TYPES.SMALL_DILUTION]: 'smalldilutiontests',
  [TEST_TYPES.LARGE_DILUTION]: 'largedilutiontests',
  [TEST_TYPES.RECIRCULATION]: 'recirculationtests',
  [TEST_TYPES.VERIFICATION]: 'verificationtests',
};

export const HEATMAP_VERIFICATION_LIKE_BEHAVIOR = [
  TEST_TYPES.VERIFICATION,
  TEST_TYPES.SMALL_DILUTION,
  TEST_TYPES.LARGE_DILUTION,
];

export const HEATMAP_HEALTH_CARE_LIKE_BEHAVIOR = [
  TEST_TYPES.NEUTRAL,
  TEST_TYPES.NEGATIVE,
  TEST_TYPES.POSITIVE,
];

export const HEATMAP_GENERAL_BEHAVIOUR = [
  TEST_TYPES.GENERAL,
  TEST_TYPES.MIN_SURVEY,
  TEST_TYPES.DILUTION,
  TEST_TYPES.RECIRCULATION,
];

export const projectStatus = {
  new: 'new',
  planned: 'planned',
  scheduled: 'scheduled',
  published: 'published',
  completed: 'completed',
};

export const TEST_SP_COUNTS = {
  smallsurveytests: 12,
  largesurveytests: 24,
  minisurveytests: 4,
  smalldilutiontests: 1,
  largedilutiontests: 1,
  recirculationtests: 4,
};

export const TEST_BUILDING_DATA = {
  smalldilutiontests: { sq_ft: 4000 },
  largedilutiontests: { sq_ft: 8000 },
};

export const CHART_COLORS = [
  '#54bebe',
  '#76c8c8',
  '#98d1d1',
  '#badbdb',
  '#c7d7ce',
  '#dedad2',
  '#e4bcad',
  '#e08f8f',
  '#e29d91',
  '#df979e',
  '#d7658b',
  '#c80064',
];

export const ST_CHART_COLORS = {
  green: '#93BB2B',
  blue: '#4C8894',
};

export const COMPANY_TYPES = {
  Partner: 'Partner',
  Customer: 'Customer',
};

export const EXCEL_FILE_TYPES = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

export const TAGS_LIST = [
  'Tag-C1',
  'Tag-C2',
  'Tag-C3',
  'Tag-C4',
  'Tag-C5',
  'Tag-C6',
  'Tag-C7',
  'Tag-C8',
];

export const TAGS_B_LIST = [
  'Tag-B1',
  'Tag-B2',
  'Tag-B3',
  'Tag-B4',
  'Tag-B5',
  'Tag-B6',
  'Tag-B7',
  'Tag-B8',
];

export const AIR_DISTRIBUTION_EFFECT_OPTIONS = [
  0.5, 0.7, 0.8, 1.0, 1.05, 1.2, 1.4, 1.5,
];

export const MERV_FILTER_RATING_OPTIONS = [
  4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
];

export const PROJECT_STATUSES = {
  NEW: 'new',
  IN_PROGRESS: 'in_progress',
  EXECUTION_READY: 'exec_ready',
  EXECUTED: 'executed',
  READY_FOR_LAB: 'ready_for_lab',
  RESULTS_GENERATED: 'res_generated',
  PUBLISHED: 'published',
};

export const PROJECT_STATUSES_LABELS = {
  [PROJECT_STATUSES.NEW]: 'New',
  [PROJECT_STATUSES.IN_PROGRESS]: 'In progress',
  [PROJECT_STATUSES.EXECUTION_READY]: 'Ready for execution',
  [PROJECT_STATUSES.EXECUTED]: 'Executed',
  [PROJECT_STATUSES.READY_FOR_LAB]: 'Ready for laboratory',
  [PROJECT_STATUSES.RESULTS_GENERATED]: 'Results generated',
  [PROJECT_STATUSES.PUBLISHED]: 'Published',
};

export const PROJECT_STATUS_WORKFLOW = [
  PROJECT_STATUSES.NEW,
  PROJECT_STATUSES.IN_PROGRESS,
  PROJECT_STATUSES.EXECUTION_READY,
  PROJECT_STATUSES.READY_FOR_LAB,
  PROJECT_STATUSES.EXECUTED,
  PROJECT_STATUSES.RESULTS_GENERATED,
  PROJECT_STATUSES.PUBLISHED,
];

export const DASHBOARD_VIEW_TYPES = ['all', 'portfolio', 'building'];

export const DEFAULT_ROWS_AMOUNT_PER_TABLE_PAGE = 10;

export const SP_COLLECTION_METHODS = {
  filter: 'Filter',
  swab: 'Swab',
  filterandswab: 'Filter and Swab',
};

export const SP_COLLECTION_METHODS_FLEX = {
  filter: 'Filter',
  swab: 'Swab',
};

export const ERROR_MESSAGE_DEFAULT = `Something went wrong. Please, try again`;
export const SERVER_ERROR_MESSAGE_DEFAULT = `Server does not respond. Please, try again later`;
export const UNMOUNT_IGNORED_ERR_MSG = 'unmount ignored';
