import * as React from 'react';

import { Box, Typography } from '@mui/material';

const UserDisplayInfo = ({ userEmail, userRoleName }) => {
  return (
    <Box
      sx={{
        flexDirection: 'row',
        mr: 1,
        display: { xs: 'none', md: 'none', lg: 'block' },
      }}
    >
      <Typography align="right">{userEmail}</Typography>
      <Typography align="right">{userRoleName}</Typography>
    </Box>
  );
};

export default React.memo(UserDisplayInfo);
