import {
  HEATMAP_VERIFICATION_LIKE_BEHAVIOR,
  LS_BREADCRUMBS,
  TEST_TYPES,
} from 'Constants';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './index.css';

import { Box } from '@mui/material';

import {
  doCropImage,
  floorPlanCropSelector,
} from 'Features/FloorplanCrop/floorPlanCropSlice';

import { changeCommentString, getSignificanceColor } from 'Utils';

const gradientReaderObj = {
  /**
   * @param {'reductionLog' | 'copiesPerMillion'} type
   */
  createGradientReader: (type = 'reductionLog') => {
    const colorStops =
      type === 'reductionLog'
        ? [
            { stop: 0.0, color: 'red' },
            { stop: 0.143, color: 'red' },
            { stop: 0.286, color: 'yellow' },
            { stop: 0.428, color: 'lightgreen' },
            { stop: 0.571, color: 'green' },
            { stop: 0.714, color: 'green' },
            { stop: 0.847, color: 'green' },
            { stop: 1, color: 'green' },
          ]
        : [
            { stop: 1, color: 'red' },
            { stop: 0.75, color: 'red' },
            { stop: 0.5, color: 'yellow' },
            { stop: 0.25, color: 'lightgreen' },
            { stop: 0, color: 'green' },
          ];
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const gr = ctx.createLinearGradient(0, 0, 101, 0);
    canvas.width = 101;
    canvas.height = 1;

    for (const { stop, color } of colorStops) {
      gr.addColorStop(stop, color);
    }

    ctx.fillStyle = gr;
    ctx.fillRect(0, 0, 101, 1);

    return {
      getColor: (pst) =>
        ctx.getImageData(pst | 0, 0, 1, 1, {
          willReadFrequently: true,
        }).data,
    };
  },
};

const configIndicator = {
  fontSize: '1.6em',
  rectY: 100,
  y: 1770,
  x1: 250,
  x2: 130,
  lineYheight: 80,
  lineStart: -100,
  textX: 10,
  globalTextX: 120,
  globalXshift: -90,
  globalYshift: -400,
  dTextX: 160,
  dTextY: 0,
  sTextX: 1800,
  sTextY: -350,
  sTextXs: 1755,
  sTextYs: 150,
  perLineStringHeight: 80,
  imagePadding: 200,
};

const imgGradientConfigColors = {
  reductionLog: [
    'green',
    'green',
    'green',
    'green',
    'lightgreen',
    'yellow',
    'red',
    'red',
  ],
  copiesPerMillion: [
    'red',
    'red',
    'red',
    'yellow',
    'lightgreen',
    'green',
    'green',
    'green',
    'green',
  ],
};

export default function HeatMap(props) {
  const spDataType =
    props.resultsData.testtype !== TEST_TYPES.GENERAL
      ? 'reductionLog'
      : 'copiesPerMillion';
  const [gradientReader] = useState(
    gradientReaderObj.createGradientReader(spDataType),
  );
  const [verificationLikeBehavior, setvVerificationLikeBehavior] =
    useState(false);
  const [activeFPWidth, setActiveFPWidth] = useState(null);
  const [activeFPHeight, setActiveFPHeight] = useState(null);
  const [croppedImage, setCroppedImage] = useState(
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC',
  );
  const [shift, setShift] = useState({ left: 0, top: 0 });
  const [imageShift, setImageShift] = useState({
    left: 0,
    top: '0',
    width: activeFPWidth,
    height: activeFPHeight,
  });

  const [single] = useState(props.showFirst && props.showLast);
  const [imgSrc, setImgSrc] = useState('');
  const { images } = useSelector(floorPlanCropSelector);

  const dispatch = useDispatch();
  const getColorByValue = (value, verResult = null) => {
    if (verificationLikeBehavior) {
      let value = 0;
      if (verResult) {
        if (verResult.isValid) {
          value = verResult.pass ? 100 : 0;
        } else value = 30;
      }
      const color = gradientReader.getColor(value);
      return 'rgb(' + color[0] + ', ' + color[1] + ', ' + color[2] + ')';
    }
    const pointValue = spDataType === 'reductionLog' ? Math.round(100 / 7) : 25;
    const val = spDataType === 'reductionLog' ? value : Math.log10(value) + 1;
    let percentValue = pointValue * parseFloat(val);
    if (spDataType !== 'reductionLog') {
      percentValue = Math.min(100, percentValue);
      percentValue = Math.max(0, percentValue);
    }
    const colors = gradientReader.getColor(percentValue);

    return 'rgb(' + colors[0] + ', ' + colors[1] + ', ' + colors[2] + ')';
  };

  let commentArray = [];
  let commentHeight = 0;
  if (props.comment) {
    commentArray = changeCommentString(props.comment);
    if (props.isDownload) {
      commentHeight = commentArray
        ? commentArray.length * configIndicator.perLineStringHeight
        : 0;
    }
  }

  let breadcrumbArray = [];
  let breadcrumbHeight = 0;
  if (localStorage.getItem(LS_BREADCRUMBS)) {
    breadcrumbArray = changeCommentString(localStorage.getItem(LS_BREADCRUMBS));
    if (props.isDownload) {
      breadcrumbHeight = breadcrumbArray
        ? breadcrumbArray.length * configIndicator.perLineStringHeight
        : 0;
    }
  }

  let calculatedResultArray = [];
  let calculatedResultHeight = 0;
  if (props.calculatedResult) {
    calculatedResultArray = changeCommentString(props.calculatedResult);
    if (props.isDownload) {
      calculatedResultHeight = calculatedResultArray
        ? calculatedResultArray.length * configIndicator.perLineStringHeight
        : 0;
    }
  }

  const forceHeight = commentHeight + breadcrumbHeight + calculatedResultHeight;

  useEffect(() => {
    const isVerificationLike = !!~HEATMAP_VERIFICATION_LIKE_BEHAVIOR.indexOf(
      props.resultsData.testtype,
    );
    setvVerificationLikeBehavior(isVerificationLike);
  }, [props.resultsData.testtype]);

  const getImageSize = async () => {
    let img = document.querySelector('#activeFP');

    setActiveFPHeight(img.naturalHeight);
    setActiveFPWidth(img.naturalWidth);

    // Call parent method, need to pass detected sizes of image
    props.onImageLoaded(img.naturalWidth, img.naturalHeight);
  };

  const cropImage = () => {
    let img = document.querySelector('#activeFP1');

    dispatch(doCropImage(img.src));
    setImgSrc(img.src);
  };

  useEffect(() => {
    if (
      typeof images[imgSrc] !== 'undefined' &&
      images[imgSrc].loading === false
    ) {
      if (props.overwriteImage) {
        setCroppedImage(props.overwriteImage);
      } else {
        setCroppedImage(images[imgSrc].image);
        setShift(images[imgSrc].shift);
      }
    }
  }, [images, props.overwriteImage]);

  useEffect(() => {
    setImageShift({
      left: 0,
      top: 0,
      width: activeFPWidth,
      height: activeFPHeight,
    });
  }, [shift]);

  const getCoordX = (coordx) => {
    if (props.resultsData.testtype !== TEST_TYPES.DILUTION) {
      return coordx - shift.left;
    }
    if (coordx < props.heatMap.center.x) {
      let x1 = parseFloat(coordx) - shift.left;
      return x1 - props.sliderCropBox * x1;
    } else if (coordx > props.heatMap.center.x) {
      let x1 = parseFloat(coordx) - shift.left;
      return x1 + props.sliderCropBox * (activeFPWidth - x1);
    } else {
      return getOPCoordX(coordx);
    }
  };

  const getCoordY = (coordy) => {
    if (props.resultsData.testtype !== TEST_TYPES.DILUTION) {
      return coordy - shift.top + forceHeight;
    }
    if (coordy < props.heatMap.center.y) {
      let x1 = parseFloat(coordy) - shift.top;
      return x1 - props.sliderCropBox * x1 + forceHeight;
    } else if (coordy > props.heatMap.center.y) {
      let x1 = parseFloat(coordy) - shift.top;
      return x1 + props.sliderCropBox * (activeFPHeight - x1) + forceHeight;
    } else {
      return getOPCoordY(coordy);
    }
  };

  const getOPCoordX = (coordx) => {
    let x1 = parseFloat(coordx) - shift.left;
    return x1 + props.sliderCropBox * (activeFPWidth / 2 - x1);
  };
  const getOPCoordY = (coordy) => {
    let y1 = parseFloat(coordy) - shift.top;
    return y1 + props.sliderCropBox * (activeFPHeight / 2 - y1) + forceHeight;
  };

  const getSpDisplayValue = (
    reduction,
    displayValue = null,
    copiesPerMillion,
  ) => {
    if (verificationLikeBehavior) {
      return displayValue;
    }
    return spDataType === 'reductionLog' ? reduction : copiesPerMillion;
  };

  useEffect(() => {
    if (shift.top === 0 || activeFPWidth === 1) {
      return;
    }
    if (props.resultsData.testtype !== TEST_TYPES.DILUTION) {
      setImageShift({
        left: 0,
        top: '0',
        width: activeFPWidth,
        height: activeFPHeight,
      });
      return;
    }
    const imageDimensions = getImageLeftTop();
    const imageShift = {
      left: imageDimensions.left * props.sliderCropBox,
      top: imageDimensions.top * props.sliderCropBox,
      width:
        activeFPWidth +
        props.sliderCropBox * (imageDimensions.boxWidth - activeFPWidth),
      height:
        activeFPHeight +
        props.sliderCropBox * (imageDimensions.boxHeight - activeFPHeight),
    };
    setImageShift(imageShift);
  }, [props.sliderCropBox, shift, activeFPWidth]);

  const getImageLeftTop = () => {
    let ratio = activeFPWidth / activeFPHeight;
    let horizontal = props.heatMap.center.x - props.heatMap.dimensions.left;
    horizontal =
      horizontal > 0
        ? horizontal
        : props.heatMap.dimensions.right - props.heatMap.center.x;

    let vertical = props.heatMap.center.y - props.heatMap.dimensions.top;
    vertical =
      vertical > 0
        ? vertical
        : props.heatMap.dimensions.bottom - props.heatMap.center.y;
    let width = 2 * horizontal;
    let height = 2 * vertical;
    if (width / ratio > height) {
      height = width / ratio;
    } else {
      width = height * ratio;
    }

    let centerX = activeFPWidth / 2;
    let centerY = activeFPHeight / 2;
    const boxWidth = activeFPWidth * (activeFPWidth / width);
    const boxHeight = activeFPHeight * (activeFPHeight / height);
    return {
      left:
        (activeFPWidth - boxWidth) / 2 -
        (props.heatMap.center.x - centerX - shift.left) *
          (boxWidth / activeFPWidth),
      top:
        (activeFPHeight - boxHeight) / 2 -
        (props.heatMap.center.y - centerY - shift.top) *
          (boxWidth / activeFPWidth),
      width,
      height,
      boxWidth,
      boxHeight,
    };
  };

  let spCircleValue = props.sliderSp ? props.sliderSp : 35;

  if (props.isDownload && props.overwriteImage) {
    // Show a simplified view, logo, text and the overwriteImage
    return (
      <div
        style={{
          fontSize: '3.5rem',
        }}
      >
        <div style={{ height: '650px' }}>
          <img src="/SafeTraces_Logo.png" alt="logo" />
        </div>
        <div>
          {(props.resultsData.testtype === TEST_TYPES.DILUTION ||
            props.resultsData.testtype === TEST_TYPES.RECIRCULATION) && (
            <span>Interval: {props.interval}</span>
          )}
          <span>
            Test: {props.resultsData.testid}, Sample type:&nbsp;
            {props.resultsData.sampletype ?? props.resultsData.collectionmethod}
            <br />
            {props.resultsData.scenarioid !== null &&
              props.resultsData.testtype !== TEST_TYPES.VERIFICATION && (
                <span>
                  Scenario: {props.resultsData.scenarioid} |&nbsp;
                  {props.resultsData.scenarioname}
                </span>
              )}
          </span>
          <br />
          <span>Calculated Result:&nbsp;</span>
          {!!calculatedResultArray &&
            calculatedResultArray.map((item, index) => (
              <span key={index}>{item}</span>
            ))}
          <br />
          {!!commentArray && (
            <>
              <span>Comment:</span>
              {!!commentArray &&
                commentArray.map((item, index) => (
                  <span key={index}>{item}</span>
                ))}
            </>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4rem',
            }}
          >
            <span>
              {props.resultsData.testtype !== TEST_TYPES.VERIFICATION
                ? 'Significance:'
                : 'Result: '}
            </span>
            <div
              style={{
                width: '120px',
                height: '120px',
                backgroundColor: getSignificanceColor(props.significance),
              }}
            />
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'start',
              gap: '2rem',
            }}
          >
            <img
              style={{ minWidth: '1200px', flexGrow: 1 }}
              alt="HeatMap"
              src={props.overwriteImage}
              id="overwriteImage"
            />
            {props.showLegend ? (
              <table
                style={{
                  height: 'fit-content',
                }}
              >
                <tbody>
                  <tr>
                    <td colSpan="2">
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <div style={{ fontSize: '3.5rem' }}>
                          <span>
                            {spDataType === 'reductionLog'
                              ? 'Indicator Tag'
                              : 'DNA Copies per'}
                          </span>
                          <br />
                          <span>
                            {spDataType === 'reductionLog'
                              ? 'Reduction Log'
                              : 'million emitted'}
                          </span>
                        </div>
                        {spDataType === 'reductionLog' ? (
                          <span style={{ fontSize: '6rem' }}>10</span>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                  {spDataType === 'reductionLog' ? (
                    <React.Fragment>
                      <tr>
                        <td>
                          <div
                            style={{
                              width: '150px',
                              height: '150px',
                              backgroundColor: 'green',
                            }}
                          />
                        </td>
                        <td>6 (99.9999%)</td>
                      </tr>
                      <tr>
                        <td>
                          <div
                            style={{
                              width: '150px',
                              height: '150px',
                              backgroundColor: 'green',
                            }}
                          />
                        </td>
                        <td>5 (99.999%)</td>
                      </tr>
                      <tr>
                        <td>
                          <div
                            style={{
                              width: '150px',
                              height: '150px',
                              backgroundColor: 'green',
                            }}
                          />
                        </td>
                        <td>4 (99.99%)</td>
                      </tr>
                      <tr>
                        <td>
                          <div
                            style={{
                              width: '150px',
                              height: '150px',
                              background: 'linear-gradient(green, lightgreen)',
                            }}
                          />
                        </td>
                        <td>3 (99.9%)</td>
                      </tr>
                      <tr>
                        <td>
                          <div
                            style={{
                              width: '150px',
                              height: '150px',
                              background: 'linear-gradient(lightgreen, yellow)',
                            }}
                          />
                        </td>
                        <td>2 (99%)</td>
                      </tr>
                      <tr>
                        <td>
                          <div
                            style={{
                              width: '150px',
                              height: '150px',
                              background:
                                'linear-gradient(yellow 0%, red 100%)',
                            }}
                          />
                        </td>
                        <td>1 (90%)</td>
                      </tr>
                      <tr>
                        <td>
                          <div
                            style={{
                              width: '150px',
                              height: '150px',
                              backgroundColor: 'red',
                            }}
                          />
                        </td>
                        <td>0 (0%)</td>
                      </tr>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <tr>
                        <td>
                          <div
                            style={{
                              width: '150px',
                              height: '150px',
                              backgroundColor: 'red',
                            }}
                          />
                        </td>
                        <td>1000</td>
                      </tr>
                      <tr>
                        <td>
                          <div
                            style={{
                              width: '150px',
                              height: '150px',
                              backgroundColor: 'red',
                            }}
                          />
                        </td>
                        <td>100</td>
                      </tr>
                      <tr>
                        <td>
                          <div
                            style={{
                              width: '150px',
                              height: '150px',
                              backgroundColor: 'yellow',
                            }}
                          />
                        </td>
                        <td>10</td>
                      </tr>
                      <tr>
                        <td>
                          <div
                            style={{
                              width: '150px',
                              height: '150px',
                              background: 'linear-gradient(yellow, lightgreen)',
                            }}
                          />
                        </td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>
                          <div
                            style={{
                              width: '150px',
                              height: '150px',
                              background: 'linear-gradient(lightgreen, green)',
                            }}
                          />
                        </td>
                        <td>0.1</td>
                      </tr>
                      <tr>
                        <td>
                          <div
                            style={{
                              width: '150px',
                              height: '150px',
                              background: 'green',
                            }}
                          />
                        </td>
                        <td>0.01</td>
                      </tr>
                      <tr>
                        <td>
                          <div
                            style={{
                              width: '150px',
                              height: '150px',
                              backgroundColor: 'green',
                            }}
                          />
                        </td>
                        <td>0.001</td>
                      </tr>
                    </React.Fragment>
                  )}
                </tbody>
              </table>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {props.isDownload && (
        <div style={{ height: single ? '1100px' : '650px' }}>
          {props.showFirst && <img src="/SafeTraces_Logo.png" alt="logo" />}
        </div>
      )}

      <div className="img-overlay-wrap">
        {props.activeFloorPlanUrl && (
          <div
            style={{
              width: activeFPWidth + 130 + 'px',
              height: activeFPHeight + forceHeight + 'px',
            }}
          >
            <div
              style={{
                width: activeFPWidth + 'px',
                height: activeFPHeight + 'px',
                overflow: 'hidden',
                position: 'relative',
                marginTop: configIndicator.imagePadding + forceHeight + 'px',
              }}
            >
              <img
                style={{
                  width: imageShift.width + 'px',
                  height: imageShift.height + 'px',
                  left: imageShift.left + 'px',
                  top: imageShift.top - configIndicator.imagePadding + 'px',
                  position: 'relative',
                }}
                alt="HeatMap"
                src={props.overwriteImage ? props.overwriteImage : croppedImage}
                id="activeFP"
                onLoad={getImageSize}
              />
            </div>
          </div>
        )}
        {activeFPWidth && activeFPHeight && (
          <svg
            style={{
              width: activeFPWidth + 130 + 'px',
              height: activeFPHeight + 'px',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'top left',
              overflow: 'visible',
              fontSize: '16px',
            }}
          >
            {!verificationLikeBehavior && props.showLegend && (
              <defs>
                <linearGradient id="gradient" gradientTransform="rotate(90)">
                  {imgGradientConfigColors[spDataType].map((color, i) => (
                    <stop
                      key={color + i}
                      offset={`${
                        (i * 100) /
                        (imgGradientConfigColors[spDataType].length - 1)
                      }%`}
                      stopColor={color}
                    />
                  ))}
                </linearGradient>
              </defs>
            )}
            {(props.resultsData.testtype === TEST_TYPES.DILUTION ||
              props.resultsData.testtype === TEST_TYPES.RECIRCULATION) && (
              <text
                x={configIndicator.dTextX + 40}
                y={configIndicator.dTextY + forceHeight + 160}
                fill="black"
                fontFamily="Arial"
                fontSize={'3.5em'}
              >
                Interval: {props.interval}
              </text>
            )}
            {props.isDownload && props.showFirst && (
              <g>
                <text
                  x={configIndicator.dTextX + 40}
                  y={
                    configIndicator.dTextY -
                    (single ? 90 : 0) +
                    breadcrumbHeight +
                    calculatedResultHeight
                  }
                  fill="black"
                  fontFamily="Arial"
                  fontSize={'3.5em'}
                >
                  Test: {props.resultsData.testid}, Sample type:&nbsp;
                  {props.resultsData.sampletype ??
                    props.resultsData.collectionmethod}
                  {props.resultsData.scenarioid !== null &&
                    props.resultsData.testtype !== TEST_TYPES.VERIFICATION && (
                      <tspan x={configIndicator.dTextX + 45} y={75}>
                        Scenario: {props.resultsData.scenarioid} |&nbsp;
                        {props.resultsData.scenarioname}
                      </tspan>
                    )}
                </text>
                {!!breadcrumbArray &&
                  breadcrumbArray.map((item, index) => (
                    <text
                      x={
                        configIndicator.dTextX +
                        configIndicator.sTextX -
                        (single ? configIndicator.sTextXs : 0)
                      }
                      y={
                        configIndicator.dTextY +
                        configIndicator.sTextY -
                        (single ? configIndicator.sTextYs : 0) +
                        index * configIndicator.perLineStringHeight
                      }
                      fill="black"
                      fontFamily="Arial"
                      fontSize={'4.5em'}
                      key={index}
                    >
                      {item}
                    </text>
                  ))}

                {!!calculatedResultArray && !verificationLikeBehavior && (
                  <g>
                    <text
                      x={
                        configIndicator.dTextX +
                        configIndicator.sTextX -
                        (single ? configIndicator.sTextXs : 0)
                      }
                      y={
                        configIndicator.dTextY +
                        configIndicator.sTextY -
                        (single ? configIndicator.sTextYs : 0) +
                        breadcrumbHeight +
                        160
                      }
                      fill="black"
                      fontFamily="Arial"
                      fontSize={'3.5em'}
                    >
                      Calculated Result:
                    </text>
                    {!!calculatedResultArray &&
                      calculatedResultArray.map((item, index) => (
                        <text
                          x={
                            configIndicator.dTextX +
                            configIndicator.sTextX -
                            (single ? configIndicator.sTextXs : 0) +
                            480
                          }
                          y={
                            configIndicator.dTextY +
                            configIndicator.sTextY -
                            (single ? configIndicator.sTextYs : 0) +
                            breadcrumbHeight +
                            index * configIndicator.perLineStringHeight +
                            160
                          }
                          fill="black"
                          fontFamily="Arial"
                          fontSize={'3.5em'}
                          key={index}
                        >
                          {item}
                        </text>
                      ))}
                  </g>
                )}
                {!!commentArray && (
                  <g>
                    <text
                      x={
                        configIndicator.dTextX +
                        configIndicator.sTextX -
                        (single ? configIndicator.sTextXs : 0)
                      }
                      y={
                        configIndicator.dTextY +
                        configIndicator.sTextY -
                        (single ? configIndicator.sTextYs - 510 : -380) +
                        breadcrumbHeight +
                        calculatedResultHeight
                      }
                      fill="black"
                      fontFamily="Arial"
                      fontSize={'3.5em'}
                    >
                      Comment:
                    </text>
                    {!!commentArray &&
                      commentArray.map((item, index) => (
                        <text
                          x={single ? 500 : 2250}
                          y={
                            (single ? configIndicator.sTextYs - 140 : 20) +
                            index * configIndicator.perLineStringHeight +
                            breadcrumbHeight +
                            calculatedResultHeight
                          }
                          fill="black"
                          fontFamily="Arial"
                          fontSize={'3.5em'}
                          key={index}
                        >
                          {item}
                        </text>
                      ))}
                  </g>
                )}
                <text
                  x={
                    configIndicator.dTextX +
                    configIndicator.sTextX -
                    (single ? configIndicator.sTextXs : 0)
                  }
                  y={
                    configIndicator.dTextY +
                    configIndicator.sTextY -
                    (single ? configIndicator.sTextYs : 0) +
                    breadcrumbHeight +
                    calculatedResultHeight +
                    260
                  }
                  fill="black"
                  fontFamily="Arial"
                  fontSize={'3.5em'}
                >
                  {props.resultsData.testtype !== TEST_TYPES.VERIFICATION
                    ? 'Significance:'
                    : 'Result: '}
                </text>
                <rect
                  x={
                    configIndicator.dTextX +
                    configIndicator.sTextX -
                    (single ? configIndicator.sTextXs : 0) +
                    350
                  }
                  y={
                    configIndicator.dTextY +
                    configIndicator.sTextY -
                    (single ? configIndicator.sTextYs : 0) +
                    breadcrumbHeight +
                    calculatedResultHeight +
                    165
                  }
                  width="120"
                  height="120"
                  fill={getSignificanceColor(props.significance)}
                />
              </g>
            )}
            {!!props.heatMap &&
              !props.overwriteImage &&
              props.heatMap.sp.map((sp, sIndex) => (
                <g key={`key-` + sp.id + `-` + sIndex}>
                  <rect
                    x={
                      parseFloat(getCoordX(sp.spcoordx)) -
                      spCircleValue / props.mapValue.scale
                    }
                    y={
                      parseFloat(getCoordY(sp.spcoordy)) -
                      (spCircleValue * 0.5) / props.mapValue.scale
                    }
                    rx={(0.1 * spCircleValue) / props.mapValue.scale}
                    ry={(0.1 * spCircleValue) / props.mapValue.scale}
                    width={(spCircleValue * 2) / props.mapValue.scale}
                    height={spCircleValue / props.mapValue.scale}
                    fill={getColorByValue(
                      spDataType === 'reductionLog'
                        ? sp.reduction
                        : sp.copiesPerMillion,
                      sp.result,
                    )}
                    fillOpacity="0.8"
                  />
                  <rect
                    x={
                      parseFloat(getCoordX(sp.spcoordx)) -
                      (spCircleValue * 0.8) / props.mapValue.scale
                    }
                    y={
                      parseFloat(getCoordY(sp.spcoordy)) -
                      (spCircleValue * 0.5 * 0.8) / props.mapValue.scale
                    }
                    width={(spCircleValue * 2 * 0.8) / props.mapValue.scale}
                    height={(spCircleValue * 0.8) / props.mapValue.scale}
                    fill="rgb(255, 255, 255)"
                    fillOpacity="0.8"
                    stroke="black"
                    strokeWidth={1 / props.mapValue.scale}
                  />
                  <text
                    x={parseFloat(getCoordX(sp.spcoordx))}
                    y={
                      parseFloat(getCoordY(sp.spcoordy)) +
                      2 / props.mapValue.scale
                    }
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    fontFamily="Arial"
                    fontSize={
                      !verificationLikeBehavior
                        ? 1 / props.mapValue.scale + 'em'
                        : 0.8 / props.mapValue.scale + 'em'
                    }
                  >
                    {getSpDisplayValue(
                      sp.reduction,
                      sp.result && sp.result.displayValue,
                      sp.copiesPerMillionDisplay,
                    )}
                  </text>
                  <circle
                    className={'circleForHover'}
                    cx={parseFloat(getCoordX(sp.spcoordx))}
                    cy={parseFloat(getCoordY(sp.spcoordy))}
                    r={40}
                    fillOpacity="0"
                  />
                  <g
                    className={
                      (props.isDownload || props.showSpOpValue
                        ? 'displayBlock'
                        : 'displayNone') + ' tooltip'
                    }
                  >
                    <rect
                      x={
                        parseFloat(getCoordX(sp.spcoordx)) -
                        20 / props.mapValue.scale
                      }
                      y={
                        parseFloat(getCoordY(sp.spcoordy)) +
                        ((17 / 1.5) * 2) / props.mapValue.scale
                      }
                      width={42 / props.mapValue.scale}
                      height={16 / props.mapValue.scale}
                      fill="lightblue"
                      fillOpacity="0.85"
                      stroke="black"
                      strokeWidth={1 / props.mapValue.scale}
                    />
                    <text
                      x={parseFloat(getCoordX(sp.spcoordx))}
                      y={
                        parseFloat(getCoordY(sp.spcoordy)) +
                        ((26 / 1.4) * 1.66) / props.mapValue.scale
                      }
                      textAnchor="middle"
                      alignmentBaseline="middle"
                      fontFamily="Arial"
                      fontSize={0.7 / props.mapValue.scale + 'em'}
                    >
                      {sp.spnumber}
                    </text>
                  </g>
                </g>
              ))}
            {!!props.heatMap &&
              !props.overwriteImage &&
              props.heatMap.op.map((op, oIndex) => (
                <g key={`key-` + op.id + `-` + oIndex}>
                  <circle
                    cx={parseFloat(getOPCoordX(op.opcoordx))}
                    cy={parseFloat(getOPCoordY(op.opcoordy))}
                    r={10 / props.mapValue.scale}
                    fill={
                      !verificationLikeBehavior
                        ? 'rgb(255, 0, 0)'
                        : 'rgb(0, 0, 255)'
                    }
                    fillOpacity="0.8"
                    stroke="black"
                    strokeWidth="1"
                  />
                  <circle
                    className={'circleForHover'}
                    cx={parseFloat(getOPCoordX(op.opcoordx))}
                    cy={parseFloat(getOPCoordY(op.opcoordy))}
                    r={40}
                    fillOpacity="0"
                  />
                  <g
                    className={
                      (props.isDownload || props.showSpOpValue
                        ? 'displayBlock'
                        : 'displayNone') + ' tooltip'
                    }
                  >
                    <rect
                      x={
                        parseFloat(getOPCoordX(op.opcoordx)) -
                        22 / props.mapValue.scale
                      }
                      y={
                        parseFloat(getOPCoordY(op.opcoordy)) +
                        (12 * 2) / props.mapValue.scale
                      }
                      width={45 / props.mapValue.scale}
                      height={16 / props.mapValue.scale}
                      fill="lightblue"
                      fillOpacity="0.85"
                      stroke="black"
                      strokeWidth={1 / props.mapValue.scale}
                    />
                    <text
                      x={parseFloat(getOPCoordX(op.opcoordx))}
                      y={
                        parseFloat(getOPCoordY(op.opcoordy)) +
                        (21 * 1.58) / props.mapValue.scale
                      }
                      textAnchor="middle"
                      alignmentBaseline="middle"
                      fontFamily="Arial"
                      fontSize={0.7 / props.mapValue.scale + 'em'}
                    >
                      {op.opnumber}
                    </text>
                  </g>
                </g>
              ))}
            {!!props.showLast && !verificationLikeBehavior && props.showLegend && (
              <g>
                {spDataType === 'reductionLog' ? (
                  <React.Fragment>
                    <rect
                      x={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x1
                      }
                      y={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.rectY -
                        configIndicator.y
                      }
                      width="120"
                      height="560"
                      fill="url(#gradient)"
                    />
                    <line
                      x1={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x1
                      }
                      y1={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        configIndicator.lineYheight
                      }
                      x2={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x2
                      }
                      y2={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        configIndicator.lineYheight
                      }
                      stroke="white"
                      strokeOpacity="0.3"
                    />
                    <text
                      x={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.globalTextX
                      }
                      y={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        configIndicator.lineYheight
                      }
                      fontFamily="Arial"
                      alignmentBaseline="middle"
                      fontSize={configIndicator.fontSize}
                    >
                      6 (99.9999%)
                    </text>
                    <line
                      x1={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x1
                      }
                      y1={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        2 * configIndicator.lineYheight
                      }
                      x2={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x2
                      }
                      y2={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        2 * configIndicator.lineYheight
                      }
                      stroke="white"
                      strokeOpacity="10.3"
                    />
                    <text
                      x={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.globalTextX
                      }
                      y={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        2 * configIndicator.lineYheight
                      }
                      fontFamily="Arial"
                      alignmentBaseline="middle"
                      fontSize={configIndicator.fontSize}
                    >
                      5 (99.999%)
                    </text>
                    <line
                      x1={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x1
                      }
                      y1={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        3 * configIndicator.lineYheight
                      }
                      x2={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x2
                      }
                      y2={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        3 * configIndicator.lineYheight
                      }
                      stroke="white"
                      strokeOpacity="10.3"
                    />
                    <text
                      x={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.globalTextX
                      }
                      y={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        3 * configIndicator.lineYheight
                      }
                      fontFamily="Arial"
                      alignmentBaseline="middle"
                      fontSize={configIndicator.fontSize}
                    >
                      4 (99.99%)
                    </text>
                    <line
                      x1={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x1
                      }
                      y1={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        4 * configIndicator.lineYheight
                      }
                      x2={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x2
                      }
                      y2={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        4 * configIndicator.lineYheight
                      }
                      stroke="white"
                      strokeOpacity="10.3"
                    />
                    <text
                      x={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.globalTextX
                      }
                      y={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        4 * configIndicator.lineYheight
                      }
                      fontFamily="Arial"
                      alignmentBaseline="middle"
                      fontSize={configIndicator.fontSize}
                    >
                      3 (99.9%)
                    </text>
                    <line
                      x1={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x1
                      }
                      y1={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        5 * configIndicator.lineYheight
                      }
                      x2={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x2
                      }
                      y2={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        5 * configIndicator.lineYheight
                      }
                      stroke="white"
                      strokeOpacity="10.3"
                    />
                    <text
                      x={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.globalTextX
                      }
                      y={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        5 * configIndicator.lineYheight
                      }
                      fontFamily="Arial"
                      alignmentBaseline="middle"
                      fontSize={configIndicator.fontSize}
                    >
                      2 (99%)
                    </text>
                    <line
                      x1={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x1
                      }
                      y1={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        6 * configIndicator.lineYheight
                      }
                      x2={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x2
                      }
                      y2={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        6 * configIndicator.lineYheight
                      }
                      stroke="white"
                      strokeOpacity="10.3"
                    />
                    <text
                      x={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.globalTextX
                      }
                      y={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        6 * configIndicator.lineYheight
                      }
                      fontFamily="Arial"
                      alignmentBaseline="middle"
                      fontSize={configIndicator.fontSize}
                    >
                      1 (90%)
                    </text>
                    <text
                      x={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.globalTextX
                      }
                      y={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        7 * configIndicator.lineYheight
                      }
                      fontFamily="Arial"
                      alignmentBaseline="middle"
                      fontSize={configIndicator.fontSize}
                    >
                      0 (0%)
                    </text>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <rect
                      x={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x1
                      }
                      y={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.rectY -
                        configIndicator.y
                      }
                      width="120"
                      height={(560 * 8) / 7}
                      fill="url(#gradient)"
                    />
                    <line
                      x1={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x1
                      }
                      y1={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        configIndicator.lineYheight
                      }
                      x2={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x2
                      }
                      y2={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        configIndicator.lineYheight
                      }
                      stroke="white"
                      strokeOpacity="0.3"
                    />
                    <text
                      x={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.globalTextX
                      }
                      y={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        configIndicator.lineYheight
                      }
                      fontFamily="Arial"
                      alignmentBaseline="middle"
                      fontSize={configIndicator.fontSize}
                    >
                      1000
                    </text>
                    <line
                      x1={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x1
                      }
                      y1={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        2 * configIndicator.lineYheight
                      }
                      x2={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x2
                      }
                      y2={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        2 * configIndicator.lineYheight
                      }
                      stroke="white"
                      strokeOpacity="10.3"
                    />
                    <text
                      x={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.globalTextX
                      }
                      y={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        2 * configIndicator.lineYheight
                      }
                      fontFamily="Arial"
                      alignmentBaseline="middle"
                      fontSize={configIndicator.fontSize}
                    >
                      100
                    </text>
                    <line
                      x1={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x1
                      }
                      y1={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        3 * configIndicator.lineYheight
                      }
                      x2={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x2
                      }
                      y2={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        3 * configIndicator.lineYheight
                      }
                      stroke="white"
                      strokeOpacity="10.3"
                    />
                    <text
                      x={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.globalTextX
                      }
                      y={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        3 * configIndicator.lineYheight
                      }
                      fontFamily="Arial"
                      alignmentBaseline="middle"
                      fontSize={configIndicator.fontSize}
                    >
                      10
                    </text>
                    <line
                      x1={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x1
                      }
                      y1={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        4 * configIndicator.lineYheight
                      }
                      x2={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x2
                      }
                      y2={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        4 * configIndicator.lineYheight
                      }
                      stroke="white"
                      strokeOpacity="10.3"
                    />
                    <text
                      x={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.globalTextX
                      }
                      y={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        4 * configIndicator.lineYheight
                      }
                      fontFamily="Arial"
                      alignmentBaseline="middle"
                      fontSize={configIndicator.fontSize}
                    >
                      1
                    </text>
                    <line
                      x1={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x1
                      }
                      y1={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        5 * configIndicator.lineYheight
                      }
                      x2={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x2
                      }
                      y2={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        5 * configIndicator.lineYheight
                      }
                      stroke="white"
                      strokeOpacity="10.3"
                    />
                    <text
                      x={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.globalTextX
                      }
                      y={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        5 * configIndicator.lineYheight
                      }
                      fontFamily="Arial"
                      alignmentBaseline="middle"
                      fontSize={configIndicator.fontSize}
                    >
                      0.1
                    </text>
                    <line
                      x1={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x1
                      }
                      y1={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        6 * configIndicator.lineYheight
                      }
                      x2={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.x2
                      }
                      y2={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        6 * configIndicator.lineYheight
                      }
                      stroke="white"
                      strokeOpacity="10.3"
                    />
                    <text
                      x={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.globalTextX
                      }
                      y={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        6 * configIndicator.lineYheight
                      }
                      fontFamily="Arial"
                      alignmentBaseline="middle"
                      fontSize={configIndicator.fontSize}
                    >
                      0.01
                    </text>
                    <text
                      x={
                        activeFPWidth -
                        configIndicator.globalXshift -
                        configIndicator.globalTextX
                      }
                      y={
                        activeFPHeight -
                        configIndicator.globalYshift -
                        configIndicator.y +
                        configIndicator.lineStart +
                        7 * configIndicator.lineYheight
                      }
                      fontFamily="Arial"
                      alignmentBaseline="middle"
                      fontSize={configIndicator.fontSize}
                    >
                      0.001
                    </text>
                  </React.Fragment>
                )}
                <text
                  x={activeFPWidth - configIndicator.globalXshift - 250}
                  y={
                    activeFPHeight -
                    configIndicator.globalYshift -
                    185 -
                    configIndicator.y
                  }
                  fontFamily="Arial"
                  fontSize={configIndicator.fontSize}
                >
                  {spDataType === 'reductionLog'
                    ? 'Indicator Tag'
                    : 'DNA Copies per'}
                </text>
                <text
                  x={activeFPWidth - configIndicator.globalXshift - 250}
                  y={
                    activeFPHeight -
                    configIndicator.globalYshift -
                    145 -
                    configIndicator.y
                  }
                  fontFamily="Arial"
                  fontSize={configIndicator.fontSize}
                >
                  <tspan>
                    {spDataType === 'reductionLog'
                      ? 'Reduction Log'
                      : 'million emitted'}
                  </tspan>
                  {spDataType === 'reductionLog' ? (
                    <tspan
                      baselineShift="sub"
                      fontSize={configIndicator.fontSize}
                    >
                      10
                    </tspan>
                  ) : null}
                </text>
              </g>
            )}
          </svg>
        )}
        <Box sx={{ width: '1px', height: '1px', overflow: 'hidden' }}>
          <img
            alt="HeatMap"
            src={props.activeFloorPlanUrl}
            id="activeFP1"
            onLoad={cropImage}
          />
          <img src="/SafeTraces_Logo.png" alt="logo" />
        </Box>
      </div>
    </>
  );
}
