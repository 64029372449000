import React, { useEffect, useState } from 'react';

import contracts from '../../Api/contracts';
import { useAuthState } from '../../Context';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FeedIcon from '@mui/icons-material/Feed';
import PrintIcon from '@mui/icons-material/Print';
import { ListItemSecondaryAction, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';

export default function Contracts() {
  const authState = useAuthState();
  const [contractsData, setContractsData] = useState([]);

  const fetchContracts = async () => {
    const data = await contracts.getAllContracts();
    setContractsData(data.data.data.contracts);
  };

  const accept = () => {
    alert('accpet');
  };

  const download = () => {
    alert('download');
  };

  const print = () => {
    alert('print');
  };

  useEffect(() => {
    fetchContracts();
  }, [authState.viewCompanyId]);

  const ContractsWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  }));

  return (
    <>
      <ContractsWrapper>
        <div style={{ width: '100%' }}>
          <Typography variant="h6">
            Another professionally written text goes here to let the customer
            know the contract is prepared based on their portfolio documents
            with all the tests listed
          </Typography>
          <List component="div">
            {contractsData.map((contract) => (
              <ListItem key={contract.contractid}>
                <ListItemIcon>
                  <FeedIcon />
                </ListItemIcon>
                <ListItemText primary={contract.contractname} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    sx={{ mr: 1 }}
                    onClick={accept}
                  >
                    <CheckCircleIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    sx={{ mr: 1 }}
                    onClick={download}
                  >
                    <CloudDownloadIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" onClick={print}>
                    <PrintIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
      </ContractsWrapper>
    </>
  );
}
