import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useFloorplanContext } from '../context';

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import floorplansApi from 'Api/floorPlans';

import { useIsMounted } from 'Context';

import { isValidResponse } from 'Utils';

const HDACreateModal = ({ refetchHDAData }) => {
  const mounted = useIsMounted();
  const formRef = useRef(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const {
    HDACreateMetadata,
    setHDACreateMetadata,
    HDAtypesByApplication,
    floorplanId,
  } = useFloorplanContext();
  const [actionsDisabled, setActionsDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const HDAtypes = Object.values(HDAtypesByApplication).reduce((all, curr) => {
    return Object.assign(all, curr.spaceTypes);
  }, {});

  const onSubmit = async (values) => {
    try {
      setActionsDisabled(true);
      const { x, y } = HDACreateMetadata;
      const bodyObj = {
        x,
        y,
        name: values.name,
        type: values.type,
      };

      const response = await floorplansApi.createHDA(floorplanId, bodyObj);
      if (isValidResponse(response)) {
        refetchHDAData();
        setErrorMsg(null);
        handleClose();
      } else {
        setErrorMsg(`Could not create HDA`);
      }
    } catch (err) {
      setErrorMsg(`Could not create HDA`);
      console.log('Error: ', err);
    } finally {
      if (mounted.current) {
        setActionsDisabled(false);
      }
    }
  };

  const handleClose = () => {
    reset();
    setHDACreateMetadata(null);
  };

  return (
    <Dialog
      open={!!HDACreateMetadata?.x}
      transitionDuration={220}
      disableEscapeKeyDown
      PaperProps={{
        sx: {
          p: 1,
          pb: 2,
          justifyContent: 'center',
          alignItems: 'center',
          width: '420px',
        },
      }}
    >
      <DialogTitle>Create HDA</DialogTitle>
      <DialogContent
        sx={{
          width: '100%',
          mb: 2,
        }}
      >
        <Box sx={{ width: '100%' }}>
          {!!errorMsg ? <Alert severity="error">{errorMsg}</Alert> : null}
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  key="name"
                  variant="standard"
                  margin="dense"
                  fullWidth={true}
                  label="Name"
                  {...register('name', {
                    required: `Name is required`,
                    setValueAs: (v) => v && v.trim(),
                  })}
                  inputProps={{ id: 'name' }}
                  error={!!(errors && errors.name)}
                  helperText={errors?.name?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel
                    id="hda-type-label"
                    variant="standard"
                    error={!!errors?.type?.message}
                  >
                    Type
                  </InputLabel>
                  <Select
                    key="type"
                    sx={{ width: '100%' }}
                    variant="standard"
                    margin="dense"
                    {...register('type', {
                      required: `Type is required`,
                      setValueAs: (v) => v && v.trim(),
                    })}
                    defaultValue={''}
                    inputProps={{ id: 'type' }}
                    error={!!(errors && errors.type)}
                  >
                    {HDAtypes
                      ? Object.entries(HDAtypes).map((hdaType, i) => (
                          <MenuItem
                            key={`${i}-${hdaType[0]}`}
                            value={hdaType[0]}
                          >
                            {hdaType[1]}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                  <FormHelperText error={true} variant="standard">
                    {errors?.type?.message || ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between', width: '70%' }}>
        <Button
          style={{ width: '100%', marginRight: '8px' }}
          variant="outlined"
          onClick={handleClose}
          disabled={actionsDisabled}
        >
          Cancel
        </Button>
        <Button
          style={{ width: '100%' }}
          variant="contained"
          disabled={actionsDisabled}
          onClick={() => formRef.current?.requestSubmit()}
          endIcon={
            actionsDisabled ? (
              <CircularProgress color="inherit" size={'1rem'} />
            ) : null
          }
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HDACreateModal;
