import React, { useRef, useState } from 'react';

import FloorplanDetailsForm from '../Forms/FloorplanDetailsForm';

import { Button, CircularProgress, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useIsMounted } from 'Context';

const FloorplanEditModal = ({ open, file, handleClose, handleSubmit }) => {
  const mounted = useIsMounted();
  const formRef = useRef(null);

  const [actionsDisabled, setActionsDisabled] = useState(false);

  const onSubmit = async (values) => {
    try {
      setActionsDisabled(true);

      await handleSubmit(values);
    } catch (err) {
      console.log('Error: ', err);
    } finally {
      if (mounted.current) {
        setActionsDisabled(false);
      }
    }
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      onClose={handleClose}
      PaperProps={{
        sx: {
          padding: 2,
          justifyContent: 'center',
          alignItems: 'center',
          width: '540px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          whiteSpace: 'nowrap',
          maxWidth: '80%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {file?.name}
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" alignItems="center">
          <FloorplanDetailsForm
            ref={formRef}
            defaultValues={file || {}}
            onSubmit={onSubmit}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: '60%', mt: 4 }}
            gap={3}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              disabled={actionsDisabled}
              sx={{ width: '100%' }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ width: '100%' }}
              disabled={actionsDisabled}
              onClick={() => formRef.current?.requestSubmit()}
              endIcon={
                actionsDisabled ? (
                  <CircularProgress color="inherit" size={'1rem'} />
                ) : null
              }
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(FloorplanEditModal);
