import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_PAGE_TITLE = 'veriDART Portal';

function startLoading(state) {
  state.isLoading = true;
}

function finishLoading(state) {
  state.isLoading = false;
}

export const slice = createSlice({
  name: 'main',
  initialState: {
    companies: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    setStartLoading: startLoading,
    setFinishLoading: finishLoading,
    setCompanies: (state, action) => {
      state.isLoading = false;
      state.companies = action.payload;
    },
    setNotError: (state) => {
      state.error = null;
    },
    setPageTitle: (state, action) => {
      if (typeof action.payload !== 'string') {
        document.title = DEFAULT_PAGE_TITLE;
        return;
      }
      document.title = `${action.payload} | ${DEFAULT_PAGE_TITLE}`;
    },
  },
});

export const { setPageTitle } = slice.actions;

export default slice.reducer;

export const mainSelector = (state) => state.main;
