import React, { useEffect, useState } from 'react';

import { cropImageFromURL } from '../utils';
import PlaceableOPItem from './PlaceableOPItem';
import PlaceableSPItem from './PlaceableSPItem';

import { Box, CircularProgress } from '@mui/material';

const FloorPlanImage = ({
  dropRef,
  imageData,
  boundingRef,
  OPs,
  SPs,
  setScaledDimensions,
  isImageLoaded,
  setIsImageLoaded,
  markerSize,
}) => {
  const [OPsInner, setOPsInner] = useState(OPs);
  const [SPsInner, setSPsInner] = useState(SPs);
  const [actualImageURL, setActiveImageUrl] = useState('');

  useEffect(() => {
    if (imageData?.cropData) {
      cropImageFromURL(imageData.url, imageData.cropData, setActiveImageUrl);
    } else if (imageData?.url) {
      setActiveImageUrl(imageData.url);
    }
  }, [imageData?.url]);

  useEffect(() => {
    setOPsInner(OPs);
    setSPsInner(SPs);
  }, [OPs, SPs, OPsInner, SPsInner]);

  const resizeHandler = () => {
    if (boundingRef.current) {
      setScaledDimensions({
        width: boundingRef.current.getBoundingClientRect().width,
        height: boundingRef.current.getBoundingClientRect().height,
      });
    }
  };

  useEffect(() => {
    resizeHandler();
  }, [imageData]);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const renderScaledPoints = () => {
    if (isImageLoaded) {
      return (
        <>
          {OPs.map((op) => (
            <PlaceableOPItem key={op.opid} op={op} markerSize={markerSize} />
          ))}
          {SPs.map((sp) => (
            <PlaceableSPItem key={sp.spid} sp={sp} markerSize={markerSize} />
          ))}
        </>
      );
    } else {
      return <CircularProgress />;
    }
  };

  return (
    <Box position="relative">
      <img
        src={actualImageURL}
        alt="active floorplan"
        width="100%"
        height="100%"
        onLoad={() => {
          if (boundingRef.current) {
            setIsImageLoaded(true);
            setTimeout(resizeHandler, 500);
          }
        }}
        style={{
          visibility: isImageLoaded ? 'visible' : 'hidden',
        }}
        ref={boundingRef}
      />
      <Box
        ref={dropRef}
        position="absolute"
        className={'drop-area'}
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={50}
      >
        {renderScaledPoints()}
      </Box>
    </Box>
  );
};

export default FloorPlanImage;
