import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useForm } from 'react-hook-form';

import FloorplanHDAComponent from './HDA/FloorplanHDAComponent';
import HDACreateModal from './HDA/HDACreateModal';
import HDAitem from './HDA/HDAitem';
import { useFloorplanContext } from './context';

import { Check } from '@mui/icons-material';
import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import floorplans from 'Api/floorPlans';

import DeleteDialog from 'Components/Dialogs/DeleteDialog';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  isValidResponse,
} from 'Utils';

const HDAPlacement = () => {
  const markerSize = 3;
  const {
    floorplanId,
    setError,
    HDAtypesByApplication,
    HDAToDelete,
    setHDAToDelete,
    setDeleteHDADialogOpen,
    deleteHDADialogOpen,
    setIsLoading,
    isLoading,
  } = useFloorplanContext();
  const {
    register,
    formState: { errors },
    trigger,
    clearErrors,
  } = useForm({
    reValidateMode: 'onChange',
  });
  const [HDAs, setHDAs] = useState([]);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [updateStatus, setUpdateStatus] = useState('idle');

  const [massAddApplicationType, setMassAddApplicationType] = useState(null);
  const [massAddHDAType, setMassAddHDAType] = useState(null);

  const [hdaTypesInFp, setHdaTypesInFp] = useState([]);

  useEffect(() => {
    if (floorplanId) {
      fetchHDAdata();
    }
  }, [floorplanId]);

  const fetchHDAdata = async (_floorplanId) => {
    try {
      const hdaTypesInFloorplan = [];
      const hdaResp = await floorplans.getFloorplanHDA(
        floorplanId || _floorplanId,
      );
      if (isValidResponse(hdaResp)) {
        const hdasData = getDataFromResponse(hdaResp);
        for (let { type } of hdasData) {
          if (!hdaTypesInFloorplan.includes(type)) {
            hdaTypesInFloorplan.push(type);
          }
        }
        const arrOfTypes = Object.values(HDAtypesByApplication).reduce(
          (curr, arr) => {
            curr.push(...Object.keys(arr.spaceTypes));
            return curr;
          },
          [],
        );
        hdaTypesInFloorplan.sort(
          (a, b) => arrOfTypes.indexOf(a) - arrOfTypes.indexOf(b),
        );

        setHdaTypesInFp(hdaTypesInFloorplan);
        setHDAs(hdasData);
      } else {
        setHdaTypesInFp([]);
        setError('Error fetching the HDA data');
      }
    } catch (e) {
      setHdaTypesInFp([]);
      console.error(e);
      setError('Error fetching the HDA data, check the console for details');
    }
  };

  const handleDeleteHDA = async () => {
    setIsLoading(true);
    try {
      const hdaResp = await floorplans.deleteHDA(HDAToDelete.id);
      if (isValidResponse(hdaResp)) {
        fetchHDAdata();
        setDeleteHDADialogOpen(false);
        setTimeout(() => setHDAToDelete(null), 200);
      } else {
        throw new Error(getErrorMessageFromResponse(hdaResp));
      }
    } catch (e) {
      console.error(e);
      setError('Error deleting the HDA data, check the console for details');
    } finally {
      setIsLoading(false);
    }
  };

  const renderStatus = () => {
    return (
      <Box sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
        {updateStatus === 'loading' && <CircularProgress size="small" />}
        {updateStatus === 'success' && <Check color="green" />}
      </Box>
    );
  };

  const beforeHDACreation = async () => {
    trigger('application_type', { shouldFocus: true }).then((res) => {
      if (res) {
        trigger('space_type', { shouldFocus: true });
      }
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <HDACreateModal refetchHDAData={fetchHDAdata} />
      <DeleteDialog
        open={deleteHDADialogOpen}
        handleClose={() => {
          setDeleteHDADialogOpen(false);
          setTimeout(() => setHDAToDelete(null), 200);
        }}
        title="Delete HDA"
        itemName={HDAToDelete?.name}
        handleDelete={handleDeleteHDA}
        isLoading={isLoading}
      />
      <Alert color="info" icon={false}>
        <Typography>
          To generate a single HDA select application type, space type and click
          on the desired position on the floorplan.
        </Typography>
      </Alert>
      <Stack sx={{ mt: 2, mb: 2 }} spacing={2} flexDirection="column">
        <Stack direction="row" spacing={2} sx={{ height: '100px' }}>
          <FormControl variant="standard" fullWidth>
            <TextField
              select
              variant="standard"
              value={massAddApplicationType || ''}
              error={!!errors.application_type}
              helperText={errors.application_type?.message}
              label="Application type"
              {...register('application_type', {
                required: 'Application type is required',
              })}
              disabled={Object.entries(HDAtypesByApplication).length === 0}
              onChange={(e) => {
                clearErrors('application_type');
                setMassAddApplicationType(e.target.value);
                setMassAddHDAType(null);
              }}
            >
              {Object.entries(HDAtypesByApplication).map((entry) => (
                <MenuItem value={entry[0]} key={entry[0]}>
                  {entry[1].applicationName}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <FormControl variant="standard" fullWidth>
            <TextField
              select
              variant="standard"
              value={massAddHDAType || ''}
              label="Space type"
              error={!!errors.space_type}
              helperText={errors.space_type?.message}
              {...register('space_type', {
                required: 'Space type is required',
              })}
              onChange={(e) => {
                clearErrors('space_type');
                setMassAddHDAType(e.target.value);
              }}
              disabled={
                Object.entries(HDAtypesByApplication).length === 0 ||
                !massAddApplicationType
              }
            >
              {massAddApplicationType ? (
                Object.entries(
                  HDAtypesByApplication[massAddApplicationType].spaceTypes,
                ).map((entry) => (
                  <MenuItem value={entry[0]} key={entry[0]}>
                    {entry[1]}
                  </MenuItem>
                ))
              ) : (
                <MenuItem />
              )}
            </TextField>
          </FormControl>
        </Stack>
      </Stack>
      <Stack flexDirection="row">
        <Stack
          spacing={3}
          sx={{ maxHeight: '700px', overflowY: 'scroll', width: '35%' }}
        >
          <Box sx={{ mt: 2 }}>
            <Stack direction="row">
              <Typography variant="h5">HDA list</Typography>
              {renderStatus()}
            </Stack>
            <Stack spacing={1} sx={{ mt: 2 }}>
              {HDAs.map((hda) => (
                <HDAitem key={hda.id} hda={hda} reFetch={fetchHDAdata} />
              ))}
            </Stack>
          </Box>
        </Stack>
        <FloorplanHDAComponent
          HDAs={HDAs}
          refetchHDA={fetchHDAdata}
          hdaTypesInFp={hdaTypesInFp}
          markerSize={markerSize}
          isImageLoaded={isImageLoaded}
          setIsImageLoaded={setIsImageLoaded}
          updateStatus={updateStatus}
          setUpdateStatus={setUpdateStatus}
          hdaToCreateSpaceType={massAddHDAType}
          beforeHDACreation={beforeHDACreation}
          clearErrors={() => {
            clearErrors('application_type');
            clearErrors('space_type');
          }}
        />
      </Stack>
    </DndProvider>
  );
};

export default HDAPlacement;
