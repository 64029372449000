import React, { useEffect, useRef, useState } from 'react';

import {
  Pagination,
  Stack,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';

import NestedTableTag from 'Components/Tags/NestedTableTag';

export const TagList = ({
  users,
  tags,
  fetchTags,
  fetchTagProperties,
  setAlertMsg,
  setAlertOpen,
}) => {
  const [page, setPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [filter, setFilter] = useState('');

  const headers = [
    '',
    '',
    '',
    'Delete Tag',
    'Name',
    'Plate Kind',
    'Slope',
    'Intercept',
    'Lot Number',
    'Manufacture Date (Bulk saliva)',
    'Expiration Date (Bulk saliva)',
    'New Baseline',
  ];

  const handleFilter = (event) => {
    setFilter(event.target.value);
    // Reset to default page
    setPage(1);
  };

  const filterTag = (tag) => {
    if (!filter) {
      return true;
    }

    if (tag.taglotnumber === filter) {
      return true;
    }

    let tagName = tag.tag.toLowerCase();

    // True if found / False if not found
    return tagName.indexOf(filter.toLowerCase()) !== -1;
  };

  const handlePageChange = (e, page) => {
    setPage(page);
  };

  const handleNumberOfPages = (tags, itemsPerPg) => {
    let pages = Math.ceil(
      tags.filter((tag) => {
        return filterTag(tag);
      }).length / itemsPerPg,
    );
    setNumberOfPages(pages);
  };

  const firstUpdate = useRef(true);
  useEffect(() => {
    handleNumberOfPages(tags, 10);
    firstUpdate.current = false;
  }, [tags]);

  const tagSort = (a, b) => {
    const isSameTagName = a.tag === b.tag;
    if (isSameTagName) {
      return a.tagid > b.tagid ? 1 : -1;
    } else {
      return a.tag > b.tag ? 1 : -1;
    }
  };

  return (
    <Stack>
      <TextField
        id="filterTags"
        label="Search by Name or Lot Number"
        name="filterTags"
        style={{ fontFamily: 'Gotham', width: '100%' }}
        onChange={(e) => handleFilter(e)}
      />
      <TableContainer mt="15px">
        <Table aria-label="tags-list-table">
          <TableHead>
            <TableRow>
              {headers.map((header, i) => (
                <TableCell key={`header-${i}`}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tags
              .sort((a, b) => tagSort(a, b))
              .filter((tag) => {
                return filterTag(tag);
              })
              .slice((page - 1) * 10, page * 10)
              .map((tag, i) => (
                <NestedTableTag
                  key={'tag-' + i}
                  tag={tag}
                  users={users}
                  fetchTags={fetchTags}
                  fetchTagProperties={fetchTagProperties}
                  setAlertMsg={setAlertMsg}
                  setAlertOpen={setAlertOpen}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        style={{
          alignSelf: 'center',
          marginTop: '20px',
          marginBottom: '20px',
        }}
        count={numberOfPages}
        page={page}
        defaultPage={1}
        onChange={handlePageChange}
        showFirstButton
        showLastButton
      />
    </Stack>
  );
};
