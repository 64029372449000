import {
  ERROR_AUTOCLOSE_TIMEOUT_MS,
  HEALTHCARE_TEST_TYPES,
  HEALTHCARE_TEST_TYPES_LABELS,
  PROJECT_STATUSES,
  PROJECT_STATUS_WORKFLOW,
  TEST_TYPES,
  TEST_TYPES_LABELS,
} from 'Constants';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Link,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
  Typography,
} from '@mui/material';

import samplePlans from 'Api/samplePlans';
import tests from 'Api/tests';

import ConfirmDialog from 'Components/Dialogs/ConfirmDialog';
import { ScenariosTableSingleProjectSingleTestCollapse } from 'Components/Scenarios';
import {
  DeleteTestBtn,
  EditTestDescriptionBtn,
  EditTestNameBtn,
} from 'Components/SingleProject/Main/Buttons';
import { useProjectContext } from 'Components/SingleProject/context';
import TableEmptyDataDisplay from 'Components/UI/TableEmptyDataDisplay';

import { isSafetracesAdmin, isViewer } from 'Config/roles';
import { ROUTE_PROJECT_TEST_DETAILS } from 'Config/routes';

import { useIsMounted } from 'Context';

import { projectDataSelector } from 'Features/Projects/projectsSlice';

import { StyledTableCell, StyledTableRow } from 'Layout/layoutStyles';

import { getErrorMessageFromResponse, isValidResponse } from 'Utils';

function ScenariosInTestCollapsible({ test, open }) {
  const { fetchProject, refetchProjectTags } = useProjectContext();
  const { permissions, projectStatus, loadingTestId, isProjectTestsLoading } =
    useSelector(projectDataSelector);
  const { canEditTests } = permissions;

  return (
    <StyledTableRow>
      <TableCell
        style={{
          paddingBottom: 0,
          paddingTop: 0,
          paddingRight: 0,
          paddingLeft: '80px',
        }}
        colSpan={9}
        align="left"
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box
            sx={
              Object.values(HEALTHCARE_TEST_TYPES).includes(test.testtype)
                ? {}
                : { margin: 1 }
            }
          >
            {!Object.values(HEALTHCARE_TEST_TYPES).includes(test.testtype) ? (
              <Typography variant="h6" gutterBottom component="div">
                Scenarios
              </Typography>
            ) : null}
            <ScenariosTableSingleProjectSingleTestCollapse
              test={test}
              reFetchProject={fetchProject}
              refetchProjectTags={refetchProjectTags}
              projectStatus={projectStatus}
              editable={
                canEditTests &&
                test.testexecuted !== true &&
                !Object.values(HEALTHCARE_TEST_TYPES).includes(test.testtype)
              }
              disableActions={
                loadingTestId === test.testid || isProjectTestsLoading
              }
            />
          </Box>
        </Collapse>
      </TableCell>
    </StyledTableRow>
  );
}

function TableCellContentsSkeleton({ wrapInSkeleton, align, children }) {
  return (
    <StyledTableCell align={align}>
      {wrapInSkeleton ? (
        <Skeleton sx={{ margin: 'auto' }}>
          <span>{children}</span>
        </Skeleton>
      ) : (
        children
      )}
    </StyledTableCell>
  );
}

function TestRow({ test, testExecutionHandler, loading, showSkeleton }) {
  const [open, setOpen] = useState(false);
  const {
    testAmountChangeHandler,
    updateTestsOrderAndCreateSamples,
    fetchProjectTest,
  } = useProjectContext();
  const {
    projectId,
    projectStatus,
    projectTests,
    loadingTestId,
    isProjectTestsLoading,
  } = useSelector(projectDataSelector);

  useEffect(() => {
    setOpen(false);
  }, [showSkeleton]);

  const canEditTestDescription =
    isSafetracesAdmin() ||
    (PROJECT_STATUS_WORKFLOW.indexOf(projectStatus) <
      PROJECT_STATUS_WORKFLOW.indexOf(PROJECT_STATUSES.PUBLISHED) &&
      !test.testexecuted);
  const canEditTestName =
    isSafetracesAdmin() ||
    (PROJECT_STATUS_WORKFLOW.indexOf(projectStatus) <
      PROJECT_STATUS_WORKFLOW.indexOf(PROJECT_STATUSES.PUBLISHED) &&
      !test.testexecuted);

  const testHasScenarios = test.scnrs.length > 0;

  const navigate = useNavigate();

  const handleClick = (event, testId) => {
    event.preventDefault();

    const _path = generatePath(ROUTE_PROJECT_TEST_DETAILS, {
      projectId,
      testId,
    });

    /** This is for opening it in new tab through command + click or ctrl + click */
    if (event.metaKey || event.ctrlKey) {
      window.open(_path);
      return;
    }
    navigate(_path);
  };

  const getTestTypeLabel = (test) => {
    const { testtype } = test;

    if (testtype === TEST_TYPES.GENERAL) {
      return test.SP > 12
        ? TEST_TYPES_LABELS[TEST_TYPES.LARGE_SURVEY]
        : TEST_TYPES_LABELS[TEST_TYPES.SMALL_SURVEY];
    } else
      return (
        TEST_TYPES_LABELS[testtype] || HEALTHCARE_TEST_TYPES_LABELS[testtype]
      );
  };

  const testNameCellContents = () => {
    return (
      <React.Fragment>
        {canEditTestName ? (
          <EditTestNameBtn
            projectId={projectId}
            afterEdit={() => fetchProjectTest(test.testid)}
            testId={test.testid}
            testName={test.testname}
            disabled={loading}
          />
        ) : null}
        <Link
          href={`/projects/${projectId}/${test.testid}`}
          onClick={(event) => handleClick(event, test.testid)}
        >
          {test.testname}
        </Link>
      </React.Fragment>
    );
  };

  const testDescriptionCellContents = () => {
    return (
      <React.Fragment>
        {canEditTestDescription ? (
          <EditTestDescriptionBtn
            projectId={projectId}
            testId={test.testid}
            testDescription={test.overview}
            disabled={
              loading || isProjectTestsLoading || loadingTestId === test.testid
            }
            afterEdit={() => fetchProjectTest(test.testid)}
          />
        ) : null}
        <span>{test.overview}</span>
      </React.Fragment>
    );
  };

  const afterDelete = async () => {
    if (!Array.isArray(projectTests) || !projectTests.length) return;

    const tests = [...projectTests];
    const testToUpdate = tests
      .filter((t) => t.testid !== test.testid)
      .map((test, i) => {
        return {
          id: test.testid,
          name: test.testname,
          order: i + 1,
        };
      });
    updateTestsOrderAndCreateSamples(testToUpdate, tests).then(() => {
      testAmountChangeHandler();
    });
  };

  return (
    <React.Fragment>
      <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <StyledTableCell>
          {testHasScenarios && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              disabled={loading || showSkeleton}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </StyledTableCell>
        <TableCellContentsSkeleton wrapInSkeleton={showSkeleton} align="center">
          {test.orderNumber}
        </TableCellContentsSkeleton>
        <TableCellContentsSkeleton wrapInSkeleton={showSkeleton} align="center">
          {test.testid}
        </TableCellContentsSkeleton>
        <TableCellContentsSkeleton wrapInSkeleton={showSkeleton} align="left">
          {getTestTypeLabel(test)}
        </TableCellContentsSkeleton>
        <TableCellContentsSkeleton wrapInSkeleton={showSkeleton} align="left">
          {testNameCellContents()}
        </TableCellContentsSkeleton>
        <TableCellContentsSkeleton wrapInSkeleton={showSkeleton} align="left">
          {testDescriptionCellContents()}
        </TableCellContentsSkeleton>
        <StyledTableCell align="center">
          {!isViewer() && test.hasplans && (
            <Checkbox
              checked={test.testexecuted}
              onChange={testExecutionHandler}
              value={test.testexecuted}
              color={
                !test.scnrs.length
                  ? 'primary'
                  : test.scnrs.reduce((acc, scn) => acc + scn.executed, 0) ===
                    test.scnrs.length
                  ? 'primary'
                  : 'warning'
              }
              disabled={
                showSkeleton ||
                loading ||
                PROJECT_STATUS_WORKFLOW.indexOf(projectStatus) >=
                  PROJECT_STATUS_WORKFLOW.indexOf(PROJECT_STATUSES.PUBLISHED) ||
                PROJECT_STATUS_WORKFLOW.indexOf(projectStatus) <
                  PROJECT_STATUS_WORKFLOW.indexOf(
                    PROJECT_STATUSES.EXECUTION_READY,
                  ) ||
                (test.testexecuted && !isSafetracesAdmin())
              }
            />
          )}
          {!isViewer() && !test.hasplans && (
            <Tooltip
              title="Incomplete Test details"
              aria-label="Incomplete Test details"
            >
              <WarningIcon color="warning" />
            </Tooltip>
          )}
        </StyledTableCell>
        <StyledTableCell align="center">
          {!isViewer() && (
            <DeleteTestBtn
              disabled={
                showSkeleton ||
                loadingTestId === test.testid ||
                !projectStatus ||
                PROJECT_STATUS_WORKFLOW.indexOf(projectStatus) >=
                  PROJECT_STATUS_WORKFLOW.indexOf(
                    PROJECT_STATUSES.EXECUTION_READY,
                  ) ||
                test.testexecuted
              }
              disabledReason={'You cannot delete test'}
              testName={`${test.testname}`}
              testId={test.testid}
              projectId={projectId}
              afterDelete={afterDelete}
            />
          )}
        </StyledTableCell>
      </StyledTableRow>
      {testHasScenarios && (
        <ScenariosInTestCollapsible
          open={open}
          test={test}
          disableActions={loading || showSkeleton}
        />
      )}
    </React.Fragment>
  );
}

export default function ProjectTestsTable({ disableActions }) {
  const { fetchProjectTest, setMainError } = useProjectContext();
  const {
    projectTests,
    projectDetails,
    initialLoading,
    isProjectTestsLoading,
    loadingTestId,
    projectId,
  } = useSelector(projectDataSelector);
  const mounted = useIsMounted();
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [testExecutionConfirmDialogConf, setTestExecutionConfirmDialogConf] =
    useState(null);

  if (initialLoading) {
    return (
      <React.Fragment>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </React.Fragment>
    );
  }

  const handleTestExecuted = (testid, executed) => async () => {
    setSaveInProgress(true);
    let isSuccess = false;

    const newCompletionStatus = executed !== true;
    const data = { testCompleted: newCompletionStatus };

    try {
      const res = await tests.updateOneTest(testid, data);
      if (!isValidResponse(res)) {
        throw new Error(getErrorMessageFromResponse(res));
      }
      isSuccess = true;
      await samplePlans.removeSamplePlan(projectId);
    } catch (err) {
      console.log('Set test as completed error: ', err);
      setMainError(err?.message || `Couldn't set test as completed`);
      setTimeout(() => setMainError(''), ERROR_AUTOCLOSE_TIMEOUT_MS);
    } finally {
      fetchProjectTest(testid);
      if (mounted.current) {
        setSaveInProgress(false);
      }
      if (isSuccess) {
        setTestExecutionConfirmDialogConf(null);
      }
    }
  };

  return (
    <TableContainer component={Paper}>
      <ConfirmDialog
        open={!!testExecutionConfirmDialogConf}
        handleConfirm={handleTestExecuted(
          testExecutionConfirmDialogConf?.id,
          true,
        )}
        handleClose={() => setTestExecutionConfirmDialogConf(null)}
        action={`set test as not completed (please note that this action will also mark all associated scenarios as incomplete)`}
        isLoading={saveInProgress}
      />
      <Table aria-label={`Table with tests in project ${projectDetails.name}`}>
        <HeaderProjectTestsTable />
        <TableBody>
          {!isProjectTestsLoading && !projectTests.length ? (
            <TableEmptyDataDisplay columns={8} height={140} />
          ) : null}
          {projectTests.map((test) => (
            <TestRow
              key={test.testid}
              test={test}
              showSkeleton={isProjectTestsLoading}
              loading={
                saveInProgress ||
                isProjectTestsLoading ||
                loadingTestId === test.testid ||
                disableActions
              }
              testExecutionHandler={
                test.testexecuted && test.scnrs.length > 0
                  ? () =>
                      setTestExecutionConfirmDialogConf({
                        id: test.testid,
                      })
                  : handleTestExecuted(test.testid, test.testexecuted)
              }
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function HeaderProjectTestsTable() {
  const projectTestsTableHeaderCells = [
    { content: '', align: 'center' },
    {
      content: (
        <span>
          Test
          <br />
          Number
        </span>
      ),
      align: 'center',
    },
    { content: 'TestId', align: 'center' },
    { content: 'Type', align: 'left' },
    { content: 'Name', align: 'left' },
    { content: 'Description', align: 'left' },
    { content: 'Done', align: 'center' },
    { content: 'Actions', align: 'center' },
  ];

  return (
    <TableHead>
      <StyledTableRow>
        {projectTestsTableHeaderCells.map(({ content, align }, index) => (
          <StyledTableCell key={index} align={align}>
            {content}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}
