import React from 'react';

import { useTestScenariosContext } from '../context';

import DeleteDialog from 'Components/Dialogs/DeleteDialog';

export default function ResetTagModal() {
  const testScenariosContext = useTestScenariosContext();

  if (!testScenariosContext) {
    console.error(`Component must be used inside TestScenariosContext`);
    return null;
  }

  const {
    forceRestrictAllEdits,
    resetTagMeta,
    cancelResetTagScenarioProcess,
    handleTagReset,
  } = testScenariosContext;

  return (
    <DeleteDialog
      type="reset"
      itemName="tag"
      open={!!resetTagMeta?.scenarioId}
      handleClose={cancelResetTagScenarioProcess}
      handleDelete={handleTagReset}
      title="Reset tag"
      isLoading={forceRestrictAllEdits}
    />
  );
}
