import React from 'react';

import '../styles.css';

import BuildIcon from '@mui/icons-material/Build';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';

import { isViewer } from 'Config/roles';

const CalculatorDataListItem = ({
  data,
  handleEdit,
  handleDelete,
  isPartnerEditor,
}) => {
  const dataKeysLabels = {
    primary: {
      floorServed: 'Floor(s): ',
      servedArea: 'Area: ',
      servedCeilingHeight: 'Ceiling: ',
      filterMervRating: 'MERV: ',
    },
    secondary: {
      totalSupplyAir: 'Supply Air: ',
      totalOutdoorAir: 'Outdoor Air: ',
      airDistributionEffectiveness: 'Zone Air Distribution Effectiveness: ',
    },
  };

  const getText = (type = 'primary') => {
    let result = '';
    if (type === 'primary' && data.name) {
      result = `${data.name}. `;
    }

    const labelsToMap = dataKeysLabels[type];

    return (
      result +
      Object.keys(labelsToMap)
        .reduce((res, key) => {
          res.push(labelsToMap[key] + data[key]);

          return res;
        }, [])
        .join(', ')
    );
  };

  return (
    <ListItem sx={{ pl: 0 }}>
      <ListItemIcon>
        <BuildIcon sx={{ fontSize: '1.3em' }} />
      </ListItemIcon>
      <ListItemText
        sx={{ pr: '96px ' }}
        primary={getText()}
        secondary={getText('secondary')}
      />
      {!isPartnerEditor && !isViewer() && (
        <ListItemSecondaryAction>
          <IconButton
            size="small"
            color="primary"
            onClick={handleEdit}
            sx={{ mr: 1 }}
          >
            <EditIcon />
          </IconButton>
          <IconButton size="small" color="error" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default CalculatorDataListItem;
