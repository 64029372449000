import React from 'react';
import { useNavigate } from 'react-router-dom';

import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Button,
  Link,
  List,
  ListItem,
  Skeleton,
  Stack,
} from '@mui/material';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

import BreadcrumbsDashboard from 'Components/Breadcrumbs/BreadcrumbsDashboard';
import { useDashboardContext } from 'Components/Dashboard/context';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    width: 360,
    height: '60vh',
    overflowX: 'hidden',
    overflowY: 'scroll',
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    padding: theme.spacing(2),
    borderTop: '0px solid rgba(0, 0, 0, .125)',
    '* > *': {
      alignItems: 'start !important',
    },
    li: {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: 0,
      paddingBottom: 0,
      '&:last-child:not(:first-of-type)': {
        paddingBottom: 0,
      },
      '&:first-of-type:not(:last-of-type)': {
        paddingBottom: 0,
      },
    },
    ul: {
      paddingRight: 0,
      '&:last-child': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
}));

const getItemStyle = (selected) => {
  if (!selected) {
    return { color: 'rgb(0, 0, 0, 0.65)' };
  }

  return {
    position: 'relative',
    right: 'calc(1rem + 4px)',
    display: 'flex',
  };
};

function SinglePortfolioNav({ portfolioId, buildings, closeNavigation }) {
  const context = useDashboardContext();
  const navigate = useNavigate();
  const { getEntityLabel, getEntityPath } = context;

  const selectedPortfolio = portfolioId === +context.portfolioId;

  return (
    <ListItem>
      <Link
        underline="none"
        component="button"
        variant="body1"
        style={getItemStyle(selectedPortfolio)}
        onClick={() => {
          navigate(getEntityPath('portfolio', portfolioId));
          closeNavigation();
        }}
      >
        {selectedPortfolio && (
          <DoubleArrowIcon
            sx={{ fontSize: '1em', marginRight: '4px', my: 'auto' }}
          />
        )}
        <span>{getEntityLabel('portfolio', portfolioId)}</span>
      </Link>
      <List>
        {Object.keys(buildings).map((buildingId) => (
          <ListItem key={buildingId}>
            <Link
              underline="none"
              component="button"
              variant="body1"
              style={getItemStyle(+buildingId === +context.buildingId)}
              onClick={() => {
                navigate(getEntityPath('building', +buildingId));
                closeNavigation();
              }}
            >
              {+buildingId === +context.buildingId && (
                <DoubleArrowIcon
                  sx={{ fontSize: '1em', marginRight: '4px', my: 'auto' }}
                />
              )}
              {getEntityLabel('building', buildingId)}
            </Link>
          </ListItem>
        ))}
      </List>
    </ListItem>
  );
}

function NavigationListMenu() {
  const { navigationData, navigationDataIsLoading } = useDashboardContext();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (navigationDataIsLoading) {
    return <Skeleton />;
  }

  return (
    <Stack direction="row">
      <Button
        id="dashboard-navigation-button"
        aria-controls={open ? 'dashboard-navigation-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        disableElevation
        onClick={handleClick}
        size="small"
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ mr: 1, textTransform: 'unset', fontSize: '1em' }}
      >
        Quick navigation
      </Button>
      <BreadcrumbsDashboard />
      <StyledMenu
        id="dashboard-navigation-menu"
        MenuListProps={{
          'aria-labelledby': 'dashboard-navigation-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box>
          <List>
            {Object.keys(navigationData).map((portfolioId) => (
              <SinglePortfolioNav
                key={portfolioId}
                portfolioId={+portfolioId}
                buildings={navigationData[portfolioId].buildings}
                closeNavigation={handleClose}
              />
            ))}
          </List>
        </Box>
      </StyledMenu>
    </Stack>
  );
}

export default React.memo(NavigationListMenu);
