import React, { useRef } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import AddTestForm from 'Components/SingleProject/Main/Forms/AddTestForm';

export default function AddTestModal({
  open,
  handleClose,
  handleSubmit,
  transitionDuration = 220,
  actionsDisabled,
  hasVerification,
  hasHealthcare,
  buildings,
}) {
  const testFormRef = useRef(null);

  return (
    <div>
      <Dialog
        open={open}
        transitionDuration={transitionDuration}
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            p: 2,
            pt: 1,
            justifyContent: 'center',
            alignItems: 'center',
            width: '440px',
          },
        }}
      >
        <DialogTitle>Add test</DialogTitle>
        <DialogContent sx={{ width: '100%' }}>
          <AddTestForm
            ref={testFormRef}
            onSubmit={(values) => handleSubmit(values)}
            hasVerification={hasVerification}
            hasHealthcare={hasHealthcare}
            buildings={buildings}
          />
        </DialogContent>
        <DialogActions
          style={{ justifyContent: 'space-between', width: '60%' }}
        >
          <Button
            style={{ width: '100%', marginRight: '8px' }}
            variant="outlined"
            onClick={handleClose}
            disabled={actionsDisabled}
          >
            Cancel
          </Button>
          <Button
            style={{ width: '100%' }}
            variant="contained"
            disabled={actionsDisabled}
            onClick={() => testFormRef.current?.requestSubmit()}
            endIcon={
              actionsDisabled ? (
                <CircularProgress color="inherit" size={'1rem'} />
              ) : null
            }
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
