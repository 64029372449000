import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import Edit from '@mui/icons-material/Edit';
import { Button, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export default function CheckBorderButton({
  clickAction,
  sx,
  disabled = false,
  tooltipText = '',
  text = null,
  variant = 'outlined',
  extraBtnProps = {},
}) {
  return (
    <Tooltip title={tooltipText} placement="top">
      <span>
        <Button
          disabled={disabled}
          variant={variant}
          sx={{ ...sx }}
          onClick={clickAction}
          startIcon={
            !disabled ? (
              <CheckIcon />
            ) : (
              <CircularProgress color="inherit" size={'1rem'} />
            )
          }
          {...extraBtnProps}
        >
          {text ? text : <Edit />}
        </Button>
      </span>
    </Tooltip>
  );
}
