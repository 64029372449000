import { FILTER_TEST_TYPES_LABELS, HEALTHCARE_TEST_TYPES } from 'Constants';
import React, { useEffect, useState } from 'react';

import SignificanceOptions from './SignificanceOptions';

import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

import { StyledCheckedIcon } from 'Layout/layoutStyles';

import { copyColorsFilterObj } from 'Utils';

function SignificanceTypeMenu({ filterColors, handleFilterColors, disabled }) {
  const [checkedObj, setCheckedObj] = useState();

  useEffect(() => {
    const newCheckedObj = copyColorsFilterObj(filterColors);
    let atLeastOneNotChecked = false;
    for (let testType of Object.keys(filterColors)) {
      const { colors } = newCheckedObj[testType];
      newCheckedObj[testType].atLeastOneChecked = Object.values(colors).reduce(
        (res, curr) => res || curr,
        false,
      );
      atLeastOneNotChecked =
        atLeastOneNotChecked ||
        Object.values(colors).reduce(
          (res, curr) => res || !curr,
          atLeastOneNotChecked,
        );
    }

    setCheckedObj(newCheckedObj);
  }, [filterColors]);

  const handleTestTypeChange = (testType, value) => {
    const newCheckedObj = copyColorsFilterObj(checkedObj);
    const colorsTestType = newCheckedObj[testType].colors;

    newCheckedObj[testType].atLeastOneChecked = value;
    for (let color of Object.keys(colorsTestType)) {
      colorsTestType[color] = value;
    }

    handleFilterChange(newCheckedObj);
  };

  const getTestTypeCheckboxValue = (testType) => {
    try {
      return checkedObj[testType].atLeastOneChecked;
    } catch (err) {
      return false;
    }
  };

  const handleColorChange = (values, testType) => {
    const newCheckedObj = copyColorsFilterObj(checkedObj);
    newCheckedObj[testType].colors = values;
    const atLeastOneChecked = Object.values(values).reduce(
      (res, curr) => res || curr,
      false,
    );

    newCheckedObj[testType].atLeastOneChecked = atLeastOneChecked;
    handleFilterChange(newCheckedObj);
  };

  const handleFilterChange = (filterObj) => {
    setCheckedObj(filterObj);
    handleFilterColors(filterObj);
  };

  if (!checkedObj) return;

  return (
    <div style={{ overflowY: 'scroll', overflowX: 'hidden', height: '60vh' }}>
      {Object.keys(FILTER_TEST_TYPES_LABELS).map((testType, i) => (
        <span key={i}>
          <MenuItem
            onClick={() =>
              handleTestTypeChange(
                testType,
                !getTestTypeCheckboxValue(testType),
              )
            }
            key={testType}
            value={testType}
            label={FILTER_TEST_TYPES_LABELS[testType]}
            disabled={disabled}
          >
            <Checkbox
              checked={getTestTypeCheckboxValue(testType) || false}
              value={getTestTypeCheckboxValue(testType) || false}
              onChange={(e) => handleTestTypeChange(testType, e.target.checked)}
              disableTouchRipple
              style={{ paddingLeft: 0 }}
              checkedIcon={<StyledCheckedIcon checked />}
              icon={<StyledCheckedIcon />}
            />
            <ListItemText
              primary={FILTER_TEST_TYPES_LABELS[testType]}
              sx={{ mr: 6 }}
            />
          </MenuItem>
          <Divider
            light
            style={{ width: '80%', marginLeft: 'auto', marginTop: 0 }}
          />
          {!Object.values(HEALTHCARE_TEST_TYPES).includes(testType) ? (
            <SignificanceOptions
              width={85}
              onChange={(values) => handleColorChange(values, testType)}
              values={checkedObj[testType].colors}
              testType={testType}
              disabled={disabled}
            />
          ) : null}
        </span>
      ))}
    </div>
  );
}

export default SignificanceTypeMenu;
