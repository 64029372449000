import { HEALTHCARE_TEST_TYPES, HEALTHCARE_TEST_TYPES_LABELS } from 'Constants';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import buildingsApi from 'Api/buildings';
import contractsApi from 'Api/contracts';

import { subtractTests, sumTests } from 'Components/TestPackages/utils';

import { isSafetracesAdmin } from 'Config/roles';
import { ROUTE_TEST_PACKAGES } from 'Config/routes';

import { useAuthState } from 'Context';

import { getSafetraceSetCompanyName, isValidResponse } from 'Utils';

const TestPackageItemsContext = createContext({});

export const TestPackageItemsContextProvider = ({ children }) => {
  const { siteContractId } = useParams();
  const navigate = useNavigate();
  const [testPackages, setTestPackages] = useState([]);
  const [siteContract, setSiteContract] = useState({});
  const [startProject, setStartProject] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [mainError, setMainError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedTestPackageList, setSelectedTestPackageList] = useState({});
  const [openCreateProjectDialog, setOpenCreateProjectDialog] = useState(false);
  const [canAddTestPackages, setCanAddTestPackages] = useState(false);
  const [availableTests, setAvailableTests] = useState({});
  const [canCancelAnyProject, setCanCancelAnyProject] = useState(false);

  //#region Auth checks
  const authState = useAuthState();
  useEffect(() => {
    setCanCancelAnyProject(checkIfCanCancelAnyProject());
  }, [authState.viewCompanyId]);

  /**
   * Checks if user is an ST Admin and LS set company name is the same as the one
   * set in the env config
   * @returns {boolean}
   */
  const checkIfCanCancelAnyProject = () => {
    const companyNamesAllowedCancelAny =
      typeof process.env.REACT_APP_TRAINING_COMPANY_NAME === 'string'
        ? process.env.REACT_APP_TRAINING_COMPANY_NAME.replace(
            /%20/g,
            ` `,
          ).toLowerCase()
        : null;

    const companyNamesArr = companyNamesAllowedCancelAny.split('&');
    const lsCompanyName = getSafetraceSetCompanyName().toLowerCase();
    const isTrainingCompany = companyNamesArr.includes(lsCompanyName);

    return isSafetracesAdmin() && isTrainingCompany;
  };
  //#endregion

  //#region Test package lifecycle methods (fetching etc.)
  const fetchTestPackages = async () => {
    const response = await contractsApi.getTestPackages(siteContractId);
    if (isValidResponse(response)) {
      setTestPackages(response.data.data);
    } else {
      setMainError(response.data?.error?.message?.toString());
    }
  };

  const fetchSiteContract = async () => {
    const response = await contractsApi.getSiteContract(siteContractId);
    if (isValidResponse(response)) {
      const siteContract = response.data.data;
      setSiteContract(siteContract);
    } else {
      setMainError(response.data?.error?.message.toString());
    }
  };

  const fetchBuildings = async () => {
    const response = await buildingsApi.getSiteContractBuildings(
      siteContractId,
    );
    if (isValidResponse(response)) {
      setBuildings(response.data.data);
    } else {
      setMainError(response.data?.error?.message.toString());
    }
  };

  const loadAllData = async () => {
    try {
      setIsLoading(true);
      await Promise.allSettled([
        fetchTestPackages(),
        fetchSiteContract(),
        fetchBuildings(),
      ]);
    } catch (e) {
      console.error(e.toString());
      setMainError(
        'Error fetching data about the site contract. Check the logs for details.',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const initialLoadData = async () => {
    try {
      setIsInitialLoading(true);
      await loadAllData();
    } catch (e) {
      console.error(e.toString());
      setMainError(
        'Error fetching data about the site contract. Check the logs for details.',
      );
    } finally {
      setIsInitialLoading(false);
    }
  };

  useEffect(() => {
    if (!siteContractId) {
      navigate(ROUTE_TEST_PACKAGES);
    } else {
      initialLoadData();
    }
  }, []);

  useEffect(() => {
    const testPackageTotalSum = sumTests(testPackages);
    const testAvailable = subtractTests(
      siteContract?.testsmetadata ?? siteContract,
      testPackageTotalSum,
    );

    // TODO: remove code that relates to partial access for good
    // when we'll be sure that we don't need it anymore
    const hasAccessToAllTests = true; // isSafetracesAdmin();
    let canCreateTestPackage = hasAccessToAllTests
      ? testAvailable.total > 0
      : false;
    if (!hasAccessToAllTests && testAvailable.total > 0) {
      canCreateTestPackage =
        Object.values(HEALTHCARE_TEST_TYPES).reduce(
          (sum, testKey) => (sum += testAvailable[testKey]),
          0,
        ) < testAvailable.total;
    }
    setCanAddTestPackages(canCreateTestPackage);
    setAvailableTests(testAvailable);
  }, [testPackages, siteContract]);

  //#endregion
  const handleAddToProject = (stateButton, testPackage) => {
    let newList = selectedTestPackageList;
    if (stateButton) {
      let listTests = [];
      const testPackageName = testPackage.testpackagename;
      const testsInfoObj = testPackage.testsmetadata || testPackage;
      if (testsInfoObj.smallsurveytests) {
        listTests.push({
          label: `Small Survey`,
          value: `${testPackageName} - Small Survey`,
          identifier: 'smallsurveytests',
          count: new Array(+testsInfoObj.smallsurveytests + 1)
            .join('0')
            .split(''),
        });
      }
      if (testsInfoObj.largesurveytests) {
        listTests.push({
          label: `Large Survey`,
          value: `${testPackageName} - Large Survey`,
          identifier: 'largesurveytests',
          count: new Array(+testsInfoObj.largesurveytests + 1)
            .join('0')
            .split(''),
        });
      }
      if (testsInfoObj.smalldilutiontests) {
        listTests.push({
          label: `Small Dilution`,
          value: `${testPackageName} - Small Dilution`,
          identifier: 'smalldilutiontests',
          count: new Array(+testsInfoObj.smalldilutiontests + 1)
            .join('0')
            .split(''),
        });
      }
      if (testsInfoObj.largedilutiontests) {
        listTests.push({
          label: `Large Dilution`,
          value: `${testPackageName} - Large Dilution`,
          identifier: 'largedilutiontests',
          count: new Array(+testsInfoObj.largedilutiontests + 1)
            .join('0')
            .split(''),
        });
      }
      if (testsInfoObj.minisurveytests) {
        listTests.push({
          label: `Min Survey`,
          value: `${testPackageName} - Min Survey`,
          identifier: 'minisurveytests',
          count: new Array(+testsInfoObj.minisurveytests + 1)
            .join('0')
            .split(''),
        });
      }
      if (testsInfoObj.recirculationtests) {
        listTests.push({
          label: `Flex`,
          value: `${testPackageName} - Flex`,
          identifier: 'recirculationtests',
          count: new Array(+testsInfoObj.recirculationtests + 1)
            .join('0')
            .split(''),
        });
      }
      if (testsInfoObj.verificationtests) {
        listTests.push({
          label: `Verification`,
          value: `${testPackageName} - Verification`,
          identifier: 'verificationtests',
          count: new Array(+testsInfoObj.verificationtests + 1)
            .join('0')
            .split(''),
        });
      }

      for (let anyHealthcareType of Object.values(HEALTHCARE_TEST_TYPES)) {
        if (testsInfoObj[anyHealthcareType]) {
          const label = HEALTHCARE_TEST_TYPES_LABELS[anyHealthcareType];
          listTests.push({
            label: label,
            value: `${testPackageName} - ${label}`,
            identifier: anyHealthcareType,
            count: new Array(+testsInfoObj[anyHealthcareType] + 1)
              .join('0')
              .split(''),
          });
        }
      }

      newList[testPackage.testpackageid] = {
        id: testPackage.testpackageid,
        buildingId: testPackage.buildingid,
        name: testPackageName,
        list: listTests,
      };
    } else {
      delete newList[testPackage.testpackageid];
    }
    const listIsNotEmpty = !!Object.keys(newList).length;
    setStartProject(listIsNotEmpty);
    setSelectedTestPackageList(newList);
  };

  return (
    <TestPackageItemsContext.Provider
      value={{
        testPackages,
        siteContract,
        startProject,
        buildings,
        isLoading,
        setIsLoading,
        mainError,
        setMainError,
        successMessage,
        setSuccessMessage,
        selectedTestPackageList,
        setSelectedTestPackageList,
        openCreateProjectDialog,
        setOpenCreateProjectDialog,
        canAddTestPackages,
        availableTests,
        setStartProject,
        handleAddToProject,
        forceAllowProjectCancel: canCancelAnyProject,
        loadAllData,
        setIsInitialLoading,
        isInitialLoading,
      }}
    >
      {children}
    </TestPackageItemsContext.Provider>
  );
};

export const useTestPackageItems = () => {
  const context = useContext(TestPackageItemsContext);
  if (context === undefined) {
    throw new Error(
      'useTestPackageItems must be used within a TestPackageItemsContextProvider',
    );
  }
  return context;
};
