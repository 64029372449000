import { HEALTHCARE_TEST_TYPES, TEST_TYPES } from 'Constants';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import common from 'Api/common';
import originpoints from 'Api/originpoints';
import projects from 'Api/projects';
import samplePoints from 'Api/samplePoints';
import scenarios from 'Api/scenarios';
import segments from 'Api/segments';
import tests from 'Api/tests';

import BreadcrumbForProjectTest from 'Components/Breadcrumbs/ProjectsBreadcrumbs/BreadcrumbsProjectTest';
import Title from 'Components/Mix/Title';
import {
  DilutionTest,
  GeneralSurveyTest,
  HealthcareTest,
  MiniSurveyTest,
  RecirculationTest,
  VerificationTest,
} from 'Components/SingleTest';
import EditTestDetailsModal from 'Components/SingleTest/Modals/EditTestDetailsModal';
import { TestContextProvider } from 'Components/SingleTest/context';
import ErrorMessage from 'Components/UI/ErrorMessage';
import Loading from 'Components/UI/Loading';

import { useIsMounted } from 'Context';

import { StyledTestHeader } from 'Layout/layoutStyles';

import { getErrorMessageFromResponse, isValidResponse } from 'Utils';

export default function SingleTest() {
  const [projectData, setProjectData] = useState(null);
  const [filteredTest, setFilteredTest] = useState(null);
  const [samplePointsList, setSamplePointsList] = useState([]);
  const [originPointsList, setOriginPointsList] = useState([]);
  const [segmentsList, setSegmentsList] = useState([]);
  const [segmentId, setSegmentId] = useState(null);
  const [segment, setSegment] = useState(null);
  const [scenariosList, setScenariosList] = useState([]);
  const [preparedScenariosList, setPreparedScenariosList] = useState(null);
  const [sampleType, setSampleType] = useState('');
  const [airSamplerRate, setAirSamplerRate] = useState('');
  const [platekind, setPlatekind] = useState('');
  const [sprayer, setSprayer] = useState('');
  const [surveydate, setSurveydate] = useState('');
  const [comments, setComments] = useState('');
  const [intervalCount, setIntervalCount] = useState('');
  const [intervalDuration, setIntervalDuration] = useState('');
  const [sprayers, setSprayers] = useState([]);
  const [testDisplayNumbers, setTestDisplayNumbers] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [mainError, setMainError] = useState('');
  const [floorPlanPlacementURL, setFloorPlanPlacementURL] = useState('');

  const { projectId, testId } = useParams();

  const mounted = useIsMounted();

  useEffect(() => {
    reFetchProject();
  }, []);
  useEffect(() => {
    fetchScenarios();
  }, [segmentsList]);

  useEffect(() => {
    if (scenariosList.length > 0 && originPointsList.length > 0) {
      const preparedScenarios = [];
      scenariosList.forEach((value) => {
        const tmp = value;
        const tags = originPointsList[0].tags;
        tags.forEach((value1) => {
          if (parseInt(value1.scenarioId) === parseInt(tmp.scenarioid)) {
            tmp.tag = value1.tag;
          }
        });
        tmp.lotNumber = '';
        const tagLotNumbers = originPointsList[0].tagLotNumber;
        if (tagLotNumbers) {
          tagLotNumbers.forEach((value1) => {
            if (value1.tag === tmp.tag) {
              tmp.lotNumber = value1.lotNumber;
            }
          });
        }
        tmp.OPs =
          originPointsList.length === 1
            ? originPointsList[0].opnumber
            : `${originPointsList[0].opnumber} - ${
                originPointsList[originPointsList.length - 1].opnumber
              }`;
        preparedScenarios.push(tmp);
      });
      setPreparedScenariosList(preparedScenarios);
    }
  }, [scenariosList, originPointsList]);

  useEffect(() => {
    setFloorPlanPlacementURL(
      composeFloorPlanPlacementURL({
        projectId,
        testId,
        buildingId: filteredTest?.buildingid,
      }),
    );
  }, [filteredTest, projectId, testId]);

  const reFetchProject = async () => {
    await Promise.all([
      fetchProject(),
      fetchSamplePoints(),
      fetchOriginPoints(),
      fetchSamplePlan(),
      fetchSprayers(),
      fetchTestDisplayNumbers(),
    ]);
  };

  const fetchTestDisplayNumbers = async () => {
    setIsLoading(true);
    try {
      const response = await tests.getDisplayNumbersProject(projectId);
      if (isValidResponse(response)) {
        setTestDisplayNumbers(response.data.data);
      } else {
        console.error(response);
        setMainError(getErrorMessageFromResponse(response));
      }
    } catch (e) {
      if (mounted.current) {
        setTestDisplayNumbers([]);
        setMainError(
          'Error while fetching test display numbers. Check the console for details',
        );
      }
      console.error(e.toString());
    } finally {
      if (mounted.current) {
        setIsLoading(false);
      }
    }
  };

  const fetchSprayers = async () => {
    const data = await common.getSprayers();
    setSprayers(data.data.data);
  };

  const fetchProject = async () => {
    setIsLoading(true);
    try {
      const statusUpdateResponse = await projects.autoUpdateStatus(projectId);
      if (!isValidResponse(statusUpdateResponse)) {
        throw new Error('Failed to update project status');
      }
      const response = await projects.getOneProject(projectId);
      if (isValidResponse(response)) {
        if (mounted.current) {
          setProjectData(response.data.data);
          const tests = response.data.data.tests;
          tests.forEach((value) => {
            if (value.testid === parseInt(testId)) {
              setFilteredTest(value);
            }
          });
        }
      }
    } catch (e) {
      if (mounted.current) {
        setProjectData(null);
      }
      console.log(e.toString());
    } finally {
      if (mounted.current) {
        setIsLoading(false);
      }
    }
  };

  const fetchSamplePoints = async () => {
    try {
      const response = await samplePoints.getAllSamplePointsForTest(testId);
      if (isValidResponse(response)) {
        if (mounted.current) {
          setSamplePointsList(response.data.data);
          setAirSamplerRate(response.data.data[0].airsamplerrate);
          setSampleType(response.data.data[0].collectionmethod);
        }
      }
    } catch (e) {
      if (mounted.current) {
        setSamplePointsList([]);
      }
      console.log(e.toString());
    }
  };

  const fetchOriginPoints = async () => {
    try {
      const response = await originpoints.getAllOriginPointsForTest(testId);
      if (isValidResponse(response)) {
        if (mounted.current) {
          setOriginPointsList(response.data.data);
        }
      }
    } catch (e) {
      if (mounted.current) {
        setOriginPointsList([]);
      }
      console.log(e.toString());
    }
  };

  const fetchScenarios = async () => {
    try {
      if (segmentsList.length > 0) {
        setSegmentId(segmentsList[0].segmentid);
        const response = await scenarios.getScenarios(
          segmentsList[0].segmentid,
        );
        if (isValidResponse(response)) {
          if (mounted.current) {
            setScenariosList(response.data.data);
          }
        }
      }
    } catch (e) {
      if (mounted.current) {
        setScenariosList([]);
      }
      console.log(e.toString());
    }
  };

  const fetchSamplePlan = async () => {
    try {
      const response = await segments.getSegments(testId);
      if (isValidResponse(response)) {
        if (mounted.current) {
          setSegmentsList(response.data.data);
          setPlatekind(
            response.data.data[0].platekind !== 'none'
              ? response.data.data[0].platekind
              : 'C',
          );
          setSprayer(response.data.data[0].sprayername);
          setSurveydate(response.data.data[0].surveydate);
          setComments(response.data.data[0].comments);
          setIntervalCount(response.data.data[0].intervalcount);
          setIntervalDuration(response.data.data[0].intervalduration);
          setSegment(response.data.data[0]);
        }
      }
    } catch (e) {
      if (mounted.current) {
        setSegmentsList([]);
      }
      console.log(e.toString());
    }
  };

  const composeFloorPlanPlacementURL = ({ projectId, testId, buildingId }) => {
    let params = new URLSearchParams();
    if (projectId) params.append('projectId', projectId);
    if (testId) params.append('testId', testId);
    if (buildingId) params.append('buildingId', buildingId);
    return `/floorplan-placement?${params.toString()}`;
  };

  const renderDisplayNumber = (testId) => {
    if (testDisplayNumbers && testDisplayNumbers[testId])
      return `(Test #${testDisplayNumbers[testId]})`;
    return '';
  };

  return (
    <TestContextProvider
      value={{
        projectId,
        reFetchProject,
        disableEdits: filteredTest?.testexecuted,
      }}
    >
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
      {!!isLoading && <Loading />}
      {projectData !== null && filteredTest !== null && segment !== null && (
        <div style={{ width: '100%' }}>
          <StyledTestHeader elevation={0} sx={{ width: 1 }}>
            <BreadcrumbForProjectTest
              currentProjectName={projectData.name}
              projectId={projectId}
              testId={filteredTest.testname}
            />
            <Title
              text={`${projectData.name} (Id ${projectData.id}): ${
                filteredTest.testname
              } ${renderDisplayNumber(filteredTest.testid)}`}
            />
          </StyledTestHeader>
          {filteredTest.testtype === TEST_TYPES.GENERAL && (
            <GeneralSurveyTest
              filteredTest={filteredTest}
              sampleType={sampleType}
              airSamplerRate={airSamplerRate}
              originPointsList={originPointsList}
              samplePointsList={samplePointsList}
              platekind={platekind}
              sprayer={sprayer}
              surveydate={surveydate}
              comments={comments}
              sprayers={sprayers}
              segmentId={segmentId}
              projectId={projectId}
              segment={segment}
              floorPlanPlacementURL={floorPlanPlacementURL}
              preparedScenariosList={preparedScenariosList}
              projectStatus={projectData.status}
            />
          )}
          {[
            TEST_TYPES.DILUTION,
            TEST_TYPES.LARGE_DILUTION,
            TEST_TYPES.SMALL_DILUTION,
          ].includes(filteredTest.testtype) && (
            <DilutionTest
              filteredTest={filteredTest}
              originPointsList={originPointsList}
              samplePointsList={samplePointsList}
              platekind={platekind}
              sprayer={sprayer}
              surveydate={surveydate}
              comments={comments}
              segmentId={segmentId}
              projectId={projectId}
              segment={segment}
              intervalCount={intervalCount}
              intervalDuration={intervalDuration}
              preparedScenariosList={preparedScenariosList}
              floorPlanPlacementURL={floorPlanPlacementURL}
              projectStatus={projectData.status}
            />
          )}
          {filteredTest.testtype === TEST_TYPES.MIN_SURVEY && (
            <MiniSurveyTest
              filteredTest={filteredTest}
              originPointsList={originPointsList}
              samplePointsList={samplePointsList}
              platekind={platekind}
              sprayer={sprayer}
              sprayers={sprayers}
              surveydate={surveydate}
              comments={comments}
              segmentId={segmentId}
              projectId={projectId}
              segment={segment}
              intervalCount={intervalCount}
              intervalDuration={intervalDuration}
              preparedScenariosList={preparedScenariosList}
              floorPlanPlacementURL={floorPlanPlacementURL}
              airSamplerRate={airSamplerRate}
              projectStatus={projectData.status}
            />
          )}
          {filteredTest.testtype === TEST_TYPES.VERIFICATION && (
            <VerificationTest
              filteredTest={filteredTest}
              originPointsList={originPointsList}
              samplePointsList={samplePointsList}
              platekind={platekind}
              sprayer={sprayer}
              sprayers={sprayers}
              surveydate={surveydate}
              comments={comments}
              segmentId={segmentId}
              projectId={projectId}
              segment={segment}
              intervalCount={intervalCount}
              intervalDuration={intervalDuration}
              preparedScenariosList={preparedScenariosList}
              floorPlanPlacementURL={floorPlanPlacementURL}
              projectStatus={projectData.status}
            />
          )}
          {filteredTest.testtype === TEST_TYPES.RECIRCULATION && (
            <RecirculationTest
              filteredTest={filteredTest}
              originPointsList={originPointsList}
              samplePointsList={samplePointsList}
              platekind={platekind}
              sprayer={sprayer}
              surveydate={surveydate}
              comments={comments}
              segmentId={segmentId}
              projectId={projectId}
              segment={segment}
              intervalCount={intervalCount}
              intervalDuration={intervalDuration}
              preparedScenariosList={preparedScenariosList}
              sprayers={sprayers}
              airSamplerRate={airSamplerRate}
              floorPlanPlacementURL={floorPlanPlacementURL}
              projectStatus={projectData.status}
              sampleType={sampleType}
            />
          )}
          {Object.values(HEALTHCARE_TEST_TYPES).includes(
            filteredTest.testtype,
          ) ? (
            <HealthcareTest
              test={filteredTest}
              originPointsList={originPointsList}
              samplePointsList={samplePointsList}
              platekind={platekind}
              sprayer={sprayer}
              sprayers={sprayers}
              surveydate={surveydate}
              comments={comments}
              segmentId={segmentId}
              projectId={projectId}
              segment={segment}
              preparedScenariosList={preparedScenariosList}
              floorPlanPlacementURL={floorPlanPlacementURL}
              projectStatus={projectData.status}
            />
          ) : null}
          <EditTestDetailsModal />
        </div>
      )}
    </TestContextProvider>
  );
}
