import React from 'react';
import { generatePath } from 'react-router';

import { ROUTE_TEST_PACKAGES } from '../../Config/routes';
import AppBreadcrumbs from './AppBreadcrumbs';

function BreadcrumbsTestPackages({ siteContractName }) {
  let breadcrumbs = [
    {
      text: 'Site Contracts',
      link: generatePath(ROUTE_TEST_PACKAGES),
    },
    siteContractName
      ? {
          text: siteContractName,
        }
      : null,
  ];
  return <AppBreadcrumbs links={breadcrumbs} />;
}

export default React.memo(BreadcrumbsTestPackages);
