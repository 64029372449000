import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import contractsApi from 'Api/contracts';

import SiteContractList from 'Components/TestPackages/SiteContractList';
import ErrorMessage from 'Components/UI/ErrorMessage';

import { useAuthState } from 'Context';

import { setPageTitle } from 'Features/Main/mainSlice';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  isValidResponse,
} from 'Utils';

export default function TestPackages() {
  const [siteContracts, setSiteContracts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mainError, setMainError] = useState(null);
  const authState = useAuthState();

  const globalDispatch = useDispatch();
  globalDispatch(setPageTitle('Test Packages'));

  const fetchSiteContracts = async () => {
    try {
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const response = await contractsApi.getSiteContracts();
      if (!isValidResponse(response)) {
        throw new Error(getErrorMessageFromResponse(response));
      }

      setSiteContracts(getDataFromResponse(response));
    } catch (e) {
      setMainError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSiteContracts();
  }, [authState.viewCompanyId]);

  return (
    <>
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
      <SiteContractList loading={isLoading} siteContracts={siteContracts} />
    </>
  );
}
