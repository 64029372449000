import React from 'react';
import { useSelector } from 'react-redux';

import AppBreadcrumbs from '../AppBreadcrumbs';
import { getProjectsBreadcrumb, getTextBreadcrumb } from '../dataUtils';

import { projectDataSelector } from 'Features/Projects/projectsSlice';

function BreadcrumbForProject() {
  const { projectDetails, isProjectDetailsLoading } =
    useSelector(projectDataSelector);

  return (
    <AppBreadcrumbs
      links={[
        getProjectsBreadcrumb(),
        !isProjectDetailsLoading
          ? getTextBreadcrumb(projectDetails?.name)
          : null,
      ]}
    />
  );
}

export default BreadcrumbForProject;
