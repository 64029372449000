import React, { forwardRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import '../styles.css';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const DetailsForm = forwardRef(
  (
    {
      onSubmit,
      defaultValues,
      viewMode,
      simpleMode = false,
      showLabels = true,
      isLoading,
      fullWidth = false,
    },
    ref,
  ) => {
    const [data, setData] = useState(null);

    const [buildingAge, setBuildingAge] = useState('');
    const [buildingAgeHelperText, setBuildingAgeHelperText] = useState('');

    const {
      register,
      setValue,
      getValues,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm({
      defaultValues: data || {},
    });

    const TextFieldDisabledViewOnly = styled(TextField)(() => ({
      '.MuiFormControl-root &.MuiTextField-root': {},
      '.MuiFormLabel-root': {
        '&.MuiInputLabel-root': {
          '&.Mui-disabled': {
            color: 'unset',
          },
        },
      },
      '.MuiInputBase-root': {
        '&.MuiInput-root': {
          '&.Mui-disabled input': {
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)',
            color: 'rgba(0, 0, 0, 0.87)',
          },
          '&::before': {
            borderBottomStyle: 'none',
          },
        },
      },
    }));

    useEffect(() => {
      const newData = Object.assign({}, defaultValues);
      if (newData) {
        if (!newData.buildingmetadata) {
          newData.buildingmetadata = {};
        }
        if (typeof newData.buildingmetadata === 'string') {
          newData.buildingmetadata = {
            address: newData.buildingmetadata,
          };
        }
        // Set value for radio group
        const { age } = newData;
        if (age) {
          updateBuildingAge(age);
        }
      }

      setData(newData);
    }, [defaultValues]);

    useEffect(() => {
      reset();
      const age = defaultValues?.age;

      if (age) {
        updateBuildingAge(age);
      }
    }, [viewMode]);

    useEffect(() => {
      reset({}, { keepTouched: true });
    }, [simpleMode]);

    const formTextFieldComponent = (
      label,
      key,
      { isNumber, isMetadataField, isComplexComponentSimplified },
    ) => {
      if (isLoading) {
        return <Skeleton key={key} />;
      }
      if (isComplexComponentSimplified && !viewMode) return null;

      const valuesObj = isMetadataField
        ? defaultValues.buildingmetadata
        : defaultValues;

      const standartProps = {
        key,
        variant: 'standard',
        margin: 'dense',
        fullWidth: true,
        defaultValue: (valuesObj && valuesObj[key]) || '',
        placeholder: !viewMode ? label : '',
      };

      if (viewMode) {
        const additionalStyle = {};
        if (isMetadataField) {
          additionalStyle.marginTop = 0;
          additionalStyle.marginBottom = 0;
          if (!valuesObj || !valuesObj[key]) return '';
        }
        if (isComplexComponentSimplified) {
          if (key === 'age') {
            standartProps.defaultValue = standartProps.defaultValue
              ? ageLabels[valuesObj[key]]
              : '';
          }
        }
        return (
          <TextFieldDisabledViewOnly
            {...standartProps}
            disabled={true}
            style={additionalStyle}
          ></TextFieldDisabledViewOnly>
        );
      }

      const requiredKey = (simpleMode && 'simpleMode') || 'fullMode';
      const isRequired = requiredConfig[key][requiredKey];
      // if (isRequired) {
      //   standartProps.placeholder += `*`;
      // }

      if (simpleMode && !isRequired) return null;

      return (
        <TextField
          {...standartProps}
          {...register(key, {
            required: isRequired && `${label} is required`,
            valueAsNumber: isRequired && isNumber,
            setValueAs: (v) => v && v.trim(),
            validate: !isRequired
              ? false
              : isNumber
              ? (value) =>
                  parseFloat(value, 10) > 0 || 'Value must be a positive number'
              : (value) => !!(value && value.trim()) || `${label} is required`,
          })}
          inputProps={{ id: key }}
          error={!!(errors && errors[key])}
          helperText={errors?.[key]?.message}
        />
      );
    };

    const requiredConfig = {
      buildingname: {
        simpleMode: true,
        fullMode: true,
      },
      totalsqft: {
        simpleMode: true,
        fullMode: true,
      },
      outdoorAirPercentage: {
        simpleMode: true,
        fullMode: true,
      },
      age: {
        simpleMode: true,
        fullMode: true,
      },
      numberoffloors: {
        simpleMode: false,
        fullMode: true,
      },
      floorsqeft: {
        simpleMode: false,
        fullMode: true,
      },
      city: {
        simpleMode: false,
        fullMode: true,
      },
      state: {
        simpleMode: false,
        fullMode: true,
      },
      address: {
        simpleMode: false,
        fullMode: true,
      },
      country: {
        simpleMode: false,
        fullMode: true,
      },
      postalCode: {
        simpleMode: true,
        fullMode: true,
      },
    };

    const config = {
      general: [
        {
          label: 'Name',
          key: 'buildingname',
          isNumber: false,
          required: requiredConfig.buildingname,
        },
        {
          label: 'Total sq ft',
          key: 'totalsqft',
          isNumber: true,
          required: requiredConfig.totalsqft,
        },
        {
          label: 'Outdoor Air Percentage',
          key: 'outdoorAirPercentage',
          isNumber: true,
          required: requiredConfig.outdoorAirPercentage,
        },
        {
          label: 'Number of floors',
          key: 'numberoffloors',
          isNumber: true,
          required: requiredConfig.numberoffloors,
        },
        {
          label: 'Floor sq ft',
          key: 'floorsqeft',
          isNumber: true,
          required: requiredConfig.floorsqeft,
        },
      ],
      metadata: [
        {
          label: 'City',
          key: 'city',
          required: requiredConfig.city,
        },
        {
          label: 'State',
          key: 'state',
          required: requiredConfig.state,
        },
        {
          label: 'Address',
          key: 'address',
          required: requiredConfig.address,
        },
        {
          label: 'Country',
          key: 'country',
          required: requiredConfig.country,
        },
        {
          label: 'Postal code',
          key: 'postalCode',
          required: requiredConfig.postalCode,
        },
      ],
      complexComponents: {
        age: {
          label: 'Building age',
          key: 'age',
          required: requiredConfig.age,
        },
      },
    };

    const ageLabels = {
      '1980-2004': 'Between 1980 and 2004',
      '<1980': 'Before 1980',
      '>2004': 'After 2004',
    };

    const updateBuildingAge = (value) => {
      const { key } = config.complexComponents.age;
      setValue(key, value);
      setBuildingAgeHelperText('');
      setBuildingAge(value);
    };

    const BuildingAgeRadioGroup = () => {
      const { label, key } = config.complexComponents.age;

      if (viewMode) {
        return formTextFieldComponent(label, key, {
          isComplexComponentSimplified: true,
        });
      }

      return (
        <FormControl>
          <RadioGroup
            aria-labelledby="radio-buttons-building-age"
            name="radio-buttons-building-age-group"
            value={buildingAge}
            onChange={(event) => updateBuildingAge(event.target.value)}
          >
            {Object.entries(ageLabels).map(([age_val, age_name]) => (
              <FormControlLabel
                key={age_val}
                value={age_val}
                control={<Radio size="small" />}
                label={age_name}
              />
            ))}
          </RadioGroup>
          {!!buildingAgeHelperText && (
            <FormHelperText error={true}>
              {buildingAgeHelperText}
            </FormHelperText>
          )}
        </FormControl>
      );
    };

    const validateComplexComponentsBeforeSubmit = (
      allOtherFieldsValidated = true,
    ) => {
      const { label, key } = config.complexComponents.age;
      const ageIsRequired =
        requiredConfig.age[simpleMode ? 'simpleMode' : 'fullMode'];

      const values = getValues();
      let age;

      try {
        age = values[key];
      } catch (err) {}

      if (ageIsRequired && !age) {
        setBuildingAgeHelperText(`${label} is required`);
        return;
      }

      if (allOtherFieldsValidated) {
        onSubmit(values);
      }
    };

    return (
      <>
        <form
          ref={ref}
          onSubmit={handleSubmit(validateComplexComponentsBeforeSubmit, () =>
            validateComplexComponentsBeforeSubmit(false),
          )}
          className="building-details--form"
        >
          <Grid container spacing={4}>
            <Grid item xs={fullWidth ? 12 : 6}>
              {config.general.map(({ label, key, isNumber }) => (
                <div className="building-details--form__field" key={key}>
                  {showLabels && <label htmlFor={key}>{label}:</label>}
                  {formTextFieldComponent(label, key, { isNumber })}
                </div>
              ))}
              <div className="building-details--form__field">
                <label style={{ marginBottom: '8px', display: 'block' }}>
                  Building age:
                </label>
                {BuildingAgeRadioGroup()}
              </div>
            </Grid>
            <Grid item xs={fullWidth ? 12 : 6}>
              <div className="building-details--form__field">
                <label style={{ marginBottom: '8px', display: 'block' }}>
                  Address:
                </label>
                <address>
                  {config.metadata.map(({ label, key }) =>
                    formTextFieldComponent(label, key, {
                      isMetadataField: true,
                    }),
                  )}
                </address>
              </div>
            </Grid>
          </Grid>
        </form>
      </>
    );
  },
);

DetailsForm.displayName = 'DetailsForm';

export default DetailsForm;
