import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Save } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  FormControl,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import buildings from 'Api/buildings';

import { usePortfolioContext } from 'Components/Portfolios/context';

const AddRegion = ({ cancel, portfolioId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { handleSubmit, register, formState } = useForm();
  const { fetchPortfoliosAndSites } = usePortfolioContext();

  const onSubmit = (data) => {
    setIsLoading(true);
    buildings
      .createRegion({ portfolioId, ...data })
      .then((res) => {
        if (res?.data?.success) {
          fetchPortfoliosAndSites();
          cancel();
        } else {
          setError(res?.data?.error?.message);
        }
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Stack p={2} spacing={2}>
      {isLoading && <LinearProgress />}
      <Typography variant="h5">Add a new region</Typography>
      <Box py={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <TextField
              id="region-id"
              fullWidth
              label="Region name"
              margin="normal"
              required
              {...register('name')}
            />
          </FormControl>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              startIcon={<Save />}
              disabled={!formState.isDirty}
              type="submit"
            >
              Create
            </Button>
            <Button onClick={cancel}>Cancel</Button>
          </Stack>
        </form>
      </Box>
      {!!error && (
        <Alert severity="error">Error when creating the region</Alert>
      )}
    </Stack>
  );
};

export default AddRegion;
