import { HEATMAP_VERIFICATION_LIKE_BEHAVIOR, TEST_TYPES } from 'Constants';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { ROUTE_HEATMAP } from '../../Config/routes';
import { setShowMenu } from '../../Features/Compare/compareSlice';
import {
  checkExistHeatmapCompare,
  getCalcResultLabelFromVerLikeTest,
  getHeatmapCompare,
  removeHeatmapCompare,
  setHeatmapCompare,
} from '../../Utils';
import ColorsPaper from '../Mix/ColorsPaper';

import CompareIcon from '@mui/icons-material/Compare';
import NoPhotographyTwoToneIcon from '@mui/icons-material/NoPhotographyTwoTone';
import { Box, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';

export default function HeatMapItem({
  item,
  testId,
  buildingId,
  displayNumber,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isSelectedForCompare, setIsSelectedForCompare] = useState(false);
  const [colorCompareButton, setColorCompareButton] = useState();
  const [compareDisabled, setCompareDisabled] = useState(false);
  const [displayedResult, setDisplayedResult] = useState('');
  const [backgroundColorCompareButton, setBackgroundColorCompareButton] =
    useState();
  const handleClick = (event) => {
    const path = generatePath(ROUTE_HEATMAP, {
      buildingId: buildingId,
      testId: testId,
      heatmapId: item.heatmapid,
    });

    if (event.metaKey || event.ctrlKey) {
      window.open(path);
      return;
    }
    navigate(path);
  };

  const compareBtnTooltipText = {
    disabled: 'Heatmap should have a floorplan to be compared',
    standart: 'Compare',
  };

  useEffect(() => {
    const isExistHeatmap = checkExistHeatmapCompare(item.heatmapid);
    setIsSelectedForCompare(isExistHeatmap);
    setColorCompareButton(
      isExistHeatmap ? theme.palette.primary.main : grey[500],
    );
    setBackgroundColorCompareButton(isExistHeatmap ? grey[50] : '#ffffff');
  }, [item.heatmapid]);

  useEffect(() => {
    const { calculatedresult, testtype } = item;
    let calculatedResult =
      (!!~HEATMAP_VERIFICATION_LIKE_BEHAVIOR.indexOf(testtype) &&
        getCalcResultLabelFromVerLikeTest(item)) ||
      calculatedresult;

    const disableCompare =
      item.testtype === TEST_TYPES.VERIFICATION && !item.floorplan;

    setCompareDisabled(disableCompare);
    setDisplayedResult(calculatedResult);
  }, [item.testtype]);

  const handleToggleCompare = () => {
    const newIsSelectedForCompare = !isSelectedForCompare;
    setIsSelectedForCompare(newIsSelectedForCompare);
    if (newIsSelectedForCompare) {
      setHeatmapCompare(item.heatmapid);
      dispatch(setShowMenu(true));
    } else {
      removeHeatmapCompare(item.heatmapid);
      dispatch(setShowMenu(getHeatmapCompare().length));
    }

    setColorCompareButton(
      newIsSelectedForCompare ? theme.palette.primary.main : grey[500],
    );
    setBackgroundColorCompareButton(
      newIsSelectedForCompare ? grey[50] : '#ffffff',
    );
  };

  const PreviewImageComponent = React.useMemo(() => {
    const noPreviewFP =
      item.testtype === TEST_TYPES.VERIFICATION && !item.floorplan;
    if (noPreviewFP) {
      return (
        <CardMedia
          component="div"
          alt={item.heatmapname}
          onClick={handleClick}
          sx={{ cursor: 'pointer', mr: 2 }}
        >
          <div
            style={{
              height: '200px',
              width: '300px',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <NoPhotographyTwoToneIcon color="disabled" sx={{ fontSize: 48 }} />
          </div>
        </CardMedia>
      );
    } else
      return (
        <CardMedia
          component="img"
          height="200"
          image={item.heatmapthumbnail ?? '/thumbnail.png'}
          alt={item.heatmapname}
          onClick={handleClick}
          sx={{ cursor: 'pointer', mr: 2 }}
        />
      );
  }, [item.testtype]);

  return (
    <>
      <Card sx={{ display: 'flex', marginBottom: 5, alignItems: 'flex-start' }}>
        {PreviewImageComponent}
        <Box sx={{ pt: 1, width: '100%' }}>
          <Grid container spacing={1} sx={{ height: '200px' }}>
            <Grid item xs={11}>
              <Typography
                component="div"
                variant="h6"
                sx={{ cursor: 'pointer' }}
                onClick={handleClick}
              >
                {(displayNumber ? '#' + displayNumber + ' ' : '') +
                  item.heatmapname}
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                sx={{ maxHeight: '135px', overflow: 'hidden' }}
              >
                {displayedResult}
                {item.heatmapcomments?.split('\n').map((str, index) => (
                  <div key={index}>{str}</div>
                ))}
              </Typography>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                flexDirection: 'row-reverse',
              }}
            >
              <div
                style={{
                  float: 'right',
                  marginTop: 15,
                  marginBottom: 15,
                  marginRight: 15,
                }}
              >
                <ColorsPaper color={item.significance} />
              </div>
              <div
                style={{
                  float: 'right',
                  marginTop: 15,
                  marginBottom: 15,
                  marginRight: 15,
                }}
              >
                <Tooltip
                  title={
                    compareDisabled
                      ? compareBtnTooltipText.disabled
                      : compareBtnTooltipText.standart
                  }
                  placement="top"
                >
                  <span>
                    <IconButton
                      sx={{
                        color: colorCompareButton,
                        cursor: 'pointer',
                        borderRadius: 0,
                        backgroundColor: backgroundColorCompareButton,
                      }}
                      disabled={compareDisabled}
                      onClick={handleToggleCompare}
                    >
                      <CompareIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
}
