import { ST_CHART_COLORS } from 'Constants';
import React, { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { useDashboardContext } from 'Components/Dashboard/context';

export default function ChartAdjustedEACHByHDA() {
  const { buildingOverviewData } = useDashboardContext();

  const [maxYAxisVal, setMaxYAxisVal] = useState(10);

  useEffect(() => {
    const data = buildingOverviewData?.eACHByHDA || [];
    let maxEACHVal = 0;
    for (let { eACH_base, eACH_opt } of data) {
      maxEACHVal = Math.max(maxEACHVal, eACH_base || 0, eACH_opt || 0);
    }
    if (Math.ceil(maxEACHVal) + 2 > maxYAxisVal) {
      setMaxYAxisVal(Math.ceil(maxEACHVal) + 2);
    }
  }, [buildingOverviewData?.eACHByHDA]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={400}
        data={buildingOverviewData?.eACHByHDA || []}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 60,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="name" />
        <YAxis
          type="number"
          domain={[0, maxYAxisVal]}
          ticks={Array(maxYAxisVal + 1)
            .fill(1)
            .map((_, i) => i)}
          interval={0}
          label={<CustomizedOYLabel maxYAxisVal={maxYAxisVal} />}
        />
        <Tooltip
          formatter={(value, name) => {
            return [
              value.toFixed(1),
              name === 'eACH_base' ? 'eACH' : 'Optimized eACH', // Baseline eACH
            ];
          }}
        />
        <Legend
          align="right"
          verticalAlign="top"
          formatter={
            (value) => (value === 'eACH_base' ? 'eACH' : 'Optimized') // Baseline
          }
        />
        <Bar dataKey="eACH_base" barSize={20} fill={ST_CHART_COLORS.blue}>
          <LabelList content={renderBarLabel} />
        </Bar>
        {/* <Bar dataKey="eACH_opt" barSize={20} fill={ST_CHART_COLORS.green}>
          <LabelList content={renderBarLabel} />
        </Bar> */}
        <ReferenceLine
          y={4.61}
          stroke="red"
          label={{
            position: 'insideTopRight',
            value: 'CDC Target',
          }}
          strokeWidth={3}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

const renderBarLabel = ({ x, y, width, value }) => {
  if (!value) return null;

  return (
    <g>
      <text
        x={x + width / 2}
        y={y - 10}
        fill="black"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value.toFixed(1)}
      </text>
    </g>
  );
};

const CustomizedOYLabel = (props) => {
  const { viewBox, maxYAxisVal } = props;
  const { height, x, y } = viewBox;
  const offsetXText = 15;
  const r = '5';
  const widthOfStroke = 3;
  const widthOfBracket = 20;

  const defZoneEndYCoord = 4.5;
  const energyZoneStartYCoord = 6;

  const defZoneHeight = height * (defZoneEndYCoord / maxYAxisVal);
  const defZoneStartY = height + y - defZoneHeight;
  const energyZoneHeight =
    height * ((maxYAxisVal - energyZoneStartYCoord) / maxYAxisVal);

  return (
    <g>
      <rect
        x={x}
        y={defZoneStartY}
        height={defZoneHeight}
        fill={ST_CHART_COLORS.blue}
        width={widthOfBracket}
        rx={r}
        ry={r}
      />
      <rect
        x={x + widthOfStroke}
        y={defZoneStartY + widthOfStroke}
        height={defZoneHeight - widthOfStroke * 2}
        fill="white"
        width={widthOfBracket - widthOfStroke}
      />
      <text
        x={x - widthOfStroke - offsetXText}
        y={(y + height + defZoneStartY) / 2}
        textAnchor="middle"
        alignmentBaseline="middle"
        transform={`rotate(-90 ${x - widthOfStroke - offsetXText} ${
          (y + height + defZoneStartY) / 2
        })`}
        fill="black"
        fontSize={14}
      >
        Deficiency zone
      </text>
      <rect
        x={x}
        y={y}
        height={energyZoneHeight}
        fill={ST_CHART_COLORS.blue}
        width={widthOfBracket}
        rx={r}
        ry={r}
      />
      <rect
        x={x + widthOfStroke}
        y={y + widthOfStroke}
        height={energyZoneHeight - widthOfStroke * 2}
        fill="white"
        width={widthOfBracket - widthOfStroke}
      />
      <text
        x={x - widthOfStroke - offsetXText}
        y={y + energyZoneHeight / 2}
        textAnchor="middle"
        alignmentBaseline="middle"
        transform={`rotate(-90 ${x - widthOfStroke - offsetXText} ${
          y + energyZoneHeight / 2
        })`}
        fill="black"
        fontSize={12}
      >
        Energy & Cost Savings Zone
      </text>
    </g>
  );
};
