import React, { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import '../styles.css';
import BuildingItem from './BuildingItem';

export default function BuildingsDraggableList({
  buildings,
  isInDragAction,
  loading,
  mode = 'view',
  disableActions,
}) {
  const [noDragMode, setNoDragMode] = useState(true);

  useEffect(() => {
    setNoDragMode(mode !== 'edit' || disableActions);
  }, [mode, disableActions]);

  const scrollableViewStyle = {
    maxHeight: '140px',
    overflowY: 'scroll',
    overflowX: 'none',
  };

  return (
    <div style={mode !== 'edit' ? scrollableViewStyle : {}}>
      {buildings
        .sort((a, b) =>
          typeof a.localOrder === 'number' ? a.localOrder - b.localOrder : 1,
        )
        .map((b, index) => {
          const noEditableBuilding = b.hasTestpackage;
          return (
            <Draggable
              draggableId={`building-${b.id}`}
              index={index}
              key={b.id}
              isDragDisabled={noDragMode || noEditableBuilding}
            >
              {(provided, snapshot) => {
                const style = {
                  boxShadow: snapshot.isDragging
                    ? 'rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px'
                    : '',
                  borderRadius: '6px',
                  backgroundColor: 'white',
                  ...provided.draggableProps.style,
                };
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={style}
                  >
                    <BuildingItem
                      buildingId={b.id}
                      buildingName={b.name}
                      mode={mode}
                      dividerVisible={index !== buildings.length - 1}
                      key={b.id}
                      dragHandleProps={provided.dragHandleProps || {}}
                      disableActions={noEditableBuilding || disableActions}
                      showTooltip={!isInDragAction && !disableActions}
                      loading={loading}
                    />
                  </div>
                );
              }}
            </Draggable>
          );
        })}
    </div>
  );
}
