import { ERROR_AUTOCLOSE_TIMEOUT_MS } from 'Constants';
import React, { useState } from 'react';

import EditTestName from '../../../SingleTest/Modals/TestEditModals/EditTestName';

import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

import tests from 'Api/tests';

import { useProjectContext } from 'Components/SingleProject/context';

import { useIsMounted } from 'Context';

import { getErrorMessageFromResponse, isValidResponse } from 'Utils';

export default function EditTestNameBtn({
  afterEdit,
  testId,
  testName,
  disabled,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const mounted = useIsMounted();
  const { setMainError } = useProjectContext();
  const saveProjectDetails = async (values) => {
    setSaveInProgress(true);
    let isSuccess = false;
    const testEditData = { testName: values.name };

    try {
      const res = await tests.updateTestName(testId, testEditData);
      if (!isValidResponse(res)) {
        throw new Error(getErrorMessageFromResponse(res));
      }
      isSuccess = true;
    } catch (err) {
      console.log('Save test name error: ', err);
      setMainError(err?.message || `Couldn't save test name`);
      setTimeout(() => setMainError(''), ERROR_AUTOCLOSE_TIMEOUT_MS);
    } finally {
      if (mounted.current) {
        setSaveInProgress(false);
        if (isSuccess) {
          setModalOpen(false);
        }
      }

      afterEdit();
    }
  };

  return (
    <React.Fragment>
      <IconButton
        aria-label="Edit test name"
        size="small"
        sx={{ mr: 1 }}
        onClick={() => setModalOpen(true)}
        disabled={disabled || saveInProgress}
      >
        <EditIcon fontSize="inherit" />
      </IconButton>
      <EditTestName
        testName={testName}
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        onSubmit={(values) => saveProjectDetails(values)}
        actionsDisabled={saveInProgress}
      />
    </React.Fragment>
  );
}
