import React from 'react';

import { Modal, Paper } from '@mui/material';

import AddRegion from 'Components/Portfolios/EditModals/AddRegion';
import AddSite from 'Components/Portfolios/EditModals/AddSite';
import AddTerritory from 'Components/Portfolios/EditModals/AddTerritory';
import EditContract from 'Components/Portfolios/EditModals/EditContract';
import EditPortfolio from 'Components/Portfolios/EditModals/EditPortfolio';
import EditRegion from 'Components/Portfolios/EditModals/EditRegion';
import EditSite from 'Components/Portfolios/EditModals/EditSite';
import EditSiteContract from 'Components/Portfolios/EditModals/EditSiteContract';
import EditTerritory from 'Components/Portfolios/EditModals/EditTerritory';
import { usePortfolioContext } from 'Components/Portfolios/context';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const EditDetailsModal = () => {
  const { isDrawerOpen, setIsDrawerOpen, drawerMeta } = usePortfolioContext();

  const renderComponent = () => {
    switch (drawerMeta?.type) {
      case 'region':
        if (drawerMeta?.id) {
          return (
            <EditRegion
              id={drawerMeta?.id}
              cancel={() => {
                setIsDrawerOpen(false);
              }}
            />
          );
        } else {
          return (
            <AddRegion
              cancel={() => {
                setIsDrawerOpen(false);
              }}
              portfolioId={drawerMeta?.portfolioId}
            />
          );
        }
      case 'territory':
        if (drawerMeta?.id) {
          return (
            <EditTerritory
              id={drawerMeta?.id}
              cancel={() => {
                setIsDrawerOpen(false);
              }}
            />
          );
        } else {
          return (
            <AddTerritory
              portfolioId={drawerMeta?.portfolioId}
              cancel={() => {
                setIsDrawerOpen(false);
              }}
            />
          );
        }

      case 'site':
        if (drawerMeta?.id) {
          return (
            <EditSite
              id={drawerMeta?.id}
              cancel={() => {
                setIsDrawerOpen(false);
              }}
            />
          );
        } else {
          return (
            <AddSite
              portfolioId={drawerMeta?.portfolioId}
              cancel={() => {
                setIsDrawerOpen(false);
              }}
            />
          );
        }

      case 'portfolio':
        return (
          <EditPortfolio
            id={drawerMeta.portfolioId}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
          />
        );

      case 'contract':
        return (
          <EditContract
            id={drawerMeta.id}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
          />
        );

      case 'siteContract':
        return (
          <EditSiteContract
            id={drawerMeta.id}
            cancel={() => {
              setIsDrawerOpen(false);
            }}
          />
        );
      default:
        setIsDrawerOpen(false);
    }
  };

  return (
    <Modal
      open={isDrawerOpen}
      onClose={() => {
        setIsDrawerOpen(false);
      }}
    >
      <Paper sx={style} variant="outlined">
        {renderComponent()}
      </Paper>
    </Modal>
  );
};

export default EditDetailsModal;
