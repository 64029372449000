import React from 'react';
import { MapInteraction } from 'react-map-interaction';

/*
  This component provides a map like interaction to any content that you place in it. It will let
  the user zoom and pan the children by scaling and translating props.children using css.
*/
export default function MapInteractionCustom(props) {
  return (
    <MapInteraction {...props}>
      {({ translation, scale }) => {
        // Translate first and then scale. Otherwise, the scale would affect the translation.
        const transform = `translate(${translation.x}px, ${translation.y}px) scale(${scale})`;

        return (
          <div
            style={{
              height: '100%',
              width: '100%',
              position: 'relative', // for absolutely positioned children
              overflow: 'visible',
              touchAction: 'none', // Not supported in Safari :(
              msTouchAction: 'none',
              cursor: 'all-scroll',
              WebkitUserSelect: 'none',
              MozUserSelect: 'none',
              msUserSelect: 'none',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <div
              style={{
                display: 'inline-block', // size to content
                transform: transform,
                transformOrigin: props.leftPadding
                  ? `${props.leftPadding} 40px`
                  : '0 0 ',
              }}
            >
              {props.children}
            </div>
          </div>
        );
      }}
    </MapInteraction>
  );
}
