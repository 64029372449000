import {
  HEALTHCARE_TEST_TYPES,
  HEALTHCARE_TEST_TYPES_LABELS,
  HEATMAP_VERIFICATION_LIKE_BEHAVIOR,
  UNMOUNT_IGNORED_ERR_MSG,
} from 'Constants';
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import ProjectConfigurationPrint from './ProjectConfiguration/ProjectConfigurationPrint';
import ProjectDataModel from './ProjectConfiguration/utils/ProjectDataModel';
import ProjectDataValidator from './ProjectConfiguration/utils/ProjectDataValidator';
import ProjectScopeTabulator from './ProjectConfiguration/utils/ProjectScopeTabulator';

import { Box, Skeleton, Stack } from '@mui/material';

import floorPlansApi from 'Api/floorPlans';
import projectsApi from 'Api/projects';

import DownloadButton from 'Components/Buttons/DownloadButton';
import RefreshButton from 'Components/Buttons/RefreshButton';
import { cropImageFromUrlPromisified } from 'Components/Floorplans/utils';
import { getACHRange } from 'Components/Scenarios/utils';
import { getTotalACH } from 'Components/Scenarios/utils';

import { useIsMounted } from 'Context';

import { csvCommasEscape, getDataFromResponse } from 'Utils';

function ProjectConfiguration({
  loading,
  projectId,
  projectName,
  hasHealthcareTest,
  hasLegacyTest,
  hasVerLikeTest,
  doExist,
  onError,
}) {
  const TOOLTIP_MSG_NO_TESTS = `The project does not have any tests`;
  const TOOLTIP_MSG_LOADING = `Preparing the data for download, please wait a few moments!`;
  const TOOLTIP_MSG_NO_DATA = `No data`;

  const mounted = useIsMounted();

  const [isFetchingData, setIsFetchingData] = useState(false);

  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [projectScopeHealthcareData, setProjectScopeHealthcareData] =
    useState(null);

  const [canRequestReload, setCanRequestReload] = useState(false);
  const [_fpImagesLoaded, _setFpImagesLoaded] = useState(false);
  const fpImagesLoaded = useRef({});

  useEffect(() => {
    const source = axios.CancelToken.source();

    getProjectDetailedData(source);

    return () => {
      source.cancel(UNMOUNT_IGNORED_ERR_MSG);
    };
  }, []);

  const updateHealthcareProjectScopeData = ({
    testsMeta,
    scenarioData,
    scenariosWithSPs,
  }) => {
    const result = [];

    // add headers row for future csv file
    result.push([
      'test_id',
      'test_type',
      'test_name',
      'scenario_id',
      'scenario_name',
      'HVAC_ACH',
      'PEC_ACH',
      'Selector Knob ACH setting',
      'setting ACH',
      'ACH pass/fail',
      'Leakage pass/fail',
      'Infiltration pass/fail',
      'Test Duration (min)',
      'OP_id_count',
      'OP_method',
      'OP_inside',
      'OP_outside',
      'sample_type',
      'SP_count',
      'samples_count',
      'SN_start',
      'SN_end',
    ]);

    for (let {
      testmetadata,
      testid,
      testtype,
      testname,
      segmentid,
    } of testsMeta) {
      if (!Object.values(HEALTHCARE_TEST_TYPES).includes(testtype)) {
        continue;
      }
      const testRows = [];

      if (!scenarioData[testid]) {
        continue;
      }

      for (let { scenarioInternalName, scenarioId } of scenarioData[testid]) {
        const testScenarioRow = Array.from(Array(22), () => '');

        const testSegmentMetadata = testmetadata[segmentid] ?? {};
        const scenarioMetadataFromTestMetadata =
          testSegmentMetadata.scenariosData?.[scenarioInternalName];

        testScenarioRow[0] = testid;
        testScenarioRow[1] = HEALTHCARE_TEST_TYPES_LABELS[testtype];
        testScenarioRow[2] = testname;
        testScenarioRow[3] = scenarioId;

        if (!scenarioMetadataFromTestMetadata) {
          testRows.push(testScenarioRow);
          continue;
        }

        testScenarioRow[4] = scenarioMetadataFromTestMetadata.displayName;
        testScenarioRow[5] = scenarioMetadataFromTestMetadata.HVAC_ACH;
        testScenarioRow[6] = scenarioMetadataFromTestMetadata.PEC_ACH;
        testScenarioRow[7] =
          scenarioMetadataFromTestMetadata.testDurationSelectorNob;

        const totalACH = getTotalACH(
          scenarioMetadataFromTestMetadata.PEC_ACH,
          scenarioMetadataFromTestMetadata.HVAC_ACH,
        );
        const achRange = getACHRange(totalACH);
        testScenarioRow[8] = `${achRange[0]} to ${achRange[1]}`;

        testScenarioRow[9] = scenarioMetadataFromTestMetadata.achPassFail;
        testScenarioRow[10] =
          scenarioMetadataFromTestMetadata.leakagePassFail || 'N/A';
        testScenarioRow[11] =
          scenarioMetadataFromTestMetadata.infiltration || 'N/A';
        testScenarioRow[12] =
          scenarioMetadataFromTestMetadata.testDurationSelectorNob;
        testScenarioRow[13] = Object.values(
          testSegmentMetadata.opAmount,
        ).reduce((sum, curr) => curr + sum, 0);
        testScenarioRow[14] = 'eSpray';
        testScenarioRow[15] =
          scenarioMetadataFromTestMetadata.tag.inner || 'N/A';
        testScenarioRow[16] =
          scenarioMetadataFromTestMetadata.tag.outer || 'N/A';
        testScenarioRow[17] = 'Air Filter';
        testScenarioRow[18] = Object.values(
          testSegmentMetadata.spAmount,
        ).reduce((sum, curr) => curr + sum, 0);

        // Extract sample numbers from scenario data
        let samplesAmountInScenario = 'N/A';
        let samplesStart = 'N/A';
        let samplesEnd = 'N/A';
        try {
          const regex = /SN-(\d+)/;
          const samplesInScenario = scenariosWithSPs[testid].scenarios
            .find(({ id }) => id === scenarioId)
            .SPs.reduce((acc, { samples }) => {
              const sampleNumbers = samples.map(({ samplenumber }) => {
                return parseInt(samplenumber.match(regex)[1]);
              });
              acc.push(...sampleNumbers);
              return acc;
            }, [])
            .sort((a, b) => a - b);

          samplesAmountInScenario = samplesInScenario.length;
          samplesStart = samplesInScenario[0];
          samplesEnd = samplesInScenario[samplesInScenario.length - 1];
        } catch (err) {
          console.error(err);
        }

        testScenarioRow[19] = samplesAmountInScenario;
        testScenarioRow[20] = samplesStart;
        testScenarioRow[21] = samplesEnd;

        testRows.push(testScenarioRow);
      }

      result.push(...testRows);
    }

    setProjectScopeHealthcareData(result);
  };

  const getProjectDetailedData = useCallback(
    async (source) => {
      if (!projectId || !doExist) return;

      setIsFetchingData(true);
      setCanRequestReload(false);

      try {
        const [projectConfResp, floorplansForProjectResp] = await Promise.all([
          projectsApi.getProjectConfigurationData(projectId, source),
          floorPlansApi.getFloorplansForProject(projectId),
        ]);

        const {
          projectData: _project,
          sampleCollectors,
          testFromProject,
          segmentsData,
          originPointsData,
          samplePointsData,
          samplesData,
          scenariosData,
          tests: testsDetailedData,
        } = getDataFromResponse(projectConfResp);

        // format wrong field name, TODO: fix its usage in the components
        for (let sampleLabel of sampleCollectors) {
          sampleLabel.spNumber = sampleLabel.SPNumber;
        }

        // format wrong field name, TODO: fix its usage in the components
        for (let segment of segmentsData) {
          segment.testmetadata = segment.segmentTestMetadata;
        }

        const projectFloorplans = getDataFromResponse(floorplansForProjectResp);
        const uniqueFpNames = projectFloorplans.reduce((acc, { filename }) => {
          if (!acc.includes(filename)) {
            acc.push(filename);
          }
          return acc;
        }, []);

        fpImagesLoaded.current.amount = uniqueFpNames.length || true;
        if (!uniqueFpNames.length) {
          _setFpImagesLoaded(true);
        }

        const projectFloorplansCropped = await Promise.all(
          projectFloorplans.map(async (fp) => {
            let finalUrl = fp.preSignedURL;
            if (fp.metadata && fp.metadata.cropData) {
              finalUrl = await cropImageFromUrlPromisified({
                url: fp.preSignedURL,
                cropData: fp.metadata.cropData,
              });
            }

            return {
              ...fp,
              floorPlanData: {
                url: finalUrl,
              },
            };
          }),
        );

        const projectModel = new ProjectDataModel(
          projectId,
          _project,
          testFromProject,
          segmentsData,
          samplePointsData,
          sampleCollectors,
          samplesData,
          originPointsData,
          scenariosData,
          projectFloorplansCropped,
        );

        updateHealthcareProjectScopeData(testsDetailedData);

        projectModel.build();

        const dataValidator = new ProjectDataValidator(projectModel, 3);
        const dataIssues = dataValidator.validate();

        const projectScopeTabulatedData = new ProjectScopeTabulator(
          projectModel,
        ).tabulate();

        const tagInfo = originPointsData
          .filter(
            ({ tagLotNumber }) =>
              !!(Array.isArray(tagLotNumber) && tagLotNumber.length),
          )
          .map(({ tagLotNumber }) => tagLotNumber[0]);

        const details = projectScopeTabulatedData.map((detail) => ({
          ...detail,
          opTags: detail.opTags.join(',  '),
          sampleType: detail.sampleType.join(',  '),
        }));

        if (mounted.current) {
          setProjectData({
            projectName:
              projectModel.project !== null ? projectModel.project.name : '',
            address:
              projectModel.project !== null ? projectModel.project.address : '',
            customer:
              projectModel.project !== null
                ? projectModel.project.customer
                : '',
            testDate: '', //TODO fill in the test dates
            contacts: 'n/a',
            projectScopeDetails: details,
            originPoints:
              projectModel.project !== null ? projectModel.originPoints : '',
            samplePoints:
              projectModel.project !== null ? projectModel.samplePoints : '',
            samples: projectModel.samples,
            scenarios: projectModel.scenarios,
            builder: projectModel,
            loadingData: false,
            details,
            dataIssues,
            status: projectModel.project.status,
            description: projectModel.project.description,
            partner: projectModel.project.partner,
            tagInfo,
            testsDetailedData,
          });

          setIsFetchingData(false);
        }
      } catch (err) {
        if (err.message === UNMOUNT_IGNORED_ERR_MSG || !mounted.current) return;

        if (mounted.current) {
          setProjectData(null);
          setIsFetchingData(false);
          setCanRequestReload(true);
        }
        if (typeof onError === 'function') {
          onError(`Failed to load data for Project Configuration`);
        }
        console.error(`Project Detailed Data Get Error: `, err);
      }
    },
    [projectId],
  );

  const buildExportStructure = () => {
    const rows = [];

    rows.push([
      'TestNum',
      'Test_id',
      'TestName',
      'Scenario',
      'ScenarioDescription',
      'OPCount',
      'OPTags',
      'SampleType',
      'SPCount',
      'IntervalCount',
      'IntervalDuration',
      'SampleCount',
      'SNStart',
      'SNEnd',
    ]);

    if (!Array.isArray(projectData?.projectScopeDetails)) {
      return [];
    }

    for (let test of projectData.projectScopeDetails) {
      if (Object.values(HEALTHCARE_TEST_TYPES).includes(test.type)) {
        continue;
      }
      const intervalDuration =
        test.intervalDuration ||
        (!!~HEATMAP_VERIFICATION_LIKE_BEHAVIOR.indexOf(test.type) &&
          'Custom (5)');
      const columns = [
        test.testSequenceNumber,
        test.testId,
        csvCommasEscape(test.testName),
        test.scenarioNames[0].split('scenario')[1],
        csvCommasEscape(test.scenarioDescriptions),
        test.opCount,
        csvCommasEscape(
          'Tag-' + test.opTags.replace(/\s/g, '').replace(/,/g, ', Tag-'),
        ),
        csvCommasEscape(test.sampleType),
        test.spCount,
        test.intervalCount,
        intervalDuration,
        test.sampleCount,
        test.startingSampleNumber,
        test.endingSampleNumber,
      ];

      rows.push(columns);
    }

    return rows;
  };

  const handleDownloadIndicators = useCallback((isDownloading) => {
    if (mounted.current) {
      setDownloadInProgress(isDownloading);
    }
  }, []);

  const handleDownloadScope = (healthcareType = false) => {
    try {
      handleDownloadIndicators(true);

      const data = healthcareType
        ? projectScopeHealthcareData
        : buildExportStructure();
      const csvContent = formatDataToCSV(data);

      if (!csvContent) {
        throw new Error();
      }

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `PID ${projectId} Project Scope.csv`);
      document.body.appendChild(link); // Required for FF

      link.click();

      return true;
    } catch (err) {
      console.error(`Project Scope Download Error: `, err);
      if (typeof onError === 'function') {
        onError(err.message);
      }

      return false;
    } finally {
      handleDownloadIndicators(false);
    }
  };

  const formatDataToCSV = useCallback((downloadData) => {
    try {
      const csvContent =
        'data:text/csv;charset=utf-8,' +
        downloadData.map((e) => e.join(',')).join('\n');

      return csvContent;
    } catch (err) {
      console.error(`formatDataToCSV Error: `, err);

      return null;
    }
  }, []);

  const getDisabledButtonExplanationForTooltip = () => {
    if (!doExist) {
      return TOOLTIP_MSG_NO_TESTS;
    }
    if (loading || isFetchingData) {
      return TOOLTIP_MSG_LOADING;
    }
    if (!projectData) {
      return TOOLTIP_MSG_NO_DATA;
    }

    return ``;
  };

  const handleRequestedDataReload = () => {
    if (!isFetchingData && !projectData) {
      getProjectDetailedData();
    }
  };

  return (
    <Stack direction="column">
      <Stack direction="row" gap={2}>
        {canRequestReload ? (
          <RefreshButton clickAction={handleRequestedDataReload} />
        ) : null}
      </Stack>
      {loading && (
        <Skeleton variant="rounded" height={35} sx={{ maxWidth: '480px' }} />
      )}
      {!loading && (
        <Box sx={{ minHeight: 40 }}>
          <Stack direction="row" gap={2}>
            {hasVerLikeTest || hasLegacyTest ? (
              <DownloadButton
                text="Project Scope"
                disabled={!projectData}
                loading={isFetchingData || downloadInProgress}
                tooltipText={getDisabledButtonExplanationForTooltip()}
                clickAction={() => handleDownloadScope(false)}
                sx={{ width: '180px' }}
              />
            ) : null}
            {hasHealthcareTest ? (
              <DownloadButton
                text="Project Scope (CET)"
                disabled={!projectData}
                loading={isFetchingData || downloadInProgress}
                tooltipText={getDisabledButtonExplanationForTooltip()}
                clickAction={() => handleDownloadScope(true)}
                sx={{ width: '220px' }}
              />
            ) : null}
            <ProjectConfigurationPrint
              projectId={projectId}
              projectName={projectName}
              onError={onError}
              loading={
                isFetchingData ||
                (fpImagesLoaded.current.amount && !_fpImagesLoaded)
              }
              tooltipText={getDisabledButtonExplanationForTooltip()}
              projectData={projectData}
              setFPLoaded={(fpName) => {
                fpImagesLoaded.current[fpName] = true;

                if (
                  Object.values(fpImagesLoaded.current).length - 1 ===
                    fpImagesLoaded.current.amount &&
                  Object.values(fpImagesLoaded.current).every((v) => !!v)
                ) {
                  _setFpImagesLoaded(true);
                }
              }}
            />
          </Stack>
        </Box>
      )}
    </Stack>
  );
}

export default React.memo(ProjectConfiguration);
