import { PROJECT_STATUSES, PROJECT_STATUSES_LABELS } from 'Constants';
import React from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import {
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
} from '@mui/material';

import { useDashboardContext } from 'Components/Dashboard/context';

export default function MainOverviewFilters() {
  const { mainOverviewFilterParams, updateMainOverviewFilterAndFetchData } =
    useDashboardContext();

  const currentYear = new Date().getFullYear();
  const yearsAvailable = Array(5)
    .fill()
    .map((_, i) => currentYear - i)
    .sort();

  return (
    <React.Fragment>
      <Stack direction="row" sx={{ width: '550px' }}>
        <FormControl variant="standard" fullWidth>
          <InputLabel id="mainOverview-period-type-label">
            Period type
          </InputLabel>
          <Select
            labelId="select-mainOverview-period-type-label"
            id="select-mainOverview-period-type"
            value={mainOverviewFilterParams.periodType}
            label="Period type"
            onChange={(e) => {
              updateMainOverviewFilterAndFetchData(
                'periodType',
                e.target.value,
              );
            }}
            sx={{ width: '240px' }}
          >
            {[
              { value: 'yearly', label: 'Yearly' },
              { value: 'quarterly', label: 'Quarterly' },
            ].map((entry) => (
              <MenuItem value={entry.value} key={entry.value}>
                {entry.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {mainOverviewFilterParams.periodType === 'quarterly' ? (
          <FormControl variant="standard" fullWidth>
            <InputLabel id="mainOverview-period-year-label">Year</InputLabel>
            <Select
              labelId="select-mainOverview-period-year-label"
              id="select-mainOverview-period-year"
              value={mainOverviewFilterParams.period || ''}
              label="Year"
              onChange={(e) => {
                updateMainOverviewFilterAndFetchData('period', e.target.value);
              }}
              sx={{ width: '240px' }}
            >
              {yearsAvailable.map((year) => (
                <MenuItem value={year} key={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
      </Stack>
      <Stack direction="row">
        <FormControlLabel
          control={
            <Switch
              checked={mainOverviewFilterParams.filterByCompany}
              onChange={(e) =>
                updateMainOverviewFilterAndFetchData(
                  'filterByCompany',
                  e.target.checked,
                )
              }
              disabled={
                mainOverviewFilterParams.periodType === 'quarterly' &&
                !mainOverviewFilterParams.period
              }
            />
          }
          label="Only selected company"
        />
        <FormControlLabel
          control={
            <Switch
              checked={mainOverviewFilterParams.filterByTestsCompletion}
              onChange={(e) =>
                updateMainOverviewFilterAndFetchData(
                  'filterByTestsCompletion',
                  e.target.checked,
                )
              }
              disabled={
                mainOverviewFilterParams.periodType === 'quarterly' &&
                !mainOverviewFilterParams.period
              }
            />
          }
          label="Only completed tests"
        />
      </Stack>
      <Stack
        direction="row"
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        gap={2}
      >
        <FormControl variant="standard">
          <InputLabel id="mainOverview-period-type-label">
            Projects statuses
          </InputLabel>
          <Select
            labelId="select-mainOverview-period-type-label"
            id="select-mainOverview-period-type"
            value={mainOverviewFilterParams.projectStatuses}
            label="Projects statuses"
            onChange={(e) =>
              updateMainOverviewFilterAndFetchData(
                'projectStatuses',
                e.target.value,
              )
            }
            multiple
            sx={{ width: '550px' }}
            disabled={
              mainOverviewFilterParams.periodType === 'quarterly' &&
              !mainOverviewFilterParams.period
            }
          >
            {Object.values(PROJECT_STATUSES).map((status) => (
              <MenuItem value={status} key={status}>
                {PROJECT_STATUSES_LABELS[status]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton
          sx={{
            mr: 'auto',
            mt: 'auto',
            visibility:
              Object.values(PROJECT_STATUSES).length !==
              mainOverviewFilterParams.projectStatuses.length
                ? ''
                : 'hidden',
          }}
          size="small"
          onClick={() =>
            updateMainOverviewFilterAndFetchData(
              'projectStatuses',
              Object.values(PROJECT_STATUSES),
            )
          }
        >
          <ClearIcon />
        </IconButton>
      </Stack>
    </React.Fragment>
  );
}
