import React from 'react';

import ChartTitle from './ChartTitle';
import ChartAdjustedEACHByHDA from './Charts/ChartAdjustedEACHByHDA';
import ChartEACHByFloor from './Charts/ChartEACHByFloor';
import './styles.css';

import { Box, Stack } from '@mui/material';

export default function BuildingOverview() {
  return (
    <Stack gap={2} sx={{ alignItems: 'center' }}>
      <ChartTitle
        titleText="Adjusted Equivalent Air Change Rate (eACH) by High Density Area"
        chartId="chart-adjusted-each-by-hda"
      />
      <Box
        id="chart-adjusted-each-by-hda"
        sx={{ width: '100%', height: '500px' }}
      >
        <ChartAdjustedEACHByHDA />
      </Box>
      <ChartTitle titleText="eACH by Floor" chartId="chart-each-by-floor" />
      <Box id="chart-each-by-floor" sx={{ width: '100%', height: '500px' }}>
        <ChartEACHByFloor />
      </Box>
    </Stack>
  );
}
